/* eslint-disable max-lines */
import { Button, FormGroup, Form, Input, Row, Col } from 'reactstrap';
import { toast } from 'react-toastify';
import { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Context as AuthContext } from '../../../../contexts/AuthContext';
import UserService from '../../../../services/User';
import MedicalService from '../../../../services/Medical';

const MedicalTab = () => {
  const { id: userId } = useParams<{ id: string }>();
  const { token }: any = useContext(AuthContext);

  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [medicalId, setMedicalId] = useState('');
  const [doctorName, setDoctorName] = useState('');
  const [doctorPhone, setDoctorPhone] = useState('');
  const [emergencyName, setEmergencyName] = useState('');
  const [emergencyPhone, setEmergencyPhone] = useState('');
  const [insurance, setInsurance] = useState('');
  const [policy, setPolicy] = useState('');
  const [medicalNotes, setMedicalNotes] = useState('');

  useEffect(() => {
    getById();
  }, []);

  useEffect(() => {
    getById();
  }, [userId]);

  const getById = async () => {
    setIsDisabled(true);
    try {
      const result = await UserService(token).show(userId);
      if (result?.data) {
        const medical = result?.data?.medical;
        setMedicalId(medical?.id || '');
        setDoctorName(medical?.doctorName || '');
        setDoctorPhone(medical?.doctorPhone || '');
        setEmergencyName(medical?.emergencyName || '');
        setEmergencyPhone(medical?.emergencyPhone || '');
        setInsurance(medical?.insurance || '');
        setPolicy(medical?.policy || '');
        setMedicalNotes(medical?.medicalNotes || '');
      }
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'Invalid user ID');
    } finally {
      setIsDisabled(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);
    setIsDisabled(true);

    const body = {
      userId,
      doctorName,
      doctorPhone,
      emergencyName,
      emergencyPhone,
      insurance,
      policy,
      medicalNotes,
    };

    try {
      medicalId ? await MedicalService(token).update(medicalId, body) : await MedicalService(token).create(body);
      toast.success('Updated successfully!');
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Update error!');
      toast.error(message);
    } finally {
      setIsDisabled(false);
    }

    setLoading(false);
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <div className="pl-lg-4 mt-3">
          <Row>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label">Emergency Name</label>
                <Input
                  className="form-control-alternative"
                  placeholder="Emergency Name"
                  type="text"
                  disabled={isDisabled}
                  value={emergencyName}
                  onChange={(e) => setEmergencyName(e.target.value)}
                  required
                />
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label">Emergency Phone</label>
                <Input
                  className="form-control-alternative"
                  placeholder="Emergency Phone"
                  type="text"
                  disabled={isDisabled}
                  value={emergencyPhone}
                  onChange={(e) => setEmergencyPhone(e.target.value)}
                  required
                />
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label">Doctor Name</label>
                <Input
                  className="form-control-alternative"
                  placeholder="Doctor Name"
                  type="text"
                  disabled={isDisabled}
                  value={doctorName}
                  onChange={(e) => setDoctorName(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label">Doctor Phone</label>
                <Input
                  className="form-control-alternative"
                  placeholder="Doctor Phone"
                  type="text"
                  disabled={isDisabled}
                  value={doctorPhone}
                  onChange={(e) => setDoctorPhone(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label">Insurance</label>
                <Input
                  className="form-control-alternative"
                  placeholder="Insurance"
                  type="text"
                  disabled={isDisabled}
                  value={insurance}
                  onChange={(e) => setInsurance(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label">Policy</label>
                <Input
                  className="form-control-alternative"
                  placeholder="Policy"
                  type="text"
                  disabled={isDisabled}
                  value={policy}
                  onChange={(e) => setPolicy(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg="12">
              <FormGroup>
                <label className="form-control-label">Medical Notes</label>
                <Input
                  className="form-control-alternative"
                  placeholder="Medical Notes"
                  type="textarea"
                  rows="5"
                  disabled={isDisabled}
                  value={medicalNotes}
                  onChange={(e) => setMedicalNotes(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Button className="my-4" color="primary" type="submit" disabled={isDisabled}>
            Save
          </Button>
        </div>
      </Form>
    </>
  );
};

export default MedicalTab;
