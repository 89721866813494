import { useEffect, useRef, useState, Ref, useContext } from 'react';
import { useLocation, Switch } from 'react-router-dom';
import { Container } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import BaseNavbar from '../components/Navbars/BaseNavbar';
import AdminFooter from '../components/Footers/AdminFooter';
import Sidebar from '../components/Sidebar/SidebarProfile';
import routes from '../routes-menu';
import { Context as AuthContext } from '../contexts/AuthContext';

import { motion } from 'framer-motion';

const Logged = (props: any, ref: Ref<HTMLDivElement>) => {
  const isTablet = window.innerWidth <= 1112 && window.innerWidth > 768 && isMobile;
  const mainContent: any = useRef(null);
  const location = useLocation();
  const [sidebarMini, setSidebarMini] = useState(false);
  const toggleSidebar = () => setSidebarMini(!sidebarMini);
  const { updateUserNotifications, updateuserTotalUnreadMessages, updateSideBarIsMini }: any = useContext(AuthContext);
  const variants = {
    mini: { marginLeft: !isMobile ? '70px' : '0px' },
    normal: { marginLeft: !isMobile ? '250px' : '0px' },
  };

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    if (document.scrollingElement) {
      document.scrollingElement.scrollTop = 0;
    }
    if (mainContent.current) {
      mainContent.current.scrollTop = 0;
    }
  }, [location, sidebarMini, variants]);

  useEffect(() => {
    updateUserNotifications();
    updateuserTotalUnreadMessages();
  }, [location]);

  useEffect(() => {
    updateSideBarIsMini(sidebarMini);
  }, [sidebarMini]);

  // const getRoutes = (routes: any) => {
  //   return routes.map((prop: any, key: any) => {
  //     if (prop.layout === '/logged') {
  //       return <Route path={prop.path} component={prop.component} key={key} />;
  //     } else {
  //       return null;
  //     }
  //   });
  // };

  const getBrandText = (_?: any) => {
    // for (let i = 0; i < routes.length; i++) {
    //   if (props.location.pathname.indexOf(routes[i].path) !== -1) {
    //     return routes[i].name;
    //   }
    // }
    return '';
  };

  return (
    <>
      <Sidebar sidebarMini={sidebarMini} toogleSideBarMini={toggleSidebar} {...props} routes={routes}>
        <div onClick={toggleSidebar} className={`d-none d-sm-block ${sidebarMini ? '' : 'position-relative'}`}>
          {sidebarMini && (
            <div
              style={{ width: 30, height: 30, right: 0 }}
              className="d-flex justify-content-center align-items-center shadow-lg bg-light rounded-circle cursor-pointer"
            >
              <i className="fas fa-arrow-right text-white" />
            </div>
          )}

          {!sidebarMini && (
            <div
              onClick={toggleSidebar}
              style={{ width: 30, height: 30, right: 0 }}
              className="d-flex justify-content-center align-items-center shadow-lg bg-light rounded-circle cursor-pointer position-absolute"
            >
              <i className="fas fa-arrow-left text-white" />
            </div>
          )}
        </div>
      </Sidebar>
      <motion.div
        ref={ref}
        animate={sidebarMini ? 'mini' : 'normal'}
        style={
          sidebarMini
            ? { marginLeft: !isMobile || isTablet ? '70px' : '0px', marginTop: isMobile && !isTablet ? '35px' : '0px' }
            : { marginLeft: !isMobile || isTablet ? '250px' : '0px', marginTop: isMobile && !isTablet ? '35px' : '0px' }
        }
        className="main-content"
      >
        <BaseNavbar {...props} brandText={getBrandText()} />
        <Switch>
          <Switch>{props.children}</Switch>
        </Switch>
        <Container fluid>
          <AdminFooter />
        </Container>
      </motion.div>
    </>
  );
};

export default Logged;
