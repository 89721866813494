const isAuthorized = (route: any, user: any) => {
  if (user?.roles?.includes('admin')) {
    return true;
  }

  let functionPermission = false;

  if (!route.moduleName && !route.functionName) {
    functionPermission = true;
  }
  if (route.moduleName && route.functionName && user?.functions?.length) {
    const isAutorized = user.functions.find(
      (f: any) => f.moduleName === route.moduleName && f.functionName === route.functionName,
    );
    functionPermission = !!isAutorized;
  }

  if (route.childs) {
    // let isAutorized;
    let showPrincipalItem;
    for (let i = 0; i < route.childs.length; i++) {
      const item = route.childs[i];
      const isRouterAutorized = !!user.functions.find(
        (f: any) => f.moduleName === item.moduleName && f.functionName === item.functionName,
      );
      if (isRouterAutorized) {
        showPrincipalItem = true;
        break;
      }
    }
    functionPermission = showPrincipalItem || false;
  }

  return functionPermission;
};

export default isAuthorized;
