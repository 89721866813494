/* eslint-disable max-lines */
import { Card, Container, Row, Col, CardBody, FormGroup, Form, Input, Button } from 'reactstrap';
import InputMask from 'react-input-mask';

type Props = {
  index: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  setFirstName: (index: number, value: string) => void;
  setLastName: (index: number, value: string) => void;
  setEmail: (index: number, value: string) => void;
  setPhone: (index: number, value: string) => void;
  deleteParent: (index: number) => void;
};

const ParentForm = ({
  index,
  firstName,
  lastName,
  email,
  phone,
  setFirstName,
  setLastName,
  setEmail,
  setPhone,
  deleteParent,
}: Props) => {
  const handleSubmitForm = async (e: React.FormEvent) => {
    e.preventDefault();
  };

  return (
    <>
      <Container fluid>
        <Form onSubmit={handleSubmitForm}>
          <Card>
            <CardBody style={{ padding: '1rem', paddingBottom: 0 }}>
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-firstName">
                      First Name *
                    </label>
                    <Input
                      className="form-control-alternative"
                      id="input-firstName"
                      placeholder="First Name"
                      type="text"
                      value={firstName}
                      onChange={(e) => setFirstName(index, e.target.value)}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-lastName">
                      Last Name *
                    </label>
                    <Input
                      className="form-control-alternative"
                      id="input-lastName"
                      placeholder="Last Name"
                      type="text"
                      value={lastName}
                      onChange={(e) => setLastName(index, e.target.value)}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-email">
                      Email *
                    </label>
                    <Input
                      className="form-control-alternative"
                      id="input-email"
                      placeholder="Email"
                      type="text"
                      value={email}
                      onChange={(e) => setEmail(index, e.target.value)}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-phone">
                      Phone Number *
                    </label>
                    <Input
                      className="form-control-alternative"
                      id="input-phone"
                      placeholder="Phone Number"
                      mask="(999) 999-9999"
                      tag={InputMask}
                      type="text"
                      value={phone}
                      onChange={(e) => setPhone(index, e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col lg="12">
                  {index > 0 && (
                    <Button color="warning" className="btn-delete mb-3" size="sm" onClick={() => deleteParent(index)}>
                      Delete
                    </Button>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Form>
      </Container>
    </>
  );
};

export default ParentForm;
