/* eslint-disable max-lines */
import {
  Card,
  Button,
  Container,
  Row,
  Col,
  CardBody,
  FormGroup,
  Form,
  Input,
  CardHeader,
  Alert,
  Label,
} from 'reactstrap';
import { useContext, useState, useEffect } from 'react';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import { toast } from 'react-toastify';
import TimePicker from '../../../components/Core/TimezonePicker';
import KidsActivity from '../../../services/KidsActivity';
import Upload from '../../../components/Core/Upload';
import ActivitySettingsService from '../../../services/ActivitySettings';
import ClassActivitySettingsList from './ClassActivitySettingsList';
import isAdmin from '../../../helpers/isAdmin';
import isDirector from '../../../helpers/isDirector';
import SchoolScheduleEvents from '../../../services/SchoolScheduleEvents';
import moment from 'moment';

type ClassActivityProps = {
  kidsIds: string[];
  tag: string;
  isNotPhotoPermissionKids?: any[];
  onCloseModal: (clear?: boolean) => void;
  onSubmitSetTimer: (id: string, type: string) => void;
};

const ClassActivityForm = ({
  kidsIds,
  tag,
  isNotPhotoPermissionKids,
  onCloseModal,
  onSubmitSetTimer = () => {},
}: ClassActivityProps) => {
  const { token, user, currentSchool }: any = useContext(AuthContext);
  const hasSettingsPermission = isAdmin(user) || isDirector(user);

  const [loading, setLoading] = useState(false);
  const [activity, setActivity] = useState('');
  const [notes, setNotes] = useState('');
  const [time, setTime] = useState('');
  const [pictures, setPictures] = useState([] as any[]);
  const [pictureWarning, setPictureWarning] = useState('');
  const [disabled, setDisabled] = useState(false);

  const [mealType, setMealType] = useState('');
  const [childAte, setChildAte] = useState('');
  const [food, setFood] = useState('');
  const [foodOther, setFoodOther] = useState('');
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [settingsList, setSettingsList] = useState([]);
  const [foodSnackList, setFoodSnackList] = useState<{ am: string[]; pm: string[] }>();

  useEffect(() => {
    if (!kidsIds.length) {
      toast.warn('Please select the students.');
      onCloseModal();
    }
    listActivitySettings();
    listSnacksAndLunchsToDay('snack');
  }, []);

  useEffect(() => {
    if (pictures?.length && isNotPhotoPermissionKids?.length) {
      const kids = isNotPhotoPermissionKids.map((k) => `${k.firstName} ${k.lastName}`);
      setPictureWarning(`Students who are not allowed to upload photos: ${kids.join(', ')}`);
      setDisabled(true);
      return;
    }
    setPictureWarning('');
    setDisabled(false);
  }, [pictures]);

  const closeModal = () => {
    onCloseModal();
  };

  const onChangeFile = (files: any[]) => {
    setPictures(files);
  };

  const handleSubmitForm = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);

    try {
      const formData = new FormData();
      if (pictures?.length) {
        for (let i = 0; i < pictures.length; i++) {
          formData.append('pictures', pictures[i]);
        }
      }
      for (const k of kidsIds) {
        const options = {
          mealType,
          food,
          foodDescribe: foodOther || '',
          childAte,
          describe: activity || '',
        };
        const result = await KidsActivity(token).sendFood(k, notes, time, options);
        if (pictures?.length && result?.data?.id) {
          await KidsActivity(token).pictures(result?.data?.id, formData);
        }
      }
      toast.success(`Saved successfully!`);

      kidsIds.forEach((id: string) => onSubmitSetTimer(id, 'FOOD'));
      onCloseModal(true);
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Create error!');
      toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  const listActivitySettings = async () => {
    try {
      const result = await ActivitySettingsService(token).listByType(tag);
      result.data.unshift({ name: 'Late PM Snack' });
      result.data.unshift({ name: 'PM Snack' });
      result.data.unshift({ name: 'Lunch' });
      result.data.unshift({ name: 'AM Snack' });
      result.data.unshift({ name: 'Breakfast' });
      setSettingsList(result.data || []);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No Activity Settings Found!');
    }
  };

  const listSnacksAndLunchsToDay = async (type: string) => {
    try {
      const filters = {
        type: type,
        schoolId: currentSchool.value,
        startAt: moment().format('YYYY-MM-DD') + ' 00:00:00',
        endAt: moment().add(1, 'day').format('YYYY-MM-DD') + ' 00:00:00',
      };
      const result = await SchoolScheduleEvents(token).getEventsBySchoolIdAndMonth(filters);
      if (type === 'snack') {
        const am: string[] = [];
        const pm: string[] = [];
        const snacks = { am, pm };
        for (const i in result.data) {
          const objectTitle = JSON.parse(result.data[i].title);
          snacks.am.push(objectTitle.am);
          snacks.pm.push(objectTitle.pm);
        }
        setFoodSnackList(snacks);
      }
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No Foods Found!');
    }
  };

  const toggleActivitySettings = (e: any) => {
    e.preventDefault();
    setIsSettingsOpen(!isSettingsOpen);
  };

  const showFoodListIfConditional = (conditional: boolean, foods: any) => {
    if (!conditional) {
      return '';
    }
    if (foods === undefined) {
      return '';
    }
    return (
      <>
        {foods.map((s: any) => (
          <Col md="12" key={`settings-input-${s}`}>
            <FormGroup check>
              <Label check>
                <Input name="food" type="radio" value={s} onChange={(e) => setFood(e.target.value)} /> {s}
              </Label>
            </FormGroup>
          </Col>
        ))}
      </>
    );
  };

  return (
    <>
      <Container fluid>
        <div className="text-right mb-3">
          {hasSettingsPermission && (
            <i className="fas fa-cog fa-lg" style={{ cursor: 'pointer' }} onClick={toggleActivitySettings}></i>
          )}
        </div>
        <Form onSubmit={handleSubmitForm}>
          <Card>
            {pictureWarning && (
              <CardHeader>
                <Alert color="warning">{pictureWarning}</Alert>
              </CardHeader>
            )}
            <CardBody>
              <Row>
                <Col lg="12">
                  <FormGroup row>
                    <Col md="12">
                      <label className="form-control-label" htmlFor="activityType">
                        Meal type
                      </label>
                    </Col>
                    {settingsList.map((s: any) => (
                      <Col md="12" key={`settings-input-${s.id}`}>
                        <FormGroup check>
                          <Label check>
                            <Input
                              name="activityType"
                              type="radio"
                              value={s.name}
                              onChange={(e) => {
                                setMealType(e.target.value);
                                setFood('');
                              }}
                            />{' '}
                            {s.name}
                          </Label>
                        </FormGroup>
                      </Col>
                    ))}
                    <Col md="12" key={`settings-input-other`}>
                      <FormGroup check>
                        <Label check>
                          <Input
                            name="activityType"
                            type="radio"
                            value={'Other'}
                            onChange={(e) => {
                              setMealType(e.target.value);
                              setFood('');
                            }}
                          />{' '}
                          Other
                        </Label>
                      </FormGroup>
                    </Col>
                  </FormGroup>
                </Col>
                {mealType === 'Other' && (
                  <Col lg="12">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-describe">
                        Describe
                      </label>
                      <Input type="text" onChange={(e) => setActivity(e.target.value)} required></Input>
                    </FormGroup>
                  </Col>
                )}
                <Col lg="12">
                  <FormGroup row>
                    <Col md="12">
                      <label className="form-control-label" htmlFor="activityFood">
                        Food
                      </label>
                    </Col>
                    {showFoodListIfConditional(mealType == 'AM Snack', foodSnackList?.am)}
                    {showFoodListIfConditional(mealType == 'PM Snack', foodSnackList?.pm)}
                    {['Breakfast', 'Lunch', 'Late PM Snack'].indexOf(mealType) == -1 && (
                      <Col md="12">
                        <FormGroup check>
                          <Label check>
                            <Input name="food" type="radio" value={'Other'} onChange={(e) => setFood(e.target.value)} />{' '}
                            Other
                          </Label>
                        </FormGroup>
                      </Col>
                    )}
                    {(food === 'Other' || ['Breakfast', 'Lunch', 'Late PM Snack'].indexOf(mealType)) != -1 ? (
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-describe">
                            Describe
                          </label>
                          <Input type="text" onChange={(e) => setFoodOther(e.target.value)} required></Input>
                        </FormGroup>
                      </Col>
                    ) : (
                      ''
                    )}
                  </FormGroup>
                </Col>
                <Col lg="12">
                  <FormGroup row>
                    <Col md="12">
                      <label className="form-control-label" htmlFor="childAte">
                        Child ate
                      </label>
                    </Col>
                    <Col md="12">
                      <FormGroup check>
                        <Label check>
                          <Input
                            name="childAte"
                            type="radio"
                            value={'None'}
                            onChange={(e) => setChildAte(e.target.value)}
                          />{' '}
                          None
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <FormGroup check>
                        <Label check>
                          <Input
                            name="childAte"
                            type="radio"
                            value={'Some'}
                            onChange={(e) => setChildAte(e.target.value)}
                          />{' '}
                          Some
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <FormGroup check>
                        <Label check>
                          <Input
                            name="childAte"
                            type="radio"
                            value={'Most'}
                            onChange={(e) => setChildAte(e.target.value)}
                          />{' '}
                          Most
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <FormGroup check>
                        <Label check>
                          <Input
                            name="childAte"
                            type="radio"
                            value={'All'}
                            onChange={(e) => setChildAte(e.target.value)}
                          />{' '}
                          All
                        </Label>
                      </FormGroup>
                    </Col>
                  </FormGroup>
                </Col>
                <Col lg="12">
                  <FormGroup>
                    <label className="form-control-label">Insert a Note</label>
                    <Input type="textarea" onChange={(e) => setNotes(e.target.value)} />
                  </FormGroup>
                </Col>
                <Col lg="12">
                  <FormGroup>
                    <label className="form-control-label">Time</label>
                    <TimePicker
                      onChangeTime={(time: string) => {
                        setTime(time);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col lg="12">
                  <Upload accept=".png,.jpg,.jpeg" multiple={true} onChangeFiles={onChangeFile} />
                </Col>
              </Row>
              <Row className="mt-5">
                <Col md="6 text-center">
                  <Button
                    color="primary"
                    type="submit"
                    size="lg"
                    style={{ minWidth: '100px' }}
                    disabled={disabled || loading}
                  >
                    {loading && (
                      <>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        {'  Save'}
                      </>
                    )}
                    {!loading && <>Save</>}
                  </Button>
                </Col>
                <Col md="6 text-center">
                  <Button
                    color="secondary"
                    size="lg"
                    style={{ minWidth: '100px' }}
                    onClick={closeModal}
                    disabled={loading}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Form>
      </Container>
      <ClassActivitySettingsList
        isOpen={isSettingsOpen}
        settingsList={settingsList}
        activityType={tag}
        onListUpdate={listActivitySettings}
        toggleModal={toggleActivitySettings}
      />
    </>
  );
};

export default ClassActivityForm;
