/* eslint-disable max-lines */
import CommonHeader from '../../../components/Headers/CommonHeader';
import CardInfo from '../../../components/Cards/CardInfo';
import { useContext, useState, useEffect, useRef } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  InputGroupAddon,
  InputGroup,
  CardText,
} from 'reactstrap';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import { Link } from 'react-router-dom';
import SchoolService from '../../../services/School';
import ImportService from '../../../services/Import';
import ClassService from '../../../services/Class';
import { toast } from 'react-toastify';
import { FilteredOptionsInterface } from '../../../entities/FilteredOptions';
import { createOptionsPage } from '../../../entities/OptionsPaged';
import { createPagedMeta } from '../../../entities/PagedMeta';
import DataTable from '../../../components/Core/DataTable';
import StateService from '../../../services/State';
// import SearchSelectWithThumb from '../../../components/Core/SearchSelectWithThumb';
import PermissionGroupsService from '../../../services/PermissionGroups';
import Pto from '../../../services/Pto';
import { validEnrollmentStatus } from '../../../interfaces/KidsInterface';
import Kids from '../../../services/Kids';
import EmployeeService from '../../../services/Employee';

const Index = () => {
  const pageTitle = 'Imports';
  const { token, currentFranchisee, currentSchool }: any = useContext(AuthContext);
  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [csvType, setCsvType] = useState('school');
  const imageRef = useRef(null as any);
  const [importFile, setImportFile] = useState<any>(null);
  const [totals, setTotals] = useState<any>({ school: 0, staff: 0, class: 0, priceSheet: 0, student: 0, parent: 0 });

  const [importedLines, setImportedLines] = useState<any[]>([]);
  const [importStep, setImportStep] = useState(1);
  const [statesList, setStatesList] = useState<any[]>([]);

  const [groupsList, setGroupsList] = useState([] as any[]);
  const [ptoPolices, setPtoPolices] = useState([] as any[]);
  const [classesList, setClassesList] = useState([] as any[]);
  const [schoolsList, setSchoolsList] = useState([] as any[]);
  const [studentsList, setStudentsList] = useState([] as any[]);
  const validPermissionGroups = [
    {
      name: 'Accounting',
      label: 'Accounting',
    },
    {
      name: 'Director',
      label: 'Director',
    },
    {
      name: 'Staff',
      label: 'Staff',
    },
    {
      name: 'Teachers',
      label: 'Teacher',
    },
    {
      name: 'EducationDirectorAdminAssistant',
      label: 'Education Director / Admin Assistant',
    },
    {
      name: 'UpperManagement',
      label: 'Upper Management',
    },
  ];

  const onChangeFile = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];
    if (file) {
      return setImportFile(file);
    }
  };

  const showOpenFileDialog = () => {
    if (isDisabled) {
      return;
    }
    setImportFile(null);
    if (imageRef?.current?.click) {
      imageRef.current.click();
    }
  };

  const loadTotals = async () => {
    try {
      const alteredTotals = totals;
      const filteredOptions: FilteredOptionsInterface = { filters: [], options: createOptionsPage(createPagedMeta()) };
      const filter = {
        field: 'franchiseeId',
        operation: 'hash',
        value: currentFranchisee.value,
      };
      filteredOptions.filters.push(filter);
      const result = await SchoolService(token).list(filteredOptions);
      const rStaff = await EmployeeService(token).list(filteredOptions);
      const rTotals = await ImportService(token).countTotalsByFranchiseId(currentFranchisee.value);

      alteredTotals.school = result.meta.total;
      alteredTotals.staff = rStaff.meta.total;
      alteredTotals.class = rTotals.data.class | 0;
      alteredTotals.student = rTotals.data.student | 0;
      alteredTotals.parent = rTotals.data.parent | 0;
      //set Totals to empty for reset Totals data
      setTotals({});
      setTotals(alteredTotals);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No totals found');
    }
  };

  const loadStates = async () => {
    try {
      const result = await StateService(token).listSelect();
      setStatesList(result.data || []);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No States found');
    }
  };

  const ucfisrt = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  const clickPrev = (actualStep: number) => {
    setImportStep(actualStep - 1);
  };

  const getObjectFromFormData = (formData: any) => {
    const returnObject: any[] = [];
    Object.keys(Object.fromEntries(formData)).forEach((item: any) => {
      const splited = item.split('[');
      if (returnObject[splited[1].replaceAll(']', '')] === undefined) {
        returnObject[splited[1].replaceAll(']', '')] = {};
      }
      returnObject[splited[1].replaceAll(']', '')][splited[2].replaceAll(']', '')] = Object.fromEntries(formData)[item];
    });
    return returnObject;
  };

  const listGroups = async () => {
    try {
      const result = await PermissionGroupsService(token).list();
      const valids = validPermissionGroups.map((v) => v.name);
      const filtered = result.filter((v: any) => valids.includes(v.name));
      const groupList: { id: string; name: string }[] = [];
      for (const r of filtered) {
        const valid = validPermissionGroups.find((v) => v.name === r.name);
        groupList.push({
          id: r?.id || '',
          name: valid?.label || '',
        });
      }
      setGroupsList(groupList || []);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No permission groups found');
    }
  };

  const listPtoPolices = async () => {
    try {
      const result = await Pto(token).ptoPolicyList();
      setPtoPolices(result.data || []);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No data Found!');
    }
  };

  const listClasses = async () => {
    try {
      const filteredOptions = {
        filters: [] as any[],
        options: createOptionsPage(createPagedMeta()),
      };
      filteredOptions.options.itemsPerPage = 100000;
      const result = await ClassService(token).list(filteredOptions);
      setClassesList(result.data || []);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No data Found!');
    }
  };

  const listStudents = async () => {
    try {
      const filteredOptions = {
        filters: [] as any[],
        options: createOptionsPage(createPagedMeta()),
      };
      const filter = {
        field: 'enrollments.schoolId',
        operation: 'hash',
        value: currentSchool.value,
      };
      filteredOptions.filters = [filter];
      filteredOptions.options.itemsPerPage = 100000;
      const result = await Kids(token).list(filteredOptions);
      setStudentsList(result.data || []);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No data Found!');
    }
  };

  const listSchools = async () => {
    try {
      const filteredOptions = {
        filters: [] as any[],
        options: createOptionsPage(createPagedMeta()),
      };
      const filterFranchiseeId = {
        field: 'franchiseeId',
        operation: 'hash',
        value: currentFranchisee.value,
      };
      filteredOptions.filters = [filterFranchiseeId];
      filteredOptions.options.itemsPerPage = 100000;
      const result = await SchoolService(token).list(filteredOptions);
      setSchoolsList(result.data || []);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No schools found');
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading || !importFile) return;
    setLoading(true);
    setIsDisabled(true);

    if (importStep === 1) {
      await listSchools();
      await listStudents();
      if (csvType === 'staff') {
        await listGroups();
        await listPtoPolices();
      }
      if (['priceSheet', 'student'].includes(csvType)) {
        await listClasses();
      }
      setImportedLines([]);
      const reader = new FileReader();
      reader.readAsText(importFile, 'UTF-8');
      reader.onload = (e: any) => {
        const lines = e.target.result.split(/[\r\n]+/g);
        // const regex = /(\s*"[^"]+"|\s*[^,]+)(?=,|$)/g;
        let regex = /;(?=(?:(?:[^"]*"){2})*[^"]*$)/;
        const objectedLines: any[] = [];
        let separator = ';';
        if (lines[0].search(';') === -1 && lines[0].search(',') !== -1) {
          separator = ',';
          regex = /,(?=(?:(?:[^"]*"){2})*[^"]*$)/;
        }
        lines.forEach((line: any, i: number) => {
          if (i === 0 || line === '') {
            return;
          }
          const innerObject = { id: i.toString() };
          lines[0].split(separator).forEach((item: any, cont: any) => {
            (innerObject as any)[item.replaceAll(' ', '_').toLocaleLowerCase()] = String(
              String(line)?.split(regex)?.[cont],
            ).replaceAll('"', '');
            // (innerObject as any)[item.replaceAll(' ', '_').toLocaleLowerCase()] = line.split(separator)[cont];
          });
          objectedLines.push(innerObject);
        });

        if (objectedLines.length === 0) {
          toast.error('No data found!');
          return;
        }
        setImportedLines(objectedLines);
      };
    }

    if (importStep === 2) {
      setImportErrors([]);
      const formData = new FormData(document.getElementById('formPrincipal') as HTMLFormElement);

      const objectData = getObjectFromFormData(formData);

      const objectMerged = importedLines.map((item: any, i: any) => {
        return { ...item, ...objectData[i], ...{ franchiseeId: currentFranchisee.value } };
      });
      try {
        let body: any = { ...objectMerged };
        if (csvType === 'parent') {
          body = objectMerged.map((item: any) => {
            const b: any = {
              ...item,
              is_payer: item['is_payer?_(yes/no)'],
              type: item['type_(parent/guardian/pickup_authorized)'],
            };
            delete b['is_payer?_(yes/no)'];
            delete b['type_(parent/guardian/pickup_authorized)'];
            return b;
          });
        }
        await ImportService(token).fromData(csvType, body);
        setImportStep(3);
      } catch (e: any) {
        setImportErrors(e?.response.data.errors);
        toast.error(e?.response?.data?.message || 'Error in import');
      }
    }
    setLoading(false);
    setIsDisabled(false);
  };

  const [importErrors, setImportErrors] = useState({});

  const getFieldByImportedConfig = (value: string, name: string) => {
    // return value;
    // const statesList = (await StateService(token).listSelect()).data) || [];
    let cell = <Input defaultValue={value} name={name} />;
    if (['on', 'off'].includes(String(value).toLowerCase())) {
      // cell = (
      //   <label className="custom-toggle" style={{ margin: '0 auto' }}>
      //     <Input type="checkbox" defaultChecked={value.toLowerCase() === 'on'} name={name} />
      //     <span className="custom-toggle-slider rounded-circle" />
      //   </label>
      // );
      const validPaymentFrequency = [
        { value: 'on', label: 'On' },
        { value: 'off', label: 'Off' },
      ];
      cell = (
        <Input type="select" defaultValue={value} name={name}>
          {validPaymentFrequency.map((a: any, index: number) => {
            return (
              <option value={a.value} key={`operational-school-option-${index}`}>
                {a.label}
              </option>
            );
          })}
        </Input>
      );
    }
    if (name.split('][')[1] === 'state]') {
      cell = (
        <Input type="select" defaultValue={value} name={name}>
          {statesList?.map((filterState: any, indexState: number) => {
            return (
              <option value={filterState.id} key={`filter-button-${indexState}`}>
                {filterState.name} ({filterState.uf})
              </option>
            );
          })}
        </Input>
      );
    }

    if (name.split('][')[1] === 'payment_frequency]') {
      let validPaymentFrequency = [
        { value: 'WEEKLY', label: 'Weekly' },
        { value: 'MONTHLY', label: 'Monthly' },
      ];
      if (csvType === 'staff') {
        validPaymentFrequency = [
          { value: 'hourly', label: 'Hourly' },
          { value: 'annually', label: 'Annually' },
        ];
      }
      cell = (
        <Input type="select" defaultValue={value} name={name}>
          {validPaymentFrequency.map((a: any, index: number) => {
            return (
              <option value={a.value} key={`payment-frequency-option-${index}`}>
                {a.label}
              </option>
            );
          })}
        </Input>
      );
    }

    if (name.split('][')[1] === 'classification]') {
      const validPaymentFrequency = [
        { value: 'FT', label: 'Full Time' },
        { value: 'PT', label: 'Part Time' },
        { value: 'Sub', label: 'Sub' },
      ];
      cell = (
        <Input type="select" defaultValue={value} name={name}>
          {validPaymentFrequency.map((a: any, index: number) => {
            return (
              <option value={a.value} key={`classification-option-${index}`}>
                {a.label}
              </option>
            );
          })}
        </Input>
      );
    }

    if (name.split('][')[1] === 'student_annual_vacation_time]') {
      cell = (
        <Input type="select" defaultValue={value} name={name}>
          {[1, 2, 3, 4].map((filter: any, index: number) => {
            return (
              <option value={filter} key={`filter-button-${index}`}>
                {filter} Weeks
              </option>
            );
          })}
        </Input>
      );
    }

    if (name.split('][')[1].toLocaleLowerCase() === 'school]') {
      let selected = schoolsList.find(
        (item) => String(item.name).toLocaleLowerCase() === String(value).toLocaleLowerCase(),
      );
      if (!selected && currentSchool?.value) {
        selected = { id: currentSchool.value };
      }
      cell = (
        <Input type="select" defaultValue={selected?.id || ''} name={name}>
          <option value={''}></option>
          {schoolsList.map((value) => (
            <option key={value.id} value={value.id}>
              {value.name}
            </option>
          ))}
        </Input>
      );
    }

    if (name.split('][')[1].toLocaleLowerCase() === 'permission_group]') {
      const selected = groupsList.find((item) => item.name.toLocaleLowerCase() === value.toLocaleLowerCase());
      cell = (
        <Input type="select" defaultValue={selected?.id || ''} name={name}>
          <option value=""></option>
          {groupsList?.map((row: any, index: number) => {
            return (
              <option value={row.id} key={`permission-group-${index}`}>
                {row.name}
              </option>
            );
          })}
        </Input>
      );
    }

    if (name.split('][')[1].toLocaleLowerCase() === 'pto_policy]') {
      const selected = ptoPolices.find((item) => item.name.toLocaleLowerCase() === value.toLocaleLowerCase());
      cell = (
        <Input type="select" defaultValue={selected?.id || ''} name={name}>
          <option value={''}></option>
          {ptoPolices.map((value) => (
            <option key={value.id} value={value.id}>
              {value.name}
            </option>
          ))}
        </Input>
      );
    }

    if (name.split('][')[1].toLocaleLowerCase() === 'enroll_online]') {
      const options = [
        { value: 'YES', label: 'Yes' },
        { value: 'NO', label: 'No' },
      ];
      cell = (
        <Input type="select" defaultValue={value} name={name}>
          {options.map((a: any, index: number) => {
            return (
              <option value={a.value} key={`enroll_online-option-${index}`}>
                {a.label}
              </option>
            );
          })}
        </Input>
      );
    }

    if (name.split('][')[1].toLocaleLowerCase() === 'age_steps]') {
      const options = [
        { value: 'WEEKS', label: 'Weeks' },
        { value: 'MONTHS', label: 'Months' },
        { value: 'YEARS', label: 'Years' },
      ];
      cell = (
        <Input type="select" defaultValue={value} name={name}>
          {options.map((a: any, index: number) => {
            return (
              <option value={a.value} key={`age_steps-option-${index}`}>
                {a.label}
              </option>
            );
          })}
        </Input>
      );
    }

    if (name.split('][')[1].toLocaleLowerCase() === 'person_online]') {
      const options = [
        { value: 'PERSON', label: 'Person' },
        { value: 'ONLINE', label: 'Online' },
      ];
      cell = (
        <Input type="select" defaultValue={value} name={name}>
          {options.map((a: any, index: number) => {
            return (
              <option value={a.value} key={`person_online-option-${index}`}>
                {a.label}
              </option>
            );
          })}
        </Input>
      );
    }

    if (name.split('][')[1].toLocaleLowerCase() === 'class_type]') {
      const options = [
        { value: 'FT-Full Time', label: 'Full Time' },
        { value: 'PT-2 Days (T, TH)', label: '2 Days (T, TH)' },
        { value: 'PT-3 Days (M, W, F)', label: '3 Days (M, W, F)' },
        { value: 'VPK-VPK Only', label: 'VPK Only' },
        { value: 'SA-After School', label: 'After School' },
        { value: 'SA-Before School', label: 'Before School' },
        { value: 'SA-Before and After School', label: 'Before and After School' },
        { value: 'SA-Camp', label: 'Camp' },
      ];
      const selected = options.find((item) => item.label.toLocaleLowerCase() === value.toLocaleLowerCase());
      cell = (
        <Input type="select" defaultValue={selected?.value} name={name}>
          {options.map((a: any, index: number) => {
            return (
              <option value={a.value} key={`class_type-option-${index}`}>
                {a.label}
              </option>
            );
          })}
        </Input>
      );
    }

    if (name.split('][')[1].toLocaleLowerCase() === 'class]') {
      const selected = classesList.find(
        (item) => String(item.name).toLocaleLowerCase() === String(value).toLocaleLowerCase(),
      );
      cell = (
        <Input type="select" defaultValue={selected?.id || ''} name={name}>
          <option value={''}></option>
          {classesList.map((value) => (
            <option key={value.id} value={value.id}>
              {value.name}
            </option>
          ))}
        </Input>
      );
    }

    if (csvType === 'student' && name.split('][')[1].toLocaleLowerCase() === 'status]') {
      const selected = validEnrollmentStatus.find(
        (item) => item.label.toLocaleLowerCase() === value.toLocaleLowerCase(),
      );
      cell = (
        <Input type="select" defaultValue={selected?.value} name={name}>
          <option value={''}></option>
          {validEnrollmentStatus.map((a: any, index: number) => {
            return (
              <option value={a.value} key={`enrollment-status-${index}`}>
                {a.label}
              </option>
            );
          })}
        </Input>
      );
    }

    if (csvType === 'student' && name.split('][')[1].toLocaleLowerCase() === 'gender]') {
      cell = (
        <Input type="select" defaultValue={value} name={name}>
          <option value="BOY" key={`filter-button-boy`}>
            Boy
          </option>
          <option value="GIRL" key={`filter-button-girl`}>
            Girl
          </option>
          <option value="UNKNOWN" key={`filter-button-unknown`}>
            Unknown
          </option>
        </Input>
      );
    }

    if (name.split('][')[1].toLocaleLowerCase() === 'student]') {
      const selected = studentsList.find(
        (item) =>
          String(`${item.firstName} ${item.lastName}`).toLocaleLowerCase() === String(value).toLocaleLowerCase().trim(),
      );
      cell = (
        <Input type="select" defaultValue={selected?.id || ''} name={name}>
          <option value={''}></option>
          {studentsList.map((value) => (
            <option key={value.id} value={value.id}>
              {`${value.firstName} ${value.lastName}`}
            </option>
          ))}
        </Input>
      );

      // cell = (
      //   <SearchSelectWithThumb
      //     searchService={async (filter) => {
      //       filter.options.itemsPerPage = 100;
      //       const r = await Kids(token).list(filter);
      //       return r;
      //     }}
      //     onChange={(e) => console.log(e)}
      //     defaultValue={{ name: value }}
      //     name={name}
      //   />
      // );
    }
    return cell;
  };

  const getCollumnsFromImportedLine = () => {
    const importedRow: any[] = [];
    Object.keys(importedLines[0]).forEach((key: any) => {
      if (key === 'id') {
        return true;
      }
      if (key === 'state') {
        if (importedLines?.[0]?.[key] !== undefined) {
          const state = statesList?.find((s) => s?.uf === importedLines[0]?.[key]);
          importedLines[0][key] = state?.id;
        }
      }
      importedRow.push({
        name: key.replaceAll('_', ' '),
        cell: (row: any, rowI: number) => {
          return getFieldByImportedConfig(row[key], `importedLine[${rowI}][${key.toLowerCase()}]`);
        },
        grow: 1,
      });
    });

    return importedRow;
  };

  useEffect(() => {
    if (!importedLines.length) {
      return;
    }
    setImportStep(2);
  }, [importedLines]);

  useEffect(() => {
    loadTotals();
    loadStates();
  }, []);

  const getimportErrorsKeyMessage = (key: string) => {
    const keyPre = key.split('.');
    const fieldMsg = keyPre[2] ? ` Field ${keyPre[2]}` : '';
    return `Line ${parseInt(keyPre[1]) + 1}` + fieldMsg;
  };

  return (
    <>
      <CommonHeader />
      <Container className="mt--7 " fluid>
        <Row>
          <div className="d-flex flex-wrap w-100 mb-4">
            <Col>
              <CardInfo title="schools" class="bg-success" count={totals.school} icon="fa-building" />
            </Col>
            <Col>
              <CardInfo title="staff" class="bg-yellow" count={totals.staff} icon="fa-user" />
            </Col>
            <Col>
              <CardInfo title="classes" class="bg-primary" count={totals.class} icon="fa-chalkboard-teacher" />
            </Col>
            <Col>
              <CardInfo title="students" class="bg-success" count={totals.student} icon="fa-baby" />
            </Col>
            <Col>
              <CardInfo title="parents" class="bg-danger" count={totals.parent} icon="fa-users" />
            </Col>
          </div>
        </Row>

        <Row>
          <Col className="order-xl-2" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="12">
                    <h3 className="mb-0">
                      <i className="fas fa-upload" />
                      &nbsp;
                      {`${pageTitle}`}
                    </h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmit} id="formPrincipal">
                  {importStep === 1 && (
                    <div className="pl-lg-4">
                      {/* <SchoolSelect cacheKey={cacheKey} value="GVEjjD" name={'dsadsadas'} />
                      <SchoolSelect cacheKey={cacheKey} value="GVEjjD" name={'dsadsadas'} />
                      <SchoolSelect cacheKey={cacheKey} value="GVEjjD" name={'dsadsadas'} />
                      <SchoolSelect cacheKey={cacheKey} value="GVEjjD" name={'dsadsadas'} />
                      <SchoolSelect cacheKey={cacheKey} value="GVEjjD" name={'dsadsadas'} /> */}
                      <Row>
                        <Col>
                          <FormGroup>
                            <label className="form-control-label">CSV Type</label>
                            <Input type="select" onChange={(e) => setCsvType(e.target.value)} value={csvType} required>
                              {Object.keys(totals).map((k) => {
                                return (
                                  <option key={k} value={k}>
                                    {ucfisrt(k)}
                                  </option>
                                );
                              })}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <label className="form-control-label" htmlFor="token-kiosk">
                              CSV File <small>(.csv)</small>
                            </label>

                            <input
                              id="img-picture"
                              name="picture"
                              type="file"
                              accept=".csv"
                              style={{ display: 'none' }}
                              ref={imageRef}
                              disabled={isDisabled}
                              onChange={onChangeFile}
                            />
                            <InputGroup>
                              <Input
                                className="form-control-alternative"
                                placeholder="No file selected"
                                type="text"
                                value={importFile?.name || ''}
                                disabled={true}
                              />
                              <InputGroupAddon addonType="append">
                                <Button color="success" onClick={showOpenFileDialog}>
                                  Upload
                                </Button>
                              </InputGroupAddon>
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="col offset-9">
                          <p>Templates</p>
                          <ul style={{ listStyle: 'none', padding: '0' }}>
                            <li>
                              <Link to="/files-examples/school.xlsx" target="_blank">
                                <i className="fa fa-file-csv"></i>&nbsp;School
                              </Link>
                            </li>
                            <li>
                              <Link to="/files-examples/staff.xlsx" target="_blank">
                                <i className="fa fa-file-csv"></i>&nbsp;Staff
                              </Link>
                            </li>
                            <li>
                              <Link to="/files-examples/class.xlsx" target="_blank">
                                <i className="fa fa-file-csv"></i>&nbsp;Class
                              </Link>
                            </li>
                            <li>
                              <Link to="/files-examples/classPricesheet.xlsx" target="_blank">
                                <i className="fa fa-file-csv"></i>&nbsp;Class Price Sheet
                              </Link>
                            </li>
                            <li>
                              <Link to="/files-examples/student.xlsx" target="_blank">
                                <i className="fa fa-file-csv"></i>&nbsp;Student
                              </Link>
                            </li>
                            <li>
                              <Link to="/files-examples/parents.xlsx" target="_blank">
                                <i className="fa fa-file-csv"></i>&nbsp;Parent
                              </Link>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </div>
                  )}
                  {importStep === 2 && (
                    <>
                      {Object.keys(importErrors).length !== 0 && (
                        <Card style={{ backgroundColor: '#F6CED8' }}>
                          <CardBody>
                            <CardText>
                              <Row>
                                <Col>
                                  <h4 className="alert-heading">Errors in import process</h4>
                                  <ul>
                                    {Object.keys(importErrors).map((k: string) => (
                                      <li>
                                        <b>{getimportErrorsKeyMessage(k)}</b>:{' '}
                                        {(importErrors as Record<string, string | undefined>)[k]}
                                      </li>
                                    ))}
                                  </ul>
                                </Col>
                              </Row>
                            </CardText>
                          </CardBody>
                        </Card>
                      )}
                      <DataTable
                        title="imported Lines"
                        columns={getCollumnsFromImportedLine()}
                        data={importedLines}
                        loading={loading}
                        button={false}
                        search={false}
                        totalRows={importedLines.length}
                        pagination={false}
                        conditionalRowStyles={[
                          {
                            when: (row: any) => {
                              const indexWithError = Object.keys(importErrors).map((item: any) => {
                                const items = item.split('.');
                                return (parseInt(items[1]) + 1).toString();
                              });
                              return indexWithError.indexOf(row.id) !== -1;
                            },
                            style: {
                              backgroundColor: 'rgb(246, 206, 216)',
                              // color: 'white',
                            },
                          },
                        ]}
                      />
                    </>
                  )}
                  {importStep === 3 && (
                    <>
                      <Row>
                        <Col>
                          <div className="alert alert-success" role="alert">
                            <h4 className="alert-heading">Data imported successfully!</h4>
                            <p>In a few minutes they will be in the system.</p>
                          </div>
                        </Col>
                      </Row>
                    </>
                  )}
                  <Row>
                    <Col>
                      <Button
                        className="my-4"
                        color="primary"
                        type="button"
                        disabled={importStep === 1}
                        onClick={() => clickPrev(importStep)}
                      >
                        Prev
                      </Button>
                    </Col>
                    {importStep < 3 && (
                      <Col className="text-right">
                        <Button className="my-4" color="primary" type="submit" disabled={isDisabled}>
                          Next
                        </Button>
                      </Col>
                    )}
                    {importStep === 3 && (
                      <Col className="text-right">
                        <Button className="my-4" color="primary" onClick={() => window.location.reload()}>
                          Return
                        </Button>
                      </Col>
                    )}
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Index;
