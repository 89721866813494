import { Container, Spinner } from 'reactstrap';

const OpacityLoaderSpinner = () => {
  return (
    <>
      <Container className="loader-wrapper" fluid>
        <Spinner />
      </Container>
    </>
  );
};

export default OpacityLoaderSpinner;
