import http from '../helpers/http';

const Timeline = (token: string) => {
  const headers = {
    'x-access-token': token,
  };

  return {
    show: async (kidsId: string, params?: any) => {
      const result = await http.get(`/timeline/${kidsId}`, {
        headers,
        params: params || {},
      });
      return result.data;
    },
    showByStudentProfile: async (kidsId: string, options?: string) => {
      const result = await http.get(`/timeline/student-profile/${kidsId}${options}`, {
        headers,
      });
      return result.data;
    },
    downloadAttach: async (attachId: string) => {
      const result = await http.get(`/timeline/student-profile/download/${attachId}`, {
        headers,
      });
      return result.data;
    },
    showPreCheckins: async (kidsId: string, params?: any) => {
      const result = await http.get(`/timeline/student-profile/pre-checkin/${kidsId}`, {
        headers,
        params: params || {},
      });
      return result.data;
    },
  };
};

export default Timeline;
