import { useContext, useEffect, useState } from 'react';
import { Button, Col, FormGroup, Input, InputGroup, InputGroupAddon, Label, Modal, Row } from 'reactstrap';
import NumberFormat from 'react-number-format';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import TransactionService from '../../../services/Transaction';
import { validTransactionTypes, validManualAccountingTypes } from '../../../interfaces/TransactionsInterface';
import { toast } from 'react-toastify';

type BillingManualPaymentModalProps = {
  parents: any[];
  kidsList: any[];
  isOpen: boolean;
  toggleModal: (e?: any) => void;
  onChange: (e?: any, amount?: number) => void;
};

const BillingManualPaymentModal = ({
  parents,
  kidsList,
  isOpen,
  toggleModal,
  onChange,
}: BillingManualPaymentModalProps) => {
  const { token }: any = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState('');
  const [description, setDescription] = useState('');
  const [amount, setAmount] = useState(0);
  const [accountingType, setAccountingType] = useState('');

  const [kidsPercents, setKidsPercent] = useState([] as { id: string; name: string; percentage: number }[]);

  useEffect(() => {
    const percents = [];
    for (const k of kidsList) {
      percents.push({ id: k.id, name: `${k.firstName || ''} ${k.lastName || ''}`, percentage: 0 });
    }
    setKidsPercent(percents);
  }, [isOpen]);

  const handleRequest = async (e: any) => {
    if (loading) return;
    e.preventDefault();

    const percentage = kidsPercents.reduce(
      (previousValue: number, k: any) => Number(previousValue) + Number(k?.percentage || 0),
      0,
    );
    if (percentage !== 100) {
      toast.error('The sum of the percentages must be 100!');
      setLoading(false);
      return;
    }

    setLoading(true);
    try {
      await TransactionService(token).createPayment({
        kidsPercents,
        userId: userId,
        transactionType: validTransactionTypes.MANUAL,
        amount,
        description,
        accountingType,
      });
      onManualModalChange(e);
      setLoading(false);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'Request error');
      setLoading(false);
    }
  };

  const toggleManualModal = (e: any) => {
    e.preventDefault();
    toggleModal(e);
    setDescription('');
    setAmount(0);
  };

  const onManualModalChange = (e: any) => {
    onChange(e);
    e.preventDefault();
    setDescription('');
    setAmount(0);
  };

  const onKidsPercentChange = (id: string, floatValue: number) => {
    const newKidsPercent = JSON.parse(JSON.stringify(kidsPercents));
    for (const k of newKidsPercent) {
      if (k.id === id) {
        k.percentage = floatValue;
      }
    }
    setKidsPercent(newKidsPercent);
  };

  return (
    <>
      <Modal className="modal-dialog-centered" size="xl" isOpen={isOpen} toggle={(e: any) => toggleManualModal(e)}>
        <div className="modal-header pb-1">
          <h5 className="modal-title">Manual Payment</h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={(e) => toggleManualModal(e)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body pt-0">
          <Row className="mt-2">
            <Col lg="8">
              <FormGroup>
                <label className="form-control-label">Parent</label>
                <Input type="select" onChange={(e) => setUserId(e.target.value)} required>
                  <option value="">Please select</option>
                  {parents
                    .filter((item: any) => item.isPaying === true)
                    .map((p: any, index: number) => {
                      return (
                        <option value={p.userId} key={`parent-${index}`}>
                          {`${p?.user?.firstName || ''} ${p?.user?.lastName || ''}`}
                        </option>
                      );
                    })}
                </Input>
              </FormGroup>
            </Col>
            <Col xl="4">
              <FormGroup>
                <label className="form-control-label">Amount</label>
                <Input
                  type="text"
                  thousandsGroupStyle="thousand"
                  prefix="$"
                  decimalSeparator="."
                  displayType="input"
                  thousandSeparator={true}
                  allowNegative={false}
                  decimalScale={2}
                  tag={NumberFormat}
                  value={amount}
                  onValueChange={(e: any) => setAmount(e.floatValue)}
                />
              </FormGroup>
            </Col>
            <Col lg="12">
              <FormGroup>
                <label className="form-control-label">Description</label>
                <Input
                  className="form-control-alternative"
                  placeholder="Please, insert a description."
                  type="text"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </FormGroup>
            </Col>
            {kidsPercents.map((k: any, index: number) => (
              <Col lg="3" key={`child-${index}`}>
                <FormGroup>
                  <label className="form-control-label">{`${k?.name} `}</label>

                  <InputGroup>
                    <Input
                      type="text"
                      thousandsGroupStyle="thousand"
                      suffix="%"
                      decimalSeparator="."
                      displayType="input"
                      thousandSeparator={true}
                      allowNegative={false}
                      decimalScale={2}
                      tag={NumberFormat}
                      value={k?.percentage}
                      onValueChange={(e: any) => onKidsPercentChange(k.id, e.floatValue)}
                    />

                    <InputGroupAddon addonType="append">
                      <Button color="primary" disabled={true}>
                        ${!amount || !k?.percentage ? '0.00' : Number((k?.percentage / 100) * amount).toFixed(2)}
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Col>
            ))}
            <Col lg="12">
              <label className="form-control-label">Type</label>
            </Col>
            {validManualAccountingTypes.map((s: any, index: number) => (
              <Col lg="2" key={`code-input-${index}`}>
                <FormGroup check>
                  <Label check>
                    <Input
                      name="accountingType"
                      type="radio"
                      value={s.value}
                      onChange={(e) => setAccountingType(e.target.value)}
                    />{' '}
                    {s.label}
                  </Label>
                </FormGroup>
              </Col>
            ))}
            {/* <Col lg="4">
              <FormGroup>
                <label className="form-control-label">Accounting Code</label>
                <Input type="select" onChange={(e) => setAccountingType(e.target.value)} required>
                  <option value="">Please select</option>
                  {validManualAccountingTypes.map((a: any, index: number) => {
                    return (
                      <option value={a.value} key={`accounting-${index}`}>
                        {a.label}
                      </option>
                    );
                  })}
                </Input>
              </FormGroup>
            </Col> */}
          </Row>
          <hr />
          <Row>
            <Col md="6 text-center">
              <Button color="secondary" size="md" style={{ minWidth: '100px' }} onClick={(e) => toggleManualModal(e)}>
                Cancel
              </Button>
            </Col>
            <Col md="6 text-center">
              <Button color="success" size="md" style={{ minWidth: '100px' }} onClick={(e) => handleRequest(e)}>
                Save
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default BillingManualPaymentModal;
