import { Card } from 'reactstrap';

type PaginationProps = {
  data: any[];
  meta: any;
  onChangeRowsPerPage: (perPage: any) => Promise<void>;
  onChangePage: (page: number) => Promise<void>;
};

const Pagination = ({ data, meta, onChangeRowsPerPage, onChangePage }: PaginationProps) => {
  const customStyles: any = {
    table: {
      style: {},
    },
    header: {
      style: {
        borderRadius: 'calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0',
        minHeight: '65px',
        fontSize: '1.0625rem',
        fontWeight: '900',
        lineHeight: '1.5',
      },
    },
    headCells: {
      style: {
        paddingLeft: '1.5rem',
        paddingRight: '1.5rem',
        background: '#F6F9FC',
        color: '#8898aa',
        fontSize: '0.65rem',
        paddingTop: '0.75rem',
        paddingBottom: '0.75rem',
        textTransform: 'uppercase',
        letterSpacing: '1px',
        verticalAlign: 'middle',
        fontWeight: '600',
      },
    },
    rows: {
      style: {
        minHeight: '65px',
        fontSize: '0.8125rem',
        whiteSpace: 'nowrap',
        verticalAlign: 'middle',
        color: '#525f7f',
      },
    },
    cells: {
      style: {
        paddingLeft: '1.5rem',
        paddingRight: '1.5rem',
        fontSize: '0.8125rem',
      },
    },
  };

  return (
    <>
      {data.length ? (
        <Card className="shadow" style={customStyles}>
          <nav
            className="sc-eeDRCY kqWIVd rdt_Pagination pagination"
            style={{
              display: 'flex',
              flex: '1 1 auto',
              WebkitBoxPack: 'end',
              justifyContent: 'end',
              WebkitBoxAlign: 'center',
              alignItems: 'center',
              boxSizing: 'border-box',
              paddingRight: '8px',
              paddingLeft: '8px',
              width: '100%',
              color: 'rgba(0,0,0,0.54)',
              fontSize: '13px',
              minHeight: '56px',
              backgroundColor: '#fff',
              borderTopStyle: 'solid',
              borderTopWidth: '1px',
              borderTopColor: 'rgba(0,0,0,.12)',
            }}
          >
            <span className="sc-iHGNWf sc-kOHTFB isLfJH hkULGy">Rows per page:</span>
            <div className="sc-jsJBEP dvSlcPage">
              <select
                aria-label="Rows per page:"
                className="sc-bXCLTC eVrcSZ"
                onChange={(e) => {
                  onChangeRowsPerPage(e.currentTarget.value);
                }}
              >
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value="25">25</option>
                <option value="30">30</option>
              </select>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path d="M7 10l5 5 5-5z"></path>
                <path d="M0 0h24v24H0z" fill="none"></path>
              </svg>
            </div>
            <span className="sc-iHGNWf sc-dtBdUo isLfJH kjHanM">
              {1 + (meta.currentPage - 1) * meta.perPage}-
              {meta.currentPage < meta.totalPages ? meta.perPage * meta.currentPage : meta.total} of {meta.total}
            </span>
            <div className="sc-bmzYkS controlls">
              <button
                id="pagination-first-page"
                type="button"
                aria-label="First Page"
                aria-disabled="true"
                className="sc-koXPp actionButtons"
                onClick={() => onChangePage(1)}
                disabled={!meta.hasPrevPage}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  role="presentation"
                >
                  <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path>
                  <path fill="none" d="M24 24H0V0h24v24z"></path>
                </svg>
              </button>
              <button
                id="pagination-previous-page"
                type="button"
                aria-label="Previous Page"
                aria-disabled="true"
                className="sc-koXPp actionButtons"
                onClick={() => onChangePage(meta.prevPage)}
                disabled={!meta.hasPrevPage}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  role="presentation"
                >
                  <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                  <path d="M0 0h24v24H0z" fill="none"></path>
                </svg>
              </button>
              <button
                id="pagination-next-page"
                type="button"
                aria-label="Next Page"
                aria-disabled="true"
                className="sc-koXPp actionButtons"
                onClick={() => onChangePage(meta.nextPage)}
                disabled={!meta.hasNextPage}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  role="presentation"
                >
                  <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                  <path d="M0 0h24v24H0z" fill="none"></path>
                </svg>
              </button>
              <button
                id="pagination-last-page"
                type="button"
                aria-label="Last Page"
                aria-disabled="true"
                className="sc-koXPp actionButtons"
                onClick={() => onChangePage(meta.totalPages)}
                disabled={!meta.hasNextPage}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  role="presentation"
                >
                  <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path>
                  <path fill="none" d="M0 0h24v24H0V0z"></path>
                </svg>
              </button>
            </div>
          </nav>
        </Card>
      ) : (
        <></>
      )}
    </>
  );
};

export default Pagination;
