import DataTableComponent from 'react-data-table-component';
import { Card } from 'reactstrap';
import DataTableProgress from './DataTableProgress';

type DataTableProps = {
  title: string;
  columns: any[];
  data: any[];
  loading: boolean;
  button?: boolean;
  buttonUrl?: string;
  settingsButton?: boolean;
  filtersList?: any[];
  onChangeRowsPerPage: (perPage: number) => Promise<void>;
  onChangePage: (page: number) => Promise<void>;
  onChangeFilter?: (filter: string) => void;
  onClickSettings?: () => void;
};

const DataTableWithoutHeaderAndPagination = ({
  columns,
  data,
  loading,
  onChangeRowsPerPage,
  onChangePage,
}: DataTableProps) => {
  const customStyles: any = {
    table: {
      style: {},
    },
    header: {
      style: {
        borderRadius: 'calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0',
        minHeight: '65px',
        fontSize: '1.0625rem',
        fontWeight: '900',
        lineHeight: '1.5',
      },
    },
    headCells: {
      style: {
        paddingLeft: '1.5rem',
        paddingRight: '1.5rem',
        background: '#F6F9FC',
        color: '#8898aa',
        fontSize: '0.65rem',
        paddingTop: '0.75rem',
        paddingBottom: '0.75rem',
        textTransform: 'uppercase',
        letterSpacing: '1px',
        verticalAlign: 'middle',
        fontWeight: '600',
      },
    },
    rows: {
      style: {
        minHeight: '65px',
        fontSize: '0.8125rem',
        whiteSpace: 'nowrap',
        verticalAlign: 'middle',
        color: '#525f7f',
      },
    },
    cells: {
      style: {
        paddingLeft: '1.5rem',
        paddingRight: '1.5rem',
        fontSize: '0.8125rem',
      },
    },
  };

  return (
    <>
      <Card className="shadow">
        <DataTableComponent
          customStyles={customStyles}
          columns={columns}
          data={data}
          progressPending={loading}
          progressComponent={<DataTableProgress />}
          onChangeRowsPerPage={onChangeRowsPerPage}
          onChangePage={onChangePage}
        />
      </Card>
    </>
  );
};

export default DataTableWithoutHeaderAndPagination;
