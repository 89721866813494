import { Row, Col, FormGroup, Input } from 'reactstrap';
import { useState, useEffect } from 'react';

type ClassIpadAccessFormData = {
  id?: string;
  username: string;
  password: string;
};
type ClassIpadAccessListProps = {
  classId?: string;
  data: ClassIpadAccessFormData;
  setData: any;
};

const ClassIpadAccess = ({ data, setData }: ClassIpadAccessListProps) => {
  const [formData, setFormData] = useState<ClassIpadAccessFormData>({ id: '', username: '', password: '' });

  const handleSetFormData = (key: string, value: string) => {
    const newData = { ...formData, ...{ [key]: value } };
    setFormData(newData);
    setData(newData);
  };

  useEffect(() => {
    setFormData(data);
  }, [data]);

  return (
    <>
      <hr className="my-4" />
      <h6 className="heading-small text-muted mb-4">User Access</h6>
      <Row style={{ padding: '0 15px' }}>
        <Col md="4">
          <FormGroup className="mb-1">
            <label className="form-control-label">Username</label>
            <Input
              type="text"
              defaultValue={formData.username}
              onChange={(e) => handleSetFormData('username', e.target.value)}
              readOnly={true}
            />
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup className="mb-1">
            <label className="form-control-label">Password</label>
            <Input
              type="text"
              defaultValue={formData.password}
              onChange={(e) => handleSetFormData('password', e.target.value)}
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default ClassIpadAccess;
