import http from '../helpers/http';

const EnrollmentFiles = (token: string) => {
  const headers = {
    'x-access-token': token,
  };

  return {
    list: async (kidId: string) => {
      const result = await http.get(`/enrollment-files/${kidId}`, {
        headers,
      });
      return result.data;
    },
    create: async (body: { typeId: string }, kidId: string) => {
      const result = await http.post(`/enrollment-files/${kidId}`, body, { headers });
      return result.data;
    },
    delete: async (id: string) => {
      const result = await http.delete(`/enrollment-files/${id}`, {
        headers,
      });
      return result.data;
    },
    file: async (id: string, body: FormData) => {
      const headers = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-access-token': token,
        },
      };
      const result = await http.patch(`/enrollment-files/${id}/file`, body, headers);
      return result.data;
    },
  };
};

export default EnrollmentFiles;
