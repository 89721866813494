/* eslint-disable max-len */
/* eslint-disable prettier/prettier */
/* eslint-disable max-lines */
import { useContext, useEffect, useState } from 'react';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { Context as AuthContext } from '../../../../contexts/AuthContext';
import moment from 'moment';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faSpinner } from '@fortawesome/free-solid-svg-icons';
import SchoolService from '../../../../services/School';
import { saveAs } from 'file-saver';
import { font as robotoRegular } from '../../../../assets/fonts/Roboto-Regular';
import { font as robotoMedium } from '../../../../assets/fonts/Roboto-Medium';
import { font as robotoItalic } from '../../../../assets/fonts/Roboto-Italic';
import { font as robotoMediumItalic } from '../../../../assets/fonts/Roboto-MediumItalic';
import { font as dancingScript } from '../../../../assets/fonts/DancingScript';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.vfs['Roboto-Regular.ttf'] = robotoRegular;
pdfMake.vfs['Roboto-Medium.ttf'] = robotoMedium;
pdfMake.vfs['Roboto-Italic.ttf'] = robotoItalic;
pdfMake.vfs['Roboto-MediumItalic.ttf'] = robotoMediumItalic;
pdfMake.vfs['DancingScript.ttf'] = dancingScript;

const WriteUpPDF = ({ data }: any) => {
  const { token, currentSchool }: any = useContext(AuthContext);
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);
  const [school, setSchool] = useState<any>();
  // TODO comment to fix lint
  // const [schoolAddress, setSchoolAddress] = useState<any>();
  // const startDate = moment(data.kids?.birthday);
  // const finalDate = moment();
  const [, setSchoolAddress] = useState<any>();

  const length = data.length;

  const listSelectSchool = async () => {
    const result = await SchoolService(token).show(currentSchool.value);
    const resultAddress = await SchoolService(token).showAddress(currentSchool.value);
    setSchool(result.data || []);
    setSchoolAddress(resultAddress.data || []);
  };

  useEffect(() => {
    listSelectSchool();
  }, []);

  const generatePDF = async () => {
    try {
      setIsGeneratingPDF(true);
      const imageUrl = school?.franchisee?.imageUrl;
      const response = await fetch(imageUrl);
      const imageBlob = await response.blob();
      const reader = new FileReader();

      reader.onloadend = () => {
        const imageDataUrl = reader.result as string;

        const result = data.map((data: any, index: number) => {
          return [
            {
              columns: [
                {
                  stack: [{ text: 'PERFORMANCE IMPROVEMENT DOCUMENT', style: 'header' }],
                  alignment: 'left',
                  margin: [0, 15, 0, 15],
                },
                {
                  image: imageDataUrl,
                  width: 100,
                  height: 40,
                  alignment: 'right',
                  margin: [0, 0, 0, 15],
                },
              ],
            },
            {
              table: {
                widths: ['*', '*'],
                margin: [0, 10, 0, 10],
                body: [
                  [
                    {
                      margin: [5, 5, 5, 5],
                      text: [{ text: 'Name ', bold: true }, { text: `${data.user.firstName} ${data.user.lastName}` }],
                    },
                    {
                      margin: [5, 5, 5, 5],
                      text: [{ text: 'Date of Incident ' }, { text: moment(data.incidentDate).format('M/D/YYYY') }],
                    },
                  ],
                  [
                    {
                      margin: [5, 5, 5, 5],
                      text: [{ text: 'Position ', bold: true }, { text: data.user.employee.franchisee.name }],
                    },
                    {
                      margin: [5, 5, 5, 5],
                      text: [{ text: 'Department ', bold: true }, { text: data.user.employee.department.name }],
                    },
                  ],
                  [
                    { margin: [5, 5, 5, 5], text: [{ text: 'Supervisor ', bold: true }, { text: '' }] },
                    {
                      margin: [5, 5, 5, 5],
                      text: [
                        { text: 'Date of Hire ', bold: true },
                        { text: moment(data.user.employee.startDate).format('M/D/YYYY') },
                      ],
                    },
                  ],
                ],
              },
            },
            {
              margin: [0, 15, 0, 5],
              columns: [
                {
                  width: 'auto',
                  text: 'Prior Verbal Discussion of Written Warning',
                  noWrap: true,
                  bold: true,
                },
              ],
            },
            {
              margin: [0, 0, 0, 5],
              columns: [
                {
                  width: 'auto',
                  text: 'List dates & type of informal discussion or warning',
                  noWrap: true,
                  fontSize: 11,
                },
              ],
            },
            {
              margin: [0, 0, 0, 10],
              columns: [
                {
                  width: 'auto',
                  text: data.writtenWarning,
                  fontSize: 11,
                },
              ],
            },
            {
              canvas: [
                {
                  type: 'line',
                  x1: 0,
                  y1: 0,
                  x2: 500,
                  y2: 0,
                  lineWidth: 1,
                  lineColor: 'black',
                },
              ],
            },
            {
              margin: [0, 10, 0, 5],
              columns: [
                {
                  width: 'auto',
                  text: 'Policy Violation',
                  noWrap: true,
                  bold: true,
                },
              ],
            },
            {
              margin: [0, 0, 0, 10],
              columns: [
                {
                  width: 'auto',
                  ul: data.policyViolation.split(',').map((value: string) => {
                    return { text: value, color: '#de0000', fontSize: 11, margin: [0, 2, 0, 2] };
                  }),
                  fontSize: 11,
                },
              ],
            },
            {
              canvas: [
                {
                  type: 'line',
                  x1: 0,
                  y1: 0,
                  x2: 500,
                  y2: 0,
                  lineWidth: 1,
                  lineColor: 'black',
                },
              ],
            },
            {
              margin: [0, 10, 0, 5],
              columns: [
                {
                  width: 'auto',
                  text: 'Description of Incident',
                  noWrap: true,
                  bold: true,
                },
              ],
            },
            {
              margin: [0, 0, 0, 10],
              columns: [
                {
                  width: 'auto',
                  text: data.incidentDescription,
                  fontSize: 11,
                },
              ],
            },
            {
              canvas: [
                {
                  type: 'line',
                  x1: 0,
                  y1: 0,
                  x2: 500,
                  y2: 0,
                  lineWidth: 1,
                  lineColor: 'black',
                },
              ],
            },
            {
              margin: [0, 10, 0, 5],
              columns: [
                {
                  width: 'auto',
                  text: 'Action Taken',
                  noWrap: true,
                  bold: true,
                },
              ],
            },
            {
              margin: [0, 0, 0, 10],
              columns: [
                {
                  width: 'auto',
                  text: data.actionTaken,
                  fontSize: 11,
                },
              ],
            },
            {
              canvas: [
                {
                  type: 'line',
                  x1: 0,
                  y1: 0,
                  x2: 500,
                  y2: 0,
                  lineWidth: 1,
                  lineColor: 'black',
                },
              ],
            },
            data.isComunicated && {
              margin: [0, 10, 0, 10],
              columns: [
                {
                  width: 'auto',
                  text: 'Communicated',
                  fontSize: 12,
                  bold: true,
                  margin: [0, 0, 0, 0],
                },
                {
                  stack: [
                    {
                      canvas: [
                        {
                          type: 'rect',
                          x: 0,
                          y: 0,
                          w: 20,
                          h: 10,
                          lineWidth: 1,
                          lineColor: 'black',
                        },
                        {
                          type: 'line',
                          x1: 8,
                          y1: 3,
                          x2: 12,
                          y2: 8,
                          lineWidth: 1,
                          lineColor: data.isComunicated === true ? 'black' : 'white',
                        },
                        {
                          type: 'line',
                          x1: 12,
                          y1: 3,
                          x2: 8,
                          y2: 8,
                          lineWidth: 1,
                          lineColor: data.isComunicated === true ? 'black' : 'white',
                        },
                      ],
                    },
                  ],
                  width: 'auto',
                  margin: [5, 2, 0, 0],
                  alignment: 'center',
                },
                {
                  width: 'auto',
                  text: 'Yes',
                  alignment: 'center',
                  fontSize: 10,
                  margin: [3, 1, 0, 0],
                  color: '#6d6e70',
                },
              ],
            },
            {
              canvas: [
                {
                  type: 'line',
                  x1: 0,
                  y1: 0,
                  x2: 500,
                  y2: 0,
                  lineWidth: 1,
                  lineColor: 'black',
                },
              ],
            },
            data.improvementPlan && {
              margin: [0, 10, 0, 5],
              columns: [
                {
                  width: 'auto',
                  text: 'Improvement Plan',
                  noWrap: true,
                  bold: true,
                },
              ],
            },
            data.improvementPlan && {
              margin: [0, 0, 0, 10],
              columns: [
                {
                  width: 'auto',
                  text: data.improvementPlan,
                  fontSize: 11,
                  pageBreak: index < length - 1 ? 'after' : null,
                },
              ],
            },
            data.trainig && {
              margin: [0, 10, 0, 5],
              columns: [
                {
                  width: 'auto',
                  text: 'Trainig to be Completed',
                  noWrap: true,
                  bold: true,
                },
              ],
            },
            data.trainig && {
              margin: [0, 0, 0, 10],
              columns: [
                {
                  width: 'auto',
                  text: data.trainig,
                  fontSize: 11,
                },
              ],
            },
            data.trainig && {
              canvas: [
                {
                  type: 'line',
                  x1: 0,
                  y1: 0,
                  x2: 500,
                  y2: 0,
                  lineWidth: 1,
                  lineColor: 'black',
                },
              ],
            },
            data.meetingDate && {
              margin: [0, 25, 0, 25],
              columns: [
                {
                  width: 'auto',
                  text: `We'll have a follow up meeting on ${moment(data.meetingDate).format('M/D/YYYY')}`,
                  noWrap: true,
                  bold: true,
                  color: '#001d75',
                  fontSize: 14,
                },
              ],
            },

            data.employeeSignature && {
              widths: ['*', '*'],
              margin: [0, 10, 0, 20],
              columns: [
                {
                  text: `I understand that employment with Amazing Explorers Academy is at-will. I also undestand that failure to maintain or sustain acceptable levels of performancem behavior or conduct may result in further action, up to and incluing separation of employment. Signing this plan does not indicate agreement, but only receipt. I also understand I may attach written comments about this manner..`,
                  fontSize: 10,
                  color: '#6d6e70',
                },
              ],
            },
            data.employeeSignature && {
              margin: [0, 5, 0, 0],
              columns: [
                { text: 'Employee Signature', alignment: 'left' },
                { text: 'Director Signature', alignment: 'left' },
              ],
            },
            data.employeeSignature && {
              margin: [0, 0, 0, 0],
              columns: [
                { text: data.employeeSignature, font: 'DancingScript', alignment: 'left' },
                { text: `| ${data.employeeSignature}`, fontSize: 10, alignment: 'left' },
                { text: data.directorSignature, font: 'DancingScript', alignment: 'left' },
                { text: ` | ${data.directorSignature}`, fontSize: 10, alignment: 'left' },
              ],
            },
            data.employeeSignature && {
              margin: [0, 0, 0, 0],
              columns: [
                {
                  text: `Date: ${moment(data.employeeSignatureDate).format('MM/DD/YY')}/${moment(
                    data.employeeSignatureDate,
                  ).format('h:mm A')}`,
                  alignment: 'left',
                  fontSize: 10,
                },
                {
                  text: `Date: ${moment(data.directorSignatureDate).format('MM/DD/YY')}/${moment(
                    data.directorSignatureDate,
                  ).format('h:mm A')}`,
                  alignment: 'left',
                  fontSize: 10,
                  pageBreak: index < length - 1 ? 'after' : null,
                },
              ],
            },
          ];
        });

        const pdfBody = result.reduce((acc: any, val: any) => acc.concat(val), []);
        pdfMake.fonts = {
          Roboto: {
            normal: 'Roboto-Regular.ttf',
            bold: 'Roboto-Medium.ttf',
            italics: 'Roboto-Italic.ttf',
            bolditalics: 'Roboto-MediumItalic.ttf',
          },
          DancingScript: {
            normal: 'DancingScript.ttf',
            bold: 'DancingScript.ttf',
            italics: 'DancingScript.ttf',
            bolditalics: 'DancingScript.ttf',
          },
        };
        const documentDefinition: any = {
          content: pdfBody,
          styles: {
            header: {
              fontSize: 16,
              color: '#395dab',
            },
            underlineHeader: {
              fontSize: 16,
              decoration: 'underline',
              color: '#395dab',
            },
            title: {
              fontSize: 14,
              color: '#6d6e70',
            },
          },
        };

        const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
        pdfDocGenerator.getBlob((blob) => {
          saveAs(blob, 'accident_report.pdf');
          setIsGeneratingPDF(false);
        });
      };
      reader.readAsDataURL(imageBlob);
    } catch (error) {
      console.error('Erro ao obter a imagem:', error);
    }
  };

  return (
    <Button size="sm" color="primary" onClick={generatePDF} disabled={isGeneratingPDF}>
      <FontAwesomeIcon icon={!isGeneratingPDF ? faDownload : faSpinner} spin={isGeneratingPDF} />
    </Button>
  );
};

export default WriteUpPDF;
