import { useContext, useState, useEffect } from 'react';
// import { toast } from 'react-toastify';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import { FilteredOptionsInterface } from '../../../entities/FilteredOptions';
import { PagedMetaInterface, createPagedMeta } from '../../../entities/PagedMeta';
import { createOptionsPage } from '../../../entities/OptionsPaged';
import DataTable from '../../../components/Core/DataTableWithoutHeader';
import TaxDocumentsService from '../../../services/TaxDocuments';
import FileDownload from 'js-file-download';

const BillingFilesList = () => {
  const { token, currentSchool, user } = useContext(AuthContext);

  const columns = [
    {
      name: 'Description',
      selector: (row: any) => <span>{`${row?.description || '-'}`}</span>,
      grow: 2,
    },
    {
      name: 'URL',
      selector: (row: any) => (
        <a href={`${row?.url || '#'}`} onClick={(e) => getPDF(e, row.year)}>
          Download
        </a>
      ),
      grow: 2,
    },
  ];

  const [data, setData] = useState([] as any[]);
  const [loading, setLoading] = useState(false);
  const [pagedMeta, setPagedMeta] = useState(createPagedMeta());
  const [filteredOptions, setFilteredOptions] = useState({
    filters: [
      {
        field: 'userId',
        operation: 'hash',
        value: user.id,
      },
    ],
    options: createOptionsPage(pagedMeta),
  });

  useEffect(() => {
    loadList(filteredOptions);
  }, [filteredOptions, currentSchool]);

  const getPDF = async (e: React.FormEvent, id: string) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);

    try {
      const response = await TaxDocumentsService(token || '').getPDF(id);
      FileDownload(response, 'tax-document-' + id + '.pdf');
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Report error!');
      console.error(message);
    } finally {
      setLoading(false);
    }
  };

  const loadList = async (filteredOptions: FilteredOptionsInterface) => {
    try {
      setLoading(true);
      const response = await TaxDocumentsService(token || '').list(filteredOptions);
      setData(response.data);
      const meta: PagedMetaInterface = {
        currentPage: 1,
        perPage: 10,
        total: 0,
        totalPages: 0,
        hasPrevPage: false,
        hasNextPage: false,
        nextPage: '1',
        prevPage: '1',
      };
      if (meta) {
        setPagedMeta(meta);
      }
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Error fetching list');
      console.error(message);
    } finally {
      setLoading(false);
    }
  };

  const handlePerRowsChange = async (perPage: number) => {
    const newOptions = {
      ...pagedMeta,
      perPage,
    };
    const newFilteredOptions = {
      filters: filteredOptions.filters,
      options: createOptionsPage(newOptions),
    };
    setFilteredOptions(newFilteredOptions);
  };

  const handlePageChange = async (page: number) => {
    const newOptions = {
      ...pagedMeta,
      currentPage: page,
    };
    const newFilteredOptions = {
      filters: filteredOptions.filters,
      options: createOptionsPage(newOptions),
    };
    setFilteredOptions(newFilteredOptions);
  };

  return (
    <>
      <DataTable
        title=""
        columns={columns}
        data={data}
        totalRows={pagedMeta.total}
        loading={loading}
        button={false}
        buttonUrl=""
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
      />
    </>
  );
};

export default BillingFilesList;
