import { Container, Button, Row, Col, Card, CardBody, Form, FormGroup, InputGroup, Modal } from 'reactstrap';
import ReactDatetime from 'react-datetime';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import { useContext, useState } from 'react';
import ReportsService from '../../../services/Reports';
import * as XLSX from 'xlsx';
import moment from 'moment';

interface ChildNotUpdatedModalProps {
  isOpen: boolean;
  toggleModal: () => void;
}

export function ChildNotUpdatedModal({ isOpen, toggleModal }: ChildNotUpdatedModalProps) {
  const [initialDate, setInitialDate] = useState(moment().isoWeekday(1).format('MM/DD/yyyy'));
  const [endDate, setEndDate] = useState(moment().isoWeekday(1).add(4, 'days').format('MM/DD/yyyy'));

  const { token, currentSchool }: any = useContext(AuthContext);

  const handleExportTimecard = async () => {
    const idSchool = currentSchool.value;
    const { data } = await ReportsService(token).ChildNotUpdated(initialDate, endDate, idSchool);

    const ws = XLSX.utils.json_to_sheet(data.responseTab1);
    const ws2 = XLSX.utils.json_to_sheet(data.responseTab2);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Tab 1');
    XLSX.utils.book_append_sheet(wb, ws2, 'Tab 2');

    XLSX.writeFile(wb, 'Child Not Updated Report.xlsx');

    toggleModal();
  };

  return (
    <Modal className="modal-dialog-centered" size="lg" isOpen={isOpen} toggle={() => toggleModal()}>
      <Container fluid style={{ minWidth: '500px' }}>
        <Card>
          <CardBody>
            <Form onSubmit={handleExportTimecard}>
              <div className="pl-lg-0">
                <Row>
                  <Col lg="4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-validAt">
                        Initial Date
                      </label>
                      <InputGroup className="input-group-alternative">
                        <ReactDatetime
                          inputProps={{
                            placeholder: 'Start Date',
                          }}
                          onChange={(newValue) => {
                            setInitialDate(moment(newValue).format('MM/DD/yyyy'));
                          }}
                          value={initialDate}
                          timeFormat={false}
                          dateFormat={'MM/DD/yyyy'}
                          closeOnSelect={true}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>

                  <Col lg="4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-validAt">
                        End Date
                      </label>
                      <InputGroup className="input-group-alternative">
                        <ReactDatetime
                          inputProps={{
                            placeholder: 'End Date',
                          }}
                          onChange={(newValue) => {
                            setEndDate(moment(newValue).format('MM/DD/yyyy'));
                          }}
                          value={endDate}
                          timeFormat={false}
                          dateFormat={'MM/DD/yyyy'}
                          closeOnSelect={true}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md="12" className="d-flex justify-content-center">
                    <Button
                      color="primary"
                      type="submit"
                      href="#"
                      onClick={() => {
                        handleExportTimecard();
                      }}
                      size="lg"
                      style={{ minWidth: '200px' }}
                    >
                      Download
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Container>
    </Modal>
  );
}
