import CommonHeader from '../components/Headers/CommonHeader';
import AdminDashboard from '../components/Dashboards/AdminDashboard';
import ParentDashboard from '../components/Dashboards/ParentDashboard';
import StaffDashboard from '../components/Dashboards/StaffDashboard';
import ClassroomDashboard from '../components/Dashboards/ClassroomDashboard';

import isAdmin from '../helpers/isAdmin';
import { useContext, useState } from 'react';

import { Context as AuthContext } from '../contexts/AuthContext';
import isParent from '../helpers/isParent';
import isStaff from '../helpers/isStaff';
import isClassroom from '../helpers/isClassroom';
import isTeacher from '../helpers/isTeacher';
import ChangePasswordModal from './pages/Users/ChangePasswordModal';
import isDirector from '../helpers/isDirector';
import isEducationDirectorAdminAssistant from '../helpers/isEducationDirectorAdminAssistant';
import isUpperManagment from '../helpers/isUpperManagment';
import isFranchiseeManagment from '../helpers/isFranchiseeManagment';

const Home = (_: any) => {
  const { user, getProfile }: any = useContext(AuthContext);
  const [passwordModal, setPasswordModal] = useState(user?.passwordReset || false);
  return (
    <>
      {!isClassroom(user) && <CommonHeader />}
      {(isDirector(user) ||
        isAdmin(user) ||
        isEducationDirectorAdminAssistant(user) ||
        isUpperManagment(user) ||
        isFranchiseeManagment(user)) && <AdminDashboard />}
      {isParent(user) && <ParentDashboard currentUser={user} />}
      {(isStaff(user) || isTeacher(user)) && <StaffDashboard currentUser={user} />}
      {isClassroom(user) && <ClassroomDashboard currentUser={user} />}
      <ChangePasswordModal
        userId={user?.id}
        isOpen={passwordModal}
        isAdmin={false}
        toggleModal={() => {}}
        notClose={true}
        submitModal={() => {
          getProfile();
          setPasswordModal(!passwordModal);
        }}
      />
    </>
  );
};

export default Home;
