/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import { Input, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import DataTable from '../../../components/Core/DataTable';

interface EmplooyeTableProps {
  employee: EmployeeProps;
  setSelected: (value: any) => void;
}

export interface EmployeeProps {
  employeeIncative: Array<any>;
  employeeActive: Array<any>;
}

export const EmplooyeTable = ({ employee, setSelected }: EmplooyeTableProps) => {
  const [activeTab, setActiveTab] = useState('1');
  const [allCheckedActive, setAllCheckedActive] = useState(false);
  const [allCheckedInactive, setAllCheckedInactive] = useState(false);
  const employeeActive = employee.employeeActive;
  const employeeInactive = employee.employeeIncative;
  const [selectedActive, setSelectedActive] = useState(employeeActive);
  const [selectedInactive, setSelectedInactive] = useState<any[]>([]);

  const customStyles = {
    navLink: {
      minHeight: '42px',
      paddingTop: '8px',
      cursor: 'pointer',
    },
  };

  useEffect(() => {
    const newSelect = selectedActive.concat(selectedInactive);
    setSelected(newSelect);
  }, [selectedActive, selectedInactive]);

  const columnsOut = [
    {
      name: (
        <Input
          type="checkbox"
          className="mt-n1 ml-2"
          onChange={() => handleSelecOrDiselectAllEmployeeActive()}
          checked={allCheckedActive}
        />
      ),
      selector: (row: any) => (
        <Input
          type="checkbox"
          className="mt-n1 ml-2"
          checked={!!selectedActive.find((employee: any) => employee.id === row.id)}
          onChange={() => selectEmployeeActiveById(row.id)}
        />
      ),
      grow: 1,
    },
    {
      name: 'Name',
      route: '/users',
      label: (row: any) => `${row.user.firstName} ${row.user.lastName}`,
      grow: 2,
    },
    {
      name: 'Title',
      selector: (row: any) => `${row.title.name}`,
      grow: 2,
    },
    {
      name: 'Deparment',
      selector: (row: any) => `${row.department.name}`,
      grow: 2,
    },
  ];
  const columnsIn = [
    {
      name: (
        <Input
          type="checkbox"
          className="mt-n1 ml-2"
          onChange={() => handleSelecOrDiselectAllEmployeeInactive()}
          checked={allCheckedInactive}
        />
      ),
      selector: (row: any) => (
        <Input
          type="checkbox"
          className="mt-n1 ml-2"
          checked={!!selectedInactive.find((employee: any) => employee.id === row.id)}
          onChange={() => selectEmployeeInactiveById(row.id)}
        />
      ),
      grow: 1,
    },
    {
      name: 'Name',
      route: '/users',
      label: (row: any) => `${row.user.firstName} ${row.user.lastName}`,
      grow: 2,
    },
    {
      name: 'Title',
      selector: (row: any) => `${row.title.name}`,
      grow: 2,
    },
    {
      name: 'Deparment',
      selector: (row: any) => `${row.department.name}`,
      grow: 2,
    },
  ];

  const selectEmployeeActiveById = (id: string) => {
    const newSelected = JSON.parse(JSON.stringify(selectedActive));
    if (newSelected.find((employee: any) => employee?.id === id)) {
      return setSelectedActive(newSelected.filter((employee: any) => employee.id !== id));
    }
    const userActive = employee.employeeActive.find((employee) => employee.id === id);

    if (userActive) {
      return setSelectedActive([...newSelected, userActive]);
    }
  };

  const selectEmployeeInactiveById = (id: string) => {
    const newSelected = JSON.parse(JSON.stringify(selectedInactive));
    if (newSelected.find((employee: any) => employee?.id === id)) {
      return setSelectedInactive(newSelected.filter((employee: any) => employee.id !== id));
    }

    const userInactive = employee.employeeIncative.find((employee) => employee.id === id);

    return setSelectedInactive([...newSelected, userInactive]);
  };

  const handleSelecOrDiselectAllEmployeeActive = () => {
    if (allCheckedActive) {
      setSelectedActive([]);
      setAllCheckedActive(false);
    } else {
      setSelectedActive(employeeActive);
      setAllCheckedActive(true);
    }
  };

  const handleSelecOrDiselectAllEmployeeInactive = () => {
    if (allCheckedInactive) {
      setSelectedInactive([]);
      setAllCheckedInactive(false);
    } else {
      setSelectedInactive(employeeInactive);
      setAllCheckedInactive(true);
    }
  };

  useEffect(() => {
    if (selectedActive?.length !== employeeActive?.length) {
      setAllCheckedActive(false);
    }

    if (selectedActive?.length === employeeActive?.length) {
      setAllCheckedActive(true);
    }

    if (selectedInactive?.length !== employeeInactive?.length) {
      setAllCheckedInactive(false);
    }

    if (selectedInactive?.length === employeeInactive?.length) {
      setAllCheckedInactive(true);
    }
  }, [selectedActive, selectedInactive]);
  return (
    <>
      <Row>
        <Col xs="12">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={`${activeTab === '1' ? 'active' : ''}`}
                onClick={() => {
                  setActiveTab('1');
                  setSelected([]);
                }}
                style={customStyles.navLink}
              >
                Employed
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={`${activeTab === '2' ? 'active' : ''}`}
                onClick={() => {
                  setActiveTab('2');
                  setSelected([]);
                }}
                style={customStyles.navLink}
              >
                Terminated
              </NavLink>
            </NavItem>
          </Nav>
        </Col>
        <Col xs="12">
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <DataTable
                header={false}
                title=""
                columns={columnsOut}
                data={employeeActive}
                totalRows={employeeActive.length}
                button={false}
                buttonUrl=""
                onChangeRowsPerPage={(() => {}) as any}
                onChangePage={(() => {}) as any}
              />
            </TabPane>
            <TabPane tabId="2">
              <DataTable
                header={false}
                title=""
                columns={columnsIn}
                data={employeeInactive}
                totalRows={employeeInactive.length}
                button={false}
                buttonUrl=""
                onChangeRowsPerPage={(() => {}) as any}
                onChangePage={(() => {}) as any}
              />
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </>
  );
};
