/* eslint-disable max-lines */
import { Button, FormGroup, Form, Input, Row, Col, InputGroupAddon, InputGroup, Modal } from 'reactstrap';
import { toast } from 'react-toastify';
import { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { Context as AuthContext } from '../../../../contexts/AuthContext';
import UserService from '../../../../services/User';
import { toLocaleFormat, toSystemFormat } from '../../../../helpers/dateFormat';
import confirmAlert from '../../../../helpers/confirmAlert';
import isAdmin from '../../../../helpers/isAdmin';
import isParent from '../../../../helpers/isParent';
import isStaff from '../../../../helpers/isStaff';
import PermissionGroupsService from '../../../../services/PermissionGroups';
import UploadAndCropImage from '../../../../components/Core/UploadAndCropImage';

const ProfileTab = ({ refresh }: { refresh?: () => void }) => {
  const { id } = useParams<{ id: string }>();
  const { token, getProfile, user }: any = useContext(AuthContext);
  const isParentUser = isParent(user);
  const isUserAdmin = isAdmin(user);
  const isStaffUser = isStaff(user);
  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [workPhone, setHomePhone] = useState('');
  const [birthday, setBirthday] = useState('');
  const [pin, setPin] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [zipCodeWork, setZipCodeWork] = useState('');
  const [addressWork, setAddressWork] = useState('');
  const [cityWork, setCityWork] = useState('');
  const [countryWork, setCountryWork] = useState('');
  const [picture, setPicture] = useState<any>();
  const [cameraAccess, setCameraAccess] = useState(false);
  const [canDownloadPhotos, setCanDownloadPhotos] = useState(true);
  const [workAddress, setWorkAddress] = useState(false);
  const [isUserHimself, setIsUserHimself] = useState(false);
  const [pictureUrl, setPictureUrl] = useState('');

  const [userRoleList, setUserRoleList] = useState([{}]);
  const [userRole, setUserRole] = useState('');
  const [modalPicture, setModalPicture] = useState(false);

  useEffect(() => {
    getById();
    listUserRoleGroups();
  }, []);

  useEffect(() => {
    getById();
  }, [id]);

  const getById = async () => {
    setIsDisabled(true);
    try {
      setIsUserHimself(id === user.id);
      const result = await UserService(token).show(id);
      if (result?.data) {
        const user = result?.data;
        setFirstName(user?.firstName || '');
        setLastName(user?.lastName || '');
        setEmail(user?.email || '');
        setPhone(user?.phone || '');
        setHomePhone(user?.workPhone || '');
        setBirthday(user?.birthday ? toLocaleFormat(user?.birthday) : '');
        setPin(user?.pin || '');
        setPictureUrl(user?.picture || '');
        setUserRole(result?.data?.groupId || '');
        // const employee = result?.data?.employee;
        const configs = result?.data?.configs;
        setCameraAccess(configs?.canAccessCameras != undefined ? configs?.canAccessCameras : false);
        setCanDownloadPhotos(configs?.canDownloadPhotos != undefined ? configs?.canDownloadPhotos : true);
        const address = user?.addresses?.[0] || {};
        setZipCode(address?.zipCode || '');
        setAddress(address?.address || '');
        setCity(address?.city || '');
        setCountry(address?.country || '');
        const addressWork = user?.addressesWork?.[0] || {};
        setZipCodeWork(addressWork?.zipCode || '');
        setAddressWork(addressWork?.address || '');
        setCityWork(addressWork?.city || '');
        setCountryWork(addressWork?.country || '');
      }
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'Invalid user ID');
    } finally {
      setIsDisabled(false);
    }
  };

  const showOpenFileDialog = () => {
    if (isDisabled) {
      return;
    }
    setModalPicture(!modalPicture);
  };

  const onChangePicture = (blob: Blob, imageUrl: string) => {
    setPicture(blob);
    setPictureUrl(imageUrl);
    setModalPicture(!modalPicture);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);
    setIsDisabled(true);

    const body = {
      firstName,
      lastName,
      birthday: birthday ? toSystemFormat(birthday) : '',
      phone,
      workPhone,
      groupId: userRole,
      addresses: [
        {
          zipCode,
          address,
          city,
          country,
          stateId: 1,
        },
      ],
      addressesWork: [
        {
          zipCode: zipCodeWork,
          address: addressWork,
          city: cityWork,
          country: countryWork,
          stateId: 1,
        },
      ],
      configs: {
        canDownloadPhotos,
        canAccessCameras: cameraAccess,
      },
    };

    try {
      await UserService(token).update(id, body);
      if (picture) {
        const formData = new FormData();
        formData.append('picture', picture as any);
        await UserService(token).picture(id, formData);
      }
      await getProfile();
      setPicture({});
      toast.success('Updated successfully!');
      if (refresh) {
        refresh();
      }
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Update error!');
      toast.error(message);
    } finally {
      setIsDisabled(false);
    }

    setLoading(false);
  };

  const changePin = async (e: React.FormEvent) => {
    e.preventDefault();
    return confirmAlert({
      title: 'Change PIN',
      messageType: 'EDIT',
      message: 'Are you sure to change user PIN?',
      onClickYes: handlePin,
    });
  };

  const handlePin = async () => {
    if (loading) return;

    setLoading(true);
    setIsDisabled(true);

    try {
      await UserService(token).pin({ userId: id });
      await getProfile();
      await getById();
      toast.success('Updated successfully!');
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Update error!');
      toast.error(message);
    } finally {
      setIsDisabled(false);
    }

    setLoading(false);
  };

  const handleWorkAddress = (value: boolean) => {
    setWorkAddress((value) => !value);
    if (value) {
      setAddressWork(address);
      setCityWork(city);
      setCountryWork(country);
      setZipCodeWork(zipCode);
    }

    if (!value) {
      setAddressWork('');
      setCityWork('');
      setCountryWork('');
      setZipCodeWork('');
    }
  };

  const listUserRoleGroups = async () => {
    try {
      const result = await PermissionGroupsService(token).list();

      setUserRoleList(result || []);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No user roles groups found');
    }
  };

  return (
    <>
      <Modal className="modal-dialog-centered" isOpen={modalPicture} toggle={() => setModalPicture(!modalPicture)}>
        <div className="modal-header">
          <h5 className="modal-title">Change Avatar Picture</h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setModalPicture(!modalPicture)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body pt-0">
          <UploadAndCropImage image={pictureUrl} onChange={onChangePicture} />
        </div>
      </Modal>
      <Form onSubmit={handleSubmit}>
        {/* <div className="pl-lg-4">
          <Row>
            <Col lg="6" xs="12">
              <FormGroup style={isMobile ? { textAlign: 'center' } : {}}>
                <input
                  id="img-picture"
                  name="picture"
                  type="file"
                  accept=".png,.jpg,.jpeg"
                  style={{ display: 'none' }}
                  ref={imageRef}
                  disabled={isDisabled}
                  onChange={onChangeFile}
                />
                <img
                  alt={`${firstName}`}
                  className="rounded-circle"
                  src={pictureUrl ? `${pictureUrl}` : require('../../../../assets/img/user-default.png').default}
                  style={{
                    width: 135,
                    height: 135,
                    objectFit: 'cover',
                    cursor: isDisabled ? 'not-allowed' : 'pointer',
                  }}
                  onClick={showOpenFileDialog}
                />
              </FormGroup>
            </Col>
          </Row>
        </div> */}
        {/* <hr className="my-4" /> */}
        <h6 className="heading-small text-muted mb-2 mt-4">User information</h6>
        <div className="pl-lg-4">
          <Row>
            <Col lg="12">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-first-name">
                  Picture
                </label>
                <br />
                <Button size="sm" color="primary" onClick={showOpenFileDialog}>
                  Change Picture
                </Button>
                <span>{picture?.name || ''}</span>
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-first-name">
                  First name
                </label>
                <Input
                  className="form-control-alternative"
                  id="input-first-name"
                  placeholder="First name"
                  type="text"
                  disabled={isParentUser || isDisabled}
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-last-name">
                  Last name
                </label>
                <Input
                  className="form-control-alternative"
                  id="input-last-name"
                  placeholder="Last name"
                  type="text"
                  disabled={isParentUser || isDisabled}
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label">User Role</label>
                <Input
                  type="select"
                  onChange={(e) => setUserRole(e.target.value)}
                  value={userRole}
                  required
                  disabled={!isUserAdmin}
                >
                  <option value=""></option>
                  {userRoleList?.map((filter: any, index: number) => {
                    return (
                      <option value={filter.id} key={`user-role-${index}`}>
                        {filter.name}
                      </option>
                    );
                  })}
                </Input>
              </FormGroup>
            </Col>

            <Col lg="6">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-email">
                  Email address
                </label>
                <Input
                  className="form-control-alternative"
                  id="input-email"
                  placeholder="Email address"
                  type="email"
                  disabled={true}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label">Mobile Phone</label>
                <Input
                  className="form-control-alternative"
                  placeholder="Phone"
                  type="text"
                  mask="(999) 999-9999"
                  tag={InputMask}
                  disabled={isDisabled}
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label">Work Phone</label>
                <Input
                  className="form-control-alternative"
                  placeholder="Work Phone"
                  type="text"
                  mask="(999) 999-9999"
                  tag={InputMask}
                  disabled={isDisabled}
                  value={workPhone}
                  onChange={(e) => setHomePhone(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-birthday">
                  Birthday
                </label>
                <Input
                  className="form-control-alternative"
                  id="input-birthday"
                  placeholder="Birthday"
                  type="text"
                  mask="99/99/9999"
                  tag={InputMask}
                  disabled={isDisabled}
                  value={birthday}
                  onChange={(e) => setBirthday(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label">PIN</label>
                <InputGroup>
                  <Input
                    className="form-control-alternative"
                    type="text"
                    disabled={true}
                    value={pin}
                    onChange={(e) => setPin(e.target.value)}
                  />
                  {isAdmin(user) && (
                    <InputGroupAddon addonType="append">
                      <Button color="success" onClick={changePin}>
                        Change
                      </Button>
                    </InputGroupAddon>
                  )}
                </InputGroup>
              </FormGroup>
            </Col>
            {!isUserHimself && !isParentUser && (
              <Col lg="6">
                <FormGroup>
                  <Row>
                    <Col>
                      <label className="form-control-label">Camera Access</label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label className="custom-toggle">
                        <Input
                          type="checkbox"
                          onChange={(e) => setCameraAccess(e.target.checked)}
                          checked={cameraAccess}
                        >
                          <option className="custom-toggle-slider rounded-circle" />
                        </Input>
                        <span className="custom-toggle-slider rounded-circle" />
                      </label>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
            )}
            {!isUserHimself && !isParentUser && (
              <Col lg="6">
                <FormGroup>
                  <Row>
                    <Col>
                      <label className="form-control-label">Can Download Photos</label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label className="custom-toggle">
                        <Input
                          type="checkbox"
                          onChange={(e) => setCanDownloadPhotos(e.target.checked)}
                          checked={canDownloadPhotos}
                        >
                          <option className="custom-toggle-slider rounded-circle" />
                        </Input>
                        <span className="custom-toggle-slider rounded-circle" />
                      </label>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
            )}
          </Row>
        </div>
        <hr className="my-4" />
        <h6 className="heading-small text-muted mb-4">Home Address</h6>
        <div className="pl-lg-4">
          <Row>
            <Col md="12">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-address">
                  Address
                </label>
                <Input
                  className="form-control-alternative"
                  id="input-address"
                  placeholder="Address"
                  type="text"
                  disabled={isDisabled}
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="4">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-city">
                  City
                </label>
                <Input
                  className="form-control-alternative"
                  id="input-city"
                  placeholder="City"
                  type="text"
                  disabled={isDisabled}
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg="4">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-country">
                  Country
                </label>
                <Input
                  className="form-control-alternative"
                  id="input-country"
                  placeholder="Country"
                  type="text"
                  disabled={isDisabled}
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg="4">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-country">
                  Postal code
                </label>
                <Input
                  className="form-control-alternative"
                  id="input-postal-code"
                  placeholder="Postal code"
                  type="number"
                  disabled={isDisabled}
                  value={zipCode}
                  onChange={(e) => setZipCode(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
        {!isStaffUser && (
          <>
            <hr className="my-4" />
            <h6 className="heading-small text-muted mb-4">Work address</h6>
            <div className="pl-lg-4">
              <Row>
                <Col>
                  <label className="form-control-label">Same as work address</label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <label className="custom-toggle">
                    <Input type="checkbox" onChange={(e) => handleWorkAddress(e.target.checked)} checked={workAddress}>
                      <option className="custom-toggle-slider rounded-circle" />
                    </Input>
                    <span className="custom-toggle-slider rounded-circle" />
                  </label>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-address-work">
                      Address
                    </label>
                    <Input
                      className="form-control-alternative"
                      id="input-address-work"
                      placeholder="Address"
                      type="text"
                      disabled={isDisabled}
                      value={addressWork}
                      onChange={(e) => setAddressWork(e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="4">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-city-work">
                      City
                    </label>
                    <Input
                      className="form-control-alternative"
                      id="input-city-work"
                      placeholder="City"
                      type="text"
                      disabled={isDisabled}
                      value={cityWork}
                      onChange={(e) => setCityWork(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col lg="4">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-country-work">
                      Country
                    </label>
                    <Input
                      className="form-control-alternative"
                      id="input-country-work"
                      placeholder="Country"
                      type="text"
                      disabled={isDisabled}
                      value={countryWork}
                      onChange={(e) => setCountryWork(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col lg="4">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-country-work">
                      Postal code
                    </label>
                    <Input
                      className="form-control-alternative"
                      id="input-postal-code-work"
                      placeholder="Postal code"
                      type="number"
                      disabled={isDisabled}
                      value={zipCodeWork}
                      onChange={(e) => setZipCodeWork(e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </>
        )}

        <Row>
          <div className="pl-lg-4">
            <Button className="my-4" color="primary" type="submit" disabled={isDisabled}>
              Save
            </Button>
          </div>
        </Row>
      </Form>
    </>
  );
};

export default ProfileTab;
