import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { HashLink as Link } from 'react-router-hash-link';
import KidsService from '../../../services/Kids';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import history from '../../../history';
import BillingSummary from './BillingSummary';
import BillingCreditModal from './BillingCreditModal';
import BillingChargeModal from './BillingChargeModal';
import { isMobile } from 'react-device-detect';

const BillingKids = ({
  parents,
  kidsList,
  reload,
  isAdmin,
}: {
  parents: any[];
  kidsList: any[];
  reload: () => void;
  isAdmin: boolean;
}) => {
  const isTablet = window.innerWidth <= 1112 && window.innerWidth > 768 && isMobile;
  const { token }: any = useContext(AuthContext);
  const [currentKids, setCurrentKids] = useState('');
  const [creditModal, setCreditModal] = useState(false);
  const [chargeModal, setChargeModal] = useState(false);

  const handleBillingTurnedOn = async (kidsId: string, turnedOn: boolean) => {
    if (!kidsId) return;
    try {
      await KidsService(token).billingTurnedOn(kidsId, turnedOn);
      toast.success('Billing settings updated successfully');
      reload();
    } catch (e: any) {
      console.error(e?.response?.data?.message || 'Billing turned on error');
    }
  };

  const handleAutochargeTurnedOn = async (kidsId: string, turnedOn: boolean) => {
    if (!kidsId) return;
    try {
      await KidsService(token).autochargeTurnedOn(kidsId, turnedOn);
      toast.success('Billing settings updated successfully');
      reload();
    } catch (e: any) {
      console.error(e?.response?.data?.message || 'Autocharge turned on error');
    }
  };

  const toggleCreditModal = (e: any) => {
    e.preventDefault();
    if (!creditModal) {
      setCurrentKids('');
    }
    setCreditModal(!creditModal);
  };

  const creditModalChange = (e?: any) => {
    if (e) {
      e.preventDefault();
    }
    reload();
    toast.success('Credit created successfully');
    setCreditModal(!creditModal);
  };

  const toggleChargeModal = (e: any) => {
    e.preventDefault();
    if (!chargeModal) {
      setCurrentKids('');
    }
    setChargeModal(!chargeModal);
  };

  const chargeModalChange = (e?: any) => {
    if (e) {
      e.preventDefault();
    }
    reload();
    toast.success('Charge created successfully');
    setChargeModal(!chargeModal);
  };

  const getMaxDiscount = (kids: any) => {
    if (
      kids?.discountsKids?.length &&
      kids?.discountsKids?.[0]?.discount?.percentage >= kids?.promoCode?.discountTuition
    ) {
      return kids?.billingSettings?.amount;
    }
    return kids?.promoCode?.discountTuition || 0;
  };

  return (
    <Card className="bg-secondary shadow mb-2">
      <CardBody className="bg-white border-0">
        <Row className={isMobile ? 'mb-1' : 'mb-4'}>
          <Col xs="12" className="mb-2">
            <h3 className="mb-0">Kids</h3>
          </Col>
        </Row>
        {kidsList.map((kids, i) => (
          <Card className="bg-secondary shadow mb-2" key={`kids-${i}`}>
            <CardBody className="border-0">
              <Row>
                <Col lg="2" xl="2" xs="2">
                  <img
                    alt={`${kids?.firstName || ''} ${kids?.lastName || ''}`}
                    className="rounded-circle mt-4"
                    src={kids?.picture || require('../../../assets/img/user-default.png').default}
                    style={
                      isMobile
                        ? { width: 90, height: 90, cursor: 'default' }
                        : { width: 135, height: 135, cursor: 'default' }
                    }
                  />
                  {isAdmin && isMobile && !isTablet && (
                    <UncontrolledDropdown
                      style={{
                        marginTop: 45,
                        marginLeft: 12,
                      }}
                    >
                      <DropdownToggle
                        href="#"
                        role="button"
                        size="sm"
                        color="primary"
                        onClick={(e) => e.preventDefault()}
                      >
                        Options
                      </DropdownToggle>

                      <DropdownMenu className="dropdown-menu-arrow" right>
                        <DropdownItem
                          onClick={(e) => {
                            e.preventDefault();
                            setCurrentKids(kids?.id || '');
                            setCreditModal(true);
                          }}
                        >
                          Add a Credit
                        </DropdownItem>
                        <DropdownItem
                          onClick={(e) => {
                            e.preventDefault();
                            setCurrentKids(kids?.id || '');
                            setChargeModal(true);
                          }}
                        >
                          Post a Charge
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  )}
                </Col>
                <Col lg="10" xl="10" xs="10">
                  <Row className={`${isMobile ? '' : ''} align-items-center pl-5-media pt-2`}>
                    <Col xs="12" className="mb-3">
                      {isAdmin && (!isMobile || isTablet) && (
                        <UncontrolledDropdown style={{ float: 'right' }}>
                          <DropdownToggle
                            href="#"
                            role="button"
                            size="sm"
                            color="primary"
                            onClick={(e) => e.preventDefault()}
                          >
                            Options
                          </DropdownToggle>

                          <DropdownMenu className="dropdown-menu-arrow" right>
                            <DropdownItem
                              onClick={(e) => {
                                e.preventDefault();
                                setCurrentKids(kids?.id || '');
                                setCreditModal(true);
                              }}
                            >
                              Add a Credit
                            </DropdownItem>
                            <DropdownItem
                              onClick={(e) => {
                                e.preventDefault();
                                setCurrentKids(kids?.id || '');
                                setChargeModal(true);
                              }}
                            >
                              Post a Charge
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      )}

                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          history.push(`/kids/${kids?.id}`);
                        }}
                        style={{ display: 'inline-block', maxWidth: 400 }}
                      >
                        <h3 className="mb-0 text-primary">{`${kids?.firstName || ''} ${kids?.lastName || ''}`}</h3>
                      </a>

                      {kids && (
                        <p className="mt-0 mb-1">Enrolled at {`${kids?.enrollment?.class?.name || '-'}`} - Full Time</p>
                      )}

                      {isAdmin && (
                        <Link className="text-yellow" to={`/kids/${kids?.id}#billing-title`}>
                          <i className="fa fa-calendar"></i> Schedule
                        </Link>
                      )}
                    </Col>
                    {isAdmin && (
                      <>
                        <Col xs="6">
                          <FormGroup>
                            <Row>
                              <Col>
                                <label className="form-control-label">Billing</label>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <label className="custom-toggle">
                                  <Input
                                    type="checkbox"
                                    onChange={(e) => handleBillingTurnedOn(kids?.id, !!e.target.checked)}
                                    checked={!!kids.billingSettings?.billingTurnedOn}
                                  />

                                  <span className="custom-toggle-slider rounded-circle" />
                                </label>
                                {!isMobile && <p>Invoices will be automatically created by the system.</p>}
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                        <Col xs="6">
                          <FormGroup>
                            <Row>
                              <Col>
                                <label className="form-control-label">Auto Charge</label>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <label className="custom-toggle">
                                  <Input
                                    type="checkbox"
                                    onChange={(e) => handleAutochargeTurnedOn(kids?.id, !!e.target.checked)}
                                    checked={!!kids.billingSettings?.autochargeTurnedOn}
                                  />
                                  <span className="custom-toggle-slider rounded-circle" />
                                </label>
                                {!isMobile && (
                                  <p>We will try to charge all created invoice from parent's default payment method.</p>
                                )}
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                      </>
                    )}
                  </Row>
                </Col>
                <Col lg="12" xl="12" style={isMobile ? {} : { marginTop: '15px' }}>
                  <BillingSummary
                    tuitionBase={kids?.billingSettings?.amount || 0}
                    currentDiscount={getMaxDiscount(kids)}
                    billingAmount={kids?.billingSettings?.amount || 0}
                    currentBalance={kids?.balance?.amount || 0}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        ))}
      </CardBody>
      <BillingCreditModal
        kidsId={currentKids}
        isOpen={creditModal}
        toggleModal={toggleCreditModal}
        onChange={creditModalChange}
      />
      <BillingChargeModal
        parents={parents}
        kidsId={currentKids}
        isOpen={chargeModal}
        toggleModal={toggleChargeModal}
        onChange={chargeModalChange}
      />
    </Card>
  );
};

export default BillingKids;
