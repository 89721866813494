import { Button, Row, Col } from 'reactstrap';

type ClassKidsActivityButtonsProps = {
  title: string;
  selectedAll: boolean;
  checkedOutSelected: boolean;
  confirmActivity: (e: any, type: string) => void;
  toggleMovingForm: (e: any) => void;
  toggleActivityForm: (e: any, type: string) => void;
  toggleRemindersForm: (e: any) => void;
  toggleFoodForm: (e: any, type: string) => void;
  toggleSleepForm: (e: any, type: string) => void;
  toggleBathroomForm: (e: any, type: string) => void;
};

const ClassKidsActivityButtonsMobile = ({
  title,
  selectedAll,
  checkedOutSelected,
  confirmActivity,
  toggleMovingForm,
  toggleActivityForm,
  toggleRemindersForm,
  toggleFoodForm,
  toggleSleepForm,
  toggleBathroomForm,
}: ClassKidsActivityButtonsProps) => {
  const stylesCol = { paddingLeft: 5, paddingRight: 5, marginTop: 5 };
  const stylesText = { margin: 0, fontSize: 16 };
  const stylesBtn = { minWidth: 76 };
  return (
    <Row style={{ paddingLeft: 5, paddingRight: 5, marginTop: 5 }}>
      <Col xs="12" className="bg-secondary shadow" style={{ borderRadius: '0.375rem' }}>
        <Row className="align-items-center">
          <Col xs="12" className="pt-2 pb-2">
            <h3 className="mb-0">{title}</h3>
          </Col>
          <Col xs="3" style={stylesCol}>
            <Button
              color="success"
              size="sm"
              style={stylesBtn}
              onClick={(e) => {
                confirmActivity(e, 'CHECKIN');
              }}
              disabled={selectedAll}
            >
              <i className="far fa-arrow-alt-circle-right" style={stylesText}></i>
              <br />
              <span style={{ fontSize: 11 }}>Check In</span>
            </Button>
          </Col>
          <Col xs="3" style={stylesCol}>
            <Button
              color="warning"
              size="sm"
              style={stylesBtn}
              onClick={(e) => toggleMovingForm(e)}
              disabled={selectedAll || checkedOutSelected}
            >
              <i className="fas fa-exchange-alt" style={stylesText}></i>
              <br />
              <span style={{ fontSize: 11 }}>Moving</span>
            </Button>
          </Col>
          <Col xs="3" style={stylesCol}>
            <Button color="info" size="sm" style={stylesBtn} onClick={(e) => toggleActivityForm(e, 'activity')}>
              <i className="fas fa-puzzle-piece" style={stylesText}></i>
              <br />
              <span style={{ fontSize: 11 }}>Activity</span>
            </Button>
          </Col>
          <Col xs="3" style={stylesCol}>
            <Button
              color="outline-warning"
              size="sm"
              style={stylesBtn}
              onClick={(e) => toggleFoodForm(e, 'food')}
              disabled={checkedOutSelected}
            >
              <i className="fas fa-utensils" style={stylesText}></i>
              <br />
              <span style={{ fontSize: 11 }}>Food</span>
            </Button>
          </Col>
          <Col xs="3" style={stylesCol}>
            <Button
              color="outline-primary"
              size="sm"
              style={stylesBtn}
              onClick={(e) => toggleSleepForm(e, 'sleep')}
              disabled={checkedOutSelected}
            >
              <i className="fas fa-bed" style={stylesText}></i>
              <br />
              <span style={{ fontSize: 11 }}>Sleep</span>
            </Button>
          </Col>
          <Col xs="3" style={stylesCol}>
            <Button
              color="outline-success"
              size="sm"
              style={stylesBtn}
              onClick={(e) => toggleBathroomForm(e, 'bathroom')}
              disabled={checkedOutSelected}
            >
              <i className="fas fa-toilet" style={stylesText}></i>
              <br />
              <span style={{ fontSize: 11 }}>Bathroom</span>
            </Button>
          </Col>
          <Col xs="3" style={stylesCol}>
            <Button
              color="outline-danger"
              size="sm"
              style={stylesBtn}
              onClick={(e) => toggleRemindersForm(e)}
              disabled={checkedOutSelected}
            >
              <i className="fas fa-bell" style={stylesText}></i>
              <br />
              <span style={{ fontSize: 11 }}>Reminders</span>
            </Button>
          </Col>
          <Col xs="3" style={stylesCol}>
            <Button
              color="success"
              size="sm"
              style={stylesBtn}
              onClick={(e) => {
                confirmActivity(e, 'CHECKOUT');
              }}
              disabled={selectedAll || checkedOutSelected}
            >
              <i className="far fa-arrow-alt-circle-left" style={stylesText}></i>
              <br />
              <span style={{ fontSize: 11 }}>Check Out</span>
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ClassKidsActivityButtonsMobile;
