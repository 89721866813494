import { Row, Col, Button, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import { useContext, useState, useEffect } from 'react';
import ReactDatetime from 'react-datetime';
import moment, { Moment } from 'moment';
import { toast } from 'react-toastify';
import VacationKidsService from '../../../services/VacationKids';
import confirmAlert from '../../../helpers/confirmAlert';

type VacationTimeKidsListProps = {
  kidsId?: string;
  availableVacationWeeks?: number;
};

const VacationTimeKidsList = ({ kidsId, availableVacationWeeks }: VacationTimeKidsListProps) => {
  const { token }: any = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [vacationKidsList, setVacationKidsList] = useState([]);
  const [showVacationForm, setShowVacationForm] = useState(false);

  const [vacationTime, setvacationTime] = useState('1');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [vacationTimeSelectOptions, setvacationTimeSelectOptions] = useState([1, 2, 3, 4]);
  const [selfAvalVacWeeks, setSelfAvalVacWeeks] = useState(0);

  const validVacationStartDate = (current: Moment) => {
    return current.day() === 1 && current.format('w') > moment().format('w') + 1;
  };

  const handleSubmit = async () => {
    if (loading) return;
    if (!kidsId) return;

    setLoading(true);

    try {
      setShowVacationForm(false);
      setvacationTime('1');
      setStartDate('');
      setEndDate('');

      await VacationKidsService(token).create(
        { vacationTime, startDate: moment(startDate).format('yyyy-MM-DD'), endDate },
        kidsId,
      );
      toast.success(`Saved successfully!`);
      loadVacationsKids();
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Update error!');
      toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  const reloadAvaliableVacationWeeks = async () => {
    if (!kidsId) return;
    const result = await VacationKidsService(token).totalAvaliableVacationWeeks(kidsId);
    setSelfAvalVacWeeks(result.avaliableVacationWeeks);
  };

  const loadVacationsKids = async () => {
    if (!kidsId) return;
    try {
      const result = await VacationKidsService(token).list(kidsId);
      setVacationKidsList(result.data || []);
      reloadAvaliableVacationWeeks();
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No Discounts Found!');
    }
  };

  const deleteItemAlert = async (id: string) => {
    return confirmAlert({ title: 'Delete', messageType: 'DELETE', id, onClickYes: deleteKidsVacation });
  };

  const deleteKidsVacation = async (id: string) => {
    if (loading) return;
    if (!kidsId) return;

    setLoading(true);

    try {
      await VacationKidsService(token).delete(kidsId, id);
      toast.success(`Deleted successfully!`);
      loadVacationsKids();
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Delete error!');
      toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (startDate == '') {
      return;
    }
    const startDateMoment = moment(startDate);
    const endDate = startDateMoment
      .add(parseInt(vacationTime) - 1, 'weeks')
      .add(4, 'days')
      .format('MM/DD/yyyy');
    setEndDate(endDate);
  }, [startDate, vacationTime]);

  useEffect(() => {
    if (selfAvalVacWeeks === undefined) {
      return;
    }
    const possibleWeeks = [];
    for (let i = 1; i <= 1; i++) {
      possibleWeeks.push(i);
    }
    setvacationTimeSelectOptions(possibleWeeks);
  }, [selfAvalVacWeeks]);

  useEffect(() => {
    loadVacationsKids();
    if (availableVacationWeeks !== undefined) {
      setSelfAvalVacWeeks(availableVacationWeeks);
    }
  }, []);

  return (
    <>
      <h6 className="heading-small text-muted mb-4 mt-4">Vacations</h6>
      <Row>
        <Col>
          {vacationKidsList.map((p: any, i) => {
            const currentVacation: any = vacationKidsList[i];
            return (
              <Col key={`vacation-time-${i}`}>
                <Row>
                  <Col lg="2" className="mb-3">
                    <label className="form-control-label">Available Vacation Weeks</label>
                    <Input type="text" value={selfAvalVacWeeks} disabled />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <label className="form-control-label">Vacation Time</label>
                      <Input type="text" displayType="input" value={currentVacation.timeInWeek} disabled />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label className="form-control-label">Start At</label>
                      <Input
                        type="text"
                        displayType="input"
                        value={moment(currentVacation.startDate).format('MM/DD/YYYY')}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label className="form-control-label">End At</label>
                      <Input
                        type="text"
                        displayType="input"
                        value={moment(currentVacation.endDate).format('MM/DD/YYYY')}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    {currentVacation.canDeleted && (
                      <i
                        className="far fa-trash-alt text-danger mt-5"
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => {
                          e.preventDefault();
                          deleteItemAlert(currentVacation.id);
                        }}
                      ></i>
                    )}
                  </Col>
                </Row>
              </Col>
            );
          })}
          <Col>
            <Button
              size="sm"
              onClick={() => {
                setShowVacationForm(true);
              }}
            >
              + Vacation
            </Button>
          </Col>
          {showVacationForm && (
            <Col className="mt-2">
              <Row>
                <Col className="mb-2" lg="4">
                  <FormGroup className="mb-1">
                    <label className="form-control-label">Vacation Time</label>
                    <Input
                      type="select"
                      defaultValue={vacationTime || ''}
                      onChange={(e) => setvacationTime(e.target.value)}
                      required
                    >
                      {vacationTimeSelectOptions.map((a: any, index: number) => {
                        return (
                          <option value={a} key={`discount-${index}`}>
                            {a} Weeks
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </Col>
                <Col className="mb-2" lg="4">
                  <FormGroup>
                    <label className="form-control-label">Start Date</label>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-calendar-grid-58" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <ReactDatetime
                        inputProps={{
                          placeholder: 'Start Date',
                        }}
                        onChange={(newValue) => {
                          setStartDate(`${newValue}`);
                        }}
                        timeFormat={false}
                        value={`${startDate}`}
                        dateFormat={'MM/DD/yyyy'}
                        isValidDate={validVacationStartDate}
                        closeOnSelect={true}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col className="mb-2" lg="4">
                  <FormGroup>
                    <label className="form-control-label">End Date</label>

                    <Input type="text" value={endDate} disabled />
                  </FormGroup>
                </Col>
                <Col lg="12">
                  <Button
                    color="primary"
                    size="sm"
                    style={{ minWidth: '100px' }}
                    onClick={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                  >
                    Add
                  </Button>

                  <Button
                    color="secondary"
                    size="sm"
                    style={{ minWidth: '100px' }}
                    onClick={(e) => {
                      e.preventDefault();
                      setShowVacationForm(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Col>
          )}
        </Col>
      </Row>
    </>
  );
};

export default VacationTimeKidsList;
