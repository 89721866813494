import { v4 as uuidv4 } from 'uuid';

export function generate() {
  return uuidv4();
}

export function validate(uuid: string) {
  const pattern = /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;
  return uuid && pattern.test(uuid);
}

export default {
  validate,
  generate,
};
