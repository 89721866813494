import { Link } from 'react-router-dom';
import BreadcrumbInterface from '../../interfaces/BreadcrumbInterface';

const Breadcrumb = (props: BreadcrumbInterface) => {
  return (
    <nav aria-label="breadcrumb" role="navigation">
      <ol className="breadcrumb bg-transparent font-weight-600">
        <li className="breadcrumb-item mr-2">
          <Link to="/">
            <i className="fas fa-home text-white" />
          </Link>
        </li>
        {props.routes.map((r, index) => {
          return (
            <li key={index} className="breadcrumb-item">
              <Link to={`/${r.route}`}>{r.name}</Link>
            </li>
          );
        })}
        <li className="breadcrumb-item active" aria-current="page">
          {props.title}
        </li>
      </ol>
    </nav>
  );
};

export default Breadcrumb;
