/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-lines */
import { Container, Row, Col, Card, CardBody, CardHeader, Table, Modal, Button } from 'reactstrap';
import CommonHeader from '../../../components/Headers/CommonHeader';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import ReportsService from '../../../services/Reports';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import './styles.scss';
import { useContext } from 'react';
import { TimecardModal } from './timecardModal';
import { useState } from 'react';
import { IncidentReportModal } from './IncidentReportModal';
import { TimerModal } from './TimerModal';
import { ChildNotUpdatedModal } from './ChildNotUpdatedModal';
import { WriteUpModal } from './WriteUpModal';
import { ClassroomOutOfRatio } from './ClassroomOutOfRatio';
import { TeacherTracking } from './TeacherTracking';
import { StatusChangeModal } from './StatusChangeModal';
import { isMobile } from 'react-device-detect';
import isAuthorized from '../../../helpers/isAuthorized';
import isDirector from '../../../helpers/isDirector';
import { toast } from 'react-toastify';
import OpacityLoaderSpinner from '../../../components/Core/OpacityLoaderSpinner';

const Reports = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { token, currentSchool, user }: any = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenIncidentModal, setIsOpenIncidentModal] = useState(false);
  const [isTimerModalOpen, setIsTimerModalOpen] = useState(false);
  const [isRangeModalOpen, setIsRangeModalOpen] = useState(false);
  const [isOpenWriteUpModal, setIsOpenWriteUpModal] = useState(false);
  const [isOpenStatusChangeModal, setIsOpenStatusChangeModal] = useState(false);
  const [isOpenClassroomOutOfRatioModal, setIsOpenClassroomOutOfRatioModal] = useState(false);
  const [isOpenTeacherTrackingModal, setIsOpenTeacherTrackingModal] = useState(false);

  const [pdfSrc, setPdfSrc] = useState('');
  const [modalPdfIsOpen, setModalPdfIsOpen] = useState(false);

  const idSchool = currentSchool.value;
  const exportPdf = isMobile;

  const toggleModal = () => setIsOpen(isOpen ? false : true);
  const toggleModalIncidentModal = () => setIsOpenIncidentModal(isOpenIncidentModal ? false : true);
  const toggleWriteUpModal = () => setIsOpenWriteUpModal(isOpenWriteUpModal ? false : true);
  const toggleStatusChangeModal = () => setIsOpenStatusChangeModal(isOpenStatusChangeModal ? false : true);

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToCSV = (apiData: any, fileName: string) => {
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const exportToPdf = async (report: string, fileName: string, params?: {}) => {
    try {
      setIsLoading(true);
      const response = await ReportsService(token).reportPdf(idSchool, report, params);
      const arr = new Uint8Array(response);
      const blob = new Blob([arr], { type: 'application/pdf' });
      const blobURL = URL.createObjectURL(blob);
      setPdfSrc(blobURL);
      setModalPdfIsOpen(true);
    } catch (e) {
      console.error(e);
      toast.error('Error on loading PDF');
    } finally {
      setIsLoading(false);
    }
  };

  const handleExportWeeklyTuition = async () => {
    const { data } = await ReportsService(token).weeklyTuition(idSchool);

    const ws = XLSX.utils.json_to_sheet(data.currentWeek);
    const ws2 = XLSX.utils.json_to_sheet(data.overall);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Current Week');
    XLSX.utils.book_append_sheet(wb, ws2, 'Overall');

    XLSX.writeFile(wb, 'Weekly Tuition.xlsx');
  };

  const handleExportEmployeeInformation = async () => {
    if (exportPdf) {
      await exportToPdf('employee-information', 'ach-activity');
      return;
    }
    const { data } = await ReportsService(token).employeeInformation(idSchool);
    exportToCSV(data, 'Staff Information');
  };

  const handleExportStaffExpirationDocuments = async () => {
    if (exportPdf) {
      await exportToPdf('staff-expiration-documents', 'ach-activity');
      return;
    }
    const { data } = await ReportsService(token).staffExpirationDocuments(idSchool);
    exportToCSV(data, 'Staff Expiration Documents');
  };

  const handleExportParentChildInformation = async () => {
    const { data } = await ReportsService(token).parentChildInformation(idSchool);
    exportToCSV(data, 'Parent Child Information');
  };

  const handleExportChildrenEnrollDisenrollRate = async () => {
    const { data } = await ReportsService(token).childrenEnrollDisenrollRate(idSchool);
    exportToCSV(data, 'Children Enroll Disenroll Rate');
  };

  const handleExportChildExpirationDocuments = async () => {
    const { data } = await ReportsService(token).childExpirationDocuments(idSchool);
    exportToCSV(data, 'Child Expiration Documents');
  };

  const handleExportEnrolledChildren = async () => {
    const { data } = await ReportsService(token).enrolledChildren(idSchool);
    exportToCSV(data, 'Enrolled Children');
  };

  const handleExportClassRevenues = async () => {
    const { data } = await ReportsService(token).classRevenues(idSchool);
    exportToCSV(data, 'Class Revenues');
  };

  const handleExportClassBreakdown = async () => {
    const { data } = await ReportsService(token).classBreakdown(idSchool);
    exportToCSV(data, 'Class Breakdown');
  };

  const handleExportIncidentReports = async () => {
    const { data } = await ReportsService(token).incidentReports(idSchool);
    exportToCSV(data, 'Incident Reports');
  };

  const readReminderAlert = async () => {
    toggleModal();
  };

  const openIncidentModal = async () => {
    toggleModalIncidentModal();
  };

  const handleTimerModal = async () => {
    setIsTimerModalOpen(isTimerModalOpen ? false : true);
  };

  const handleClassroomOutOfRatioModal = () => {
    setIsOpenClassroomOutOfRatioModal(!isOpenClassroomOutOfRatioModal);
  };

  const handleTeacherTrackingModal = () => {
    setIsOpenTeacherTrackingModal(!isOpenTeacherTrackingModal);
  };

  const handleRangeModal = async () => {
    setIsRangeModalOpen(isRangeModalOpen ? false : true);
  };

  const openWriteUpModal = async () => {
    toggleWriteUpModal();
  };

  const openStatusChangeModal = async () => {
    toggleStatusChangeModal();
  };

  return (
    <>
      {isLoading && <OpacityLoaderSpinner />}
      <CommonHeader />
      <Modal
        className="modal-dialog-centered"
        size="xl"
        isOpen={modalPdfIsOpen}
        toggle={() => setModalPdfIsOpen(!modalPdfIsOpen)}
        style={{ maxWidth: '98%' }}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="Label">
            {`View Report`}
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setModalPdfIsOpen(!modalPdfIsOpen)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body p-0 m-0">
          <div className="row">
            <div className="col">
              <iframe
                src={`${pdfSrc}#toolbar=0&scrollbar=0&scrolling=0&embedded=true&view=FitH`}
                style={{ width: '100%', minHeight: '80vh' }}
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button onClick={() => setModalPdfIsOpen(!modalPdfIsOpen)}>Close</Button>
        </div>
      </Modal>

      <TimecardModal isOpen={isOpen} toggleModal={toggleModal} />
      <IncidentReportModal isOpen={isOpenIncidentModal} toggleModal={toggleModalIncidentModal} />
      <TimerModal
        isOpen={isTimerModalOpen}
        toggleModal={handleTimerModal}
        isPdf={exportPdf}
        exportPdfHandler={async (report: string, filename: string, params: {}) => {
          setIsTimerModalOpen(!isTimerModalOpen);
          await exportToPdf(report, filename, params);
        }}
      />
      <ChildNotUpdatedModal isOpen={isRangeModalOpen} toggleModal={handleRangeModal} />
      <WriteUpModal isOpen={isOpenWriteUpModal} toggleModal={toggleWriteUpModal} />
      <StatusChangeModal isOpen={isOpenStatusChangeModal} toggleModal={toggleStatusChangeModal} />
      <ClassroomOutOfRatio
        isOpen={isOpenClassroomOutOfRatioModal}
        toggleModal={handleClassroomOutOfRatioModal}
        isPdf={exportPdf}
        exportPdfHandler={async (report: string, filename: string, params: {}) => {
          setIsOpenClassroomOutOfRatioModal(!isOpenClassroomOutOfRatioModal);
          await exportToPdf(report, filename, params);
        }}
      />
      <TeacherTracking isOpen={isOpenTeacherTrackingModal} toggleModal={handleTeacherTrackingModal} />
      <Container className="mt--8" fluid>
        <Row>
          <Col className="order-xl-2" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col>
                    <Row>
                      <Col xs="12">
                        <h3 className="mb-0">Reporting</h3>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12">
                        <Table hover className="mt-4" responsive>
                          <tbody>
                            {!isMobile && (
                              <>
                                <tr>
                                  <td>
                                    <strong>Billing Reports:</strong>
                                  </td>
                                </tr>
                                {isAuthorized({ moduleName: 'ReportExportData', functionName: 'Timecard' }, user) && (
                                  <tr>
                                    <th scope="row" className="p-0">
                                      <button className="btn-table-row" onClick={handleExportWeeklyTuition}>
                                        <i className="fa fa-download text-success" />
                                        Weekly Tuition
                                      </button>
                                    </th>
                                  </tr>
                                )}
                              </>
                            )}
                            <tr>
                              <td>
                                <strong>Children Reports:</strong>
                              </td>
                            </tr>
                            {!isMobile && (
                              <>
                                {isAuthorized(
                                  { moduleName: 'ReportExportData', functionName: 'ChildExpirationDocuments' },
                                  user,
                                ) && (
                                  <tr>
                                    <th scope="row" className="p-0">
                                      <button className="btn-table-row" onClick={handleExportChildExpirationDocuments}>
                                        <i className="fa fa-download text-success" />
                                        Expiration Dates
                                      </button>
                                    </th>
                                  </tr>
                                )}
                                {isAuthorized(
                                  { moduleName: 'ReportExportData', functionName: 'ParentChildInformation' },
                                  user,
                                ) && (
                                  <tr>
                                    <th scope="row" className="p-0">
                                      <button className="btn-table-row" onClick={handleExportParentChildInformation}>
                                        <i className="fa fa-download text-success" />
                                        Parent and Child Information
                                      </button>
                                    </th>
                                  </tr>
                                )}
                                {isAuthorized(
                                  { moduleName: 'ReportExportData', functionName: 'IncidentReport' },
                                  user,
                                ) && (
                                  <tr>
                                    <th scope="row" className="p-0">
                                      <button
                                        className="btn-table-row col float-left"
                                        onClick={openIncidentModal}
                                        style={{ width: '200px' }}
                                      >
                                        <i className="fa fa-download text-success" />
                                        Incident Reports
                                      </button>
                                      {/* <a
                                        className="btn-table-row float-right"
                                        style={{ width: '70px' }}
                                        onClick={() => openPdfInModal('Incident Reports')}
                                      >
                                        PDF
                                      </a> */}
                                    </th>
                                  </tr>
                                )}
                              </>
                            )}
                            {isAuthorized(
                              { moduleName: 'ReportExportData', functionName: 'ChildrenEnrollDisenrollRate' },
                              user,
                            ) && (
                              <tr>
                                <th scope="row" className="p-0">
                                  <button className="btn-table-row" onClick={handleExportChildrenEnrollDisenrollRate}>
                                    <i className="fa fa-download text-success" />
                                    Children Enroll Disenroll Rate
                                  </button>
                                </th>
                              </tr>
                            )}
                            <tr>
                              <td>
                                <strong>Staff:</strong>
                              </td>
                            </tr>
                            {!isMobile &&
                              isAuthorized(
                                { moduleName: 'ReportExportData', functionName: 'EmployeeInformation' },
                                user,
                              ) && (
                                <tr style={{ position: 'relative' }}>
                                  <th scope="row" className="p-0">
                                    <button className="btn-table-row" onClick={handleExportEmployeeInformation}>
                                      <i className="fa fa-download text-success" />
                                      Staff Information
                                    </button>
                                    {/* {isMobile && (
                                    <button
                                      className="btn btn-primary btn-sm"
                                      style={{ position: 'absolute', top: '15px', right: '15px' }}
                                      onClick={() => console.log('aki')}
                                    >
                                      PDF
                                    </button>
                                  )} */}
                                  </th>
                                </tr>
                              )}
                            {isAuthorized(
                              { moduleName: 'ReportExportData', functionName: 'StaffExpirationDocuments' },
                              user,
                            ) && (
                              <tr>
                                <th scope="row" className="p-0">
                                  <button className="btn-table-row" onClick={handleExportStaffExpirationDocuments}>
                                    <i className="fa fa-download text-success" />
                                    Staff Expiration
                                  </button>
                                </th>
                              </tr>
                            )}
                            {!isMobile && (
                              <>
                                {isAuthorized({ moduleName: 'ReportExportData', functionName: 'Timecard' }, user) && (
                                  <tr>
                                    <th scope="row" className="p-0">
                                      <button className="btn-table-row" onClick={readReminderAlert}>
                                        <i className="fa fa-download text-success" />
                                        Timecards
                                      </button>
                                    </th>
                                  </tr>
                                )}

                                {!isDirector(user) &&
                                  isAuthorized({ moduleName: 'ReportExportData', functionName: 'WriteUp' }, user) && (
                                    <tr>
                                      <th scope="row" className="p-0">
                                        <button className="btn-table-row" onClick={openWriteUpModal}>
                                          <i className="fa fa-download text-success" />
                                          Write Up
                                        </button>
                                      </th>
                                    </tr>
                                  )}
                                {isDirector(user) ||
                                  (isAuthorized({ moduleName: 'ReportExportData', functionName: 'WriteUp' }, user) && (
                                    <tr>
                                      <th scope="row" className="p-0">
                                        <button className="btn-table-row" onClick={openStatusChangeModal}>
                                          <i className="fa fa-download text-success" />
                                          Status Change
                                        </button>
                                      </th>
                                    </tr>
                                  ))}
                              </>
                            )}
                            {!isMobile && (
                              <>
                                {isAuthorized(
                                  { moduleName: 'ReportExportData', functionName: 'EnrolledChildren' },
                                  user,
                                ) && (
                                  <>
                                    <tr>
                                      <td>
                                        <strong>Enroll:</strong>
                                      </td>
                                    </tr>
                                    <tr>
                                      <th scope="row" className="p-0">
                                        <button className="btn-table-row" onClick={handleExportEnrolledChildren}>
                                          <i className="fa fa-download text-success" />
                                          Enrolled Children Current +Future
                                        </button>
                                      </th>
                                    </tr>
                                  </>
                                )}
                              </>
                            )}
                            {isAuthorized(
                              {
                                childs: [
                                  { moduleName: 'ReportExportData', functionName: 'ClassBreakdown' },
                                  { moduleName: 'ReportExportData', functionName: 'ClassRevenues' },
                                  { moduleName: 'ReportExportData', functionName: 'TimerOverage' },
                                  { moduleName: 'ReportExportData', functionName: 'ChildNotUpdated' },
                                  { moduleName: 'ReportExportData', functionName: 'ClassroomOutOfRatio' },
                                  { moduleName: 'ReportExportData', functionName: 'TeacherTracking' },
                                ],
                              },
                              user,
                            ) && (
                              <>
                                <tr>
                                  <td>
                                    <strong>Class:</strong>
                                  </td>
                                </tr>
                                {!isMobile && (
                                  <>
                                    {isAuthorized(
                                      { moduleName: 'ReportExportData', functionName: 'ClassBreakdown' },
                                      user,
                                    ) && (
                                      <tr>
                                        <th scope="row" className="p-0">
                                          <button className="btn-table-row" onClick={handleExportClassBreakdown}>
                                            <i className="fa fa-download text-success" />
                                            Class Breakdown
                                          </button>
                                        </th>
                                      </tr>
                                    )}
                                    {isAuthorized(
                                      { moduleName: 'ReportExportData', functionName: 'ClassRevenues' },
                                      user,
                                    ) && (
                                      <tr>
                                        <th scope="row" className="p-0">
                                          <button className="btn-table-row" onClick={handleExportClassRevenues}>
                                            <i className="fa fa-download text-success" />
                                            Class Revenues
                                          </button>
                                        </th>
                                      </tr>
                                    )}
                                    {isAuthorized(
                                      { moduleName: 'ReportExportData', functionName: 'TeacherTracking' },
                                      user,
                                    ) && (
                                      <tr>
                                        <th scope="row" className="p-0">
                                          <button className="btn-table-row" onClick={handleTeacherTrackingModal}>
                                            <i className="fa fa-download text-success" />
                                            Teacher Tracking Report
                                          </button>
                                        </th>
                                      </tr>
                                    )}
                                  </>
                                )}
                                {isAuthorized(
                                  { moduleName: 'ReportExportData', functionName: 'TimerOverage' },
                                  user,
                                ) && (
                                  <tr>
                                    <th scope="row" className="p-0">
                                      <button className="btn-table-row" onClick={handleTimerModal}>
                                        <i className="fa fa-download text-success" />
                                        Timer Overage
                                      </button>
                                    </th>
                                  </tr>
                                )}
                                {isAuthorized(
                                  { moduleName: 'ReportExportData', functionName: 'ChildNotUpdated' },
                                  user,
                                ) && (
                                  <tr>
                                    <th scope="row" className="p-0">
                                      <button className="btn-table-row" onClick={handleRangeModal}>
                                        <i className="fa fa-download text-success" />
                                        Child Not Updated
                                      </button>
                                    </th>
                                  </tr>
                                )}
                                {isAuthorized(
                                  { moduleName: 'ReportExportData', functionName: 'ClassroomOutOfRatio' },
                                  user,
                                ) && (
                                  <tr>
                                    <th scope="row" className="p-0">
                                      <button className="btn-table-row" onClick={handleClassroomOutOfRatioModal}>
                                        <i className="fa fa-download text-success" />
                                        Classroom Out of Ratio
                                      </button>
                                    </th>
                                  </tr>
                                )}
                              </>
                            )}
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody></CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Reports;
