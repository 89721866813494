import { Container, Spinner } from 'reactstrap';
import { useContext, useEffect, useState } from 'react';
import { Context as AuthContext } from '../../contexts/AuthContext';
import QrCodeService from '../../services/QrCode';

const QrCode = () => {
  const { token } = useContext(AuthContext);
  const [qrCode, setQrCode] = useState('');

  useEffect(() => {
    const getQrCode = async () => {
      if (token) {
        const result = await QrCodeService().get(token);
        setQrCode(result.qr);
      }
    };
    getQrCode();
    // eslint-disable-next-line
  }, []);

  return (
    <Container fluid className="text-center">
      {qrCode && <img alt={`QRCode`} src={qrCode} style={{ width: 250, height: 250 }} />}
      {!qrCode && <Spinner />}
    </Container>
  );
};

export default QrCode;
