import { useContext, useState } from 'react';
import { Button, Col, FormGroup, Input, Modal, Row } from 'reactstrap';
import NumberFormat from 'react-number-format';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import TransactionService from '../../../services/Transaction';
import { validTransactionTypes } from '../../../interfaces/TransactionsInterface';
import { toast } from 'react-toastify';

type BillingCreditModalProps = {
  kidsId: string;
  isOpen: boolean;
  toggleModal: (e?: any) => void;
  onChange: (e?: any, amount?: number) => void;
};

const BillingCreditModal = ({ kidsId, isOpen, toggleModal, onChange }: BillingCreditModalProps) => {
  const { token, user }: any = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState('');
  const [amount, setAmount] = useState(0);

  const handleRequest = async (e: any) => {
    if (loading) return;
    e.preventDefault();
    setLoading(true);
    try {
      await TransactionService(token).createPayment({
        kidsId,
        userId: user?.id || '',
        transactionType: validTransactionTypes.CREDIT,
        amount,
        description,
      });
      onCreditModalChange(e);
      setLoading(false);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'Request error');
      setLoading(false);
    }
  };

  const toggleCreditModal = (e: any) => {
    e.preventDefault();
    toggleModal(e);
    setDescription('');
    setAmount(0);
  };

  const onCreditModalChange = (e: any) => {
    onChange(e);
    e.preventDefault();
    setDescription('');
    setAmount(0);
  };

  return (
    <>
      <Modal className="modal-dialog-centered" isOpen={isOpen} toggle={(e: any) => toggleCreditModal(e)}>
        <div className="modal-header pb-1">
          <h5 className="modal-title">Add a Credit</h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={(e) => toggleCreditModal(e)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body pt-0">
          <Row className="mt-2">
            <Col lg="12">
              <FormGroup>
                <label className="form-control-label">Description</label>
                <Input
                  className="form-control-alternative"
                  placeholder="Please, insert a reason for this credit."
                  type="text"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col xl="12">
              <FormGroup>
                <label className="form-control-label">Amount</label>
                <Input
                  type="text"
                  thousandsGroupStyle="thousand"
                  prefix="$"
                  decimalSeparator="."
                  displayType="input"
                  thousandSeparator={true}
                  allowNegative={false}
                  decimalScale={2}
                  tag={NumberFormat}
                  value={amount}
                  onValueChange={(e: any) => setAmount(e.floatValue)}
                />
              </FormGroup>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col md="6 text-center">
              <Button color="secondary" size="md" style={{ minWidth: '100px' }} onClick={(e) => toggleCreditModal(e)}>
                Cancel
              </Button>
            </Col>
            <Col md="6 text-center">
              <Button color="success" size="md" style={{ minWidth: '100px' }} onClick={(e) => handleRequest(e)} lo>
                Save
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default BillingCreditModal;
