import { Modal, ModalBody } from 'reactstrap';
import { Context as AuthContext } from '../../contexts/AuthContext';
import { useContext, useState, useEffect } from 'react';
import Franchisee from '../../services/Franchisee';
import moment from 'moment';
import KidsActivity from '../../services/KidsActivity';
import { toast } from 'react-toastify';
import Timeline from '../../services/Timeline';
import ModalParentKidsForm from './ModalParentKidsForm';

export interface simpleKidEnroll {
  id: string;
  name: string;
  classId: string;
  className: string;
  showModal: boolean;
  modalType: string;
  canCloseModal?: boolean;
  data?: any;
  canEdit?: boolean;
  editing?: boolean;
}

const showModalConfiguration: boolean = true;

const ModalParentKids = ({
  user,
  student,
  onClose = () => {},
}: {
  user?: any;
  student?: simpleKidEnroll;
  onClose?: () => void;
}) => {
  const { currentFranchisee, token }: any = useContext(AuthContext);
  const [franchisePicture, setFranchisePicture] = useState('');
  const [kids, setKids] = useState<simpleKidEnroll[]>([]);
  const [time, setTime] = useState('');
  const [timeDiasper, setTimeDiasper] = useState('');

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const data = new FormData(e.target as HTMLFormElement);
      const body = {} as any;
      for (const [key, value] of data.entries()) {
        (body as any)[key] = value;
      }

      if (body.type === 'infant') {
        const options = {
          className: body.className,
          type: 'infant',
          activityType: 'Food',
          mealType: '',
          food: '',
          foodDescribe: '',
          childAte: body.how_much,
          describe: body.more_detail,
          createdAt: time,
        };

        if (
          Object.keys(body).includes('kids_activity_id_diaper') &&
          Object.keys(body).includes('kids_activity_id_food') &&
          body.kids_activity_id_diaper !== '' &&
          body.kids_activity_id_food !== ''
        ) {
          await KidsActivity(token).deleteActivityKidsRelation(body.kids_activity_id_diaper);
          await KidsActivity(token).deleteActivityKidsRelation(body.kids_activity_id_food);
        }

        await KidsActivity(token).sendFood(body.kidId, 'PRE-CHECKIN', time, options);
        await KidsActivity(token).sendBathroom(body.kidId, 'PRE-CHECKIN', timeDiasper, {
          type: 'infant',
          activityType: 'Diaper',
          options: [body.deaper_change_expecification],
          createdAt: timeDiasper,
          className: body.className,
        });
      } else if (body.type === 'other') {
        if (Object.keys(body).includes('kids_activity_id') && body.kids_activity_id !== '') {
          await KidsActivity(token).deleteActivityKidsRelation(body.kids_activity_id);
        }

        const createdAt = moment().format('YYYY-MM-DD HH:mm:ss');
        await KidsActivity(token).sendReminder(body.kidId, 'PRE-CHECKIN', createdAt, {
          ...{ createdAt, className: body.className },
          ...body,
        });
      }

      const index = kids.findIndex((kid) => kid.id === body.kidId);
      kids[index].showModal = !kids[index].showModal;
      setKids(JSON.parse(JSON.stringify(kids)));
      onClose();
      toast.success(`Saved successfully!`);
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Create error!');
      toast.error(message);
    }
  };

  const enableEditing = () => {
    if (!student) return;
    const index = kids.findIndex((kid) => kid.id === student.id);
    kids[index].editing = !kids[index].editing;
    setKids(JSON.parse(JSON.stringify(kids)));
  };

  const formInputs = (type: string) => {
    return (
      <ModalParentKidsForm
        type={type}
        data={student}
        setTime={(val) => setTime(val)}
        setTimeDiasper={(val) => setTimeDiasper(val)}
        time={time}
        timeDiasper={timeDiasper}
      />
    );
  };

  useEffect(() => {
    if (!currentFranchisee) return;
    const loadFranchisePicture = async () => {
      try {
        const franchisee = await Franchisee(token).show(currentFranchisee.value);
        setFranchisePicture(franchisee.data.picture);
      } catch (error) {
        console.error(error);
      }
    };
    loadFranchisePicture();
  }, [currentFranchisee]);

  useEffect(() => {
    if (!user) return;
    const _kids = user.kids;
    const loadKids = async () => {
      for (const kid of _kids) {
        const response = await Timeline(token).showPreCheckins(kid.id, {
          filters: [
            { field: 'time', operation: '>=', value: moment().format('YYYY-MM-DD 00:00:00') },
            { field: 'time', operation: '<=', value: moment().format('YYYY-MM-DD 23:59:59') },
          ],
        });
        _kids.find((i: any) => i.id === kid.id).hasPreCheckinToday = response.data.length > 0;
      }
      setKids(
        _kids
          .filter((i: any) => !i.hasPreCheckinToday)
          .map((item: any) => {
            return {
              id: item.kids.id,
              name: `${item.kids.firstName} ${item.kids.lastName}`,
              classId: item.kids.enrollment.class.id,
              className: item.kids.enrollment.class.name,
              showModal: showModalConfiguration,
              modalType: !item.kids.enrollment.isInfantRoom ? 'infant' : 'other',
              editing: true,
            };
          })
          .sort((a: simpleKidEnroll, b: simpleKidEnroll) => b.modalType.localeCompare(a.modalType)),
      );
    };
    loadKids();
  }, [user]);

  useEffect(() => {
    if (!student) return;
    setKids([student]);
    if (student.modalType === 'infant') {
      setTime(student?.data.find((item: any) => item.activityType === 'Food').createdAt);
      setTimeDiasper(student?.data.find((item: any) => item.activityType === 'Diaper').createdAt);
    }
  }, [student]);

  const closeModal = () => {
    if (!kids.length) return;
    const selected = kids.find((item: simpleKidEnroll) => item.showModal);
    if (!selected?.canCloseModal) return;
    setKids(kids.map((item: simpleKidEnroll) => ({ ...item, showModal: false })));
    onClose();
  };

  return (
    <>
      {kids.map((kid: simpleKidEnroll, i: number) => {
        return (
          <Modal
            isOpen={kid.showModal}
            toggle={() => {
              closeModal();
            }}
            centered
            key={`modal-kids_info-${i}`}
          >
            <ModalBody className="p-0 pb-3 pt-3">
              <form onSubmit={(e) => handleSubmit(e)}>
                <input type="hidden" name="kidId" value={kid.id} readOnly />
                <input type="hidden" name="classId" value={kid.classId} readOnly />
                <input type="hidden" name="type" value={kid.modalType} readOnly />
                <input type="hidden" name="className" value={kid.className} readOnly />
                <div className="row m-0 justify-content-center">
                  <img
                    alt="Franchisee"
                    className="rounded"
                    src={franchisePicture ? `${franchisePicture}` : ''}
                    style={{ width: 135, height: 135 }}
                  />
                </div>
                <div className="bg-primary text-white text-center pt-1 pb-1 mb-3 mt-3">
                  <h3 className="pb-0 mb-0 text-white">{kid.name}</h3>
                  <h3 className="pb-0 mb-0 text-white">
                    {kid.className} | {moment(time).format('MM/DD/yyyy')}
                  </h3>
                </div>
                <div className="row m-0 justify-content-center">
                  {formInputs(kid.modalType)}

                  <div className="col-7">
                    <div className="row">
                      {kid.editing && (
                        <div className="col">
                          <button className="btn btn-success w-100">Save</button>
                        </div>
                      )}
                      {kid.canEdit && !kid.editing && (
                        <div className="col">
                          <button
                            className="btn btn-primary w-100"
                            type="button"
                            onClick={() => {
                              enableEditing();
                            }}
                          >
                            Edit
                          </button>
                        </div>
                      )}
                      {kid.canCloseModal && (
                        <div className="col">
                          <button
                            className="btn btn-danger w-100"
                            type="button"
                            onClick={() => {
                              closeModal();
                            }}
                          >
                            Close
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  {kids.length > 1 && (
                    <div className="col-12 text-right">
                      <span className="badge badge-pill badge-primary">{`${kids.length - i}/${kids.length}`}</span>
                    </div>
                  )}
                </div>
              </form>
            </ModalBody>
          </Modal>
        );
      })}
    </>
  );
};

export default ModalParentKids;
