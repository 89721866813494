/* eslint-disable max-lines */
import {
  Container,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Card,
  Button,
  Modal,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Table,
} from 'reactstrap';
import React, { useContext, useState, useEffect, useRef, ChangeEvent } from 'react';
import { toast } from 'react-toastify';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import CommonHeader from '../../../components/Headers/CommonHeader';
import ReactDatetime from 'react-datetime';
import { PagedMetaInterface, createPagedMeta } from '../../../entities/PagedMeta';
import { createOptionsPage } from '../../../entities/OptionsPaged';
import DataTable from '../../../components/Core/DataTable';
import moment from 'moment';
import CardInfo from '../../../components/Cards/CardInfo';
import ModalContent from '../../../components/Modals/Modal';
import Employee from '../../../services/Employee';
import TimePicker from '../../../components/Core/TimePicker';
import PayCodesService from '../../../services/PayCodes';
import PayrollService from '../../../services/Payroll';
import DeductionDetail from './DeductionDetail';
import ExtraPaymentDetail from './ExtraPaymentDetail';
import RaiseDetail from './RaiseDetail';
import BonusesDetail from './BonusesDetail';

const Payroll = () => {
  const { token, currentSchool }: any = useContext(AuthContext);
  const [activeTab, setActiveTab] = useState('1');
  const [isOpen, setIsOpen] = useState(false);
  const [payCodes, setPayCodes] = useState([] as any[]);

  const [deductionDetailsData, setDeductionDetailsData] = useState<any>();
  const [isDeductionDetailsOpen, setIsDeductionDetailsOpen] = useState(false);

  const [extraPaymentDetailsData, setExtraPaymentDetailsData] = useState<any>();
  const [isExtraPaymentDetailsOpen, setIsExtraPaymentDetailsOpen] = useState(false);

  const [raiseDetailsData, setRaiseDetailsData] = useState<any>();
  const [isRaiseDetailsOpen, setIsRaiseDetailsOpen] = useState(false);

  const [bonusesDetailsData, setBonusesDetailsData] = useState<any>();
  const [isBonusesDetailsOpen, setIsBonusesDetailsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const customStyles = {
    navLink: {
      minHeight: '42px',
      paddingTop: '8px',
      cursor: 'pointer',
    },
  };

  const columnsPayroll = [
    {
      name: 'Name',
      grow: 2,
      route: (row: any) => `/users/${row.user.id}/profile`,
      label: (row: any) => (
        <div className="d-flex align-items-center">
          <div style={{ width: '35px', height: '35px', borderRadius: '50%', overflow: 'hidden' }}>
            <img
              src={row.user.picture}
              alt="User Profile"
              className="img-fluid"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </div>
          <span className="ml-2">{`${row.user.firstName || '-'} ${row.user.lastName || '-'}`}</span>
        </div>
      ),
    },
    {
      name: 'Hours Worked',
      selector: () => '',
      grow: 1,
      cell: () => '',
    },
    {
      name: 'Overtime',
      selector: () => '',
      grow: 1,
      cell: () => '',
    },
    {
      name: 'PTO/Holiday',
      selector: () => '',
      grow: 1,
      cell: () => '',
    },
    {
      name: 'Training',
      selector: () => '',
      grow: 1,
      cell: () => '',
    },
    {
      name: 'Total Hours',
      selector: () => '',
      grow: 1,
      cell: () => '',
    },
    {
      name: 'Salary/Rate',
      selector: () => '$1.00 /hr',
      grow: 1,
      cell: () => '$1.00 /hr',
    },
  ];

  const columnsDeductions = [
    {
      name: 'Name',
      grow: 2,
      cell: (row: any) => (
        <div
          className="d-flex align-items-center"
          style={{
            cursor: 'pointer',
            transition: 'filter 0.3s ease',
            filter: 'brightness(1)',
          }}
          onMouseEnter={(e) => (e.currentTarget.style.filter = 'brightness(0.8)')}
          onMouseLeave={(e) => (e.currentTarget.style.filter = 'brightness(1)')}
          onClick={() => {
            setDeductionDetailsData(row);
            setIsDeductionDetailsOpen(true);
          }}
        >
          <div style={{ width: '35px', height: '35px', borderRadius: '50%', overflow: 'hidden' }}>
            <img
              src={row.user.picture}
              alt="User Profile"
              className="img-fluid"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </div>
          <span className="ml-2 text-primary font-weight-bold text-sm">{`${row.user.firstName || '-'} ${
            row.user.lastName || '-'
          }`}</span>
        </div>
      ),
    },
    {
      name: 'Type',
      selector: (row: any) => row.type,
      grow: 1,
      cell: (row: any) => row.type,
    },
    {
      name: 'Total Amount',
      selector: (row: any) => (row.amount ? `$ ${parseFloat(row.amount).toFixed(2)}` : ''),
      grow: 2,
      cell: (row: any) => (row.amount ? `$ ${parseFloat(row.amount).toFixed(2)}` : ''),
    },
    {
      name: 'Date',
      selector: (row: any) => moment(row.startDate).format('MMMM D, YYYY'),
      grow: 1,
      cell: (row: any) => moment(row.startDate).format('MMMM D, YYYY'),
    },
    {
      name: 'Amount Paid',
      selector: () => `$ 0`,
      grow: 1,
      cell: () => `$ 0`,
    },
    {
      name: 'Amount Left',
      selector: (row: any) => (row.amount ? `$ ${parseFloat(row.amount).toFixed(2)}` : ''),
      grow: 1,
      cell: (row: any) => (row.amount ? `$ ${parseFloat(row.amount).toFixed(2)}` : ''),
    },
    {
      name: 'End Date',
      selector: (row: any) => (row.endDate ? moment(row.endDate).format('MMMM D, YYYY') : ''),
      grow: 1,
      cell: (row: any) => (row.endDate ? moment(row.endDate).format('MMMM D, YYYY') : ''),
    },
    {
      name: 'View',
      selector: (row: any) => row.supervisor.name,
      grow: 0,
      cell: (row: any) => (
        <div className="d-flex">
          <Button
            style={{
              border: 'none',
              boxShadow: 'none',
              background: '#FFFFFF',
              padding: 0,
            }}
            onClick={() => {
              setDeductionDetailsData(row);
              setIsDeductionDetailsOpen(true);
            }}
          >
            <i className="fas fa-eye" />
          </Button>
        </div>
      ),
    },
  ];

  const columnsExtraPayment = [
    {
      name: 'Name',
      grow: 2,
      cell: (row: any) => (
        <div
          className="d-flex align-items-center"
          style={{
            cursor: 'pointer',
            transition: 'filter 0.3s ease',
            filter: 'brightness(1)',
          }}
          onMouseEnter={(e) => (e.currentTarget.style.filter = 'brightness(0.8)')}
          onMouseLeave={(e) => (e.currentTarget.style.filter = 'brightness(1)')}
          onClick={() => {
            setExtraPaymentDetailsData(row);
            setIsExtraPaymentDetailsOpen(true);
          }}
        >
          <div style={{ width: '35px', height: '35px', borderRadius: '50%', overflow: 'hidden' }}>
            <img
              src={row.user.picture}
              alt="User Profile"
              className="img-fluid"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </div>
          <span className="ml-2 text-primary font-weight-bold text-sm">{`${row.user.firstName || '-'} ${
            row.user.lastName || '-'
          }`}</span>
        </div>
      ),
    },
    {
      name: 'Type',
      selector: (row: any) => row.type,
      grow: 1,
      cell: (row: any) => row.type,
    },
    {
      name: 'Pay Amount',
      selector: (row: any) => (row.amount ? `$ ${parseFloat(row.amount).toFixed(2)}` : ''),
      grow: 1,
      cell: (row: any) => (row.amount ? `$ ${parseFloat(row.amount).toFixed(2)}` : ''),
    },
    {
      name: 'Date Added To Payroll',
      selector: (row: any) => (row.dateAddingToPayroll ? moment(row.dateAddingToPayroll).format('MMMM D, YYYY') : ''),
      grow: 2,
      cell: (row: any) => (row.dateAddingToPayroll ? moment(row.dateAddingToPayroll).format('MMMM D, YYYY') : ''),
    },
    {
      name: 'Documentation',
      selector: () => (
        <div className="d-flex">
          <Button
            style={{
              border: 'none',
              boxShadow: 'none',
              background: '#FFFFFF',
              padding: 0,
            }}
          >
            <i className="fas fa-download" />
          </Button>
        </div>
      ),
      grow: 1,
      cell: (row: any) => (
        <div className="d-flex">
          {(row.type === 'Health Care' || row.type === 'Reimbursement') && (
            <Button
              style={{
                border: 'none',
                boxShadow: 'none',
                background: '#FFFFFF',
                padding: 0,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                transition: 'filter 0.3s ease',
                filter: 'brightness(1)',
                color: '#416adb',
              }}
              onMouseEnter={(e) => (e.currentTarget.style.filter = 'brightness(1.2)')}
              onMouseLeave={(e) => (e.currentTarget.style.filter = 'brightness(1)')}
            >
              <a href={row.document} target="_blank" className="d-flex flex-column align-items-center">
                <i className="fas fa-download" />
                <span style={{ fontSize: 11, fontWeight: 'normal' }}>Document</span>
              </a>
            </Button>
          )}
          {row.type === 'Reimbursement' && (
            <Button
              style={{
                border: 'none',
                boxShadow: 'none',
                background: '#FFFFFF',
                padding: 0,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                transition: 'filter 0.3s ease',
                filter: 'brightness(1)',
                color: '#416adb',
              }}
              onMouseEnter={(e) => (e.currentTarget.style.filter = 'brightness(1.2)')}
              onMouseLeave={(e) => (e.currentTarget.style.filter = 'brightness(1)')}
            >
              <a href={row.receipt} target="_blank" className="d-flex flex-column align-items-center">
                <i className="fas fa-download" />
                <span style={{ fontSize: 11, fontWeight: 'normal' }}>Receipt</span>
              </a>
            </Button>
          )}
        </div>
      ),
    },
    {
      name: 'View',
      selector: (row: any) => row.supervisor.name,
      grow: 0,
      cell: (row: any) => (
        <div className="d-flex">
          <Button
            style={{
              border: 'none',
              boxShadow: 'none',
              background: '#FFFFFF',
              padding: 0,
            }}
            onClick={() => {
              setExtraPaymentDetailsData(row);
              setIsExtraPaymentDetailsOpen(true);
            }}
          >
            <i className="fas fa-eye" />
          </Button>
        </div>
      ),
    },
  ];

  const columnsRaises = [
    {
      name: 'Name',
      grow: 2,
      cell: (row: any) => (
        <div
          className="d-flex align-items-center"
          style={{
            cursor: 'pointer',
            transition: 'filter 0.3s ease',
            filter: 'brightness(1)',
          }}
          onMouseEnter={(e) => (e.currentTarget.style.filter = 'brightness(0.8)')}
          onMouseLeave={(e) => (e.currentTarget.style.filter = 'brightness(1)')}
          onClick={() => {
            setRaiseDetailsData(row);
            setIsRaiseDetailsOpen(true);
          }}
        >
          <div style={{ width: '35px', height: '35px', borderRadius: '50%', overflow: 'hidden' }}>
            <img
              src={row.user.picture}
              alt="User Profile"
              className="img-fluid"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </div>
          <span className="ml-2 text-primary font-weight-bold text-sm">{`${row.user.firstName || '-'} ${
            row.user.lastName || '-'
          }`}</span>
        </div>
      ),
    },
    {
      name: 'Status',
      selector: (row: any) => row.status,
      grow: 1,
      cell: (row: any) => (
        <>
          {row.status === 'PENDDING' && (
            <div style={{ border: '1px solid #d4d110', padding: '4px 8px', borderRadius: '3px' }}>
              <span style={{ color: '#d4d110', fontWeight: '700' }}>REQUESTED</span>
            </div>
          )}

          {row.status === 'REPPROVE' && (
            <div style={{ border: '1px solid #d43710', padding: '4px 8px', borderRadius: '3px' }}>
              <span style={{ color: '#d43710', fontWeight: '700' }}>DENIED</span>
            </div>
          )}

          {row.status === 'APPROVE' && (
            <div style={{ border: '1px solid #08b408', padding: '4px 8px', borderRadius: '3px' }}>
              <span style={{ color: '#08b408', fontWeight: '700' }}>APPROVED</span>
            </div>
          )}
        </>
      ),
    },
    {
      name: 'Current Pay Rate',
      selector: (row: any) => (row.oldAmount ? `$ ${parseFloat(row.oldAmount).toFixed(2)}` : ''),
      grow: 1,
      cell: (row: any) => (row.oldAmount ? `$ ${parseFloat(row.oldAmount).toFixed(2)}` : ''),
    },
    {
      name: 'Raise Amount',
      selector: (row: any) => (row.amount ? `$ ${parseFloat(row.amount).toFixed(2)}` : ''),
      grow: 1,
      cell: (row: any) => (row.amount ? `$ ${parseFloat(row.amount).toFixed(2)}` : ''),
    },
    {
      name: '% Increase',
      selector: (row: any) => (((row.amount - row.oldAmount) / row.oldAmount) * 100).toFixed(2) + ' %',
      grow: 1,
      cell: (row: any) => (((row.amount - row.oldAmount) / row.oldAmount) * 100).toFixed(2) + ' %',
    },
    {
      name: 'Reason',
      selector: (row: any) => row.reason,
      grow: 1,
      cell: (row: any) => row.reason,
    },
    {
      name: 'Actions',
      selector: (row: any) => row.supervisor.name,
      grow: 1,
      cell: (row: any) => (
        <div className="d-flex" style={{ gap: '5px' }}>
          {row.status === 'PENDDING' && (
            <>
              <Button
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  background: '#FFFFFF',
                  padding: 0,
                }}
                onClick={() => handleApprove(row.id)}
              >
                <i className="fas fa-check" />
              </Button>
              <Button
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  background: '#FFFFFF',
                  padding: 0,
                }}
                onClick={() => handleRepprove(row.id)}
              >
                <i className="fas fa-ban" />
              </Button>
            </>
          )}

          <Button
            style={{
              border: 'none',
              boxShadow: 'none',
              background: '#FFFFFF',
              padding: 0,
            }}
            onClick={() => {
              setRaiseDetailsData(row);
              setIsRaiseDetailsOpen(true);
            }}
          >
            <i className="fas fa-eye" />
          </Button>
        </div>
      ),
    },
  ];

  const columnsBonuses = [
    {
      name: 'Name',
      grow: 2,
      cell: (row: any) => (
        <div
          className="d-flex align-items-center"
          style={{
            cursor: 'pointer',
            transition: 'filter 0.3s ease',
            filter: 'brightness(1)',
          }}
          onMouseEnter={(e) => (e.currentTarget.style.filter = 'brightness(0.8)')}
          onMouseLeave={(e) => (e.currentTarget.style.filter = 'brightness(1)')}
          onClick={() => {
            setBonusesDetailsData(row);
            setIsBonusesDetailsOpen(true);
          }}
        >
          <div style={{ width: '35px', height: '35px', borderRadius: '50%', overflow: 'hidden' }}>
            <img
              src={row.user.picture}
              alt="User Profile"
              className="img-fluid"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </div>
          <span className="ml-2 text-primary font-weight-bold text-sm">{`${row.user.firstName || '-'} ${
            row.user.lastName || '-'
          }`}</span>
        </div>
      ),
    },
    {
      name: 'Bonuses Amount',
      selector: (row: any) => (row.amount ? `$ ${parseFloat(row.amount).toFixed(2)}` : ''),
      grow: 1,
      cell: (row: any) => (row.amount ? `$ ${parseFloat(row.amount).toFixed(2)}` : ''),
    },
    {
      name: 'Reasoning',
      selector: (row: any) => row.reason,
      grow: 1,
      cell: (row: any) => row.reason,
    },
    {
      name: 'Effective Date',
      selector: (row: any) => moment(row.dateItBecomesEffectiveOn).format('MMMM D, YYYY'),
      grow: 1,
      cell: (row: any) => moment(row.dateItBecomesEffectiveOn).format('MMMM D, YYYY'),
    },

    {
      name: 'Actions',
      selector: (row: any) => row.supervisor.name,
      grow: 1,
      cell: (row: any) => (
        <div className="d-flex" style={{ gap: '5px' }}>
          <Button
            style={{
              border: 'none',
              boxShadow: 'none',
              background: '#FFFFFF',
              padding: 0,
            }}
            onClick={() => {
              setBonusesDetailsData(row);
              setIsBonusesDetailsOpen(true);
            }}
          >
            <i className="fas fa-eye" />
          </Button>
        </div>
      ),
    },
  ];

  const [data, setData] = useState([] as any[]);
  const [deductionsData, setDeductionsData] = useState([] as any[]);
  const [extraPaymentsData, setExtraPaymentsData] = useState([] as any[]);
  const [raiseData, setRaiseData] = useState([] as any[]);
  const [bonusesData, setBonusesData] = useState([] as any[]);

  const [loading, setLoading] = useState(false);
  const [searchStaffNameByPayroll, setSearchStaffNameByPayroll] = useState('');
  const [searchStaffNameByBulkImport, setSearchStaffNameByBulkImport] = useState('');

  const [searchStaffNameByDeductions, setSearchStaffNameByDeductions] = useState('');
  const [searchStaffNameByExtraPayments, setSearchStaffNameByExtraPayments] = useState('');
  const [searchStaffNameByRaises, setSearchStaffNameByRaises] = useState('');
  const [searchStaffNameByBonuses, setSearchStaffNameByBonuses] = useState('');

  const [employees, setEmployees] = useState<any[]>();
  const [employeesByBulkImport, setEmployeesByBulkImport] = useState<any[]>();

  const [isBulkImportOpen, setIsBulkImportOpen] = useState(false);
  const [isBulkImportFormOpen, setIsBulkImportFormOpen] = useState(false);
  const [isSelectedAllStaff, setIsSelectedAllStaff] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState([] as any[]);
  const [payCode, setPayCode] = useState('');
  const [isDeductionOpen, setIsDeductionOpen] = useState(false);
  const [deductionStaff, setDeductionStaff] = useState('');
  const [deductionType, setDeductionType] = useState('');
  const [deductionAmount, setDeductionAmount] = useState('');
  const [deductionReason, setDeductionReason] = useState('');
  const [amountDeducted, setAmountDeducted] = useState('');
  const [amountDeductedPeriod, setAmountDeductedPeriod] = useState('');
  const [deductionPaymentsStartDate, setDeductionPaymentsStartDate] = useState(moment().format());
  const [deductionPaymentsEndDate, setDeductionPaymentsEndDate] = useState('');
  const [selectedFile, setSelectedFile] = useState<any>();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [isExtraPaymentOpen, setIsExtraPaymentOpen] = useState(false);
  const [extraPaymentStaff, setExtraPaymentStaff] = useState('');
  const [extraPaymentType, setExtraPaymentType] = useState('');
  const [extraPaymentAmount, setExtraPaymentAmount] = useState('');
  const [extraPaymentAddingDate, setExtraPaymentAddingDate] = useState(moment().format());
  const [extraPaymentReason, setExtraPaymentReason] = useState('');
  const [extraPaymentDocumentSelected, setExtraPaymentDocumentSelected] = useState<any>();
  const documentInputRef = useRef<HTMLInputElement>(null);
  const [extraPaymentReceiptSelected, setExtraPaymentReceiptSelected] = useState<any>();
  const receiptInputRef = useRef<HTMLInputElement>(null);

  const [isRaiseOpen, setIsRaiseOpen] = useState(false);
  const [raiseStaff, setRaiseStaff] = useState('');
  const [raiseAmount, setRaiseAmount] = useState('');
  const [raiseCurrentAmount, setRaiseCurrentAmount] = useState('');
  const [raiseDateItBecomesEffectiveOn, setRaiseDateItBecomesEffectiveOn] = useState(moment().format());
  const [raiseReason, setRaiseReason] = useState('');
  const [raiseFileSelected, setRaiseFileSelected] = useState<any>();

  const [isBonusesOpen, setIsBonusesOpen] = useState(false);
  const [bonusesStaff, setBonusesStaff] = useState('');
  const [bonusesAmount, setBonusesAmount] = useState('');
  const [bonusesDateItBecomesEffectiveOn, setBonusesDateItBecomesEffectiveOn] = useState(moment().format());
  const [bonusesReason, setBonusesReason] = useState('');

  const [isCoverSheetOpen, setIsCoverSheetOpen] = useState(false);
  const [coverSheetStartDate, setCoverSheetStartDate] = useState('');
  const [coverSheetEndDate, setCoverSheetEndDate] = useState('');
  const [coverSheetData, setCoverSheetData] = useState<any>();

  useEffect(() => {
    handleGetCoverSheetData();
  }, [coverSheetStartDate, coverSheetEndDate]);

  const handleCoverSheetDateChange = (date: string) => {
    const initialDate = moment(date).startOf('isoWeek').format('MM/DD/yyyy');
    const finishDate = moment(date).endOf('isoWeek').format('MM/DD/yyyy');

    setCoverSheetStartDate(initialDate);
    setCoverSheetEndDate(finishDate);
  };

  useEffect(() => {
    handleCoverSheetDateChange(moment().subtract(7, 'days').format('MM/DD/yyyy'));
  }, []);

  const handleGetCoverSheetData = async () => {
    const query = `startDateTime=${coverSheetStartDate}&finalDateTime=${coverSheetEndDate}`;
    const result = await PayrollService(token).coverSheetList(currentSchool.value, query);

    if (result?.data) {
      setCoverSheetData(result.data);
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectFile = event.target.files?.[0];
    if (selectFile) {
      setSelectedFile(selectFile);
    }
  };

  const handleRaiseFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectFile = event.target.files?.[0];
    if (selectFile) {
      setRaiseFileSelected(selectFile);
    }
  };

  const handleDocumentChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectFile = event.target.files?.[0];
    if (selectFile) {
      setExtraPaymentDocumentSelected(selectFile);
    }
  };

  const handleReceiptChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectFile = event.target.files?.[0];
    if (selectFile) {
      setExtraPaymentReceiptSelected(selectFile);
    }
  };

  const handleCustomButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleCustomButtonDocumentClick = () => {
    if (documentInputRef.current) {
      documentInputRef.current.click();
    }
  };

  const handleCustomButtonReceiptClick = () => {
    if (receiptInputRef.current) {
      receiptInputRef.current.click();
    }
  };

  const handleGenerateDeductionEndDate = () => {
    if (!deductionPaymentsStartDate || !deductionAmount || !amountDeducted) return;

    const period = Math.ceil(
      parseFloat(deductionAmount.replace('$', '')) / parseFloat(amountDeducted.replace('$', '')),
    );

    if (amountDeductedPeriod === 'per Week') {
      const endDate = moment(deductionPaymentsStartDate).add(period, 'weeks').format('MM/DD/yyyy');
      setDeductionPaymentsEndDate(endDate);
    }

    if (amountDeductedPeriod === 'per Month') {
      const endDate = moment(deductionPaymentsStartDate).add(period, 'months').format('MM/DD/yyyy');
      setDeductionPaymentsEndDate(endDate);
    }
  };

  const handleApprove = async (id: string) => {
    try {
      await PayrollService(token).raiseApprove(id);
      loadRaises();
    } catch (err) {
      console.log(err);
    }
  };

  const handleRepprove = async (id: string) => {
    try {
      await PayrollService(token).raiseRepprove(id);
      loadRaises();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleGenerateDeductionEndDate();
  }, [deductionPaymentsStartDate, deductionAmount, amountDeducted, amountDeductedPeriod]);

  const toggleBulkImport = () => setIsBulkImportOpen(!isBulkImportOpen);

  const toggleBulkImportForm = () => setIsBulkImportFormOpen(!isBulkImportFormOpen);

  const toggleDeduction = () => setIsDeductionOpen(!isDeductionOpen);

  const toggleExtraPayment = () => setIsExtraPaymentOpen(!isExtraPaymentOpen);

  const toggleRaise = () => setIsRaiseOpen(!isRaiseOpen);

  const toggleBonuses = () => setIsBonusesOpen(!isBonusesOpen);

  const toggleCoverSheet = () => setIsCoverSheetOpen(!isCoverSheetOpen);

  const [pagedMeta, setPagedMeta] = useState(createPagedMeta());
  const [filteredOptions, setFilteredOptions] = useState({
    filters: [],
    options: createOptionsPage(pagedMeta),
  });

  const [toggleModalPending, setToggleModalPending] = useState(false);

  const handleToggleModalPending = () => setToggleModalPending(!toggleModalPending);

  const [toggleModal, setToggleModal] = useState(false);

  const handleToggleModal = () => setToggleModal(!toggleModal);

  useEffect(() => {
    loadStaff();
  }, []);

  const loadStaff = async () => {
    const result = await Employee(token).listEmployeesBySchool(currentSchool.value);
    setEmployees(result?.data);
  };

  useEffect(() => {
    loadPayroll();
  }, [searchStaffNameByPayroll]);

  useEffect(() => {
    loadDeductions();
  }, [searchStaffNameByDeductions]);

  useEffect(() => {
    loadExtraPayments();
  }, [searchStaffNameByExtraPayments]);

  useEffect(() => {
    loadRaises();
  }, [searchStaffNameByRaises]);

  useEffect(() => {
    loadBonuses();
  }, [searchStaffNameByBonuses]);

  useEffect(() => {
    loadEmployeesByBulkImport();
  }, [searchStaffNameByBulkImport]);

  useEffect(() => {
    if (isBulkImportFormOpen) {
      listTypes();
    }
  }, [isBulkImportFormOpen]);

  const listTypes = async () => {
    try {
      const result = await PayCodesService(token).list();
      setPayCodes(result.data || []);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No Types Found!');
    }
  };

  const loadEmployeesByBulkImport = async () => {
    const result = await Employee(token).listEmployeesBySchool(currentSchool.value, searchStaffNameByBulkImport);
    setEmployeesByBulkImport(result?.data);
  };

  const loadDeductions = async () => {
    try {
      const result = await PayrollService(token).deductionList(currentSchool.value, searchStaffNameByDeductions);
      setDeductionsData(result?.data);
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Error fetching list');
      return toast.error(message);
    }
  };

  const loadExtraPayments = async () => {
    try {
      const result = await PayrollService(token).extraPaymentList(currentSchool.value, searchStaffNameByExtraPayments);
      setExtraPaymentsData(result?.data);
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Error fetching list');
      return toast.error(message);
    }
  };

  const loadRaises = async () => {
    try {
      const result = await PayrollService(token).raiseList(currentSchool.value, searchStaffNameByRaises);
      setRaiseData(result?.data);
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Error fetching list');
      return toast.error(message);
    }
  };

  const loadBonuses = async () => {
    try {
      const result = await PayrollService(token).bonusesList(currentSchool.value, searchStaffNameByBonuses);
      setBonusesData(result?.data);
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Error fetching list');
      return toast.error(message);
    }
  };

  const loadPayroll = async () => {
    try {
      setLoading(true);
      const result = await Employee(token).listEmployeesBySchool(currentSchool.value, searchStaffNameByPayroll);
      const data = result.data;
      setData(data);
      const meta: PagedMetaInterface = result.meta;
      if (meta) {
        setPagedMeta(meta);
      }
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Error fetching list');
      return toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  const handlePerRowsChange = async (perPage: number) => {
    const newOptions = {
      ...pagedMeta,
      perPage,
    };
    const newFilteredOptions = {
      filters: filteredOptions.filters,
      options: createOptionsPage(newOptions),
    };
    setFilteredOptions(newFilteredOptions);
  };

  const handlePageChange = async (page: number) => {
    const newOptions = {
      ...pagedMeta,
      currentPage: page,
    };
    const newFilteredOptions = {
      filters: filteredOptions.filters,
      options: createOptionsPage(newOptions),
    };
    setFilteredOptions(newFilteredOptions);
  };

  useEffect(() => {
    if (selectedStaff.length === employeesByBulkImport?.length) {
      setIsSelectedAllStaff(true);
    } else {
      setIsSelectedAllStaff(false);
    }
  }, [employeesByBulkImport]);

  useEffect(() => {
    if (raiseStaff && employees) {
      const user = employees?.find((user) => user.userId === raiseStaff);
      const oldAmount = parseFloat(user.payRate).toLocaleString('en-IN', {
        style: 'currency',
        currency: 'USD',
      });
      setRaiseCurrentAmount(String(oldAmount).replaceAll(',', ''));
    }
  }, [raiseStaff, employees]);

  const handleSelectStaff = (staff: any) => {
    const foundStaff = selectedStaff.find((selected) => selected.id === staff.id);

    if (foundStaff) {
      const newSelectedStaff = selectedStaff.filter((selected) => selected.id !== staff.id);
      setSelectedStaff(newSelectedStaff);
      if (newSelectedStaff.length === employees?.length) {
        setIsSelectedAllStaff(true);
      } else {
        setIsSelectedAllStaff(false);
      }
    } else {
      const newSelectedStaff = selectedStaff;
      newSelectedStaff.push(staff);
      setSelectedStaff(newSelectedStaff);
      if (newSelectedStaff.length === employees?.length) {
        setIsSelectedAllStaff(true);
      } else {
        setIsSelectedAllStaff(false);
      }
    }
  };

  const handleSubmitDeduction = async (event: React.FormEvent) => {
    event.preventDefault();

    if (deductionType === 'Loan' && !selectedFile) {
      toast.error('File is required');
      return;
    }

    const data: any = {
      userId: deductionStaff,
      schoolId: currentSchool.value,
      type: deductionType,
      amount: parseFloat(deductionAmount.replace('$', '')),
      reason: deductionReason,
      startDate: deductionPaymentsStartDate,
      frequency: amountDeductedPeriod,
    };

    if (amountDeducted && deductionType === 'Loan') {
      data.amountDeducted = parseFloat(amountDeducted.replace('$', ''));
    }

    if (deductionPaymentsEndDate && deductionType === 'Loan') {
      data.endDate = deductionPaymentsEndDate;
    }
    try {
      const result = await PayrollService(token).deductionCreate(data);

      if (selectedFile) {
        const formData = new FormData();
        formData.append('file', selectedFile as any);
        await PayrollService(token).deductionFile(result?.data?.id, formData);
      }

      toast.success(`Saved successfully!`);

      toggleDeduction();
      loadDeductions();
      setDeductionStaff('');
      setDeductionType('');
      setDeductionAmount('');
      setDeductionReason('');
      setDeductionPaymentsStartDate('');
      setAmountDeductedPeriod('');
      setAmountDeducted('');
      setDeductionPaymentsEndDate('');
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Error to create');
      return toast.error(message);
    }
  };

  const handleSubmitExtraPayment = async (event: React.FormEvent) => {
    event.preventDefault();

    if ((extraPaymentType === 'Health Care' || extraPaymentType === 'Reimbursement') && !extraPaymentDocumentSelected) {
      toast.error('Document is required');
      return;
    }

    if (extraPaymentType === 'Reimbursement' && !extraPaymentReceiptSelected) {
      toast.error('Receipt is required');
      return;
    }

    const data: any = {
      userId: extraPaymentStaff,
      schoolId: currentSchool.value,
      type: extraPaymentType,
      amount: parseFloat(extraPaymentAmount.replace('$', '')),
      reason: extraPaymentReason,
      dateAddingToPayroll: extraPaymentAddingDate,
    };

    try {
      const result = await PayrollService(token).extraPaymentCreate(data);

      if (extraPaymentDocumentSelected) {
        const formDataDocument = new FormData();
        formDataDocument.append('file', extraPaymentDocumentSelected as any);
        await PayrollService(token).extraPaymentDocument(result?.data?.id, formDataDocument);
      }

      if (extraPaymentReceiptSelected) {
        const formDataReceipt = new FormData();
        formDataReceipt.append('file', extraPaymentReceiptSelected as any);
        await PayrollService(token).extraPaymentReceipt(result?.data?.id, formDataReceipt);
      }

      toast.success(`Saved successfully!`);

      toggleExtraPayment();
      loadExtraPayments();
      setExtraPaymentStaff('');
      setExtraPaymentType('');
      setExtraPaymentAmount('');
      setExtraPaymentReason('');
      setExtraPaymentAddingDate(moment().format());
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Error to create');
      return toast.error(message);
    }
  };

  const handleSubmitRaise = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!raiseFileSelected) {
      toast.error('File is required');
      return;
    }

    const data: any = {
      userId: raiseStaff,
      schoolId: currentSchool.value,
      amount: parseFloat(raiseAmount.replace('$', '')),
      oldAmount: parseFloat(raiseCurrentAmount.replace('$', '')),
      reason: raiseReason,
      dateItBecomesEffectiveOn: raiseDateItBecomesEffectiveOn,
    };

    try {
      const result = await PayrollService(token).raiseCreate(data);

      if (raiseFileSelected) {
        const formDataDocument = new FormData();
        formDataDocument.append('file', raiseFileSelected as any);
        await PayrollService(token).raiseFile(result?.data?.id, formDataDocument);
      }

      toast.success(`Saved successfully!`);

      toggleRaise();
      loadRaises();
      setRaiseStaff('');
      setRaiseCurrentAmount('');
      setRaiseAmount('');
      setRaiseReason('');
      setRaiseFileSelected(null);
      setRaiseDateItBecomesEffectiveOn(moment().format());
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Error to create');
      return toast.error(message);
    }
  };

  const handleSubmitBonuses = async (event: React.FormEvent) => {
    event.preventDefault();

    const data: any = {
      userId: bonusesStaff,
      schoolId: currentSchool.value,
      amount: parseFloat(bonusesAmount.replace('$', '')),
      reason: bonusesReason,
      dateItBecomesEffectiveOn: bonusesDateItBecomesEffectiveOn,
    };

    try {
      await PayrollService(token).bonusesCreate(data);

      toast.success(`Saved successfully!`);

      toggleBonuses();
      loadBonuses();
      setBonusesStaff('');
      setBonusesAmount('');
      setBonusesReason('');
      setBonusesDateItBecomesEffectiveOn(moment().format());
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Error to create');
      return toast.error(message);
    }
  };

  return (
    <>
      <CommonHeader />
      <Container className="mt--8" fluid>
        <DeductionDetail
          data={deductionDetailsData}
          isOpen={isDeductionDetailsOpen}
          toggle={() => setIsDeductionDetailsOpen(!isDeductionDetailsOpen)}
        />

        <ExtraPaymentDetail
          data={extraPaymentDetailsData}
          isOpen={isExtraPaymentDetailsOpen}
          toggle={() => setIsExtraPaymentDetailsOpen(!isExtraPaymentDetailsOpen)}
        />

        <RaiseDetail
          data={raiseDetailsData}
          isOpen={isRaiseDetailsOpen}
          toggle={() => setIsRaiseDetailsOpen(!isRaiseDetailsOpen)}
        />

        <BonusesDetail
          data={bonusesDetailsData}
          isOpen={isBonusesDetailsOpen}
          toggle={() => setIsBonusesDetailsOpen(!isBonusesDetailsOpen)}
        />

        <Modal className="modal-dialog-centered" isOpen={isBulkImportOpen} toggle={toggleBulkImport} size={'xl'}>
          <div className="modal-header">
            <h4 className="modal-title">Bulk Payroll Import</h4>
            <div onClick={toggleBulkImport} className="d-flex p-1 cursor-pointer">
              <i className="fas fa-times" />
            </div>
          </div>
          <div className="modal-body">
            {!isBulkImportFormOpen ? (
              <div className="mt-4 p-2">
                <div className="d-flex align-items-center">
                  <div className="w-100 d-flex justify-content-end">
                    <Input
                      type="text"
                      placeholder="Search by Name"
                      style={{ width: '180px' }}
                      value={searchStaffNameByBulkImport}
                      onChange={(event: any) => setSearchStaffNameByBulkImport(event.target.value)}
                    />
                  </div>
                </div>
                <Table className="mt-2" responsive>
                  <tr>
                    <th>
                      <Input
                        type="checkbox"
                        checked={isSelectedAllStaff}
                        onChange={() => {
                          if (isSelectedAllStaff) {
                            setSelectedStaff([]);
                            setIsSelectedAllStaff(false);
                          } else {
                            setSelectedStaff(employeesByBulkImport || []);
                            setIsSelectedAllStaff(true);
                          }
                        }}
                        style={{ transform: 'scale(1.5)' }}
                      />
                    </th>
                    <th>Staff Name</th>
                    <th>Position</th>
                    <th>Status</th>
                    <th>90 days Employee?</th>
                  </tr>
                  {employeesByBulkImport?.map((employee) => (
                    <tr key={employee.id}>
                      <td>
                        <Input
                          type="checkbox"
                          checked={selectedStaff.find((staff: any) => staff.id === employee.id)}
                          onChange={() => handleSelectStaff(employee)}
                          style={{ transform: 'scale(1.5)' }}
                        />
                      </td>
                      <td>{`${employee.user.firstName} ${employee.user.lastName}`}</td>
                      <td>{employee.department.name}</td>
                      <td>
                        {employee.workday === 'FT' && 'Full Time'}
                        {employee.workday === 'PT' && 'Part Time'}
                      </td>
                      <td>{moment() > moment(employee.startDate).add(90, 'days') ? 'Yes' : 'No'}</td>
                    </tr>
                  ))}
                </Table>
                <div className="d-flex justify-content-end mt-2">
                  <Button
                    style={{ background: '#02c412', color: '#FFFFFF', width: '150px' }}
                    onClick={toggleBulkImportForm}
                  >
                    Bulk Import
                  </Button>
                </div>
              </div>
            ) : (
              <div className="mt-4 p-2">
                <Form>
                  <Row>
                    <Col lg="4">
                      <FormGroup>
                        <label className="form-control-label">Date</label>
                        <InputGroup className="input-group-alternative">
                          <ReactDatetime
                            inputProps={{
                              placeholder: 'Please select',
                            }}
                            timeFormat={false}
                            value={``}
                            dateFormat={'MM/DD/yyyy'}
                            closeOnSelect={true}
                          />
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-calendar-grid-58" />
                            </InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col lg="4">
                      <FormGroup>
                        <label className="form-control-label">Check IN</label>
                        <TimePicker
                          value={''}
                          onChangeTime={() => {
                            return;
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="4">
                      <FormGroup>
                        <label className="form-control-label">Check OUT</label>
                        <TimePicker
                          value={''}
                          onChangeTime={() => {
                            return;
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label">Pay Code</label>
                        <Input type="select" value={payCode} onChange={(e) => setPayCode(e.target.value)}>
                          <option value=""></option>
                          {payCodes.map((a: any, index: number) => {
                            return (
                              <option value={a.name} key={`codes-${index}`}>
                                {a.name}
                              </option>
                            );
                          })}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="d-flex justify-content-around">
                    <Button className="btn-secondary" onClick={toggleBulkImportForm}>
                      Cancel
                    </Button>
                    <Button
                      style={{ background: '#02c412', color: '#FFFFFF', width: '150px' }}
                      onClick={toggleBulkImport}
                    >
                      Save
                    </Button>
                  </div>
                </Form>
              </div>
            )}
          </div>
        </Modal>

        <Modal className="modal-dialog-centered" isOpen={isDeductionOpen} toggle={toggleDeduction} size={'xl'}>
          <div className="modal-header">
            <h4 className="modal-title">Record a New Deduction Request</h4>
            <div onClick={toggleDeduction} className="d-flex p-1 cursor-pointer">
              <i className="fas fa-times" />
            </div>
          </div>
          <div className="modal-body">
            <div className="px-2">
              <Form onSubmit={handleSubmitDeduction}>
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label">Name</label>
                      <Input
                        type="select"
                        value={deductionStaff}
                        onChange={(e) => setDeductionStaff(e.target.value)}
                        required
                      >
                        <option value=""></option>
                        {employees?.map((value: any, index: number) => {
                          return (
                            <option value={value.user.id} key={`codes-${index}`}>
                              {value.user.firstName} {value.user.lastName}
                            </option>
                          );
                        })}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label">Type</label>
                      <Input
                        type="select"
                        value={deductionType}
                        onChange={(e) => setDeductionType(e.target.value)}
                        required
                      >
                        <option value=""></option>
                        <option value="Loan">Loan</option>
                        <option value="ChildCare">ChildCare</option>
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col lg="6">
                    <label className="form-control-label">$ Amount</label>
                    <Input
                      type="text"
                      value={deductionAmount}
                      required
                      onChange={(event) => {
                        const numbers = event.target.value.replace(/[^\d]/g, '');

                        const amount = (parseFloat(numbers) / 100).toLocaleString('en-IN', {
                          style: 'currency',
                          currency: 'USD',
                        });
                        setDeductionAmount(String(amount).replaceAll(',', ''));
                      }}
                    />
                  </Col>

                  <Col lg="6">
                    <label className="form-control-label">Reason</label>
                    <Input
                      type="text"
                      value={deductionReason}
                      required
                      onChange={(event) => setDeductionReason(event.target.value)}
                    />
                  </Col>
                </Row>

                {deductionType === 'Loan' && (
                  <>
                    <hr />
                    <Row className="mt-4">
                      <Col lg="6">
                        <label className="form-control-label">Amount deducted</label>
                        <Input
                          type="text"
                          value={amountDeducted}
                          required
                          onChange={(event) => {
                            const numbers = event.target.value.replace(/[^\d]/g, '');

                            const amount = (parseFloat(numbers) / 100).toLocaleString('en-IN', {
                              style: 'currency',
                              currency: 'USD',
                            });
                            setAmountDeducted(String(amount).replaceAll(',', ''));
                          }}
                        />
                      </Col>

                      <Col lg="6">
                        <label className="form-control-label">Frequency</label>
                        <Input
                          type="select"
                          value={amountDeductedPeriod}
                          required
                          onChange={(e) => setAmountDeductedPeriod(e.target.value)}
                        >
                          <option value=""></option>
                          <option value="per Week">per Week</option>
                          <option value="per Month">per Month</option>
                        </Input>
                      </Col>
                    </Row>

                    <Row className="mt-4">
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label">
                            LoanPayments
                            <br /> Start Date
                          </label>
                          <InputGroup className="input-group-alternative">
                            <ReactDatetime
                              inputProps={{
                                placeholder: 'Start Date',
                              }}
                              onChange={(newValue) => {
                                setDeductionPaymentsStartDate(`${newValue}`);
                              }}
                              timeFormat={false}
                              value={`${moment(deductionPaymentsStartDate).format('MM/DD/yyyy')}`}
                              dateFormat={'MM/DD/yyyy'}
                              closeOnSelect={true}
                            />
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-calendar-grid-58" />
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <label className="form-control-label">
                          LoanPayments
                          <br /> End Date
                        </label>
                        <Input type="text" value={deductionPaymentsEndDate} disabled />
                      </Col>
                    </Row>

                    <div>
                      <h3>Attach File Here</h3>
                      <div className="d-flex align-items-center">
                        <Button color="primary" onClick={handleCustomButtonClick} style={{ width: 160 }}>
                          Select File
                        </Button>
                        <input ref={fileInputRef} type="file" style={{ display: 'none' }} onChange={handleFileChange} />
                        {selectedFile && (
                          <>
                            <p className="p-0 m-0">Selected File: {selectedFile.name} </p>
                            <div onClick={() => setSelectedFile(null)} className="d-flex p-1 cursor-pointer ml-2">
                              <i className="fas fa-times" />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </>
                )}

                {deductionType === 'ChildCare' && (
                  <>
                    <hr />
                    <Row className="mt-4">
                      <Col lg="6">
                        <label className="form-control-label">Frequency</label>
                        <Input
                          type="select"
                          value={amountDeductedPeriod}
                          required
                          onChange={(e) => setAmountDeductedPeriod(e.target.value)}
                        >
                          <option value=""></option>
                          <option value="per Week">per Week</option>
                          <option value="per Month">per Month</option>
                        </Input>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label">Start Date</label>
                          <InputGroup className="input-group-alternative">
                            <ReactDatetime
                              inputProps={{
                                placeholder: 'Start Date',
                              }}
                              onChange={(newValue) => {
                                setDeductionPaymentsStartDate(`${newValue}`);
                              }}
                              timeFormat={false}
                              value={`${moment(deductionPaymentsStartDate).format('MM/DD/yyyy')}`}
                              dateFormat={'MM/DD/yyyy'}
                              closeOnSelect={true}
                            />
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-calendar-grid-58" />
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                  </>
                )}

                <div className="d-flex justify-content-around mt-4">
                  <Button className="btn-secondary" onClick={toggleDeduction}>
                    Cancel
                  </Button>
                  <Button style={{ background: '#02c412', color: '#FFFFFF', width: '150px' }}>Save</Button>
                </div>
              </Form>
            </div>
          </div>
        </Modal>

        <Modal className="modal-dialog-centered" isOpen={isExtraPaymentOpen} toggle={toggleExtraPayment} size={'xl'}>
          <div className="modal-header">
            <h4 className="modal-title">Record a New Extra Payment</h4>
            <div onClick={toggleExtraPayment} className="d-flex p-1 cursor-pointer">
              <i className="fas fa-times" />
            </div>
          </div>
          <div className="modal-body">
            <div className="px-2">
              <Form onSubmit={handleSubmitExtraPayment}>
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label">Name</label>
                      <Input
                        type="select"
                        value={extraPaymentStaff}
                        required
                        onChange={(e) => setExtraPaymentStaff(e.target.value)}
                      >
                        <option value=""></option>
                        {employees?.map((value: any, index: number) => {
                          return (
                            <option value={value.user.id} key={`codes-${index}`}>
                              {value.user.firstName} {value.user.lastName}
                            </option>
                          );
                        })}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label">Type</label>
                      <Input
                        type="select"
                        value={extraPaymentType}
                        required
                        onChange={(e) => setExtraPaymentType(e.target.value)}
                      >
                        <option value=""></option>
                        <option value="Event Pay">Event Pay</option>
                        <option value="Health Care">Health Care</option>
                        <option value="Reimbursement">Reimbursement</option>
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col lg="6">
                    <label className="form-control-label">$ Amount</label>
                    <Input
                      type="text"
                      value={extraPaymentAmount}
                      required
                      onChange={(event) => {
                        const numbers = event.target.value.replace(/[^\d]/g, '');

                        const amount = (parseFloat(numbers) / 100).toLocaleString('en-IN', {
                          style: 'currency',
                          currency: 'USD',
                        });
                        setExtraPaymentAmount(String(amount).replaceAll(',', ''));
                      }}
                    />
                  </Col>

                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label">Date Adding To Payroll</label>
                      <InputGroup className="input-group-alternative">
                        <ReactDatetime
                          inputProps={{
                            placeholder: 'Start Date',
                          }}
                          onChange={(newValue) => {
                            setExtraPaymentAddingDate(`${newValue}`);
                          }}
                          timeFormat={false}
                          value={`${moment(extraPaymentAddingDate).format('MM/DD/yyyy')}`}
                          dateFormat={'MM/DD/yyyy'}
                          closeOnSelect={true}
                        />
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-calendar-grid-58" />
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <label className="form-control-label">Reason</label>
                    <Input
                      type="text"
                      value={extraPaymentReason}
                      required
                      onChange={(event) => setExtraPaymentReason(event.target.value)}
                    />
                  </Col>
                </Row>

                {extraPaymentType === 'Health Care' && (
                  <div className="mt-4">
                    <h3>Attach Document Here</h3>
                    <div className="d-flex align-items-center">
                      <Button color="primary" onClick={handleCustomButtonClick} style={{ width: 170 }}>
                        Select Document
                      </Button>
                      <input
                        ref={documentInputRef}
                        type="file"
                        style={{ display: 'none' }}
                        onChange={handleDocumentChange}
                      />
                      {extraPaymentDocumentSelected && (
                        <>
                          <p className="p-0 m-0">Selected Document: {extraPaymentDocumentSelected.name} </p>
                          <div
                            onClick={() => setExtraPaymentDocumentSelected(null)}
                            className="d-flex p-1 cursor-pointer ml-2"
                          >
                            <i className="fas fa-times" />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                )}

                {extraPaymentType === 'Reimbursement' && (
                  <div className="d-flex flex-column mt-4" style={{ gap: 20 }}>
                    <div>
                      <h3>Attach Document Here</h3>
                      <div className="d-flex align-items-center">
                        <Button color="primary" onClick={handleCustomButtonDocumentClick} style={{ width: 170 }}>
                          Select Document
                        </Button>
                        <input
                          ref={documentInputRef}
                          type="file"
                          style={{ display: 'none' }}
                          onChange={handleDocumentChange}
                        />
                        {extraPaymentDocumentSelected && (
                          <>
                            <p className="p-0 m-0">Selected Document: {extraPaymentDocumentSelected.name} </p>
                            <div
                              onClick={() => setExtraPaymentDocumentSelected(null)}
                              className="d-flex p-1 cursor-pointer ml-2"
                            >
                              <i className="fas fa-times" />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div>
                      <h3>Attach Receipt Here</h3>
                      <div className="d-flex align-items-center">
                        <Button color="primary" onClick={handleCustomButtonReceiptClick} style={{ width: 170 }}>
                          Select Receipt
                        </Button>
                        <input
                          ref={receiptInputRef}
                          type="file"
                          style={{ display: 'none' }}
                          onChange={handleReceiptChange}
                        />
                        {extraPaymentReceiptSelected && (
                          <>
                            <p className="p-0 m-0">Selected Receipt: {extraPaymentReceiptSelected.name} </p>
                            <div
                              onClick={() => setExtraPaymentReceiptSelected(null)}
                              className="d-flex p-1 cursor-pointer ml-2"
                            >
                              <i className="fas fa-times" />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                <div className="d-flex justify-content-around mt-4">
                  <Button className="btn-secondary" onClick={toggleExtraPayment}>
                    Cancel
                  </Button>
                  <Button style={{ background: '#02c412', color: '#FFFFFF', width: '150px' }}>Save</Button>
                </div>
              </Form>
            </div>
          </div>
        </Modal>

        <Modal className="modal-dialog-centered" isOpen={isRaiseOpen} toggle={toggleRaise} size={'xl'}>
          <div className="modal-header">
            <h4 className="modal-title">Request a Raise</h4>
            <div onClick={toggleRaise} className="d-flex p-1 cursor-pointer">
              <i className="fas fa-times" />
            </div>
          </div>
          <div className="modal-body">
            <div className="px-2">
              <Form onSubmit={handleSubmitRaise}>
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label">Name</label>
                      <Input type="select" value={raiseStaff} required onChange={(e) => setRaiseStaff(e.target.value)}>
                        <option value=""></option>
                        {employees?.map((value: any, index: number) => {
                          return (
                            <option value={value.user.id} key={`codes-${index}`}>
                              {value.user.firstName} {value.user.lastName}
                            </option>
                          );
                        })}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label">Reason</label>
                      <Input
                        type="select"
                        value={raiseReason}
                        required
                        onChange={(e) => setRaiseReason(e.target.value)}
                      >
                        <option value=""></option>
                        <option value="Degree">Degree</option>
                        <option value="CDA">CDA</option>
                        <option value="Annual">Annual</option>
                        <option value="Position Change">Position Change</option>
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col lg="4">
                    <label className="form-control-label">$ Current Rate</label>
                    <Input type="text" value={raiseCurrentAmount} disabled />
                  </Col>

                  <Col lg="4">
                    <label className="form-control-label">$ Amount</label>
                    <Input
                      type="text"
                      value={raiseAmount}
                      required
                      onChange={(event) => {
                        const numbers = event.target.value.replace(/[^\d]/g, '');

                        const amount = (parseFloat(numbers) / 100).toLocaleString('en-IN', {
                          style: 'currency',
                          currency: 'USD',
                        });
                        setRaiseAmount(String(amount).replaceAll(',', ''));
                      }}
                    />
                  </Col>

                  <Col lg="4">
                    <FormGroup>
                      <label className="form-control-label">Date It Becomes Effective On</label>
                      <InputGroup className="input-group-alternative">
                        <ReactDatetime
                          inputProps={{
                            placeholder: 'Start Date',
                          }}
                          onChange={(newValue) => {
                            setRaiseDateItBecomesEffectiveOn(`${newValue}`);
                          }}
                          timeFormat={false}
                          value={`${moment(raiseDateItBecomesEffectiveOn).format('MM/DD/yyyy')}`}
                          dateFormat={'MM/DD/yyyy'}
                          closeOnSelect={true}
                        />
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-calendar-grid-58" />
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>

                <div className="mt-4">
                  <h3>Attach File Here</h3>
                  <div className="d-flex align-items-center">
                    <Button color="primary" onClick={handleCustomButtonClick} style={{ width: 170 }}>
                      Select File
                    </Button>
                    <input
                      ref={fileInputRef}
                      type="file"
                      style={{ display: 'none' }}
                      onChange={handleRaiseFileChange}
                    />
                    {raiseFileSelected && (
                      <>
                        <p className="p-0 m-0">Selected Document: {raiseFileSelected.name} </p>
                        <div onClick={() => setRaiseFileSelected(null)} className="d-flex p-1 cursor-pointer ml-2">
                          <i className="fas fa-times" />
                        </div>
                      </>
                    )}
                  </div>
                </div>

                <div className="d-flex justify-content-around mt-4">
                  <Button className="btn-secondary" onClick={toggleExtraPayment}>
                    Cancel
                  </Button>
                  <Button style={{ background: '#02c412', color: '#FFFFFF', width: '150px' }}>Save</Button>
                </div>
              </Form>
            </div>
          </div>
        </Modal>

        <Modal className="modal-dialog-centered" isOpen={isBonusesOpen} toggle={toggleBonuses} size={'xl'}>
          <div className="modal-header">
            <h4 className="modal-title">Request a Bonuses</h4>
            <div onClick={toggleBonuses} className="d-flex p-1 cursor-pointer">
              <i className="fas fa-times" />
            </div>
          </div>
          <div className="modal-body">
            <div className="px-2">
              <Form onSubmit={handleSubmitBonuses}>
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label">Name</label>
                      <Input
                        type="select"
                        value={bonusesStaff}
                        required
                        onChange={(e) => setBonusesStaff(e.target.value)}
                      >
                        <option value=""></option>
                        {employees?.map((value: any, index: number) => {
                          return (
                            <option value={value.user.id} key={`codes-${index}`}>
                              {value.user.firstName} {value.user.lastName}
                            </option>
                          );
                        })}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label">Reason</label>
                      <Input
                        type="select"
                        value={bonusesReason}
                        required
                        onChange={(e) => setBonusesReason(e.target.value)}
                      >
                        <option value=""></option>
                        <option value="Monthly Bonus">Monthly Bonus</option>
                        <option value="Referral Bonus">Referral Bonus</option>
                        <option value="Annual Bonus">Annual Bonus</option>
                        <option value="Sign On Bonus">Sign On Bonus</option>
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col lg="6">
                    <label className="form-control-label">$ Amount</label>
                    <Input
                      type="text"
                      value={bonusesAmount}
                      required
                      onChange={(event) => {
                        const numbers = event.target.value.replace(/[^\d]/g, '');

                        const amount = (parseFloat(numbers) / 100).toLocaleString('en-IN', {
                          style: 'currency',
                          currency: 'USD',
                        });
                        setBonusesAmount(String(amount).replaceAll(',', ''));
                      }}
                    />
                  </Col>

                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label">Date It Becomes Effective On</label>
                      <InputGroup className="input-group-alternative">
                        <ReactDatetime
                          inputProps={{
                            placeholder: 'Start Date',
                          }}
                          onChange={(newValue) => {
                            setBonusesDateItBecomesEffectiveOn(`${newValue}`);
                          }}
                          timeFormat={false}
                          value={`${moment(bonusesDateItBecomesEffectiveOn).format('MM/DD/yyyy')}`}
                          dateFormat={'MM/DD/yyyy'}
                          closeOnSelect={true}
                        />
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-calendar-grid-58" />
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>

                <div className="d-flex justify-content-around mt-4">
                  <Button className="btn-secondary" onClick={toggleExtraPayment}>
                    Cancel
                  </Button>
                  <Button style={{ background: '#02c412', color: '#FFFFFF', width: '150px' }}>Save</Button>
                </div>
              </Form>
            </div>
          </div>
        </Modal>

        <Modal
          className="modal-dialog-centered"
          isOpen={isCoverSheetOpen}
          toggle={toggleCoverSheet}
          style={{ maxWidth: 700, position: 'relative' }}
        >
          <div className="modal-body">
            <div
              onClick={toggleCoverSheet}
              className="d-flex p-1 cursor-pointer"
              style={{ position: 'absolute', top: 20, right: 20 }}
            >
              <i className="fas fa-times" />
            </div>
            <div className="p-2">
              <div className="d-flex justify-content-center mb-2">
                <h1 className="text-primary">Cover Sheet</h1>
              </div>

              <div className="d-flex align-items-center" style={{ gap: 20 }}>
                <h3 className="text-primary">Payroll Period:</h3>
                <Col lg="4">
                  <FormGroup className="custom-datepicker">
                    <label className="form-control-label"> Start Date</label>
                    <InputGroup className="input-group-alternative">
                      <ReactDatetime
                        inputProps={{
                          placeholder: 'Start Date',
                        }}
                        onChange={(newValue) => {
                          handleCoverSheetDateChange(`${newValue}`);
                        }}
                        timeFormat={false}
                        value={coverSheetStartDate}
                        dateFormat={'MM/DD/yyyy'}
                        closeOnSelect={true}
                      />
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-calendar-grid-58" />
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                </Col>
                <h3>To</h3>
                <Col lg="4">
                  <FormGroup className="custom-datepicker">
                    <label className="form-control-label">End Date</label>
                    <InputGroup className="input-group-alternative">
                      <ReactDatetime
                        inputProps={{
                          placeholder: 'Start Date',
                        }}
                        onChange={(newValue) => {
                          handleCoverSheetDateChange(`${newValue}`);
                        }}
                        timeFormat={false}
                        value={coverSheetEndDate}
                        dateFormat={'MM/DD/yyyy'}
                        closeOnSelect={true}
                      />
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-calendar-grid-58" />
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                </Col>
              </div>

              <hr className="my-3" />

              <div className="d-flex align-items-center" style={{ gap: 20 }}>
                <h3 className="text-primary">Submission Date:</h3>
                <h3>-</h3>
              </div>

              <hr className="my-3" />

              <div className="d-flex align-items-center" style={{ gap: 20 }}>
                <h3 className="text-primary">Location:</h3>
                <h3>Ponte Vedra</h3>
              </div>

              <hr className="my-3" />

              <div className="d-flex flex-column justify-content-center" style={{ gap: 20 }}>
                <h3 className="text-primary m-0 p-0">New Employees:</h3>
                <Table responsive>
                  <tr>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Amount</th>
                    <th>Start Date</th>
                  </tr>
                  {coverSheetData?.newEmployeeData.map((employee: any) => (
                    <tr key={employee.id}>
                      <td>
                        {employee.user.lastName}, {employee.user.firstName}
                      </td>
                      <td>None</td>
                      <td>$ {parseFloat(employee.payRate).toFixed(2)}</td>
                      <td>{employee.startDate}</td>
                    </tr>
                  ))}
                </Table>
              </div>

              <hr className="my-3" />

              <div className="d-flex flex-column justify-content-center" style={{ gap: 20 }}>
                <h3 className="text-primary m-0 p-0">Deactivated Employees:</h3>
                <Table responsive>
                  <tr>
                    <th>Name</th>
                    <th>Deduction</th>
                    <th>Amount</th>
                    <th>Last Date</th>
                  </tr>
                  {coverSheetData?.deactivatedEmployeeData.map((employee: any) => (
                    <tr key={employee.id}>
                      <td>
                        {employee.user.lastName}, {employee.user.firstName}
                      </td>
                      <td>None</td>
                      <td>$ {parseFloat(employee.payRate).toFixed(2)}</td>
                      <td>{employee.terminationDate}</td>
                    </tr>
                  ))}
                </Table>
              </div>

              <hr className="my-3" />

              <div className="d-flex flex-column justify-content-center" style={{ gap: 20 }}>
                <h3 className="text-primary m-0 p-0">Deductions:</h3>
                <Table responsive>
                  <tr>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Amount</th>
                  </tr>
                  {coverSheetData?.deductions.map((deduction: any) => (
                    <tr key={deduction.id}>
                      <td>
                        {deduction.user.lastName}, {deduction.user.firstName}
                      </td>
                      <td>{deduction.type}</td>
                      <td>$ {parseFloat(deduction.amount).toFixed(2)}</td>
                    </tr>
                  ))}
                </Table>
              </div>

              <hr className="my-3" />

              <div className="d-flex flex-column justify-content-center" style={{ gap: 20 }}>
                <h3 className="text-primary m-0 p-0">Raises:</h3>
                <Table responsive>
                  <tr>
                    <th>Name</th>
                    <th>Reason</th>
                    <th>Amount</th>
                  </tr>
                  {coverSheetData?.raises.map((raise: any) => (
                    <tr key={raise.id}>
                      <td>
                        {raise.user.lastName}, {raise.user.firstName}
                      </td>
                      <td>{raise.reason}</td>
                      <td>$ {parseFloat(raise.amount).toFixed(2)}</td>
                    </tr>
                  ))}
                </Table>
              </div>
              <hr className="my-3" />

              <div className="d-flex flex-column justify-content-center" style={{ gap: 20 }}>
                <h3 className="text-primary m-0 p-0">Extra Payments:</h3>
                <Table responsive>
                  <tr>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Amount</th>
                  </tr>
                  {coverSheetData?.extraPayments.map((extraPayment: any) => (
                    <tr key={extraPayment.id}>
                      <td>
                        {extraPayment.user.lastName}, {extraPayment.user.firstName}
                      </td>
                      <td>{extraPayment.type}</td>
                      <td>$ {parseFloat(extraPayment.amount).toFixed(2)}</td>
                    </tr>
                  ))}
                </Table>
              </div>

              <hr className="my-3" />

              <div className="d-flex flex-column justify-content-center" style={{ gap: 20 }}>
                <h3 className="text-primary m-0 p-0">Bonus:</h3>
                <Table responsive>
                  <tr>
                    <th>Name</th>
                    <th>Reason</th>
                    <th>Amount</th>
                  </tr>
                  {coverSheetData?.bonuses.map((bonus: any) => (
                    <tr key={bonus.id}>
                      <td>
                        {bonus.user.lastName}, {bonus.user.firstName}
                      </td>
                      <td>{bonus.reason}</td>
                      <td>$ {parseFloat(bonus.amount).toFixed(2)}</td>
                    </tr>
                  ))}
                </Table>
              </div>

              <hr className="my-3" />

              <div className="d-flex flex-column justify-content-center" style={{ gap: 20 }}>
                <h3 className="text-primary m-0 p-0">Notes:</h3>
                <Input type="textarea" />
                <div className="d-flex justify-content-end">
                  <Button size="l" color="warning">
                    Add Note
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        <Row>
          <Col className="order-xl-2" xl="12">
            <Card className="bg-secondary shadow">
              <Card className="bg-white border-0" style={{ maxHeight: '62px' }}>
                <Card>
                  <Row className="px-8 mt-2">
                    <div className="d-flex w-100 justify-content-around p-4 flex-wrap gap-2" style={{ gap: '10px' }}>
                      <CardInfo
                        count={2}
                        class="bg-danger card-info-mg-button"
                        title="Pending Approval"
                        icon="fa-clock"
                        toggle={handleToggleModalPending}
                      />
                      <ModalContent
                        title="Pending Approval"
                        class="pt-0"
                        modal={toggleModalPending}
                        toggle={handleToggleModalPending}
                        size="xl"
                      >
                        <Row>
                          <Col xs="12">
                            <DataTable
                              columns={columnsPayroll}
                              data={[]}
                              header={false}
                              totalRows={pagedMeta.total}
                              loading={loading}
                              onChangeRowsPerPage={handlePerRowsChange}
                              onChangePage={handlePageChange}
                            />
                          </Col>
                        </Row>
                      </ModalContent>

                      <CardInfo
                        count={2}
                        class="bg-danger card-info-mg-button"
                        title="Declined Timecards"
                        icon="fa-clock"
                        toggle={handleToggleModal}
                      />
                      <ModalContent
                        title="Declined Timecards"
                        class="pt-0"
                        modal={toggleModal}
                        toggle={handleToggleModal}
                        size="xl"
                      >
                        <Row>
                          <Col xs="12">
                            <DataTable
                              columns={columnsPayroll}
                              data={[]}
                              header={false}
                              totalRows={pagedMeta.total}
                              loading={loading}
                              onChangeRowsPerPage={handlePerRowsChange}
                              onChangePage={handlePageChange}
                            />
                          </Col>
                        </Row>
                      </ModalContent>
                    </div>
                  </Row>
                  <Row className="px-4 mt-4 mb-4">
                    <Col xs="12" className="d-flex justify-content-end">
                      <Button
                        style={{ background: '#3fa0fa', color: '#FFFFFF', width: '150px' }}
                        onClick={toggleCoverSheet}
                      >
                        Cover Sheet
                      </Button>
                      <Button style={{ background: '#3fa0fa', color: '#FFFFFF', width: '150px' }} onClick={toggle}>
                        Submit Payroll
                      </Button>
                      {activeTab === '1' && (
                        <Button
                          style={{ background: '#ff7b00', color: '#FFFFFF', width: '150px' }}
                          onClick={toggleBulkImport}
                        >
                          Bulk Import
                        </Button>
                      )}
                      {activeTab === '2' && (
                        <Button
                          style={{ background: '#ff7b00', color: '#FFFFFF', width: '150px' }}
                          onClick={toggleDeduction}
                        >
                          <i className="fas fa-plus" /> Deduction
                        </Button>
                      )}
                      {activeTab === '3' && (
                        <Button style={{ background: '#ff7b00', color: '#FFFFFF' }} onClick={toggleExtraPayment}>
                          <i className="fas fa-plus" /> Extra Payment
                        </Button>
                      )}
                      {activeTab === '4' && (
                        <Button style={{ background: '#ff7b00', color: '#FFFFFF' }} onClick={toggleRaise}>
                          <i className="fas fa-plus" /> Raise
                        </Button>
                      )}
                      {activeTab === '5' && (
                        <Button style={{ background: '#ff7b00', color: '#FFFFFF' }} onClick={toggleBonuses}>
                          <i className="fas fa-plus" /> Bonuses
                        </Button>
                      )}
                    </Col>
                    <Col xs="12" className="my-2">
                      {activeTab === '1' && (
                        <Input
                          type="text"
                          placeholder="Search by Name"
                          style={{ width: '280px' }}
                          value={searchStaffNameByPayroll}
                          onChange={(event: any) => setSearchStaffNameByPayroll(event.target.value)}
                        />
                      )}
                      {activeTab === '2' && (
                        <Input
                          type="text"
                          placeholder="Search by Name"
                          style={{ width: '280px' }}
                          value={searchStaffNameByDeductions}
                          onChange={(event: any) => setSearchStaffNameByDeductions(event.target.value)}
                        />
                      )}
                      {activeTab === '3' && (
                        <Input
                          type="text"
                          placeholder="Search by Name"
                          style={{ width: '280px' }}
                          value={searchStaffNameByExtraPayments}
                          onChange={(event: any) => setSearchStaffNameByExtraPayments(event.target.value)}
                        />
                      )}
                      {activeTab === '4' && (
                        <Input
                          type="text"
                          placeholder="Search by Name"
                          style={{ width: '280px' }}
                          value={searchStaffNameByRaises}
                          onChange={(event: any) => setSearchStaffNameByRaises(event.target.value)}
                        />
                      )}
                      {activeTab === '5' && (
                        <Input
                          type="text"
                          placeholder="Search by Name"
                          style={{ width: '280px' }}
                          value={searchStaffNameByBonuses}
                          onChange={(event: any) => setSearchStaffNameByBonuses(event.target.value)}
                        />
                      )}
                    </Col>
                    <Col xs="12">
                      <Nav tabs>
                        <NavItem>
                          <NavLink
                            className={`${activeTab === '1' ? 'active' : ''}`}
                            onClick={() => setActiveTab('1')}
                            style={customStyles.navLink}
                          >
                            Payroll
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={`${activeTab === '2' ? 'active' : ''}`}
                            onClick={() => setActiveTab('2')}
                            style={customStyles.navLink}
                          >
                            Deductions
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={`${activeTab === '3' ? 'active' : ''}`}
                            onClick={() => setActiveTab('3')}
                            style={customStyles.navLink}
                          >
                            Extra Payments
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={`${activeTab === '4' ? 'active' : ''}`}
                            onClick={() => setActiveTab('4')}
                            style={customStyles.navLink}
                          >
                            Raises
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={`${activeTab === '5' ? 'active' : ''}`}
                            onClick={() => setActiveTab('5')}
                            style={customStyles.navLink}
                          >
                            Bonuses
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={`${activeTab === '6' ? 'active' : ''}`}
                            onClick={() => setActiveTab('6')}
                            style={customStyles.navLink}
                          >
                            Submitted
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </Col>
                    <Col xs="12">
                      <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                          <DataTable
                            columns={columnsPayroll}
                            data={data}
                            header={false}
                            totalRows={pagedMeta.total}
                            loading={loading}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                          />
                        </TabPane>
                      </TabContent>
                      <TabContent activeTab={activeTab}>
                        <TabPane tabId="2">
                          <DataTable
                            columns={columnsDeductions}
                            data={deductionsData}
                            header={false}
                            totalRows={pagedMeta.total}
                            loading={loading}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                          />
                        </TabPane>
                      </TabContent>
                      <TabContent activeTab={activeTab}>
                        <TabPane tabId="3">
                          <DataTable
                            columns={columnsExtraPayment}
                            data={extraPaymentsData}
                            header={false}
                            totalRows={pagedMeta.total}
                            loading={loading}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                          />
                        </TabPane>
                      </TabContent>
                      <TabContent activeTab={activeTab}>
                        <TabPane tabId="4">
                          <DataTable
                            columns={columnsRaises}
                            data={raiseData}
                            header={false}
                            totalRows={pagedMeta.total}
                            loading={loading}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                          />
                        </TabPane>
                      </TabContent>
                      <TabContent activeTab={activeTab}>
                        <TabPane tabId="5">
                          <DataTable
                            columns={columnsBonuses}
                            data={bonusesData}
                            header={false}
                            totalRows={pagedMeta.total}
                            loading={loading}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                          />
                        </TabPane>
                      </TabContent>
                    </Col>
                  </Row>
                </Card>
              </Card>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Payroll;
