import { useState, useEffect, useContext } from 'react';
import { Modal } from 'reactstrap';
import FullCalendar, { EventClickArg, EventContentArg, DatesSetArg } from '@fullcalendar/react';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import { Context as AuthContext } from '../../contexts/AuthContext';
import SchoolScheduleEvents from '../../services/SchoolScheduleEvents';
import { toast } from 'react-toastify';
import SchoolScheduleEventForm from '../../views/pages/SchoolScheduleEvent/SchoolScheduleEventForm';

const SchoolEventsCalendar = ({ schoolId }: { schoolId: string }) => {
  const callendars = ['calendar', 'lunch', 'snack'];
  const [currentCalendar, setCurrentCalendar] = useState('calendar');
  const [eventCalendar, setEventCalendar] = useState<{ startAt: string; endAt: string; events: [] }>();
  const [scheduleId, setScheduleId] = useState('');
  const [scheduleForm, setScheduleForm] = useState(false);
  const [dateClick, setDateClick] = useState('');
  const { token }: any = useContext(AuthContext);

  const handleScheduleCreated = () => {
    if (currentCalendar === 'calendar') {
      setCalendarEvents(currentCalendar, eventCalendar?.startAt || '', eventCalendar?.endAt || '');
    }
    if (currentCalendar === 'lunch') {
      setCalendarEvents(currentCalendar, eventCalendar?.startAt || '', eventCalendar?.endAt || '');
    }
    if (currentCalendar === 'snack') {
      setCalendarEvents(currentCalendar, eventCalendar?.startAt || '', eventCalendar?.endAt || '');
    }
  };

  const toggleScheduleForm = () => {
    setScheduleForm(!scheduleForm);
  };

  const setCalendarEvents = async (type: string, startAt: string, endAt: string) => {
    if (startAt === '' || endAt === '' || schoolId === '') {
      return;
    }
    try {
      const filters = {
        type: type,
        schoolId: schoolId,
        startAt: startAt,
        endAt: endAt,
      };
      const result = await SchoolScheduleEvents(token).getEventsBySchoolIdAndMonth(filters);
      const dataResult = result.data || [];

      setEventCalendar({
        startAt,
        endAt,
        events: dataResult,
      });
    } catch (e: any) {
      toast.error('No Events found');
    }
  };

  const handleDateClick = (selectInfo: DateClickArg) => {
    const date = new Date(selectInfo.date).toISOString().replace(/T.*$/, ''); // YYYY-MM-DD
    setScheduleId('');
    setDateClick(date);
    modalChange();
  };

  const alterDatesSet = (type: string, args: DatesSetArg) => {
    setEventCalendar({
      startAt: moment(args.start).format('YYYY-MM-DD HH:mm:ss'),
      endAt: moment(args.end).format('YYYY-MM-DD HH:mm:ss'),
      events: [],
    });
    setCalendarEvents(
      type,
      moment(args.start).format('YYYY-MM-DD HH:mm:ss'),
      moment(args.end).format('YYYY-MM-DD HH:mm:ss'),
    );
  };

  const alterDatesSetCalendar = (args: DatesSetArg) => {
    alterDatesSet('calendar', args);
  };

  const modalChange = () => {
    setScheduleForm(!scheduleForm);
  };

  const handleEventClick = (clickInfo: EventClickArg) => {
    // setFormEventType('calendar');
    if (clickInfo.event.classNames[0] == 'schedule-calendar-event-red') {
      //console.log(clickInfo.event.classNames);
    } else {
      setScheduleId(clickInfo.event.id);
      modalChange();
    }
  };

  const renderEventContent = (type: string, eventContent: EventContentArg) => {
    if (currentCalendar === '') {
      return;
    }
    const objectTitle = JSON.parse(eventContent.event.title);
    let innerEventContent;

    switch (currentCalendar) {
      case 'calendar':
        innerEventContent = (
          <>
            <p className="m-0 text-center">
              <b style={{ fontWeight: 'bold' }}>{objectTitle.name}</b>
            </p>
            {!eventContent.event.allDay && (
              <p className="m-0 text-center">
                {moment(eventContent.event.start).format('hh:mma')} - {moment(eventContent.event.end).format('hh:mma')}
              </p>
            )}
            {objectTitle.isClosureSchool && (
              <p className="m-0 text-center" style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
                School Closed
              </p>
            )}
          </>
        );
        break;
      case 'lunch':
        innerEventContent = (
          <>
            <p className="m-0 text-center" style={{ fontWeight: 'bold' }}>
              {objectTitle.protein}
            </p>
            <p className="m-0 text-center" style={{ fontWeight: 'bold' }}>
              {objectTitle.vegetable}
            </p>
            <p className="m-0 text-center" style={{ fontWeight: 'bold' }}>
              {objectTitle.fruits}
            </p>
            <p className="m-0 text-center" style={{ fontWeight: 'bold' }}>
              {objectTitle.drink}
            </p>
          </>
        );
        break;
      case 'snack':
        innerEventContent = (
          <>
            <p className="m-0">
              <b style={{ fontWeight: 'bold' }}>Morning: </b>
              {objectTitle.am}
            </p>
            <p className="m-0">
              <b style={{ fontWeight: 'bold' }}>Afternoon: </b>
              {objectTitle.pm}
            </p>
          </>
        );
        break;
      default:
        innerEventContent = <></>;
    }
    return (
      <>
        <div data-tip={eventContent.event.extendedProps.observations}>{innerEventContent}</div>
        <ReactTooltip />
      </>
    );
  };

  const renderEventsCalendarContent = (eventContent: EventContentArg) => {
    return renderEventContent('calendar', eventContent);
  };

  const changeCurrentCalendar = (type: string) => {
    let indexCurrent = callendars.indexOf(currentCalendar);
    if (type === 'prev') {
      indexCurrent--;
      if (indexCurrent < 0) {
        indexCurrent = 2;
      }
    } else {
      indexCurrent++;
      if (indexCurrent > 2) {
        indexCurrent = 0;
      }
    }
    setCurrentCalendar(callendars[indexCurrent]);
  };

  useEffect(() => {
    if (schoolId === '') {
      return;
    }
    setCalendarEvents(currentCalendar, eventCalendar?.startAt || '', eventCalendar?.endAt || '');
  }, [currentCalendar, schoolId]);

  return (
    <>
      <div className="calendar-app-main">
        <div>
          <h3 className="text-center text-uppercase">
            what's ahead this week
            <br />
            <small className="text-muted">{currentCalendar === 'calendar' ? 'events' : currentCalendar}</small>
          </h3>
          <div className="btn-group" role="group" aria-label="Basic example">
            <button type="button" className="btn btn-sm btn-primary" onClick={() => changeCurrentCalendar('prev')}>
              <i className="fc-icon fc-icon-chevron-left" style={{ fontSize: '1.5em' }}></i>
            </button>
            <button type="button" className="btn btn-sm btn-primary" onClick={() => changeCurrentCalendar('next')}>
              <i className="fc-icon fc-icon-chevron-right" style={{ fontSize: '1.5em' }}></i>
            </button>
          </div>
        </div>
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
          headerToolbar={{
            left: '',
            center: '',
            right: '',
          }}
          eventTimeFormat={{
            hour: 'numeric',
            minute: '2-digit',
            meridiem: 'short',
          }}
          initialView="dayGridWeek"
          // initialView="timeGridWeek"
          editable={false}
          selectable={false}
          selectMirror={true}
          dayMaxEvents={false}
          weekends={document.body.clientWidth > 425}
          events={eventCalendar?.events}
          eventContent={renderEventsCalendarContent} // custom render function
          eventClick={handleEventClick}
          datesSet={alterDatesSetCalendar}
          dateClick={handleDateClick}
          height="350px"
        />
      </div>
      <Modal className="modal-dialog-centered" isOpen={scheduleForm} toggle={() => toggleScheduleForm()}>
        <div className="modal-header">
          <h5 className="modal-title" id="Label">
            {`View Schedule`}
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => toggleScheduleForm()}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <SchoolScheduleEventForm
            isOpen={scheduleForm}
            scheduleId={scheduleId}
            onModalStatus={modalChange}
            dateClick={dateClick}
            onCreateSchedule={handleScheduleCreated}
            formEventType={currentCalendar}
          />
        </div>
      </Modal>
    </>
  );
};
export default SchoolEventsCalendar;
