import { useState } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import SaveDataStatus from './SaveDataStatus';

type SavePaymentDataFormProps = {
  clientSecret?: string;
};

const SavePaymentDataForm = ({ clientSecret }: SavePaymentDataFormProps) => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const { error }: any = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: `${process.env.REACT_APP_FRONTEND_URL}/test-transaction`,
      },
    });

    if (error) {
      setErrorMessage(error.message);
    }
  };

  return (
    <>
      {clientSecret ? (
        <SaveDataStatus clientSecret={clientSecret} />
      ) : (
        <form onSubmit={handleSubmit}>
          <PaymentElement />
          <button className="btn btn-primary mt-2" disabled={!stripe}>
            Submit
          </button>
          {/* Show error message to your customers */}
          {errorMessage && <div>{errorMessage}</div>}
        </form>
      )}
    </>
  );
};

export default SavePaymentDataForm;
