/* eslint-disable max-lines */
import { Button, Card, CardHeader, CardBody, Container, Row, Col, Badge } from 'reactstrap';
import { toast } from 'react-toastify';
import { useContext, useState, useEffect } from 'react';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import CommonHeader from '../../../components/Headers/CommonHeader';
import { Link } from 'react-router-dom';
import SupportService from '../../../services/Support';
import { useParams } from 'react-router-dom';
import SupportListInterface, { validStatus, statusEnum } from '../../../interfaces/SupportListInterface';
import SupportReplyList from './SupportReplyList';
import SupportReplyNew from './SupportReplyNew';
import dateDistance from '../../../helpers/dateDistance';
import history from '../../../history';
import getFormatByMimetypes from '../../../helpers/getFormatByMimetypes';
import { AvatarComponent } from '../../../helpers/generateAvatarRamdomColor';
import RoutingSupportTickets from '../../../services/RoutingSupportTickets';
import AddUserOnDepartmentModal from './AddUserOnDepartmentModal';
import isAdmin from '../../../helpers/isAdmin';

const SupportView = () => {
  const { token, currentSchool, user }: any = useContext(AuthContext);
  const [data, setData] = useState({} as SupportListInterface);
  const { id } = useParams<{ id: string }>();
  const [formatedDate, setFormatedDate] = useState('');
  const [validStatuss, setValidStatuss] = useState('');
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [departmentUsers, setDepartmentUsers] = useState<any[]>([]);
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);

  const handleRequestCloseAddUserModal = () => {
    setIsAddUserModalOpen(false);
  };

  const handleFindRecipients = async (department: string, ticketId: string) => {
    const result = await RoutingSupportTickets(token).list(currentSchool.value);

    const dataResult = result.data.filter(
      (value: any) => value.department === department || value.supportTicketId === ticketId,
    );

    setDepartmentUsers(dataResult);
  };

  const getSupportById = async () => {
    try {
      const result = await SupportService(token).show(id);
      const dataResult: SupportListInterface = result.data;
      setData(dataResult);
      if (dataResult.createdAt) {
        setFormatedDate(dateDistance(dataResult.createdAt));
      }
      if (dataResult.status) {
        setValidStatuss(validStatus[dataResult.status as statusEnum].color);
      }
      if (dataResult.department) {
        handleFindRecipients(dataResult.department, data.id);
      }
    } catch (e: any) {
      toast.error('Error: ' + id);
    }
  };

  const updateStatus = async (status: string) => {
    changeStatus(status);
  };

  const updatePriority = async (priority: string) => {
    changePriority(priority);
  };

  const changePriority = async (priority: string) => {
    if (loading) return;

    setLoading(true);

    const body = {
      status: data.status,
      priority,
    };
    try {
      await SupportService(token).update(id, body);
      toast.success(`Priority updated with successfully!`);
      const newData = data;
      newData.priority = priority;
      setData(newData);
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'error!');
      toast.error(message);
    }

    setLoading(false);
  };

  const changeStatus = async (status: string) => {
    if (loading) return;

    setLoading(true);

    const body = {
      status,
    };
    try {
      const result = await SupportService(token).update(id, body);
      toast.success(`Status updated with successfully!`);
      const newData = data;
      newData.status = status;
      setData(newData);
      if (result?.data?.id) {
        history.push(`/support/${result?.data?.id}`);
      }
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'error!');
      toast.error(message);
    }

    setLoading(false);
  };

  const handleReplyCreated = () => {
    setRefresh(!refresh);
  };

  const ucfirst = (text: string) => {
    if (text === '' || text === undefined) {
      return '';
    }
    return text[0].toUpperCase() + text.substring(1).toLowerCase();
  };

  useEffect(() => {
    getSupportById();
  }, []);

  useEffect(() => {
    handleFindRecipients(data.department, data.id);
  }, [isAddUserModalOpen]);

  return (
    <>
      <CommonHeader />
      <Container className="mt--8" fluid>
        <Row>
          <Col className="order-xl-2" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="10">
                    <Row className="align-items-center">
                      <div className="col-12 d-flex align-items-center">
                        <h4 className="mb-0">
                          <Badge
                            className="badge-dot badge-lg mr-2"
                            style={{ fontSize: 'x-small', verticalAlign: 'middle' }}
                          >
                            <i className={`bg-${validStatuss} mr-2`} style={{ verticalAlign: 'baseline' }} />
                          </Badge>
                          Support ID: {data.id} -{' '}
                          <span style={{ fontWeight: '100' }}>
                            {data.department} - ({data?.user?.firstName || ''} {data?.user?.lastName || ''})
                          </span>
                        </h4>

                        {departmentUsers.map((data: any) => (
                          <div className="ml-2" key={data.id}>
                            <AvatarComponent
                              name={`${data.user.firstName} ${data.user.lastName}`}
                              size="32"
                              userPicture={data.user.picture}
                            />
                          </div>
                        ))}
                        {isAdmin(user) && (
                          <div className="ml-2">
                            <Button
                              color="secondary"
                              className="rounded-circle d-flex align-items-center justify-content-center btn-custom"
                              onClick={() => setIsAddUserModalOpen(true)}
                            >
                              <i className="fas fa-pencil-alt"></i>
                            </Button>
                          </div>
                        )}
                      </div>
                      {isAdmin(user) && (
                        <>
                          <div className="col-12 mt-3 d-flex align-items-center">
                            <h3 className="mb-0 mr-2">Change priority to:</h3>

                            <button
                              className={`btn btn-${data.priority === 'LOW' ? 'primary' : 'secondary'}`}
                              onClick={() => {
                                updatePriority('LOW');
                              }}
                            >
                              LOW
                            </button>
                            <button
                              className={`btn btn-${data.priority === 'MEDIUM' ? 'primary' : 'secondary'}`}
                              onClick={() => {
                                updatePriority('MEDIUM');
                              }}
                            >
                              MEDIUM
                            </button>
                            <button
                              className={`btn btn-${data.priority === 'HIGH' ? 'primary' : 'secondary'}`}
                              onClick={() => {
                                updatePriority('HIGH');
                              }}
                            >
                              HIGH
                            </button>
                          </div>
                          <div className="col-12 mt-3 d-flex align-items-center">
                            <h3 className="mb-0 mr-2">Change status to:</h3>
                            {data.status === 'OPEN' && (
                              <>
                                <button
                                  className="btn btn-primary"
                                  onClick={() => {
                                    updateStatus('OPEN');
                                  }}
                                >
                                  OPEN
                                </button>
                                <button
                                  className="btn btn-secondary"
                                  onClick={() => {
                                    updateStatus('PENDING');
                                  }}
                                >
                                  PENDING
                                </button>
                                <button
                                  className="btn btn-secondary"
                                  onClick={() => {
                                    updateStatus('CLOSED');
                                  }}
                                >
                                  CLOSED
                                </button>
                              </>
                            )}

                            {data.status === 'PENDING' && (
                              <>
                                <button className="btn btn-secondary" disabled>
                                  OPEN
                                </button>
                                <button
                                  className="btn btn-primary"
                                  onClick={() => {
                                    updateStatus('PENDING');
                                  }}
                                >
                                  PENDING
                                </button>
                                <button
                                  className="btn btn-secondary"
                                  onClick={() => {
                                    updateStatus('CLOSED');
                                  }}
                                >
                                  CLOSED
                                </button>
                              </>
                            )}

                            {data.status === 'CLOSED' && (
                              <>
                                <button className="btn btn-secondary" disabled>
                                  OPEN
                                </button>
                                <button className="btn btn-secondary" disabled>
                                  PENDING
                                </button>
                                <button
                                  className="btn btn-primary"
                                  onClick={() => {
                                    updateStatus('CLOSED');
                                  }}
                                >
                                  CLOSED
                                </button>
                              </>
                            )}
                          </div>
                        </>
                      )}
                    </Row>
                  </Col>
                  <Col className="text-right" xs="2">
                    <Link to="/support/">
                      <Button color="primary" className="float-right">
                        Back
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row className="align-items-center">
                  <Col xs="12">
                    <p>Description:</p> {data.description}
                  </Col>
                  {data?.attachment && data?.attachmentMimetype && (
                    <Col xs="12" className="mt-3 mb-2">
                      <i className="fas fa-paperclip" />{' '}
                      <a href={data.attachment} target="_blank">
                        {data.id}.{getFormatByMimetypes(data.attachmentMimetype)}
                      </a>
                    </Col>
                  )}

                  <Col xs="12 mt-4">
                    <p>
                      <strong>Name:</strong> {data.user?.firstName} {data.user?.lastName}
                      <span className="ml-4">
                        <strong>Email:</strong> {data.user?.email}
                      </span>
                    </p>
                    <p></p>
                    <p>
                      <strong>Type:</strong> {ucfirst(data.type)}{' '}
                      <span className="ml-4">
                        <strong>Priority:</strong> {ucfirst(data.priority)}
                      </span>
                    </p>
                    <p>- {formatedDate}</p>
                  </Col>

                  <Col>
                    <hr></hr>
                    {data?.requests?.map((request) => {
                      if (request.type === 'Anniversary T-shirts') {
                        return (
                          <>
                            <h2 className="text-primary font-weight-bold">Anniversary T-shirts</h2>
                            <hr></hr>

                            <Row>
                              <Col lg="6" className="mb-4 text-primary font-weight-bold">
                                {request.model}
                              </Col>
                            </Row>

                            <Row>
                              <Col lg="2">
                                <p className="text-primary font-weight-bold">XS</p>

                                <h4>{request.xsQuantity}</h4>
                              </Col>

                              <Col lg="2">
                                <p className="text-primary font-weight-bold">S</p>

                                <h4>{request.sQuantity}</h4>
                              </Col>

                              <Col lg="2">
                                <p className="text-primary font-weight-bold">L</p>

                                <h4>{request.lQuantity}</h4>
                              </Col>

                              <Col lg="2">
                                <p className="text-primary font-weight-bold">XL</p>

                                <h4>{request.xlQuantity}</h4>
                              </Col>

                              <Col lg="2">
                                <p className="text-primary font-weight-bold">2XL</p>

                                <h4>{request['2xlQuantity']}</h4>
                              </Col>

                              <Col lg="2">
                                <p className="text-primary font-weight-bold">3XL</p>

                                <h4>{request['3xlQuantity']}</h4>
                              </Col>
                            </Row>
                            <hr></hr>
                          </>
                        );
                      } else
                        return (
                          <>
                            <h2 className="text-primary font-weight-bold">{request.type}</h2>
                            <hr></hr>

                            {request.model && (
                              <Row>
                                <Col lg="6" className="mb-4 text-primary font-weight-bold">
                                  {request.model}
                                </Col>
                              </Row>
                            )}

                            <Row>
                              <Col lg="2">
                                <p className="text-primary font-weight-bold">XS</p>

                                <h4>{request.xsQuantity}</h4>
                              </Col>

                              <Col lg="2">
                                <p className="text-primary font-weight-bold">S</p>

                                <h4>{request.sQuantity}</h4>
                              </Col>

                              <Col lg="2">
                                <p className="text-primary font-weight-bold">L</p>

                                <h4>{request.lQuantity}</h4>
                              </Col>

                              <Col lg="2">
                                <p className="text-primary font-weight-bold">XL</p>

                                <h4>{request.xlQuantity}</h4>
                              </Col>

                              <Col lg="2">
                                <p className="text-primary font-weight-bold">2XL</p>

                                <h4>{request['2xlQuantity']}</h4>
                              </Col>

                              <Col lg="2">
                                <p className="text-primary font-weight-bold">3XL</p>

                                <h4>{request['3xlQuantity']}</h4>
                              </Col>
                            </Row>
                            <hr></hr>
                          </>
                        );
                    })}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <SupportReplyList refresh={refresh} />
      <SupportReplyNew onCreateReply={handleReplyCreated} />
      <AddUserOnDepartmentModal
        isOpen={isAddUserModalOpen}
        OnRequestedClose={handleRequestCloseAddUserModal}
        department={data.department}
        ticketId={data.id}
      />
    </>
  );
};

export default SupportView;
