import http from '../helpers/http';

const BlackoutDate = (token: string) => {
  const headers = {
    'x-access-token': token,
  };

  return {
    list: async (schoolId: string) => {
      const result = await http.get(`/blackout-date/${schoolId}`, {
        headers,
      });
      return result.data;
    },
    create: async (body: { schoolId: string; reason: string; startDate: string; endDate: string }) => {
      const result = await http.post(`/blackout-date`, body, { headers });
      return result.data;
    },
    delete: async (id: string) => {
      const result = await http.delete(`/blackout-date/${id}`, {
        headers,
      });
      return result.data;
    },
    update: async (id: string, body: any) => {
      const result = await http.put(`/blackout-date/${id}`, body, {
        headers,
      });
      return result.data;
    },
  };
};

export default BlackoutDate;
