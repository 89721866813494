const ClassManageKidsReminderBox = ({ type, kid, onClick }: { type: string; kid: any; onClick: any }) => {
  const iconTypes = {
    BATHROOM: 'fa-toilet',
    FOOD: 'fa-utensils',
  };
  return (
    <>
      <button
        className={`btn btn-${type} w-100 p-1 m-0 rounded-0 text-left`}
        onClick={(e) => {
          onClick(e, kid.id);
        }}
      >
        <span className="fa-stack float-left" style={{ verticalAlign: 'top', fontSize: '11px' }}>
          <i className="far fa-circle fa-stack-2x" />
          <i
            className={`fas ${(iconTypes as Record<string, string | undefined>)[kid.type]} fa-stack-1x`}
            style={{ marginLeft: 0 }}
          />
        </span>
        &nbsp;
        <span style={{ fontSize: '12px' }}>{`${kid.firstName} ${kid.lastName}`}</span>
      </button>
    </>
  );
};

export default ClassManageKidsReminderBox;
