/* eslint-disable prettier/prettier */
/* eslint-disable max-lines */
import { useContext, useEffect, useState } from 'react';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { Context as AuthContext } from '../../../../contexts/AuthContext';
import moment from 'moment';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faSpinner } from '@fortawesome/free-solid-svg-icons';
import SchoolService from '../../../../services/School';
import { saveAs } from 'file-saver';
import { font as robotoRegular } from '../../../../assets/fonts/Roboto-Regular';
import { font as robotoMedium } from '../../../../assets/fonts/Roboto-Medium';
import { font as robotoItalic } from '../../../../assets/fonts/Roboto-Italic';
import { font as robotoMediumItalic } from '../../../../assets/fonts/Roboto-MediumItalic';
import { font as dancingScript } from '../../../../assets/fonts/DancingScript';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.vfs['Roboto-Regular.ttf'] = robotoRegular;
pdfMake.vfs['Roboto-Medium.ttf'] = robotoMedium;
pdfMake.vfs['Roboto-Italic.ttf'] = robotoItalic;
pdfMake.vfs['Roboto-MediumItalic.ttf'] = robotoMediumItalic;
pdfMake.vfs['DancingScript.ttf'] = dancingScript;

const ReportPage = ({ data }: any) => {
  const { token, currentSchool }: any = useContext(AuthContext);
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);
  const [school, setSchool] = useState<any>();
  const [schoolAddress, setSchoolAddress] = useState<any>();
  const dataInicial = moment(data.kids?.birthday);
  const dataFinal = moment();

  const length = data.length;

  const listSelectSchool = async () => {
    const result = await SchoolService(token).show(currentSchool.value);
    const resultAddress = await SchoolService(token).showAddress(currentSchool.value);
    setSchool(result.data || []);
    setSchoolAddress(resultAddress.data || []);
  };

  useEffect(() => {
    listSelectSchool();
  }, []);

  const generatePDF = async () => {
    try {
      setIsGeneratingPDF(true);
      const imageUrl = school?.franchisee?.imageUrl;
      const response = await fetch(imageUrl);
      const imageBlob = await response.blob();
      const reader = new FileReader();

      reader.onloadend = () => {
        const imageDataUrl = reader.result as string;

        const result = data.map((data: any, index: number) => {
          return [
            {
              columns: [
                {
                  stack: [
                    { text: 'ACCIDENT / INCIDENT REPORT', style: 'underlineHeader' },
                    { text: 'Facility', style: 'underlineHeader' },
                    { text: school?.name, style: 'header' },
                    {
                      // eslint-disable-next-line max-len
                      text: `${schoolAddress?.address}, ${schoolAddress?.city}, ${schoolAddress?.state.uf} ${schoolAddress?.zipCode}`,
                      style: 'header',
                    },
                  ],
                  alignment: 'left',
                },
                {
                  image: imageDataUrl,
                  width: 120,
                  height: 80,
                  alignment: 'right',
                },
              ],
            },
            {
              margin: [0, 20, 0, 10],
              columns: [
                { width: 'auto', text: 'First/Last Name:', bold: true, fontSize: 12, color: '#6d6e70' },
                {
                  width: '*',
                  stack: [
                    { text: `${data.kids?.firstName} ${data.kids?.lastName}`, margin: [5, 0, 0, 0] },
                    {
                      canvas: [
                        {
                          type: 'line',
                          x1: 0,
                          y1: 0,
                          x2: 400,
                          y2: 5,
                          lineWidth: 1,
                          lineColor: '#6d6e70',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              columns: [
                { width: 'auto', text: 'Date of Birth:', bold: true, fontSize: 12, color: '#6d6e70' },
                { width: 'auto', text: moment(data.kids?.birthday).format('MM/DD/YY'), margin: [5, 1, 0, 0] },
                { width: 'auto', text: 'Age:', bold: true, fontSize: 12, margin: [10, 0, 0, 0], color: '#6d6e70' },
                { width: 'auto', text: dataFinal.diff(dataInicial, 'years'), margin: [5, 1, 0, 0] },
                { width: 'auto', text: 'Gender:', bold: true, fontSize: 12, margin: [20, 0, 0, 0], color: '#6d6e70' },
                {
                  stack: [
                    {
                      canvas: [
                        {
                          type: 'rect',
                          x: 0,
                          y: 0,
                          w: 20,
                          h: 10,
                          lineWidth: 1,
                          lineColor: 'black',
                        },
                        {
                          type: 'line',
                          x1: 8,
                          y1: 3,
                          x2: 12,
                          y2: 8,
                          lineWidth: 1,
                          lineColor: data.kids?.gender === 'BOY' ? 'black' : 'white',
                        },
                        {
                          type: 'line',
                          x1: 12,
                          y1: 3,
                          x2: 8,
                          y2: 8,
                          lineWidth: 1,
                          lineColor: data.kids?.gender === 'BOY' ? 'black' : 'white',
                        },
                      ],
                    },
                  ],
                  width: 'auto',
                  margin: [10, 2, 0, 0],
                  alignment: 'center',
                },
                { width: 'auto', text: 'Male', alignment: 'center', margin: [5, 1, 0, 0], color: '#6d6e70' },
                {
                  stack: [
                    {
                      canvas: [
                        {
                          type: 'rect',
                          x: 0,
                          y: 0,
                          w: 20,
                          h: 10,
                          lineWidth: 1,
                          lineColor: 'black',
                        },
                        {
                          type: 'line',
                          x1: 8,
                          y1: 3,
                          x2: 12,
                          y2: 8,
                          lineWidth: 1,
                          lineColor: data.kids?.gender === 'GIRL' ? 'black' : 'white',
                        },
                        {
                          type: 'line',
                          x1: 12,
                          y1: 3,
                          x2: 8,
                          y2: 8,
                          lineWidth: 1,
                          lineColor: data.kids?.gender === 'GIRL' ? 'black' : 'white',
                        },
                      ],
                    },
                  ],
                  width: 'auto',
                  margin: [10, 2, 0, 0],
                  alignment: 'center',
                },
                { width: 'auto', text: 'Female', margin: [5, 1, 0, 0], alignment: 'center', color: '#6d6e70' },
                {
                  stack: [
                    {
                      canvas: [
                        {
                          type: 'rect',
                          x: 0,
                          y: 0,
                          w: 20,
                          h: 10,
                          lineWidth: 1,
                          lineColor: 'black',
                        },
                        {
                          type: 'line',
                          x1: 8,
                          y1: 3,
                          x2: 12,
                          y2: 8,
                          lineWidth: 1,
                          lineColor: data.kids?.gender === 'UNKNOWN' ? 'black' : 'white',
                        },
                        {
                          type: 'line',
                          x1: 12,
                          y1: 3,
                          x2: 8,
                          y2: 8,
                          lineWidth: 1,
                          lineColor: data.kids?.gender === 'UNKNOWN' ? 'black' : 'white',
                        },
                      ],
                    },
                  ],
                  width: 'auto',
                  margin: [10, 2, 0, 0],
                  alignment: 'center',
                },
                { width: 'auto', text: 'Unknown', margin: [5, 1, 0, 0], alignment: 'center', color: '#6d6e70' },
              ],
            },
            {
              margin: [0, 10, 0, 10],
              columns: [{ text: 'WHO WAS AFFECTED OR INJURED', style: 'title', bold: true }],
            },
            {
              columns: [
                {
                  stack: [
                    {
                      canvas: [
                        {
                          type: 'rect',
                          x: 0,
                          y: 0,
                          w: 20,
                          h: 10,
                          lineWidth: 1,
                          lineColor: 'black',
                        },
                        {
                          type: 'line',
                          x1: 8,
                          y1: 3,
                          x2: 12,
                          y2: 8,
                          lineWidth: 1,
                          lineColor: data.afectedOrInjured === 'Child' ? 'black' : 'white',
                        },
                        {
                          type: 'line',
                          x1: 12,
                          y1: 3,
                          x2: 8,
                          y2: 8,
                          lineWidth: 1,
                          lineColor: data.afectedOrInjured === 'Child' ? 'black' : 'white',
                        },
                      ],
                    },
                  ],
                  width: 'auto',
                  margin: [0, 2, 0, 0],
                  alignment: 'center',
                },
                { width: 'auto', text: 'Child', alignment: 'center', margin: [5, 1, 0, 0], color: '#6d6e70' },
                {
                  stack: [
                    {
                      canvas: [
                        {
                          type: 'rect',
                          x: 0,
                          y: 0,
                          w: 20,
                          h: 10,
                          lineWidth: 1,
                          lineColor: 'black',
                        },
                        {
                          type: 'line',
                          x1: 8,
                          y1: 3,
                          x2: 12,
                          y2: 8,
                          lineWidth: 1,
                          lineColor: data.afectedOrInjured === 'Parent' ? 'black' : 'white',
                        },
                        {
                          type: 'line',
                          x1: 12,
                          y1: 3,
                          x2: 8,
                          y2: 8,
                          lineWidth: 1,
                          lineColor: data.afectedOrInjured === 'Parent' ? 'black' : 'white',
                        },
                      ],
                    },
                  ],
                  width: 'auto',
                  margin: [10, 2, 0, 0],
                  alignment: 'center',
                },
                { width: 'auto', text: 'Parent', margin: [5, 1, 0, 0], alignment: 'center', color: '#6d6e70' },
                {
                  stack: [
                    {
                      canvas: [
                        {
                          type: 'rect',
                          x: 0,
                          y: 0,
                          w: 20,
                          h: 10,
                          lineWidth: 1,
                          lineColor: 'black',
                        },
                        {
                          type: 'line',
                          x1: 8,
                          y1: 3,
                          x2: 12,
                          y2: 8,
                          lineWidth: 1,
                          lineColor: data.afectedOrInjured === 'Staff' ? 'black' : 'white',
                        },
                        {
                          type: 'line',
                          x1: 12,
                          y1: 3,
                          x2: 8,
                          y2: 8,
                          lineWidth: 1,
                          lineColor: data.afectedOrInjured === 'Staff' ? 'black' : 'white',
                        },
                      ],
                    },
                  ],
                  width: 'auto',
                  margin: [10, 2, 0, 0],
                  alignment: 'center',
                },
                { width: 'auto', text: 'Staff', margin: [5, 1, 0, 0], alignment: 'center', color: '#6d6e70' },
                {
                  stack: [
                    {
                      canvas: [
                        {
                          type: 'rect',
                          x: 0,
                          y: 0,
                          w: 20,
                          h: 10,
                          lineWidth: 1,
                          lineColor: 'black',
                        },
                        {
                          type: 'line',
                          x1: 8,
                          y1: 3,
                          x2: 12,
                          y2: 8,
                          lineWidth: 1,
                          lineColor: data.afectedOrInjured === 'Visitor' ? 'black' : 'white',
                        },
                        {
                          type: 'line',
                          x1: 12,
                          y1: 3,
                          x2: 8,
                          y2: 8,
                          lineWidth: 1,
                          lineColor: data.afectedOrInjured === 'Visitor' ? 'black' : 'white',
                        },
                      ],
                    },
                  ],
                  width: 'auto',
                  margin: [10, 2, 0, 0],
                  alignment: 'center',
                },
                { width: 'auto', text: 'Visitor', margin: [5, 1, 0, 0], alignment: 'center', color: '#6d6e70' },
              ],
            },
            {
              margin: [0, 10, 0, 10],
              columns: [
                { width: 'auto', text: 'INCIDENT DESCRIPTION', bold: true, fontSize: 12, color: '#6d6e70' },
                {
                  width: 'auto',
                  text: 'Type of Incident:',
                  fontSize: 12,
                  margin: [5, 0, 0, 0],
                  color: '#6d6e70',
                },
                {
                  stack: [
                    {
                      canvas: [
                        {
                          type: 'rect',
                          x: 0,
                          y: 0,
                          w: 20,
                          h: 10,
                          lineWidth: 1,
                          lineColor: 'black',
                        },
                        {
                          type: 'line',
                          x1: 8,
                          y1: 3,
                          x2: 12,
                          y2: 8,
                          lineWidth: 1,
                          lineColor: data.type === 'Accident/Injury' ? 'black' : 'white',
                        },
                        {
                          type: 'line',
                          x1: 12,
                          y1: 3,
                          x2: 8,
                          y2: 8,
                          lineWidth: 1,
                          lineColor: data.type === 'Accident/Injury' ? 'black' : 'white',
                        },
                      ],
                    },
                  ],
                  width: 'auto',
                  margin: [5, 2, 0, 0],
                  alignment: 'center',
                },
                {
                  width: 'auto',
                  text: 'Accident/Injury',
                  alignment: 'center',
                  fontSize: 10,
                  margin: [3, 1, 0, 0],
                  color: '#6d6e70',
                },
                {
                  stack: [
                    {
                      canvas: [
                        {
                          type: 'rect',
                          x: 0,
                          y: 0,
                          w: 20,
                          h: 10,
                          lineWidth: 1,
                          lineColor: 'black',
                        },
                        {
                          type: 'line',
                          x1: 8,
                          y1: 3,
                          x2: 12,
                          y2: 8,
                          lineWidth: 1,
                          lineColor: data.type === 'Illness' ? 'black' : 'white',
                        },
                        {
                          type: 'line',
                          x1: 12,
                          y1: 3,
                          x2: 8,
                          y2: 8,
                          lineWidth: 1,
                          lineColor: data.type === 'Illness' ? 'black' : 'white',
                        },
                      ],
                    },
                  ],
                  width: 'auto',
                  margin: [5, 2, 0, 0],
                  alignment: 'center',
                },
                {
                  width: 'auto',
                  text: 'Illness',
                  margin: [3, 1, 0, 0],
                  alignment: 'center',
                  fontSize: 10,
                  color: '#6d6e70',
                },
                {
                  stack: [
                    {
                      canvas: [
                        {
                          type: 'rect',
                          x: 0,
                          y: 0,
                          w: 20,
                          h: 10,
                          lineWidth: 1,
                          lineColor: 'black',
                        },
                        {
                          type: 'line',
                          x1: 8,
                          y1: 3,
                          x2: 12,
                          y2: 8,
                          lineWidth: 1,
                          lineColor: data.type === 'Observation/Incident' ? 'black' : 'white',
                        },
                        {
                          type: 'line',
                          x1: 12,
                          y1: 3,
                          x2: 8,
                          y2: 8,
                          lineWidth: 1,
                          lineColor: data.type === 'Observation/Incident' ? 'black' : 'white',
                        },
                      ],
                    },
                  ],
                  width: 'auto',
                  margin: [5, 2, 0, 0],
                  alignment: 'center',
                },
                {
                  width: 'auto',
                  text: 'Observation/Incident',
                  margin: [3, 1, 0, 0],
                  alignment: 'center',
                  fontSize: 10,
                  color: '#6d6e70',
                },
              ],
            },
            {
              columns: [
                { width: 'auto', text: 'Date of Accident/Incident:', bold: true, fontSize: 12, color: '#6d6e70' },
                {
                  width: 'auto',
                  text: moment(data.acidentDate).format('MM/DD/YY'),
                  fontSize: 12,
                  margin: [5, 1, 0, 0],
                },
                {
                  width: 'auto',
                  text: 'Time of Accident/Incident:',
                  bold: true,
                  fontSize: 12,
                  margin: [10, 0, 0, 0],
                  color: '#6d6e70',
                },
                { width: 'auto', text: moment(data.acidentTime).format('h:mm'), fontSize: 12, margin: [5, 1, 0, 0] },
                {
                  stack: [
                    {
                      canvas: [
                        {
                          type: 'rect',
                          x: 0,
                          y: 0,
                          w: 20,
                          h: 10,
                          lineWidth: 1,
                          lineColor: 'black',
                        },
                        {
                          type: 'line',
                          x1: 8,
                          y1: 3,
                          x2: 12,
                          y2: 8,
                          lineWidth: 1,
                          lineColor: moment(data.acidentTime).format('A') === 'AM' ? 'black' : 'white',
                        },
                        {
                          type: 'line',
                          x1: 12,
                          y1: 3,
                          x2: 8,
                          y2: 8,
                          lineWidth: 1,
                          lineColor: moment(data.acidentTime).format('A') === 'AM' ? 'black' : 'white',
                        },
                      ],
                    },
                  ],
                  width: 'auto',
                  margin: [10, 2, 0, 0],
                  alignment: 'center',
                },
                {
                  width: 'auto',
                  text: 'AM',
                  margin: [5, 1, 0, 0],
                  alignment: 'center',
                  fontSize: 10,
                  color: '#6d6e70',
                },
                {
                  stack: [
                    {
                      canvas: [
                        {
                          type: 'rect',
                          x: 0,
                          y: 0,
                          w: 20,
                          h: 10,
                          lineWidth: 1,
                          lineColor: 'black',
                        },
                        {
                          type: 'line',
                          x1: 8,
                          y1: 3,
                          x2: 12,
                          y2: 8,
                          lineWidth: 1,
                          lineColor: moment(data.acidentTime).format('A') === 'PM' ? 'black' : 'white',
                        },
                        {
                          type: 'line',
                          x1: 12,
                          y1: 3,
                          x2: 8,
                          y2: 8,
                          lineWidth: 1,
                          lineColor: moment(data.acidentTime).format('A') === 'PM' ? 'black' : 'white',
                        },
                      ],
                    },
                  ],
                  width: 'auto',
                  margin: [10, 2, 0, 0],
                  alignment: 'center',
                },
                {
                  width: 'auto',
                  text: 'PM',
                  margin: [5, 1, 0, 0],
                  alignment: 'center',
                  fontSize: 10,
                  color: '#6d6e70',
                },
              ],
            },
            {
              margin: [0, 10, 0, 10],
              columns: [
                {
                  width: 'auto',
                  text: 'Location of Accident/Incident:',
                  fontSize: 12,
                  bold: true,
                  margin: [0, 0, 0, 0],
                  color: '#6d6e70',
                },
                {
                  stack: [
                    {
                      canvas: [
                        {
                          type: 'rect',
                          x: 0,
                          y: 0,
                          w: 20,
                          h: 10,
                          lineWidth: 1,
                          lineColor: 'black',
                        },
                        {
                          type: 'line',
                          x1: 8,
                          y1: 3,
                          x2: 12,
                          y2: 8,
                          lineWidth: 1,
                          lineColor: data.location === 'Classroom' ? 'black' : 'white',
                        },
                        {
                          type: 'line',
                          x1: 12,
                          y1: 3,
                          x2: 8,
                          y2: 8,
                          lineWidth: 1,
                          lineColor: data.location === 'Classroom' ? 'black' : 'white',
                        },
                      ],
                    },
                  ],
                  width: 'auto',
                  margin: [5, 2, 0, 0],
                  alignment: 'center',
                },
                {
                  width: 'auto',
                  text: 'Classroom',
                  alignment: 'center',
                  fontSize: 10,
                  margin: [3, 1, 0, 0],
                  color: '#6d6e70',
                },
                {
                  stack: [
                    {
                      canvas: [
                        {
                          type: 'rect',
                          x: 0,
                          y: 0,
                          w: 20,
                          h: 10,
                          lineWidth: 1,
                          lineColor: 'black',
                        },
                        {
                          type: 'line',
                          x1: 8,
                          y1: 3,
                          x2: 12,
                          y2: 8,
                          lineWidth: 1,
                          lineColor: data.location === 'Playground' ? 'black' : 'white',
                        },
                        {
                          type: 'line',
                          x1: 12,
                          y1: 3,
                          x2: 8,
                          y2: 8,
                          lineWidth: 1,
                          lineColor: data.location === 'Playground' ? 'black' : 'white',
                        },
                      ],
                    },
                  ],
                  width: 'auto',
                  margin: [5, 2, 0, 0],
                  alignment: 'center',
                },
                {
                  width: 'auto',
                  text: 'Playground',
                  margin: [3, 1, 0, 0],
                  alignment: 'center',
                  fontSize: 10,
                  color: '#6d6e70',
                },
                {
                  stack: [
                    {
                      canvas: [
                        {
                          type: 'rect',
                          x: 0,
                          y: 0,
                          w: 20,
                          h: 10,
                          lineWidth: 1,
                          lineColor: 'black',
                        },
                        {
                          type: 'line',
                          x1: 8,
                          y1: 3,
                          x2: 12,
                          y2: 8,
                          lineWidth: 1,
                          lineColor: data.location === 'Bathroom' ? 'black' : 'white',
                        },
                        {
                          type: 'line',
                          x1: 12,
                          y1: 3,
                          x2: 8,
                          y2: 8,
                          lineWidth: 1,
                          lineColor: data.location === 'Bathroom' ? 'black' : 'white',
                        },
                      ],
                    },
                  ],
                  width: 'auto',
                  margin: [5, 2, 0, 0],
                  alignment: 'center',
                },
                {
                  width: 'auto',
                  text: 'Bathroom',
                  margin: [3, 1, 0, 0],
                  alignment: 'center',
                  fontSize: 10,
                  color: '#6d6e70',
                },
                {
                  stack: [
                    {
                      canvas: [
                        {
                          type: 'rect',
                          x: 0,
                          y: 0,
                          w: 20,
                          h: 10,
                          lineWidth: 1,
                          lineColor: 'black',
                        },
                        {
                          type: 'line',
                          x1: 8,
                          y1: 3,
                          x2: 12,
                          y2: 8,
                          lineWidth: 1,
                          lineColor: data.location === 'Other' ? 'black' : 'white',
                        },
                        {
                          type: 'line',
                          x1: 12,
                          y1: 3,
                          x2: 8,
                          y2: 8,
                          lineWidth: 1,
                          lineColor: data.location === 'Other' ? 'black' : 'white',
                        },
                      ],
                    },
                  ],
                  width: 'auto',
                  margin: [5, 2, 0, 0],
                  alignment: 'center',
                },
                {
                  width: 'auto',
                  text: 'Other',
                  margin: [3, 1, 0, 0],
                  alignment: 'center',
                  fontSize: 10,
                  color: '#6d6e70',
                },
              ],
            },
            {
              margin: [0, 0, 0, 10],
              columns: [
                {
                  width: 'auto',
                  text: [
                    { text: 'Describe Accident/Incident: ', noWrap: true, bold: true },
                    {
                      text: data.incidentDescription,
                    },
                  ],
                  fontSize: 12,
                  color: '#6d6e70',
                },
              ],
            },
            {
              columns: [
                {
                  width: 'auto',
                  text: [
                    { text: 'Describe Nature of Injury/Illness: ', noWrap: true, bold: true },
                    {
                      text: data.injuryNatureDescription,
                    },
                  ],
                  fontSize: 12,
                  color: '#6d6e70',
                },
              ],
            },
            {
              margin: [0, 10, 0, 10],
              columns: [
                {
                  width: 'auto',
                  text: [
                    { text: 'Describe Medical Treatment/First Aid: ', noWrap: true, bold: true },
                    {
                      text: data.medicalTreatmentDescription,
                    },
                  ],
                  fontSize: 12,
                  color: '#6d6e70',
                },
              ],
            },
            {
              margin: [0, 0, 0, 10],
              columns: [
                {
                  width: 'auto',
                  text: 'Ambulance Called:',
                  fontSize: 12,
                  bold: true,
                  margin: [0, 0, 0, 0],
                  color: '#6d6e70',
                },
                {
                  stack: [
                    {
                      canvas: [
                        {
                          type: 'rect',
                          x: 0,
                          y: 0,
                          w: 20,
                          h: 10,
                          lineWidth: 1,
                          lineColor: 'black',
                        },
                        {
                          type: 'line',
                          x1: 8,
                          y1: 3,
                          x2: 12,
                          y2: 8,
                          lineWidth: 1,
                          lineColor: data.ambulanceCalled === true ? 'black' : 'white',
                        },
                        {
                          type: 'line',
                          x1: 12,
                          y1: 3,
                          x2: 8,
                          y2: 8,
                          lineWidth: 1,
                          lineColor: data.ambulanceCalled === true ? 'black' : 'white',
                        },
                      ],
                    },
                  ],
                  width: 'auto',
                  margin: [5, 2, 0, 0],
                  alignment: 'center',
                },
                {
                  width: 'auto',
                  text: 'Yes',
                  alignment: 'center',
                  fontSize: 10,
                  margin: [3, 1, 0, 0],
                  color: '#6d6e70',
                },
                {
                  stack: [
                    {
                      canvas: [
                        {
                          type: 'rect',
                          x: 0,
                          y: 0,
                          w: 20,
                          h: 10,
                          lineWidth: 1,
                          lineColor: 'black',
                        },
                        {
                          type: 'line',
                          x1: 8,
                          y1: 3,
                          x2: 12,
                          y2: 8,
                          lineWidth: 1,
                          lineColor: data.ambulanceCalled === false ? 'black' : 'white',
                        },
                        {
                          type: 'line',
                          x1: 12,
                          y1: 3,
                          x2: 8,
                          y2: 8,
                          lineWidth: 1,
                          lineColor: data.ambulanceCalled === false ? 'black' : 'white',
                        },
                      ],
                    },
                  ],
                  width: 'auto',
                  margin: [5, 2, 0, 0],
                  alignment: 'center',
                },
                {
                  width: 'auto',
                  text: 'No',
                  margin: [3, 1, 0, 0],
                  alignment: 'center',
                  fontSize: 10,
                  color: '#6d6e70',
                },
              ],
            },
            {
              margin: [0, 0, 0, 10],
              columns: [
                {
                  width: 'auto',
                  text: [
                    { text: 'Witness(es)toAccident/Incident: ', noWrap: true, bold: true },
                    {
                      text: data.witness,
                    },
                  ],
                  fontSize: 12,
                  color: '#6d6e70',
                },
              ],
            },
            {
              margin: [0, 0, 0, 10],
              columns: [
                {
                  width: 'auto',
                  text: [
                    { text: 'What Action Was Taken & byWhom? ', noWrap: true, bold: true },
                    {
                      text: data.action,
                    },
                  ],
                  fontSize: 12,
                  color: '#6d6e70',
                },
              ],
            },
            {
              canvas: [
                {
                  type: 'line',
                  x1: 0,
                  y1: 0,
                  x2: 500,
                  y2: 0,
                  lineWidth: 1,
                  lineColor: 'black',
                },
              ],
            },
            {
              margin: [0, 10, 0, 10],
              columns: [{ text: 'NOTIFICATIONS & SIGNATURES', style: 'title', bold: true }],
            },
            {
              margin: [0, 0, 0, 10],
              columns: [
                {
                  width: 'auto',
                  text: 'Was Parent/Guardian Contacted?',
                  fontSize: 12,
                  bold: true,
                  margin: [0, 0, 0, 0],
                  color: '#6d6e70',
                },
                {
                  stack: [
                    {
                      canvas: [
                        {
                          type: 'rect',
                          x: 0,
                          y: 0,
                          w: 20,
                          h: 10,
                          lineWidth: 1,
                          lineColor: 'black',
                        },
                        {
                          type: 'line',
                          x1: 8,
                          y1: 3,
                          x2: 12,
                          y2: 8,
                          lineWidth: 1,
                          lineColor: data.ambulanceCalled === true ? 'black' : 'white',
                        },
                        {
                          type: 'line',
                          x1: 12,
                          y1: 3,
                          x2: 8,
                          y2: 8,
                          lineWidth: 1,
                          lineColor: data.ambulanceCalled === true ? 'black' : 'white',
                        },
                      ],
                    },
                  ],
                  width: 'auto',
                  margin: [5, 2, 0, 0],
                  alignment: 'center',
                },
                {
                  width: 'auto',
                  text: 'Yes',
                  alignment: 'center',
                  fontSize: 10,
                  margin: [3, 1, 0, 0],
                  color: '#6d6e70',
                },
                {
                  stack: [
                    {
                      canvas: [
                        {
                          type: 'rect',
                          x: 0,
                          y: 0,
                          w: 20,
                          h: 10,
                          lineWidth: 1,
                          lineColor: 'black',
                        },
                        {
                          type: 'line',
                          x1: 8,
                          y1: 3,
                          x2: 12,
                          y2: 8,
                          lineWidth: 1,
                          lineColor: data.ambulanceCalled === false ? 'black' : 'white',
                        },
                        {
                          type: 'line',
                          x1: 12,
                          y1: 3,
                          x2: 8,
                          y2: 8,
                          lineWidth: 1,
                          lineColor: data.ambulanceCalled === false ? 'black' : 'white',
                        },
                      ],
                    },
                  ],
                  width: 'auto',
                  margin: [5, 2, 0, 0],
                  alignment: 'center',
                },
                {
                  width: 'auto',
                  text: 'No',
                  margin: [3, 1, 0, 0],
                  alignment: 'center',
                  fontSize: 10,
                  color: '#6d6e70',
                },
                {
                  stack: [
                    {
                      canvas: [
                        {
                          type: 'rect',
                          x: 0,
                          y: 0,
                          w: 20,
                          h: 10,
                          lineWidth: 1,
                          lineColor: 'black',
                        },
                        {
                          type: 'line',
                          x1: 8,
                          y1: 3,
                          x2: 12,
                          y2: 8,
                          lineWidth: 1,
                          lineColor: data.ambulanceCalled === null ? 'black' : 'white',
                        },
                        {
                          type: 'line',
                          x1: 12,
                          y1: 3,
                          x2: 8,
                          y2: 8,
                          lineWidth: 1,
                          lineColor: data.ambulanceCalled === null ? 'black' : 'white',
                        },
                      ],
                    },
                  ],
                  width: 'auto',
                  margin: [5, 2, 0, 0],
                  alignment: 'center',
                },
                {
                  width: 'auto',
                  text: 'N/A',
                  margin: [3, 1, 0, 0],
                  alignment: 'center',
                  fontSize: 10,
                  color: '#6d6e70',
                },
                {
                  width: 'auto',
                  text: 'Time?',
                  bold: true,
                  fontSize: 12,
                  margin: [10, 0, 0, 0],
                  color: '#6d6e70',
                },
                {
                  width: 'auto',
                  text: data.parentContactedTime ? moment(data.parentContactedTime).format('h:mm') : '-',
                  fontSize: 12,
                  margin: [5, 1, 0, 0],
                },
                {
                  stack: [
                    {
                      canvas: [
                        {
                          type: 'rect',
                          x: 0,
                          y: 0,
                          w: 20,
                          h: 10,
                          lineWidth: 1,
                          lineColor: 'black',
                        },
                        {
                          type: 'line',
                          x1: 8,
                          y1: 3,
                          x2: 12,
                          y2: 8,
                          lineWidth: 1,
                          lineColor: moment(data.parentContactedTime).format('A') === 'AM' ? 'black' : 'white',
                        },
                        {
                          type: 'line',
                          x1: 12,
                          y1: 3,
                          x2: 8,
                          y2: 8,
                          lineWidth: 1,
                          lineColor: moment(data.parentContactedTime).format('A') === 'AM' ? 'black' : 'white',
                        },
                      ],
                    },
                  ],
                  width: 'auto',
                  margin: [10, 2, 0, 0],
                  alignment: 'center',
                },
                {
                  width: 'auto',
                  text: 'AM',
                  margin: [5, 1, 0, 0],
                  alignment: 'center',
                  fontSize: 10,
                  color: '#6d6e70',
                },
                {
                  stack: [
                    {
                      canvas: [
                        {
                          type: 'rect',
                          x: 0,
                          y: 0,
                          w: 20,
                          h: 10,
                          lineWidth: 1,
                          lineColor: 'black',
                        },
                        {
                          type: 'line',
                          x1: 8,
                          y1: 3,
                          x2: 12,
                          y2: 8,
                          lineWidth: 1,
                          lineColor: moment(data.parentContactedTime).format('A') === 'PM' ? 'black' : 'white',
                        },
                        {
                          type: 'line',
                          x1: 12,
                          y1: 3,
                          x2: 8,
                          y2: 8,
                          lineWidth: 1,
                          lineColor: moment(data.parentContactedTime).format('A') === 'PM' ? 'black' : 'white',
                        },
                      ],
                    },
                  ],
                  width: 'auto',
                  margin: [10, 2, 0, 0],
                  alignment: 'center',
                },
                {
                  width: 'auto',
                  text: 'PM',
                  margin: [5, 1, 0, 0],
                  alignment: 'center',
                  fontSize: 10,
                  color: '#6d6e70',
                },
              ],
            },
            {
              margin: [0, 0, 0, 10],
              columns: [
                {
                  width: 'auto',
                  text: 'How? Via ',
                  fontSize: 12,
                  bold: true,
                  margin: [0, 0, 0, 0],
                  color: '#6d6e70',
                },
                {
                  stack: [
                    {
                      canvas: [
                        {
                          type: 'rect',
                          x: 0,
                          y: 0,
                          w: 20,
                          h: 10,
                          lineWidth: 1,
                          lineColor: 'black',
                        },
                        {
                          type: 'line',
                          x1: 8,
                          y1: 3,
                          x2: 12,
                          y2: 8,
                          lineWidth: 1,
                          lineColor: data.contactedVia === 'Phone' ? 'black' : 'white',
                        },
                        {
                          type: 'line',
                          x1: 12,
                          y1: 3,
                          x2: 8,
                          y2: 8,
                          lineWidth: 1,
                          lineColor: data.contactedVia === 'Phone' ? 'black' : 'white',
                        },
                      ],
                    },
                  ],
                  width: 'auto',
                  margin: [5, 2, 0, 0],
                  alignment: 'center',
                },
                {
                  width: 'auto',
                  text: 'Phone',
                  alignment: 'center',
                  fontSize: 10,
                  margin: [3, 1, 0, 0],
                  color: '#6d6e70',
                },
                {
                  stack: [
                    {
                      canvas: [
                        {
                          type: 'rect',
                          x: 0,
                          y: 0,
                          w: 20,
                          h: 10,
                          lineWidth: 1,
                          lineColor: 'black',
                        },
                        {
                          type: 'line',
                          x1: 8,
                          y1: 3,
                          x2: 12,
                          y2: 8,
                          lineWidth: 1,
                          lineColor: data.contactedVia === 'Email' ? 'black' : 'white',
                        },
                        {
                          type: 'line',
                          x1: 12,
                          y1: 3,
                          x2: 8,
                          y2: 8,
                          lineWidth: 1,
                          lineColor: data.contactedVia === 'Email' ? 'black' : 'white',
                        },
                      ],
                    },
                  ],
                  width: 'auto',
                  margin: [5, 2, 0, 0],
                  alignment: 'center',
                },
                {
                  width: 'auto',
                  text: 'Email',
                  margin: [3, 1, 0, 0],
                  alignment: 'center',
                  fontSize: 10,
                  color: '#6d6e70',
                },
                {
                  stack: [
                    {
                      canvas: [
                        {
                          type: 'rect',
                          x: 0,
                          y: 0,
                          w: 20,
                          h: 10,
                          lineWidth: 1,
                          lineColor: 'black',
                        },
                        {
                          type: 'line',
                          x1: 8,
                          y1: 3,
                          x2: 12,
                          y2: 8,
                          lineWidth: 1,
                          lineColor: data.contactedVia === 'In person' ? 'black' : 'white',
                        },
                        {
                          type: 'line',
                          x1: 12,
                          y1: 3,
                          x2: 8,
                          y2: 8,
                          lineWidth: 1,
                          lineColor: data.contactedVia === 'In person' ? 'black' : 'white',
                        },
                      ],
                    },
                  ],
                  width: 'auto',
                  margin: [5, 2, 0, 0],
                  alignment: 'center',
                },
                {
                  width: 'auto',
                  text: 'In person',
                  margin: [3, 1, 0, 0],
                  alignment: 'center',
                  fontSize: 10,
                  color: '#6d6e70',
                },
              ],
            },
            {
              margin: [0, 0, 0, 10],
              columns: [
                {
                  width: 'auto',
                  text: [
                    { text: 'Other Persons Contacted: ', noWrap: true, bold: true },
                    {
                      text: data.otherPersonsContacted,
                    },
                  ],
                  fontSize: 12,
                  color: '#6d6e70',
                },
              ],
            },
            {
              columns: [
                {
                  stack: [
                    {
                      canvas: [
                        {
                          type: 'rect',
                          x: 0,
                          y: 0,
                          w: 20,
                          h: 10,
                          lineWidth: 1,
                          lineColor: 'black',
                        },
                        {
                          type: 'line',
                          x1: 8,
                          y1: 3,
                          x2: 12,
                          y2: 8,
                          lineWidth: 1,
                          lineColor: 'black',
                        },
                        {
                          type: 'line',
                          x1: 12,
                          y1: 3,
                          x2: 8,
                          y2: 8,
                          lineWidth: 1,
                          lineColor: 'black',
                        },
                      ],
                    },
                  ],
                  width: 'auto',
                  margin: [5, 0, 0, 0],
                  alignment: 'center',
                },
                {
                  width: 'auto',
                  text: 'I hereby acknowledge and agree that my electronic signature on this document is the legal equivalent of my handwritten signature.',
                  margin: [3, 1, 0, 0],
                  alignment: 'left',
                  fontSize: 8,
                  color: '#6d6e70',
                },
              ],
            },
            {
              margin: [0, 5, 0, 0],
              columns: [
                { text: 'Signature of Staff Completing Form', alignment: 'left' },
                { text: 'Date/Time', alignment: 'right' },
              ],
            },
            {
              margin: [0, 0, 0, 0],
              columns: [
                { text: data.teacherSignature, font: 'DancingScript', alignment: 'left' },
                { text: ` | ${data.teacherSignature}`, fontSize: 10, alignment: 'left' },
                {
                  text: `${moment(data.teacherSignatureDate).format('MM/DD/YY')}/${moment(
                    data.teacherSignatureDate,
                  ).format('h:mm A')}`,
                  alignment: 'right',
                },
              ],
            },
            {
              margin: [0, 0, 0, 10],
              columns: [
                {
                  text: `Sent ${moment(data.teacherSignatureDate).format('MM/DD/YY')}/${moment(data.teacherSignatureDate).format('h:mm A')}`,
                  fontSize: 6,
                  color: '#6d6e70',
                },
              ],
            },
            {
              columns: [
                {
                  stack: [
                    {
                      canvas: [
                        {
                          type: 'rect',
                          x: 0,
                          y: 0,
                          w: 20,
                          h: 10,
                          lineWidth: 1,
                          lineColor: 'black',
                        },
                        {
                          type: 'line',
                          x1: 8,
                          y1: 3,
                          x2: 12,
                          y2: 8,
                          lineWidth: 1,
                          lineColor: 'black',
                        },
                        {
                          type: 'line',
                          x1: 12,
                          y1: 3,
                          x2: 8,
                          y2: 8,
                          lineWidth: 1,
                          lineColor: 'black',
                        },
                      ],
                    },
                  ],
                  width: 'auto',
                  margin: [5, 0, 0, 0],
                  alignment: 'center',
                },
                {
                  width: 'auto',
                  text: 'I hereby acknowledge and agree that my electronic signature on this document is the legal equivalent of my handwritten signature.',
                  margin: [3, 1, 0, 0],
                  alignment: 'left',
                  fontSize: 8,
                  color: '#6d6e70',
                },
              ],
            },
            {
              margin: [0, 5, 0, 0],
              columns: [
                { text: 'Signature of Director/Person in Charge', alignment: 'left' },
                { text: 'Date/Time', alignment: 'right' },
              ],
            },
            {
              margin: [0, 0, 0, 0],
              columns: [
                { text: data.directorSignature, font: 'DancingScript', alignment: 'left' },
                { text: ` | ${data.directorSignature}`, fontSize: 10, alignment: 'left' },
                {
                  text: `${moment(data.directorSignatureDate).format('MM/DD/YY')}/${moment(
                    data.directorSignatureDate,
                  ).format('h:mm A')}`,
                  alignment: 'right',
                },
              ],
            },
            {
              margin: [0, 0, 0, 10],
              columns: [
                {
                  text: `Opened ${moment(data.teacherSignatureDate).format('MM/DD/YY')}/${moment(data.teacherSignatureDate).format('h:mm A')} - Sent ${moment(data.directorSignatureDate).format('MM/DD/YY')}/${moment(data.directorSignatureDate).format('h:mm A')}`,
                  fontSize: 6,
                  color: '#6d6e70',
                },
              ],
            },
            {
              columns: [
                {
                  stack: [
                    {
                      canvas: [
                        {
                          type: 'rect',
                          x: 0,
                          y: 0,
                          w: 20,
                          h: 10,
                          lineWidth: 1,
                          lineColor: 'black',
                        },
                        {
                          type: 'line',
                          x1: 8,
                          y1: 3,
                          x2: 12,
                          y2: 8,
                          lineWidth: 1,
                          lineColor: 'black',
                        },
                        {
                          type: 'line',
                          x1: 12,
                          y1: 3,
                          x2: 8,
                          y2: 8,
                          lineWidth: 1,
                          lineColor: 'black',
                        },
                      ],
                    },
                  ],
                  width: 'auto',
                  margin: [5, 0, 0, 0],
                  alignment: 'center',
                },
                {
                  width: 'auto',
                  text: 'I hereby acknowledge and agree that my electronic signature on this document is the legal equivalent of my handwritten signature.',
                  margin: [3, 1, 0, 0],
                  alignment: 'left',
                  fontSize: 8,
                  color: '#6d6e70',
                },
              ],
            },
            {
              margin: [0, 5, 0, 0],
              columns: [
                { text: 'Signature of Parent/Guardian/Authorized Pickup Person', alignment: 'left', width: 'auto' },
                { text: 'Date/Time', alignment: 'right' },
              ],
            },
            {
              margin: [0, 0, 0, 0],
              columns: [
                { text: data.parentSignature, font: 'DancingScript', alignment: 'left' },
                { text: ` | ${data.parentSignature}`, fontSize: 10, alignment: 'left' },
                {
                  text: `${moment(data.parentSignatureDate).format('MM/DD/YY')}/${moment(data.parentSignatureDate).format('h:mm A')}`,
                  alignment: 'right',
                },
              ],
            },
            {
              margin: [0, 0, 0, 10],
              columns: [
                {
                  text: `Opened ${moment(data.directorSignatureDate).format('MM/DD/YY')}/${moment(data.directorSignatureDate).format('h:mm A')}`,
                  fontSize: 6,
                  color: '#6d6e70',
                },
              ],
            },
            {
              margin: [0, 10, 0, 0],
              columns: [
                {
                  text: '*In the event of a serious injury, the incident must immediately be reported to the Early Learning Coalition (if applicable) and to the Florida Department of Children and Families. Documentation must be maintained and available for inspection for 15 months as per Rule 6M-4.620 Health and Safety Checklists and Inspections.',
                  fontSize: 8,
                  color: '#6d6e70',
                  pageBreak: index < length - 1 ? 'after' : null,
                },
              ],
            },
          ];
        });

        const pdfBody = result.reduce((acc: any, val: any) => acc.concat(val), []);
        pdfMake.fonts = {
          Roboto: {
            normal: 'Roboto-Regular.ttf',
            bold: 'Roboto-Medium.ttf',
            italics: 'Roboto-Italic.ttf',
            bolditalics: 'Roboto-MediumItalic.ttf',
          },
          DancingScript: {
            normal: 'DancingScript.ttf',
            bold: 'DancingScript.ttf',
            italics: 'DancingScript.ttf',
            bolditalics: 'DancingScript.ttf',
          },
        };
        const documentDefinition: any = {
          content: pdfBody,
          styles: {
            header: {
              fontSize: 16,
              color: '#395dab',
            },
            underlineHeader: {
              fontSize: 16,
              decoration: 'underline',
              color: '#395dab',
            },
            title: {
              fontSize: 14,
              color: '#6d6e70',
            },
          },
        };

        const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
        pdfDocGenerator.getBlob((blob) => {
          saveAs(blob, 'accident_report.pdf');
          setIsGeneratingPDF(false);
        });
      };
      reader.readAsDataURL(imageBlob);
    } catch (error) {
      console.error('Erro ao obter a imagem:', error);
    }
  };

  return (
    <Button size="sm" color="primary" onClick={generatePDF} disabled={isGeneratingPDF}>
      <FontAwesomeIcon icon={!isGeneratingPDF ? faDownload : faSpinner} spin={isGeneratingPDF} />
    </Button>
  );
};

export default ReportPage;
