import { Button, Card, CardHeader, CardBody, FormGroup, Form, Input, Container, Row, Col, Modal } from 'reactstrap';
import { toast } from 'react-toastify';
import { useContext, useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import CommonHeader from '../../../components/Headers/CommonHeader';
import FranchiseeService from '../../../services/Franchisee';
import history from '../../../history';
import UploadAndCropImage from '../../../components/Core/UploadAndCropImage';

const FranchiseeCreate = () => {
  const { id } = useParams<{ id: string }>();
  const pageTitle = id ? 'Update' : 'Create';
  const { token, franchisee }: any = useContext(AuthContext);
  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [obs, setObs] = useState('');

  const [pictureUrl, setPictureUrl] = useState(franchisee?.picture || '');
  const [picture, setPicture] = useState<any>();
  const [modalPicture, setModalPicture] = useState(false);

  const getById = async () => {
    try {
      const result = await FranchiseeService(token).show(id);
      if (result?.data) {
        setName(result?.data?.name || '');
        setObs(result?.data?.obs || '');
        setPictureUrl(result?.data?.picture || '');
      }
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'Invalid franchisee ID');
    }
  };

  useEffect(() => {
    if (id) {
      getById();
    }
  }, []);

  const showOpenFileDialog = () => {
    if (isDisabled) {
      return;
    }
    setModalPicture(!modalPicture);
  };

  const onChangePicture = (blob: Blob, imageUrl: string) => {
    setPicture(blob);
    setPictureUrl(imageUrl);
    setModalPicture(!modalPicture);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);
    setIsDisabled(true);

    const body = {
      name,
      obs,
    };

    try {
      const result = id ? await FranchiseeService(token).update(id, body) : await FranchiseeService(token).create(body);

      if (picture) {
        const formData = new FormData();
        formData.append('picture', picture as any);
        await FranchiseeService(token).picture(result?.data?.id, formData);
      }

      toast.success(`${id ? 'Updated' : 'Created'} successfully!`);
      if (result?.data?.id) {
        history.push(`/franchisee/${result?.data?.id}`);
      }
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Create error!');
      toast.error(message);
    }

    setLoading(false);
    setIsDisabled(false);
  };

  return (
    <>
      <Modal className="modal-dialog-centered" isOpen={modalPicture} toggle={() => setModalPicture(!modalPicture)}>
        <div className="modal-header">
          <h5 className="modal-title">Change Avatar Picture</h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setModalPicture(!modalPicture)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body pt-0">
          <UploadAndCropImage image={pictureUrl} onChange={onChangePicture} />
        </div>
      </Modal>
      <CommonHeader />
      <Container className="mt--8" fluid>
        <Row>
          <Col className="order-xl-2" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">{`${pageTitle} Franchisee`}</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Link to="/franchisee">
                      <Button color="primary" className="float-right">
                        Back
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmit}>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="12">
                        <Row style={{ display: 'grid' }}>
                          <Row>
                            <Col lg="12">
                              <h6 className="heading-small text-muted mb-4">
                                Picture <small style={{ textTransform: 'none' }}>(Recommended size: 800 x 800px)</small>
                              </h6>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <FormGroup>
                                <img
                                  alt="Franchisee"
                                  className="rounded-circle"
                                  src={
                                    pictureUrl
                                      ? `${pictureUrl}`
                                      : require('../../../assets/img/user-default.png').default
                                  }
                                  style={{ width: 135, height: 135, cursor: isDisabled ? 'not-allowed' : 'pointer' }}
                                  onClick={showOpenFileDialog}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-name">
                            Name
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-name"
                            placeholder="Franchisee Name"
                            type="text"
                            disabled={isDisabled}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-obs">
                            Description
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-obs"
                            placeholder="Description"
                            type="text"
                            disabled={isDisabled}
                            value={obs}
                            onChange={(e) => setObs(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <div className="pl-lg-4">
                    <Button className="my-4" color="primary" type="submit" disabled={isDisabled}>
                      Save
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default FranchiseeCreate;
