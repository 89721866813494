import { Card, CardBody, Container, Row, Col } from 'reactstrap';
import CommonHeader from '../../../components/Headers/CommonHeader';
import MessagingListTalk from './MessagingListTalk';

const MessagingHome = () => {
  return (
    <>
      <CommonHeader />
      <Container className="mt--8" fluid>
        <Row className="align-items-center mt-3">
          <Col className="order-xl-2" xl="12">
            <Card>
              <CardBody>
                <MessagingListTalk />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default MessagingHome;
