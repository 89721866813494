import { Card, CardHeader, CardBody, CardText, CardFooter, Row, Col, Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { useContext, useEffect, useState } from 'react';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import MessagingService from '../../../services/Messaging';
import MessagingReply from './MessagingReply';
import getFormatByMimetypes from '../../../helpers/getFormatByMimetypes';
import { dateDistanceForMessages } from '../../../helpers/dateDistance';
import isAuthorized from '../../../helpers/isAuthorized';
import confirmAlert from '../../../helpers/confirmAlert';
import LoaderSpinner from '../../../components/Core/LoaderSpinner';

type props = {
  idTalk: string;
  userId?: string;
  setNewTypeDisplay?: (type: string) => void;
  studentId?: string;
};

const MessagingTalk = ({ idTalk, userId, setNewTypeDisplay = () => {}, studentId }: props) => {
  //const { id } = useParams<{ id: string }>();

  const id = idTalk;

  const scroolForm = async () => {
    if (document.getElementsByClassName('messaging-talk-main').length) {
      document
        .getElementsByClassName('messaging-talk-main')[0]
        .scrollTo(0, document.getElementsByClassName('messaging-talk-main')[0].scrollHeight);
    }
  };

  const [messagingId, setMessagingId] = useState('');
  const [refresh, setRefresh] = useState(false);

  const { user, token }: any = useContext(AuthContext);

  const [data, setData] = useState([] as any[]);
  const [page, setPage] = useState(1);
  const [disableLoadOldMessages, setDisableLoadOldMessages] = useState(false);

  const [loading, setLoading] = useState(true);

  const onRefresh = () => {
    setRefresh(!refresh);
  };

  const loadList = async () => {
    setLoading(true);
    try {
      const result = await MessagingService(token).show(id, 1);
      if (result.meta.totalPages === 1) {
        setDisableLoadOldMessages(true);
      }
      const dataResult: any = result.data;
      setData(dataResult);
      scroolForm();
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No messaging found');
    } finally {
      setLoading(false);
    }
  };

  const loadOldMessages = async () => {
    setLoading(true);
    try {
      const result = await MessagingService(token).show(id, page);
      const dataResult: any = result.data;
      if (result.meta.totalPages === page) {
        setDisableLoadOldMessages(true);
      }
      const msgLoaded = data;
      setData(dataResult.concat(msgLoaded));
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No messaging found');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setPage(1);
    if (id) {
      loadList();
      setMessagingId(id);
      if (!userId) {
        scroolForm();
      }
    }
  }, [refresh]);

  useEffect(() => {
    setDisableLoadOldMessages(false);
    setPage(1);
    if (id != '') {
      loadList();
      setMessagingId(id);
    }
    scroolForm();
  }, [id]);

  useEffect(() => {
    if (page > 1) {
      loadOldMessages();
    } else {
      setDisableLoadOldMessages(false);
    }
  }, [page]);

  // useEffect(() => {
  //   setInterval(() => {
  //     loadList();
  //   }, 2 * 1000);
  // }, []);

  const removeMessagingTalk = async (messagingTalkId: string) => {
    setLoading(true);
    try {
      await MessagingService(token).deleteTalk(messagingTalkId);
      setNewTypeDisplay('blank');
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No messaging found');
    } finally {
      setLoading(false);
      loadList();
    }
  };

  const buttonToRemoveMessagingTalk = (messagingTalkId: string) => {
    return (
      <>
        {isAuthorized({ moduleName: 'Messaging', functionName: 'DeleteMessagingTalk' }, user) && (
          <Button
            className="btn-sm btn-secondary m-0"
            style={{ float: 'right' }}
            onClick={() => {
              confirmAlert({
                title: 'Delete',
                messageType: 'DELETE',
                message: 'Are you sure to delete this message?',
                // onClickYes: () => removePaymentMethod(paymentMethodId),
                onClickYes: () => removeMessagingTalk(messagingTalkId),
              });
            }}
          >
            <i className="fas fa-trash" />
          </Button>
        )}
      </>
    );
  };

  return (
    <>
      <div className="messaging-talk-main">
        <Row style={{ height: '100%' }}>
          {loading && (
            <Col xs="12">
              <LoaderSpinner />
            </Col>
          )}
          {!loading && (
            <Col className="order-xl-2" xl="12">
              <Card className="bg-secondary shadow" style={{ height: '99%' }}>
                <CardBody>
                  <div className="text-center">
                    <Button
                      color="primary"
                      type="button"
                      onClick={() => {
                        setPage(page + 1);
                      }}
                      disabled={disableLoadOldMessages}
                    >
                      Load More
                    </Button>
                  </div>
                  {data.map((r, index) => {
                    const lastUser = data[index - 1];
                    return (
                      <Row
                        className={`align-items-center ${r.userId == (userId || user.id) ? '' : null} ${
                          lastUser === undefined || r.userId != lastUser.userId ? 'mt-3' : 'mt-0'
                        }`}
                        style={
                          r.userId === (userId || user.id)
                            ? { paddingLeft: '120px', paddingRight: '50px' }
                            : { paddingRight: '120px', paddingLeft: '50px' }
                        }
                        //style={`${r.userId == user.id ? '{{paddingLeft: 0}}' : '{{}}'}`}
                        // style={`${r.userId == user.id ? 'paddingLeft: 0' : ''}`}
                        key={`item-${index}`}
                        id={r.userId}
                        name={userId || user.id}
                      >
                        <Col className="order-xl-2" xl="12">
                          <Card>
                            {(lastUser === undefined || r.userId != lastUser.userId) && (
                              <>
                                <img
                                  alt={`${r.user.firstName}`}
                                  title={`${r.user.firstName}`}
                                  className={`rounded-circle profilepic-message ${
                                    r.userId === (userId || user.id) ? 'right' : 'left'
                                  }`}
                                  src={r.user.picture}
                                  style={{ width: 40, height: 40, marginRight: 10 }}
                                />
                                <div
                                  className={`msg-arrow ${r.userId === (userId || user.id) ? 'right' : 'left'}`}
                                ></div>
                                <CardHeader style={{ padding: '5px 10px', borderBottom: '0' }}>
                                  {r?.user?.firstName || ''} {r?.user?.lastName || ''}
                                  {buttonToRemoveMessagingTalk(r.id)}
                                </CardHeader>
                              </>
                            )}
                            <CardBody style={{ padding: '5px 10px' }}>
                              <CardText>
                                {lastUser !== undefined &&
                                  r.userId === lastUser.userId &&
                                  buttonToRemoveMessagingTalk(r.id)}
                                <div className="is-size-6" dangerouslySetInnerHTML={{ __html: r.message }}></div>
                              </CardText>

                              {r?.attachment && r?.attachmentMimetype && (
                                <CardText>
                                  <Col xs="12" className="mt-3 mb-2">
                                    <i className="fas fa-paperclip" />{' '}
                                    <a href={r.attachment} target="_blank">
                                      {r.id}.{getFormatByMimetypes(r.attachmentMimetype)}
                                    </a>
                                  </Col>
                                </CardText>
                              )}
                            </CardBody>
                            <CardFooter style={{ padding: '5px 10px', borderTop: '0' }} className="text-right">
                              <small>{dateDistanceForMessages(r.createdAt)}</small>
                            </CardFooter>
                          </Card>
                        </Col>
                      </Row>
                    );
                  })}
                </CardBody>
              </Card>
            </Col>
          )}
        </Row>
      </div>
      {!userId && !studentId && (
        <>
          <div id="formReply">
            <MessagingReply id={messagingId} onRefresh={onRefresh} />
          </div>
        </>
      )}
    </>
  );
};

export default MessagingTalk;
