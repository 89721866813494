import http from '../helpers/http';

const UserSettings = (token: string) => {
  const headers = {
    'x-access-token': token,
  };

  return {
    show: async (userId: string) => {
      const result = await http.get(`/user-settings/${userId}`, {
        headers,
      });
      return result.data;
    },
    createOrUpdate: async (body: { userId: string; defaultPayment: string; achData: any; cardData: any }) => {
      const result = await http.put(`/user-settings`, body, { headers });
      return result.data;
    },
  };
};

export default UserSettings;
