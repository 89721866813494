import http from '../helpers/http';

const Tour = () => {
  return {
    getSchoolList: async (franchiseeId: string) => {
      const result = await http.get(`/franchisees/${franchiseeId}/schools-public`);
      return result.data;
    },
    create: async (body: any) => {
      const result = await http.post(`/schedule/public`, body);
      return result.data;
    },
  };
};

export default Tour;
