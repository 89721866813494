import http from '../helpers/http';
import { FilteredOptionsInterface, makeQueryParamsForRequest } from '../entities/FilteredOptions';

const TaxDocuments = (token: string) => {
  const base = '/tax-documents';
  const headers = {
    'x-access-token': token,
  };

  return {
    list: async (filteredOptions?: FilteredOptionsInterface, schoolId?: string) => {
      const params = makeQueryParamsForRequest(filteredOptions);
      if (schoolId) {
        params.schoolId = schoolId;
      }
      const result = await http.get(base, {
        headers,
        params,
      });
      return result.data;
    },
    show: async (id: string) => {
      const result = await http.get(`${base}/${id}`, {
        headers,
      });
      return result.data;
    },
    getPDF: async (invoiceId: string) => {
      const headersPdf = { ...headers, 'Content-Type': 'multipart/form-data' };
      const result = await http.get(`${base}/${invoiceId}/pdf`, {
        headers: headersPdf,
        responseType: 'arraybuffer',
      });
      return result.data;
    },
  };
};

export default TaxDocuments;
