/* eslint-disable prefer-spread */
/* eslint-disable max-lines */
import { Col, Modal, Row } from 'reactstrap';
import { useEffect, useState } from 'react';
import { pdfjs } from 'react-pdf';
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';
import moment from 'moment';

type DocumentModalProps = {
  isOpen: boolean;
  data: any;
  toggleModal: (e?: any) => void;
};

const DocumentModal = ({ isOpen, data, toggleModal }: DocumentModalProps) => {
  const [pdf, setPdf] = useState('');

  useEffect(() => {
    if (isOpen) {
      pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
      loadPdf();
    }
  }, [isOpen]);

  const loadPdf = async () => {
    try {
      const arrayBuffer = await fetch(data?.pdfUrl).then((res) => res.arrayBuffer());
      const pdfDoc = await PDFDocument.load(arrayBuffer);
      const pages = pdfDoc.getPages();
      const page = pages[data?.options?.signPage || 0];
      const sigPad = data?.signature || null;
      const pdfUrl = data?.options || {};
      if (page && sigPad) {
        const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);
        if (pdfUrl?.coordinatesRectangle) {
          page.drawRectangle({
            ...pdfUrl?.coordinatesRectangle,
          });
        }

        const staffName = data?.userName || '';

        const pageName =
          typeof pdfUrl?.signNamePage !== 'undefined' ? pages[pdfUrl?.signNamePage] : pages[pdfUrl?.signPage || 0];
        if (pdfUrl?.coordinatesName) {
          pageName.drawText(staffName, {
            ...pdfUrl?.coordinatesName,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
          });
        }

        const names = pdfUrl?.coordinatesNames || [];
        for (const n of names) {
          const pageName = pages[n?.page || 0];
          pageName.drawText(staffName, {
            ...n?.coordinates,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
          });
        }

        if (pdfUrl?.coordinatesDate) {
          const pageDate =
            typeof pdfUrl?.signDatePage !== 'undefined' ? pages[pdfUrl?.signDatePage] : pages[pdfUrl?.signPage || 0];
          pageDate.drawText(moment().format('MM/DD/YYYY'), {
            ...pdfUrl?.coordinatesDate,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
          });
        }
        const pngImage = await pdfDoc.embedPng(data?.options?.signPng);
        page.drawImage(pngImage, pdfUrl.coordinates);
      }

      const pdfBytes = await pdfDoc.save();

      const blob = new Blob([pdfBytes], { type: 'application/pdf' });
      const docUrl = URL.createObjectURL(blob);
      setPdf(docUrl);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Modal className="modal-dialog-centered" size="xl" isOpen={isOpen} toggle={(e: any) => toggleModal(e)}>
        <div className="modal-header pb-1">
          <h5 className="modal-title">{data?.options?.title || ''}</h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={(e) => toggleModal(e)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body pt-0">
          <Row className="mt-2">
            <Col lg="12" className="mt-4" style={{ maxHeight: '500px', overflow: 'scroll' }}>
              <iframe src={pdf} style={{ minHeight: 500, width: '100%' }} />
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default DocumentModal;
