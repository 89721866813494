import { Nav, NavItem, NavLink, Card, CardHeader, CardBody, TabContent, TabPane } from 'reactstrap';
import { useState } from 'react';
import EmployeeSettingsForm, { settingsType } from './EmployeeSettingsForm';
import PtoPolices from './PtoPolices';
import HireAgreementsForm from './HireAgreementsForm';

type EmployeeSettingsProps = {
  isOpen: boolean;
};

const customStyles = {
  navLink: {
    minHeight: '42px',
    paddingTop: '8px',
    cursor: 'pointer',
  },
};

const EmployeeSettingsTabs = ({ isOpen }: EmployeeSettingsProps) => {
  const [activeTab, setActiveTab] = useState('1');

  return (
    <>
      <Card className="bg-secondary shadow">
        <CardHeader className="bg-white border-0" style={{ maxHeight: '62px' }}>
          <Nav tabs className="nav-profile">
            <NavItem>
              <NavLink
                className={`${activeTab === '1' ? 'active' : ''} nav-link-profile`}
                onClick={() => setActiveTab('1')}
                style={customStyles.navLink}
              >
                <i className="fas fa-user-tie"></i> <span>Titles</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={`${activeTab === '2' ? 'active' : ''} nav-link-profile`}
                onClick={() => setActiveTab('2')}
                style={customStyles.navLink}
              >
                <i className="fas fa-building"></i> <span>Departments</span>
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={`${activeTab === '3' ? 'active' : ''} nav-link-profile`}
                onClick={() => setActiveTab('3')}
                style={customStyles.navLink}
              >
                <i className="fas fa-business-time" /> <span>PTO Policies</span>
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={`${activeTab === '4' ? 'active' : ''} nav-link-profile`}
                onClick={() => setActiveTab('4')}
                style={customStyles.navLink}
              >
                <i className="fas fa-file" /> <span>Hire Agreements</span>
              </NavLink>
            </NavItem>
          </Nav>
        </CardHeader>
        <CardBody>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1" className="pt-2">
              <EmployeeSettingsForm isOpen={isOpen} type={settingsType.TITLE} />
            </TabPane>

            <TabPane tabId="2">
              <EmployeeSettingsForm isOpen={isOpen} type={settingsType.DEPARTMENT} />
            </TabPane>

            <TabPane tabId="3">
              <PtoPolices isOpen={isOpen} />
            </TabPane>

            <TabPane tabId="4">
              <HireAgreementsForm isOpen={isOpen} />
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    </>
  );
};

export default EmployeeSettingsTabs;
