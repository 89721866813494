import { Card, CardHeader, CardBody, Container, Row, Col, FormGroup, Input, Button, Modal } from 'reactstrap';
import { useContext, useState, useEffect } from 'react';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import CommonHeader from '../../../components/Headers/CommonHeader';
import { useLocation, Link } from 'react-router-dom';
import DocumentsFoldersForm from '../Documents/DocumentsFoldersForm';
import DocumentsForm from '../Documents/DocumentsForm';
import { DragDrop } from '../Documents/DragDrop';
import { DragDropFiles } from '../Documents/DragDropFiles';
import isAdmin from '../../../helpers/isAdmin';
import isDirector from '../../../helpers/isDirector';
import { isMobile } from 'react-device-detect';

const DocumentsFoldersList = () => {
  const { user, currentSchool } = useContext(AuthContext);
  const hasEditPermission = isAdmin(user) || isDirector(user);
  const urlLocation = useLocation()['pathname'];

  const [title, setTitle] = useState('');
  const [filterSearch, setFilterSearch] = useState('');

  const [documentsFoldersForm, setDocumentsFoldersForm] = useState(false);
  const [documentsForm, setDocumentsForm] = useState(false);

  const [refreshList, setRefreshList] = useState(false);

  const { hash } = useLocation();
  const hashFolderId = hash.replace('#', '');
  const handleRefresh = () => {
    setRefreshList(!refreshList);
  };

  const toggleDocumentsFoldersForm = () => {
    setDocumentsFoldersForm(!documentsFoldersForm);
  };

  const toggleDocumentsForm = () => {
    setDocumentsForm(!documentsForm);
  };

  const modalChange = () => {
    setDocumentsFoldersForm(!documentsFoldersForm);
  };

  const modalFileChange = () => {
    setDocumentsForm(!documentsForm);
  };

  const changeSchoolReload = () => {
    window.open(`${urlLocation}`, '_self');
  };

  useEffect(() => {
    if (urlLocation == '/curriculum') {
      setTitle('Curriculum');
    } else if (urlLocation == '/resource') {
      setTitle('Resources');
    }
  }, [urlLocation]);

  useEffect(() => {
    if (Number(hashFolderId) > 0) {
      changeSchoolReload();
    }
  }, [currentSchool]);

  const buttonsMenu = () => {
    return (
      <>
        {hasEditPermission && !isMobile && (
          <>
            <Button
              onClick={() => {
                toggleDocumentsForm();
              }}
              color="primary"
            >
              Add new File
            </Button>
          </>
        )}
      </>
    );
  };

  const listFiles = () => {
    return (
      <>
        <hr />
        <CardBody>
          <DragDropFiles onRefresh={handleRefresh} searchFilter={filterSearch} />
        </CardBody>
      </>
    );
  };

  return (
    <>
      <CommonHeader />
      <Container className="mt--8" fluid>
        <Row>
          <Col className="order-xl-2" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="6">
                    <h3 className="mb-0">{title}</h3>
                  </Col>
                  <Col className="text-right" xs="6">
                    <Link to={urlLocation}>
                      <Button color="primary" className="float-right" size="sm">
                        Back
                      </Button>
                    </Link>
                  </Col>
                  <Col xs="12" className="mt-2">
                    <FormGroup>
                      <Input
                        className="form-control-alternative"
                        id="input-search"
                        placeholder="Search"
                        type="text"
                        onChange={(e) => setFilterSearch(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {!isMobile ? (
                  <Row>
                    <Col xs="12">
                      {hashFolderId ? buttonsMenu() : ''}
                      {hasEditPermission && (
                        <Button
                          onClick={() => {
                            toggleDocumentsFoldersForm();
                          }}
                          color="primary"
                        >
                          Add new Folder
                        </Button>
                      )}
                    </Col>
                  </Row>
                ) : (
                  <></>
                )}
              </CardHeader>
              <CardBody>
                <DragDrop onRefresh={handleRefresh} searchFilter={filterSearch} />
              </CardBody>
              {hashFolderId || filterSearch ? listFiles() : ''}
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        className="modal-dialog-centered"
        isOpen={documentsFoldersForm}
        toggle={() => toggleDocumentsFoldersForm()}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="Label">
            New Folder
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => toggleDocumentsFoldersForm()}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <DocumentsFoldersForm title={title} onModalStatus={modalChange} onRefresh={handleRefresh} />
        </div>
      </Modal>
      <Modal className="modal-dialog-centered" isOpen={documentsForm} toggle={() => toggleDocumentsForm()}>
        <div className="modal-header">
          <h5 className="modal-title" id="Label">
            New File
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => toggleDocumentsForm()}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <DocumentsForm title={title} onModalStatus={modalFileChange} onRefresh={handleRefresh} />
        </div>
      </Modal>
    </>
  );
};

export default DocumentsFoldersList;
