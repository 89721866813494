/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import { Input, Row, Col, Button, Badge, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import DataTable from '../../../components/Core/DataTable';
import CardInfo from '../../../components/Cards/CardInfo';
import ModalContent from '../../../components/Modals/Modal';
import AttendancesService from '../../../services/Attendances';
import { toast } from 'react-toastify';
import confirmAlert from '../../../helpers/confirmAlert';
import Timer from '../../../components/Core/Timer';
import { dateFormat } from '../../../helpers/dateFormat';
import { Link } from 'react-router-dom';

export const StaffCheckModal: React.FC<{
  currentSchool: any;
  token: string;
  count: number;
  users: Array<any>;
  refreshData?: () => void;
}> = ({ currentSchool, count, users, token, refreshData }) => {
  const [loading, setLoading] = useState(false);
  const [toggleModal, setToggleModal] = useState(false);
  const [usersSelected, setUsersSelected] = useState([] as any[]);
  const [activeTab, setActiveTab] = useState('1');
  const [allClockedIn, setAllClockedIn] = useState(false);
  const [allClockedOut, setAllClockedOut] = useState(false);
  const usersClockedIn = users.filter((k: any) => k.isClockedIn);
  const [currentUsersClockedIn, setCurrentUsersClockedIn] = useState(usersClockedIn as any[]);
  const usersClockedOut = users.filter((k: any) => !k.isClockedIn);
  const [currentUsersClockedOut, setCurrentUsersClockedOut] = useState(usersClockedOut as any[]);
  const usersBreaked = usersClockedIn.filter((u: any) => u.isBreaked);

  const customStyles = {
    navLink: {
      minHeight: '42px',
      paddingTop: '8px',
      cursor: 'pointer',
    },
  };
  const typeLabels: any = {
    CLOCKIN: 'Clock In',
    CLOCKOUT: 'Clock Out',
    STARTBREAK: 'Start Break',
    ENDBREAK: 'End Break',
  };

  const columnClockOut = {
    name: (
      <Input
        type="checkbox"
        className="mt-n1 ml-2"
        onChange={(_) => selectUsersByType('CLOCKOUT')}
        checked={allClockedOut}
      />
    ),
    selector: (row: any) => (
      <Input
        type="checkbox"
        className="mt-n1 ml-2"
        checked={usersSelected.find((k) => k.id === row.id)}
        onChange={(_) => selectUsersById(row.id)}
      />
    ),
    grow: 1,
  };

  const columnClockIn = {
    name: (
      <Input
        type="checkbox"
        className="mt-n1 ml-2"
        onChange={(_) => selectUsersByType('CLOCKIN')}
        checked={allClockedIn}
      />
    ),
    selector: (row: any) => (
      <Input
        type="checkbox"
        className="mt-n1 ml-2"
        checked={usersSelected.find((k) => k.id === row.id)}
        onChange={(_) => selectUsersById(row.id)}
      />
    ),
    grow: 1,
  };

  const columnBreaked = {
    name: <Input type="checkbox" className="mt-n1 ml-2" onChange={(_) => selectUsersByType('ENDBREAK')} />,
    selector: (row: any) => (
      <Input
        type="checkbox"
        className="mt-n1 ml-2"
        checked={usersSelected.find((k) => k.id === row.id)}
        onChange={(_) => selectUsersById(row.id)}
      />
    ),
    grow: 1,
  };

  const columns = [
    {
      name: 'Name',
      selector: (row: any) => (
        <Link to={`/users/${row?.id}/profile`}>{`${row.firstName || '-'} ${row.lastName || '-'}`}</Link>
      ),
      grow: 2,
    },
    {
      name: 'Status',
      selector: (row: any) => (
        <Badge className="badge-dot badge-lg p-1 ml-1">
          <i className={getStatusColor(row.isClockedIn, row.isBreaked)} />
        </Badge>
      ),
      grow: 2,
    },
  ];

  const columnBreak = [
    {
      name: 'Name',
      route: '/users',
      label: (row: any) => `${row.firstName || '-'} ${row.lastName || '-'}`,
      grow: 2,
    },
    {
      name: 'Start',
      selector: (row: any) => `${row?.lastBreak?.createdAt ? dateFormat(row?.lastBreak?.createdAt) : '-'}`,
      grow: 2,
    },
    {
      name: 'Timer',
      selector: (row: any) => <Timer startTime={new Date(row?.lastBreak?.createdAt)}></Timer>,
      grow: 2,
    },
  ];

  const getStatusColor = (isClockedIn: boolean, isBreaked: boolean) => {
    if (!isClockedIn) return 'bg-danger';
    if (isClockedIn && !isBreaked) return 'bg-success';
    if (isClockedIn && isBreaked) return 'bg-warning';
  };

  const handleToggleModal = () => {
    setToggleModal(!toggleModal);
  };

  const selectUsersByType = (type: string) => {
    if (type === 'CLOCKIN') {
      const usersSelect = users.filter((k) => k.isClockedIn);
      if (usersSelect.length === usersSelected.length) {
        setAllClockedIn(false);
        return setUsersSelected([]);
      }
      setAllClockedIn(true);
      return setUsersSelected(usersSelect);
    }
    if (type === 'CLOCKOUT') {
      const usersSelect = users.filter((k) => !k.isClockedIn);
      if (usersSelect.length === usersSelected.length) {
        setAllClockedOut(false);
        return setUsersSelected([]);
      }
      setAllClockedOut(true);
      return setUsersSelected(usersSelect);
    }
  };
  const selectUsersById = (usersId: string) => {
    const newUsersSelected = JSON.parse(JSON.stringify(usersSelected));
    if (newUsersSelected.find((u: any) => u.id === usersId)) {
      return setUsersSelected(newUsersSelected.filter((u: any) => u.id !== usersId));
    }
    const user = users.find((k) => k.id === usersId);
    if (user) {
      return setUsersSelected([...newUsersSelected, user]);
    }
  };
  const confirmActivity = (e: any, type: string) => {
    e.preventDefault();

    if (!usersSelected.length) {
      return toast.warn('Please select at least one student');
    }

    return confirmAlert({
      title: 'Confirm',
      messageType: 'SAVE',
      message: `Are you sure you want to ${typeLabels[type]}?`,
      onClickYes: () => sendActivity(type),
    });
  };
  const sendActivity = async (type: string) => {
    setLoading(true);
    try {
      for (const k of usersSelected) {
        await AttendancesService(token).setByUser(k.id, type, currentSchool.value);
      }
      setUsersSelected([]);
      toast.success('Saved successfully!');
      if (refreshData) refreshData();
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'Error');
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    const usersClockedIn = users.filter((k: any) => k.isClockedIn);
    const usersClockedOut = users.filter((k: any) => !k.isClockedIn);
    setCurrentUsersClockedIn(usersClockedIn);
    setCurrentUsersClockedOut(usersClockedOut);
  }, [users]);
  useEffect(() => {
    if (usersSelected?.length !== usersClockedIn?.length) {
      setAllClockedIn(false);
    }
    if (usersSelected?.length !== usersClockedOut?.length) {
      setAllClockedOut(false);
    }
  }, [usersSelected]);
  return (
    <>
      <CardInfo
        count={count}
        title="Staff Clocked IN"
        class="bg-default card-info-mg-button"
        icon="fa-chalkboard-teacher"
        toggle={handleToggleModal}
      />
      <ModalContent title="Staff Clocked In" modal={toggleModal} toggle={handleToggleModal} size="xl">
        <Row>
          <Col xs="12">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={`${activeTab === '1' ? 'active' : ''}`}
                  onClick={() => {
                    setActiveTab('1');
                    setUsersSelected([]);
                    setAllClockedIn(false);
                  }}
                  style={customStyles.navLink}
                >
                  Clocked Out
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={`${activeTab === '2' ? 'active' : ''}`}
                  onClick={() => {
                    setActiveTab('2');
                    setUsersSelected([]);
                    setAllClockedOut(false);
                  }}
                  style={customStyles.navLink}
                >
                  Clocked In
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={`${activeTab === '3' ? 'active' : ''}`}
                  onClick={() => setActiveTab('3')}
                  style={customStyles.navLink}
                >
                  Break
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
          <Col xs="12">
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <DataTable
                  header={false}
                  title=""
                  columns={[columnClockOut, ...columns]}
                  data={currentUsersClockedOut}
                  totalRows={currentUsersClockedOut.length}
                  loading={loading}
                  button={false}
                  buttonUrl=""
                  onChangeRowsPerPage={(() => {}) as any}
                  onChangePage={(() => {}) as any}
                />
                <Button size="sm" className="mt-3" color="success" onClick={(e) => confirmActivity(e, 'CLOCKIN')}>
                  Clock In
                </Button>
              </TabPane>
              <TabPane tabId="2">
                <DataTable
                  header={false}
                  title=""
                  columns={[columnClockIn, ...columns]}
                  data={currentUsersClockedIn}
                  totalRows={currentUsersClockedIn.length}
                  loading={loading}
                  button={false}
                  buttonUrl=""
                  onChangeRowsPerPage={(() => {}) as any}
                  onChangePage={(() => {}) as any}
                />
                <Button size="sm" className="mt-3" color="primary" onClick={(e) => confirmActivity(e, 'STARTBREAK')}>
                  Start Break
                </Button>
                <Button size="sm" className="mt-3" color="danger" onClick={(e) => confirmActivity(e, 'CLOCKOUT')}>
                  Clock Out
                </Button>
              </TabPane>
              <TabPane tabId="3">
                <DataTable
                  header={false}
                  title=""
                  columns={[columnBreaked, ...columnBreak]}
                  data={usersBreaked}
                  totalRows={usersBreaked.length}
                  loading={loading}
                  button={false}
                  buttonUrl=""
                  onChangeRowsPerPage={(() => {}) as any}
                  onChangePage={(() => {}) as any}
                />
                <Button size="sm" className="mt-3" color="primary" onClick={(e) => confirmActivity(e, 'ENDBREAK')}>
                  End Break
                </Button>
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </ModalContent>
    </>
  );
};
