import SupportListInterface from '../interfaces/SupportListInterface';
import SupportReplyListInterface from '../interfaces/SupportReplyListInterface';
import { FilteredOptionsInterface, makeQueryParamsForRequest } from '../entities/FilteredOptions';
import http from '../helpers/http';

const Support = (token: string) => {
  return {
    show: async (supportId: string) => {
      const result = await http.get(`/support/${supportId}/show`, {
        headers: {
          'x-access-token': token,
        },
      });
      return result.data;
    },
    list: async (filteredOptions?: FilteredOptionsInterface) => {
      const params = makeQueryParamsForRequest(filteredOptions);
      const result = await http.get('/support', {
        headers: {
          'x-access-token': token,
        },
        params,
      });
      return result.data;
    },
    listStatus: async (supportStatus: string, filteredOptions?: FilteredOptionsInterface) => {
      const params = makeQueryParamsForRequest(filteredOptions);
      const result = await http.get(`/support/${supportStatus}`, {
        headers: {
          'x-access-token': token,
        },
        params,
      });
      return result.data;
    },
    listReply: async (supportId: string) => {
      const result = await http.get(`/support/${supportId}/reply`, {
        headers: {
          'x-access-token': token,
        },
      });
      return result.data;
    },
    create: async (body: Partial<SupportListInterface>) => {
      const headers = {
        headers: {
          'x-access-token': token,
        },
      };
      const result = await http.post(`/support`, body, headers);
      return result.data;
    },
    createReply: async (supportId: string, body: Partial<SupportReplyListInterface>) => {
      const headers = {
        headers: {
          'x-access-token': token,
        },
      };
      const result = await http.post(`/support/${supportId}/reply`, body, headers);
      return result.data;
    },
    update: async (supportId: string, body: Partial<SupportListInterface>) => {
      const headers = {
        headers: {
          'x-access-token': token,
        },
      };
      const result = await http.put(`/support/${supportId}`, body, headers);
      return result.data;
    },
    attachment: async (id: string, body: FormData) => {
      const headers = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-access-token': token,
        },
      };
      const result = await http.patch(`/support/${id}/attachment`, body, headers);
      return result.data;
    },
    countByStatus: async (schoolId: string) => {
      const result = await http.get(`/suport/count-by-status/${schoolId}`, {
        headers: {
          'x-access-token': token,
        },
      });
      return result.data;
    },
  };
};

export default Support;
