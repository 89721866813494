import { useState } from 'react';
import { Modal } from 'reactstrap';
import { Row } from 'reactstrap';
import KidsViewCamera from '../../Kids/KidsViewCamera';
const KidsCameras = ({ currentUser, kidsUser }: { currentUser: any; kidsUser: [] }) => {
  const [modalKidsViewCamera, setModalKidsViewCamera] = useState(false);
  const [selectedKid, setSelectedKid] = useState({ fullName: '', picture: '' });
  const [modalKidsViewCameraSelectedKid, setModalKidsViewCameraSelectedKid] = useState('');
  const [breadcrumbModal, setBreadcrumbModal] = useState('');

  const toggleModalKidsViewCamera = () => {
    setModalKidsViewCameraSelectedKid('');
    setModalKidsViewCamera(!modalKidsViewCamera);
  };

  return (
    <>
      <Row>
        <button
          className="w-100 btn-lg btn-primary mt-3"
          style={{ height: '100px', fontSize: '30px', fontWeight: 'bold' }}
          onClick={() => toggleModalKidsViewCamera()}
        >
          <i className="fas fa-camera"></i>&nbsp;Cameras
        </button>
      </Row>

      <Modal className="modal-dialog-centered" isOpen={modalKidsViewCamera} toggle={() => toggleModalKidsViewCamera()}>
        <div className="modal-header">
          <h5 className="modal-title w-100" id="Label">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb parentsDash">
                <li className="breadcrumb-item" style={{ paddingTop: '8px' }}>
                  <a
                    href="#"
                    style={{ color: '#5e72e4' }}
                    onClick={(e) => {
                      e.preventDefault();
                      setModalKidsViewCameraSelectedKid('');
                    }}
                  >
                    Your Kids
                  </a>
                </li>
                {breadcrumbModal !== '' && (
                  <li className="breadcrumb-item active" aria-current="page" style={{ color: '#8898aa' }}>
                    <div className="align-items-center media">
                      <span className="avatar avatar-sm rounded-circle">
                        <img alt={selectedKid.fullName} src={selectedKid.picture} style={{ height: '100%' }} />
                      </span>
                      <div className="ml-2 d-none d-lg-block media">
                        <span className="mb-0 font-weight-bold">{selectedKid.fullName}</span>
                      </div>
                    </div>
                  </li>
                )}
              </ol>
            </nav>
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => toggleModalKidsViewCamera()}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body pt-0" style={{ minHeight: '300px' }}>
          <KidsViewCamera
            kidsList={kidsUser}
            currentUser={currentUser}
            setSelectedKidObject={(kid: any) => {
              setBreadcrumbModal(kid.fullName || '');
              setSelectedKid(kid);
            }}
            selectedKid={modalKidsViewCameraSelectedKid}
            setSelectedKid={(e: any) => setModalKidsViewCameraSelectedKid(e)}
          />
        </div>
      </Modal>
    </>
  );
};

export default KidsCameras;
