import http from '../helpers/http';

const QrCode = () => {
  return {
    get: async (token: string) => {
      const result = await http.get('/qrcode', {
        headers: {
          'x-access-token': token,
        },
      });
      return result.data;
    },
  };
};

export default QrCode;
