import RoutingSupportTicketsInterface from '../interfaces/RoutingSupportTicketsInterface';
import http from '../helpers/http';

const RoutingSupportTickets = (token: string) => {
  const headers = {
    'x-access-token': token,
  };

  return {
    list: async (schoolId: string) => {
      const result = await http.get(`/routing-support-tickets/${schoolId}`, {
        headers,
      });
      return result.data;
    },
    delete: async (schoolId: string, RoutingSupportTicketsId: string) => {
      const result = await http.delete(`/routing-support-tickets/${schoolId}/${RoutingSupportTicketsId}`, {
        headers,
      });
      return result.data;
    },
    create: async (schoolId: string, body: Partial<RoutingSupportTicketsInterface>) => {
      const result = await http.post(`/routing-support-tickets/${schoolId}`, body, { headers });
      return result.data;
    },
  };
};

export default RoutingSupportTickets;
