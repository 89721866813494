import { useState, useEffect, useContext } from 'react';
import { Col, Nav, NavItem, Row, TabContent, TabPane, NavLink, FormGroup, InputGroup, Input } from 'reactstrap';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import CardInfo from '../../../components/Cards/CardInfo';
import ModalContent from '../../../components/Modals/Modal';
import DataTable from '../../../components/Core/DataTable';
import { Link } from 'react-router-dom';
import ActivitySettings from '../../../services/Attendances';

export const PastDueKids: React.FC<{
  token: string;
  currentSchoolId: string;
}> = ({ token, currentSchoolId }) => {
  const { currentSchool }: any = useContext(AuthContext);
  const [toggleModal, setToggleModal] = useState(false);
  const handleToggleModal = () => setToggleModal(!toggleModal);
  const [activeTab, setActiveTab] = useState('1');
  const [kidsTopastDueData, setKidsTopastDueData] = useState<any>([]);
  const [pastDueData, setPastDueData] = useState<any>([]);
  const [notUpdatedData, setNotUpdatedData] = useState<any>([]);
  const [director, setDirector] = useState([]);
  const [parent, setParent] = useState([]);
  const schoolId = currentSchool.value;

  useEffect(() => {
    setPastDueData([]);
    for (const kid of kidsTopastDueData) {
      const newPastDueData = pastDueData;
      const newNotUpdatedData = notUpdatedData;

      const foodActvity = kid.kidsActivityRelations.filter((kid: any) => kid.key === 'FOOD');
      const lastFoodActivity = foodActvity[foodActvity.length - 1];

      const bathroomActvity = kid.kidsActivityRelations.filter((kid: any) => kid.key === 'BATHROOM');
      const lastBathroomActivity = bathroomActvity[bathroomActvity.length - 1];

      const lastActivity = kid.kidsActivityRelations[kid.kidsActivityRelations.length - 1];
      const now = new Date();

      if (lastActivity === 'CHECKOUT') return;

      if (lastActivity?.key === 'CHECKIN') {
        if (kid.notPottyTrained) {
          const bathroomTime = new Date(lastActivity.time);
          const timeDifference = bathroomTime ? (now.getTime() - bathroomTime.getTime()) / (1000 * 60 * 60) : null;

          if (timeDifference !== null && timeDifference >= 2) {
            newPastDueData.push({
              id: kid.id,
              firstName: kid.firstName,
              lastName: kid.lastName,
              type: 'BATHROOM',
              time: lastActivity.time,
              class: {
                id: kid.class.id,
                name: kid.class.name,
              },
            });
          }
        }

        if (kid.feedingSchedule) {
          const feedingTime = new Date(lastActivity.time);
          const timeDifference = feedingTime ? (now.getTime() - feedingTime.getTime()) / (1000 * 60 * 60) : null;
          if (timeDifference !== null && timeDifference >= kid.feedingIntervalHours) {
            newPastDueData.push({
              id: kid.id,
              firstName: kid.firstName,
              lastName: kid.lastName,
              type: 'FOOD',
              class: {
                id: kid.class.id,
                name: kid.class.name,
              },
            });
          }
        }

        const checkinTime = new Date(lastActivity.time);
        const timeDifference = checkinTime ? (now.getTime() - checkinTime.getTime()) / (1000 * 60 * 60) : null;
        if (timeDifference !== null && timeDifference >= kid.feedingIntervalHours) {
          newNotUpdatedData.push({
            id: kid.id,
            firstName: kid.firstName,
            lastName: kid.lastName,
            class: {
              id: kid.class.id,
              name: kid.class.name,
            },
          });
        }
      }

      if (kid.feedingSchedule) {
        if (lastFoodActivity) {
          const feedingTime = new Date(lastFoodActivity.time);
          const timeDifference = feedingTime ? (now.getTime() - feedingTime.getTime()) / (1000 * 60 * 60) : null;

          if (timeDifference !== null && timeDifference >= kid.feedingIntervalHours) {
            newPastDueData.push({
              id: kid.id,
              firstName: kid.firstName,
              lastName: kid.lastName,
              type: 'FOOD',
              time: lastFoodActivity.time,
              class: {
                id: kid.class.id,
                name: kid.class.name,
              },
            });
          }
        }
      }

      if (kid.notPottyTrained) {
        if (lastBathroomActivity) {
          const bathroomTime = new Date(lastBathroomActivity.time);
          const timeDifference = bathroomTime ? (now.getTime() - bathroomTime.getTime()) / (1000 * 60 * 60) : null;

          if (timeDifference !== null && timeDifference >= 2) {
            newPastDueData.push({
              id: kid.id,
              firstName: kid.firstName,
              lastName: kid.lastName,
              type: 'BATHROOM',
              time: lastBathroomActivity.time,
              class: {
                id: kid.class.id,
                name: kid.class.name,
              },
            });
          }
        }
      }

      setPastDueData(newPastDueData);
      setNotUpdatedData(newNotUpdatedData);
    }
  }, [kidsTopastDueData]);

  const customStyles = {
    navLink: {
      minHeight: '42px',
      paddingTop: '8px',
      cursor: 'pointer',
    },
  };

  const columnsDirector = [
    {
      name: 'Name',
      selector: (row: any) => <span>{`${row?.firstName || '-'} ${row?.lastName || '-'}`}</span>,
      grow: 1,
    },
    {
      name: 'Class',
      selector: (row: any) => row.class.name,
      grow: 1,
    },
  ];

  const columnsParent = [
    {
      name: 'Name',
      selector: (row: any) => (
        <Link to={`class/${row?.class.id}/details`}>
          <span>{`${row?.firstName || '-'} ${row?.lastName || '-'}`}</span>
        </Link>
      ),
      grow: 1,
    },
    {
      name: 'Class',
      selector: (row: any) => row.class.name,
      grow: 1,
    },
    {
      name: 'Type',
      selector: (row: any) => row.type,
      grow: 1,
    },
  ];

  useEffect(() => {
    loadList();
  }, [currentSchoolId, token]);

  useEffect(() => {
    populateTabs();
  }, [pastDueData]);

  const loadList = async () => {
    if (!schoolId) return;
    const result = await ActivitySettings(token).listKidsReminderBySchool(schoolId);
    setKidsTopastDueData(result?.data);
  };

  const populateTabs = () => {
    const directorData = notUpdatedData;
    const parentData = pastDueData;

    setDirector(directorData);
    setParent(parentData);
  };

  return (
    <>
      <CardInfo
        count={pastDueData.length + notUpdatedData.length}
        class="bg-warning card-info-mg-button"
        title="Child not Updated"
        icon="fa-exclamation-triangle"
        toggle={handleToggleModal}
      />
      <ModalContent title="Past Due" class="pt-0" modal={toggleModal} toggle={handleToggleModal} size="lg">
        <Row>
          <Col lg="4">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-validAt">
                Class
              </label>
              <InputGroup className="input-group-alternative">
                <Input type="select">
                  <option>Test</option>
                </Input>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={`${activeTab === '1' ? 'active' : ''}`}
                  onClick={() => setActiveTab('1')}
                  style={customStyles.navLink}
                >
                  Child not Updated
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={`${activeTab === '2' ? 'active' : ''}`}
                  onClick={() => setActiveTab('2')}
                  style={customStyles.navLink}
                >
                  Timer Due
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
          <Col xs="12">
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                {director && (
                  <DataTable
                    header={false}
                    shadowStyle={false}
                    columns={columnsDirector}
                    data={director}
                    totalRows={director.length}
                  />
                )}
              </TabPane>
            </TabContent>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="2">
                {parent && (
                  <DataTable
                    header={false}
                    shadowStyle={false}
                    columns={columnsParent}
                    data={parent}
                    totalRows={parent.length}
                  />
                )}
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </ModalContent>
    </>
  );
};
