import { Container, Row, Col, Card, CardBody, CardHeader } from 'reactstrap';
import CommonHeader from '../../../components/Headers/CommonHeader';

const SettingsList = () => {
  return (
    <>
      <CommonHeader />
      <Container className="mt--8" fluid>
        <Row>
          <Col className="order-xl-2" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col>
                    <Row>
                      <Col xs="12">
                        <h3 className="mb-0">Settings</h3>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12">
                        <p> </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>aaaaaaa</CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SettingsList;
