import { Card, Button, Container, Row, Col, CardBody, FormGroup, Form, Input } from 'reactstrap';
import { useContext, useState, useRef, useEffect } from 'react';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import { toast } from 'react-toastify';
import Documents from '../../../services/Documents';

import { useLocation } from 'react-router-dom';

type FoldersIdProps = {
  onModalStatus: () => void;
  title: string;
  onRefresh: () => void;
  fileId?: string;
};

const DocumentsForm = ({ onModalStatus, title, onRefresh, fileId }: FoldersIdProps) => {
  const { token, currentSchool }: any = useContext(AuthContext);
  const { hash } = useLocation();
  const hashFolderId = hash.replace('#', '');
  const schoolId = currentSchool?.value;
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const position = 100;
  const documentsFoldersId = hashFolderId;
  const [name, setName] = useState('');

  const fileRef = useRef(null as any);
  const [attachment, setAttachment] = useState({} as any);

  const [pictureUrl, setPictureUrl] = useState('');
  //const [attachmentUrl, setAttachmentUrl] = useState('');

  const changeonModalStatus = () => {
    onModalStatus();
  };

  const showOpenFileDialog = () => {
    if (isDisabled) {
      return;
    }
    if (fileRef?.current?.click) {
      fileRef.current.click();
    }
  };

  const onChangeFile = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];
    setAttachment(file);
  };

  const handleSubmitForm = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);
    setIsDisabled(true);

    const body = {
      schoolId,
      documentsFoldersId: documentsFoldersId,
      name,
      position,
    };

    try {
      const result = fileId ? await Documents(token).update(fileId, body) : await Documents(token).create(body);

      if (attachment) {
        const formData = new FormData();
        formData.append('attachment', attachment as any);
        await Documents(token).file(result?.data?.id, formData);
      }

      toast.success(`${fileId ? 'Updated' : 'Created'} successfully!`);
      if (result?.data?.id) {
        onModalStatus();
        onRefresh();
      }
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Create error!');
      toast.error(message);
    }

    setLoading(false);
    setIsDisabled(false);
  };

  useEffect(() => {
    if (fileId) {
      getById();
    }
  }, []);

  const getById = async () => {
    try {
      const result = await Documents(token).show(fileId || '');
      if (result?.data) {
        setName(result?.data?.name || '');
        setPictureUrl(result?.data?.picture || '');
        //setAttachmentUrl(result?.data?.attachment || '');
      }
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'Invalid Document ID');
    }
  };

  return (
    <>
      <Container fluid>
        <Form onSubmit={handleSubmitForm}>
          <Card>
            <CardBody>
              <Row>
                <Col lg="12">
                  <Row style={{ display: 'grid' }}>
                    <Row>
                      <Col lg="12">
                        <h6 className="heading-small text-muted mb-4">
                          PDF <small style={{ textTransform: 'none' }}></small>
                        </h6>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12" style={{ textAlign: 'center' }}>
                        <FormGroup>
                          <input
                            id="img-picture"
                            name="attach"
                            type="file"
                            accept=".pdf"
                            style={{ display: 'none' }}
                            ref={fileRef}
                            disabled={isDisabled}
                            onChange={onChangeFile}
                            required={fileId ? false : true}
                          />
                          <img
                            alt={title}
                            className=""
                            src={pictureUrl ? `${pictureUrl}` : require('../../../assets/img/default.png').default}
                            style={{ width: 135, height: 135, cursor: isDisabled ? 'not-allowed' : 'pointer' }}
                            onClick={showOpenFileDialog}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-name">
                      File Name
                    </label>
                    <Input
                      className="form-control-alternative"
                      id="input-name"
                      placeholder="File Name"
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row className="mt-5">
                <Col md="6 text-center" xs="6">
                  <Button color="primary" type="submit" size="lg" style={{ minWidth: '100px' }}>
                    Save
                  </Button>
                </Col>
                <Col md="6 text-center" xs="6">
                  <Button color="secondary" size="lg" style={{ minWidth: '100px' }} onClick={changeonModalStatus}>
                    Cancel
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Form>
      </Container>
    </>
  );
};

export default DocumentsForm;
