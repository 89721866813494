import { Row, Col } from 'reactstrap';

import CardInfo from '../../../components/Cards/CardInfo';
import { KidsCheckModal } from './KidsCheckModal';
import { StaffCheckModal } from './StaffCheckModal';
import { BirthdayAndAnniversary } from './BirthdayAndAnniversaryModal';

import FranchiseeInterface from '../../../interfaces/FranchiseeInterface';
import { useEffect, useState } from 'react';
import { FilteredOptionsInterface } from '../../../entities/FilteredOptions';
import UserService from '../../../services/User';
import UserInterface from '../../../interfaces/UserInterface';
import { createPagedMeta, PagedMetaInterface } from '../../../entities/PagedMeta';
import { createOptionsPage } from '../../../entities/OptionsPaged';
import { IncidentReportModal } from './IncidentReportModal';
import { PastDueKids } from './PastDueKids';
import { RatioTeachers } from './RatioTeachers';
import { EnrolledDetailsModal } from './EnrolledDetailsModal';
import { isMobile } from 'react-device-detect';

const mobileStyles = {
  marginBottom: 2,
  padding: 2,
};

export const DashInfo: React.FC<{
  currentSchool: Partial<FranchiseeInterface>;
  token: string;
  kids: Array<any>;
  handleStudentsModal?: (title: string, type: string) => void;
  refreshData?: () => void;
}> = ({ kids, token, currentSchool, handleStudentsModal, refreshData }) => {
  const isTablet = window.innerWidth <= 1112 && !isMobile;
  const isLaptop = window.innerWidth >= 1035 && window.innerWidth <= 1395;
  const classTypes = ['FT', 'PT', 'SA', 'VPK'];
  const [users, setUsers] = useState([] as any[]);
  const [pagedMeta, setPagedMeta] = useState(createPagedMeta());
  const [modalDetails, setModalDetails] = useState<boolean>(false);

  const count = (status: string | string[], type?: string) => {
    if (status && type) {
      return (
        kids?.filter(
          (k: any) =>
            (Array.isArray(status) ? status.includes(k?.enrollment?.status) : k?.enrollment?.status === status) &&
            k?.enrollment?.classType?.type === type,
        ).length || 0
      );
    }
    return (
      kids?.filter((k: any) =>
        Array.isArray(status) ? status.includes(k?.enrollment?.status) : k?.enrollment?.status === status,
      ).length || 0
    );
  };

  const enrolled = Number(count(['ENROLLED']));
  const checkedIn = kids?.filter((k: any) => k?.isCheckedIn).length || 0;

  const loadUsersList = async () => {
    if (!currentSchool?.value) return;
    const optionsPage = createOptionsPage(pagedMeta);
    optionsPage.itemsPerPage = 10000;
    const filteredOptions: FilteredOptionsInterface = {
      filters: [],
      options: optionsPage,
    };
    try {
      const result = await UserService(token || '').list(filteredOptions);
      const resultData = result?.data?.length ? result?.data : [];
      const data: UserInterface[] = resultData.filter((u: any) => u?.employee?.schoolId === currentSchool.value);
      setUsers(data);
      const meta: PagedMetaInterface = result.meta;
      if (meta) {
        setPagedMeta(meta);
      }
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Error fetching list');
      return console.error(message);
    }
  };

  useEffect(() => {
    loadUsersList();
  }, [currentSchool.value]);

  return (
    <Row className={`${isTablet ? '' : 'mb-4'}`}>
      <div className={`d-flex flex-wrap w-100 ${isMobile || isTablet ? '' : 'mb-4'}`}>
        <Col lg="3" sm="6" xs="6" className="home-card-info" style={isMobile || isTablet ? mobileStyles : {}}>
          <CardInfo
            title={isMobile || isTablet ? 'FTE Currently E.' : 'FTE Currently Enrolled'}
            class="bg-success"
            count={count(['ENROLLED'], 'FT') + count(['ENROLLED'], 'PT') / 2}
            icon="fa-users"
          />
        </Col>
        <Col lg="3" sm="6" xs="6" className="home-card-info" style={isMobile || isTablet ? mobileStyles : {}}>
          <CardInfo
            title={isMobile || isTablet ? 'Current E. Children' : 'Current Enrolled Children'}
            class="bg-primary"
            count={enrolled}
            icon="fa-user"
            toggle={() => (handleStudentsModal ? handleStudentsModal('Current Enrolled Children', 'CURRENT') : {})}
          >
            <div
              className={`d-flex justify-content-center ${isMobile || isTablet ? 'pl-4' : 'dash-info-list'} ${
                isLaptop ? 'mt-n2' : ''
              }`}
            >
              {classTypes.map((type: string, index: number) => (
                <span
                  key={index}
                  className={`d-flex flex-column ${isTablet ? 'mr-2 ml-1' : 'mr-3'}`}
                  style={isTablet ? { fontSize: 14 } : {}}
                >
                  <b>{type}</b>
                  <p className="mb-0">{count(['ENROLLED'], type)}</p>
                </span>
              ))}
              <span className="d-flex flex-column mr-3" style={isTablet ? { fontSize: 14 } : {}}>
                <b>TT</b>
                <p className="mb-0">{count(['ENROLLED'])}</p>
              </span>
            </div>
          </CardInfo>
        </Col>
        <Col lg="3" sm="6" xs="6" className="home-card-info" style={isMobile || isTablet ? mobileStyles : {}}>
          <CardInfo
            title={isMobile || isTablet ? 'Future E. Children' : 'Future Enrolled Children'}
            class="bg-yellow"
            count={count('ENROLLED_NOT_STARTED')}
            icon="fa-user-clock"
            toggle={() => (handleStudentsModal ? handleStudentsModal('Future Enrolled Children', 'FUTURE') : {})}
          >
            <div
              className={`d-flex justify-content-center ${isMobile || isTablet ? 'pl-4' : 'dash-info-list'} ${
                isLaptop ? 'mt-n2' : ''
              }`}
            >
              {classTypes.map((type: string, index: number) => (
                <span
                  key={index}
                  className={`d-flex flex-column ${isTablet ? 'mr-2 ml-1' : 'mr-3'}`}
                  style={isTablet ? { fontSize: 14 } : {}}
                >
                  <b>{type}</b>
                  <p className="mb-0">{count('ENROLLED_NOT_STARTED', type)}</p>
                </span>
              ))}
              <span className="d-flex flex-column mr-3" style={isTablet ? { fontSize: 14 } : {}}>
                <b>TT</b>
                <p className="mb-0">{count('ENROLLED_NOT_STARTED')}</p>
              </span>
            </div>
          </CardInfo>
        </Col>
        <Col lg="3" sm="6" xs="6" className="home-card-info" style={isMobile || isTablet ? mobileStyles : {}}>
          <CardInfo
            title={isMobile || isTablet ? 'Total E. Children' : 'Total Enrolled Children'}
            class="bg-info"
            count={count(['ENROLLED', 'ENROLLED_NOT_STARTED'])}
            icon="fa-users"
            toggle={() => (handleStudentsModal ? handleStudentsModal('Total Enrolled Children', 'ALL') : {})}
            handleCountClick={() => setModalDetails(true)}
          />
        </Col>
      </div>

      <div className={`d-flex flex-wrap w-100 ${!isMobile && !isTablet ? 'mb-4' : ''}`}>
        <Col lg="3" xl="3" sm="6" xs="6" className="home-card-info" style={isMobile || isTablet ? mobileStyles : {}}>
          <KidsCheckModal
            token={token}
            kids={kids}
            count={checkedIn}
            currentSchoolId={currentSchool?.value || ''}
            refreshData={refreshData}
          />
        </Col>
        <Col lg="3" xl="3" sm="6" xs="6" className="home-card-info" style={isMobile || isTablet ? mobileStyles : {}}>
          <StaffCheckModal
            token={token}
            users={users}
            count={users.filter((u) => u.isClockedIn).length}
            currentSchool={currentSchool || {}}
            refreshData={loadUsersList}
          />
        </Col>
        <Col lg="3" xl="3" sm="6" xs="6" className="home-card-info" style={isMobile || isTablet ? mobileStyles : {}}>
          <BirthdayAndAnniversary token={token} currentSchoolId={currentSchool.value || ''} />
        </Col>
        <Col lg="3" xl="3" sm="6" xs="6" className="home-card-info" style={isMobile || isTablet ? mobileStyles : {}}>
          <IncidentReportModal token={token} currentSchoolId={currentSchool.value || ''} />
        </Col>
      </div>
      <div className={`d-flex flex-wrap w-100 ${!isMobile || isTablet ? 'mb-4' : ''}`}>
        <Col lg="3" xl="3" sm="6" xs="6" className="home-card-info" style={isMobile || isTablet ? mobileStyles : {}}>
          <PastDueKids token={token} currentSchoolId={currentSchool.value || ''} />
        </Col>
        <Col lg="3" xl="3" sm="6" xs="6" className="home-card-info" style={isMobile || isTablet ? mobileStyles : {}}>
          <RatioTeachers token={token} currentSchoolId={currentSchool.value || ''} />
        </Col>
      </div>
      <EnrolledDetailsModal
        toggleModal={modalDetails}
        handleToggleModal={() => setModalDetails(!modalDetails)}
        kids={kids?.filter((k: any) => ['ENROLLED_NOT_STARTED', 'ENROLLED'].includes(k?.enrollment?.status))}
      />
    </Row>
  );
};
