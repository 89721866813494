/* eslint-disable max-lines */
import { Container, Row, Col, Card, Button, ButtonGroup, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import TourService from '../../../services/TourPublic';
import ScheduleCalendar from '../SchedulePublic/ScheduleCalendar';
import ScheduleForm from '../SchedulePublic/ScheduleForm';
import ScheduleService from '../../../services/SchedulePublic';
import moment from 'moment';
import { dateTimeFormat } from '../../../helpers/dateFormat';
import confirmAlert from '../../../helpers/confirmAlertPublic';
import { toast } from 'react-toastify';

const TourScheduleForm = () => {
  const { franchiseeId, schoolUri, scheduleId } = useParams<{
    franchiseeId: string;
    schoolUri: string;
    scheduleId?: string;
  }>();
  const [franchiseePicture, setFranchiseePicture] = useState('');
  const [schools, setSchools] = useState([] as any[]);
  const [currentScheduleId, setCurrentScheduleId] = useState<string>('');
  const [currentSchedule, setCurrentSchedule] = useState<any>({});
  const [franchiseeName, setFranchiseeName] = useState('');
  const [selectedSchool, setSelectedSchool] = useState('');
  const [selectedSchoolName, setSelectedSchoolName] = useState('');
  const [selectedSchoolAddress, setSelectedSchoolAddress] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTime, setSelectedTime] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [showTime, setShowTime] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showUnavailable, setShowUnavailable] = useState(false);
  const [unavailableMessage, setUnavailableMessage] = useState('');
  const [timeSlot, setTimeSlot] = useState<{ id: number; time: string; timeDb: string; schoolId: string }[]>([]);

  const styleModalText: any = { textAlign: 'center', fontSize: '1rem', fontWeight: 300 };
  const styleModalTextStrong: any = { ...styleModalText, fontSize: '1rem', fontWeight: 900 };

  const getSchools = async () => {
    try {
      const result = await TourService().getSchoolList(franchiseeId);
      const data = result?.data || [];
      if (!data?.length) {
        setUnavailableMessage('Invalid tour.');
        setShowUnavailable(true);
        return;
      }
      if (schoolUri) {
        const currentSchool = result?.data?.find((s: any) => s.uri === schoolUri);
        if (!currentSchool?.tourList) {
          setUnavailableMessage('Sorry, the tour is currently unavailable for this school.');
          setShowUnavailable(true);
        }
        setSchools(currentSchool ? [currentSchool] : []);
        setSelectedSchool(currentSchool?.id || '');
        setSelectedSchoolName(currentSchool?.name || '');
        setFranchiseeName(currentSchool?.franchisee?.name || '');
        setFranchiseePicture(currentSchool?.franchisee?.picture || '');
        const addressObj = currentSchool?.addresses?.length ? currentSchool?.addresses[0] : {};
        const addressState = addressObj?.state || {};
        const schoolAddress = `${addressObj?.address}, ${addressObj?.city}, ${addressState?.uf} ${addressObj?.zipCode}`;
        setSelectedSchoolAddress(schoolAddress);
        return;
      }
      setSchools(result?.data || []);
      setSelectedSchool(result?.data?.[0]?.id || '');
      setFranchiseeName(result?.data?.[0]?.franchisee?.name || '');
      setFranchiseePicture(result?.data?.[0]?.franchisee?.picture || '');
    } catch (e) {
      console.error('Error searching for School');
      setUnavailableMessage('Invalid tour.');
      setShowUnavailable(true);
    }
  };

  const onClickDay = (date: string) => {
    setSelectedDate(date);
    setShowTime(true);
  };

  useEffect(() => {
    getSchools();
  }, [franchiseeId]);

  useEffect(() => {
    setCurrentScheduleId(scheduleId || '');
    showSchedule();
  }, [scheduleId]);

  useEffect(() => {
    if (currentSchedule?.id) {
      return setShowModal(true);
    }
    setShowModal(false);
  }, [currentSchedule]);

  useEffect(() => {
    getTimeSlot();
  }, [showTime, selectedDate]);

  const showSchedule = async () => {
    try {
      if (scheduleId) {
        const result = await ScheduleService().show(scheduleId);
        if (result?.data) {
          if (result?.data?.kids?.length) {
            const scheduleKids = [];
            for (const sk of result?.data?.kids) {
              if (sk.id)
                scheduleKids.push({
                  id: sk.id,
                  firstName: sk.firstName,
                  lastName: sk.lastName,
                  birthday: moment(sk.birthday).format('MM/DD/YYYY'),
                  potentialDate: sk.potentialStartDate ? moment(sk.potentialStartDate).format('MM/DD/YYYY') : '',
                  tourStatus: sk.status,
                });
            }
            setCurrentSchedule({ ...(result?.data || {}), scheduleKids });
          }
        }
      }
    } catch (e: any) {
      console.error(e?.response?.data?.message || 'No tour found');
    }
  };

  const cancelSchedule = async () => {
    try {
      if (scheduleId) {
        const result = await ScheduleService().delete(scheduleId);
        if (result) {
          setCurrentSchedule({});
          setCurrentScheduleId('');
          toast.success('Tour canceled successfully!');
        }
      }
    } catch (e: any) {
      console.error(e?.response?.data?.message || 'No tour found');
    }
  };

  const getTimeSlot = async () => {
    try {
      if (selectedSchool) {
        const resultBlockDays = await ScheduleService().blockDays(selectedSchool);
        const blockDaysData: any[] = resultBlockDays?.data || [];
        let isDateBlocked: any = {};
        if (selectedDate) {
          isDateBlocked = blockDaysData.find(
            (b: any) => moment(b?.dateBlock).format('YYYY-MM-DD') === moment(selectedDate).format('YYYY-MM-DD'),
          );
          if (isDateBlocked?.typeBlock === 'entire_day') {
            setTimeSlot([]);
            return;
          }
        }
        const result = await ScheduleService().timeSlot(selectedSchool);
        const data: any = result?.data || {};
        if (data?.timeSlot?.length) {
          const times = [];
          let idTimeSlot = 1;
          for (const t of data?.timeSlot) {
            const time = moment(`${moment().format('YYYY-MM-DD')} ${t}`);
            let startBlock: any = undefined;
            let endBlock: any = undefined;
            if (isDateBlocked?.typeBlock && isDateBlocked?.typeBlock !== 'entire_day') {
              const timeBlock = Number(isDateBlocked?.typeBlock);
              const timeStartBlock = moment(`${moment().format('YYYY-MM-DD')} ${isDateBlocked?.timeStartBlock}`);
              startBlock = moment(`${timeStartBlock.format('YYYY-MM-DD HH:mm')}`);
              endBlock = moment(`${timeStartBlock.format('YYYY-MM-DD HH:mm')}`).add(Number(timeBlock), 'minutes');
            }
            if (startBlock && endBlock && time >= startBlock && time <= endBlock) {
              continue;
            }
            times.push({
              id: idTimeSlot,
              time: time.format('hh:mm A'),
              timeDb: time.format('HH:mm:ss'),
              schoolId: selectedSchool,
            });
            idTimeSlot++;
          }
          setTimeSlot(times);
        }
      }
    } catch (e: any) {
      console.error(e?.response?.data?.message || 'No time slot found');
    }
  };

  return (
    <>
      <Container fluid>
        {!showUnavailable ? (
          <Row>
            <Col md="12">
              <Card className="shadow mt-5">
                <Row>
                  <Col md="4">
                    <Row>
                      <Col md="12" style={{ textAlign: 'center' }}>
                        <img src={franchiseePicture} style={{ maxWidth: '200px' }} className="mt-3"></img>
                        <p style={{ fontSize: '1.5em', textAlign: 'center' }} className="mt-2">
                          Schedule a Tour
                        </p>
                        <hr style={{ marginTop: '0px', marginBottom: '5px' }} />
                        <h5>Select Your Preferred School:</h5>
                      </Col>
                      <Col md="12" style={{ textAlign: 'center' }} className="mt-2 pb-2">
                        {schools
                          .filter((s: any) => (!showForm ? true : s?.id === selectedSchool))
                          .map((s: any, index: number) => (
                            <Card
                              key={`school-${index}`}
                              className={`card-stats card-select mb-2 ${
                                s?.id === selectedSchool ? 'card-selected' : ''
                              }`}
                              style={{ minHeight: '60px' }}
                              onClick={() => {
                                setSelectedSchool(s?.id);
                                setFranchiseeName(s?.franchisee?.name || '');
                              }}
                            >
                              <span>
                                <strong>{`${s?.franchisee?.name} ${s?.name}`}</strong>
                              </span>
                              <span>
                                {`${s?.addresses?.[0]?.address}`}, {`${s?.addresses?.[0]?.city}`},{' '}
                                {`${s?.addresses?.[0]?.state?.uf} ${s?.addresses?.[0]?.zipCode}`}
                              </span>
                            </Card>
                          ))}
                      </Col>
                    </Row>
                  </Col>
                  {!showForm && (
                    <>
                      <Col md={`${showTime ? '6' : '8'}`}>
                        <ScheduleCalendar onClickDay={onClickDay} />
                      </Col>
                    </>
                  )}
                  {showTime && !showForm && (
                    <>
                      <Col md="2" style={{ paddingRight: '0px', paddingLeft: '0px' }}>
                        {!timeSlot.length ? (
                          <div style={{ width: '90%' }}>
                            <Row>
                              <Col md="12" className="mt-4 text-center">
                                There are no times available for this date.
                              </Col>
                            </Row>
                          </div>
                        ) : (
                          <>
                            <div
                              style={{
                                position: 'absolute',
                                maxHeight: '100%',
                                overflow: 'scroll',
                                paddingTop: '12px',
                                backgroundColor: '#FFF',
                                width: '91%',
                              }}
                            >
                              {timeSlot.map((s: any, index: number) => {
                                return (
                                  <div key={`time-slot-${index}`} style={{ textAlign: 'center' }}>
                                    <Row
                                      className="mt-1"
                                      style={{ display: `${selectedTime === s.timeDb ? 'none' : 'block'}` }}
                                    >
                                      <Col md="12">
                                        <Button
                                          style={{ fontSize: '12px', minWidth: '145px' }}
                                          size="sm"
                                          color="outline-primary"
                                          className="mb-2"
                                          onClick={() => setSelectedTime(s.timeDb)}
                                        >
                                          {s.time}
                                        </Button>
                                      </Col>
                                    </Row>
                                    <Row
                                      className="mt-1"
                                      style={{ display: `${selectedTime === s.timeDb ? 'block' : 'none'}` }}
                                    >
                                      <Col md="12">
                                        <ButtonGroup className="mb-2" style={{ minWidth: '145px' }}>
                                          <Button
                                            color="outline-primary"
                                            size="sm"
                                            disabled={true}
                                            style={{ fontSize: '10px' }}
                                          >
                                            {s.time}
                                          </Button>
                                          <Button
                                            size="sm"
                                            color="success"
                                            style={{ fontSize: '10px' }}
                                            onClick={() => setShowForm(true)}
                                          >
                                            Confirm
                                          </Button>
                                        </ButtonGroup>
                                      </Col>
                                    </Row>
                                  </div>
                                );
                              })}
                            </div>
                          </>
                        )}
                      </Col>
                    </>
                  )}
                  {showForm && (
                    <Col mod="8">
                      <ScheduleForm
                        isOpen={true}
                        scheduleId={currentScheduleId || ''}
                        currentSchedule={currentSchedule || {}}
                        franchiseeName={franchiseeName}
                        schoolId={selectedSchool}
                        onModalStatus={() => {
                          setSelectedTime('');
                          setShowTime(false);
                          setShowForm(false);
                        }}
                        selectedDate={selectedDate}
                        selectedTime={selectedTime}
                        onCreateSchedule={showSchedule}
                      />
                    </Col>
                  )}
                </Row>
              </Card>
            </Col>
          </Row>
        ) : (
          <Card className="shadow mt-5 pl-4 pr-4 pb-2 pt-2">
            <Row>
              <Col mod="12" className="text-center">
                {unavailableMessage}
              </Col>
            </Row>
          </Card>
        )}
      </Container>
      <Modal className="modal-dialog-centered" size="lg" isOpen={showModal}>
        <ModalHeader></ModalHeader>
        <ModalBody>
          <p style={styleModalText}>
            {`Hello `}
            <strong
              style={styleModalTextStrong}
            >{`${currentSchedule?.firstName} ${currentSchedule?.lastName}!`}</strong>
          </p>
          <p style={styleModalText}>{`This is your confirmation:`}</p>
          <p style={styleModalTextStrong}>
            {currentSchedule?.dateTour
              ? dateTimeFormat(`${currentSchedule?.dateTour} ${currentSchedule.timeTour}`)
              : ''}
          </p>
          <p style={styleModalTextStrong}>{`${franchiseeName || ''} ${selectedSchoolName}`}</p>
          <p style={styleModalTextStrong}>{`${selectedSchoolAddress || ''}`}</p>
          <p style={styleModalText}>Select an option to reschedule or cancel your tour.</p>
        </ModalBody>
        <ModalFooter>
          <Row style={{ width: '100%' }}>
            <Col md="6" style={{ textAlign: 'left' }}>
              <Button color="primary" onClick={(_: any) => setShowModal(false)}>
                Reschedule Your Tour
              </Button>
            </Col>
            <Col md="6" style={{ textAlign: 'right' }}>
              <Button
                color="danger"
                onClick={(_: any) => {
                  setShowModal(false);
                  confirmAlert({
                    title: 'Confirm',
                    messageType: 'DELETE',
                    message: 'Are you sure you want to cancel the tour?',
                    onClickYes: () => {
                      cancelSchedule();
                    },
                    onClickNo: () => {
                      setShowModal(true);
                    },
                  });
                }}
              >
                Cancel Your Tour
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default TourScheduleForm;
