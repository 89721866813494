import {
  Modal,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardHeader,
  CardBody,
  TabContent,
  TabPane,
  Row,
  FormGroup,
  Label,
  Input,
  Form,
  Button,
  Col,
} from 'reactstrap';
import { toast } from 'react-toastify';
import { useContext, useState, useEffect } from 'react';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import ScheduleCalendarBlockDaysComponent from '../Schedule/ScheduleCalendarBlockDaysComponent';
import ScheduleBlockdaySettings from '../../../services/ScheduleBlockdaySettings';
import SchoolService from '../../../services/School';
import moment from 'moment';
import ScheduleTimeslotSettings from '../../../services/ScheduleTimeslotSettings';

type SchedulleSettingsModalProps = {
  isOpen: boolean;
  toggleModal: (e?: any) => void;
};

const customStyles = {
  navLink: {
    minHeight: '42px',
    paddingTop: '8px',
    cursor: 'pointer',
  },
};

const SchedulleSettingsModal = ({ isOpen, toggleModal }: SchedulleSettingsModalProps) => {
  const { token, currentSchool }: any = useContext(AuthContext);
  const [activeTab, setActiveTab] = useState('1');

  const [data, setData] = useState([]);
  const [updateEventList, setUpdateEventList] = useState(false);

  const dateToday = moment().format('YYYY-MM-DD');
  const [timeOperationStart, setTimeOperationStart] = useState(undefined as undefined | string);
  const [timeOperationEnd, setTimeOperationEnd] = useState(undefined as undefined | string);

  const [timeSlot, setTimeSlot] = useState([{ id: 1, time: '', timeDb: '', schoolId: currentSchool.value }]);

  const [selectedTimeSlot, setSelectedTimeSlot] = useState<string[]>([]);
  const [isAllSelected, setIsAllSelected] = useState(false);

  const getCalendarBlockDaysList = async () => {
    try {
      const result = await ScheduleBlockdaySettings(token || '').list(currentSchool.value);
      const dataResult = result.data;
      setData(dataResult);
    } catch (e: any) {
      console.error('Error');
    }
  };

  const listTimeSlot = async () => {
    try {
      const result = await ScheduleTimeslotSettings(token || '').show(currentSchool.value);
      const dataResult = result.data;
      setSelectedTimeSlot(dataResult?.timeSlot || []);
    } catch (e: any) {
      console.error('Error');
    }
  };

  const returnSchool = async () => {
    try {
      const result = await SchoolService(token || '').show(currentSchool.value);

      setTimeOperationStart(
        result?.data?.timeOperationStart ? `${dateToday} ${result?.data?.timeOperationStart}` : undefined,
      );
      setTimeOperationEnd(
        result?.data?.timeOperationEnd ? `${dateToday} ${result?.data?.timeOperationEnd}` : undefined,
      );
    } catch (e: any) {
      console.error('Error');
    }
  };

  const handleScheduleCreated = () => {
    setUpdateEventList(!updateEventList);
  };

  const returnAllTimeSlot = () => {
    const schoolTimeStart = moment(timeOperationStart);
    const schoolTimeEnd = moment(timeOperationEnd);
    const times = [];

    let idTimeSlot = 1;

    for (const m = moment(schoolTimeStart); m.diff(schoolTimeEnd, 'minutes') <= 0; m.add(30, 'minutes')) {
      times.push({
        id: idTimeSlot,
        time: m.format('hh:mm A'),
        timeDb: m.format('HH:mm'),
        schoolId: currentSchool.value,
      });
      idTimeSlot++;
    }
    setTimeSlot(times);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await ScheduleTimeslotSettings(token || '').createOrUpdate({
        timeSlot: selectedTimeSlot,
        schoolId: currentSchool.value,
      });
      toast.success(`Updated successfully!`);
    } catch (e) {
      console.error(e);
    }
  };

  const handleSelectAll = () => {
    const isAllSelected = selectedTimeSlot.length > 0 && selectedTimeSlot.length === timeSlot.length;
    if (isAllSelected) {
      setIsAllSelected(false);
      setSelectedTimeSlot([]);
      return;
    }
    setIsAllSelected(true);
    setSelectedTimeSlot(timeSlot.map((t) => t.timeDb));
  };

  const toggleAddTime = (timeDb: string) => {
    setSelectedTimeSlot((list) => {
      if (list.includes(timeDb)) {
        return list.filter((id) => id !== timeDb);
      } else {
        return [...list, timeDb];
      }
    });
  };

  useEffect(() => {
    const isAll = selectedTimeSlot.length > 0 && selectedTimeSlot.length === timeSlot.length;
    if (isAll) return setIsAllSelected(true);
    return setIsAllSelected(false);
  }, [selectedTimeSlot, timeSlot]);

  useEffect(() => {
    if (!isOpen) return;
    returnSchool();
    returnAllTimeSlot();
    getCalendarBlockDaysList();
  }, []);

  useEffect(() => {
    if (!isOpen) return;
    returnSchool();
    returnAllTimeSlot();
    getCalendarBlockDaysList();
    listTimeSlot();
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen) return;
    returnAllTimeSlot();
    getCalendarBlockDaysList();
  }, [timeOperationStart, timeOperationEnd]);

  useEffect(() => {
    if (!isOpen) return;
    getCalendarBlockDaysList();
  }, [updateEventList]);

  useEffect(() => {
    if (!isOpen) return;
    getCalendarBlockDaysList();
    returnSchool();
    listTimeSlot();
  }, [currentSchool]);

  return (
    <>
      <Modal
        className="modal-dialog-centered schedule-settings-modal"
        isOpen={isOpen}
        toggle={(e: any) => toggleModal(e)}
        size="lg"
      >
        <div className="modal-header pb-1">
          <h5 className="modal-title">Edit Tour Schedule Settings</h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={(e) => toggleModal(e)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body pt-0">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={`${activeTab === '1' ? 'active' : ''}`}
                    onClick={() => setActiveTab('1')}
                    style={customStyles.navLink}
                  >
                    <i className="fas fa-clock"></i> Time Slot
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={`${activeTab === '2' ? 'active' : ''}`}
                    onClick={() => setActiveTab('2')}
                    style={customStyles.navLink}
                  >
                    <i className="fas fa-calendar-alt"></i> Block Days
                  </NavLink>
                </NavItem>
              </Nav>
            </CardHeader>
            <CardBody>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1" className="pt-2">
                  <Row>
                    <Col lg="12">
                      <Form onSubmit={handleSubmit}>
                        <FormGroup check>
                          <Label className="d-flex align-items-center cursor-pointer" check>
                            <Input
                              type="checkbox"
                              className="mt-0"
                              onClick={handleSelectAll}
                              onChange={() => {}}
                              checked={isAllSelected}
                            />
                            {isAllSelected ? 'Unselect All' : 'Select All'}
                          </Label>
                        </FormGroup>
                        {timeSlot.map((s: any, index: number) => (
                          <FormGroup key={index} check>
                            <Label check>
                              <Input
                                name="options"
                                type="checkbox"
                                checked={selectedTimeSlot.includes(s.timeDb)}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  toggleAddTime(s.timeDb);
                                }}
                                onChange={() => {}}
                              />
                              {s.time}
                            </Label>
                          </FormGroup>
                        ))}
                        <div>
                          <Button className="my-4" color="primary" type="submit">
                            Save
                          </Button>
                        </div>
                      </Form>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row className={'schedule-calendar-component'}>
                    <ScheduleCalendarBlockDaysComponent events={data} updateEventList={handleScheduleCreated} />
                  </Row>
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </div>
      </Modal>
    </>
  );
};

export default SchedulleSettingsModal;
