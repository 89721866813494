/* eslint-disable max-lines */
import moment from 'moment';
import {
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Modal,
  Row,
} from 'reactstrap';
import ReactDatetime from 'react-datetime';
import { toLocaleFormatSlash } from '../../../helpers/dateFormat';

interface TerminatedEmployeeFormProps {
  isTerminatedEmployeeFormOpen: boolean;
  toogleTerminatedEmployeeForm: () => void;
  handleSubmitTerminatedUser: (v: any) => Promise<void>;
  terminatedEmployeeStep: number;
  terminationUser: any;
  terminationDate: string | Date;
  setTerminationDate: (v: any) => void;
  terminationCategory: string;
  setTerminationCategory: (v: any) => void;
  willBeRehired: boolean | null;
  setWillBeRehired: (v: any) => void;
  terminationReason: string;
  setTerminationReason: (v: any) => void;
  terminationNotes: string;
  setTerminationNotes: (v: any) => void;
  terminationUserEmail: string;
  setTerminationUserEmail: (v: any) => void;
  terminationNameToConfirm: string;
  setTerminationNameToConfirm: (v: any) => void;
  setTerminatedEmployeeStep: (v: any) => void;
}

const TerminatedEmployeeForm = ({
  isTerminatedEmployeeFormOpen,
  toogleTerminatedEmployeeForm,
  handleSubmitTerminatedUser,
  terminatedEmployeeStep,
  terminationUser,
  terminationDate,
  setTerminationDate,
  terminationCategory,
  setTerminationCategory,
  willBeRehired,
  setWillBeRehired,
  terminationReason,
  setTerminationReason,
  terminationNotes,
  setTerminationNotes,
  terminationUserEmail,
  setTerminationUserEmail,
  terminationNameToConfirm,
  setTerminationNameToConfirm,
  setTerminatedEmployeeStep,
}: TerminatedEmployeeFormProps) => {
  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={isTerminatedEmployeeFormOpen}
      toggle={toogleTerminatedEmployeeForm}
      size={'lg'}
    >
      <div className="modal-header">
        {terminatedEmployeeStep === 0 && (
          <h4 className="modal-title">
            First, provide some basic information about {terminationUser?.user?.firstName}'s termination
          </h4>
        )}
        {terminatedEmployeeStep === 1 && (
          <h4 className="modal-title">
            {terminationUser?.user?.firstName} will be marked as terminated on{' '}
            {moment(terminationDate).format('MMMM Do, YYYY')}.
          </h4>
        )}
        <div onClick={toogleTerminatedEmployeeForm} className="d-flex p-1 cursor-pointer">
          <i className="fas fa-times" />
        </div>
      </div>
      <div className="modal-body">
        <div className="px-2">
          <Form onSubmit={handleSubmitTerminatedUser}>
            {terminatedEmployeeStep === 0 && (
              <>
                <Row className="d-flex aligm-items-center mt-4">
                  <Col lg="4">
                    <label className="form-control-label">
                      When is {terminationUser?.user?.firstName}'s last day of work?
                    </label>
                  </Col>
                  <Col lg="8">
                    <FormGroup className="custom-datepicker">
                      <InputGroup className="input-group-alternative">
                        <ReactDatetime
                          inputProps={{
                            placeholder: 'Start Date',
                          }}
                          onChange={(newValue) => {
                            setTerminationDate(moment(newValue).format('MM/DD/yyyy'));
                          }}
                          timeFormat={false}
                          value={`${terminationDate}`}
                          dateFormat={'MM/DD/yyyy'}
                          closeOnSelect={true}
                        />
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-calendar-grid-58" />
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>

                <Row className="d-flex aligm-items-center mt-4">
                  <Col lg="4">
                    <label className="form-control-label">
                      How should {terminationUser?.user?.firstName}'s termination be categorized?
                    </label>
                  </Col>
                  <Col lg="8">
                    <div className="d-flex flex-column flex-wrap">
                      <div className="form-check form-check-inline mr-3">
                        <Input
                          className="form-check-input"
                          type="radio"
                          name="termiantion-category"
                          id="termiantion-category-involuntary"
                          value={'Involuntary'}
                          checked={terminationCategory === 'Involuntary'}
                          onChange={(e) => setTerminationCategory(e.target.value)}
                          required
                        />
                        <Label className="form-check-label mr-2" htmlFor="termiantion-category-involuntary">
                          Involuntary
                        </Label>
                      </div>
                      <div className="form-check form-check-inline mr-3">
                        <Input
                          className="form-check-input"
                          type="radio"
                          name="termiantion-category"
                          id="termiantion-category-voluntary"
                          value={'Voluntary'}
                          checked={terminationCategory === 'Voluntary'}
                          onChange={(e) => setTerminationCategory(e.target.value)}
                          required
                        />
                        <Label className="form-check-label mr-2" htmlFor="termiantion-category-voluntary">
                          Voluntary
                        </Label>
                      </div>

                      <div className="form-check form-check-inline mr-3">
                        <Input
                          className="form-check-input"
                          type="radio"
                          name="termiantion-category"
                          id="termiantion-category-not-classify"
                          value={'Do Not Classify'}
                          checked={terminationCategory === 'Do Not Classify'}
                          onChange={(e) => setTerminationCategory(e.target.value)}
                          required
                        />
                        <Label className="form-check-label mr-2" htmlFor="termiantion-category-not-classify">
                          Do Not Classify
                        </Label>
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row className="d-flex aligm-items-center mt-4">
                  <Col lg="4">
                    <label className="form-control-label">Would you hire this individual again?</label>
                  </Col>
                  <Col lg="8">
                    <div className="d-flex flex-column flex-wrap">
                      <div className="form-check form-check-inline mr-3">
                        <Input
                          className="form-check-input"
                          type="radio"
                          name="will-be-rehired"
                          id="will-be-rehired-yes"
                          checked={willBeRehired === true}
                          onChange={() => setWillBeRehired(true)}
                          required
                        />
                        <Label className="form-check-label mr-2" htmlFor="will-be-rehired-yes">
                          Yes
                        </Label>
                      </div>
                      <div className="form-check form-check-inline mr-3">
                        <Input
                          className="form-check-input"
                          type="radio"
                          name="will-be-rehired"
                          id="will-be-rehired-no"
                          checked={willBeRehired === false}
                          onChange={() => setWillBeRehired(false)}
                          required
                        />
                        <Label className="form-check-label mr-2" htmlFor="will-be-rehired-no">
                          No
                        </Label>
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row className="d-flex aligm-items-center mt-4">
                  <Col lg="4">
                    <label className="form-control-label">
                      What is the reason for terminating {terminationUser?.user?.firstName}?
                    </label>
                  </Col>
                  <Col lg="8">
                    <Input
                      className="form-input"
                      type="textarea"
                      value={terminationReason}
                      onChange={(e) => setTerminationReason(e.target.value)}
                      required
                    />
                  </Col>
                </Row>

                <Row className="d-flex aligm-items-center mt-4">
                  <Col lg="4">
                    <label className="form-control-label">Notes (optional):</label>
                  </Col>
                  <Col lg="8">
                    <Input
                      className="form-input"
                      type="textarea"
                      value={terminationNotes}
                      onChange={(e) => setTerminationNotes(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className="d-flex aligm-items-center mt-4">
                  <Col lg="4">
                    <label className="form-control-label">
                      What is {terminationUser?.user?.firstName}'s non-work email address?
                    </label>
                  </Col>
                  <Col lg="8">
                    <Input
                      className="form-input"
                      type="text"
                      value={terminationUserEmail}
                      onChange={(e) => setTerminationUserEmail(e.target.value)}
                      required
                    />
                  </Col>
                </Row>

                <hr />

                <div className="d-flex justify-content-end mt-4">
                  <div>
                    <Button className="btn-secondary" onClick={toogleTerminatedEmployeeForm}>
                      Cancel
                    </Button>
                    <Button color="warning" type="submit">
                      Next
                    </Button>
                  </div>
                </div>
              </>
            )}

            {terminatedEmployeeStep === 1 && (
              <>
                <Card style={{ padding: '40px 60px', background: '#eaeaff' }}>
                  <Row className="mb-2">
                    <h3>Basic Info</h3>
                  </Row>
                  <Row
                    className="d-flex mb-2 align-items-center justify-content-between"
                    style={{ borderBottom: '2px solid #dddddd' }}
                  >
                    <h4 className="text-muted">Termination Date</h4>
                    <h4>{toLocaleFormatSlash(terminationDate)}</h4>
                  </Row>
                  <Row
                    className="d-flex mb-2 align-items-center justify-content-between"
                    style={{ borderBottom: '2px solid #dddddd' }}
                  >
                    <h4 className="text-muted">Termination Type</h4>
                    <h4>{terminationCategory}</h4>
                  </Row>
                  <Row className="d-flex mb-2 justify-content-between" style={{ borderBottom: '2px solid #dddddd' }}>
                    <Col className="p-0 m-0" lg="4">
                      <h4 className="text-muted">Termination Reason</h4>
                    </Col>
                    <Col className="p-0 m-0 a" lg="8">
                      <h4 className="text-right" style={{ whiteSpace: 'pre-line' }}>
                        {terminationReason}
                      </h4>
                    </Col>
                  </Row>
                  <Row className="d-flex mb-2 justify-content-between" style={{ borderBottom: '2px solid #dddddd' }}>
                    <Col className="p-0 m-0" lg="4">
                      <h4 className="text-muted">Notes</h4>
                    </Col>
                    <Col className="p-0 m-0 a" lg="8">
                      <h4 className="text-right" style={{ whiteSpace: 'pre-line' }}>
                        {terminationNotes}
                      </h4>
                    </Col>
                  </Row>
                  <Row className="mb-2 mt-4">
                    <h3>PTO Balance</h3>
                  </Row>
                  <Row
                    className="d-flex mb-2 align-items-center justify-content-between"
                    style={{ borderBottom: '2px solid #dddddd' }}
                  >
                    <h4 className="text-muted">Available PTO Balance</h4>
                    <h4>Information not available</h4>
                  </Row>
                </Card>

                <Row className="d-flex mb-2 align-items-center justify-content-between mt-4">
                  <Col lg="5">
                    <Label>Review the settings above and type "{terminationUser?.user?.firstName}" to confirm</Label>
                  </Col>
                  <Col lg="7">
                    <Input
                      type="text"
                      value={terminationNameToConfirm}
                      onChange={(event) => setTerminationNameToConfirm(event.target.value)}
                    />
                  </Col>
                </Row>
                <div className="d-flex justify-content-end mt-4">
                  <div>
                    <Button className="btn-secondary" onClick={() => setTerminatedEmployeeStep(0)}>
                      Back
                    </Button>
                    <Button
                      color="warning"
                      type="submit"
                      disabled={!(terminationNameToConfirm === terminationUser?.user.firstName)}
                    >
                      Confirm
                    </Button>
                  </div>
                </div>
              </>
            )}
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default TerminatedEmployeeForm;
