/* eslint-disable max-len */
/* eslint-disable max-lines */
import { Container, Button, Row, Col, Card, CardBody, FormGroup, InputGroup, Input, Modal } from 'reactstrap';
import ReactDatetime from 'react-datetime';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import { useContext, useEffect, useState } from 'react';
import ReportsService from '../../../services/Reports';
import * as XLSX from 'xlsx';
import moment from 'moment';
import FileSaver from 'file-saver';
import { saveAs } from 'file-saver';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { font as robotoRegular } from '../../../assets/fonts/Roboto-Regular';
import { font as robotoMedium } from '../../../assets/fonts/Roboto-Medium';
import { font as robotoItalic } from '../../../assets/fonts/Roboto-Italic';
import { font as robotoMediumItalic } from '../../../assets/fonts/Roboto-MediumItalic';
import { font as dancingScript } from '../../../assets/fonts/DancingScript';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import WriteUpService from '../../../services/WriteUp';
import Messaging from '../../../services/Messaging';
import SchoolService from '../../../services/School';
import { Checkbox, ListItemText, MenuItem, Select, SelectChangeEvent } from '@mui/material';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.vfs['Roboto-Regular.ttf'] = robotoRegular;
pdfMake.vfs['Roboto-Medium.ttf'] = robotoMedium;
pdfMake.vfs['Roboto-Italic.ttf'] = robotoItalic;
pdfMake.vfs['Roboto-MediumItalic.ttf'] = robotoMediumItalic;
pdfMake.vfs['DancingScript.ttf'] = dancingScript;

interface timecardModalProps {
  isOpen: boolean;
  toggleModal: () => void;
}

export function WriteUpModal({ isOpen, toggleModal }: timecardModalProps) {
  const [fileFormat, setFileFormat] = useState('XLSX');
  const [employees, setEmployees] = useState<any>([]);
  const [selectedEmployees, setSelectedEmployees] = useState<string[]>([]);
  const [employeeIds, setEmployeeIds] = useState<string[]>([]);
  const [school, setSchool] = useState<any>();
  const { token, currentSchool }: any = useContext(AuthContext);
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);
  const [initialDate, setInitialDate] = useState(moment().isoWeekday(0).format('MM/DD/yyyy'));
  const [endDate, setEndDate] = useState(moment().isoWeekday(0).add(6, 'days').format('MM/DD/yyyy'));

  const generateEmployee = async () => {
    const idSchool = currentSchool.value;
    const { data } = await Messaging(token).listSelectStaff(idSchool);

    setEmployees(data);
  };

  const listSelectSchool = async () => {
    const result = await SchoolService(token).show(currentSchool.value);
    setSchool(result.data || []);
  };

  useEffect(() => {
    generateEmployee();
    listSelectSchool();
  }, []);

  useEffect(() => {
    const ids = selectedEmployees.map((name: string) => {
      const employeesFound = employees.find((employee: any) => name === `${employee.firstName} ${employee.lastName}`);
      return employeesFound.userId;
    });

    setEmployeeIds(ids);
  }, [selectedEmployees]);

  const handleChangeMultiSelect: any = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    if (typeof value === 'string') {
      const newValue = value.split(',').filter((value) => value !== 'All');
      setSelectedEmployees(newValue);
    } else {
      const newValue = value.filter((value) => value !== 'All');
      setSelectedEmployees(newValue);
    }
  };

  const handleExportXlms = async () => {
    if (fileFormat === 'XLSX') {
      const idSchool = currentSchool.value;
      const query = `startDateTime=${initialDate}&finalDateTime=${endDate}&employees=${employeeIds.join(',')}`;
      const { data } = await ReportsService(token).writeUp(idSchool, query);
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';

      const ws = XLSX.utils.json_to_sheet(data);
      const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const response = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(response, 'Write Up' + fileExtension);
      toggleModal();
    }
  };

  const generatePDF = async () => {
    const query = `startDateTime=${initialDate}&finalDateTime=${endDate}&employees=${employeeIds.join(',')}`;
    const idSchool = currentSchool.value;
    const result = await WriteUpService(token).list(idSchool, query);
    const data: any[] = result.data;

    const length = data?.length;

    try {
      setIsGeneratingPDF(true);
      const imageUrl = school?.franchisee?.imageUrl;
      const response = await fetch(imageUrl);
      const imageBlob = await response.blob();
      const reader = new FileReader();

      reader.onloadend = () => {
        const imageDataUrl = reader.result as string;

        const result = data?.map((data: any, index: number) => {
          return [
            {
              columns: [
                {
                  stack: [{ text: 'PERFORMANCE IMPROVEMENT DOCUMENT', style: 'header' }],
                  alignment: 'left',
                  margin: [0, 15, 0, 15],
                },
                {
                  image: imageDataUrl,
                  width: 100,
                  height: 40,
                  alignment: 'right',
                  margin: [0, 0, 0, 15],
                },
              ],
            },
            {
              table: {
                widths: ['*', '*'],
                margin: [0, 10, 0, 10],
                body: [
                  [
                    {
                      margin: [5, 5, 5, 5],
                      text: [{ text: 'Name ', bold: true }, { text: `${data.user.firstName} ${data.user.lastName}` }],
                    },
                    {
                      margin: [5, 5, 5, 5],
                      text: [{ text: 'Date of Incident ' }, { text: moment(data.incidentDate).format('M/D/YYYY') }],
                    },
                  ],
                  [
                    {
                      margin: [5, 5, 5, 5],
                      text: [{ text: 'Position ', bold: true }, { text: data.user.employee.franchisee.name }],
                    },
                    {
                      margin: [5, 5, 5, 5],
                      text: [{ text: 'Department ', bold: true }, { text: data.user.employee.department.name }],
                    },
                  ],
                  [
                    { margin: [5, 5, 5, 5], text: [{ text: 'Supervisor ', bold: true }, { text: '' }] },
                    {
                      margin: [5, 5, 5, 5],
                      text: [
                        { text: 'Date of Hire ', bold: true },
                        { text: moment(data.user.employee.startDate).format('M/D/YYYY') },
                      ],
                    },
                  ],
                ],
              },
            },
            {
              margin: [0, 15, 0, 5],
              columns: [
                {
                  width: 'auto',
                  text: 'Prior Verbal Discussion of Written Warning',
                  noWrap: true,
                  bold: true,
                },
              ],
            },
            {
              margin: [0, 0, 0, 5],
              columns: [
                {
                  width: 'auto',
                  text: 'List dates & type of informal discussion or warning',
                  noWrap: true,
                  fontSize: 11,
                },
              ],
            },
            {
              margin: [0, 0, 0, 10],
              columns: [
                {
                  width: 'auto',
                  text: data.writtenWarning,
                  fontSize: 11,
                },
              ],
            },
            {
              canvas: [
                {
                  type: 'line',
                  x1: 0,
                  y1: 0,
                  x2: 500,
                  y2: 0,
                  lineWidth: 1,
                  lineColor: 'black',
                },
              ],
            },
            {
              margin: [0, 10, 0, 5],
              columns: [
                {
                  width: 'auto',
                  text: 'Policy Violation',
                  noWrap: true,
                  bold: true,
                },
              ],
            },
            {
              margin: [0, 0, 0, 10],
              columns: [
                {
                  width: 'auto',
                  ul: data.policyViolation.split(',').map((value: string) => {
                    return { text: value, color: '#de0000', fontSize: 11, margin: [0, 2, 0, 2] };
                  }),
                  fontSize: 11,
                },
              ],
            },
            {
              canvas: [
                {
                  type: 'line',
                  x1: 0,
                  y1: 0,
                  x2: 500,
                  y2: 0,
                  lineWidth: 1,
                  lineColor: 'black',
                },
              ],
            },
            {
              margin: [0, 10, 0, 5],
              columns: [
                {
                  width: 'auto',
                  text: 'Description of Incident',
                  noWrap: true,
                  bold: true,
                },
              ],
            },
            {
              margin: [0, 0, 0, 10],
              columns: [
                {
                  width: 'auto',
                  text: data.incidentDescription,
                  fontSize: 11,
                },
              ],
            },
            {
              canvas: [
                {
                  type: 'line',
                  x1: 0,
                  y1: 0,
                  x2: 500,
                  y2: 0,
                  lineWidth: 1,
                  lineColor: 'black',
                },
              ],
            },
            {
              margin: [0, 10, 0, 5],
              columns: [
                {
                  width: 'auto',
                  text: 'Action Taken',
                  noWrap: true,
                  bold: true,
                },
              ],
            },
            {
              margin: [0, 0, 0, 10],
              columns: [
                {
                  width: 'auto',
                  text: data.actionTaken,
                  fontSize: 11,
                },
              ],
            },
            {
              canvas: [
                {
                  type: 'line',
                  x1: 0,
                  y1: 0,
                  x2: 500,
                  y2: 0,
                  lineWidth: 1,
                  lineColor: 'black',
                },
              ],
            },
            data.isComunicated && {
              margin: [0, 10, 0, 10],
              columns: [
                {
                  width: 'auto',
                  text: 'Communicated',
                  fontSize: 12,
                  bold: true,
                  margin: [0, 0, 0, 0],
                },
                {
                  stack: [
                    {
                      canvas: [
                        {
                          type: 'rect',
                          x: 0,
                          y: 0,
                          w: 20,
                          h: 10,
                          lineWidth: 1,
                          lineColor: 'black',
                        },
                        {
                          type: 'line',
                          x1: 8,
                          y1: 3,
                          x2: 12,
                          y2: 8,
                          lineWidth: 1,
                          lineColor: data.isComunicated === true ? 'black' : 'white',
                        },
                        {
                          type: 'line',
                          x1: 12,
                          y1: 3,
                          x2: 8,
                          y2: 8,
                          lineWidth: 1,
                          lineColor: data.isComunicated === true ? 'black' : 'white',
                        },
                      ],
                    },
                  ],
                  width: 'auto',
                  margin: [5, 2, 0, 0],
                  alignment: 'center',
                },
                {
                  width: 'auto',
                  text: 'Yes',
                  alignment: 'center',
                  fontSize: 10,
                  margin: [3, 1, 0, 0],
                  color: '#6d6e70',
                },
              ],
            },
            {
              canvas: [
                {
                  type: 'line',
                  x1: 0,
                  y1: 0,
                  x2: 500,
                  y2: 0,
                  lineWidth: 1,
                  lineColor: 'black',
                },
              ],
            },
            data.improvementPlan && {
              margin: [0, 10, 0, 5],
              columns: [
                {
                  width: 'auto',
                  text: 'Improvement Plan',
                  noWrap: true,
                  bold: true,
                },
              ],
            },
            data.improvementPlan && {
              margin: [0, 0, 0, 10],
              columns: [
                {
                  width: 'auto',
                  text: data.improvementPlan,
                  fontSize: 11,
                  pageBreak: index < length - 1 ? 'after' : null,
                },
              ],
            },
            data.trainig && {
              margin: [0, 10, 0, 5],
              columns: [
                {
                  width: 'auto',
                  text: 'Trainig to be Completed',
                  noWrap: true,
                  bold: true,
                },
              ],
            },
            data.trainig && {
              margin: [0, 0, 0, 10],
              columns: [
                {
                  width: 'auto',
                  text: data.trainig,
                  fontSize: 11,
                },
              ],
            },
            data.trainig && {
              canvas: [
                {
                  type: 'line',
                  x1: 0,
                  y1: 0,
                  x2: 500,
                  y2: 0,
                  lineWidth: 1,
                  lineColor: 'black',
                },
              ],
            },
            data.meetingDate && {
              margin: [0, 25, 0, 25],
              columns: [
                {
                  width: 'auto',
                  text: `We'll have a follow up meeting on ${moment(data.meetingDate).format('M/D/YYYY')}`,
                  noWrap: true,
                  bold: true,
                  color: '#001d75',
                  fontSize: 14,
                },
              ],
            },

            data.employeeSignature && {
              widths: ['*', '*'],
              margin: [0, 10, 0, 20],
              columns: [
                {
                  text: `I understand that employment with Amazing Explorers Academy is at-will. I also undestand that failure to maintain or sustain acceptable levels of performancem behavior or conduct may result in further action, up to and incluing separation of employment. Signing this plan does not indicate agreement, but only receipt. I also understand I may attach written comments about this manner..`,
                  fontSize: 10,
                  color: '#6d6e70',
                },
              ],
            },
            data.employeeSignature && {
              margin: [0, 5, 0, 0],
              columns: [
                { text: 'Employee Signature', alignment: 'left' },
                { text: 'Director Signature', alignment: 'left' },
              ],
            },
            data.employeeSignature && {
              margin: [0, 0, 0, 0],
              columns: [
                { text: data.employeeSignature, font: 'DancingScript', alignment: 'left' },
                { text: `| ${data.employeeSignature}`, fontSize: 10, alignment: 'left' },
                { text: data.directorSignature, font: 'DancingScript', alignment: 'left' },
                { text: ` | ${data.directorSignature}`, fontSize: 10, alignment: 'left' },
              ],
            },
            data.employeeSignature && {
              margin: [0, 0, 0, 0],
              columns: [
                {
                  text: `Date: ${moment(data.employeeSignatureDate).format('MM/DD/YY')}/${moment(
                    data.employeeSignatureDate,
                  ).format('h:mm A')}`,
                  alignment: 'left',
                  fontSize: 10,
                },
                {
                  text: `Date: ${moment(data.directorSignatureDate).format('MM/DD/YY')}/${moment(
                    data.directorSignatureDate,
                  ).format('h:mm A')}`,
                  alignment: 'left',
                  fontSize: 10,
                  pageBreak: index < length - 1 ? 'after' : null,
                },
              ],
            },
          ];
        });

        const pdfBody = result?.reduce((acc: any, val: any) => acc.concat(val), []);
        pdfMake.fonts = {
          Roboto: {
            normal: 'Roboto-Regular.ttf',
            bold: 'Roboto-Medium.ttf',
            italics: 'Roboto-Italic.ttf',
            bolditalics: 'Roboto-MediumItalic.ttf',
          },
          DancingScript: {
            normal: 'DancingScript.ttf',
            bold: 'DancingScript.ttf',
            italics: 'DancingScript.ttf',
            bolditalics: 'DancingScript.ttf',
          },
        };
        const documentDefinition: any = {
          content: pdfBody,
          styles: {
            header: {
              fontSize: 16,
              color: '#395dab',
            },
            underlineHeader: {
              fontSize: 16,
              decoration: 'underline',
              color: '#395dab',
            },
            title: {
              fontSize: 14,
              color: '#6d6e70',
            },
          },
        };

        const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
        pdfDocGenerator.getBlob((blob) => {
          saveAs(blob, 'Write Up.pdf');
          setIsGeneratingPDF(false);
        });
      };
      reader.readAsDataURL(imageBlob);
    } catch (error) {
      console.error('Erro ao obter a imagem:', error);
    }
  };

  return (
    <Modal className="modal-dialog-centered" size="lg" isOpen={isOpen} toggle={() => toggleModal()}>
      <Container fluid style={{ minWidth: '500px', paddingTop: 15, paddingBottom: 15 }}>
        <Card>
          <CardBody>
            <div className="pl-lg-0">
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-validAt">
                      Initial Date
                    </label>
                    <InputGroup className="input-group-alternative">
                      <ReactDatetime
                        inputProps={{
                          placeholder: 'Start Date',
                        }}
                        onChange={(newValue) => {
                          setInitialDate(moment(newValue).format('MM/DD/yyyy'));
                        }}
                        value={initialDate}
                        timeFormat={false}
                        dateFormat={'MM/DD/yyyy'}
                        closeOnSelect={true}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>

                <Col lg="6">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-validAt">
                      End Date
                    </label>
                    <InputGroup className="input-group-alternative">
                      <ReactDatetime
                        inputProps={{
                          placeholder: 'End Date',
                        }}
                        onChange={(newValue) => {
                          setEndDate(moment(newValue).format('MM/DD/yyyy'));
                        }}
                        value={endDate}
                        timeFormat={false}
                        dateFormat={'MM/DD/yyyy'}
                        closeOnSelect={true}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col lg="12" className="mb-2">
                  <label className="form-control-label" htmlFor="input-gender">
                    Employees
                  </label>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    placeholder="All"
                    multiple
                    value={selectedEmployees.length > 0 ? selectedEmployees : ['All']}
                    onChange={handleChangeMultiSelect}
                    renderValue={(selected: string[]) => selected.join(', ')}
                    sx={{
                      height: 43,
                      width: '100%',
                      fontSize: 14,
                      color: '#8898aa',
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 280,
                          width: 250,
                        },
                      },
                    }}
                  >
                    {employees?.map((employee: any, index: any) => (
                      <MenuItem value={`${employee.firstName} ${employee.lastName}`} key={`employee-${index}`}>
                        <Checkbox
                          checked={selectedEmployees.indexOf(`${employee.firstName} ${employee.lastName}`) > -1}
                        />
                        <ListItemText primary={`${employee.firstName} ${employee.lastName}`} />
                      </MenuItem>
                    ))}
                  </Select>
                </Col>
              </Row>

              <Row>
                <Col lg="12">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-gender">
                      File type
                    </label>
                    <Input type="select" onChange={(e) => setFileFormat(e.target.value)} value={fileFormat}>
                      <option value="XLSX" key={`XLSX`} selected>
                        XLSX
                      </option>
                      <option value="PDF" key={`PDF`}>
                        PDF
                      </option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="12" className="d-flex justify-content-center">
                  <Button
                    color="primary"
                    type="submit"
                    href="#"
                    onClick={async () => {
                      if (fileFormat === 'XLSX') handleExportXlms();
                      if (fileFormat === 'PDF') await generatePDF();
                    }}
                    size="lg"
                    style={{ minWidth: '200px' }}
                  >
                    {isGeneratingPDF ? <FontAwesomeIcon icon={faSpinner} spin={isGeneratingPDF} /> : 'Download'}
                  </Button>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
      </Container>
    </Modal>
  );
}
