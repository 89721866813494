import { Card, CardHeader, Button, CardText, Row, Col, CardBody, Modal } from 'reactstrap';
import { useContext, useState, useEffect } from 'react';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { toLocaleFormatSlash } from '../../../helpers/dateFormat';
import ImmunizationKidsService from '../../../services/ImmunizationKids';
import ImmunizationKidsInterface from '../../../interfaces/ImmunizationKidsInterface';
import ImmunizationKidsForm from '../Immunization/ImmunizationKidsForm';
import confirmAlert from '../../../helpers/confirmAlert';
import imageModal from '../../../helpers/imageModal';

type KidsIdProps = {
  kidsId: string;
};

const ImmunizationKidsList = ({ kidsId }: KidsIdProps) => {
  const { token }: any = useContext(AuthContext);
  const [data, setData] = useState([] as any[]);
  const { id } = useParams<{ id: string }>();

  const [immunizationForm, setImmunizationForm] = useState(false);

  const toggleImmunizationForm = () => {
    setImmunizationForm(!immunizationForm);
  };

  const toogleModalImg = (picture?: string) => {
    return imageModal({
      title: '',
      picture: picture || '',
    });
  };

  const modalChange = () => {
    setImmunizationForm(!immunizationForm);
    if (immunizationForm == true) {
      getImmunizationsByKidsId();
    }
  };

  const deleteItemAlert = async (id: string, label: string) => {
    return confirmAlert({
      title: 'Delete',
      messageType: 'DELETE',
      id,
      onClickYes: deleteItem,
      label,
    });
  };

  const deleteItem = async (id: string) => {
    try {
      await ImmunizationKidsService(token || '').delete(id);
      toast.success(`Deleted successfully!`);
      getImmunizationsByKidsId();
    } catch (e: any) {
      const message = String(e?.response?.data?.message || `Error deleting item: ${id}`);
      return toast.error(message);
    }
  };

  const getImmunizationsByKidsId = async () => {
    try {
      const result = await ImmunizationKidsService(token || '').list(kidsId);
      const data: ImmunizationKidsInterface[] = result.data;

      setData(data);
    } catch (e: any) {
      toast.error('Error: ' + id);
    }
  };

  // useEffect(() => {
  //   getImmunizationsByKidsId();
  // }, []);

  useEffect(() => {
    getImmunizationsByKidsId();
  }, [kidsId]);

  return (
    <>
      <hr className="my-4" />
      <Row>
        <Col>
          <h6 className="heading-small text-muted mb-4">Immunizations</h6>
        </Col>
        <Col>
          <Button
            size="sm"
            className="float-right"
            onClick={() => {
              toggleImmunizationForm();
            }}
          >
            + Immunization
          </Button>
        </Col>
      </Row>

      <Card>
        <CardHeader>
          <Row>
            <Col>Type</Col>
            <Col>Exp. Date</Col>
            <Col></Col>
          </Row>
        </CardHeader>
        {data.map((r: any, index: number) => {
          return (
            <CardBody style={{ borderTop: '1px solid rgba(0, 0, 0, 0.05)' }} key={`picture-key-${index}`}>
              <CardText>
                <Row>
                  <Col>
                    {r?.picture && r?.pictureMimetype && (
                      <a
                        onClick={(e) => {
                          toogleModalImg(r?.picture);
                          e.preventDefault();
                        }}
                        href="#"
                      >
                        {r?.immunization?.name}
                      </a>
                    )}

                    {!r?.picture && r?.immunization?.name}
                  </Col>
                  <Col>{toLocaleFormatSlash(r.validAt)}</Col>
                  <Col>
                    <i
                      className="far fa-trash-alt text-danger float-right"
                      style={{ cursor: 'pointer' }}
                      onClick={(e) => {
                        e.preventDefault();
                        deleteItemAlert(r.id, r?.immunization?.name);
                      }}
                    ></i>
                  </Col>
                </Row>
              </CardText>
            </CardBody>
          );
        })}
      </Card>
      <Modal className="modal-dialog-centered" isOpen={immunizationForm} toggle={() => toggleImmunizationForm()}>
        <div className="modal-header">
          <h5 className="modal-title" id="Label">
            New Immunization
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => toggleImmunizationForm()}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <ImmunizationKidsForm kidsId={kidsId} onModalStatus={modalChange} />
        </div>
      </Modal>
    </>
  );
};

export default ImmunizationKidsList;
