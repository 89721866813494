import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  InputGroupAddon,
  InputGroup,
} from 'reactstrap';
import { toast } from 'react-toastify';
import { useContext, useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import SchoolService from '../../../services/School';
import SchoolScheduleEvents from '../../../services/SchoolScheduleEvents';
import CommonHeader from '../../../components/Headers/CommonHeader';

const SchoolScheduleEventImportForm = () => {
  const pageTitle = 'Import Events';
  const { token, currentSchool }: any = useContext(AuthContext);

  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  const [schoolSelectList, setSchoolSelectList] = useState([{}]);

  const [schoolId, setSchoolId] = useState('');
  const [csvType, setCsvType] = useState('');

  const [scheduleEventsFile, setScheduleEventsFile] = useState<any>();
  const imageRef = useRef(null as any);

  const onChangeFile = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];
    setScheduleEventsFile(file);
  };

  const showOpenFileDialog = () => {
    if (isDisabled) {
      return;
    }
    if (imageRef?.current?.click) {
      imageRef.current.click();
    }
  };

  const listSelectSchool = async () => {
    try {
      const result = await SchoolService(token).listSelect();
      setSchoolSelectList(result.data || []);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No Schools found');
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);
    setIsDisabled(true);
    try {
      const formData = new FormData();
      formData.append('file', scheduleEventsFile as any);
      await SchoolScheduleEvents(token).importFromFile(schoolId, csvType, formData);
      toast.success(`successfully!`);
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Import error!');
      toast.error(message);
    }

    setLoading(false);
    setIsDisabled(false);
  };

  useEffect(() => {
    listSelectSchool();
    setSchoolId(currentSchool.value || '');
    setCsvType('calendar');
  }, []);

  useEffect(() => {
    setSchoolId(currentSchool.value || '');
  }, [currentSchool]);

  return (
    <>
      <CommonHeader />
      <Container className="mt--8" fluid>
        <Row>
          <Col className="order-xl-2" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">{`${pageTitle}`}</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Link to="/monthly-info">
                      <Button color="primary" className="float-right">
                        Back
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmit}>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-school-id">
                            School
                          </label>
                          <Input type="select" onChange={(e) => setSchoolId(e.target.value)} value={schoolId}>
                            <option value=""></option>
                            {schoolSelectList?.map((filter: any, index: number) => {
                              return (
                                <option value={filter.id} key={`filter-button-${index}`}>
                                  {filter.name}
                                </option>
                              );
                            })}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label">Csv Type</label>
                          <Input type="select" onChange={(e) => setCsvType(e.target.value)} value={csvType} required>
                            <option value="calendar">Calendar</option>
                            <option value="lunch">Lunch</option>
                            <option value="snack">Snack</option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="token-kiosk">
                            Scheduled Events File
                          </label>

                          <input
                            id="img-picture"
                            name="picture"
                            type="file"
                            accept=".csv"
                            style={{ display: 'none' }}
                            ref={imageRef}
                            disabled={isDisabled}
                            onChange={onChangeFile}
                          />
                          <InputGroup>
                            <Input
                              className="form-control-alternative"
                              placeholder="No file selected"
                              type="text"
                              value={scheduleEventsFile?.name}
                              disabled={true}
                            />
                            <InputGroupAddon addonType="append">
                              <Button color="success" onClick={showOpenFileDialog}>
                                Upload
                              </Button>
                            </InputGroupAddon>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="col-4 offset-8">
                        <p>Templates</p>
                        <ul style={{ listStyle: 'none', padding: '0' }}>
                          <li>
                            <Link to="/files-examples/calendar.csv" target="_blank">
                              <i className="fa fa-file-csv"></i>&nbsp;Calendar
                            </Link>
                          </li>
                          <li>
                            <Link to="/files-examples/lunch.csv" target="_blank">
                              <i className="fa fa-file-csv"></i>&nbsp;Lunch
                            </Link>
                          </li>
                          <li>
                            <Link to="/files-examples/snack.csv" target="_blank">
                              <i className="fa fa-file-csv"></i>&nbsp;Snack
                            </Link>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  </div>
                  <div className="pl-lg-4">
                    <Button className="my-4" color="primary" type="submit" disabled={isDisabled}>
                      Save
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default SchoolScheduleEventImportForm;
