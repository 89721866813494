import { Container, Badge, Button, Row, Col, Card, CardBody, CardTitle } from 'reactstrap';
import { useContext, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import CommonHeader from '../../../components/Headers/CommonHeader';
import SchoolService from '../../../services/School';
import SchoolInterface, { validStatus, statusEnum } from '../../../interfaces/SchoolInterface';
import dateFormat from '../../../helpers/dateFormat';
import { FilterInterface, FilteredOptionsInterface } from '../../../entities/FilteredOptions';
import { PagedMetaInterface, createPagedMeta } from '../../../entities/PagedMeta';
import { OptionsPagedInterface, createOptionsPage } from '../../../entities/OptionsPaged';
import DataTable from '../../../components/Core/DataTable';
import isAdmin from '../../../helpers/isAdmin';
import isUpperManagment from '../../../helpers/isUpperManagment';
import isFranchiseeManagment from '../../../helpers/isFranchiseeManagment';

const SchoolList = () => {
  const { token, currentFranchisee, user } = useContext(AuthContext);
  const hasEditPermission = isAdmin(user) || isUpperManagment(user) || isFranchiseeManagment(user);

  const columns = [
    {
      name: 'Name',
      route: '/school',
      label: (row: any) => row.name,
      disabled: !hasEditPermission,
      sortable: true,
      sortField: 'name',
    },
    {
      name: 'Status',
      selector: (row: any) => row.status,
      cell: (row: any) => (
        <Badge
          color=""
          className="badge-dot badge-lg"
          style={{ fontSize: '0.8125rem', verticalAlign: 'middle', lineHeight: 'normal' }}
        >
          <i
            className={`bg-${validStatus[row.status as statusEnum].color} mr-2`}
            style={{ verticalAlign: 'baseline' }}
          />
          {validStatus[row.status as statusEnum].value}
        </Badge>
      ),
      grow: 1,
    },
    {
      name: 'Created at',
      selector: (row: any) => row.createdAt,
      cell: (row: any) => dateFormat(row.createdAt),
      grow: 2,
    },
    {
      name: 'Updated at',
      selector: (row: any) => row.updatedAt,
      cell: (row: any) => dateFormat(row.updatedAt),
      grow: 2,
    },
    hasEditPermission && {
      type: 'delete',
      onClick: (row: any) => {
        deleteItemAlert(row.id);
      },
    },
  ];

  const [data, setData] = useState([] as any[]);
  const [loading, setLoading] = useState(false);
  const [pagedMeta, setPagedMeta] = useState(createPagedMeta());
  const [filteredOptions, setFilteredOptions] = useState({
    filters: [],
    options: createOptionsPage(pagedMeta),
  } as { filters: FilterInterface[]; options: OptionsPagedInterface });

  useEffect(() => {
    loadList(filteredOptions);
  }, [filteredOptions]);
  useEffect(() => {
    loadList(filteredOptions);
  }, [currentFranchisee]);

  const loadList = async (filteredOptions: FilteredOptionsInterface) => {
    try {
      setLoading(true);

      const filter = {
        field: 'franchiseeId',
        operation: 'hash',
        value: currentFranchisee.value,
      };
      filteredOptions.filters.push(filter);

      const result = await SchoolService(token || '').list(filteredOptions);

      const data: SchoolInterface[] = result.data;
      setData(data);
      const meta: PagedMetaInterface = result.meta;
      if (meta) {
        setPagedMeta(meta);
      }
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Error fetching list');
      return toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = async (text: string) => {
    const filterFirstName = {
      field: 'name',
      operation: 'ilike',
      value: text || '',
    };
    const newFilteredOptions = {
      filters: [filterFirstName],
      options: filteredOptions.options,
    };
    newFilteredOptions.options.page = 1;
    setFilteredOptions(newFilteredOptions);
  };

  const handleSort = async (column: string, direction: string) => {
    const options = { ...filteredOptions.options, sortBy: [column], sortDesc: [direction !== 'asc'] };
    const newFilteredOptions = {
      filters: filteredOptions.filters,
      options,
    };
    newFilteredOptions.options.page = 1;
    setFilteredOptions(newFilteredOptions);
  };

  const deleteItemAlert = async (id: string) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Container fluid>
            <Card>
              <CardBody>
                <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                  Delete
                </CardTitle>
                <Row className="mt-3">
                  <Col md="12 text-center">
                    <span className="h2 font-weight-bold mb-0">{`Are you sure to delete item: ${id}?`}</span>
                  </Col>
                </Row>
                <Row className="mt-5">
                  <Col md="6 text-center">
                    <Button
                      color="primary"
                      href="#"
                      onClick={() => {
                        deleteItem(id);
                        onClose();
                      }}
                      size="lg"
                      style={{ minWidth: '100px' }}
                    >
                      Yes
                    </Button>
                  </Col>
                  <Col md="6 text-center">
                    <Button color="danger" href="#" onClick={onClose} size="lg" style={{ minWidth: '100px' }}>
                      No
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Container>
        );
      },
    });
  };

  const deleteItem = async (id: string) => {
    try {
      setLoading(true);
      await SchoolService(token || '').delete(id);
      toast.success(`Deleted successfully!`);
      loadList(filteredOptions);
    } catch (e: any) {
      const message = String(e?.response?.data?.message || `Error deleting item: ${id}`);
      return toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  const handlePerRowsChange = async (perPage: number) => {
    const newOptions = {
      ...pagedMeta,
      perPage,
    };
    const newFilteredOptions = {
      filters: filteredOptions.filters,
      options: createOptionsPage(newOptions),
    };
    setFilteredOptions(newFilteredOptions);
  };

  const handlePageChange = async (page: number) => {
    const newOptions = {
      ...pagedMeta,
      currentPage: page,
    };
    const newFilteredOptions = {
      filters: filteredOptions.filters,
      options: createOptionsPage(newOptions),
    };
    setFilteredOptions(newFilteredOptions);
  };

  return (
    <>
      <CommonHeader />
      <Container className="mt--8" fluid>
        <DataTable
          title="School List"
          columns={columns}
          data={data}
          totalRows={pagedMeta.total}
          loading={loading}
          button={hasEditPermission}
          buttonUrl="/school/new"
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          search={true}
          onSearch={handleSearch}
          onChangeSort={(column: string, direction: string) => handleSort(column, direction)}
        />
      </Container>
    </>
  );
};

export default SchoolList;
