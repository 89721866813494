import { Modal, Table, Row, Col, Button, Form, FormGroup, Input } from 'reactstrap';
import { useContext, useState, useEffect } from 'react';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import DiscountsService from '../../../services/Discounts';
import { toast } from 'react-toastify';
import NumberFormat from 'react-number-format';

type DiscountsModalProps = {
  isOpen: boolean;
  toggleModal: (e?: any) => void;
};

const DiscountsModal = ({ isOpen, toggleModal }: DiscountsModalProps) => {
  const { token }: any = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [discountsName, setDiscountsName] = useState('');
  const [discountsPercent, setDiscountsPercent] = useState(0);
  const [discountsList, setDiscountsList] = useState([] as any[]);

  useEffect(() => {
    if (isOpen) {
      listData();
    }
  }, [isOpen]);

  const listData = async () => {
    try {
      const result = await DiscountsService(token).list();
      setDiscountsList(result.data || []);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No discounts Found!');
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);

    try {
      await DiscountsService(token).create({
        name: discountsName,
        percentage: discountsPercent,
      });
      toast.success(`Saved successfully!`);
      listData();
      setShowForm(false);
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Create error!');
      toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (e: React.FormEvent, id: string) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);

    try {
      await DiscountsService(token).delete(id);
      toast.success(`Deleted successfully!`);
      listData();
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Delete error!');
      toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal className="modal-dialog-centered" isOpen={isOpen} toggle={(e: any) => toggleModal(e)}>
        <div className="modal-header pb-1">
          <h5 className="modal-title">Edit Discounts</h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={(e) => toggleModal(e)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body pt-0">
          <Table className="align-items-center table-flush" responsive>
            <tbody>
              <tr>
                <th>Title</th>
                <th>Percentage</th>
                <th>Action</th>
              </tr>
              {discountsList?.length === 0 && (
                <tr>
                  <td colSpan={3} className="text-center">
                    Data not found
                  </td>
                </tr>
              )}
              {discountsList.map((s: any) => (
                <tr key={`discountsList-modal-${s.id}`}>
                  <td>{s.name}</td>
                  <td>{Number(s.percentage).toFixed(2)}%</td>
                  <td>
                    <i
                      className="far fa-trash-alt text-danger"
                      style={{ cursor: 'pointer' }}
                      onClick={(e) => handleDelete(e, s.id)}
                    ></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Row>
            {!showForm && (
              <Col>
                <Button
                  onClick={() => {
                    setShowForm(true);
                  }}
                >
                  New
                </Button>
              </Col>
            )}
            {showForm && (
              <Col>
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col lg="12">
                      <FormGroup>
                        <label className="form-control-label">Name</label>
                        <Input onChange={(e) => setDiscountsName(e.target.value)} />
                      </FormGroup>
                    </Col>
                    <Col lg="12">
                      <FormGroup>
                        <label className="form-control-label">Percentage</label>
                        <Input
                          type="text"
                          thousandsGroupStyle="thousand"
                          suffix="%"
                          decimalSeparator="."
                          displayType="input"
                          thousandSeparator={true}
                          allowNegative={false}
                          decimalScale={2}
                          tag={NumberFormat}
                          value={discountsPercent}
                          onValueChange={(e: any) => setDiscountsPercent(e.floatValue)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6 text-center">
                      <Button color="primary" type="submit" size="lg" style={{ minWidth: '100px' }}>
                        Save
                      </Button>
                    </Col>
                    <Col md="6 text-center">
                      <Button
                        color="secondary"
                        size="lg"
                        style={{ minWidth: '100px' }}
                        onClick={(e) => {
                          e.preventDefault();
                          setShowForm(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            )}
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default DiscountsModal;
