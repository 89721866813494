import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { unregister } from './serviceWorkerRegistration';
import dotenv from 'dotenv';

dotenv.config();
unregister();
ReactDOM.render(<App />, document.getElementById('root'));
serviceWorkerRegistration.register();
