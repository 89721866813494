import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
} from 'reactstrap';
import { toast } from 'react-toastify';
import history from '../../../history';
import UserService from '../../../services/User';
import { useState } from 'react';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const submit = async () => {
    try {
      if (!email) return toast.success('Please provide a valid email.');
      await UserService('').forgotPasswordPublic({ email });
      toast.success('Request sent successfully!');
      history.push('/login');
    } catch (e) {
      toast.error('Unable to send request, please try again later.');
    }
  };
  return (
    <>
      <Col lg="6" md="8">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Enter your email</small>
            </div>
            <Form role="form">
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Email" type="email" onChange={(e) => setEmail(e.target.value)} />
                </InputGroup>
              </FormGroup>
              <div className="text-center">
                <Button className="mt-4" color="primary" onClick={submit}>
                  Send Password Reset Email
                </Button>
                <Link to="/login">
                  <Button className="mt-4" color="link">
                    Cancel
                  </Button>
                </Link>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default ForgotPassword;
