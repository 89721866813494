import { useContext, useState, useEffect } from 'react';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import { Row, Col, FormGroup, InputGroup, Input, InputGroupAddon, Button, Modal } from 'reactstrap';
import DataTable from '../../../components/Core/DataTableWithoutHeaderAndPagination';
import TourService from '../../../services/Tour';
import { toLocaleFormatSlash, timeFormat } from '../../../helpers/dateFormat';
import ScheduleForm from '../Schedule/ScheduleForm';

const TourList = () => {
  const { token, currentSchool }: any = useContext(AuthContext);
  const [search, setSearch] = useState('');
  const [data, setData] = useState([] as any[]);
  const [scheduleId, setScheduleId] = useState('');
  const [scheduleForm, setScheduleForm] = useState(false);
  const [dateClick, setDateClick] = useState('');
  const [loading, setLoading] = useState(false);

  const toggleScheduleForm = () => {
    setScheduleForm(!scheduleForm);
  };

  const modalChange = () => {
    setScheduleForm(!scheduleForm);
  };

  const columns = [
    {
      name: 'Day',
      selector: (row: any) => (
        <span
          style={{ cursor: 'pointer', color: '#5e72e4' }}
          onClick={() => {
            setScheduleId(row.id);
            setDateClick(row?.day);
          }}
        >
          {row?.day || '-'}
        </span>
      ),
      grow: 1,
    },
    {
      name: 'Time',
      selector: (row: any) => `${row?.time || '-'}`,
      grow: 1,
    },
    {
      name: 'Name',
      selector: (row: any) => `${row?.name || '-'}`,
      grow: 2,
    },
    {
      name: 'Child(ren)',
      selector: (row: any) => `${row?.child || '-'}`,
      grow: 1,
    },
  ];

  useEffect(() => {
    listSearch();
  }, [currentSchool]);

  useEffect(() => {
    if (scheduleId) {
      setScheduleForm(true);
    }
  }, [scheduleId]);

  const handleSearch = async () => {
    listSearch(search);
  };

  const listSearch = async (s: string = '') => {
    setLoading(true);
    try {
      if (currentSchool?.value && token) {
        const result: any = await TourService(token || '').listByFilter(
          String(s || ''),
          String(currentSchool?.value || ''),
        );
        if (result?.data) {
          const res: any[] = [];
          for (const r of result?.data) {
            res.push({
              id: r?.id,
              day: toLocaleFormatSlash(r?.dateTour),
              time: timeFormat(`${r?.timeTour} ${r?.dateTour}`),
              name: `${r?.firstName || ''} ${r?.lastName || ''}`,
              child: r?.kids?.length,
            });
          }
          setData(res);
        }
      }
    } catch (e: any) {
      console.error(e?.response?.data?.message || 'Search error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Row>
        <Col md="6" className="mt-3 ml-3 mr-1">
          <FormGroup className="shadow">
            <InputGroup>
              <Input
                className="form-control-alternative"
                type="text"
                value={search}
                onChange={(v: any) => setSearch(v.target.value)}
                onKeyDown={(e: any) => (e?.key === 'Enter' ? handleSearch() : null)}
              />
              <InputGroupAddon addonType="append">
                <Button color="primary" onClick={handleSearch}>
                  <i className="fas fa-search"></i>
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </FormGroup>
        </Col>
        <Col md="12">
          <DataTable
            title="Tour List"
            columns={columns}
            data={data}
            loading={loading}
            button={false}
            buttonUrl=""
            onChangeRowsPerPage={async () => {}}
            onChangePage={async () => {}}
          />
        </Col>
      </Row>

      <Modal className="modal-dialog-centered" isOpen={scheduleForm} toggle={() => toggleScheduleForm()}>
        <div className="modal-header">
          <h5 className="modal-title" id="Label">
            {`Schedule`}
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => toggleScheduleForm()}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <ScheduleForm
            isOpen={scheduleForm}
            scheduleId={scheduleId}
            onModalStatus={modalChange}
            dateClick={dateClick}
            onCreateSchedule={() => {}}
          />
        </div>
      </Modal>
    </>
  );
};

export default TourList;
