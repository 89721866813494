import { isMobile } from 'react-device-detect';
import { Col } from 'reactstrap';
import CardWidgetBilling from '../../../components/Cards/CardWidgetBilling';

const BillingParentsSummary = ({
  totalAccounts,
  totalBillingAmount,
  totalBalanceAmount,
  isAdmin = true,
}: {
  totalAccounts: number;
  totalBillingAmount: number;
  totalBalanceAmount: number;
  isAdmin: boolean;
}) => {
  return (
    <>
      {isAdmin && (
        <Col
          lg="4"
          md="4"
          xl="4"
          xs="4"
          className={isMobile ? 'mb-2' : ''}
          style={isMobile ? { paddingLeft: 5, paddingRight: 5 } : {}}
        >
          <CardWidgetBilling
            title="Accounts Linked"
            text={totalAccounts.toFixed(0)}
            icon="fas fa-link"
            iconColor="primary"
          />
        </Col>
      )}
      <Col
        lg="4"
        md="4"
        xl="4"
        xs="4"
        className={isMobile ? 'mb-2' : ''}
        style={isMobile ? { paddingLeft: 5, paddingRight: 5 } : {}}
      >
        <CardWidgetBilling
          title="Weekly Billed Amount"
          text={`$${totalBillingAmount.toFixed(2)}`}
          icon="fas fa-hand-holding-usd"
          iconColor="info"
        />
      </Col>
      <Col lg="4" xl="4" xs="4" md="4" style={isMobile ? { paddingLeft: 5, paddingRight: 5 } : {}}>
        <CardWidgetBilling
          title="Current Balance"
          text={`$${Number(totalBalanceAmount * -1).toFixed(2)}`}
          icon="fas fa-wallet"
          iconColor={`${Number(totalBalanceAmount || 0) >= 0 ? 'success' : 'danger'}`}
        />
      </Col>
    </>
  );
};

export default BillingParentsSummary;
