import { Button, Card, CardBody, Form, Row, Col } from 'reactstrap';
import { toast } from 'react-toastify';
import { useContext, useState, useEffect, useRef } from 'react';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import MessagingService from '../../../services/Messaging';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

type MessagingReplyProps = {
  id: string;
  onRefresh: () => void;
};

const MessagingReply = ({ id, onRefresh }: MessagingReplyProps) => {
  const fileRef = useRef(null as any);

  const { token, currentSchool }: any = useContext(AuthContext);
  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  const [schoolId, setSchoolId] = useState('');
  const [sendTo, setSendTo] = useState('');
  const [groupMessage, setGroupMessage] = useState(true);
  const [message, setMessage] = useState('');
  const [attachment, setAttachment] = useState(null as any);

  //const [selectList, setSelectList] = useState([{}]);

  const getById = async () => {
    try {
      const result = await MessagingService(token).show(id);
      if (result?.data) {
        setSendTo(result?.data?.sendTo || '');
      }
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'Invalid Messaging ID');
    }
  };

  const showOpenFileDialog = () => {
    if (isDisabled) {
      return;
    }
    if (fileRef?.current?.click) {
      fileRef.current.click();
    }
  };

  const onChangeFile = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];
    setAttachment(file);
  };

  useEffect(() => {
    if (id) {
      getById();
    }
  }, []);

  useEffect(() => {
    setSchoolId(currentSchool.value || '');
    setGroupMessage(true);
  }, [currentSchool]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);
    setIsDisabled(true);

    const body = {
      schoolId,
      sendTo,
      groupMessage,
      message,
    };

    try {
      const result = await MessagingService(token).createReply(id, body);
      if (attachment) {
        const formData = new FormData();
        formData.append('attachment', attachment as any);
        await MessagingService(token).attachment(result?.data?.id, formData);
      }
      toast.success(`Created successfully!`);
      if (result?.data?.id) {
        onRefresh();
        setMessage('');
        setAttachment(null);
      }
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Create error!');
      toast.error(message);
    }

    setLoading(false);
    setIsDisabled(false);
  };

  return (
    <>
      <Row>
        <Col className="order-xl-2" xl="12">
          <Card className="bg-secondary shadow">
            <CardBody style={{ padding: '5px 10px' }}>
              <Form onSubmit={handleSubmit}>
                <div>
                  <Row>
                    <Col className="order-xl-2" xl="12">
                      <ReactQuill value={message} onChange={setMessage} />
                    </Col>
                  </Row>

                  <Row className="mt-2">
                    <Col xs="6">
                      <Button
                        className="btn-icon btn-sm"
                        color="secondary"
                        type="button"
                        id="input-attach"
                        onClick={showOpenFileDialog}
                        style={{ marginBottom: '2px' }}
                      >
                        <span className="btn-inner--icon pt-1" style={{ marginRight: '1px' }}>
                          <i className="fas fa-paperclip" style={{ top: '0' }} />
                        </span>
                      </Button>
                      <span>{attachment?.name || 'Select a file'}</span>
                      <input
                        id="img-attach"
                        name="attach"
                        type="file"
                        accept=".png,.jpg,.jpeg,.pdf"
                        style={{ display: 'none' }}
                        ref={fileRef}
                        disabled={isDisabled}
                        onChange={onChangeFile}
                      />
                    </Col>
                    <Col xs="6" className="text-right">
                      <Button color="primary" type="submit" disabled={isDisabled}>
                        Send
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default MessagingReply;
