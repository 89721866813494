import http from '../helpers/http';
import KidsParentsInterface from '../interfaces/KidsParentsInterface';

const KidsParents = (token: string) => {
  const headers = {
    'x-access-token': token,
  };

  return {
    list: async (kidsId: string) => {
      const result = await http.get(`/kids/${kidsId}/parents`, {
        headers,
      });
      return result.data;
    },
    show: async (id: string, kidsUserId: string) => {
      const result = await http.get(`/kids/${id}/parents/${kidsUserId}`, {
        headers,
      });
      return result.data;
    },
    create: async (body: Partial<KidsParentsInterface>) => {
      const result = await http.post(`/kids/parents`, body, { headers });
      return result.data;
    },
    update: async (id: string, kidsUserId: string, body: Partial<KidsParentsInterface>) => {
      const result = await http.put(`/kids/${id}/parents/${kidsUserId}`, body, { headers });
      return result.data;
    },
    delete: async (id: string, kidsUserId: string) => {
      const result = await http.delete(`/kids/${id}/parents/${kidsUserId}`, {
        headers,
      });
      return result.data;
    },
  };
};

export default KidsParents;
