import { useState, useEffect, useContext } from 'react';
import { Context as AuthContext } from '../../contexts/AuthContext';
import ActivitySettings from '../../services/Attendances';
import ClassService from '../../services/Class';
import { Row, Col, Button } from 'reactstrap';
import LoaderSpinner from '../Core/LoaderSpinner';
import { toast } from 'react-toastify';
import React from 'react';

const KidsCurrentClassCameras = ({ kidId, stopTimeInterval }: { kidId: string; stopTimeInterval?: boolean }) => {
  const [currentClass, setCurrentClass] = useState(null);
  const [currentCamera, setCurrentCamera] = useState('');
  const [cameras, setCameras] = useState([] as string[]);
  const { token }: any = useContext(AuthContext);
  const [loading, setLoading] = useState(true);

  const getKidCurrentClass = async () => {
    setLoading(true);
    try {
      const result = await ActivitySettings(token).listMovementByKids(kidId, '');
      if (!result.data) {
        throw new Error('No data found');
      }
      setCurrentClass(null);
      setCurrentClass(result.data[0].strRoomId);
      // setTimeout(() => {
      //   getKidCurrentClass();
      // }, 15000);
    } catch (e: any) {
      toast.warning('Child not checked in.');
    } finally {
      setLoading(false);
    }
  };

  const getKidCurrentCamera = async () => {
    setLoading(true);
    try {
      if (!currentClass) {
        throw new Error('No class found');
      }

      const result = await ClassService(token).show(currentClass);
      if (!result.data) {
        throw new Error('No data found');
      }

      setCameras(result?.data?.cameras ? result?.data?.cameras.map((c: any) => c.url) : []);

      setCurrentCamera(result?.data?.cameras.map((c: any) => c.url)[0] + `&teste=${Date.now()}`);
    } catch (e: any) {
      toast.error('No kids found.');
    } finally {
      setLoading(false);
    }
  };

  const cameraImage = (src: string) => {
    const teste = React.createElement('img', { src: src, style: { width: '100%' } });

    return teste;
  };

  useEffect(() => {
    // console.log(timerInterval);
    setCurrentClass(null);
    setCameras([]);
    setCurrentCamera('');
    getKidCurrentClass();
    // const timer = setInterval(async () => {
    //   getKidCurrentClass();
    // }, 15000);
    // setTimerInterval(timer);
  }, [kidId]);

  useEffect(() => {
    if (!currentClass) {
      return;
    }
    getKidCurrentCamera();
  }, [currentClass]);

  useEffect(() => {
    setCurrentClass(null);
    setCameras([]);
    setCurrentCamera('');
  }, [stopTimeInterval]);

  return (
    <>
      {stopTimeInterval && (
        <>
          {loading && (
            <Row>
              <Col>
                <LoaderSpinner />
              </Col>
            </Row>
          )}
          {!loading && cameras && currentCamera && (
            <Row>
              <Col xs="12">
                {cameras.map((c, i) => (
                  <Button
                    key={`camera-${i}`}
                    onClick={() => setCurrentCamera(c)}
                    className={currentCamera === c ? 'active' : ''}
                  >
                    Camera {i + 1}
                  </Button>
                ))}
              </Col>
              <Col xs="12" className="mt-2 ">
                {currentCamera}
                {/* {!imgLoaded && (
                  <div
                    style={{
                      width: '100%',
                      backgroundColor: '#eeeef0',
                      height: '300px',
                      textAlign: 'center',
                      borderRadius: '10px',
                    }}
                  >
                    <i className="fas fa-5x fa-image" style={{ padding: '110px 0' }}></i>
                  </div>
                )} */}
                {cameraImage(currentCamera)}
                {/* <ReactPlayer
                  playsinline={true} // very very imp prop
                  pip={false}
                  light={false}
                  controls={false}
                  muted={true}
                  playing={true}
                  url={currentCamera}
                  height={'100%'}
                  width={'100%'}
                  onError={(err) => {
                    console.log(err, 'participant video error');
                  }}
                /> */}
                {/* <Media
                  key={new Date().getTime()}
                  id="camImage"
                  object
                  src={currentCamera}
                  style={{ width: '100%' }}
                  onLoad={() => console.log('CARREGOU!')}
                  onError={() => console.log('ERRO!')}
                /> */}
                {/* {currentCamera &&
                  cameraImage(
                    currentCamera,
                  )
                  
                } */}
              </Col>
            </Row>
          )}
        </>
      )}
    </>
  );
};

export default KidsCurrentClassCameras;
