import { Button, Card, CardBody, Container, Row, Col } from 'reactstrap';

type PtoReproveConfirmProps = {
  id: string;
  type: string;
  handleRepprove: (id: string) => void;
  onClose: () => void;
};

const PtoReproveConfirm = ({ id, handleRepprove, type, onClose }: PtoReproveConfirmProps) => {
  return (
    <Container fluid>
      <Card>
        <CardBody>
          <Row className="mt-3">
            <Col md="12">
              <span className="h2 font-weight-bold mb-0">Are you sure you want to {type} this PTO request?</span>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col xs="6" md="6 text-center">
              <Button
                color="primary"
                href="#"
                onClick={() => {
                  handleRepprove(id);
                  onClose();
                }}
                size="lg"
                style={{ minWidth: '100px' }}
              >
                Yes
              </Button>
            </Col>
            <Col xs="6" md="6 text-center">
              <Button color="danger" href="#" onClick={onClose} size="lg" style={{ minWidth: '100px' }}>
                No
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
};

export default PtoReproveConfirm;
