import UserInterface from '../interfaces/UserInterface';
import { FilteredOptionsInterface, makeQueryParamsForRequest } from '../entities/FilteredOptions';
import http from '../helpers/http';

const User = (token: string) => {
  const headers = {
    'x-access-token': token,
  };

  return {
    readNotification: async (body: any) => {
      const result = await http.put('/user-notification/read-multiple', body, {
        headers,
      });
      return result.data;
    },
    notifications: async (schoolId: string) => {
      const result = await http.get(`/user-notification/my/${schoolId}`, {
        headers,
      });
      return result.data;
    },
    profile: async () => {
      const result = await http.get('/users/profile', {
        headers,
      });
      return result.data;
    },
    update: async (userId: string, body: Partial<UserInterface>) => {
      const result = await http.put(`/users/${userId}`, body, { headers });
      return result.data;
    },
    updateConfigs: async (userId: string, body: any) => {
      const result = await http.put(`/users/${userId}/configs`, body, { headers });
      return result.data;
    },
    picture: async (userId: string, body: FormData) => {
      const headers = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-access-token': token,
        },
      };
      const result = await http.patch(`/users/${userId}/picture`, body, headers);
      return result.data;
    },
    list: async (filteredOptions?: FilteredOptionsInterface) => {
      const params = makeQueryParamsForRequest(filteredOptions);
      const result = await http.get('/users', {
        headers,
        params,
      });
      return result.data;
    },
    show: async (id: string) => {
      const result = await http.get(`/users/${id}`, {
        headers,
      });
      return result.data;
    },
    create: async (body: Partial<UserInterface>) => {
      const result = await http.post(`/users`, body, { headers });
      return result.data;
    },
    listFilter: async (ids: string[]) => {
      const params = { ids: JSON.stringify(ids) };
      const result = await http.get(`/users/filter`, {
        headers,
        params,
      });
      return result.data;
    },
    password: async (body: { userId: string; currentPassword: string; password: string }) => {
      const result = await http.patch(`/users/password`, body, { headers });
      return result.data;
    },
    forgotPasswordPublic: async (body: { email: string }) => {
      const result = await http.patch(`/users/forgot-password`, body, {});
      return result.data;
    },
    forgotPasswordSendPublic: async (body: { token: string; password: string }) => {
      const result = await http.patch(`/users/forgot-password-send`, body, {});
      return result.data;
    },
    pin: async (body: { userId: string }) => {
      const result = await http.patch(`/users/pin`, body, { headers });
      return result.data;
    },
    listKids: async (userId: string) => {
      const result = await http.get(`/parents/${userId}/kids`, {
        headers,
      });
      return result.data;
    },
    createContact: async (body: Partial<UserInterface>) => {
      const result = await http.post(`/users/contact`, body, { headers });
      return result.data;
    },
    updateContact: async (userId: string, body: Partial<UserInterface>) => {
      const result = await http.put(`/users/${userId}/contact`, body, { headers });
      return result.data;
    },
    listBirthdayUsers: async () => {
      const result = await http.get('/users/birthday', {
        headers,
      });
      return result.data;
    },
    listAnniversaryUsers: async () => {
      const result = await http.get('/users/anniversary', {
        headers,
      });
      return result.data;
    },
    listTourUsers: async (schoolId: string) => {
      const result = await http.get(`/users/${schoolId}/tour`, {
        headers,
      });
      return result.data;
    },
    createPublic: async (body: any) => {
      const result = await http.post(`/users/public`, body, { headers });
      return result.data;
    },
    updatePublic: async (body: any) => {
      const result = await http.put(`/users/public`, body, { headers });
      return result.data;
    },
  };
};

export default User;
