import { Container, Row, Col, Card, CardBody, CardTitle, CardHeader } from 'reactstrap';
import { useContext, useState, useEffect } from 'react';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import CommonHeader from '../../../components/Headers/CommonHeader';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import TimelineService from '../../../services/Timeline';
import KidsService from '../../../services/Kids';
import { toLocaleFormatSlash } from '../../../helpers/dateFormat';
import isParent from '../../../helpers/isParent';
import { VerticalTimeline } from 'react-vertical-timeline-component';
import Breadcrumb from '../../../components/Navbars/Breadcrumb';
import TimeLineItem from '../../../components/Timeline/TimelineItem';
import Gallery from '../../../components/Gallery/ImageViewer';
import activityIcons from './timelineActivityIcons';
import UserService from '../../../services/User';

const TimelineList = () => {
  const { kidsId } = useParams<{ kidsId: string }>();
  const { kids, token, user }: any = useContext(AuthContext);
  const isParentUser = isParent(user);
  const [data, setData] = useState<any[]>([]);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [birthday, setBirthday] = useState('');
  const [routeClassId, setRouteClassId] = useState('');
  const [className, setClassName] = useState('');
  const [authUser, setAuthUser] = useState<any>({});

  const [pictureUrl, setPictureUrl] = useState(kids?.picture || '');

  const getByKidsId = async () => {
    try {
      const result = await TimelineService(token).show(kidsId);
      if (result?.data) {
        const dataResult: any = result.data;
        setData(dataResult);
      }
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'Invalid Kids ID');
    }
  };

  const showKidsId = async () => {
    try {
      const result = await KidsService(token).show(kidsId);
      if (result?.data) {
        setFirstName(result?.data?.firstName || '');
        setLastName(result?.data?.lastName || '');
        setBirthday(result?.data?.birthday ? toLocaleFormatSlash(result?.data?.birthday) : '');
        setPictureUrl(result?.data?.picture || '');
        setRouteClassId(result?.data?.enrollment?.classId);
        setClassName(result?.data?.enrolled?.class);
      }
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'Invalid Kids ID');
    }
  };

  const BreadcrumbRoutes = [
    {
      name: 'Class',
      route: 'class',
    },
    {
      name: className || routeClassId,
      route: `class/${routeClassId}/details`,
    },
  ];

  const BreadcrumbTitle = `${firstName} ${lastName}`;

  useEffect(() => {
    getByKidsId();
    showKidsId();
    getAuthUserData();
  }, []);

  const getAuthUserData = async () => {
    try {
      const result = await UserService(token).profile();
      if (result?.data) {
        setAuthUser(result?.data);
      }
    } catch (e: any) {
      console.error(e?.response?.data?.message || 'Invalid user ID');
    }
  };

  return (
    <>
      <CommonHeader />

      <Container className="mt--8" fluid>
        <Row>
          <Col className="order-xl-2" xl="12">
            {!isParentUser && <Breadcrumb routes={BreadcrumbRoutes} title={BreadcrumbTitle} />}

            <Card className="bg-secondary shadow border-0">
              <CardHeader className="p-0 border-0 rounded-top">
                <Row>
                  <Col lg="12">
                    <Card className="border-0">
                      <CardBody>
                        <Row className="d-flex justify-content-start pl-4">
                          <img
                            className="rounded-lg shadow"
                            alt={`${firstName} ${lastName}`}
                            src={pictureUrl ? `${pictureUrl}` : require('../../../assets/img/user-default.png').default}
                            style={{ maxWidth: '100px' }}
                          />
                          <div className="d-flex flex-column ml-4">
                            <span className="h2 font-weight-bold mt-2 mb-0" style={{ color: '#32325d' }}>
                              {firstName} {lastName}
                            </span>
                            <CardTitle className="text-muted mb-0">Birthday</CardTitle>
                            <span style={{ fontSize: '15px' }} className="h3 mb-0 text-muted">
                              {birthday}
                            </span>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="pb-4">
                <Row>
                  <Col lg="12">
                    <h3 className="text-muted ml-4 text-uppercase">Timeline</h3>
                    <VerticalTimeline layout="1-column-left" lineColor="#007BFF" animate={false}>
                      {data.map((a, index) => {
                        const findItem = activityIcons.find((item) => a.kidsActivity.tags.includes(item.key));
                        const icon = findItem?.icon || 'fa-ellipsis';
                        const status = findItem?.status || 'bg-info';
                        a.canDownloadAttachs =
                          authUser.configs?.canDownloadPhotos === undefined
                            ? true
                            : authUser.configs?.canDownloadPhotos;
                        return (
                          <TimeLineItem
                            options={a.options}
                            icon={icon}
                            status={status}
                            name={a.kidsActivity.name}
                            description={(a as any).notes}
                            key={`item-${index}`}
                            canDownloadAttachs={
                              authUser.configs?.canDownloadPhotos === undefined
                                ? true
                                : authUser.configs?.canDownloadPhotos
                            }
                            attachments={a.attachments}
                            content={
                              a.attachments.length > 0 && (
                                <div className="d-flex flex-column">
                                  <p
                                    style={{ fontSize: '10px' }}
                                    className="h6 mt-4 text-muted font-weight-600 text-uppercase mb-3"
                                  >
                                    Attachments
                                  </p>
                                  <Gallery galleries={a.attachments} />
                                </div>
                              )
                            }
                          />
                        );
                      })}
                    </VerticalTimeline>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default TimelineList;
