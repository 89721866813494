import { Button, Card, CardHeader, Row, Col } from 'reactstrap';
import useCheckMobileScreen from '../../../hooks/useCheckMobileScreen';

type ClassKidsActivityButtonsProps = {
  title: string;
  selectedAll: boolean;
  checkedOutSelected: boolean;
  confirmActivity: (e: any, type: string) => void;
  toggleMovingForm: (e: any) => void;
  toggleActivityForm: (e: any, type: string) => void;
  toggleRemindersForm: (e: any) => void;
  toggleFoodForm: (e: any, type: string) => void;
  toggleSleepForm: (e: any, type: string) => void;
  toggleBathroomForm: (e: any, type: string) => void;
};

const ClassKidsActivityButtons = ({
  title,
  selectedAll,
  checkedOutSelected,
  confirmActivity,
  toggleMovingForm,
  toggleActivityForm,
  toggleRemindersForm,
  toggleFoodForm,
  toggleSleepForm,
  toggleBathroomForm,
}: ClassKidsActivityButtonsProps) => {
  const { isMobile } = useCheckMobileScreen();
  return (
    <Card className="bg-secondary shadow">
      <CardHeader className="bg-white border-0">
        <Row className="align-items-center">
          <Col xs="12">
            <h3 className="mb-0">{title}</h3>
          </Col>
          <Col lg="12" xl="12" className="mt-2">
            <Button
              className={`${isMobile ? 'btn-block' : ''} mt-2 ml-0`}
              color="success"
              size="md"
              style={{ minWidth: 124, minHeight: 28 }}
              onClick={(e) => {
                confirmActivity(e, 'CHECKIN');
              }}
              disabled={selectedAll}
            >
              <i className="far fa-arrow-alt-circle-right"></i> Check In
            </Button>
            <Button
              className={`${isMobile ? 'btn-block' : ''} mt-2 ml-0`}
              color="warning"
              size="md"
              style={{ minWidth: 124, minHeight: 28 }}
              onClick={(e) => toggleMovingForm(e)}
              disabled={selectedAll || checkedOutSelected}
            >
              <i className="fas fa-exchange-alt"></i> Moving
            </Button>
            <Button
              className={`${isMobile ? 'btn-block' : ''} mt-2 ml-0`}
              color="info"
              size="md"
              style={{ minWidth: 124, minHeight: 28 }}
              onClick={(e) => toggleActivityForm(e, 'activity')}
            >
              <i className="fas fa-puzzle-piece"></i> Activity
            </Button>
            <Button
              className={`${isMobile ? 'btn-block' : ''} mt-2 ml-0`}
              color="outline-warning"
              size="md"
              style={{ minWidth: 124, minHeight: 28 }}
              onClick={(e) => toggleFoodForm(e, 'food')}
              disabled={checkedOutSelected}
            >
              <i className="fas fa-utensils"></i> Food
            </Button>
            <Button
              className={`${isMobile ? 'btn-block' : ''} mt-2 ml-0`}
              color="outline-primary"
              size="md"
              style={{ minWidth: 124, minHeight: 28 }}
              onClick={(e) => toggleSleepForm(e, 'sleep')}
              disabled={checkedOutSelected}
            >
              <i className="fas fa-bed"></i> Sleep
            </Button>
            <Button
              className={`${isMobile ? 'btn-block' : ''} mt-2 ml-0`}
              color="outline-success"
              size="md"
              style={{ minWidth: 124, minHeight: 28 }}
              onClick={(e) => toggleBathroomForm(e, 'bathroom')}
              disabled={checkedOutSelected}
            >
              <i className="fas fa-toilet"></i> Bathroom
            </Button>
            <Button
              className={`${isMobile ? 'btn-block' : ''} mt-2 ml-0`}
              color="outline-danger"
              size="md"
              style={{ minWidth: 124, minHeight: 28 }}
              onClick={(e) => toggleRemindersForm(e)}
              disabled={checkedOutSelected}
            >
              <i className="fas fa-bell"></i> Reminders
            </Button>
            <Button
              className={`${isMobile ? 'btn-block' : ''} mt-2 ml-0`}
              color="success"
              size="md"
              style={{ minWidth: 124, minHeight: 28 }}
              onClick={(e) => {
                confirmActivity(e, 'CHECKOUT');
              }}
              disabled={selectedAll || checkedOutSelected}
            >
              <i className="far fa-arrow-alt-circle-left"></i> Check Out
            </Button>
          </Col>
        </Row>
      </CardHeader>
    </Card>
  );
};

export default ClassKidsActivityButtons;
