import moment from 'moment';
import { Button, Modal } from 'reactstrap';

interface ExtraPaymentDetailProps {
  isOpen: boolean;
  toggle: () => void;
  data: any;
}

const ExtraPaymentDetail = ({ isOpen, toggle, data }: ExtraPaymentDetailProps) => {
  return (
    <Modal className="modal-dialog-centered" isOpen={isOpen} toggle={toggle} size={'lg'}>
      <div className="modal-header">
        <div className="d-flex align-items-center">
          <div style={{ width: '35px', height: '35px', borderRadius: '50%', overflow: 'hidden' }}>
            <img
              src={data?.user.picture}
              alt="User Profile"
              className="img-fluid"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </div>
          <span className="ml-2">{`${data?.user.firstName || '-'} ${data?.user.lastName || '-'}`}</span>
        </div>
        <div onClick={toggle} className="d-flex p-1 cursor-pointer">
          <i className="fas fa-times" />
        </div>
      </div>
      <div className="modal-body px-5 pt-0">
        <hr />
        <div className="d-flex align-items-center" style={{ gap: 80 }}>
          <div className="d-flex flex-column justify-content-start" style={{ gap: 15 }}>
            <h4 className="p-0 m-0">Type</h4>
            <h4 className="p-0 m-0">Amount</h4>
            <h4 className="p-0 m-0">Date Adding To Payroll</h4>
            <h4 className="p-0 m-0">Reason</h4>
            {data?.document && <h4 className="p-0 m-0">Document</h4>}
            {data?.receipt && <h4 className="p-0 m-0">Receipt</h4>}
          </div>

          <div className="d-flex flex-column justify-content-center" style={{ gap: 15 }}>
            <p className="p-0 m-0">{data?.type}</p>
            <p className="p-0 m-0">$ {parseFloat(data?.amount).toFixed(2)}</p>
            <p className="p-0 m-0">{moment(data?.startDate).format('MMMM D, YYYY')}</p>
            <p className="p-0 m-0">{data?.reason}</p>
            {data?.document && (
              <p className="p-0 m-0">
                <Button
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    background: '#FFFFFF',
                    padding: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    transition: 'filter 0.3s ease',
                    filter: 'brightness(1)',
                    color: '#416adb',
                  }}
                  onMouseEnter={(e) => (e.currentTarget.style.filter = 'brightness(1.2)')}
                  onMouseLeave={(e) => (e.currentTarget.style.filter = 'brightness(1)')}
                >
                  <a href={data?.document} target="_blank">
                    <i className="fas fa-download" />
                    <span style={{ fontSize: 11, fontWeight: 'normal' }}>Document</span>
                  </a>
                </Button>
              </p>
            )}
            {data?.receipt && (
              <p className="p-0 m-0">
                <Button
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    background: '#FFFFFF',
                    padding: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    transition: 'filter 0.3s ease',
                    filter: 'brightness(1)',
                    color: '#416adb',
                  }}
                  onMouseEnter={(e) => (e.currentTarget.style.filter = 'brightness(1.2)')}
                  onMouseLeave={(e) => (e.currentTarget.style.filter = 'brightness(1)')}
                >
                  <a href={data?.receipt} target="_blank">
                    <i className="fas fa-download" />
                    <span style={{ fontSize: 11, fontWeight: 'normal' }}>Receipt</span>
                  </a>
                </Button>
              </p>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ExtraPaymentDetail;
