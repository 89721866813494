import http from '../helpers/http';

const ClassType = (token: string) => {
  const headers = {
    'x-access-token': token,
  };

  return {
    list: async () => {
      const result = await http.get(`/class-type`, {
        headers,
      });
      return result.data;
    },
    create: async (body: { name: string }) => {
      const result = await http.post(`/class-type`, body, { headers });
      return result.data;
    },
    delete: async (id: string) => {
      const result = await http.delete(`/class-type/${id}`, {
        headers,
      });
      return result.data;
    },
    listPublic: async () => {
      const result = await http.get(`/class-type`);
      return result.data;
    },
    listPriceSheetPublic: async (classTypeId: string) => {
      const result = await http.get(`/class-type/${classTypeId}/price-sheet`);
      return result.data;
    },
  };
};

export default ClassType;
