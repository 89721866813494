import { Link, useParams } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
} from 'reactstrap';
import { toast } from 'react-toastify';
import history from '../../../history';
import UserService from '../../../services/User';
import { useState } from 'react';

const ForgotPasswordReset = () => {
  const { token } = useParams<{ token: string }>();
  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const submit = async () => {
    try {
      if (password !== passwordRepeat) return toast.warn('The passwords are different!');
      await UserService('').forgotPasswordSendPublic({ token, password });
      toast.success('Password changed successfully!');
      history.push('/login');
    } catch (e) {
      toast.error('Unable to send request, please try again later.');
    }
  };
  return (
    <>
      <Col lg="6" md="8">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Enter your new password</small>
            </div>
            <Form role="form">
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Password" type="password" onChange={(e) => setPassword(e.target.value)} />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Repeat Password"
                    type="password"
                    onChange={(e) => setPasswordRepeat(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <div className="text-center">
                <Button className="mt-4" color="primary" onClick={submit}>
                  Send
                </Button>
                <Link to="/login">
                  <Button className="mt-4" color="link">
                    Cancel
                  </Button>
                </Link>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default ForgotPasswordReset;
