import { format, formatDistanceToNow, differenceInYears, parse } from 'date-fns';

export const dateDistanceForMessages = (date: string | Date) => {
  let d = date;
  if (!(d instanceof Date)) {
    d = new Date(date);
  }

  return format(d, `MM/dd/yyyy HH:mm`);
};

const dateDistance = (date: string | Date) => {
  let d = date;
  if (!(d instanceof Date)) {
    d = new Date(date);
  }

  return formatDistanceToNow(d, { addSuffix: true }) + ' (' + format(d, `EEEE, MM/dd/yyyy`) + ')';
};

export const dateDistanceTime = (date: string | Date) => {
  let d = date;
  if (!(d instanceof Date)) {
    d = new Date(date);
  }

  return formatDistanceToNow(d, { addSuffix: true });
};

export const calculateAge = (dob: string): number => {
  const date = parse(dob, 'MM/dd/yyyy', new Date());
  const age = differenceInYears(new Date(), date);
  return age;
};

export default dateDistance;
