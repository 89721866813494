import { useContext, useEffect, useState } from 'react';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import TransactionsService from '../../../services/Transaction';
import { PagedMetaInterface, createPagedMeta } from '../../../entities/PagedMeta';
import { createOptionsPage } from '../../../entities/OptionsPaged';
import DataTable from '../../../components/Core/DataTableWithoutHeader';
import { dateFormat } from '../../../helpers/dateFormat';
import FilteredOptionsInterface from '../../../entities/FilteredOptions';
import { Modal } from 'reactstrap';
import moment from 'moment';

const BillingTransactionsList = ({
  reload,
  kidsList,
  kidsId,
}: {
  reload: boolean;
  kidsList: any[];
  kidsId: string;
}) => {
  const { token } = useContext(AuthContext);

  const conditionalRowStyles = [
    {
      when: (row: any) =>
        ['failed', 'requires_payment_method', 'canceled'].includes(row.options?.paymentData?.status || ''),
      style: {
        backgroundColor: 'rgb(246, 206, 216)',
      },
    },
  ];
  const columns = [
    {
      name: 'Date',
      selector: (row: any) => <b style={{ fontSize: 15 }}>{`${row?.createdAt ? dateFormat(row.createdAt) : '-'}`}</b>,
      grow: 2,
    },
    {
      name: 'Account',
      selector: (row: any) => `${row?.user?.firstName || ''} ${row?.user?.lastName || ''}`,
      grow: 1,
    },
    {
      name: 'Description',
      selector: (row: any) => (
        <a
          href="#"
          className="transaction-type"
          onClick={(e) => {
            e.preventDefault();
            openTransactionModal(row?.id);
          }}
        >
          {`${row?.description || '-'}`}
        </a>
      ),
      grow: 2,
    },
    {
      name: 'Type',
      selector: (row: any) => `${row?.transactionType || '-'}`,
      grow: 1,
    },
    {
      name: 'Status',
      selector: (row: any) => `${row?.status || '-'}`,
      grow: 1,
    },
    {
      name: 'Charges',
      selector: (row: any) => row?.charges,
      grow: 1,
      cell: (row: any) =>
        ['CHARGE', 'FEE', 'TUITION'].includes(row.transactionType)
          ? `$${row?.amount ? Number(row?.amount).toFixed(2) : '0.00'}`
          : '$0.00',
    },
    {
      name: 'Credits',
      selector: (row: any) => row.amount,
      grow: 1,
      cell: (row: any) =>
        !['CHARGE', 'FEE', 'TUITION'].includes(row.transactionType)
          ? `$${row?.amount ? Number(row?.amount).toFixed(2) : '0.00'}`
          : '$0.00',
    },
  ];

  const [data, setData] = useState([] as any[]);
  const [loading, setLoading] = useState(false);
  const [pagedMeta, setPagedMeta] = useState(createPagedMeta());
  const [filteredOptions, setFilteredOptions] = useState({
    filters: [],
    options: createOptionsPage(pagedMeta),
  });
  const [showModal, setShowModal] = useState(false);
  const [selectedTansaction, setSelectedTransaction] = useState(
    null as {
      amount: number;
      id: string;
      providerId: string;
      transactionType: string;
      statusDescription: string;
      options: {
        created: number;
        us_bank_account?: { account_type: string; routing_number: number; last4: number; bank_name: string };
        paymentData?: { status: string; last_payment_error: any };
      };
    } | null,
  );

  useEffect(() => {
    loadList(filteredOptions);
  }, [filteredOptions, kidsId, reload]);

  const loadList = async (filteredOptions: FilteredOptionsInterface) => {
    try {
      setLoading(true);
      if (kidsList.length > 1) {
        const filter = {
          field: 'kidsId',
          operation: 'in',
          value: kidsList.map((item) => item.id),
        };
        filteredOptions.filters = [filter];
      }
      const result = await TransactionsService(token || '').listByKids(kidsId, filteredOptions);
      setData(result?.data);
      const meta: PagedMetaInterface = result?.meta;
      if (meta) {
        setPagedMeta(meta);
      }
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Error fetching list');
      console.error(message);
    } finally {
      setLoading(false);
    }
  };

  const handlePerRowsChange = async (perPage: number) => {
    const newOptions = {
      ...pagedMeta,
      perPage,
    };
    const newFilteredOptions = {
      filters: filteredOptions.filters,
      options: createOptionsPage(newOptions),
    };
    setFilteredOptions(newFilteredOptions);
  };

  const handlePageChange = async (page: number) => {
    const newOptions = {
      ...pagedMeta,
      currentPage: page,
    };
    const newFilteredOptions = {
      filters: filteredOptions.filters,
      options: createOptionsPage(newOptions),
    };
    setFilteredOptions(newFilteredOptions);
  };

  const openTransactionModal = (transactionId: string) => {
    setSelectedTransaction(data.find((item: any) => item.id === transactionId));
  };

  const getPaymentStatus = () => {
    let status = selectedTansaction?.options?.paymentData?.status;
    if (['failed', 'requires_payment_method', 'canceled'].includes(status || '')) {
      status = selectedTansaction?.options?.paymentData?.last_payment_error?.code.replace('_', ' ');
    }
    return status;
  };

  const getPaymentBadge = () => {
    return (
      <span
        className={`badge badge-pill badge-${getPaymentStatus() === 'succeeded' ? 'success' : 'danger'}`}
        style={{ fontSize: '0.7rem' }}
      >
        {getPaymentStatus()}
      </span>
    );
  };

  const getNotes = () => {
    let notes = (
      <>
        Invoice: #{selectedTansaction?.id}. Payment bank {selectedTansaction?.options?.us_bank_account?.account_type}{' '}
        account ending in **** **** **** {selectedTansaction?.options?.us_bank_account?.last4}
      </>
    );

    if (selectedTansaction?.statusDescription) {
      notes = <>{selectedTansaction?.statusDescription}</>;
    }
    return notes;
  };

  useEffect(() => {
    if (
      selectedTansaction === null ||
      selectedTansaction.transactionType === 'TUITION' ||
      selectedTansaction.transactionType === 'CHARGE'
    ) {
      setShowModal(false);
      return;
    }
    setShowModal(true);
  }, [selectedTansaction]);

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        size={'lg'}
        isOpen={showModal}
        toggle={() => setSelectedTransaction(null)}
      >
        {selectedTansaction && (
          <>
            <div className={`modal-header ${getPaymentStatus() === 'succeeded' ? 'bg-success' : 'bg-danger'}`}>
              <h2 className="modal-title text-white" id="Label">
                Details
              </h2>
              <button
                aria-label="Close"
                className="close text-white"
                data-dismiss="modal"
                type="button"
                onClick={() => setSelectedTransaction(null)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <table className="table table-hover">
                <tbody>
                  <tr>
                    <th>Document ID:</th>
                    <td>{selectedTansaction?.providerId}</td>
                  </tr>
                  <tr>
                    <th>Invoice #:</th>
                    <td>{selectedTansaction?.id}</td>
                  </tr>
                  <tr>
                    <th>Notes:</th>
                    <td>{getNotes()}</td>
                  </tr>
                  <tr>
                    <th>Account Holder Name:</th>
                    <td>{selectedTansaction?.options?.us_bank_account?.bank_name}</td>
                  </tr>
                  <tr>
                    <th>Account Type:</th>
                    <td style={{ textTransform: 'capitalize' }}>
                      {selectedTansaction?.options?.us_bank_account?.account_type}
                    </td>
                  </tr>
                  <tr>
                    <th>Routing Number:</th>
                    <td>{selectedTansaction?.options?.us_bank_account?.routing_number}</td>
                  </tr>
                  <tr>
                    <th>Account Number:</th>
                    <td>**** **** **** {selectedTansaction?.options?.us_bank_account?.last4}</td>
                  </tr>
                  <tr>
                    <th>Amount:</th>
                    <td>{Number(selectedTansaction?.amount).toFixed(2)}</td>
                  </tr>
                  <tr>
                    <th>Payment Status:</th>
                    <td>{getPaymentBadge()}</td>
                  </tr>
                  <tr>
                    <th>Posted Date:</th>
                    <td>{moment.unix(selectedTansaction?.options?.created || 0).format('MM/DD/YYYY HH:mm A')}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        )}
      </Modal>
      <DataTable
        title=""
        columns={columns}
        data={data}
        totalRows={pagedMeta.total}
        loading={loading}
        button={false}
        buttonUrl=""
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        conditionalRowStyles={conditionalRowStyles}
      />
    </>
  );
};

export default BillingTransactionsList;
