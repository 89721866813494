import { useContext, useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Row, Spinner } from 'reactstrap';
import TransactionService from '../../../services/Transaction';
import UserCustomer from '../../../services/UserCustomer';
import SchoolService from '../../../services/School';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import SavePaymentDataForm from './SavePaymentDataForm';

const SaveCreditCard = () => {
  const { token, user, currentSchool }: any = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const [options, setOptions] = useState({} as any);
  const [stripePromise, setStripePromise] = useState<any>();

  const getSchoolStripeCurrent = async () => {
    if (!currentSchool?.value) return;
    try {
      const r = await SchoolService('').getSchoolStripeCurrent(currentSchool?.value);
      if (r?.data?.stripePublicKey) {
        setStripePromise(loadStripe(r?.data?.stripePublicKey || '', { locale: 'en' }));
      }
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Stripe current error!');
      console.error(message);
    }
  };

  useEffect(() => {
    getSchoolStripeCurrent();
  }, [currentSchool]);

  useEffect(() => {
    getUserCustomer();
  }, []);

  useEffect(() => {
    if (options?.clientSecret) {
      setShowButton(false);
    }
    setLoading(false);
  }, [options]);

  const getUserCustomer = async () => {
    setLoading(true);
    const customer = await UserCustomer(token).create({
      userId: user?.id || '',
      schoolId: currentSchool?.value || '',
      options: { email: user?.email || '' },
    });
    setOptions({ customerId: customer?.options?.providerId || '' });
  };

  const handlePaymentIntent = async () => {
    setLoading(true);
    const intent = await TransactionService(token).createIntent({
      userId: user?.id || '',
      transactionType: 'ACH',
    });
    const newOptions = JSON.parse(JSON.stringify(options));
    newOptions.clientSecret = intent?.data?.clientSecret || '';
    setOptions(newOptions);
    setLoading(false);
  };

  return (
    <>
      <Row>
        <Col xl="12">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">Save ACH Test</CardHeader>
            <CardBody>
              <Row>
                {!loading && (
                  <>
                    {showButton && (
                      <Col xl="12">
                        <button className="btn btn-primary" onClick={handlePaymentIntent}>
                          Start Payment
                        </button>
                      </Col>
                    )}
                    {!showButton && stripePromise && (
                      <Col xl="12">
                        <Elements stripe={stripePromise} options={options}>
                          <SavePaymentDataForm />
                        </Elements>
                      </Col>
                    )}
                  </>
                )}
                {loading && (
                  <Col xl="12" className="mt-4 text-center">
                    <span>
                      <Spinner />
                    </span>
                  </Col>
                )}
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default SaveCreditCard;
