/* eslint-disable prefer-spread */
/* eslint-disable max-lines */
import { Col, Modal, Row } from 'reactstrap';
import { useEffect, useState } from 'react';
import { PDFDocument } from 'pdf-lib';

type I9DocumentViewModalProps = {
  isOpen: boolean;
  data: any;
  toggleModal: (e?: any) => void;
};

const I9DocumentViewModal = ({ isOpen, data, toggleModal }: I9DocumentViewModalProps) => {
  const [pdf, setPdf] = useState('');

  useEffect(() => {
    if (isOpen) {
      createPdf();
    }
  }, [isOpen]);

  const pdfUrl =
    'https://arb-systems-s3-public.s3.us-east-2.amazonaws.com/public/Employment-Eligibility/i-9-english.pdf';

  const coordinatesSign = {
    x: 60,
    y: 420,
    height: 30,
    opacity: 1,
  };

  const readOnlyFields = (pdfForm: any) => {
    try {
      const fieldNames = pdfForm.getFields();
      for (const f of fieldNames) {
        const type = f.constructor.name;
        const fieldName = f.getName();
        if (type === 'PDFTextField') {
          pdfForm.getTextField(fieldName).enableReadOnly();
        }
        if (type === 'PDFDropdown') {
          pdfForm.getDropdown(fieldName).enableReadOnly();
        }
        if (type === 'PDFCheckBox') {
          pdfForm.getCheckBox(fieldName).enableReadOnly();
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const createPdf = async () => {
    try {
      if (!data) return;
      const arrayBuffer = await fetch(pdfUrl).then((res) => res.arrayBuffer());
      const pdfDoc = await PDFDocument.load(arrayBuffer);
      const pdfForm = pdfDoc.getForm();
      readOnlyFields(pdfForm);
      const pages = pdfDoc.getPages();
      const firstPage = pages[0];
      if (firstPage && data?.options?.signPng) {
        const pngImage = await pdfDoc.embedPng(data?.options?.signPng);
        firstPage.drawImage(pngImage, coordinatesSign);
      }

      for (const f of data?.options?.formFieldsValues) {
        const fieldForm = data?.options?.formFields[f?.fieldIndex];
        if (f?.fieldType === 'PDFTextField' && f?.fieldValue) {
          const field = pdfForm.getTextField(fieldForm?.fieldName);
          field.setText(`${f?.fieldValue}`);
          field.enableReadOnly();
        }
        if (f?.fieldType === 'PDFDropdown' && f?.fieldValue) {
          const field = pdfForm.getDropdown(fieldForm?.fieldName);
          field.enableReadOnly();
          if (f?.fieldValue) field.select(f?.fieldValue);
        }
        if (f?.fieldType === 'PDFCheckBox' && f?.fieldValue === 'true') {
          const field = pdfForm.getCheckBox(fieldForm?.fieldName);
          field.enableReadOnly();
          field.check();
        }
      }

      const pdfBytes = await pdfDoc.save();
      const blob = new Blob([pdfBytes], { type: 'application/pdf' });
      const docUrl = URL.createObjectURL(blob);
      setPdf(docUrl);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Modal className="modal-dialog-centered" size="xl" isOpen={isOpen} toggle={(e: any) => toggleModal(e)}>
        <div className="modal-header pb-1">
          <h5 className="modal-title">I9</h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={(e) => toggleModal(e)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body pt-0">
          <Row className="mt-2">
            <Col lg="12" className="mt-4" style={{ maxHeight: '500px', overflow: 'scroll' }}>
              <iframe src={pdf} style={{ minHeight: 500, width: '100%' }} />
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default I9DocumentViewModal;
