import moment from 'moment';
import { Button, Modal, Table } from 'reactstrap';

interface DeductionDetailProps {
  isOpen: boolean;
  toggle: () => void;
  data: any;
}

const DeductionDetail = ({ isOpen, toggle, data }: DeductionDetailProps) => {
  return (
    <Modal className="modal-dialog-centered" isOpen={isOpen} toggle={toggle} size={'lg'}>
      <div className="modal-header pt-4 pb-0 px-5">
        <div className="d-flex align-items-center">
          <div style={{ width: '35px', height: '35px', borderRadius: '50%', overflow: 'hidden' }}>
            <img
              src={data?.user.picture}
              alt="User Profile"
              className="img-fluid"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </div>
          <span className="ml-2">{`${data?.user.firstName || '-'} ${data?.user.lastName || '-'}`}</span>
        </div>
        <div onClick={toggle} className="d-flex p-1 cursor-pointer">
          <i className="fas fa-times" />
        </div>
      </div>
      <div className="modal-body px-5 pt-0">
        <hr />
        <div className="d-flex align-items-start" style={{ gap: 80 }}>
          <div className="d-flex flex-column justify-content-start" style={{ gap: 15 }}>
            <h4 className="p-0 m-0 text-sm">Type</h4>
            <h4 className="p-0 m-0 text-sm">Total Amount</h4>
            <h4 className="p-0 m-0 text-sm">Start Date</h4>
            <h4 className="p-0 m-0 text-sm">End Date</h4>
            <h4 className="p-0 m-0 text-sm">Amount Paid</h4>
            <h4 className="p-0 m-0 text-sm">Amount Left</h4>
            {data?.file && <h4 className="p-0 m-0 text-sm">File</h4>}
          </div>

          <div className="d-flex flex-column justify-content-start" style={{ gap: 15 }}>
            <p className="p-0 m-0 text-sm">{data?.type}</p>
            <p className="p-0 m-0 text-sm">$ {parseFloat(data?.amount).toFixed(2)}</p>
            <p className="p-0 m-0 text-sm">{moment(data?.startDate).format('MMMM D, YYYY')}</p>
            <p className="p-0 m-0 text-sm">{moment(data?.endDate).format('MMMM D, YYYY')}</p>
            <p className="p-0 m-0 text-sm">$ 0</p>
            <p className="p-0 m-0 text-sm">$ {parseFloat(data?.amount).toFixed(2)}</p>
            {data?.file && (
              <p className="p-0 m-0 text-sm">
                <Button
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    background: '#FFFFFF',
                    padding: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    transition: 'filter 0.3s ease',
                    filter: 'brightness(1)',
                    color: '#416adb',
                  }}
                  onMouseEnter={(e) => (e.currentTarget.style.filter = 'brightness(1.2)')}
                  onMouseLeave={(e) => (e.currentTarget.style.filter = 'brightness(1)')}
                >
                  <a href={data.file} target="_blank">
                    <i className="fas fa-download" />
                  </a>
                </Button>
              </p>
            )}
          </div>
        </div>

        <div>
          <hr />
          <h3>Payment History</h3>

          <Table responsive>
            <tr>
              <th>Date</th>
              <th>Value</th>
            </tr>
            <tr>
              <td>{moment(data?.startDate).format('MMMM D, YYYY')}</td>
              <td>$ {parseFloat(data?.amountDeducted).toFixed(2)}</td>
            </tr>
          </Table>
        </div>
      </div>
    </Modal>
  );
};

export default DeductionDetail;
