import { Button, Card, CardHeader, CardBody, FormGroup, Form, Input, Container, Row, Col } from 'reactstrap';
import DateTime from '../../../components/Core/DateTime';
import { toast } from 'react-toastify';
import { useContext, useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import CommonHeader from '../../../components/Headers/CommonHeader';
import PromoCodesService from '../../../services/PromoCodes';
import history from '../../../history';
import moment from 'moment';

const PromoCodesCreate = () => {
  const { id } = useParams<{ id: string }>();
  const pageTitle = id ? 'Update' : 'Create';
  const { token }: any = useContext(AuthContext);
  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState('');
  const [regFee, setRegFee] = useState(0);
  const [tuitionFee, setTuitionFee] = useState(0);
  const [qty, setQty] = useState(0);

  const [status, setStatus] = useState('ACTIVE');
  const [expiredAt, setExpiredAt] = useState('');
  const [expirationAtQtd] = useState('');
  const [expirationAtType] = useState('WEEKS');
  const [msgAfterApply, setMsgAfterApply] = useState('');

  const getById = async () => {
    try {
      const result = await PromoCodesService(token).show(id);
      if (result?.data) {
        setCode(result?.data?.code || '');
        setRegFee(result?.data?.regFee || 0);
        setTuitionFee(result?.data?.tuitionFee || 0);
        setQty(result?.data?.qty || 0);
      }
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'Invalid promo code ID');
    }
  };

  useEffect(() => {
    if (id) {
      getById();
    }
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);
    setIsDisabled(true);

    let units: moment.unitOfTime.DurationConstructor = 'months';
    if (expirationAtType === 'WEEKS') {
      units = 'weeks';
    }

    let bodyExpiredAt = expiredAt ? expiredAt : moment().add(expirationAtQtd, units);
    if (!expirationAtQtd && !expiredAt) {
      bodyExpiredAt = '';
    }

    const body = {
      code,
      regFee,
      tuitionFee,
      qty,
      msgAfterApply,
      expiredAt: bodyExpiredAt,
      status,
    };

    try {
      const result = id ? await PromoCodesService(token).update(id, body) : await PromoCodesService(token).create(body);

      toast.success(`${id ? 'Updated' : 'Created'} successfully!`);
      if (result?.data?.id) {
        history.push(`/promo-codes/${result?.data?.id}`);
      }
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Create error!');
      toast.error(message);
    }

    setLoading(false);
    setIsDisabled(false);
  };

  return (
    <>
      <CommonHeader />
      <Container className="mt--8" fluid>
        <Row>
          <Col className="order-xl-2" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">{`${pageTitle} Promo Codes`}</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Link to="/promo-codes">
                      <Button color="primary" className="float-right">
                        Back
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmit}>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label">Code</label>
                          <Input
                            className="form-control-alternative"
                            placeholder="Code"
                            type="text"
                            disabled={isDisabled}
                            value={code}
                            onChange={(e) => setCode(e.target.value.toUpperCase())}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label">Quantity</label>
                          <Input
                            className="form-control-alternative"
                            placeholder="Qty"
                            type="number"
                            disabled={isDisabled}
                            value={qty}
                            onChange={(e) => setQty(Number(e.target.value || 0))}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label">Discount for Reg. Fee (%)</label>
                          <Input
                            className="form-control-alternative"
                            placeholder="Reg Fee"
                            type="number"
                            disabled={isDisabled}
                            value={regFee}
                            onChange={(e) => setRegFee(Number(e.target.value || 0))}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label">Discount for Tuition (%)</label>
                          <Input
                            className="form-control-alternative"
                            placeholder="Tuition Fee"
                            type="number"
                            disabled={isDisabled}
                            value={tuitionFee}
                            onChange={(e) => setTuitionFee(Number(e.target.value || 0))}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label">Code Exp. Date</label>
                          <DateTime
                            onChange={(newValue) => {
                              setExpiredAt(`${newValue}`);
                            }}
                            value={expiredAt}
                            clearButton={true}
                            inputProps={{ disabled: status !== 'ACTIVE' }}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label">Actived</label>
                          <Input type="select" onChange={(e) => setStatus(e.target.value)} value={status}>
                            {['ACTIVE', 'INACTIVE'].map((item, i) => {
                              return (
                                <>
                                  <option value={item} key={`slc-actived-${i}`}>
                                    {item}
                                  </option>
                                </>
                              );
                            })}
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label">Show a message after apply</label>
                          <Input
                            className=""
                            onChange={(e) => setMsgAfterApply(e.target.value)}
                            value={msgAfterApply}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <div className="pl-lg-4">
                    <Button className="my-4" color="primary" type="submit" disabled={isDisabled}>
                      Save
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PromoCodesCreate;
