/* eslint-disable max-lines */
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Card, CardHeader, CardBody, Container, Row, Col } from 'reactstrap';
import CardInfo from '../../components/Cards/CardInfo';

import { useState, useEffect, useContext } from 'react';
import { Context as AuthContext } from '../../contexts/AuthContext';
import OpacityLoaderSpinner from '../Core/OpacityLoaderSpinner';
import SchoolEventsCalendar from '../Calendar/SchoolEventsCalendar';
import UserService from '../../services/User';
import QrCode from '../../components/Core/QrCode';
// import { ErrorsList } from '../../views/pages/Home/ErrorsList';
import Employee from '../../services/Employee';
import { createOptionsPage } from '../../entities/OptionsPaged';

import dateInterval from '../../helpers/dateInterval';
import AttendancesService from '../../services/Attendances';
import getTimeCardInfo from '../../helpers/getTimeCardInfo';

import PtoService from '../../services/Pto';
import hasKids from '../../helpers/hasKids';
import KidsCameras from './Partials/KidsCameras';
import KidsTimeline from '../Kids/KidsTimeline';

const StaffDashboard = ({ currentUser }: { currentUser: any }) => {
  const [loading, setLoading] = useState(false);

  const [schoolId, setSchoolId] = useState('');
  const { currentSchool, token }: any = useContext(AuthContext);
  const [modalQr, setModalQr] = useState(false);
  const toggleModalQr = () => setModalQr(!modalQr);
  const [currentWorkedHours, setCurrentWorkedHours] = useState(0);
  const [timeOffPendingRequests, setTimeOffPendingRequests] = useState([] as any[]);
  const [timeOffScheduledThisMonth, setTimeOffScheduledThisMonth] = useState([] as any[]);

  const userHasKids = hasKids(currentUser);
  const [kidsUser, setKidsUser] = useState<any>([]);
  const [selectedKid, setSelectedKid] = useState('');

  const getKidsUserData = async () => {
    if (!userHasKids) {
      return;
    }
    const result = await UserService(token).listKids(currentUser.id);
    if (result?.data) {
      setKidsUser(
        result?.data.map((kidsUser: any) => {
          return {
            id: kidsUser.kids.id,
            firstName: kidsUser.kids.firstName,
            lastName: kidsUser.kids.lastName,
            fullName: `${kidsUser.kids.firstName} ${kidsUser.kids.lastName}`,
            picture: kidsUser.kids.picture,
            isCheckedIn: kidsUser.kids.isCheckedIn,
            class: kidsUser.kids.enrollment.class,
          };
        }),
      );
      setSelectedKid(result.data[0].kids.id);
    }
  };

  const getAuthUserData = async () => {
    setLoading(true);
    try {
      const result = await Employee(token).list({
        filters: [
          {
            field: 'userId',
            operation: 'hash',
            value: currentUser.id,
          },
        ],
        options: createOptionsPage(),
      });
      if (result?.data) {
        const interval = dateInterval('THIS_WEEK');
        const startDateTime = interval.startDateTime;
        const finalDateTime = interval.finalDateTime;
        const resultAttendance = await AttendancesService(token || '').listByUser(
          currentUser.id,
          `startDateTime=${startDateTime}&finalDateTime=${finalDateTime}`,
        );
        const { sumRealWorkTotal, sumOvertime } = getTimeCardInfo(
          startDateTime,
          finalDateTime,
          currentUser,
          resultAttendance.data,
        );
        setCurrentWorkedHours(sumRealWorkTotal + sumOvertime);
      }
      getKidsUserData();
      setLoading(false);
    } catch (e: any) {
      console.error(e?.response?.data?.message || 'Invalid user ID');
      setLoading(false);
    }
  };

  const filterByUserOrAdmin = (userId: string) => {
    return userId == currentUser.id;
    //&& !isAdminUser && value.userId === user.id
  };

  const getPtoData = async () => {
    const result = await PtoService(token).timeOffRequestList(currentSchool.value);
    const penddingList = result?.data?.filter(
      (value: any) => value.status === 'PENDDING' && filterByUserOrAdmin(value.userId),
    );
    const resulTtimeOffRequestListApprovedThisMonth = await PtoService(token).timeOffRequestListApprovedThisMonth(
      currentSchool.value,
    );

    setTimeOffScheduledThisMonth(resulTtimeOffRequestListApprovedThisMonth?.data || []);
    setTimeOffPendingRequests(penddingList || []);
  };

  useEffect(() => {
    setSchoolId(currentSchool.value || '');
    getAuthUserData();
    getPtoData();
  }, []);

  useEffect(() => {
    setSchoolId(currentSchool.value || '');
  }, [currentSchool]);

  return (
    <>
      <Modal isOpen={modalQr} toggle={toggleModalQr} centered>
        <ModalHeader toggle={toggleModalQr} className="pb-0">
          My Code
        </ModalHeader>
        <ModalBody className="pt-0">
          <QrCode />
        </ModalBody>
        <ModalFooter style={{ textAlign: 'center' }}>
          <span className="h2 font-weight-bold mx-auto">{`${currentUser.pin || '-'}`}</span>
        </ModalFooter>
      </Modal>
      <Container className="mt--7 " fluid>
        <Row className="mt-5">
          <Col xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Staff's Dashboard</h3>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <Row className="mb-3">
                  <Col xs="12">
                    <button
                      className="w-100 btn-lg btn-success"
                      style={{ height: '100px', fontSize: '28px', fontWeight: 'bold' }}
                      onClick={() => toggleModalQr()}
                    >
                      <i className="ni ni-badge" />
                      &nbsp;Checkin/Checkout
                    </button>
                  </Col>
                </Row>
                <Row>
                  <Col lg="4" xl="4" sm="12" className="mt-3">
                    <CardInfo class="bg-primary card-info-mg-button" title="Current Worked Hours" icon="fa-clock">
                      <Row className="mt-4">
                        <Col md="12" className="text-center" style={{ padding: 0 }}>
                          <h1 className="text-center">{currentWorkedHours}</h1>
                        </Col>
                      </Row>
                    </CardInfo>
                  </Col>
                  <Col lg="4" xl="4" sm="12" className="mt-3">
                    <CardInfo class="bg-primary card-info-mg-button" title="PENDING PTO REQUESTS" icon="fa-calendar">
                      <Row className="mt-4">
                        <Col md="12" className="text-center" style={{ padding: 0 }}>
                          <h1 className="text-center">{timeOffPendingRequests?.length || 0}</h1>
                        </Col>
                      </Row>
                    </CardInfo>
                  </Col>
                  <Col lg="4" xl="4" sm="12" className="mt-3">
                    <CardInfo
                      class="bg-primary card-info-mg-button"
                      title="SCHEDULED PTO OFF THIS MONTH"
                      icon="fa-calendar-check"
                    >
                      <Row className="mt-4">
                        <Col md="12" className="text-center" style={{ padding: 0 }}>
                          <h1 className="text-center">{timeOffScheduledThisMonth?.length || 0}</h1>
                        </Col>
                      </Row>
                    </CardInfo>
                  </Col>
                  {/* <Col lg="4" xl="4" sm="12" className="mt-3">
                    <CardInfo class="bg-primary card-info-mg-button" title="Upcoming PTO Requests" icon="fa-clock">
                      <Row className="mt-4">
                        <Col xs="6" className="text-center" style={{ padding: 0, borderRight: '#CCC 1px solid' }}>
                          <h2 style={{ fontSize: '25px', fontWeight: 900, color: '#525f7f' }}>
                            {timeOffPendingRequests?.length || 0}
                          </h2>
                          <p style={{ fontSize: '10px' }}>PENDING REQUESTS</p>
                        </Col>
                        <Col xs="6" className="text-center" style={{ padding: 0 }}>
                          <h2 style={{ fontSize: '25px', fontWeight: 900, color: '#525f7f' }}>
                            {timeOffScheduledThisMonth?.length || 0}
                          </h2>
                          <p style={{ fontSize: '10px' }}>SCHEDULED OFF THIS MONTH</p>
                        </Col>
                      </Row>
                    </CardInfo>
                  </Col> */}
                </Row>
                {/* <Row className="mb-3">
                  <Col md="12">
                    <ErrorsList />
                  </Col>
                </Row> */}
                <Row className="mt-5">
                  <Col md="12">
                    <SchoolEventsCalendar schoolId={schoolId} />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {userHasKids && (
          <>
            <Row className="mt-5">
              <Col xl="12">
                <Card className="shadow">
                  <CardBody>
                    <KidsCameras currentUser={currentUser} kidsUser={kidsUser} />
                    <Row className="mt-3 mb-2">
                      {kidsUser.map((kid: any, i: any) => {
                        return (
                          <button
                            key={`kidsUser-${i}`}
                            className={
                              selectedKid === kid.id ? 'btn-sm btn-secondary mr-3 active' : 'btn-sm btn-secondary mr-3'
                            }
                            onClick={() => setSelectedKid(kid.id)}
                          >
                            <div className="align-items-center media">
                              <span className="avatar avatar-sm rounded-circle">
                                <img alt={kid.fullName} src={kid.picture} style={{ height: '100%' }} />
                              </span>
                              <div className="ml-2 d-none d-lg-block media">
                                <span className="mb-0 text-sm font-weight-bold">{kid.fullName}</span>
                              </div>
                            </div>
                          </button>
                        );
                      })}
                    </Row>
                    <Row>
                      <KidsTimeline kidsId={selectedKid} currentUser={currentUser} />
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </Container>
      {loading ? <OpacityLoaderSpinner /> : <></>}
    </>
  );
};

export default StaffDashboard;
