import { Card, CardBody, Row, Col, Button } from 'reactstrap';

type props = {
  setNewTypeDisplay: (type: string) => void;
};

const MessagingBlank = ({ setNewTypeDisplay }: props) => {
  return (
    <>
      <Card className="messaging-card-blank">
        <CardBody className="messaging-blank-cardbody">
          <Row>
            <Col md="12" className="messaging-card-header-title">
              <i className="fas fa-paper-plane"></i>
            </Col>
            <Col md="12" className="messaging-card-header-text">
              <p>Send messages to a person or group.</p>
            </Col>
            <Col md="12" className="messaging-card-header-text">
              <Button color="primary" className="" onClick={() => setNewTypeDisplay('')}>
                New Message
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default MessagingBlank;
