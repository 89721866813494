import { useEffect, useState, useCallback } from 'react';
import ImageViewer from 'react-simple-image-viewer';

interface ImageInterface {
  galleries: any;
}

const Gallery = (props: ImageInterface) => {
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [images, setImages] = useState([] as any[]);
  useEffect(() => {}, []);

  const openImageViewer = useCallback((index) => {
    const img = props.galleries?.map((a: any) => a.file);
    setImages(img);
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <>
      <div className="d-flex">
        <span>
          {props.galleries?.map((a: any, index: number) => (
            <img
              key={`attachment-${index}`}
              src={a.file}
              style={{ width: '150px', cursor: 'pointer' }}
              className="mr-1"
              onClick={() => openImageViewer(index)}
            />
          ))}
        </span>
        {isViewerOpen && (
          <ImageViewer
            src={images}
            currentIndex={currentImage}
            onClose={closeImageViewer}
            disableScroll={false}
            backgroundStyle={{
              backgroundColor: 'rgba(0,0,0,0.9)',
            }}
            closeOnClickOutside={true}
          />
        )}
      </div>
    </>
  );
};

export default Gallery;
