import { isMobile } from 'react-device-detect';

const collumns = ({
  setBalanceDetailData,
  setUserBalanceDetailFormEditMode,
  setModalBalanceOpen,
}: {
  setBalanceDetailData: any;
  setUserBalanceDetailFormEditMode: any;
  setModalBalanceOpen: any;
}) => {
  let cols: any[] = [
    {
      name: 'Name',
      grow: 2,
      cell: (row: any) => (
        <div
          className="d-flex align-items-center"
          style={{
            cursor: 'pointer',
            transition: 'filter 0.3s ease',
            filter: 'brightness(1)',
          }}
          onMouseEnter={(e) => (e.currentTarget.style.filter = 'brightness(0.8)')}
          onMouseLeave={(e) => (e.currentTarget.style.filter = 'brightness(1)')}
          onClick={() => {
            setBalanceDetailData(row);
            setUserBalanceDetailFormEditMode(false);
            setModalBalanceOpen(true);
          }}
        >
          <div style={{ width: '35px', height: '35px', borderRadius: '50%', overflow: 'hidden' }}>
            <img
              src={row.user.picture}
              alt="User Profile"
              className="img-fluid"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </div>
          <span className="ml-2 text-primary font-weight-bold text-sm">{`${row.user.firstName || '-'} ${
            row.user.lastName || '-'
          }`}</span>
        </div>
      ),
    },
  ];
  if (!isMobile) {
    cols = [
      ...cols,
      ...[
        {
          name: 'Policy Name',
          selector: (row: any) => row.ptoPolicy?.name,
          grow: 1,
          cell: (row: any) => row.ptoPolicy?.name,
        },
        {
          name: 'Current Balance',
          selector: (row: any) => row.ptoPolicy.currentBalance,
          grow: 1,
          cell: (row: any) => row.ptoPolicy.currentBalance,
        },
        {
          name: 'Scheduled Hours',
          selector: (row: any) => row.department.name,
          grow: 1,
          cell: (row: any) => (row.ptoPolicy.scheduleHours ? row.ptoPolicy.scheduleHours : ''),
        },
        {
          name: 'Available Balance',
          selector: (row: any) =>
            row.ptoPolicy.name ? (row.ptoPolicy.currentBalance - row.ptoPolicy.scheduleHours).toFixed(2) : '',
          grow: 1,
          cell: (row: any) =>
            row.ptoPolicy.name ? (row.ptoPolicy.currentBalance - row.ptoPolicy.scheduleHours).toFixed(2) : '',
        },
      ],
    ];
  }
  if (isMobile) {
    cols = [
      ...cols,
      ...[
        {
          name: 'Avail Balance',
          selector: (row: any) =>
            row.ptoPolicy.name ? (row.ptoPolicy.currentBalance - row.ptoPolicy.scheduleHours).toFixed(2) : '',
          grow: 2,
          cell: (row: any) => (
            <div className="w-100 text-right">
              {row.ptoPolicy.name ? (row.ptoPolicy.currentBalance - row.ptoPolicy.scheduleHours).toFixed(2) : ''}
            </div>
          ),
        },
      ],
    ];
  }
  return cols;
};

export default collumns;
