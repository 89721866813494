export const validTransactionTypes = {
  CREDIT: 'CREDIT',
  CHARGE: 'CHARGE',
  MANUAL: 'MANUAL',
  CREDIT_CARD: 'CREDIT_CARD',
  ACH: 'ACH',
};

export const validChargeAccountingTypes = [
  { value: 'DISCOUNT', label: 'Discount' },
  { value: 'EXTRA_FEE', label: 'Extra Fee' },
  { value: 'REGISTRATION_FEE', label: 'Registration Fee' },
  { value: 'SUPPLY_FEE', label: 'Supply Fee' },
  { value: 'TUITION', label: 'Tuition' },
  { value: 'SHIRTS', label: 'Shirts' },
  { value: 'OTHERS', label: 'Others' },
];

export const validManualAccountingTypes = [
  { value: 'CHECK', label: 'Check' },
  { value: 'CASH', label: 'Cash' },
  { value: 'MONEY_ORDER', label: 'Money Order' },
  { value: 'PAYCHECK', label: 'Paycheck' },
];
