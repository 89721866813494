/* eslint-disable max-lines */
import { useContext, useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import CommonHeader from '../../../components/Headers/CommonHeader';
import LoaderSpinner from '../../../components/Core/LoaderSpinner';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Modal,
  FormGroup,
  Input,
  Label,
  Form,
} from 'reactstrap';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import ClassService from '../../../services/Class';
import KidsService from '../../../services/Kids';
import KidsInterface from '../../../interfaces/KidsInterface';
import ModalDanger from '../../../components/Core/ModalDanger';
import ClassActivityForm from './ClassActivityForm';
import ClassMovingForm from './ClassMovingForm';
import ClassRemindersForm from './ClassRemindersForm';
import ClassFoodForm from './ClassFoodForm';
import ClassSleepForm from './ClassSleepForm';
import ClassBathroomForm from './ClassBathroomForm';
import KidsActivity from '../../../services/KidsActivity';
import ClassKidsList from './ClassKidsList';
import ClassKidsActivityButtons from './ClassKidsActivityButtons';
import ClassActivityConfirm from './ClassActivityConfirm';
import ClassSummaryBanner from './ClassSummaryBanner';
import ClassCameras from './ClassCameras';
import ClassAttendanceReportModal from './ClassAttendanceReportModal';
import ClassTrackingReportModal from './ClassTrackingReportModal';
import DatePicker from 'react-datepicker';
import TimePicker from '../../../components/Core/TimePicker';
import IncidentReports from '../../../services/IncidentReports';
import moment from 'moment';
import ActivitySettings from '../../../services/Attendances';
import ClassCheckInModal from './ClassCheckInModal';
import { isMobile } from 'react-device-detect';
import ClassKidsActivityButtonsMobile from './ClassKidsActivityButtonsMobile';
import ClassManageKidsReminderBox from './ClassManageKidsReminderBox';

const Class = ({ classId, withBackButton = true }: { classId?: string; withBackButton?: boolean }) => {
  const { id } = useParams<{ id: string }>();
  const { token, currentSchool }: any = useContext(AuthContext);
  const isTablet = window.innerWidth <= 1112 && window.innerWidth > 768 && isMobile;

  const [name, setName] = useState('');
  const [capacity, setCapacity] = useState(0);
  const [teachersCheckedIn, setTeachersCheckedIn] = useState(0);
  const [legalRatioTeachers, setLegalRatioTeachers] = useState(0);
  const [legalRatioChildrens, setLegalRatioChildrens] = useState(0);
  const [kids, setKids] = useState([] as any[]);
  const [kidsSelected, setKidsSelected] = useState([] as string[]);
  const [selectedAll, setSelectedAll] = useState(false);
  const [kidsCheckedIn, setKidsCheckedIn] = useState(0);
  const [kidsNotCheckedIn, setKidsNotCheckedIn] = useState(0);
  const [kidsMoved, setKidsMoved] = useState(0);
  const [kidsCheckedInList, setKidsCheckedInList] = useState([] as any[]);
  const [kidsMovedOutList, setKidsMovedOutList] = useState([] as any[]);
  const [schoolId, setSchoolId] = useState('');
  const [loading, setLoading] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalMessage, setModalMessage] = useState('');
  const [isModal, setIsModal] = useState(false);
  const [activityForm, setActivityForm] = useState(false);
  const [movingForm, setMovingForm] = useState(false);
  const [remindersForm, setRemindersForm] = useState(false);
  const [foodForm, setFoodForm] = useState(false);
  const [sleepForm, setSleepForm] = useState(false);
  const [bathroomForm, setBathroomForm] = useState(false);
  const [tag, setTag] = useState('');
  const [isNotPhotoPermissionKids, setIsNotPhotoPermissionKids] = useState([] as any[]);
  const [checkedOutSelected, setCheckedOutSelected] = useState(false);
  const [isSleepingKids, setIsSleepingKids] = useState([] as any[]);
  const [modalCamera, setModalCamera] = useState(false);
  const [cameras, setCameras] = useState([] as string[]);
  const [modalAttendanceReport, setModalAttendanceReport] = useState(false);
  const [modalTrackingReport, setModalTrackingReport] = useState(false);
  const [isIncidentReportOpen, setIsIncidentReportOpen] = useState(false);
  const [isClockInOpen, setIsClockInOpen] = useState(false);
  const [isSign, setIsSign] = useState(false);
  const [studentName, setStudentName] = useState('');
  const [kidsId, setKidsId] = useState('');
  const [afectedOrInjured, setAfectedOrInjured] = useState('');
  const [type, setType] = useState('');
  const [acidentDate, setAcidentDate] = useState(new Date());
  const [acidentTime, setAcidentTime] = useState(moment().format());
  const [location, setLocation] = useState('');
  const [incidentDescription, setIncidentDescription] = useState('');
  const [injuryNatureDescription, setInjuryNatureDescription] = useState('');
  const [medicalTreatmentDescription, setMedicalTreatmentDescription] = useState('');
  const [ambulanceCalled, setAmbulanceCalled] = useState(false);
  const [witness, setWitness] = useState('');
  const [action, setAction] = useState('');
  const [teacherSignature, setTeacherSignature] = useState('');
  const [teacherSignatureDate, setTeacherSignatureDate] = useState<Date | null>();
  const [kidsReminder, setKidsReminder] = useState<any>(null);
  const [kidsReminderLate, setKidsReminderLate] = useState<any[]>([]);
  const [kidsReminderAlmostLate, setKidsReminderAlmostLate] = useState<any[]>([]);
  const { sideBarIsMini }: any = useContext(AuthContext);

  let timerInterval: any;

  useEffect(() => {
    if (!currentSchool) {
      return;
    }
  }, [currentSchool]);

  const startLoadActivity = async () => {
    if ((id || classId || '') === '') return;
    const result = await ActivitySettings(token).listKidsReminderByClass(id || classId || '');
    setKidsReminder(result?.data);
    timerInterval = setInterval(async () => {
      const result = await ActivitySettings(token).listKidsReminderByClass(id || classId || '');

      setKidsReminder(result?.data);
    }, 100000);
    return () => window.clearInterval(timerInterval);
  };

  useEffect(() => {
    startLoadActivity();
  }, []);

  useEffect(() => {
    if (kidsReminder !== null) {
      const updatedKidsReminderLate = [];
      const updatedKidsReminderAlmostLate = [];

      for (const kid of kidsReminder) {
        const foodActvity = kid.kidsActivityRelations.filter((kid: any) => kid.key === 'FOOD');
        const lastFoodActivity = foodActvity[foodActvity.length - 1];

        const bathroomActvity = kid.kidsActivityRelations.filter((kid: any) => kid.key === 'BATHROOM');
        const lastBathroomActivity = bathroomActvity[bathroomActvity.length - 1];

        // const checkInActvity = kid.kidsActivityRelations.filter((kid: any) => kid.key === 'CHECKIN');
        // const lastCheckInActvity = checkInActvity[checkInActvity.length - 1];

        const lastActivity = kid.kidsActivityRelations[kid.kidsActivityRelations.length - 1];
        const now = new Date();

        if (lastActivity === 'CHECKOUT') return;

        if (lastActivity?.key === 'CHECKIN') {
          if (kid.notPottyTrained) {
            const bathroomTime = new Date(lastActivity.time);
            const timeDifference = bathroomTime ? (now.getTime() - bathroomTime.getTime()) / (1000 * 60 * 60) : null;

            if (timeDifference !== null && timeDifference >= 2) {
              updatedKidsReminderLate.push({
                id: kid.id,
                firstName: kid.firstName,
                lastName: kid.lastName,
                type: 'BATHROOM',
              });
            } else if (timeDifference !== null && timeDifference >= 1) {
              updatedKidsReminderAlmostLate.push({
                id: kid.id,
                firstName: kid.firstName,
                lastName: kid.lastName,
                type: 'BATHROOM',
              });
            }
          }

          if (kid.feedingSchedule) {
            const feedingTime = new Date(lastActivity.time);
            const timeDifference = feedingTime ? (now.getTime() - feedingTime.getTime()) / (1000 * 60 * 60) : null;
            if (timeDifference !== null && timeDifference >= kid.feedingIntervalHours) {
              updatedKidsReminderLate.push({
                id: kid.id,
                firstName: kid.firstName,
                lastName: kid.lastName,
                type: 'FOOD',
              });
            } else if (timeDifference !== null && timeDifference >= kid.feedingIntervalHours - 1) {
              updatedKidsReminderAlmostLate.push({
                id: kid.id,
                firstName: kid.firstName,
                lastName: kid.lastName,
                type: 'FOOD',
              });
            }
          }
        }

        if (kid.feedingSchedule) {
          if (lastFoodActivity) {
            const feedingTime = new Date(lastFoodActivity.time);
            const timeDifference = feedingTime ? (now.getTime() - feedingTime.getTime()) / (1000 * 60 * 60) : null;

            if (timeDifference !== null && timeDifference >= kid.feedingIntervalHours) {
              updatedKidsReminderLate.push({
                id: kid.id,
                firstName: kid.firstName,
                lastName: kid.lastName,
                type: 'FOOD',
              });
            } else if (timeDifference !== null && timeDifference >= kid.feedingIntervalHours - 1) {
              updatedKidsReminderAlmostLate.push({
                id: kid.id,
                firstName: kid.firstName,
                lastName: kid.lastName,
                type: 'FOOD',
              });
            }
          }
        }

        if (kid.notPottyTrained) {
          if (lastBathroomActivity) {
            const bathroomTime = new Date(lastBathroomActivity.time);
            const timeDifference = bathroomTime ? (now.getTime() - bathroomTime.getTime()) / (1000 * 60 * 60) : null;

            if (timeDifference !== null && timeDifference >= 2) {
              updatedKidsReminderLate.push({
                id: kid.id,
                firstName: kid.firstName,
                lastName: kid.lastName,
                type: 'BATHROOM',
              });
            } else if (timeDifference !== null && timeDifference >= 1) {
              updatedKidsReminderAlmostLate.push({
                id: kid.id,
                firstName: kid.firstName,
                lastName: kid.lastName,
                type: 'BATHROOM',
              });
            }
          }
        }
      }
      const checkedIns = kidsCheckedInList.map((i: any) => i.id);
      setKidsReminderAlmostLate(updatedKidsReminderAlmostLate.filter((i: any) => checkedIns.includes(i.id)));
      setKidsReminderLate(updatedKidsReminderLate.filter((i: any) => checkedIns.includes(i.id)));
    }
  }, [kidsReminder, kidsCheckedInList]);

  const handleRemoveItem = (id: string, type: string) => {
    const filteredKidsReminderLate = kidsReminderLate.filter((item: any) => item.id !== id || item.type !== type);
    setKidsReminderLate(filteredKidsReminderLate);

    const filteredKidsReminderAlmostLate = kidsReminderAlmostLate.filter(
      (item: any) => item.id !== id || item.type !== type,
    );
    setKidsReminderAlmostLate(filteredKidsReminderAlmostLate);
  };

  useEffect(() => {
    if (id || classId) {
      getById();
      getTeachersAttendance();
    }
    loadList();
  }, []);

  useEffect(() => {
    if (id || classId) {
      getById();
      getTeachersAttendance();
    }
    loadList();
  }, [isClockInOpen]);

  useEffect(() => {
    let isCheckedOut = false;
    for (const i of kidsSelected) {
      if (isCheckedOut || !!kids.find((f) => f.id === i && !f.isCheckedIn)) {
        isCheckedOut = true;
      }
    }
    setCheckedOutSelected(isCheckedOut);
  }, [kidsSelected]);

  const getById = async () => {
    try {
      setLoading(true);
      const result = await ClassService(token).show(id || classId || '');
      if (result?.data) {
        setName(result?.data?.name || '');
        setCapacity(result?.data?.capacity || '');
        setLegalRatioTeachers(result?.data?.legalRatioTeachers || '');
        setLegalRatioChildrens(result?.data?.legalRatioChildrens || '');
        setSchoolId(result?.data?.schoolId);
        setCameras(result?.data?.cameras ? result?.data?.cameras.map((c: any) => c.url) : []);
      }
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'Invalid Class ID');
    } finally {
      setLoading(false);
    }
  };

  const handleOpenIncidentModal = () => {
    if (kidsSelected.length > 1) {
      toast.error('Only 1 child must be selected!');
    } else if (kidsSelected.length < 1) {
      toast.error('Select 1 child!');
    } else {
      const kid = kids.filter((kids) => kids.id === kidsSelected[0]);
      setKidsId(kidsSelected[0]);
      setStudentName(`${kid[0].firstName} ${kid[0].lastName}`);
      setIsIncidentReportOpen(true);
    }
  };

  const handleOpenCheckIn = () => {
    setIsClockInOpen(true);
  };

  const getTeachersAttendance = async () => {
    try {
      setLoading(true);
      const result = await ClassService(token).listAttendances(id || classId || '', 'USER');
      if (result?.data) {
        const teachersCheckedIn = result?.data.filter((t: any) => t.attendanceType === 'CHECKIN');
        const teachersCheckedOut = result?.data.filter((t: any) => t.attendanceType === 'CHECKOUT');
        setTeachersCheckedIn(teachersCheckedIn.length - teachersCheckedOut.length);
      }
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'Invalid Class ID');
    } finally {
      setLoading(false);
    }
  };

  const loadList = async () => {
    try {
      if ((id || classId || '') === '') return;
      setLoading(true);
      const result = await KidsService(token || '').listByClass(id || classId || '');
      const data: KidsInterface[] = result?.data || [];

      setKids(
        data
          .map((k) => ({
            ...k,
            enrolledCurrentClass: k.enrolledClassId === id || classId,
          }))
          .filter((k) => k.enrollmentStatus === 'ENROLLED'),
      );
      const movedInFilter = data.filter((k) => k.isCheckedIn && k.isMoved && k.movedRoomId === (id || classId));
      const movedIn = movedInFilter.map((k) => ({
        ...k,
        fromAnotherClass: true,
        enrolledCurrentClass: k.enrolledClassId === id || classId,
      }));
      const checkedIn = data.filter((k) => k.isCheckedIn && !k.isMoved);
      setKidsCheckedIn(Number((checkedIn.length || 0) + (movedIn.length || 0)));
      setKidsCheckedInList([...checkedIn, ...movedIn]);
      const countNotCheckedIn = data.filter((k) => !k.isCheckedIn).length;
      setKidsNotCheckedIn(countNotCheckedIn);
      const movedOutFilter = data.filter((k) => k.isCheckedIn && k.isMoved && k.movedRoomId !== classId);
      const movedOut = movedOutFilter.map((k) => ({
        ...k,
        fromAnotherClass: false,
        enrolledCurrentClass: k.enrolledClassId === id || classId,
      }));
      setKidsMovedOutList(movedOut);
      const countMovedOut = movedOut.length || 0;
      setKidsMoved(Number(countMovedOut));
    } finally {
      setLoading(false);
    }
  };

  const selectChild = (e: any, id: string, isSleep: boolean) => {
    e.preventDefault();
    setSelectedAll(false);
    const newKidsSelected = JSON.parse(JSON.stringify(kidsSelected));
    if (newKidsSelected.includes(id)) {
      const removedArray = newKidsSelected.filter((r: string) => String(r) !== String(id));
      setKidsSelected(removedArray);
      const removedSleepArray = isSleepingKids.filter((r: string) => String(r) !== String(id));
      setIsSleepingKids(removedSleepArray);
      return;
    }
    newKidsSelected.push(id);
    setKidsSelected(newKidsSelected);
    if (isSleep) {
      const newIsSleepingKids = JSON.parse(JSON.stringify(isSleepingKids));
      newIsSleepingKids.push(id);
      setIsSleepingKids(newIsSleepingKids);
    }
  };

  const selectAll = () => {
    if (kidsSelected.length) {
      setKidsSelected([]);
      setSelectedAll(false);
      setIsSleepingKids([]);
      return;
    }

    const newKidsSelected: string[] = [];
    const isSleeping: string[] = [];
    kidsCheckedInList.map((k) => {
      newKidsSelected.push(k.id);
      if (k.isSleep) {
        isSleeping.push(k.id);
      }
    });
    setKidsSelected(newKidsSelected);
    setSelectedAll(true);
    setIsSleepingKids(isSleeping);
    return;
  };

  const openModal = (title: string, message: string) => {
    setModalTitle(title);
    setModalMessage(message);
    setIsModal(true);
  };

  const confirmActivity = async (e: any, type: string) => {
    e.preventDefault();

    if (!kidsSelected.length) {
      return toast.warn('Please select at least one student');
    }

    confirmAlert({
      customUI: ({ onClose }) => {
        return <ClassActivityConfirm type={type} sendActivity={sendActivity} onClose={onClose} />;
      },
    });
  };

  const sendActivity = async (type: string) => {
    try {
      for (const k of kidsSelected) {
        if (type === 'CHECKIN') {
          await KidsActivity(token).checkin(k);
        }
        if (type === 'CHECKOUT') {
          await KidsActivity(token).checkout(k);
        }
      }
      setKidsSelected([]);
      setIsSleepingKids([]);
      toast.success('Saved successfully!');
      await loadList();
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'Error');
    }
  };

  const setIsNotPhotoPermission = () => {
    const notPhotoPermissionKids = [];
    for (const i of kidsSelected) {
      const k = kids.find((f) => f.id === i);
      if (!k?.systemPhotoPermission) {
        notPhotoPermissionKids.push(k);
      }
    }
    setIsNotPhotoPermissionKids(notPhotoPermissionKids);
  };

  const toggleActivityForm = (e: any, tag: string) => {
    e.preventDefault();
    setTag(tag);
    setIsNotPhotoPermission();
    setActivityForm(!activityForm);
  };

  const modalActivityChange = (clear: boolean = false) => {
    setActivityForm(!activityForm);
    if (clear) {
      setKidsSelected([]);
      setIsSleepingKids([]);
    }
  };

  const toggleMovingForm = (e: any) => {
    e.preventDefault();
    setMovingForm(!movingForm);
  };

  const modalMovingChange = async (clear: boolean = false) => {
    setMovingForm(!movingForm);
    if (clear) {
      setKidsSelected([]);
      setIsSleepingKids([]);
    }
    await loadList();
  };

  const toggleRemindersForm = (e: any) => {
    e.preventDefault();
    setRemindersForm(!remindersForm);
  };

  const modalRemindersChange = (clear: boolean = false) => {
    setRemindersForm(!remindersForm);
    if (clear) {
      setKidsSelected([]);
      setIsSleepingKids([]);
    }
  };

  const toggleFoodForm = (e: any, tag: string) => {
    e.preventDefault();
    setTag(tag);
    setIsNotPhotoPermission();
    setFoodForm(!foodForm);
  };

  const modalFoodChange = (clear: boolean = false) => {
    setFoodForm(!foodForm);
    if (clear) {
      setKidsSelected([]);
      setIsSleepingKids([]);
    }
  };

  const toggleSleepForm = (e: any, tag: string) => {
    e.preventDefault();
    setTag(tag);
    setIsNotPhotoPermission();
    setSleepForm(!sleepForm);
  };

  const modalSleepChange = async (clear: boolean = false) => {
    setSleepForm(!sleepForm);
    if (clear) {
      setKidsSelected([]);
      setIsSleepingKids([]);
    }
    await loadList();
  };

  const toggleBathroomForm = (e: any) => {
    e.preventDefault();
    setBathroomForm(!bathroomForm);
  };

  const modalBathroomChange = (clear: boolean = false) => {
    setBathroomForm(!bathroomForm);
    if (clear) {
      setKidsSelected([]);
      setIsSleepingKids([]);
    }
  };

  const toggleModalAttendanceReport = () => {
    setModalAttendanceReport(!modalAttendanceReport);
  };

  const handleSubmitIncidentReports = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const body = {
      kidsId,
      schoolId,
      classId: id || classId,
      afectedOrInjured,
      type,
      acidentDate,
      acidentTime,
      location,
      incidentDescription,
      injuryNatureDescription,
      medicalTreatmentDescription,
      ambulanceCalled,
      witness,
      action,
      teacherSignature,
      teacherSignatureDate,
    };

    try {
      await IncidentReports(token).create(body);
      toast.success('Incident report successfully created');
      setIsIncidentReportOpen(false);
      setAfectedOrInjured('');
      setType('');
      setAcidentDate(new Date());
      setAcidentTime(moment().format());
      setLocation('');
      setIncidentDescription('');
      setInjuryNatureDescription('');
      setMedicalTreatmentDescription('');
      setAmbulanceCalled(false);
      setWitness('');
      setAction('');
      setTeacherSignature('');
      setTeacherSignatureDate(null);
      setIsSign(false);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'Request error');
      setLoading(false);
    }
  };

  return (
    <>
      <CommonHeader />
      <Container
        className={`mt--8`}
        fluid={!isTablet}
        style={isTablet ? { paddingLeft: '15px', paddingRight: '15px', maxWidth: '100%' } : {}}
      >
        <Row>
          <Col className="order-xl-2" xl="12" style={isMobile || isTablet ? { paddingLeft: 5, paddingRight: 5 } : {}}>
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8" md="6" className="mb-2">
                    <h3 className={`${isMobile || isTablet ? 'mb-2' : 'mb-0'}`}>{name || '-'}</h3>
                  </Col>

                  {isMobile || isTablet ? (
                    <Col xs="4" md="6">
                      <i
                        className={`far fa-file-pdf fa-lg float-right mr-2`}
                        title="Attendance Roster"
                        style={{ cursor: 'pointer' }}
                        onClick={toggleModalAttendanceReport}
                      ></i>
                      <i
                        className={`fas fa-exchange-alt fa-lg float-right mr-2`}
                        title="Tracking Report"
                        style={{ cursor: 'pointer' }}
                        onClick={() => setModalTrackingReport(!modalTrackingReport)}
                      ></i>
                    </Col>
                  ) : (
                    <></>
                  )}
                  <Col
                    className={`text-right ${isMobile || isTablet ? 'ml-n3' : ''}`}
                    xs="12"
                    md={isTablet ? '12' : '6'}
                  >
                    {withBackButton && (
                      <Link to="/class">
                        <Button color="primary" className="float-right">
                          Back
                        </Button>
                      </Link>
                    )}
                    <Button
                      size="sm"
                      color="info"
                      title="Check In"
                      className="float-right"
                      onClick={handleOpenCheckIn}
                      style={{
                        height: '42px',
                        width: '75px',
                        fontSize: isMobile || isTablet ? '0.75rem' : '16px',
                        textAlign: 'center',
                      }}
                    >
                      {isMobile || isTablet ? <>Check In</> : <i className="fa fa-clock"></i>}
                    </Button>
                    <Button
                      size="sm"
                      color="danger"
                      className="float-right d-flex align-items-center"
                      onClick={handleOpenIncidentModal}
                      style={{ height: '42px' }}
                    >
                      {isMobile || isTablet ? (
                        <>
                          Incident
                          <br />
                          Report
                        </>
                      ) : (
                        <>
                          <i className="fas fa-hospital mr-2"></i>
                          Incident <br />
                          Report
                        </>
                      )}
                    </Button>
                    <Link to={`/class-info/${id || classId || ''}`}>
                      <Button
                        size="md"
                        color="info"
                        className="float-right d-flex align-items-center"
                        style={{ height: '42px' }}
                      >
                        Class <br />
                        Info
                      </Button>
                    </Link>
                    {!isMobile && !isTablet ? (
                      <>
                        <i
                          className={`far fa-file-pdf fa-lg mt-3 ${
                            isMobile || isTablet ? 'float-left ml-1' : 'float-right mr-4'
                          }`}
                          title="Attendance Roster"
                          style={{ cursor: 'pointer' }}
                          onClick={toggleModalAttendanceReport}
                        ></i>
                        <i
                          className={`fas fa-exchange-alt fa-lg mt-3 ${
                            isMobile || isTablet ? 'float-left ml-1' : 'float-right mr-4'
                          }`}
                          title="Tracking Report"
                          style={{ cursor: 'pointer' }}
                          onClick={() => setModalTrackingReport(!modalTrackingReport)}
                        ></i>
                      </>
                    ) : (
                      <></>
                    )}
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <ClassSummaryBanner
                  enrolled={kids?.length}
                  capacity={capacity}
                  legalRatioTeachers={legalRatioTeachers}
                  legalRatioChildrens={legalRatioChildrens}
                  teachersCheckedIn={teachersCheckedIn}
                  kidsCheckedIn={kidsCheckedIn}
                />
                <Row className="d-flex">
                  <Col
                    lg={kidsReminderLate.length > 0 || kidsReminderAlmostLate.length > 0 ? 10 : 12}
                    md={(kidsReminderLate.length > 0 || kidsReminderAlmostLate.length > 0) && sideBarIsMini ? 9 : 12}
                    style={isMobile || isTablet ? { paddingLeft: 5, paddingRight: 5 } : {}}
                  >
                    <Card className="bg-secondary shadow">
                      <CardHeader className="bg-white border-0">
                        <Row className="align-items-center">
                          <Col xs="12">
                            <h3 className="mb-0">Students</h3>
                          </Col>
                          {(!!kidsCheckedIn || !!cameras.length) && (
                            <Col lg="12" xl="12" className="mt-4">
                              {!!kidsCheckedIn && (
                                <Button
                                  color="primary"
                                  size="md"
                                  style={{ minWidth: 124, minHeight: 28 }}
                                  onClick={selectAll}
                                >
                                  <i
                                    className={`fas ${kidsSelected.length ? 'fa-minus-circle' : 'fa-check-circle'}`}
                                  ></i>{' '}
                                  {`${kidsSelected.length ? 'Unselect' : 'Select'} all`}
                                </Button>
                              )}
                              {!!cameras.length && (
                                <Button color="warning" size="md" onClick={() => setModalCamera(true)}>
                                  <i className="fas fa-camera"></i> Cameras
                                </Button>
                              )}
                            </Col>
                          )}

                          {loading && (
                            <Col xs="12">
                              <LoaderSpinner />
                            </Col>
                          )}
                        </Row>
                      </CardHeader>
                      <CardBody style={isMobile || isTablet ? { paddingTop: 2 } : {}}>
                        {!!kidsCheckedIn && (
                          <ClassKidsList
                            paddingTop={isMobile || isTablet ? '' : 'pt-2'}
                            title="Checked In"
                            badgeColor="success"
                            kids={kidsCheckedInList}
                            kidsSelected={kidsSelected}
                            selectChild={selectChild}
                            openModal={openModal}
                          />
                        )}
                        {!!kidsMoved && (
                          <ClassKidsList
                            paddingTop={isMobile || isTablet ? 'pt-2' : `pt-${kidsCheckedIn ? '3' : '2'}`}
                            title="Moved"
                            badgeColor="light"
                            kids={kidsMovedOutList}
                            kidsSelected={kidsSelected}
                            collapse={true}
                            selectChild={selectChild}
                            openModal={openModal}
                          />
                        )}
                        {!!kidsNotCheckedIn && (
                          <ClassKidsList
                            paddingTop={isMobile || isTablet ? 'pt-2' : `pt-${kidsCheckedIn ? '3' : '2'}`}
                            title="Checked Out"
                            badgeColor="danger"
                            kids={kids?.filter((k: any) => !k.isCheckedIn)}
                            kidsSelected={kidsSelected}
                            collapse={true}
                            selectChild={selectChild}
                            openModal={openModal}
                          />
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                  {(kidsReminderLate.length > 0 || kidsReminderAlmostLate.length > 0) && (
                    <Col lg="2" md={sideBarIsMini ? '3' : '12'}>
                      <h1 className="text-center">Timer</h1>
                      <h3>Past Due</h3>
                      <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                        {kidsReminderLate.map((kid: any, i: number) => (
                          <ClassManageKidsReminderBox
                            key={`past-due-${i}`}
                            type="danger"
                            kid={kid}
                            onClick={(e: any, id: any) => {
                              setKidsSelected([id]);
                              switch (kid.type) {
                                case 'BATHROOM':
                                  toggleBathroomForm(e);
                                  break;
                                case 'FOOD':
                                  toggleFoodForm(e, 'food');
                                  break;
                              }
                            }}
                          />
                        ))}
                      </div>
                      <h3 className="mt-2">Due Within the Hour</h3>
                      <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                        {kidsReminderAlmostLate.map((kid: any, i: number) => (
                          <ClassManageKidsReminderBox
                            key={`due-tithin-the-hour-${i}`}
                            type="info"
                            kid={kid}
                            onClick={(e: any, id: any) => {
                              setKidsSelected([id]);
                              switch (kid.type) {
                                case 'BATHROOM':
                                  toggleBathroomForm(e);
                                  break;
                                case 'FOOD':
                                  toggleFoodForm(e, 'food');
                                  break;
                              }
                            }}
                          />
                        ))}
                      </div>
                    </Col>
                  )}
                </Row>
                {isMobile ? (
                  <ClassKidsActivityButtonsMobile
                    title="Amazing Connect"
                    selectedAll={selectedAll}
                    checkedOutSelected={checkedOutSelected}
                    confirmActivity={confirmActivity}
                    toggleMovingForm={toggleMovingForm}
                    toggleActivityForm={toggleActivityForm}
                    toggleRemindersForm={toggleRemindersForm}
                    toggleFoodForm={toggleFoodForm}
                    toggleSleepForm={toggleSleepForm}
                    toggleBathroomForm={toggleBathroomForm}
                  />
                ) : (
                  <ClassKidsActivityButtons
                    title="Amazing Connect"
                    selectedAll={selectedAll}
                    checkedOutSelected={checkedOutSelected}
                    confirmActivity={confirmActivity}
                    toggleMovingForm={toggleMovingForm}
                    toggleActivityForm={toggleActivityForm}
                    toggleRemindersForm={toggleRemindersForm}
                    toggleFoodForm={toggleFoodForm}
                    toggleSleepForm={toggleSleepForm}
                    toggleBathroomForm={toggleBathroomForm}
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <ModalDanger
        isModal={isModal}
        modalTitle={modalTitle}
        modalMessage={modalMessage}
        onClose={() => setIsModal(false)}
      />

      <Modal className="modal-dialog-centered" isOpen={activityForm} toggle={(e: any) => toggleActivityForm(e, '')}>
        <div className="modal-header pb-1">
          <h5 className="modal-title">Post an Activity</h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={(e) => toggleActivityForm(e, '')}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body pt-0">
          <ClassActivityForm
            kidsIds={kidsSelected}
            tag={tag}
            isNotPhotoPermissionKids={isNotPhotoPermissionKids}
            onCloseModal={modalActivityChange}
          />
        </div>
      </Modal>

      <Modal className="modal-dialog-centered" isOpen={foodForm} toggle={(e: any) => toggleFoodForm(e, '')}>
        <div className="modal-header pb-1">
          <h5 className="modal-title">Post Meal Activity</h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={(e) => toggleFoodForm(e, '')}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body pt-0">
          <ClassFoodForm
            kidsIds={kidsSelected}
            tag={tag}
            isNotPhotoPermissionKids={isNotPhotoPermissionKids}
            onCloseModal={modalFoodChange}
            onSubmitSetTimer={handleRemoveItem}
          />
        </div>
      </Modal>

      <Modal className="modal-dialog-centered" isOpen={movingForm} toggle={(e: any) => toggleMovingForm(e)}>
        <div className="modal-header">
          <h5 className="modal-title">Moving</h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={(e) => toggleMovingForm(e)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <ClassMovingForm
            kidsIds={kidsSelected}
            schoolId={schoolId}
            classId={id || classId || ''}
            //Não tenho certeza se deve trocar
            onCloseModal={modalMovingChange}
          />
        </div>
      </Modal>

      <Modal className="modal-dialog-centered" isOpen={remindersForm} toggle={(e: any) => toggleRemindersForm(e)}>
        <div className="modal-header pb-1">
          <h5 className="modal-title">Post Reminder Activity</h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={(e) => toggleRemindersForm(e)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body pt-0">
          <ClassRemindersForm kidsIds={kidsSelected} onCloseModal={modalRemindersChange} />
        </div>
      </Modal>

      <Modal className="modal-dialog-centered" isOpen={sleepForm} toggle={(e: any) => toggleSleepForm(e, 'sleep')}>
        <div className="modal-header">
          <h5 className="modal-title">Sleep</h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={(e) => toggleSleepForm(e, 'sleep')}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body pt-0">
          <ClassSleepForm kidsIds={kidsSelected} isSleepingKids={isSleepingKids} onCloseModal={modalSleepChange} />
        </div>
      </Modal>

      <Modal className="modal-dialog-centered" isOpen={bathroomForm} toggle={(e: any) => toggleBathroomForm(e)}>
        <div className="modal-header pb-1">
          <h5 className="modal-title">Bathroom Activity</h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={(e) => toggleBathroomForm(e)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body pt-0">
          <ClassBathroomForm
            kidsIds={kidsSelected}
            onCloseModal={modalBathroomChange}
            onSubmitSetTimer={handleRemoveItem}
          />
        </div>
      </Modal>

      <ClassCheckInModal
        classId={id || classId || ''}
        //TROCOU AQUI TBM
        nameClass={name}
        isOpen={isClockInOpen}
        toggleModal={() => setIsClockInOpen(!isClockInOpen)}
      />

      <Modal
        className="modal-dialog-centered modal-lg"
        size="1000px"
        isOpen={isIncidentReportOpen}
        toggle={() => setIsIncidentReportOpen(false)}
      >
        <div className="modal-header pt-4 pb-0">
          <h6 className="modal-title" id="modal-title-notification">
            Accident / Incident Report - {studentName}
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setIsIncidentReportOpen(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body px-5 pt-2">
          <div className="py-3 text-center">
            <div className="text-justify">
              <Form onSubmit={handleSubmitIncidentReports}>
                <Row>
                  <Col lg="12">
                    <Label>
                      <strong>WHO WAS AFFECTED OR INJURED</strong>
                    </Label>
                    <div className="d-flex align-items-center">
                      <div className="form-check form-check-inline mr-3">
                        <Label className="form-check-label mr-2" htmlFor="child">
                          Child
                        </Label>
                        <Input
                          className="form-check-input"
                          type="radio"
                          name="afected"
                          id="child"
                          value={'Child'}
                          checked={afectedOrInjured === 'Child'}
                          onChange={(e) => setAfectedOrInjured(e.target.value)}
                          required
                        />
                      </div>
                      <div className="form-check form-check-inline mr-3">
                        <Label className="form-check-label mr-2" htmlFor="parent">
                          Parent
                        </Label>
                        <Input
                          className="form-check-input"
                          type="radio"
                          name="afected"
                          id="parent"
                          value={'Parent'}
                          checked={afectedOrInjured === 'Parent'}
                          onChange={(e) => setAfectedOrInjured(e.target.value)}
                          required
                        />
                      </div>
                      <div className="form-check form-check-inline mr-3">
                        <Label className="form-check-label mr-2" htmlFor="staff">
                          Staff
                        </Label>
                        <Input
                          className="form-check-input"
                          type="radio"
                          name="afected"
                          id="staff"
                          value={'Staff'}
                          checked={afectedOrInjured === 'Staff'}
                          onChange={(e) => setAfectedOrInjured(e.target.value)}
                          required
                        />
                      </div>
                      <div className="form-check form-check-inline mr-3">
                        <Label className="form-check-label mr-2" htmlFor="visitor">
                          Visitor
                        </Label>
                        <Input
                          className="form-check-input"
                          type="radio"
                          name="afected"
                          id="visitor"
                          value={'Visitor'}
                          checked={afectedOrInjured === 'Visitor'}
                          onChange={(e) => setAfectedOrInjured(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                  </Col>

                  <Col lg="12">
                    <h3 className="mt-4">
                      <strong>INCIDENT DESCRIPTION</strong>
                    </h3>
                  </Col>

                  <Col lg="12" className="mt-2">
                    <Label>
                      <strong>Type of Incident</strong>
                    </Label>
                    <div className="d-flex flex-wrap align-items-center">
                      <div className="form-check form-check-inline mr-3">
                        <Label className="form-check-label mr-2" htmlFor="accident-injury">
                          Accident/Injury
                        </Label>
                        <Input
                          className="form-check-input"
                          type="radio"
                          name="type"
                          id="accident-injury"
                          value={'Accident/Injury'}
                          checked={type === 'Accident/Injury'}
                          onChange={(e) => setType(e.target.value)}
                          required
                        />
                      </div>
                      <div className="form-check form-check-inline mr-3">
                        <Label className="form-check-label mr-2" htmlFor="illness">
                          Illness
                        </Label>
                        <Input
                          className="form-check-input"
                          type="radio"
                          name="type"
                          id="illness"
                          value={'Illness'}
                          checked={type === 'Illness'}
                          onChange={(e) => setType(e.target.value)}
                          required
                        />
                      </div>
                      <div className="form-check form-check-inline mr-3">
                        <Label className="form-check-label mr-2" htmlFor="observation-incident">
                          Observation/Incident
                        </Label>
                        <Input
                          className="form-check-input"
                          type="radio"
                          name="type"
                          id="observation-incident"
                          value={'Observation/Incident'}
                          checked={type === 'Observation/Incident'}
                          onChange={(e) => setType(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                  </Col>

                  <Col lg="6">
                    <div className="d-flex align-items-center mt-4">
                      <Label className="form-control-label text-left mr-4" htmlFor="incident-date">
                        <strong>Date of Accident/Incident:</strong>
                      </Label>
                      <Input
                        tag={DatePicker}
                        dateFormat="MM/dd/yyyy"
                        onChange={(d: any) => {
                          setAcidentDate(d);
                        }}
                        selected={acidentDate}
                        id="incident-date"
                        required
                      />
                    </div>
                  </Col>
                  <Col lg="4">
                    <div className="d-flex align-items-center mt-4">
                      <Label className="form-control-label text-left mr-4">Time</Label>
                      <TimePicker
                        value={acidentTime}
                        onChangeTime={(time: string) => {
                          setAcidentTime(time);
                        }}
                      />
                    </div>
                  </Col>

                  <Col lg="12" className="mt-4">
                    <Label>
                      <strong>Location of Accident/Incident:</strong>
                    </Label>
                    <div className="d-flex flex-wrap align-items-center">
                      <div className="form-check form-check-inline mr-3">
                        <Label className="form-check-label mr-2" htmlFor="classroom">
                          Classroom
                        </Label>
                        <Input
                          className="form-check-input"
                          type="radio"
                          name="location"
                          id="classroom"
                          value={'Classroom'}
                          checked={location === 'Classroom'}
                          onChange={(e) => setLocation(e.target.value)}
                          required
                        />
                      </div>
                      <div className="form-check form-check-inline mr-3">
                        <Label className="form-check-label mr-2" htmlFor="playground">
                          Playground
                        </Label>
                        <Input
                          className="form-check-input"
                          type="radio"
                          name="location"
                          id="playground"
                          value={'Playground'}
                          checked={location === 'Playground'}
                          onChange={(e) => setLocation(e.target.value)}
                          required
                        />
                      </div>
                      <div className="form-check form-check-inline mr-3">
                        <Label className="form-check-label mr-2" htmlFor="bathroom">
                          Bathroom
                        </Label>
                        <Input
                          className="form-check-input"
                          type="radio"
                          name="location"
                          id="bathroom"
                          value={'Bathroom'}
                          checked={location === 'Bathroom'}
                          onChange={(e) => setLocation(e.target.value)}
                          required
                        />
                      </div>
                      <div className="form-check form-check-inline mr-3">
                        <Label className="form-check-label mr-2" htmlFor="other">
                          Other
                        </Label>
                        <Input
                          className="form-check-input"
                          type="radio"
                          name="location"
                          id="other"
                          value={'Other'}
                          checked={location === 'Other'}
                          onChange={(e) => setLocation(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                  </Col>

                  <Col lg="12" className="mt-4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-description-1">
                        <strong>Describe Accident/Incident:</strong>
                      </label>
                      <textarea
                        className="form-control"
                        id="input-description-1"
                        placeholder="Description"
                        value={incidentDescription}
                        onChange={(e) => {
                          setIncidentDescription(e.target.value);
                        }}
                        required
                      />
                    </FormGroup>
                  </Col>

                  <Col lg="12">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-description-2">
                        <strong>Describe Nature of Injury/Illness:</strong>
                      </label>
                      <textarea
                        className="form-control"
                        id="input-description-2"
                        placeholder="Description"
                        value={injuryNatureDescription}
                        onChange={(e) => {
                          setInjuryNatureDescription(e.target.value);
                        }}
                        required
                      />
                    </FormGroup>
                  </Col>

                  <Col lg="12" className="mb-0">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-description-3">
                        <strong>Describe Medical Treatment/First Aid:</strong>
                      </label>
                      <textarea
                        className="form-control"
                        id="input-description-3"
                        placeholder="Description"
                        value={medicalTreatmentDescription}
                        onChange={(e) => {
                          setMedicalTreatmentDescription(e.target.value);
                        }}
                        required
                      />
                    </FormGroup>
                  </Col>

                  <Col lg="12" className="mt-4">
                    <Label>
                      <strong>Ambulance Called:</strong>
                    </Label>
                    <div className="d-flex flex-wrap align-items-center">
                      <div className="form-check form-check-inline mr-3">
                        <Label className="form-check-label mr-2" htmlFor="ambulance-yes">
                          Yes
                        </Label>
                        <Input
                          className="form-check-input"
                          type="radio"
                          name="radio1"
                          id="ambulance-yes"
                          checked={ambulanceCalled === true}
                          onChange={() => setAmbulanceCalled(true)}
                        />
                      </div>
                      <div className="form-check form-check-inline mr-3">
                        <Label className="form-check-label mr-2" htmlFor="ambulance-no">
                          No
                        </Label>
                        <Input
                          className="form-check-input"
                          type="radio"
                          name="radio1"
                          id="ambulance-no"
                          checked={ambulanceCalled === false}
                          onChange={() => setAmbulanceCalled(false)}
                          required
                        />
                      </div>
                    </div>
                  </Col>

                  <Col lg="12" className="mt-4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-description-4">
                        <strong>Witness(es) to Accident/Incident</strong>
                      </label>
                      <textarea
                        className="form-control"
                        id="input-description-4"
                        placeholder="Answer"
                        value={witness}
                        onChange={(e) => {
                          setWitness(e.target.value);
                        }}
                        required
                      />
                    </FormGroup>
                  </Col>

                  <Col lg="12" className="mt-4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-description-5">
                        <strong>What Action Was Taken & by Whom?</strong>
                      </label>
                      <textarea
                        className="form-control"
                        id="input-description-5"
                        placeholder="Answer"
                        value={action}
                        onChange={(e) => {
                          setAction(e.target.value);
                        }}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12" className="mt-4">
                    <Label>
                      <strong>Signature:</strong>
                    </Label>
                    <div className="d-flex flex-wrap align-items-center">
                      <div className="form-check form-check-inline mr-3">
                        <Input
                          className="form-check-input"
                          type="checkbox"
                          id="to-sign"
                          checked={isSign}
                          onChange={() => {
                            if (!isSign) setTeacherSignatureDate(new Date());
                            setIsSign(!isSign);
                          }}
                        />
                        <Label className="form-check-label mr-2" htmlFor="to-sign">
                          I hereby acknowledge and agree that my electronic signature on this document is the legal
                          equivalent of my handwritten signature.
                        </Label>
                      </div>
                    </div>
                  </Col>

                  {isSign && (
                    <Col lg="12" className="mt-4">
                      <FormGroup>
                        <Label htmlFor="name-for-signature">
                          <strong>Enter your name for signature:</strong>
                        </Label>
                        <Input
                          className="form-control"
                          type="text"
                          id="name-for-signature"
                          style={{ fontFamily: 'Dancing Script' }}
                          value={teacherSignature}
                          onChange={(e) => {
                            setTeacherSignature(e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  )}
                </Row>

                <div className="d-flex justify-content-end">
                  <Button className="my-4" color="primary" type="submit">
                    Submit
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button className="text-white ml-auto" color="link" data-dismiss="modal" type="button" onClick={() => {}}>
            Close
          </Button>
        </div>
      </Modal>
      <ClassCameras isOpen={modalCamera} cameras={cameras} toggleModal={() => setModalCamera(!modalCamera)} />
      <ClassAttendanceReportModal
        isOpen={modalAttendanceReport}
        classId={id || classId || ''}
        toggleModal={() => setModalAttendanceReport(!modalAttendanceReport)}
      />
      <ClassTrackingReportModal
        isOpen={modalTrackingReport}
        classId={id || classId || ''}
        toggleModal={() => setModalTrackingReport(!modalTrackingReport)}
      />
    </>
  );
};

export default Class;
