import http from '../helpers/http';

const EmployeeTitles = (token: string) => {
  const headers = {
    'x-access-token': token,
  };

  return {
    list: async () => {
      const result = await http.get(`/employee-titles`, {
        headers,
      });
      return result.data;
    },
    create: async (body: { franchiseeId: string; name: string }) => {
      const result = await http.post(`/employee-titles`, body, { headers });
      return result.data;
    },
    delete: async (id: string) => {
      const result = await http.delete(`/employee-titles/${id}`, {
        headers,
      });
      return result.data;
    },
  };
};

export default EmployeeTitles;
