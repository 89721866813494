import { Card, CardHeader, CardFooter, CardText, CardBody, Container, Row, Col } from 'reactstrap';
import { useContext, useState, useEffect } from 'react';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import SupportService from '../../../services/Support';
import dateDistance from '../../../helpers/dateDistance';

type SupportReplyListProps = {
  refresh: boolean;
};

const SupportReplyList = ({ refresh }: SupportReplyListProps) => {
  const { token }: any = useContext(AuthContext);
  const [data, setData] = useState([] as any[]);
  const { id } = useParams<{ id: string }>();

  const getSupportReplyById = async () => {
    try {
      const result = await SupportService(token).listReply(id);
      const dataResult: any = result.data;
      setData(dataResult);
    } catch (e: any) {
      toast.error('Error: ' + id);
    }
  };

  useEffect(() => {
    getSupportReplyById();
  }, []);

  useEffect(() => {
    getSupportReplyById();
  }, [refresh]);

  return (
    <>
      <Container fluid>
        {data.map((r, index) => {
          return (
            <Row className="align-items-center mt-3" key={`item-${index}`}>
              <Col className="order-xl-2" xl="12">
                <Card>
                  <CardHeader>
                    {r?.user?.firstName || ''} {r?.user?.lastName || ''}
                  </CardHeader>
                  <CardBody>
                    <CardText>{r.description}</CardText>
                  </CardBody>
                  <CardFooter>{dateDistance(r.createdAt)}</CardFooter>
                </Card>
              </Col>
            </Row>
          );
        })}
      </Container>
    </>
  );
};

export default SupportReplyList;
