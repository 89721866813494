export const validStatus = {
  NEW: { value: 'NEW', color: 'info' },
  ACTIVE: { value: 'ACTIVE', color: 'success' },
  INACTIVE: { value: 'INACTIVE', color: 'warning' },
  BLOCKED: { value: 'BLOCKED', color: 'danger' },
  PENDING_ACCEPTANCE: { value: 'PENDING', color: 'danger' },
};

export enum statusEnum {
  NEW = 'NEW',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  BLOCKED = 'BLOCKED',
  PENDING_ACCEPTANCE = 'PENDING_ACCEPTANCE',
}

export interface UserInterface {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  status: string;
  statusDescription?: string;
  roles: any[];
  language: string;
  groupId: string;
  birthday: string;
  createdAt: Date;
  updatedAt: Date;
  picture: string;
}

export default UserInterface;
