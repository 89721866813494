/* eslint-disable max-lines */
import { Button, Card, CardHeader, CardBody, FormGroup, Form, Input, Row, Col } from 'reactstrap';
import { toast } from 'react-toastify';
import { useContext, useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import MessagingService from '../../../services/Messaging';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import isParent from '../../../helpers/isParent';
import UserSelect from '../../../components/Core/UserSelect';

type props = {
  setNewTypeDisplay: (type: string) => void;
};

const MessagingCreate = ({ setNewTypeDisplay }: props) => {
  const { id } = useParams<{ id: string }>();
  const fileRef = useRef(null as any);
  const pageTitle = id ? 'Update' : 'Create';
  const { token, currentSchool, user }: any = useContext(AuthContext);
  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  const [schoolId, setSchoolId] = useState('');
  const [sendTo, setSendTo] = useState('');
  const [groupMessage, setGroupMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [attachment, setAttachment] = useState(null as any);

  const [selectList, setSelectList] = useState([{}]);
  const [selectListStaff, setSelectListStaff] = useState([{}]);
  const [selectListClass, setSelectListClass] = useState([{}]);
  const [kidsId, setKidsId] = useState('');
  const [levelOfConcern, setLevelOfConcern] = useState('');

  const [userIdMessage, setUserIdMessage] = useState<string | []>('');
  const [entireClassroomId, setEntireClassroomId] = useState('');

  const setTypeDisplay = (type: string) => {
    setNewTypeDisplay(type);
  };

  const getById = async () => {
    try {
      const result = await MessagingService(token || '').show(id);
      if (result?.data) {
        setSendTo(result?.data?.sendTo || '');
        setMessage(result?.data?.message || '');
      }
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'Invalid Messaging ID');
    }
  };

  const listSelect = async () => {
    try {
      const result = await MessagingService(token || '').listSelect();
      setSelectList(result || []);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No options found');
    }
  };

  const checkType = async (sendTo: string) => {
    try {
      if (sendTo == `Child's Parents`) {
        setGroupMessage(false);
        setSelectListStaff([]);
        setSelectListClass([]);
        setEntireClassroomId('');
      } else if (sendTo == `Staff Class`) {
        setGroupMessage(false);
        setSelectListStaff([]);
        setSelectListClass([]);
        setEntireClassroomId('');
        setKidsId('');

        await listSelectStaff();
      } else if (sendTo == `Entire Classroom`) {
        setGroupMessage(true);
        setSelectListStaff([]);
        setSelectListClass([]);
        setUserIdMessage('');
        setKidsId('');

        await listSelectClass();
      } else if (sendTo == `All Parents`) {
        setGroupMessage(true);
        setSelectListStaff([]);
        setSelectListClass([]);
        setEntireClassroomId('');
        setUserIdMessage('');
        setKidsId('');
      } else if (sendTo == `All Staff`) {
        setGroupMessage(true);
        setSelectListStaff([]);
        setSelectListClass([]);
        setEntireClassroomId('');
        setUserIdMessage('');
        setKidsId('');
      } else if (sendTo == `Entire School (Staff + Parents)`) {
        setGroupMessage(true);
        setSelectListStaff([]);
        setSelectListClass([]);
        setEntireClassroomId('');
        setUserIdMessage('');
        setKidsId('');
      }
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No options found');
    }
  };

  const listSelectStaff = async () => {
    try {
      const result = await MessagingService(token || '').listSelectStaff(currentSchool.value || '');
      setSelectListStaff(result.data || []);

      //setSendTo(result[0].name);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No options found');
    }
  };

  const listSelectClass = async () => {
    try {
      const result = await MessagingService(token || '').listSelectClass(currentSchool.value || '');
      setSelectListClass(result.data || []);
      //setSendTo(result[0].name);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No options found');
    }
  };

  const showOpenFileDialog = () => {
    if (isDisabled) {
      return;
    }
    if (fileRef?.current?.click) {
      fileRef.current.click();
    }
  };

  const onChangeFile = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];
    setAttachment(file);
  };

  useEffect(() => {
    if (id) {
      getById();
    }
    listSelect();
  }, []);

  useEffect(() => {
    checkType(sendTo);
  }, [sendTo]);

  useEffect(() => {
    setSchoolId(currentSchool.value || '');
    //setGroupMessage(true);
  }, [currentSchool]);

  useEffect(() => {
    if (levelOfConcern === 'concern') {
      setSendTo('Management');
    }
  }, [levelOfConcern]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);
    setIsDisabled(true);

    const body = {
      schoolId,
      sendTo,
      groupMessage,
      message,
      entireClassroomId,
      levelOfConcern,
      related: {
        users: userIdMessage,
        kids: [kidsId],
      },
    };

    try {
      const result = await MessagingService(token || '').create(body);
      if (attachment) {
        const formData = new FormData();
        formData.append('attachment', attachment as any);
        await MessagingService(token).attachment(result?.data?.id, formData);
      }
      toast.success(`${id ? 'Updated' : 'Created'} successfully!`);
      if (result?.data?.id) {
        //history.push(`/messaging`);
        setTypeDisplay(result?.data?.messagingId);
      }
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Create error!');
      toast.error(message);
    }

    setLoading(false);
    setIsDisabled(false);
  };

  const getLevelOfConcernOptions = () => {
    let options: any[] = [];
    options.push('');
    options = [...options, ...['General Question', 'Reminder', 'Concern']];
    return options?.map((filter: string, index: number) => {
      return (
        <option value={filter.toLowerCase().replaceAll(' ', '-')} key={`filter-button-${index}`}>
          {filter}
        </option>
      );
    });
  };

  return (
    <>
      <Row>
        <Col className="order-xl-2" xl="12">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="12">
                  <h3 className="mb-0">{`${pageTitle} Messaging`}</h3>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Form onSubmit={handleSubmit}>
                <div className="pl-lg-4">
                  <Row>
                    <Col>
                      <FormGroup>
                        <label className="form-control-label" htmlFor="input-franchisee-id">
                          Send to
                        </label>
                        <Input type="select" onChange={(e) => setSendTo(e.target.value)} required value={sendTo}>
                          <option value=""></option>
                          Select
                          {selectList?.map((filter: any, index: number) => {
                            return (
                              <option value={filter.name} key={`filter-button-${index}`}>
                                {filter.name}
                              </option>
                            );
                          })}
                        </Input>
                      </FormGroup>
                    </Col>
                    {isParent(user) && (
                      <Col>
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-franchisee-id">
                            Level Of Concern
                          </label>
                          <Input type="select" onChange={(e) => setLevelOfConcern(e.target.value)} required>
                            {getLevelOfConcernOptions()}
                          </Input>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>

                  {sendTo == `Staff Class` && (
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-franchisee-id">
                            Staff
                          </label>
                          <Input type="select" onChange={(e) => setUserIdMessage(e.target.value)} required>
                            <option value=""></option>
                            Select
                            {selectListStaff?.map((filter: any, index: number) => {
                              return (
                                <option value={filter.id} key={`filter-button-${index}`}>
                                  {filter.lastName}, {filter.firstName}
                                </option>
                              );
                            })}
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                  )}

                  {sendTo == `Child's Parents` && (
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-franchisee-id">
                            Kids
                          </label>
                          <UserSelect
                            userType={'kids'}
                            onChange={(val: any) => {
                              setKidsId(val.value);
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  )}

                  {kidsId && (
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-franchisee-id">
                            Parents
                          </label>
                          <UserSelect
                            userType={'parents'}
                            onChange={(val: any) => {
                              setUserIdMessage(val.map((item: any) => item.value));
                            }}
                            filterId={kidsId}
                            isMulti={true}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  )}

                  {sendTo == `Entire Classroom` && (
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-franchisee-id">
                            Class
                          </label>
                          <Input type="select" onChange={(e) => setEntireClassroomId(e.target.value)} required>
                            <option value=""></option>
                            Select
                            {selectListClass?.map((filter: any, index: number) => {
                              return (
                                <option value={filter.id} key={`filter-button-${index}`}>
                                  {filter.name}
                                </option>
                              );
                            })}
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                  )}

                  <Row style={{ height: '240px' }}>
                    <Col className="order-xl-2" xl="12">
                      <FormGroup>
                        <label className="form-control-label">Message</label>
                        <ReactQuill value={message} onChange={setMessage} style={{ height: '150px' }} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12">
                      <Button
                        className="btn-icon btn-sm"
                        color="secondary"
                        type="button"
                        id="input-attach"
                        onClick={showOpenFileDialog}
                        style={{ marginBottom: '2px' }}
                      >
                        <span className="btn-inner--icon pt-1" style={{ marginRight: '1px' }}>
                          <i className="fas fa-paperclip" style={{ top: '0' }} />
                        </span>
                      </Button>
                      <span>{attachment?.name || 'Select a file'}</span>
                      <input
                        id="img-attach"
                        name="attach"
                        type="file"
                        accept=".png,.jpg,.jpeg,.pdf"
                        style={{ display: 'none' }}
                        ref={fileRef}
                        disabled={isDisabled}
                        onChange={onChangeFile}
                      />
                    </Col>
                  </Row>
                </div>

                <div className="pl-lg-4">
                  <Button className="my-4" color="primary" type="submit" disabled={isDisabled}>
                    Save
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default MessagingCreate;
