import {
  Card,
  CardHeader,
  Button,
  CardText,
  Container,
  Row,
  Col,
  CardBody,
  Modal,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { useContext, useState, useEffect } from 'react';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import KidsParents from '../../../services/KidsParents';
import KidsParentsInterface from '../../../interfaces/KidsParentsInterface';
import confirmAlert from '../../../helpers/confirmAlert';
import KidsParentsForm from '../Kids/KidsParentsForm';

type KidsIdProps = {
  kidsId: string;
  newContactCreated?: any;
  setIsContactOpen: (isOpen: boolean) => void;
};

const KidsParentsList = ({ kidsId, setIsContactOpen, newContactCreated }: KidsIdProps) => {
  const { token }: any = useContext(AuthContext);
  const [data, setData] = useState([] as any[]);
  const [kidsParent, setKidsParent] = useState({} as any);
  const { id } = useParams<{ id: string }>();

  const [kidsParentsForm, setKidsParentsForm] = useState(false);

  const toggleKidsParentsForm = () => {
    setKidsParentsForm(!kidsParentsForm);
  };

  const modalChange = () => {
    setKidsParentsForm(!kidsParentsForm);
    if (kidsParentsForm == true) {
      getKidsParentsByKidsId();
      setKidsParent({});
    }
    return false;
  };

  const editItem = (item: any) => {
    setKidsParent(item);
    toggleKidsParentsForm();
  };

  const deleteItemAlert = async (id: string) => {
    return confirmAlert({ title: 'Delete', messageType: 'DELETE', id, onClickYes: () => deleteItem(id) });
  };

  const deleteItem = async (id: string) => {
    try {
      await KidsParents(token || '').delete(kidsId, id);
      toast.success(`Deleted successfully!`);
      getKidsParentsByKidsId();
    } catch (e: any) {
      const message = String(e?.response?.data?.message || `Error deleting item: ${id}`);
      return toast.error(message);
    }
  };

  const getKidsParentsByKidsId = async () => {
    try {
      const result = await KidsParents(token || '').list(kidsId);

      const data: KidsParentsInterface[] = result.data;

      setData(data);
    } catch (e: any) {
      toast.error('Error: ' + id);
    }
  };
  const getClassNameByType = (type: string) => {
    let className = 'text-white ';
    switch (type) {
      case 'Guardian':
        className += 'bg-info';
        break;
      case 'Parent':
        className += 'bg-success bg-gradient';
        break;
      case 'Pickup Authorized':
        className += 'bg-warning';
        break;
    }

    return className;
  };

  useEffect(() => {
    getKidsParentsByKidsId();
  }, [kidsId]);

  return (
    <>
      <hr className="my-4" />
      <Row>
        <Col>
          <h6 className="heading-small text-muted mb-4">Parents/Contacts</h6>
        </Col>
        <Col>
          <Button
            size="sm"
            className="float-right"
            onClick={() => {
              setKidsParent({});
              toggleKidsParentsForm();
            }}
          >
            + Parents/Contacts
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <ul style={{ listStyle: 'none', padding: 0, fontSize: '0.7rem' }}>
            <li style={{ display: 'inline-block', marginRight: '10px' }}>
              <div className="bg-info" style={{ float: 'left', width: '16.8px' }}>
                &nbsp;
              </div>
              &nbsp; Guardian
            </li>
            <li style={{ display: 'inline-block', marginRight: '10px' }}>
              <div className="bg-success bg-gradient" style={{ float: 'left', width: '16.8px' }}>
                &nbsp;
              </div>
              &nbsp; Parent
            </li>
            <li style={{ display: 'inline-block', marginRight: '10px' }}>
              <div className="bg-warning" style={{ float: 'left', width: '16.8px' }}>
                &nbsp;
              </div>
              &nbsp; Pickup Authorized
            </li>
          </ul>
        </Col>
      </Row>

      <Container fluid>
        {data.map((r, index) => {
          return (
            <Row className="align-items-center mt-3" key={`item-${index}`}>
              <Col className="order-xl-2" xl="12">
                <Card>
                  <CardHeader
                    style={{ color: '#8898aa', padding: '10px 15px' }}
                    className={getClassNameByType(r?.title)}
                  >
                    {r?.user?.lastName || ''}, {r?.user?.firstName || ''}{' '}
                    <UncontrolledDropdown style={{ float: 'right' }}>
                      <DropdownToggle
                        href="#a"
                        role="button"
                        size="sm"
                        color="primary"
                        onClick={(e) => e.preventDefault()}
                      >
                        Actions
                      </DropdownToggle>

                      <DropdownMenu className="dropdown-menu-arrow" right>
                        <DropdownItem
                          onClick={(e) => {
                            e.preventDefault();
                            editItem(r);
                          }}
                        >
                          <i className="fas fa-edit text-primary"></i> Edit
                        </DropdownItem>
                        <DropdownItem
                          onClick={(e) => {
                            e.preventDefault();
                            deleteItemAlert(r.id);
                          }}
                        >
                          <i className="far fa-trash-alt text-danger"></i> Delete
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </CardHeader>
                  <CardBody style={{ padding: '10px 15px' }}>
                    <CardText>
                      <Row>
                        <Col lg="6">
                          <strong>Type:</strong> {r?.title || ''}
                        </Col>
                        <Col lg="6">
                          <strong>Email:</strong> {r?.user?.email || ''}
                        </Col>
                        <Col lg="6">
                          <strong>Phone:</strong> {r?.user?.phone || '-'}
                        </Col>
                        <Col lg="6">
                          <strong>Work Phone:</strong> {r?.user?.workPhone || '-'}
                        </Col>
                        {r?.isPaying && (
                          <Col lg="12">
                            <strong>Billing Percentage:</strong> {`${Number(r?.payPercent || 0).toFixed(2)}%`}
                          </Col>
                        )}
                      </Row>
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          );
        })}
      </Container>

      <Modal className="modal-dialog-centered" isOpen={kidsParentsForm} toggle={() => toggleKidsParentsForm()}>
        <div className="modal-header">
          <h5 className="modal-title" id="Label">
            New Parents/Contacts
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => toggleKidsParentsForm()}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <KidsParentsForm
            kidsId={kidsId}
            onModalStatus={modalChange}
            setIsContactOpen={setIsContactOpen}
            kidsParent={kidsParent}
            newContactCreated={newContactCreated}
          />
        </div>
      </Modal>
    </>
  );
};

export default KidsParentsList;
