/* eslint-disable max-lines */
import { Button, Form, Row, Col } from 'reactstrap';
import { toast } from 'react-toastify';
import { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Context as AuthContext } from '../../../../contexts/AuthContext';
import UserService from '../../../../services/User';
import NotificationPermissionsList from '../../../../views/pages/Users/TabContent/NotificationPermissionsList';

const NotificationTab = () => {
  const { id: userId } = useParams<{ id: string }>();
  const { token }: any = useContext(AuthContext);

  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  const [notificationPermissions, setNotificationPermissions] = useState<any>({});

  useEffect(() => {
    getById();
  }, []);

  const getById = async () => {
    setIsDisabled(true);
    try {
      const result = await UserService(token).show(userId);
      if (result?.data) {
        setNotificationPermissions(
          Object.keys(result?.data?.configs?.notificationPermissions || {}).length === 0
            ? notificationPermissions
            : result?.data.configs.notificationPermissions,
        );
      }
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'Invalid user ID');
    } finally {
      setIsDisabled(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);
    setIsDisabled(true);

    const body = {
      notificationPermissions,
    };

    try {
      await UserService(token).updateConfigs(userId, body);
      toast.success('Updated successfully!');
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Update error!');
      toast.error(message);
    } finally {
      setIsDisabled(false);
    }

    setLoading(false);
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <div className="pl-lg-4 mt-3">
          <Row>
            <Col lg="12">
              <NotificationPermissionsList
                userId={userId}
                setNotifications={(e: any) => setNotificationPermissions(e)}
              />
            </Col>
          </Row>
          <Button className="my-4" color="primary" type="submit" disabled={isDisabled}>
            Save
          </Button>
        </div>
      </Form>
    </>
  );
};

export default NotificationTab;
