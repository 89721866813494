/* eslint-disable max-lines */
import { Row } from 'reactstrap';
import { toast } from 'react-toastify';
import { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Context as AuthContext } from '../../../../contexts/AuthContext';
import UserService from '../../../../services/User';
import ChildDetailedTable from '../ChildDetailedTable';

const ChildrenTab = () => {
  const { id: userId } = useParams<{ id: string }>();
  const { token }: any = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [kidsList, setKidsList] = useState([]);

  useEffect(() => {
    getByUserId();
  }, []);

  useEffect(() => {
    getByUserId();
  }, [userId]);

  const getByUserId = async () => {
    setLoading(true);
    try {
      const result = await UserService(token).listKids(userId);
      setKidsList(result?.data || []);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'Invalid user ID');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {!loading && !kidsList?.length && <span>We couldn't find any child linked with this user.</span>}
      <Row>
        {kidsList?.map((k: any, index: number) => (
          <ChildDetailedTable
            key={`child-${index}`}
            id={k?.kidsId || ''}
            pictureUrl={k?.kids?.picture || ''}
            name={`${k?.kids?.firstName || '-'}${k?.kids?.lastName ? ` ${k?.kids?.lastName}` : ''}`}
            enrolled={!!k?.kids?.enrollment?.id}
            checked={k?.kids?.isCheckedIn}
            className={k?.kids?.enrollment?.class?.name || ''}
            schoolName={k?.kids?.enrollment?.school?.name || ''}
          />
        ))}
      </Row>
    </>
  );
};

export default ChildrenTab;
