const isParent = (user: any) => {
  if (
    user?.roles?.includes('user') &&
    ['Parents', 'AuthorizedPickup', 'Guardian'].includes(user?.groupPermission?.name)
  ) {
    return true;
  }
  return false;
};

export default isParent;
