import http from '../helpers/http';

const KidsActivity = (token: string) => {
  const headers = {
    'x-access-token': token,
  };

  return {
    checkin: async (kidsId: string, notes: string = '', time: string = '') => {
      const result = await http.patch(
        `/kids/${kidsId}/checkin`,
        { notes, time },
        {
          headers,
        },
      );
      return result.data;
    },
    checkout: async (kidsId: string, notes: string = '', time: string = '') => {
      const result = await http.patch(
        `/kids/${kidsId}/checkout`,
        { notes, time },
        {
          headers,
        },
      );
      return result.data;
    },
    listSelect: async () => {
      const result = await http.get(`/activity/select`, {
        headers,
      });
      return result.data;
    },
    send: async (kidsId: string, activityId: string, notes: string = '', time: string = '', options: any = {}) => {
      const result = await http.patch(
        `/kids/${kidsId}/activity/${activityId}`,
        { notes, time, options },
        {
          headers,
        },
      );
      return result.data;
    },
    sendMoving: async (kidsId: string, currentClassId: string, classId: string, time: string = '') => {
      const result = await http.patch(
        `/kids/${kidsId}/moving/${classId}`,
        { notes: '', time, originRoomId: currentClassId },
        {
          headers,
        },
      );
      return result.data;
    },
    sendReminder: async (kidsId: string, notes: string = '', time: string = '', options: any = {}) => {
      const result = await http.patch(
        `/kids/${kidsId}/reminders`,
        { notes, time, options },
        {
          headers,
        },
      );
      return result.data;
    },
    sendFood: async (kidsId: string, notes: string = '', time: string = '', options: any = {}) => {
      const result = await http.patch(
        `/kids/${kidsId}/food`,
        { notes, time, options },
        {
          headers,
        },
      );
      return result.data;
    },
    sendSleep: async (
      kidsId: string,
      activityType: string = '',
      notes: string = '',
      time: string = '',
      options: any = {},
    ) => {
      const result = await http.patch(
        `/kids/${kidsId}/sleep/${activityType}`,
        { notes, time, options },
        {
          headers,
        },
      );
      return result.data;
    },
    sendBathroom: async (kidsId: string, notes: string = '', time: string = '', options: any = {}) => {
      const result = await http.patch(
        `/kids/${kidsId}/bathroom`,
        { notes, time, options },
        {
          headers,
        },
      );
      return result.data;
    },
    pictures: async (activityId: string, body: FormData) => {
      const headers = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-access-token': token,
        },
      };
      const result = await http.patch(`/activity/${activityId}/pictures`, body, headers);
      return result.data;
    },
    createActivity: async (name: string, type: string) => {
      const keySplited = name.toUpperCase().split(' ');
      const key = keySplited.join('_');
      const result = await http.post(
        `/activity/create`,
        { name, key, tags: [type] },
        {
          headers,
        },
      );
      return result.data;
    },
    deleteActivity: async (id: string) => {
      const result = await http.delete(`/activity/${id}`, {
        headers,
      });
      return result.data;
    },
    deleteActivityKidsRelation: async (id: string) => {
      const result = await http.delete(`/activityKids/${id}`, {
        headers,
      });
      return result.data;
    },
  };
};

export default KidsActivity;
