import { Button } from 'reactstrap';
import { useRef, useState } from 'react';
import Cropper from 'react-easy-crop';
import { Area } from 'react-easy-crop/types';

interface UploadAndCropImageProps {
  image: string;
  cropImageProps?: {
    width: number;
    height: number;
    type: string;
  };
  onChange: any;
}

const UploadAndCropImage = ({
  image,
  cropImageProps = { width: 200, height: 200, type: 'image/png' },
  onChange,
}: UploadAndCropImageProps) => {
  const imageRef = useRef(null as any);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [uploadedImg, setUploadedImg] = useState('');
  const [croppedArea, setCroppedArea] = useState<Area>();
  const [originalFile, setOriginalFile] = useState<{ name: string }>();

  const handleChangePicClick = () => {
    if (imageRef?.current?.click) {
      imageRef.current.click();
    }
  };

  const onCropComplete = (_: Area, croppedAreaPixels: Area) => {
    if (JSON.stringify(croppedAreaPixels) === JSON.stringify(croppedArea)) {
      return;
    }
    setCroppedArea(croppedAreaPixels);
  };

  const onChangeFile = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];
    setOriginalFile(file);
    setUploadedImg(URL.createObjectURL(file));
  };

  const createImage = async (url: string): Promise<any> => {
    const image: any = new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener('load', () => resolve(image));
      image.addEventListener('error', (error) => reject(error));
      image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
      image.src = url;
    });
    return image;
  };

  const clickSaveCropedImage = async () => {
    if (!croppedArea || !originalFile) {
      return;
    }
    const image: CanvasImageSource = await createImage(uploadedImg);
    const canvas = document.createElement('canvas');
    canvas.width = cropImageProps.width;
    canvas.height = cropImageProps.height;
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      return;
    }
    ctx.drawImage(
      image,
      croppedArea.x,
      croppedArea.y,
      croppedArea.width,
      croppedArea.height,
      0,
      0,
      cropImageProps.width,
      cropImageProps.height,
    );
    const imageData = canvas.toDataURL(cropImageProps.type, 0.8);
    canvas.toBlob((blob) => {
      if (blob === null) {
        return;
      }
      onChange(new File([blob], originalFile.name, { type: cropImageProps.type }), imageData);
    }, 'image/jpeg');
  };
  return (
    <>
      {uploadedImg !== '' && (
        <>
          <div className="row" style={{ position: 'relative', minHeight: '200px' }}>
            <Cropper
              image={uploadedImg}
              crop={crop}
              zoom={zoom}
              cropShape="round"
              aspect={200 / 200}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
          <div className="row mt-2">
            <div className="col" style={{ textAlign: 'center' }}>
              <Button size="sm" color="primary" onClick={() => clickSaveCropedImage()}>
                Save
              </Button>
              <Button size="sm" color="secondary" onClick={() => setUploadedImg('')}>
                Cancel
              </Button>
            </div>
          </div>
        </>
      )}

      {uploadedImg === '' && (
        <>
          <input
            id="img-picture"
            name="picture"
            type="file"
            accept=".png,.jpg,.jpeg"
            style={{ display: 'none' }}
            ref={imageRef}
            onChange={onChangeFile}
          />
          <div className="row">
            <div className="col" style={{ textAlign: 'center' }}>
              <span className="avatar rounded-circle" style={{ width: '200px', height: '200px' }}>
                <img src={image} style={{ width: '100%', height: '100%' }} />
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col" style={{ textAlign: 'center' }}>
              <Button size="sm" color="primary" onClick={handleChangePicClick}>
                Change Picture
              </Button>
              {/* <Button size="sm" color="secondary">
                Remove Picture
              </Button> */}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default UploadAndCropImage;
