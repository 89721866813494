import { Row, Col, Card } from 'reactstrap';

type SuccessPageProps = {
  firstName: string;
  lastName: string;
  schoolName: string;
};

const styles = {
  cardSuccess: {
    background: 'white',
    padding: '60px',
    display: 'inline-block',
    margin: '0 auto',
  },
  divCheckmark: {
    borderRadius: '200px',
    height: '200px',
    width: '200px',
    background: '#F8FAF5',
    margin: '0 auto',
  },
  checkmark: {
    color: '#9ABC66',
    fontSize: '100px',
    lineHeight: '200px',
    marginLeft: '-15px',
  },
  messageTitle: {
    color: '#88B04B',
    fontWeight: 900,
    fontSize: '40px',
    marginBottom: '10px',
  },
  message: {
    color: '#404F5E',
    fontSize: '20px',
    margin: 0,
  },
};
const SuccessPage = ({ firstName, lastName, schoolName }: SuccessPageProps) => {
  return (
    <>
      <Row>
        <Col md="2"></Col>
        <Col md="8">
          <Card className="shadow mt-5" style={{ textAlign: 'center' }}>
            <div className="card-success" style={styles.cardSuccess}>
              <div style={styles.divCheckmark}>
                <i className="checkmark" style={styles.checkmark}>
                  ✓
                </i>
              </div>
              <h1 style={styles.messageTitle}>Success</h1>
              <p style={styles.message}>
                <strong>
                  {firstName} {lastName}
                </strong>
              </p>
              <p style={styles.message}>
                Thank you for choosing <strong>{schoolName}</strong>.
                <br /> You will receive an email when a enroll becomes available.
              </p>
            </div>
          </Card>
        </Col>
        <Col md="2"></Col>
      </Row>
    </>
  );
};

export default SuccessPage;
