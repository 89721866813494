import { Modal, Row, Col, Button, FormGroup, Input } from 'reactstrap';
import { useContext, useState, useEffect } from 'react';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import ClassReportService from '../../../services/ClassReport';
import { toast } from 'react-toastify';
import moment from 'moment';
import InputMask from 'react-input-mask';
import FileDownload from 'js-file-download';

type ClassReportModalProps = {
  isOpen: boolean;
  classId: string;
  toggleModal: (e?: any) => void;
};

const ClassReportModal = ({ isOpen, classId, toggleModal }: ClassReportModalProps) => {
  const { token }: any = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [weekInterval, setWeekInterval] = useState('');
  const [weekList, setWeekList] = useState([] as { value: string; label: string }[]);
  const [monthInterval, setMonthInterval] = useState('');

  useEffect(() => {
    if (isOpen) {
      let date = moment();
      setMonthInterval(date.format('MM/YYYY'));
      const weeks: { value: string; label: string }[] = [];
      for (let i = 1; i <= 12; i++) {
        weeks.push({
          value: `${date.startOf('isoWeek').format('YYYY-MM-DD')}|${date
            .endOf('isoWeek')
            .weekday(-2)
            .format('YYYY-MM-DD')}`,
          label: `${date.startOf('isoWeek').format('MM/DD/YYYY')} - ${date
            .endOf('isoWeek')
            .weekday(-2)
            .format('MM/DD/YYYY')}`,
        });
        date = date.subtract(1, 'weeks');
      }
      setWeekInterval(weeks[0].value);
      setWeekList(weeks);
    }
  }, [isOpen]);

  const handleSubmit = async (e: React.FormEvent, reportType: string) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);

    try {
      if (reportType === 'MONTHLY') {
        if (!weekInterval) return toast.warn('Please select the month.');
        const interval = monthInterval.split('/');
        const response = await ClassReportService(token).monthlyAttendance(classId, interval[1], interval[0]);
        FileDownload(response, 'monthly-report.pdf');
      }
      if (reportType === 'WEEKLY') {
        if (!weekInterval) return toast.warn('Please select the week.');
        const interval = weekInterval.split('|');
        const response = await ClassReportService(token).weeklyAttendance(classId, interval[0], interval[1]);
        FileDownload(response, 'weekly-report.pdf');
      }
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Report error!');
      toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal className="modal-dialog-centered" isOpen={isOpen} toggle={(e: any) => toggleModal(e)}>
        <div className="modal-header pb-1">
          <h5 className="modal-title">Attendance Roster</h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={(e) => toggleModal(e)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body pt-0">
          <Row>
            <Col>
              <Row>
                <Col lg="12">
                  <FormGroup>
                    <label className="form-control-label">By week</label>
                    <Input type="select" onChange={(e) => setWeekInterval(e.target.value)} value={weekInterval}>
                      <option value=""></option>
                      {weekList?.map((w: any, index: number) => {
                        return (
                          <option value={w.value} key={`week-interval-${index}`}>
                            {w.label}
                          </option>
                        );
                      })}
                    </Input>
                    <Button
                      color="primary"
                      type="button"
                      size="sm"
                      className="mt-1"
                      style={{ minWidth: '100px' }}
                      onClick={(e) => handleSubmit(e, 'WEEKLY')}
                    >
                      OK
                    </Button>
                  </FormGroup>
                </Col>
                <Col lg="12">
                  <FormGroup>
                    <label className="form-control-label">By month</label>
                    <Input
                      mask="99/9999"
                      value={monthInterval}
                      tag={InputMask}
                      onChange={(e) => setMonthInterval(e.target.value)}
                    />
                    <Button
                      color="primary"
                      type="button"
                      size="sm"
                      className="mt-1"
                      style={{ minWidth: '100px' }}
                      onClick={(e) => handleSubmit(e, 'MONTHLY')}
                    >
                      OK
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default ClassReportModal;
