import http from '../helpers/http';

const IncidentReports = (token: string) => {
  const headers = {
    'x-access-token': token,
  };

  return {
    update: async (id: string, body: any) => {
      const result = await http.put(`/incidentReport/${id}`, body, { headers });
      return result.data;
    },
    list: async () => {
      const result = await http.get('/incidentReport', {
        headers,
      });
      return result.data;
    },
    show: async (id: string) => {
      const result = await http.get(`/incidentReport/${id}`, {
        headers,
      });
      return result.data;
    },
    create: async (body: any) => {
      const result = await http.post(`/incidentReport`, body, { headers });
      return result.data;
    },
    listBySchool: async (schoolId: string, query?: string) => {
      const result = await http.get(`/incidentReport/by-school/${schoolId}${query ? `?${query}` : ''}`, {
        headers,
      });
      return result.data;
    },
    listByKid: async (kidId: string, query?: string) => {
      const result = await http.get(`/incidentReport/by-kid/${kidId}${query ? `?${query}` : ''}`, {
        headers,
      });
      return result.data;
    },
  };
};

export default IncidentReports;
