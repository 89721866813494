import { isMobile } from 'react-device-detect';
import { Card, CardBody, Row, Col, CardTitle } from 'reactstrap';

type ClassSummaryBannerProps = {
  enrolled?: number;
  capacity?: number;
  legalRatioTeachers?: number;
  legalRatioChildrens?: number;
  teachersCheckedIn?: number;
  kidsCheckedIn?: number;
};

const ClassSummaryBanner = ({
  enrolled,
  capacity,
  legalRatioTeachers,
  legalRatioChildrens,
  teachersCheckedIn,
  kidsCheckedIn,
}: ClassSummaryBannerProps) => {
  const isTablet = window.innerWidth <= 1112 && window.innerWidth > 768 && isMobile;
  const EnrolledIcon = () => (
    <Col
      className={`${isMobile || isTablet ? 'col' : 'col-auto'}`}
      style={isMobile || isTablet ? { textAlign: 'center' } : {}}
    >
      <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
        <i className="fas fa-clipboard-check"></i>
      </div>
    </Col>
  );
  const RatioIcon = () => (
    <Col
      className={`${isMobile || isTablet ? 'col' : 'col-auto'}`}
      style={isMobile || isTablet ? { textAlign: 'center' } : {}}
    >
      <div className="icon icon-shape bg-primary text-white rounded-circle shadow">
        <i className="fas fa-users"></i>
      </div>
    </Col>
  );
  const TeacherIcon = () => (
    <Col
      className={`${isMobile || isTablet ? 'col' : 'col-auto'}`}
      style={isMobile || isTablet ? { textAlign: 'center' } : {}}
    >
      <div className="icon icon-shape bg-info text-white rounded-circle shadow">
        <i className="fas fa-chalkboard-teacher"></i>
      </div>
    </Col>
  );
  const StudentIcon = () => (
    <Col
      className={`${isMobile || isTablet ? 'col' : 'col-auto'}`}
      style={isMobile || isTablet ? { textAlign: 'center' } : {}}
    >
      <div className="icon icon-shape bg-success text-white rounded-circle shadow">
        <i className="fas fa-check-circle"></i>
      </div>
    </Col>
  );
  return (
    <Row className="mb-4">
      <Col lg="3" xl="3" xs="6">
        <Card className="card-stats mb-4 mb-xl-0">
          <CardBody>
            <Row>
              {isMobile || isTablet ? <EnrolledIcon /> : <></>}
              <div className="col" style={isMobile || isTablet ? { textAlign: 'center', marginTop: 5 } : {}}>
                <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                  Enrolled
                </CardTitle>
                <span className="h2 font-weight-bold mb-0">
                  {enrolled || '0'}/{capacity || '0'}
                </span>
              </div>
              {!isMobile && !isTablet ? <EnrolledIcon /> : <></>}
            </Row>
          </CardBody>
        </Card>
      </Col>
      <Col lg="3" xl="3" xs="6">
        <Card className="card-stats mb-4 mb-xl-0">
          <CardBody>
            <Row>
              {isMobile || isTablet ? <RatioIcon /> : <></>}
              <div className="col" style={isMobile || isTablet ? { textAlign: 'center', marginTop: 5 } : {}}>
                <CardTitle tag="h5" className="text-uppercase text-muted mb-0 col-12">
                  Ratio
                </CardTitle>
                <span className="h2 font-weight-bold mb-0">
                  {legalRatioTeachers && legalRatioChildrens ? `${legalRatioTeachers}:${legalRatioChildrens}` : '-'}
                </span>
              </div>
              {!isMobile && !isTablet ? <RatioIcon /> : <></>}
            </Row>
          </CardBody>
        </Card>
      </Col>
      <Col lg="3" xl="3" xs="6">
        <Card className="card-stats mb-4 mb-xl-0">
          <CardBody>
            <Row>
              {isMobile || isTablet ? <TeacherIcon /> : <></>}
              <div className="col" style={isMobile || isTablet ? { textAlign: 'center', marginTop: 5 } : {}}>
                <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                  Teachers Checked In
                </CardTitle>
                <span className="h2 font-weight-bold mb-0">{teachersCheckedIn || '0'}</span>
              </div>
              {!isMobile && !isTablet ? <TeacherIcon /> : <></>}
            </Row>
          </CardBody>
        </Card>
      </Col>
      <Col lg="3" xl="3" xs="6">
        <Card className="card-stats mb-4 mb-xl-0">
          <CardBody>
            <Row>
              {isMobile || isTablet ? <StudentIcon /> : <></>}
              <div className="col" style={isMobile || isTablet ? { textAlign: 'center', marginTop: 5 } : {}}>
                <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                  Students Checked In
                </CardTitle>
                <span className="h2 font-weight-bold mb-0">{kidsCheckedIn || '0'}</span>
              </div>
              {!isMobile && !isTablet ? <StudentIcon /> : <></>}
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default ClassSummaryBanner;
