import http from '../helpers/http';
import AttendancesBodyInterface from '../interfaces/AttendancesBodyInterface';

const ActivitySettings = (token: string) => {
  const headers = {
    'x-access-token': token,
  };

  return {
    listByUser: async (userId: string, query?: string) => {
      const result = await http.get(`/users/${userId}/attendances${query ? `?${query}` : ''}`, {
        headers,
      });
      return result.data;
    },
    listByKids: async (kidsId: string, query?: string) => {
      const result = await http.get(`/kids/${kidsId}/attendances${query ? `?${query}` : ''}`, {
        headers,
      });
      return result.data;
    },
    listMovementByKids: async (kidsId: string, query?: string) => {
      const result = await http.get(`/kids/${kidsId}/attendances/movement${query ? `?${query}` : ''}`, {
        headers,
      });
      return result.data;
    },
    listKidsReminderByClass: async (classId: string) => {
      const result = await http.get(`/class/${classId}/reminder/attendances`, {
        headers,
      });
      return result.data;
    },
    listKidsReminderBySchool: async (schoolId: string) => {
      const result = await http.get(`/school/${schoolId}/reminder/attendances`, {
        headers,
      });
      return result.data;
    },
    createByUser: async (userId: string, body: Partial<AttendancesBodyInterface>) => {
      const result = await http.post(`/users/${userId}/attendances`, body, { headers });
      return result.data;
    },
    updateByUser: async (userId: string, body: Partial<AttendancesBodyInterface>) => {
      const result = await http.put(`/users/${userId}/attendances`, body, { headers });
      return result.data;
    },
    delete: async (id: string) => {
      const result = await http.delete(`/attendances/${id}`, {
        headers,
      });
      return result.data;
    },
    setByUser: async (userId: string, attendanceType: string, schoolId: string) => {
      const result = await http.post(`/users/${userId}/attendances/${attendanceType}`, { schoolId }, { headers });
      return result.data;
    },
    checkIn: async (pin: string, classId: string) => {
      const result = await http.post(`/checkin`, { pin, classId }, { headers });
      return result.data;
    },
    listBySchool: async (schoolId: string) => {
      const result = await http.get(`/attendances-school/${schoolId}/checkedin`, {
        headers,
      });
      return result.data;
    },
    getByPinCode: async (pinCode: string | number) => {
      const result = await http.get(`/users/${pinCode}/pin`, {
        headers,
      });
      return result.data;
    },
  };
};

export default ActivitySettings;
