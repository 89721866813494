import moment from 'moment';
import { Button } from 'reactstrap';
import { timeFormat } from '../../../helpers/dateFormat';

interface StaffOfferLetterProps {
  school: any;
  user: any;
  email: string;
  phone: string;
  firstName: string;
  lastName: string;
  hireDate: string;
  classification: string;
  paymentFrequency: string;
  amount: any;
  signature: string;
  handleFindTitleName: () => void;
  handleFindSupervisorName: () => void;
}

const StaffOfferLetter = ({
  school,
  user,
  email,
  phone,
  firstName,
  lastName,
  hireDate,
  classification,
  paymentFrequency,
  amount,
  signature,
  handleFindTitleName,
  handleFindSupervisorName,
}: StaffOfferLetterProps) => {
  return (
    <div className="d-flex flex-column p-6" style={{ background: '#eeeeee', borderRadius: '5px' }}>
      <div className="d-flex justify-content-end">
        <img src={school?.franchisee?.imageUrl} width={200} />
      </div>

      <div className="d-flex flex-column mt-2">
        <h2 className="mb-2">Amazing Explorers Academy</h2>
        <p className="m-0">Person Hiring: {`${user.firstName} ${user.lastName}`}</p>
        <p className="m-0">Phone Number: {phone}</p>
        <p className="m-0">
          Date: <strong>{moment().format('MM/DD/yyyy')}</strong>
        </p>
        <p className="m-0">
          Via Email: <strong>{email}</strong>
        </p>
      </div>

      <div className="d-flex flex-column text-justify mt-4">
        <p className="m-0">
          Dear {firstName} {lastName},
        </p>
        <p className="mt-3">
          Congratulations, you have been chosen by the interviewing team at Amazing Explorers Academy, for employment as
          a <b>{`${handleFindTitleName()}`}</b> with our school. Pending the results of your Background Screening, your
          tentative scheduled start date will be on {hireDate} and you are to report to the Amazing Explorers Academy at{' '}
          <b>{`${handleFindSupervisorName()}`}</b>. Upon arrival to the Child Care Facility you are to report to the
          director for a brief orientation and to fill out any additional paperwork required for your employment. Please
          note we ask staff to park furthest from the building to allow our parents to park closer.
        </p>

        <p className="mt-3">
          As an employee of Amazing Explorers Academy you are to perform the duties and responsibilities as outlined in
          the attached job description and adhere to the policies and procedures in our Employee Handbook. You will be
          asked to acknowledge that you read and understand what is stated in the handbook. Should you have any
          questions regarding any policies, procedures, and/or job responsibilities ask your supervisor for
          clarification. You will be a PROVISIONAL EMPLOYEE for the first 90 calendar days.
        </p>

        <p className="mt-3">
          Your rate of pay will be{' '}
          <b>
            ${Number(amount).toLocaleString('en-US', { minimumFractionDigits: 2 })} {paymentFrequency}
          </b>{' '}
          for all hours worked. You are classified as a __
          <span style={{ textDecoration: 'underline' }}>{classification !== 'PT' ? 'X' : '_'}</span>__full time (work
          average of 35 or more hours per week) __
          <span style={{ textDecoration: 'underline' }}>{classification === 'PT' ? 'X' : '_'}</span>__part time (work
          average of 32 or less hours per week). Please refer to the Employee Handbook for additional information
          regarding your employment status. Note any hours worked over 40 are considered over time hours and will paid
          accordingly.
        </p>

        <p className="mt-3">
          All benefits available to you, as an employee of Amazing Explorers Academy are outlined in the Employee
          Handbook. The Director will schedule a time to meet with you, near the end of your provisional period, to
          discuss your benefit options.
        </p>

        <p className="mt-3">
          Your usual working schedule will be MONDAY THROUGH FRIDAY, anytime from{' '}
          <b>{timeFormat(`${moment().format('YYYY-MM-DD')} ${school?.timeOperationStart || '00:00:00'}`)}</b> to{' '}
          <b>{timeFormat(`${moment().format('YYYY-MM-DD')} ${school?.timeOperationEnd || '00:00:00'}`)}</b>. You will be
          given an unpaid one (1) hour lunch break each day. If during this lunch break you stay on school property, you
          are to be completely relieved of any and all work responsibilities.
        </p>

        <p className="mt-3">
          Your employment at Amazing Explorers Academy will not be guaranteed for any specific duration or term. Florida
          is an "AT WILL" state and therefore so is Amazing Explorers Academy. This letter is not intended to be a
          contract for employment. Please refer to the Employee Handbook for information regarding resignation and
          termination of employment.
        </p>

        <p className="mt-3">
          Amazing Explorers Academy is happy you will be joining our team. And we look forward to working with you.
        </p>

        <p>Sincerely,</p>

        <div className="d-flex flex-column justify-content-center mt-4">
          <h3 className="text-center">Do you accept this Offer Letter?</h3>
          <div className="d-flex justify-content-center mt-2">
            <Button color="success" style={{ width: '220px' }} onClick={() => {}}>
              Accept
            </Button>
            <Button color="danger" style={{ width: '220px' }} onClick={() => {}}>
              Reject
            </Button>
          </div>
        </div>

        {signature && (
          <div className="mt-6">
            <p style={{ fontFamily: 'Dancing Script', fontSize: 24, marginBottom: 5 }}>{signature}</p>
            <hr className="m-0" style={{ width: '300px' }} />
            <p className="m-0">Name: {`${user.firstName} ${user.lastName}`}</p>
          </div>
        )}

        <h3 className="mt-6">
          <a href="https://www.aexplorers.com/" target="_blank" style={{ color: '#FAAA42' }}>
            WWW.AEXPLORERS.COM
          </a>
        </h3>
      </div>
    </div>
  );
};

export default StaffOfferLetter;
