import moment from 'moment';
import { Button } from 'reactstrap';

interface ManagementOfferLetterProps {
  school: any;
  user: any;
  email: string;
  phone: string;
  firstName: string;
  lastName: string;
  hireDate: string;
  classification: string;
  currentSchool: any;
  paymentFrequency: string;
  amount: any;
  signature: string;
  policy: any;
  ptoPolices: any[];
  handleFindTitleName: () => void;
  handleFindSupervisorName: () => void;
}

const ManagementOfferLetter = ({
  school,
  user,
  email,
  phone,
  firstName,
  lastName,
  hireDate,
  classification,
  currentSchool,
  paymentFrequency,
  amount,
  signature,
  policy,
  ptoPolices,
  handleFindTitleName,
  handleFindSupervisorName,
}: ManagementOfferLetterProps) => {
  const currentPolicy = ptoPolices?.find((p) => p.id === policy);
  return (
    <div className="d-flex flex-column p-6" style={{ background: '#eeeeee', borderRadius: '5px' }}>
      <div className="d-flex justify-content-end">
        <img src={school?.franchisee?.imageUrl} width={200} />
      </div>

      <div className="d-flex flex-column mt-2">
        <h2 className="mb-2">Amazing Explorers Academy</h2>
        <p className="m-0">Person Hiring: {`${user.firstName} ${user.lastName}`}</p>
        <p className="m-0">Phone Number: {phone}</p>
        <p className="m-0">
          Date: <strong>{moment().format('MM/DD/yyyy')}</strong>
        </p>
        <p className="m-0">
          Via Email: <strong>{email}</strong>
        </p>
      </div>

      <div className="d-flex flex-column mt-4">
        <p className="m-0">
          Dear {firstName} {lastName}, <br />I am very pleased to offer you a position of {`${handleFindTitleName()}`}{' '}
          at Amazing Explorers {currentSchool.label}, reporting to Manager, {`${handleFindSupervisorName()}`}.
        </p>

        <p className="mt-4">
          This Offer Letter outlines the general terms and conditions of your proposed employment and compensation. The
          following sets forth our offer in its entirety: Yours sincerely, Person Sending Offer Candidate's Name
        </p>

        <div className="pl-4">
          <p>
            <strong>Position:</strong> {`${handleFindTitleName()}`}
          </p>
          <p>
            <strong>Start Date:</strong> {hireDate}
          </p>
          <p>
            <strong>Hours:</strong> {classification === 'FT' && paymentFrequency === 'hourly' && ' 40h'}
            {classification === 'PT' && paymentFrequency === 'hourly' && ' 32h'}
            {classification === 'FT' && paymentFrequency === 'annually' && ' 45h'}
          </p>
          <p>
            <strong>Base Salary:</strong> {Number(amount).toLocaleString('en-US', { minimumFractionDigits: 2 })}
          </p>
          <p>
            <strong>Yearly Bonus:</strong> You will be eligible to earn a discretionary bonus contingent on your full
            employment for a consecutive 12 months, on AEA meeting financial targets and on achieving your personal
            goals and targets. Personal targets and objectives will be defined and agreed to jointly with your manager.
            To be eligible for a bonus you must be an employee at that date. No bonus payments will be made if the
            employee leaves the company. Bonuses are typically paid at the beginning of the year.
          </p>
          <p>
            <strong>Monthly Incentives:</strong> You are eligible for monthly incentives that can be monetary, team
            building experiences, etc. that are all depended on you achieving your goals. These goals will be defined
            and agreed to jointly with your manager.
          </p>
          {currentPolicy ? (
            <p>
              <strong>Vacation:</strong> {currentPolicy?.accrualRateDaysPerYear} days PTO.
            </p>
          ) : (
            <></>
          )}

          <p>
            <strong>Benefits:</strong> You will be eligible for benefits once you have reached your 90 days.
          </p>
        </div>

        <p>
          <strong>Conditions of Employment:</strong> All employees are subject to certain conditions of employment. Such
          conditions include acceptance of the Company’s policies as outlined in the Employee Handbook, which will be
          provided to you. You will be required to sign a statement indicating your acceptance of the terms and
          conditions outlined in the Employee Manual, as well as a Confidentiality and Nondisclosure Agreement.
        </p>

        <p>
          <strong>Entire Offer of Employment:</strong> This letter sets forth the Company’s entire offer of employment
          and shall not be construed as an employment contract in any way or as limiting in any way the Company’s
          latitude to terminate your employment at any time with or without cause or to otherwise change your duties,
        </p>

        <p>
          location or any of the terms and conditions of your employment. You will be required to provide proof of
          employment eligibility as required under the Immigration Reform and Control Act of 1986 at the commencement of
          employment.
        </p>

        <p>
          We are all excited to bring you on board and look forward to working together to grow Amazing Explorers as a
          business. If the above is acceptable to you, kindly sign below and return one original to me.
        </p>

        <p>Yours sincerely,</p>

        <div className="d-flex flex-column justify-content-center mt-4">
          <h3 className="text-center">Do you accept this Offer Letter?</h3>
          <div className="d-flex justify-content-center mt-2">
            <Button color="success" style={{ width: '220px' }} onClick={() => {}}>
              Accept
            </Button>
            <Button color="danger" style={{ width: '220px' }} onClick={() => {}}>
              Reject
            </Button>
          </div>
        </div>

        {signature && (
          <div className="mt-6">
            <p style={{ fontFamily: 'Dancing Script', fontSize: 24, marginBottom: 5 }}>{signature}</p>
            <hr className="m-0" style={{ width: '300px' }} />
            <p className="m-0">Name: {`${user.firstName} ${user.lastName}`}</p>
          </div>
        )}

        <h3 className="mt-6">
          <a href="https://www.aexplorers.com/" target="_blank" style={{ color: '#FAAA42' }}>
            WWW.AEXPLORERS.COM
          </a>
        </h3>
      </div>
    </div>
  );
};

export default ManagementOfferLetter;
