/* eslint-disable max-lines */
import {
  Button,
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import isAdmin from '../../../helpers/isAdmin';
import isParent from '../../../helpers/isParent';
import ExpirationDatesModal from './ExpirationDatesModal';
import ChangePasswordModal from './ChangePasswordModal';
import DatePicker from 'react-datepicker';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { FormControl, ListItemText, MenuItem } from '@mui/material';
import WriteUpService from '../../../services/WriteUp';
import AttendancesService from '../../../services/Attendances';
import { toast } from 'react-toastify';
import moment from 'moment';
import dateInterval from '../../../helpers/dateInterval';
import getTimeCardInfo from '../../../helpers/getTimeCardInfo';
import { confirmAlert } from 'react-confirm-alert';
import EmployeeChangeStatusConfirm from './EmployeeChangeStatusConfirm';
import isClassroom from '../../../helpers/isClassroom';

interface ProfileRightBarProps {
  reload: boolean;
  handleReload: (reload: boolean) => void;
  currentUser: any;
  isUserHimself: boolean;
  handleActivatedEmployee: (employeeId: string) => void;
}

const ProfileRightBar = ({
  reload,
  handleReload,
  currentUser,
  isUserHimself = false,
  handleActivatedEmployee,
}: ProfileRightBarProps) => {
  const { id } = useParams<{ id: string }>();
  const { token, currentSchool, user: loggedUser }: any = useContext(AuthContext);
  const isParentUser = isParent(currentUser);
  const [expirationDatesModal, setExpirationDatesModal] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);
  const [isClockedIn, setIsClockedIn] = useState(false);
  const [regularWorked, setRegularWorked] = useState(0);
  const [overtimeWorked, setOvertimeWorked] = useState(0);
  const [remainingTime, setRemainingTime] = useState(0);

  const [isWriteUpOpen, setisWriteUpOpen] = useState(false);
  const [incidentDate, setIncidentDate] = useState(new Date());
  const [writtenWarning, setWrittenWarning] = useState('');
  const [policyViolation, setPolicyViolation] = useState<string[]>([]);
  const [incidentDescription, setIncidentDescription] = useState('');
  const [actionTaken, setActionTaken] = useState('');
  const [isComunicated, setIsComunicated] = useState(true);
  const [improvementPlan, setImprovementPlan] = useState('');
  const [improvementExpectation, setImprovementExpectation] = useState('');
  const [trainig, setTrainig] = useState('');
  const [meetingDate, setMeetingDate] = useState('');
  const [employeeSignature, setEmployeeSignature] = useState('');
  const [employeeSignatureDate, setEmployeeSignatureDate] = useState<Date | null>();
  const [directorSignature, setDirectorSignature] = useState('');
  const [directorSignatureDate, setDirectorSignatureDate] = useState<Date | null>();
  const [isSign, setIsSign] = useState(false);
  const [writeUpData, setWriteUpData] = useState<any[]>();

  const isClassroomUser = isClassroom(currentUser);

  useEffect(() => {
    getByUserId();
  }, [reload]);

  const getByUserId = async () => {
    try {
      const result = await WriteUpService(token).listByUser(id);
      setWriteUpData(result?.data);
      setWrittenWarning(
        result?.data
          ?.map(
            (value: any) =>
              `${moment(value?.incidentDate).format('MM/DD/YY')} ${value?.policyViolation.split(',').join(', ')} (${
                value?.actionTaken
              })`,
          )
          .join('\n'),
      );
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'Invalid user ID');
    }
  };

  useEffect(() => {
    setIsClockedIn(currentUser?.isClockedIn || false);
    timeCard();
  }, [currentUser]);

  const timeCard = async () => {
    try {
      const currentUserParam = currentUser || {};
      const weekTime = currentUserParam?.employee?.hoursPerWeek || 40;
      const interval = dateInterval('THIS_WEEK');
      const startDateTime = interval.startDateTime;
      const finalDateTime = interval.finalDateTime;
      const result = await AttendancesService(token || '').listByUser(
        id,
        `startDateTime=${startDateTime}&finalDateTime=${finalDateTime}`,
      );
      const data: any[] = result.data;
      const { sumRealWorkTotal, sumOvertime } = getTimeCardInfo(startDateTime, finalDateTime, currentUserParam, data);

      setRegularWorked(sumRealWorkTotal);
      setOvertimeWorked(sumOvertime);
      setRemainingTime(Number(weekTime || 40) - sumRealWorkTotal);
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Error fetching list');
      return console.error(message);
    }
  };

  const onSubmitWriteUp = async (event: any) => {
    event.preventDefault();
    try {
      let data: any = {
        userId: currentUser.id,
        schoolId: currentSchool.value,
        incidentDate,
        writtenWarning,
        policyViolation: policyViolation.toString(),
        incidentDescription,
        actionTaken,
      };

      if (actionTaken === 'Written Warning' || actionTaken === 'Final Warning') {
        data = {
          ...data,
          improvementExpectation: improvementExpectation,
          trainig: trainig,
          meetingDate: meetingDate,
          employeeSignature: employeeSignature,
          employeeSignatureDate: employeeSignatureDate,
          directorSignature: directorSignature,
          directorSignatureDate: directorSignatureDate,
        };
      }

      if (actionTaken === 'Verbal Warning') {
        data = {
          ...data,
          isComunicated: isComunicated,
          improvementPlan: improvementPlan,
        };
      }

      await WriteUpService(token).create(data);

      setisWriteUpOpen(false);
      setWrittenWarning('');
      setPolicyViolation([]);
      setIncidentDescription('');
      setActionTaken('');
      setIsComunicated(true);
      setImprovementPlan('');
      setImprovementExpectation('');
      setTrainig('');
      setMeetingDate('');
      setEmployeeSignature('');
      setEmployeeSignatureDate(null);
      setDirectorSignature('');
      setDirectorSignatureDate(null);
      setIsSign(false);

      handleReload(!reload);
      toast.success('Write Up successfully created');
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'Error');
    }
  };

  const handleChangeMultiSelect = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    setPolicyViolation(typeof value === 'string' ? value.split(',') : value);
  };

  const confirmChangestatus = (e: any, id: string) => {
    e.preventDefault();

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <EmployeeChangeStatusConfirm id={id} handleActivatedEmployee={handleActivatedEmployee} onClose={onClose} />
        );
      },
    });
  };

  return (
    <>
      <Modal
        className="modal-dialog-centered modal-lg"
        size="1000px"
        isOpen={isWriteUpOpen}
        toggle={() => setisWriteUpOpen(false)}
      >
        <div className="modal-header pt-4 pb-0">
          <h6 className="modal-title" id="modal-title-notification">
            Write Up
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setisWriteUpOpen(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body px-5 pt-2">
          <div className="py-3 text-center">
            <div className="text-justify">
              <Form onSubmit={onSubmitWriteUp}>
                <Row>
                  <Col lg="12">
                    <h3 className="text-primary mb-4">PERFORMANCE IMPROVEMENT DOCUMENT</h3>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-firstName">
                        Name
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-firstName"
                        placeholder="First Name"
                        type="text"
                        disabled
                        value={`${currentUser?.firstName} ${currentUser?.lastName}`}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <Label className="form-control-label text-left mr-4" htmlFor="incident-date">
                      <strong>Date of Incident:</strong>
                    </Label>
                    <Input
                      tag={DatePicker}
                      dateFormat="MM/dd/yyyy"
                      onChange={(d: any) => {
                        setIncidentDate(d);
                      }}
                      selected={incidentDate}
                      id="incident-date"
                      required
                    />
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-firstName">
                        Position
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-firstName"
                        placeholder=""
                        type="text"
                        disabled
                        value={currentUser?.employee?.franchisee?.name}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-firstName">
                        Department
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-firstName"
                        placeholder=""
                        type="text"
                        disabled
                        value={currentUser?.employee?.department?.name || ''}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-firstName">
                        Supervisor
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-firstName"
                        placeholder=""
                        type="text"
                        disabled
                        value={''}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-firstName">
                        Date of Hire
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-firstName"
                        placeholder=""
                        type="text"
                        disabled
                        value={currentUser?.employee?.startDate}
                      />
                    </FormGroup>
                  </Col>

                  <Col lg="12" className="mt-4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-description-1">
                        <strong>Prior Verbal Discussion of Written Warning</strong>
                        <br />
                        <span className="small">List dates & type of informal discussion or warning</span>
                      </label>
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Policy Violeted</th>
                            <th>Action Taken</th>
                          </tr>
                        </thead>
                        <tbody>
                          {writeUpData?.map((value) => (
                            <tr key={value.id}>
                              <td>{`${moment(value.incidentDate).format('MM/DD/YY')}`}</td>
                              <td
                                style={{
                                  display: 'flex',
                                  flexWrap: 'wrap',
                                }}
                              >
                                {value.policyViolation.split(',').join(', ')}
                              </td>
                              <td>{value.actionTaken}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </FormGroup>
                  </Col>

                  <Col lg="12">
                    <FormControl sx={{ width: '100%', marginBottom: '10px' }}>
                      <label className="form-control-label" htmlFor="input-description-1">
                        <strong>Policy Violation:</strong>
                      </label>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={policyViolation}
                        onChange={handleChangeMultiSelect}
                        renderValue={(selected: string[]) => selected.join(', ')}
                        sx={{
                          height: 43,
                          fontSize: 14,
                          color: '#8898aa',
                        }}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 280,
                              width: 250,
                            },
                          },
                        }}
                      >
                        <MenuItem value="Accident and incident reporting">
                          <Checkbox checked={policyViolation.indexOf('Accident and incident reporting') > -1} />
                          <ListItemText primary="Accident and incident reporting" />
                        </MenuItem>
                        <MenuItem value="Attendance">
                          <Checkbox checked={policyViolation.indexOf('Attendance') > -1} />
                          <ListItemText primary="Attendance" />
                        </MenuItem>
                        <MenuItem value="Cell phones & computers">
                          <Checkbox checked={policyViolation.indexOf('Cell phones & computers') > -1} />
                          <ListItemText primary="Cell phones & computers" />
                        </MenuItem>
                        <MenuItem value="Classroom Appearance">
                          <Checkbox checked={policyViolation.indexOf('Classroom Appearance') > -1} />
                          <ListItemText primary="Classroom Appearance" />
                        </MenuItem>
                        <MenuItem value="Cleaning Expectations">
                          <Checkbox checked={policyViolation.indexOf('Cleaning Expectations') > -1} />
                          <ListItemText primary="Cleaning Expectations" />
                        </MenuItem>
                        <MenuItem value="Conduct">
                          <Checkbox checked={policyViolation.indexOf('Conduct') > -1} />
                          <ListItemText primary="Conduct" />
                        </MenuItem>
                        <MenuItem value="Diaper Changing Procedures">
                          <Checkbox checked={policyViolation.indexOf('Diaper Changing Procedures') > -1} />
                          <ListItemText primary="Diaper Changing Procedures" />
                        </MenuItem>
                        <MenuItem value="Gossip in the workplace">
                          <Checkbox checked={policyViolation.indexOf('Gossip in the workplace') > -1} />
                          <ListItemText primary="Gossip in the workplace" />
                        </MenuItem>
                        <MenuItem value="Hand washing procedures">
                          <Checkbox checked={policyViolation.indexOf('Hand washing procedures') > -1} />
                          <ListItemText primary="Hand washing procedures" />
                        </MenuItem>
                        <MenuItem value="Medicine Procedures">
                          <Checkbox checked={policyViolation.indexOf('Medicine Procedures') > -1} />
                          <ListItemText primary="Medicine Procedures" />
                        </MenuItem>
                        <MenuItem value="Outside Food & Drink">
                          <Checkbox checked={policyViolation.indexOf('Outside Food & Drink') > -1} />
                          <ListItemText primary="Outside Food & Drink" />
                        </MenuItem>
                        <MenuItem value="Safety">
                          <Checkbox checked={policyViolation.indexOf('Safety') > -1} />
                          <ListItemText primary="Safety" />
                        </MenuItem>
                        <MenuItem value="Smoking">
                          <Checkbox checked={policyViolation.indexOf('Smoking') > -1} />
                          <ListItemText primary="Smoking" />
                        </MenuItem>
                        <MenuItem value="Social Media & Websites">
                          <Checkbox checked={policyViolation.indexOf('Social Media & Websites') > -1} />
                          <ListItemText primary="Social Media & Websites" />
                        </MenuItem>
                        <MenuItem value="Teamwork">
                          <Checkbox checked={policyViolation.indexOf('Teamwork') > -1} />
                          <ListItemText primary="Teamwork" />
                        </MenuItem>
                        <MenuItem value="Uniform & Dress Code">
                          <Checkbox checked={policyViolation.indexOf('Uniform & Dress Code') > -1} />
                          <ListItemText primary="Uniform & Dress Code" />
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Col>

                  <Col lg="12">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-description-1">
                        <strong>Description of Incident</strong>
                      </label>
                      <textarea
                        className="form-control"
                        id="input-description-1"
                        placeholder="Description"
                        value={incidentDescription}
                        onChange={(e) => {
                          setIncidentDescription(e.target.value);
                        }}
                        required
                      />
                    </FormGroup>
                  </Col>

                  <Col lg="12">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-gender">
                        Action Taken:
                      </label>
                      <Input
                        type="select"
                        value={actionTaken}
                        onChange={(e) => {
                          setActionTaken(e.target.value);
                        }}
                      >
                        <option value=""></option>
                        <option value="Verbal Warning">Verbal Warning</option>
                        <option value="Written Warning">Written Warning</option>
                        <option value="Final Warning">Final Warning</option>
                      </Input>
                    </FormGroup>
                  </Col>

                  {(actionTaken === 'Written Warning' || actionTaken === 'Final Warning') && (
                    <>
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-description-2">
                            <strong>Expectation for Improvement:</strong>
                          </label>
                          <textarea
                            className="form-control"
                            id="input-description-2"
                            placeholder="Description"
                            value={improvementExpectation}
                            onChange={(e) => {
                              setImprovementExpectation(e.target.value);
                            }}
                            required
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="12" className="mb-0">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-description-3">
                            <strong>Trainig to be Completed:</strong>
                          </label>
                          <textarea
                            className="form-control"
                            id="input-description-3"
                            placeholder="Description"
                            value={trainig}
                            onChange={(e) => {
                              setTrainig(e.target.value);
                            }}
                            required
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="12">
                        <Label className="form-control-label text-left mr-4" htmlFor="incident-date">
                          <strong>We'll have a follow up meeting on:</strong>
                        </Label>
                        <Input
                          tag={DatePicker}
                          dateFormat="MM/dd/yyyy"
                          onChange={(d: any) => {
                            setMeetingDate(d);
                          }}
                          selected={meetingDate}
                          id="incident-date"
                          required
                        />
                      </Col>
                      <Col lg="12" className="mt-4">
                        <Label>
                          <strong>Signature:</strong>
                        </Label>
                        <div className="d-flex flex-wrap align-items-center">
                          <div className="form-check form-check-inline mr-3">
                            <Input
                              className="form-check-input mr-4"
                              type="checkbox"
                              id="to-sign"
                              checked={isSign}
                              onChange={() => {
                                setIsSign(!isSign);
                                setEmployeeSignatureDate(new Date());
                                setDirectorSignatureDate(new Date());
                              }}
                            />
                            <Label className="form-check-label mr-2" htmlFor="to-sign">
                              I understand that employment with Amazing Explorers Academy is at-will. I also undestand
                              that failure to maintain or sustain acceptable levels of performancem behavior or conduct
                              may result in further action, up to and incluing separation of employment. Signing this
                              plan does not indicate agreement, but only receipt. I also understand I may attach written
                              comments about this manner..
                            </Label>
                          </div>
                        </div>
                      </Col>

                      {isSign && (
                        <>
                          <Col lg="12" className="mt-4">
                            <FormGroup>
                              <Label htmlFor="name-for-signature">
                                <strong>Employee Sgnature</strong>
                                <br />
                                <span className="small">Enter your name for signature:</span>
                              </Label>
                              <Input
                                className="form-control"
                                type="text"
                                id="name-for-signature"
                                style={{ fontFamily: 'Dancing Script' }}
                                value={employeeSignature}
                                onChange={(e) => {
                                  setEmployeeSignature(e.target.value);
                                }}
                              />
                            </FormGroup>
                          </Col>

                          <Col lg="12" className="mt-2">
                            <FormGroup>
                              <Label htmlFor="name-for-signature">
                                <strong>Director Sgnature</strong>
                                <br />
                                <span className="small">Enter your name for signature:</span>
                              </Label>
                              <Input
                                className="form-control"
                                type="text"
                                id="name-for-signature"
                                style={{ fontFamily: 'Dancing Script' }}
                                value={directorSignature}
                                onChange={(e) => {
                                  setDirectorSignature(e.target.value);
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </>
                      )}
                    </>
                  )}

                  {actionTaken === 'Verbal Warning' && (
                    <>
                      <Col lg="12">
                        <Label>
                          <strong>Communicated:</strong>
                        </Label>
                        <div className="d-flex flex-wrap align-items-center">
                          <label className="custom-toggle">
                            <Input type="checkbox" checked={isComunicated}>
                              <option className="custom-toggle-slider rounded-circle" />
                            </Input>
                            <span className="custom-toggle-slider rounded-circle" />
                          </label>
                        </div>
                      </Col>

                      <Col lg="12" className="mt-2">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-description-2">
                            <strong>Improvement Plan:</strong>
                          </label>
                          <textarea
                            className="form-control"
                            id="input-description-2"
                            placeholder="Description"
                            value={improvementPlan}
                            onChange={(e) => {
                              setImprovementPlan(e.target.value);
                            }}
                            required
                          />
                        </FormGroup>
                      </Col>
                    </>
                  )}
                </Row>

                <div className="d-flex justify-content-end">
                  <Button className="my-4" color="primary" type="submit">
                    Submit
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button className="text-white ml-auto" color="link" data-dismiss="modal" type="button" onClick={() => {}}>
            Close
          </Button>
        </div>
      </Modal>
      {!isUserHimself && !isParentUser && !isClassroomUser && (
        <>
          <Card>
            <CardBody style={{ textAlign: 'center' }}>
              <span className="h2 text-center mx-auto">
                <i
                  className={`far ${
                    isClockedIn ? 'fa-arrow-alt-circle-right text-success' : 'fa-arrow-alt-circle-left text-danger'
                  } mr-1 fa-xl align-baseline font-size-25`}
                />{' '}
                {isClockedIn ? 'Clocked In' : 'Clocked Out'}
              </span>
            </CardBody>
          </Card>
          <Button
            className={`btn-block mt-4 ml-0`}
            color="warning"
            size="md"
            onClick={() => setExpirationDatesModal(true)}
          >
            <i className="fas fa-calendar-alt"></i> Expiration Dates
          </Button>
          {['HIRED', 'BLOCKED'].includes(currentUser?.employee?.status) ||
          ['HIRED', 'BLOCKED'].includes(currentUser?.status) ? (
            <UncontrolledDropdown nav className="w-100">
              <DropdownToggle className="pr-0" nav style={{ paddingLeft: '0' }}>
                <Button className={`btn-block mt-4 ml-0 text-white bg-warning`} size="md">
                  <i className="fas fa-user mr-2"></i> {currentUser?.employee?.status}
                </Button>
              </DropdownToggle>
              <DropdownMenu
                className="dropdown-menu-arrow w-100"
                right
                style={{ transform: 'translate3d(0px, 75px, 0px)' }}
              >
                <DropdownItem
                  className="w-100 text-center"
                  onClick={(e) => {
                    confirmChangestatus(e, currentUser?.employee?.id);
                  }}
                >
                  <span>Active</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          ) : (
            <Button
              className={`btn-block mt-4 ml-0 text-white`}
              style={{
                background: currentUser?.employee?.status === 'ACTIVE' ? '#009c22' : '#f5365c',
                pointerEvents: 'none',
                opacity: 1,
              }}
              size="md"
              disabled
            >
              <i className="fas fa-user mr-2"></i> {currentUser?.employee?.status}
            </Button>
          )}

          {currentUser?.employee?.id ? (
            <Card className="mt-4">
              <Table responsive>
                <thead>
                  <tr>
                    <th className="text-center border-top-0" colSpan={2}>
                      Time Card (Current Week)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Regular Worked</th>
                    <td>{regularWorked}</td>
                  </tr>
                  <tr>
                    <th scope="row">Overtime Worked</th>
                    <td>{overtimeWorked}</td>
                  </tr>
                  <tr>
                    <th scope="row">Remaining</th>
                    <td>{remainingTime}</td>
                  </tr>
                </tbody>
              </Table>
            </Card>
          ) : (
            <></>
          )}
          <ExpirationDatesModal
            userId={id}
            isOpen={expirationDatesModal}
            toggleModal={() => setExpirationDatesModal(!expirationDatesModal)}
          />
        </>
      )}
      {isAdmin(loggedUser) || loggedUser?.id === currentUser?.id ? (
        <>
          <Button className={`btn-block mt-4 ml-0`} color="danger" size="md" onClick={() => setPasswordModal(true)}>
            <i className="fas fa-key"></i> Change Password
          </Button>
          <ChangePasswordModal
            userId={id}
            isOpen={passwordModal}
            isAdmin={isAdmin(loggedUser)}
            toggleModal={() => setPasswordModal(!passwordModal)}
          />
        </>
      ) : (
        <></>
      )}
      {!isUserHimself && !isClassroomUser && !isParentUser && (
        <Button className={`btn-block mt-4 ml-0`} color="primary" size="md" onClick={() => setisWriteUpOpen(true)}>
          Write Up
        </Button>
      )}
    </>
  );
};

export default ProfileRightBar;
