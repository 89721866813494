/* eslint-disable max-lines */

import { FormControl } from '@mui/material';
import { Modal, Form, Row, Col, FormGroup, Input, Label, Button } from 'reactstrap';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

interface SeeWriteUpModalProps {
  data: any;
}

export const SeeWriteUpModal = ({ data }: SeeWriteUpModalProps) => {
  const [isWriteUpOpen, setIsWriteUpOpen] = useState(false);

  return (
    <>
      <Button size="sm" color="primary" onClick={() => setIsWriteUpOpen(true)}>
        <FontAwesomeIcon icon={faEye} />
      </Button>
      <Modal
        className="modal-dialog-centered modal-lg"
        size="1000px"
        isOpen={isWriteUpOpen}
        toggle={() => setIsWriteUpOpen(false)}
      >
        <div className="modal-header pt-4 pb-0">
          <h6 className="modal-title" id="modal-title-notification">
            Write Up
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setIsWriteUpOpen(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body px-5 pt-2">
          <div className="py-3 text-center">
            <div className="text-justify">
              <Form>
                <Row>
                  <Col lg="12">
                    <h3 className="text-primary mb-4">PERFORMANCE IMPROVEMENT DOCUMENT</h3>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-firstName">
                        Name
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-firstName"
                        placeholder="First Name"
                        type="text"
                        disabled
                        value={`${data.user?.firstName} ${data.user?.lastName}`}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <Label className="form-control-label text-left mr-4" htmlFor="incident-date">
                      <strong>Date of Incident:</strong>
                    </Label>
                    <Input
                      className="form-control-alternative"
                      placeholder=""
                      type="text"
                      disabled
                      value={moment(data.incidentDate).format('M/D/YYYY')}
                    />
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-firstName">
                        Position
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-firstName"
                        placeholder=""
                        type="text"
                        disabled
                        value={data.user?.employee?.franchisee?.name}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-firstName">
                        Department
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-firstName"
                        placeholder=""
                        type="text"
                        disabled
                        value={data.user?.employee?.department.name}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-firstName">
                        Supervisor
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-firstName"
                        placeholder=""
                        type="text"
                        disabled
                        value={''}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-firstName">
                        Date of Hire
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-firstName"
                        placeholder=""
                        type="text"
                        disabled
                        value={moment(data.user?.employee?.startDate).format('M/D/YYYY')}
                      />
                    </FormGroup>
                  </Col>

                  <Col lg="12" className="mt-4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-description-1">
                        <strong>Prior Verbal Discussion of Written Warning</strong>
                        <br />
                        <span className="small">List dates & type of informal discussion or warning</span>
                      </label>
                      <p>{data.writtenWarning}</p>
                    </FormGroup>
                  </Col>

                  <Col lg="12">
                    <FormControl sx={{ width: '100%', marginBottom: '10px' }}>
                      <label className="form-control-label" htmlFor="input-description-1">
                        <strong>Policy Violation:</strong>
                      </label>
                      <textarea
                        className="form-control-alternative"
                        placeholder=""
                        disabled
                        value={data.policyViolation.split(',').join(', \n')}
                      />
                    </FormControl>
                  </Col>

                  <Col lg="12">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-description-1">
                        <strong>Description of Incident</strong>
                      </label>
                      <textarea
                        className="form-control"
                        id="input-description-1"
                        placeholder="Description"
                        value={data.incidentDescription}
                        disabled
                      />
                    </FormGroup>
                  </Col>

                  <Col lg="12">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-gender">
                        Action Taken:
                      </label>
                      <Input type="text" value={data.actionTaken} disabled />
                    </FormGroup>
                  </Col>

                  {(data.actionTaken === 'Written Warning' || data.actionTaken === 'Final Warning') && (
                    <>
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-description-2">
                            <strong>Expectation for Improvement:</strong>
                          </label>
                          <textarea
                            className="form-control"
                            id="input-description-2"
                            placeholder="Description"
                            value={data.improvementExpectation}
                            disabled
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="12" className="mb-0">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-description-3">
                            <strong>Trainig to be Completed:</strong>
                          </label>
                          <textarea
                            className="form-control"
                            id="input-description-3"
                            placeholder="Description"
                            value={data.trainig}
                            disabled
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="12">
                        <Label className="form-control-label text-left mr-4" htmlFor="incident-date">
                          <strong>We'll have a follow up meeting on:</strong>
                        </Label>
                        <Input
                          className="form-control-alternative"
                          id="input-firstName"
                          placeholder=""
                          type="text"
                          disabled
                          value={moment(data.meetingDate).format('M/D/YYYY')}
                        />
                      </Col>
                      <Col lg="12" className="mt-4">
                        <Label>
                          <strong>Signature:</strong>
                        </Label>
                        <div className="d-flex flex-wrap align-items-center">
                          <div className="form-check form-check-inline mr-3">
                            <Input className="form-check-input mr-4" type="checkbox" id="to-sign" checked={true} />
                            <Label className="form-check-label mr-2" htmlFor="to-sign">
                              I understand that employment with Amazing Explorers Academy is at-will. I also undestand
                              that failure to maintain or sustain acceptable levels of performancem behavior or conduct
                              may result in further action, up to and incluing separation of employment. Signing this
                              plan does not indicate agreement, but only receipt. I also understand I may attach written
                              comments about this manner..
                            </Label>
                          </div>
                        </div>
                      </Col>
                      <Col lg="12" className="mt-4">
                        <FormGroup>
                          <Label htmlFor="name-for-signature">
                            <strong>Employee Signature</strong>
                            <br />
                            <span className="small">Enter your name for signature:</span>
                          </Label>
                          <Input
                            className="form-control"
                            type="text"
                            id="name-for-signature"
                            style={{ fontFamily: 'Dancing Script' }}
                            value={data.employeeSignature}
                            disabled
                          />
                          <p className="mt-2">
                            Signature Date: {moment(data.employeeSignatureDate).format('M/D/YYYY')}
                          </p>
                        </FormGroup>
                      </Col>

                      <Col lg="12" className="mt-2">
                        <FormGroup>
                          <Label htmlFor="name-for-signature">
                            <strong>Director Signature</strong>
                            <br />
                            <span className="small">Enter your name for signature:</span>
                          </Label>
                          <Input
                            className="form-control"
                            type="text"
                            id="name-for-signature"
                            style={{ fontFamily: 'Dancing Script' }}
                            value={data.directorSignature}
                            disabled
                          />
                          <p className="mt-2">
                            Signature Date: {moment(data.directorSignatureDate).format('M/D/YYYY')}
                          </p>
                        </FormGroup>
                      </Col>
                    </>
                  )}

                  {data.actionTaken === 'Verbal Warning' && (
                    <>
                      <Col lg="12">
                        <Label>
                          <strong>Communicated:</strong>
                        </Label>
                        <div className="d-flex flex-wrap align-items-center">
                          <label className="custom-toggle">
                            <Input type="checkbox" checked={true}>
                              <option className="custom-toggle-slider rounded-circle" />
                            </Input>
                            <span className="custom-toggle-slider rounded-circle" />
                          </label>
                        </div>
                      </Col>

                      <Col lg="12" className="mt-2">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-description-2">
                            <strong>Improvement Plan:</strong>
                          </label>
                          <textarea
                            className="form-control"
                            id="input-description-2"
                            placeholder="Description"
                            value={data.improvementPlan}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </>
                  )}
                </Row>
              </Form>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button className="text-white ml-auto" color="link" data-dismiss="modal" type="button" onClick={() => {}}>
            Close
          </Button>
        </div>
      </Modal>
    </>
  );
};
