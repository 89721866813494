import { Container } from 'reactstrap';
import CommonHeader from '../../../components/Headers/CommonHeader';
import EmployeeSettingsTabs from './EmployeeSettingsTabs';

const EmployeeSettingsView = () => {
  return (
    <>
      <CommonHeader />
      <Container className="mt--8" fluid>
        <EmployeeSettingsTabs isOpen={true} />
      </Container>
    </>
  );
};

export default EmployeeSettingsView;
