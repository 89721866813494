import React, { createContext, useState } from 'react';

import useAuth from '../hooks/useAuth';
import useCurrentSchool from '../hooks/useCurrentSchool';
import useCurrentFranchisee from '../hooks/useCurrentFranchisee';
import UserService from '../services/User';
import Messaging from '../services/Messaging';

type AuthContextType = {
  token?: string;
  tokenExpires?: number;
  user?: any;
  authenticated?: boolean;
  loading?: boolean;
  handleLogin: (email: string, password: string, captcha: string) => Promise<void | { error: string }>;
  handleLogout: () => void;
  handleClearToken: () => void;
  getProfile: () => void;

  currentSchool?: any;
  changeSchool?: (school: any) => void;
  lastSchool?: string;
  setLastSchool?: (id: string) => void;

  currentFranchisee?: any;
  changeFranchisee?: (school: any) => void;
  lastFranchisee?: string;
  setLastFranchisee?: (id: string) => void;
  franchisee?: any;
  userNotifications?: any;
  updateUserNotifications?: (userId: string) => void;

  userTotalUnreadMessages?: number;
  setUserTotalUnredMessages?: (totalUnred: number) => void;

  updateuserTotalUnreadMessages?: () => void;

  sideBarIsMini?: boolean;
  updateSideBarIsMini?: (isMini: boolean) => void;
};

const Context = createContext({} as AuthContextType);

const AuthProvider: React.FC = ({ children }) => {
  const { token, tokenExpires, user, authenticated, loading, handleLogin, handleLogout, handleClearToken, getProfile } =
    useAuth();
  const { currentSchool, changeSchool, lastSchool, setLastSchool } = useCurrentSchool();
  const { currentFranchisee, changeFranchisee, lastFranchisee, setLastFranchisee } = useCurrentFranchisee();
  const [userNotifications, setUserNotifications] = useState<[]>([]);
  const [userTotalUnreadMessages, setUserTotalUnredMessages] = useState(0);
  const [sideBarIsMini, setSideBarIsMini] = useState(false);

  const updateuserTotalUnreadMessages = async () => {
    const total = await Messaging(token).getTotalUnreadMessagesForLoggedUser(currentSchool.value);
    setUserTotalUnredMessages(total.data || 0);
  };
  const updateSideBarIsMini = (isMini: boolean) => {
    setSideBarIsMini(isMini);
  };

  const updateUserNotifications = async () => {
    try {
      const profile = await UserService(token).notifications(currentSchool.value);
      setUserNotifications(profile.data);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Context.Provider
      value={{
        token,
        tokenExpires,
        user,
        loading,
        authenticated,
        handleLogin,
        handleLogout,
        handleClearToken,
        getProfile,

        currentSchool,
        changeSchool,
        lastSchool,
        setLastSchool,

        currentFranchisee,
        changeFranchisee,
        lastFranchisee,
        setLastFranchisee,

        userNotifications,
        updateUserNotifications,

        userTotalUnreadMessages,
        setUserTotalUnredMessages,

        updateuserTotalUnreadMessages,

        updateSideBarIsMini,
        sideBarIsMini,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export { Context, AuthProvider };
