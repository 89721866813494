import CardInfo from '../../../components/Cards/CardInfo';
import ModalContent from '../../../components/Modals/Modal';
import React, { useEffect, useState } from 'react';
import { Row, Col, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import DataTable from '../../../components/Core/DataTable';
import AttendancesService from '../../../services/Attendances';
import { dateFormat } from '../../../helpers/dateFormat';

export const RatioTeachers: React.FC<{
  token: string;
  currentSchoolId: string;
}> = ({ token, currentSchoolId }) => {
  const [loading, setLoading] = useState(false);
  const [usersNotCheckIn, setUsersNotCheckIn] = useState<any[]>([]);
  const [ratioClass, setRatioClass] = useState<any[]>([]);
  const [usersCount, setUsersCount] = useState(0);
  const [classesCount, setClassesCount] = useState(0);

  const handleFetch = async () => {
    if (!currentSchoolId) return;
    try {
      setLoading(true);

      const result = await AttendancesService(token || '').listBySchool(currentSchoolId);
      const data: any[] = result?.data;
      const attendancesCheckInUsers = data.filter(
        (d: any) => d?.userId && d?.user && d?.attendanceType === 'CHECKIN' && d?.roomType === 'CLASSROOM',
      );
      const attendancesCheckOutUsers = data.filter(
        (d: any) => d?.userId && d?.user && d?.attendanceType === 'CHECKOUT' && d?.roomType === 'CLASSROOM',
      );
      const attendancesUsers: any[] = [];
      for (const u of attendancesCheckInUsers) {
        const filterCheckedIn = attendancesCheckInUsers.filter((a: any) => u?.userId === a?.userId)?.length;
        const filterCheckedOut = attendancesCheckOutUsers.filter((a: any) => u?.userId === a?.userId)?.length;
        if (filterCheckedIn > filterCheckedOut) {
          attendancesUsers.push(u);
        }
      }
      const attendancesKids = data.filter(
        (d: any) => d?.kidsId && d?.kids && d?.attendanceType === 'CHECKIN' && d?.roomType === 'CLASSROOM',
      );
      const clockedIn = data.filter(
        (d: any) => d?.userId && d?.user && d?.attendanceType === 'CLOCKIN' && d?.roomType === 'SCHOOL',
      );
      const notCheckIn: any[] = [];
      for (const c of clockedIn) {
        const filterCheckedIn = attendancesCheckInUsers.filter((a: any) => c?.userId === a?.userId)?.length;
        const filterCheckedOut = attendancesCheckOutUsers.filter((a: any) => c?.userId === a?.userId)?.length;
        if (filterCheckedIn <= filterCheckedOut) notCheckIn.push(c);
      }
      setUsersNotCheckIn(notCheckIn);

      const countUsers: any[] = [];
      const countClasses: any[] = [];
      for (const a of attendancesUsers) {
        if (a?.userId && !countUsers.includes(a?.userId)) {
          countUsers.push(a.userId);
        }
        if (a?.roomId && !countClasses.includes(a?.roomId)) {
          countClasses.push({ classId: a.roomId, nameClass: a?.class?.name || '' });
        }
      }
      const classRatios: any[] = [];
      for (const c of countClasses) {
        const findClass = classRatios.find((f: any) => f?.classId === c?.classId);
        if (findClass) continue;
        const countStaff = attendancesUsers.filter((a: any) => a?.roomId === c.classId);
        const countKids = attendancesKids.filter((a: any) => a?.roomId === c.classId);
        classRatios.push({
          classId: c?.classId,
          nameClass: c?.nameClass || '',
          childrenCheckedIn: countKids?.length || 0,
          staffCheckedIn: countStaff?.length || 0,
        });
      }
      setUsersCount(countUsers?.length || 0);
      setClassesCount(countClasses?.length || 0);
      setRatioClass(classRatios);
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Error fetching list');
      return console.error(message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleFetch();
  }, [currentSchoolId]);

  const [toggleModal, setToggleModal] = useState(false);
  const [activeTab, setActiveTab] = useState('1');

  const customStyles = {
    navLink: {
      minHeight: '42px',
      paddingTop: '8px',
      cursor: 'pointer',
    },
  };

  const columns = [
    {
      name: 'Name',
      route: (row: any) => `/users/${row?.user?.id}/profile`,
      label: (row: any) => `${row?.user?.firstName || '-'} ${row?.user?.lastName || '-'}`,
      grow: 2,
    },
    {
      name: 'Clocked In',
      selector: (row: any) => <>{dateFormat(row?.createdAt)}</>,
      grow: 2,
    },
    {
      name: 'Last Class Checked Out Of',
      selector: (row: any) => <>{row?.user?.lastCheckOutClassName || `-`}</>,
      grow: 2,
    },
  ];

  const columnsRatio = [
    {
      name: 'Class Name',
      route: (row: any) => `/class/${row?.classId}/details`,
      label: (row: any) => `${row?.nameClass || '-'}`,
      grow: 2,
    },
    {
      name: 'Children Checked In',
      selector: (row: any) => <>{row?.childrenCheckedIn}</>,
      grow: 2,
    },
    {
      name: 'Staff Checked In',
      selector: (row: any) => <>{row?.staffCheckedIn}</>,
      grow: 2,
    },
  ];

  const handleToggleModal = () => {
    setToggleModal(!toggleModal);
  };

  return (
    <>
      <CardInfo class="bg-primary card-info-mg-button" title="Ratios" icon="fa-clock" toggle={handleToggleModal}>
        <Row className="mt-4">
          <Col md="6" xs="6" className="text-center" style={{ padding: 0, borderRight: '#CCC 1px solid' }}>
            <h2 style={{ fontSize: '25px', fontWeight: 900, color: '#525f7f' }}>{usersCount}</h2>
            <p style={{ fontSize: '10px' }}>Teachers</p>
          </Col>
          <Col md="6" xs="6" className="text-center" style={{ padding: 0 }}>
            <h2 style={{ fontSize: '25px', fontWeight: 900, color: '#525f7f' }}>{classesCount}</h2>
            <p style={{ fontSize: '10px' }}>Classrooms</p>
          </Col>
        </Row>
      </CardInfo>
      <ModalContent title="Ratios" modal={toggleModal} toggle={handleToggleModal} size="xl">
        <Row>
          <Col xs="12">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={`${activeTab === '1' ? 'active' : ''}`}
                  onClick={() => {
                    setActiveTab('1');
                  }}
                  style={customStyles.navLink}
                >
                  Staff Not Checked Into a Class
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={`${activeTab === '2' ? 'active' : ''}`}
                  onClick={() => {
                    setActiveTab('2');
                  }}
                  style={customStyles.navLink}
                >
                  Classroom Ratios
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
          <Col xs="12">
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <DataTable
                  header={false}
                  title=""
                  columns={[...columns]}
                  data={usersNotCheckIn}
                  totalRows={usersNotCheckIn?.length}
                  loading={loading}
                  button={false}
                  buttonUrl=""
                  onChangeRowsPerPage={(() => {}) as any}
                  onChangePage={(() => {}) as any}
                />
              </TabPane>
              <TabPane tabId="2">
                <DataTable
                  header={false}
                  title=""
                  columns={[...columnsRatio]}
                  data={ratioClass}
                  totalRows={ratioClass?.length || 0}
                  loading={loading}
                  button={false}
                  buttonUrl=""
                  onChangeRowsPerPage={(() => {}) as any}
                  onChangePage={(() => {}) as any}
                />
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </ModalContent>
    </>
  );
};
