import { Row, Col, Button, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import { useContext, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import KidsService from '../../../services/Kids';
import confirmAlert from '../../../helpers/confirmAlert';

type VacationTimeKidsListProps = {
  kidsId?: string;
  promoCodeDiscountReg?: number | null;
  promoCodeDistountTuition?: number | null;
};

const PromoCodeKidsList = ({ kidsId, promoCodeDiscountReg, promoCodeDistountTuition }: VacationTimeKidsListProps) => {
  const { token }: any = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [textPromoCodeDistountTuition, setTextPromoCodeDistountTuition] = useState(promoCodeDistountTuition);
  const [textPromoCodeDiscountReg, setTextPromoCodeDiscountReg] = useState(promoCodeDiscountReg);

  const removePromoCode = async () => {
    if (loading) return;
    if (!kidsId) return;
    setLoading(true);
    try {
      const body = {
        PromoCodeDiscountReg: null,
        PromoCodeDiscountTuition: null,
        PromoCodeId: null,
      };
      await KidsService(token).removePromoCode(kidsId, body);
      setTextPromoCodeDistountTuition(null);
      setTextPromoCodeDiscountReg(null);
      toast.success(`Deleted successfully!`);
    } catch (e: any) {
      console.log(e);
      const message = String(e?.response?.data?.message || 'Delete error!');
      toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setTextPromoCodeDistountTuition(promoCodeDistountTuition);
    setTextPromoCodeDiscountReg(promoCodeDiscountReg);
  }, [promoCodeDiscountReg, promoCodeDistountTuition]);

  return (
    <>
      <h6 className="heading-small text-muted mb-4 mt-4">Promo Codes</h6>

      {textPromoCodeDiscountReg === null && textPromoCodeDistountTuition === null && (
        <Col>
          <div className="alert alert-info" role="alert">
            no promo code applied
          </div>
        </Col>
      )}
      {(textPromoCodeDiscountReg !== null || textPromoCodeDistountTuition !== null) && (
        <>
          <Col>
            <Row>
              <Col xs="12">
                <Button
                  size="sm"
                  className="bg-danger border-danger text-white"
                  onClick={() => {
                    return confirmAlert({
                      title: 'Delete',
                      messageType: 'DELETE',
                      onClickYes: removePromoCode,
                      label: 'PromoCode',
                    });
                  }}
                >
                  <i className="fas fa-trash" />
                  &nbsp; Remove PromoCode
                </Button>
              </Col>
              <Col>
                <label className="form-control-label">Discount in Register</label>
                <InputGroup className="input-group-alternative">
                  <Input type="text" value={promoCodeDiscountReg || 0} disabled />
                  <InputGroupAddon addonType="append">
                    <InputGroupText style={{ backgroundColor: '#e9ecef' }}>
                      <i className="fas fa-percent" />
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </Col>
              <Col>
                <label className="form-control-label">Discount in Tuition</label>
                <InputGroup className="input-group-alternative">
                  <Input type="text" value={promoCodeDistountTuition || 0} disabled />
                  <InputGroupAddon addonType="append">
                    <InputGroupText style={{ backgroundColor: '#e9ecef' }}>
                      <i className="fas fa-percent" />
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </Col>
            </Row>
          </Col>
        </>
      )}
    </>
  );
};

export default PromoCodeKidsList;
