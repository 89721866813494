import http from '../helpers/http';

const UserCustomer = (token: string) => {
  const headers = {
    'x-access-token': token,
  };

  return {
    get: async (userId: string, schoolId: string) => {
      const result = await http.get(`/user-customer/${userId}/school/${schoolId}`, { headers });
      return result.data;
    },
    create: async (body: { userId: string; schoolId: string; options: any }) => {
      const result = await http.post(`/user-customer`, body, { headers });
      return result.data;
    },
    deletePaymentMethod: async (userId: string, schoolId: string, paymentMethodId: string) => {
      const result = await http.delete(
        `/user-customer/${userId}/school/${schoolId}/payment-method/${paymentMethodId}`,
        { headers },
      );
      return result.data;
    },
  };
};

export default UserCustomer;
