/* eslint-disable prefer-spread */
/* eslint-disable max-lines */
import { Col, Modal, Row } from 'reactstrap';
import { useEffect, useState } from 'react';
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';

type W4DocumentViewModalProps = {
  isOpen: boolean;
  data: any;
  toggleModal: (e?: any) => void;
};

const W4DocumentViewModal = ({ isOpen, data, toggleModal }: W4DocumentViewModalProps) => {
  const [pdf, setPdf] = useState('');

  const coordinatesSign = {
    x: 100,
    y: 150,
    height: 40,
    opacity: 1,
  };

  const coordinatesDate = {
    x: 460,
    y: 167,
    size: 12,
    color: rgb(0, 0, 0),
  };

  useEffect(() => {
    if (isOpen) createPdf();
  }, [isOpen]);

  const pdfUrl = 'https://arb-systems-s3-public.s3.us-east-2.amazonaws.com/public/Employment-Eligibility/fw4.pdf';

  const readOnlyFields = (pdfForm: any) => {
    try {
      const fieldNames = pdfForm.getFields();
      for (const f of fieldNames) {
        const type = f.constructor.name;
        const fieldName = f.getName();
        if (type === 'PDFTextField') {
          pdfForm.getTextField(fieldName).enableReadOnly();
        }
        if (type === 'PDFDropdown') {
          pdfForm.getDropdown(fieldName).enableReadOnly();
        }
        if (type === 'PDFCheckBox') {
          pdfForm.getCheckBox(fieldName).enableReadOnly();
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const createPdf = async () => {
    try {
      if (!data) return;
      const arrayBuffer = await fetch(pdfUrl).then((res) => res.arrayBuffer());
      const pdfDoc = await PDFDocument.load(arrayBuffer);
      const pdfForm = pdfDoc.getForm();
      readOnlyFields(pdfForm);
      const pages = pdfDoc.getPages();
      const firstPage = pages[0];
      if (firstPage) {
        if (data?.options?.signPng) {
          const pngImage = await pdfDoc.embedPng(data?.options?.signPng);
          firstPage.drawImage(pngImage, coordinatesSign);
        }

        const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);
        firstPage.drawText(data?.options?.signatureDate, {
          ...coordinatesDate,
          font: timesRomanFont,
        });
      }
      const fieldNames = pdfForm.getFields().map((f) => f.getName());

      const firstNameField = pdfForm.getTextField(fieldNames[0]);
      firstNameField.setText(data?.options?.fields?.firstName);
      firstNameField.enableReadOnly();

      const lastNameField = pdfForm.getTextField(fieldNames[1]);
      lastNameField.setText(data?.options?.fields?.lastName);
      lastNameField.enableReadOnly();

      const addressField = pdfForm.getTextField(fieldNames[2]);
      addressField.setText(data?.options?.fields?.address);
      addressField.enableReadOnly();

      const cityField = pdfForm.getTextField(fieldNames[3]);
      cityField.setText(data?.options?.fields?.city);
      cityField.enableReadOnly();

      const ssnField = pdfForm.getTextField(fieldNames[4]);
      ssnField.setText(data?.options?.fields?.ssn);
      ssnField.enableReadOnly();

      const employerNameField = pdfForm.getTextField(fieldNames[15]);
      employerNameField.setText(data?.options?.fields?.employerName);
      employerNameField.enableReadOnly();

      const dateField = pdfForm.getTextField(fieldNames[16]);
      dateField.setText(data?.options?.fields?.date);
      dateField.enableReadOnly();

      const einField = pdfForm.getTextField(fieldNames[17]);
      einField.setText(data?.options?.fields?.ein);
      einField.enableReadOnly();

      const m1 = pdfForm.getCheckBox(fieldNames[5]);
      m1.enableReadOnly();
      const m2 = pdfForm.getCheckBox(fieldNames[6]);
      m2.enableReadOnly();
      const m3 = pdfForm.getCheckBox(fieldNames[7]);
      m3.enableReadOnly();
      if (data?.options?.fields?.married === '1') pdfForm.getCheckBox(fieldNames[5]).check();
      if (data?.options?.fields?.married === '2') pdfForm.getCheckBox(fieldNames[6]).check();
      if (data?.options?.fields?.married === '3') pdfForm.getCheckBox(fieldNames[7]).check();
      for (const n of data?.options?.fields?.amounts) {
        if (n?.i) {
          const f = pdfForm.getTextField(fieldNames[n?.i]);
          f.setText(String(n?.v || 0));
          f.enableReadOnly();
        }
      }

      const pdfBytes = await pdfDoc.save();

      const blob = new Blob([pdfBytes], { type: 'application/pdf' });
      const docUrl = URL.createObjectURL(blob);
      setPdf(docUrl);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Modal className="modal-dialog-centered" size="xl" isOpen={isOpen} toggle={(e: any) => toggleModal(e)}>
        <div className="modal-header pb-1">
          <h5 className="modal-title">W4</h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={(e) => toggleModal(e)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body pt-0">
          <Row className="mt-2">
            <Col lg="12" className="mt-4" style={{ maxHeight: '500px', overflow: 'scroll' }}>
              <iframe src={pdf} style={{ minHeight: 500, width: '100%' }} />
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default W4DocumentViewModal;
