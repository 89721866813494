import { Modal, Button } from 'reactstrap';

type ModalDangerProps = {
  isModal: boolean;
  modalTitle: string;
  onClose: (value: boolean) => void;
  children?: any;
};

const DailyQuestionareShowModal = ({ isModal, modalTitle, onClose, children }: ModalDangerProps) => {
  return (
    <>
      <Modal className="modal-dialog-centered" isOpen={isModal} toggle={() => onClose(false)}>
        <div className="modal-header bg-primary">
          <h3 className="modal-title text-white" id="modal-title-notification">
            <i className="fas fa-receipt" />
            &nbsp; Daily Questionnaire
          </h3>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => onClose(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body pt-3">
          <div className="row pb-2">
            <div className="col text-center">
              <h3 className="heading m-0 p-0" style={{ textTransform: 'none' }}>
                {modalTitle}
              </h3>
            </div>
          </div>
          {children}
        </div>
        <div className="modal-footer">
          <Button
            className="text-white ml-auto"
            color="primary"
            data-dismiss="modal"
            type="button"
            onClick={() => onClose(false)}
          >
            Close
          </Button>
        </div>
      </Modal>
    </>
  );
};
export default DailyQuestionareShowModal;
