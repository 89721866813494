/* eslint-disable max-lines */
import { Button, Card, CardHeader, CardBody, FormGroup, Form, Input, Container, Row, Col } from 'reactstrap';
import { toast } from 'react-toastify';
import { useContext, useState, useEffect, useRef } from 'react';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import CommonHeader from '../../../components/Headers/CommonHeader';
import UserService from '../../../services/User';
import InputMask from 'react-input-mask';
import { toLocaleFormat, toSystemFormat } from '../../../helpers/dateFormat';

const Profile = () => {
  const { user, token, getProfile }: any = useContext(AuthContext);
  const imageRef = useRef(null as any);
  const [isDisabled, setIsDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [workPhone, setHomePhone] = useState('');
  const [birthday, setBirthday] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [pictureUrl, setPictureUrl] = useState(user?.picture || '');
  const [picture, setPicture] = useState();

  useEffect(() => {
    setIsDisabled(true);
    setFirstName(user?.firstName || '');
    setLastName(user?.lastName || '');
    setEmail(user?.email || '');
    setPhone(user?.phone || '');
    setHomePhone(user?.workPhone || '');
    setBirthday(user?.birthday ? toLocaleFormat(user?.birthday) : '');
    const address = user?.addresses?.[0] || {};
    setZipCode(address?.zipCode || '');
    setAddress(address?.address || '');
    setCity(address?.city || '');
    setCountry(address?.country || '');
  }, []);

  const showOpenFileDialog = () => {
    if (isDisabled) {
      return;
    }
    if (imageRef?.current?.click) {
      imageRef.current.click();
    }
  };

  const onChangeFile = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];
    setPicture(file);
    setPictureUrl(URL.createObjectURL(file));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);
    setIsDisabled(true);

    const body = {
      firstName,
      lastName,
      birthday: birthday ? toSystemFormat(birthday) : '',
      phone,
      workPhone,
      addresses: [
        {
          zipCode,
          address,
          city,
          country,
          stateId: 1,
        },
      ],
    };

    try {
      await UserService(token).update(user.id, body);
      if (picture) {
        const formData = new FormData();
        formData.append('picture', picture as any);
        await UserService(token).picture(user.id, formData);
      }
      await getProfile();
      toast.success('Updated successfully!');
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Update error!');
      toast.error(message);
    }

    setLoading(false);
  };

  return (
    <>
      <CommonHeader />
      <Container className="mt--8" fluid>
        <Row>
          <Col className="order-xl-2" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">My account</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button color="primary" onClick={(_) => setIsDisabled(false)} size="sm">
                      Edit Profile
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmit}>
                  <h6 className="heading-small text-muted mb-4">
                    Picture <small style={{ textTransform: 'none' }}>(Recommended size: 800 x 800px)</small>
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <input
                            id="img-picture"
                            name="picture"
                            type="file"
                            accept=".png,.jpg,.jpeg"
                            style={{ display: 'none' }}
                            ref={imageRef}
                            disabled={isDisabled}
                            onChange={onChangeFile}
                          />
                          <img
                            alt={`${user.firstName}`}
                            className="rounded-circle"
                            src={pictureUrl ? `${pictureUrl}` : require('../../../assets/img/user-default.png').default}
                            style={{ width: 135, height: 135, cursor: isDisabled ? 'not-allowed' : 'pointer' }}
                            onClick={showOpenFileDialog}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">User information</h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-first-name">
                            First name
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-first-name"
                            placeholder="First name"
                            type="text"
                            disabled={isDisabled}
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-last-name">
                            Last name
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-last-name"
                            placeholder="Last name"
                            type="text"
                            disabled={isDisabled}
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-email">
                            Email address
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-email"
                            placeholder="Email address"
                            type="email"
                            disabled={true}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label">Phone</label>
                          <Input
                            className="form-control-alternative"
                            placeholder="Phone"
                            type="text"
                            mask="(999) 999-9999"
                            tag={InputMask}
                            disabled={isDisabled}
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label">Work Phone</label>
                          <Input
                            className="form-control-alternative"
                            placeholder="Work Phone"
                            type="text"
                            mask="(999) 999-9999"
                            tag={InputMask}
                            disabled={isDisabled}
                            value={workPhone}
                            onChange={(e) => setHomePhone(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-birthday">
                            Birthday
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-birthday"
                            placeholder="Birthday"
                            type="text"
                            mask="99/99/9999"
                            tag={InputMask}
                            disabled={isDisabled}
                            value={birthday}
                            onChange={(e) => setBirthday(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">Address information</h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-address">
                            Address
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-address"
                            placeholder="Address"
                            type="text"
                            disabled={isDisabled}
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-city">
                            City
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-city"
                            placeholder="City"
                            type="text"
                            disabled={isDisabled}
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-country">
                            Country
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-country"
                            placeholder="Country"
                            type="text"
                            disabled={isDisabled}
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-country">
                            Postal code
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-postal-code"
                            placeholder="Postal code"
                            type="number"
                            disabled={isDisabled}
                            value={zipCode}
                            onChange={(e) => setZipCode(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Button className="my-4" color="primary" type="submit" disabled={isDisabled}>
                      Save
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Profile;
