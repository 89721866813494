/* eslint-disable max-lines */
import moment from 'moment';
import { Button, Card, CardBody, Col, Row, Table } from 'reactstrap';
import EmploymentDocumentsService from '../../../../services/EmploymentDocuments';
import BankingAccountsService from '../../../../services/BankingAccounts';
import { useEffect, useState } from 'react';
import confirmAlert from '../../../../helpers/confirmAlert';
import W4DocumentViewModal from './W4DocumentViewModal';
import I9DocumentViewModal from './I9DocumentViewModal';
import I9SpanishDocumentViewModal from './I9SpanishDocumentViewModal';
import BankingAccountModal from '../../NewHire/BankingAccountModal';

type Props = {
  currentUser: any;
  token: any;
  typeAndTitle: any[];
  documentsData?: any[];
  removeAlert: (e: any, id: string) => Promise<void>;
  handleDocument: (e?: any) => void;
};

const NewHireDocumentsView = ({
  currentUser,
  token,
  typeAndTitle,
  documentsData,
  removeAlert,
  handleDocument,
}: Props) => {
  const [uploads, setUploads] = useState<any[]>([]);
  const [banks, setBanks] = useState<any[]>([]);
  const [showBankingModal, setShowBankingModal] = useState(false);
  const [currentBankingAccount, setCurrentBankingAccount] = useState<any>({});
  const [currentW4Document, setCurrentW4Document] = useState<any[]>();
  const [showW4Document, setShowW4Document] = useState<boolean>(false);
  const [currentI9Document, setCurrentI9Document] = useState<any[]>();
  const [showI9Document, setShowI9Document] = useState<boolean>(false);
  const [currentI9SpanishDocument, setCurrentI9SpanishDocument] = useState<any[]>();
  const [showI9SpanishDocument, setShowI9SpanishDocument] = useState<boolean>(false);

  useEffect(() => {
    if (currentUser?.employee?.id) {
      getEmploymentDocuments();
      getBankAccounts();
    }
  }, [currentUser]);

  const getBankAccounts = async () => {
    try {
      const result = await BankingAccountsService(token).list(currentUser?.employee?.id);
      const data: any[] = result?.data || [];
      const newBanks = [];
      for (const u of data) {
        newBanks.push({
          id: u?.id,
          bankName: u?.bankName,
          routingNumber: u?.routingNumber,
          accountNumber: u?.accountNumber,
          url: u?.blankCheck,
          date: moment(u?.createdAt || '').format('YYYY-MM-DD'),
        });
      }
      setBanks(newBanks);
    } catch (e: any) {
      console.error(e?.response?.data?.message || 'Invalid employee ID');
    }
  };

  const getEmploymentDocuments = async () => {
    try {
      const result = await EmploymentDocumentsService(token).list(currentUser?.employee?.id);
      const data: any[] = result?.data || [];
      const newUploads = [];
      for (const u of data) {
        newUploads.push({ id: u?.id, title: u?.documentType, url: u?.document });
      }
      setUploads(newUploads);
    } catch (e: any) {
      console.error(e?.response?.data?.message || 'Invalid employee ID');
    }
  };

  const handleW4Document = () => {
    const doc = documentsData?.find((d) => d?.type === 'W4');
    setCurrentW4Document(doc);
    setShowW4Document(true);
  };

  const handleI9Document = () => {
    const doc = documentsData?.find((d) => d?.type === 'I9');
    setCurrentI9Document(doc);
    setShowI9Document(true);
  };

  const handleI9SpanishDocument = () => {
    const doc = documentsData?.find((d) => d?.type === 'I9-Spanish');
    setCurrentI9SpanishDocument(doc);
    setShowI9SpanishDocument(true);
  };

  const onRemoveFile = async (event: any, id: string) => {
    event.stopPropagation();
    event.preventDefault();
    return confirmAlert({
      title: 'Delete',
      messageType: 'DELETE',
      message: 'Are you sure to delete this document?',
      onClickYes: () => deleteEmploymentDocuments(id),
    });
  };

  const onRemoveBankAccount = async (event: any, id: string) => {
    event.stopPropagation();
    event.preventDefault();
    return confirmAlert({
      title: 'Delete',
      messageType: 'DELETE',
      message: 'Are you sure to delete this banking account?',
      onClickYes: () => deleteBankingAccount(id),
    });
  };

  const deleteEmploymentDocuments = async (id: string) => {
    try {
      await EmploymentDocumentsService(token).delete(id);
      getEmploymentDocuments();
    } catch (e: any) {
      console.error(e?.response?.data?.message || 'Invalid ID');
    }
  };

  const deleteBankingAccount = async (id: string) => {
    try {
      await BankingAccountsService(token).delete(id);
      getBankAccounts();
    } catch (e: any) {
      console.error(e?.response?.data?.message || 'Invalid ID');
    }
  };

  return (
    <Card className="shadow border-0 mt-4">
      <CardBody className="pb-4">
        <Row>
          <Col lg="12">
            <div className="d-flex justify-content-between align-items-center py-1">
              <h3 className="text-uppercase text-primary">HIRING DOCUMENTS</h3>
            </div>
          </Col>
        </Row>
        <Row>
          <Table className="mt-4" responsive>
            <thead>
              <tr>
                <th style={{ background: '#f3f3f3', borderTop: '1px solid #dadada' }}>
                  <div className="d-flex align-items-center py-2">Uploads - Employment Eligibility Documents</div>
                </th>
              </tr>
            </thead>
            <tbody></tbody>
          </Table>
          <Table className="align-items-center table-flush" responsive>
            <tbody>
              {uploads?.length ? (
                <>
                  <tr>
                    <th>Title</th>
                    <th>Action</th>
                  </tr>
                  {uploads.map((s: any) => (
                    <tr key={`uploads-${s.title}`}>
                      <td>
                        <i className="fas fa-file mr-2" />
                        <a
                          href={`${s.url}`}
                          target="_blank"
                          style={{ textDecoration: 'underline', color: '#5e72e4', backgroundColor: 'transparent' }}
                        >
                          {s.title}
                        </a>
                      </td>
                      <td>
                        {s.title && (
                          <div className="d-flex align-items-center" style={{ gap: 8 }}>
                            <a href={`${s.url}`} target="_blank">
                              <Button style={{ border: 'none', boxShadow: 'none', padding: 0 }}>
                                <i className="fas fa-eye" style={{ cursor: 'pointer' }}></i>
                              </Button>
                            </a>
                            <Button style={{ border: 'none', boxShadow: 'none', padding: 0, marginLeft: 10 }}>
                              <i
                                className="far fa-trash-alt text-danger"
                                style={{ cursor: 'pointer' }}
                                onClick={(e) => onRemoveFile(e, s.id)}
                              ></i>
                            </Button>
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <>
                  <tr>
                    <td>
                      <div className="d-flex align-items-center">No Document</div>
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </Table>
        </Row>
        <Row>
          <Table className="mt-4" responsive>
            <thead>
              <tr>
                <th colSpan={4} style={{ background: '#f3f3f3', borderTop: '1px solid #dadada' }}>
                  <div className="d-flex align-items-center py-1">Banking Account</div>
                </th>
              </tr>
              <tr>
                <th>Bank Name</th>
                <th>Registration Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {banks?.length ? (
                <>
                  {banks.map((s: any, i: number) => (
                    <tr key={`bank-${i}`}>
                      <td>
                        <div className="d-flex align-items-center">
                          <i className="fas fa-file mr-2" />
                          <a
                            href={`${s.url}`}
                            target="_blank"
                            style={{
                              textDecoration: 'underline',
                              color: '#5e72e4',
                              backgroundColor: 'transparent',
                            }}
                          >
                            {s.bankName}
                          </a>
                        </div>
                      </td>
                      <td>{moment(s.date).format('MM/DD/YYYY')}</td>
                      <td>
                        <div className="d-flex align-items-center" style={{ gap: 8 }}>
                          <Button
                            style={{ border: 'none', boxShadow: 'none', padding: 0 }}
                            onClick={() => {
                              setCurrentBankingAccount(s?.id || '');
                              setShowBankingModal(true);
                            }}
                          >
                            <i className="fas fa-eye" />
                          </Button>
                          <Button
                            style={{ border: 'none', boxShadow: 'none', padding: 0 }}
                            onClick={(e) => onRemoveBankAccount(e, s.id)}
                          >
                            <i className="far fa-trash-alt text-danger" style={{ cursor: 'pointer' }} />
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <>
                  <tr>
                    <td>
                      <div className="d-flex align-items-center">No Data</div>
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </Table>
        </Row>
        <Row>
          <Table className="mt-4" responsive>
            <thead>
              <tr>
                <th colSpan={4} style={{ background: '#f3f3f3', borderTop: '1px solid #dadada' }}>
                  <div className="d-flex align-items-center py-1">HIRING AGREEMENTS</div>
                </th>
              </tr>
              <tr>
                <th>Name</th>
                <th>Added Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {typeAndTitle?.map((value, i) => {
                const d = documentsData?.find((r) => r?.type === value.type);
                return (
                  <tr key={`document-${i}`}>
                    <td>
                      <div className="d-flex align-items-center">
                        <i className="fas fa-file mr-2" />
                        {value?.title}
                      </div>
                    </td>
                    <td>
                      {d ? (
                        <>
                          {`${moment().format('MM/DD/YYYY')}`}{' '}
                          <i className="fas fa-check-circle" style={{ color: '#02ce0d' }} />
                        </>
                      ) : (
                        <>
                          Incomplete <i className="fas fa-ban" style={{ color: 'red' }} />
                        </>
                      )}
                    </td>
                    <td>
                      <div className="d-flex align-items-center" style={{ gap: 8 }}>
                        {d ? (
                          <>
                            <Button
                              style={{ border: 'none', boxShadow: 'none', padding: 0 }}
                              onClick={(e: any) => {
                                e?.preventDefault();
                                handleDocument(d);
                              }}
                            >
                              <i className="fas fa-eye" />
                            </Button>

                            <Button
                              style={{ border: 'none', boxShadow: 'none', padding: 0 }}
                              onClick={(e) => removeAlert(e, d?.id || '')}
                            >
                              <i className="far fa-trash-alt text-danger" />
                            </Button>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Row>

        <Row>
          <Table className="mt-4" responsive>
            <thead>
              <tr>
                <th colSpan={4} style={{ background: '#f3f3f3', borderTop: '1px solid #dadada' }}>
                  <div className="d-flex align-items-center py-1">COMPANY HANDBOOKS</div>
                </th>
              </tr>
              <tr>
                <th>Name</th>
                <th>Added Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {documentsData?.find((d) => d?.type === 'W4') ? (
                <tr>
                  <td>
                    <div className="d-flex align-items-center">
                      <i className="fas fa-file mr-2" />
                      W4
                    </div>
                  </td>
                  <td>
                    {moment(documentsData?.find((d) => d?.type === 'W4')?.options?.signatureDate).format('MM/DD/YYYY')}
                  </td>
                  <td>
                    <div className="d-flex align-items-center" style={{ gap: 8 }}>
                      <Button
                        style={{ border: 'none', boxShadow: 'none', padding: 0 }}
                        onClick={(e: any) => {
                          e?.preventDefault();
                          handleW4Document();
                        }}
                      >
                        <i className="fas fa-eye" />
                      </Button>
                      <Button
                        style={{ border: 'none', boxShadow: 'none', padding: 0 }}
                        onClick={(e) => removeAlert(e, documentsData?.find((d) => d?.type === 'W4')?.id || '')}
                      >
                        <i className="far fa-trash-alt text-danger" />
                      </Button>
                    </div>
                  </td>
                </tr>
              ) : (
                <></>
              )}
              {documentsData?.find((d) => d?.type === 'I9') ? (
                <tr>
                  <td>
                    <div className="d-flex align-items-center">
                      <i className="fas fa-file mr-2" />
                      I9
                    </div>
                  </td>
                  <td>
                    {moment(documentsData?.find((d) => d?.type === 'I9')?.options?.signatureDate).format('MM/DD/YYYY')}
                  </td>
                  <td>
                    <div className="d-flex align-items-center" style={{ gap: 8 }}>
                      <Button
                        style={{ border: 'none', boxShadow: 'none', padding: 0 }}
                        onClick={(e: any) => {
                          e?.preventDefault();
                          handleI9Document();
                        }}
                      >
                        <i className="fas fa-eye" />
                      </Button>
                      <Button
                        style={{ border: 'none', boxShadow: 'none', padding: 0 }}
                        onClick={(e) => removeAlert(e, documentsData?.find((d) => d?.type === 'I9')?.id || '')}
                      >
                        <i className="far fa-trash-alt text-danger" />
                      </Button>
                    </div>
                  </td>
                </tr>
              ) : (
                <></>
              )}
              {documentsData?.find((d) => d?.type === 'I9-Spanish') ? (
                <tr>
                  <td>
                    <div className="d-flex align-items-center">
                      <i className="fas fa-file mr-2" />
                      I9 - Spanish
                    </div>
                  </td>
                  <td>
                    {moment(documentsData?.find((d) => d?.type === 'I9-Spanish')?.options?.signatureDate).format(
                      'MM/DD/YYYY',
                    )}
                  </td>
                  <td>
                    <div className="d-flex align-items-center" style={{ gap: 8 }}>
                      <Button
                        style={{ border: 'none', boxShadow: 'none', padding: 0 }}
                        onClick={(e: any) => {
                          e?.preventDefault();
                          handleI9SpanishDocument();
                        }}
                      >
                        <i className="fas fa-eye" />
                      </Button>
                      <Button
                        style={{ border: 'none', boxShadow: 'none', padding: 0 }}
                        onClick={(e) => removeAlert(e, documentsData?.find((d) => d?.type === 'I9-Spanish')?.id || '')}
                      >
                        <i className="far fa-trash-alt text-danger" />
                      </Button>
                    </div>
                  </td>
                </tr>
              ) : (
                <></>
              )}
            </tbody>
          </Table>
        </Row>
      </CardBody>
      <BankingAccountModal
        showBankingModal={showBankingModal}
        bankAccountId={currentBankingAccount}
        banks={banks}
        setShowBankingModal={setShowBankingModal}
      />
      <W4DocumentViewModal
        data={currentW4Document}
        isOpen={showW4Document}
        toggleModal={() => setShowW4Document(false)}
      />
      <I9DocumentViewModal
        data={currentI9Document}
        isOpen={showI9Document}
        toggleModal={() => setShowI9Document(false)}
      />
      <I9SpanishDocumentViewModal
        data={currentI9SpanishDocument}
        isOpen={showI9SpanishDocument}
        toggleModal={() => setShowI9SpanishDocument(false)}
      />
    </Card>
  );
};

export default NewHireDocumentsView;
