import { useEffect, useState, useContext } from 'react';
import {
  Card,
  CardBody,
  CardText,
  Row,
  Col,
  Button,
  Nav,
  NavItem,
  NavLink,
  CardHeader,
  TabContent,
  TabPane,
} from 'reactstrap';
import ModalContent from '../../../components/Modals/Modal';
import DataTable from '../../../components/Core/DataTable';
import { createOptionsPage } from '../../../entities/OptionsPaged';
import { createPagedMeta } from '../../../entities/PagedMeta';
import { FilteredOptionsInterface } from '../../../entities/FilteredOptions';
import KidsService from '../../../services/Kids';
import BillingNotificationsService from '../../../services/BillingNotifications';
import KidsInterface from '../../../interfaces/KidsInterface';
import { toast } from 'react-toastify';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import { Link } from 'react-router-dom';
import isAdmin from '../../../helpers/isAdmin';
import BillingParentsModal from '../../../views/pages/Billing/BillingParentsModal';
import { isMobile } from 'react-device-detect';

export const ErrorsList: React.FC<{}> = () => {
  const { currentSchool = {}, token, user } = useContext(AuthContext);
  const isTablet = window.innerWidth <= 1024 && !isMobile;
  const [modalBillingParents, setModalBillingParents] = useState(false);
  const errorTypes = {
    NO_PARENTS: 'NO_PARENTS',
  };
  const errorMessages: any = {
    NO_PARENTS: 'No parents linked to their profile.',
  };
  const isAdminUser = isAdmin(user);

  const columnsKids = [
    {
      name: 'Name',
      selector: (row: any) => (
        <Link to={`/kids/${row.id}`}>
          <span>{`${row?.firstName || '-'} ${row?.lastName || '-'}`}</span>
        </Link>
      ),
      grow: 1,
    },
    {
      name: 'Issue',
      selector: (row: any) => row.type,
      grow: 1,
      cell: () => <span className="mb-0">KIDS</span>,
    },
    {
      name: 'Message',
      selector: (row: any) => row.errorType,
      grow: 2,
      cell: (row: any) => <span className="mb-0">{errorMessages[row.errorType]}</span>,
    },
  ];

  const collumnName = isAdminUser
    ? {
        name: 'Name',
        selector: (row: any) => (
          <>
            <Link to={`/users/${row.id}/profile`}>
              <span>{`${row?.user?.firstName || '-'} ${row?.user?.lastName || '-'}`}</span>
            </Link>
          </>
        ),
        grow: 1,
      }
    : {
        name: 'Name',
        selector: (row: any) => (
          <>
            <a href="#" onClick={(e) => toggleModalBillingParents(e)}>{`${row?.user?.firstName || '-'} ${
              row?.user?.lastName || '-'
            }`}</a>
          </>
        ),
        grow: 1,
      };

  const columnsBilling = [
    collumnName,
    {
      name: 'Issue',
      selector: (row: any) => row.type,
      grow: 1,
      cell: () => <span className="mb-0">BILLING</span>,
    },
    {
      name: 'Message',
      selector: (row: any) => row.message,
      grow: 2,
      cell: (row: any) => <span style={{ whiteSpace: 'normal' }}>{row.message}</span>,
    },
  ];

  const [kidsErrors, setKidsErrors] = useState([] as any[]);
  const [billingErrors, setBillingErrors] = useState([] as any[]);
  const [loading, setLoading] = useState(false);
  const [pagedMeta] = useState(createPagedMeta());
  const [filteredOptions, setFilteredOptions] = useState({
    filters: [],
    options: createOptionsPage(pagedMeta),
  });
  const [toggleModal, setToggleModal] = useState(false);
  const [activeTab, setActiveTab] = useState('1');
  const customStyles = {
    navLink: {
      minHeight: '42px',
      paddingTop: '8px',
      cursor: 'pointer',
    },
  };
  const toggleModalBillingParents = (e: any) => {
    e.preventDefault();
    setModalBillingParents(!modalBillingParents);
  };

  useEffect(() => {
    if (!currentSchool?.value) return;
    const newFilteredOptions = JSON.parse(JSON.stringify(filteredOptions));
    if (!newFilteredOptions?.options) {
      newFilteredOptions.options = createOptionsPage(pagedMeta);
    }
    const isFilterExists = newFilteredOptions?.filters?.find((f: any) => f?.field === 'enrollments.schoolId');
    if (isFilterExists) {
      isFilterExists.value = currentSchool.value;
    }
    if (!isFilterExists) {
      const filter = {
        field: 'enrollments.schoolId',
        operation: 'hash',
        value: currentSchool.value,
      };
      if (!Array.isArray(newFilteredOptions?.filters)) {
        newFilteredOptions.filters = [];
      }

      newFilteredOptions.filters.push(filter);
    }
    newFilteredOptions.options.itemsPerPage = 10000;
    handleFetch(newFilteredOptions);
  }, [filteredOptions, currentSchool]);

  const handleFetch = async (filteredOptions: FilteredOptionsInterface) => {
    if (!currentSchool?.value) return;
    try {
      setLoading(true);

      const result = await KidsService(token || '').list(filteredOptions);
      const data: KidsInterface[] = result?.data;

      const kidsNoParentsSetted = data
        ?.filter((d: any) => !d?.kidsUsersRelations)
        .map((d: any) => ({ ...d, errorType: errorTypes.NO_PARENTS }));

      let newKidsErrors = JSON.parse(JSON.stringify(kidsErrors));
      newKidsErrors = [...kidsNoParentsSetted];
      const uniqueArray: any[] = [];
      for (const k of newKidsErrors) {
        const isExists = uniqueArray.find((c: any) => k?.id === c?.id);
        if (!isExists) uniqueArray.push(k);
      }
      setKidsErrors(uniqueArray);

      const billingNotifications = await BillingNotificationsService(token || '').list('ACTIVE');
      setBillingErrors(billingNotifications?.data || []);
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Error fetching list');
      return toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  const handlePerRowsChange = async (perPage: number) => {
    const newOptions = {
      ...pagedMeta,
      perPage,
    };
    const newFilteredOptions = {
      filters: filteredOptions.filters,
      options: createOptionsPage(newOptions),
    };
    setFilteredOptions(newFilteredOptions);
  };

  const handlePageChange = async (page: number) => {
    const newOptions = {
      ...pagedMeta,
      currentPage: page,
    };
    const newFilteredOptions = {
      filters: filteredOptions.filters,
      options: createOptionsPage(newOptions),
    };
    setFilteredOptions(newFilteredOptions);
  };

  return (
    <>
      {kidsErrors?.length || billingErrors?.length ? (
        <>
          <Row className={!isTablet ? 'mt-5' : ''}>
            <Col md="12">
              <Card style={{ backgroundColor: '#F6CED8' }}>
                <CardBody>
                  <CardText>
                    <Row>
                      <Col>Errors List</Col>
                      <Button size="sm" color="primary" onClick={() => setToggleModal(!toggleModal)}>
                        See All
                      </Button>
                    </Row>
                  </CardText>
                </CardBody>
              </Card>

              <ModalContent
                class="p-0"
                modal={toggleModal}
                toggle={() => setToggleModal(!toggleModal)}
                title="Errors List"
                size="xl"
              >
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0" style={{ maxHeight: '62px' }}>
                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          className={`${activeTab === '1' ? 'active' : ''}`}
                          onClick={() => setActiveTab('1')}
                          style={customStyles.navLink}
                        >
                          Billing Errors
                        </NavLink>
                      </NavItem>
                      {isAdmin(user) && (
                        <NavItem>
                          <NavLink
                            className={`${activeTab === '2' ? 'active' : ''}`}
                            onClick={() => setActiveTab('2')}
                            style={customStyles.navLink}
                          >
                            Parent/Child Errors
                          </NavLink>
                        </NavItem>
                      )}
                    </Nav>
                  </CardHeader>
                  <CardBody>
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="1">
                        <DataTable
                          header={false}
                          shadowStyle={false}
                          columns={columnsBilling}
                          data={billingErrors}
                          totalRows={pagedMeta.total}
                          loading={loading}
                          onChangeRowsPerPage={handlePerRowsChange}
                          onChangePage={handlePageChange}
                        />
                      </TabPane>
                      <TabPane tabId="2">
                        <DataTable
                          header={false}
                          shadowStyle={false}
                          columns={columnsKids}
                          data={kidsErrors}
                          totalRows={pagedMeta.total}
                          loading={loading}
                          onChangeRowsPerPage={handlePerRowsChange}
                          onChangePage={handlePageChange}
                        />
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </ModalContent>
            </Col>
          </Row>
          <BillingParentsModal
            isOpen={modalBillingParents}
            toggleModal={toggleModalBillingParents}
            id={user.id}
            name={user.firstName}
            email={user.email}
          />
        </>
      ) : (
        <></>
      )}
    </>
  );
};
