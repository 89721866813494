import { Modal, Table, Row, Col, Button, Form, FormGroup, Input } from 'reactstrap';
import { useContext, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import ExpirationDatesService from '../../../services/ExpirationDates';
import ExpirationDatesTypesService from '../../../services/ExpirationDatesTypes';
import ExpirationDatesTypesModal from './ExpirationDatesTypesModal';
import { toLocaleFormatSlash } from '../../../helpers/dateFormat';
import isAdmin from '../../../helpers/isAdmin';
import isDirector from '../../../helpers/isDirector';

type ExpirationDatesModalProps = {
  userId: string;
  isOpen: boolean;
  toggleModal: (e?: any) => void;
};

const ExpirationDatesModal = ({ userId, isOpen, toggleModal }: ExpirationDatesModalProps) => {
  const { token, user }: any = useContext(AuthContext);
  const hasPermission = isAdmin(user) || isDirector(user);
  const [loading, setLoading] = useState(false);
  const [expirationDatesTypes, setExpirationDatesTypes] = useState([] as any[]);
  const [expirationDatesList, setExpirationDatesList] = useState([] as any[]);
  const [isTypesOpen, setIsTypesOpen] = useState(false);
  const [expirationTypeId, setExpirationTypeId] = useState('');
  const [issueDate, setIssueDate] = useState(new Date());
  const [expDate, setExpDate] = useState(new Date());

  useEffect(() => {
    if (isOpen) {
      listTypes();
      listData();
    }
  }, [isOpen]);

  const listTypes = async () => {
    try {
      const result = await ExpirationDatesTypesService(token).list();
      setExpirationDatesTypes(result.data || []);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No Types Found!');
    }
  };

  const listData = async () => {
    try {
      const result = await ExpirationDatesService(token).list(userId);
      setExpirationDatesList(result?.data || []);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No Data Found!');
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);

    try {
      await ExpirationDatesService(token).create({
        userId,
        expirationTypeId,
        issueDate: moment(issueDate).format('YYYY-MM-DD'),
        expDate: moment(expDate).format('YYYY-MM-DD'),
      });
      toast.success(`Saved successfully!`);
      listData();
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Create error!');
      toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  const deleteSettings = async (e: React.FormEvent, id: string) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);

    try {
      await ExpirationDatesService(token).delete(id);
      toast.success(`Deleted successfully!`);
      listData();
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Delete error!');
      toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  const toggleModalTypes = (e: any) => {
    e?.preventDefault();
    setIsTypesOpen(!isTypesOpen);
    listTypes();
  };

  const handleIssueDateChange = (d: Date) => {
    setIssueDate(d);
  };

  const handleExpDateChange = (d: Date) => {
    setExpDate(d);
  };

  return (
    <>
      <Modal className="modal-dialog-centered" size="lg" isOpen={isOpen} toggle={(e: any) => toggleModal(e)}>
        <div className="modal-header pb-1">
          <h5 className="modal-title">Staff Expiration Dates</h5>

          <div className="text-right mb-3">
            {hasPermission && (
              <i className="fas fa-cog fa-lg" style={{ cursor: 'pointer' }} onClick={toggleModalTypes}></i>
            )}

            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={(e) => toggleModal(e)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
        </div>
        <div className="modal-body pt-0">
          <Row>
            <Col>
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col lg="12">
                    <FormGroup>
                      <label className="form-control-label">Type</label>
                      <Input type="select" onChange={(e) => setExpirationTypeId(e.target.value)} required>
                        <option value=""></option>
                        {expirationDatesTypes.map((a: any, index: number) => {
                          return (
                            <option value={a.id} key={`types-${index}`}>
                              {a.name}
                            </option>
                          );
                        })}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label">Issue Date</label>
                      <Input
                        tag={DatePicker}
                        dateFormat="MM/dd/yyyy"
                        onChange={(d: any) => handleIssueDateChange(d)}
                        selected={issueDate}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label">Exp. Date</label>
                      <Input
                        tag={DatePicker}
                        dateFormat="MM/dd/yyyy"
                        onChange={(d: any) => handleExpDateChange(d)}
                        selected={expDate}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="12 mb-3">
                    <Button color="primary" type="submit" size="sm">
                      Add
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          <Table className="align-items-center table-flush" responsive>
            <tbody>
              <tr>
                <th>Type</th>
                <th>Issue Date</th>
                <th>Exp. Date</th>
                <th>Action</th>
              </tr>
              {expirationDatesList?.length === 0 && (
                <tr>
                  <td colSpan={4} className="text-center">
                    Data not found
                  </td>
                </tr>
              )}
              {expirationDatesList.map((s: any) => (
                <tr key={`expirationDates-modal-${s.id}`}>
                  <td>{expirationDatesTypes.find((e) => e.id === s.expirationTypeId)?.name || ''}</td>
                  <td>{toLocaleFormatSlash(s.issueDate)}</td>
                  <td>{toLocaleFormatSlash(s.expDate)}</td>
                  <td>
                    <i
                      className="far fa-trash-alt text-danger"
                      style={{ cursor: 'pointer' }}
                      onClick={(e) => deleteSettings(e, s.id)}
                    ></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Modal>
      <ExpirationDatesTypesModal isOpen={isTypesOpen} toggleModal={(e) => toggleModalTypes(e)} />
    </>
  );
};

export default ExpirationDatesModal;
