import { Card, CardBody, Row, Col } from 'reactstrap';
import { isMobile } from 'react-device-detect';

const CardStatsCols = (props: { title: string; cols: { text: string; description: string }[] }) => {
  const isTablet = window.innerWidth <= 1024 && isMobile;
  return (
    <Card className={`${isMobile ? '' : 'card-stats mt-4'}`}>
      <CardBody style={isMobile ? { padding: 0 } : {}}>
        <Row>
          <Col
            md="12"
            className={isMobile ? 'mt-1' : ''}
            style={isMobile ? { minHeight: '30px' } : { minHeight: '55px' }}
          >
            <h5
              className={`text-uppercase text-center text-muted mb-0 ${isMobile ? '' : 'pb-3'} card-title`}
              style={isTablet || isMobile ? { fontSize: 11 } : {}}
            >
              {props?.title || ''}
            </h5>
          </Col>
          <Col md="12" style={{ minHeight: '48px' }}>
            <div className="d-flex justify-content-center">
              {props.cols.map((col: { text: string; description: string }, index: number) => (
                <span
                  key={`col-stats-${index}`}
                  className="d-flex flex-column mr-3 text-center"
                  style={isMobile ? { fontSize: 25 } : {}}
                >
                  <b style={isMobile ? { fontSize: 15 } : {}}>{col.text}</b>
                  <p className="mb-0">{col.description}</p>
                </span>
              ))}
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default CardStatsCols;
