import CardInfo from '../../../../components/Cards/CardInfo';
import ModalContent from '../../../../components/Modals/Modal';
import { Row, Col } from 'reactstrap';
import DataTable from '../../../../components/Core/DataTable';
import { useState, useContext, useEffect, useImperativeHandle, forwardRef } from 'react';
import { createPagedMeta } from '../../../../entities/PagedMeta';
import { createOptionsPage } from '../../../../entities/OptionsPaged';
import RequestCollumns from './datatableCollumns/RequestCollumns';
import { Context as AuthContext } from '../../../../contexts/AuthContext';
import PtoService from '../../../../services/Pto';
import { toast } from 'react-toastify';
import CardWidget from '../../../../components/Cards/CardWidget';
import { isMobile } from 'react-device-detect';

const PenddingRequestInfo = forwardRef(
  (
    {
      setRequestDetailsData,
      setIsRequestDetailsOpen,
      confirmApprove,
      confirmRepprove,
    }: {
      setRequestDetailsData: any;
      setIsRequestDetailsOpen: any;
      confirmApprove: any;
      confirmRepprove: any;
    },
    ref,
  ) => {
    const { token, currentSchool, user }: any = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [pagedMeta, setPagedMeta] = useState(createPagedMeta());
    const [toggleModalPending, setToggleModalPending] = useState(false);
    const [filteredOptions, setFilteredOptions] = useState({
      filters: [],
      options: createOptionsPage(pagedMeta),
    });
    const handleToggleModalPending = () => setToggleModalPending(!toggleModalPending);

    const columnsRequests = RequestCollumns({
      setRequestDetailsData,
      setIsRequestDetailsOpen,
      user,
      confirmApprove,
      confirmRepprove,
    });

    const loadData = async () => {
      setLoading(true);
      try {
        const currFilteredOption = JSON.parse(JSON.stringify(filteredOptions));
        currFilteredOption.filters = [
          {
            field: 'status',
            operation: 'in',
            value: ['PENDDING'],
          },
        ];
        currFilteredOption.options.sortBy = ['startDate'];
        currFilteredOption.options.sortDesc = [false];
        const result = await PtoService(token).timeOffRequestList(currentSchool.value, currFilteredOption);
        setData(result.data);
        setPagedMeta(result.meta);
      } catch (e: any) {
        toast.error(e?.response?.data?.message || 'No data Found!');
      } finally {
        setLoading(false);
      }
    };

    const handlePerRowsChange = async (perPage: number) => {
      const newOptions = {
        ...pagedMeta,
        perPage,
      };
      const newFilteredOptions = {
        filters: filteredOptions.filters,
        options: createOptionsPage(newOptions),
      };
      setFilteredOptions(newFilteredOptions);
    };

    const handlePageChange = async (page: number) => {
      const newOptions = {
        ...pagedMeta,
        currentPage: page,
      };
      const newFilteredOptions = {
        filters: filteredOptions.filters,
        options: createOptionsPage(newOptions),
      };
      setFilteredOptions(newFilteredOptions);
    };

    useEffect(() => {
      loadData();
    }, [filteredOptions]);

    useEffect(() => {
      loadData();
    }, []);

    useEffect(() => {
      setFilteredOptions({
        filters: [],
        options: createOptionsPage(createPagedMeta()),
      });
    }, [toggleModalPending]);

    useImperativeHandle(ref, () => ({
      reloadData() {
        setFilteredOptions({
          filters: [],
          options: createOptionsPage(createPagedMeta()),
        });
      },
    }));

    return (
      <>
        {isMobile ? (
          <CardWidget
            title="Pending requests"
            text="5"
            icon="fa-clock"
            iconColor="primary"
            tag="h6"
            cursor="pointer"
            onClick={handleToggleModalPending}
          />
        ) : (
          <CardInfo
            count={pagedMeta.total}
            class="bg-danger card-info-mg-button"
            title="Pending requests"
            icon="fa-clock"
            toggle={handleToggleModalPending}
          />
        )}

        <ModalContent
          title="Pending requests"
          class="pt-0"
          modal={toggleModalPending}
          toggle={handleToggleModalPending}
          size="xl"
        >
          <Row>
            <Col xs="12">
              <DataTable
                columns={columnsRequests}
                data={data}
                header={false}
                totalRows={pagedMeta.total | 0}
                loading={loading}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
              />
            </Col>
          </Row>
        </ModalContent>
      </>
    );
  },
);
export default PenddingRequestInfo;
