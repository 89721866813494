interface PtoRequestDetailProps {
  data: any;
}

const PtoHollidayDetail = ({ data }: PtoRequestDetailProps) => {
  return (
    <>
      {Object.values(data).length && (
        <>
          <h4 className="mb-4">Policies with this holiday</h4>
          <ul className="list-unstyled list pl-5">
            {data.ptoPolicyHolidays.map((item: any) => {
              return (
                <>
                  <li className="mb-3 d-flex align-items-center">
                    <i className="fas fa-business-time mr-3 text-primary"></i>
                    {item.policy.name}
                  </li>
                </>
              );
            })}
          </ul>
        </>
      )}
    </>
  );
};

export default PtoHollidayDetail;
