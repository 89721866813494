import { OptionsPagedInterface, createOptionsPage } from './OptionsPaged';

export interface FilterInterface {
  field: string;
  operation?: string;
  value: any;
}

export interface FilteredOptionsInterface {
  filters: FilterInterface[];
  options: OptionsPagedInterface;
}

export const makeQueryParamsForRequest = (filteredOptions?: FilteredOptionsInterface) => {
  const op = filteredOptions?.options || createOptionsPage();

  const params: any = {
    page: op?.page,
    limit: op?.itemsPerPage,
    orderBy: op?.sortBy?.[0] || 'createdAt',
    orderByDirection: op?.sortDesc?.[0] ? 'DESC' : 'ASC',
  };

  if (filteredOptions?.filters?.length) {
    params.filters = filteredOptions?.filters?.map((f) => JSON.stringify(f));
  }

  return params;
};

export default FilteredOptionsInterface;
