/* eslint-disable max-lines */
import { Row, Col, Button, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { useContext, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import ReactDatetime from 'react-datetime';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import DiscountsKidsService from '../../../services/DiscountsKids';
import DiscountsService from '../../../services/Discounts';
import confirmAlert from '../../../helpers/confirmAlert';
import { toLocaleFormatSlash } from '../../../helpers/dateFormat';
import NumberFormat from 'react-number-format';
import moment, { Moment } from 'moment';

type DiscountsKidsListProps = {
  kidsId?: string;
  billingAmount?: number;
  isChanged?: boolean;
};

const DiscountsKidsList = ({ kidsId, billingAmount, isChanged }: DiscountsKidsListProps) => {
  const { token }: any = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [showDiscountsKids, setShowDiscountsKids] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [discountsKidsList, setDiscountsKidsList] = useState([]);
  const [discountsList, setDiscountsList] = useState([]);
  const [discountsId, setDiscountsId] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [editId, setEditId] = useState('');

  useEffect(() => {
    listDiscountsData();
    listData();
  }, []);

  useEffect(() => {
    listDiscountsData();
    listData();
  }, [isChanged]);

  const listDiscountsData = async () => {
    if (!kidsId) return;
    try {
      const result = await DiscountsService(token).list();
      setDiscountsList(result.data || []);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No Discounts Found!');
    }
  };

  const listData = async () => {
    if (!kidsId) return;
    try {
      const result = await DiscountsKidsService(token).list(kidsId);
      setDiscountsKidsList(result.data || []);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No Discounts Kids Found!');
    }
  };

  const handleSubmit = async () => {
    if (loading) return;
    if (!kidsId) return;

    setLoading(true);

    try {
      isEdit
        ? await DiscountsKidsService(token).update({ discountsId, startDate, endDate }, kidsId, editId)
        : await DiscountsKidsService(token).create({ discountsId, startDate, endDate }, kidsId);
      toast.success(`Saved successfully!`);
      listData();
      setShowDiscountsKids(false);
      setIsEdit(false);
      setEditId('');
      setDiscountsId('');
      setStartDate('');
      setEndDate('');
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Update error!');
      toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  const deleteItemAlert = async (id: string, label: string) => {
    return confirmAlert({
      title: 'Delete',
      messageType: 'DELETE',
      id,
      onClickYes: deleteDiscountsKids,
      label,
    });
  };

  const deleteDiscountsKids = async (id: string) => {
    if (loading) return;
    if (!kidsId) return;

    setLoading(true);

    try {
      await DiscountsKidsService(token).delete(kidsId, id);
      toast.success(`Deleted successfully!`);
      listData();
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Delete error!');
      toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  const validDiscountStartDate = (current: Moment) => {
    const yesterday = moment().subtract(1, 'day');
    let currentDiscountDate = yesterday;
    discountsKidsList.forEach((d: any) => {
      const endDate = moment(d.endDate);
      if (endDate > currentDiscountDate) {
        currentDiscountDate = endDate;
      }
    });
    return current.isAfter(currentDiscountDate);
  };

  const validDiscountEndDate = (current: Moment) => {
    const yesterday = moment().subtract(1, 'day');
    let currentDiscountDate = yesterday;
    discountsKidsList.forEach((d: any) => {
      const endDate = moment(d.endDate);
      if (endDate > currentDiscountDate) {
        currentDiscountDate = endDate;
      }
    });
    const startDateMoment = moment(startDate);
    if (startDateMoment > currentDiscountDate) {
      currentDiscountDate = startDateMoment;
    }
    return current.isAfter(currentDiscountDate);
  };

  return (
    <>
      <h6 className="heading-small text-muted mb-4 mt-4">Discounts</h6>
      <Row>
        <Col>
          {discountsKidsList.map((p: any, i) => {
            const currentDiscount: any = discountsList.find((d: any) => d.id === p.discountsId);
            const discountValue = currentDiscount?.percentage ? Number(currentDiscount?.percentage) : 0;
            const amount = billingAmount && billingAmount > 0 ? billingAmount : 0;
            const percentage = amount > 0 ? (discountValue / 100) * amount : 0;
            const newAmount = amount - percentage;
            const startDate = moment(p.startDate);
            const endDate = moment(p.endDate);
            const today = moment();
            let styles = {};
            if (p.endDate && today > startDate && today < endDate) {
              styles = { background: '#d4ffe1' };
            }
            return (
              <Col key={`discounts-kids-${i}`} style={styles}>
                <Row>
                  <Col className="mb-2" lg="3">
                    <FormGroup className="mb-1">
                      <label className="form-control-label">Discount</label>
                      <Input type="select" disabled>
                        <option value=""></option>
                        {discountsList.map((a: any, index: number) => {
                          return (
                            <option
                              defaultValue={a.id}
                              key={`discount-edit-${i}-${index}`}
                              selected={a.id === p.discountsId}
                            >
                              {a.name}
                            </option>
                          );
                        })}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col lg="3">
                    <FormGroup>
                      <label className="form-control-label">New Amount</label>
                      <Input
                        type="text"
                        thousandsGroupStyle="thousand"
                        prefix="$"
                        decimalSeparator="."
                        displayType="input"
                        thousandSeparator={true}
                        allowNegative={false}
                        decimalScale={2}
                        tag={NumberFormat}
                        value={newAmount}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                  <Col className="mb-2" lg="2">
                    <FormGroup className="mb-1">
                      <label className="form-control-label">Start Discount</label>
                      <Input type="text" value={toLocaleFormatSlash(p.startDate)} disabled />
                    </FormGroup>
                  </Col>
                  <Col className="mb-2" lg="2">
                    <FormGroup className="mb-1">
                      <label className="form-control-label">End Discount</label>
                      <Input type="text" value={toLocaleFormatSlash(p.endDate)} disabled />
                    </FormGroup>
                  </Col>
                  <Col lg="2">
                    {' '}
                    <i
                      className="far fa-trash-alt text-danger mt-5"
                      style={{ cursor: 'pointer' }}
                      onClick={(e) => {
                        e.preventDefault();
                        deleteItemAlert(p.id, p.discount.name);
                      }}
                    ></i>
                  </Col>
                </Row>
              </Col>
            );
          })}
          <Col>
            <Button
              size="sm"
              onClick={() => {
                setShowDiscountsKids(true);
              }}
            >
              + Discount
            </Button>
          </Col>
          {showDiscountsKids && (
            <Col className="mt-2">
              <Row>
                <Col className="mb-2" lg="4">
                  <FormGroup className="mb-1">
                    <label className="form-control-label">Discount</label>
                    <Input
                      type="select"
                      defaultValue={discountsId || ''}
                      onChange={(e) => setDiscountsId(e.target.value)}
                      required
                    >
                      <option value=""></option>
                      {discountsList.map((a: any, index: number) => {
                        return (
                          <option value={a.id} key={`discount-${index}`}>
                            {a.name}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </Col>
                <Col className="mb-2" lg="4">
                  <FormGroup>
                    <label className="form-control-label">Start Discount</label>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-calendar-grid-58" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <ReactDatetime
                        inputProps={{
                          placeholder: 'Start Date',
                        }}
                        onChange={(newValue) => {
                          setStartDate(`${newValue}`);
                        }}
                        timeFormat={false}
                        value={`${startDate}`}
                        dateFormat={'MM/DD/yyyy'}
                        isValidDate={validDiscountStartDate}
                        closeOnSelect={true}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col className="mb-2" lg="4">
                  <FormGroup>
                    <label className="form-control-label">End Discount</label>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-calendar-grid-58" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <ReactDatetime
                        inputProps={{
                          placeholder: 'End Date',
                        }}
                        onChange={(newValue) => {
                          setEndDate(`${newValue}`);
                        }}
                        timeFormat={false}
                        value={`${endDate}`}
                        dateFormat={'MM/DD/yyyy'}
                        isValidDate={validDiscountEndDate}
                        closeOnSelect={true}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col lg="12">
                  <Button
                    color="primary"
                    size="sm"
                    style={{ minWidth: '100px' }}
                    onClick={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                  >
                    {isEdit ? 'Edit' : 'Add'}
                  </Button>

                  <Button
                    color="secondary"
                    size="sm"
                    style={{ minWidth: '100px' }}
                    onClick={(e) => {
                      e.preventDefault();
                      setShowDiscountsKids(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Col>
          )}
        </Col>
      </Row>
    </>
  );
};

export default DiscountsKidsList;
