import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, FormGroup, Input, Row } from 'reactstrap';
import ModalContent from '../../../components/Modals/Modal';
import FilteredOptionsInterface from '../../../entities/FilteredOptions';
import ClassInterface from '../../../interfaces/ClassInterface';
import ClassService from '../../../services/Class';
import DataTable from '../../../components/Core/DataTable';
import { toLocaleFormatSlash } from '../../../helpers/dateFormat';
import { validEnrollmentStatus } from '../../../interfaces/KidsInterface';

export const ChildrenListModal: React.FC<{
  currentSchoolId: string;
  loading: boolean;
  token: string;
  title: string;
  kids: Array<any>;
  filteredOptions: FilteredOptionsInterface;
  setFilteredOptions: (filteredOptions: FilteredOptionsInterface) => void;
  setFilteredDate: (startDate: string) => void;
  toggleModal: boolean;
  handleToggleModal: () => void;
  showStatus?: boolean;
}> = ({ currentSchoolId, loading, token, title, kids, toggleModal, handleToggleModal, showStatus }) => {
  const [classes, setClasses] = useState<ClassInterface[]>([]);
  const [filterClass, setFilterClass] = useState('');
  const [orderBy, setOrderBy] = useState({ column: 'name', direction: 'asc' });
  const [kidsFiltered, setKidsFiltered] = useState<any[]>([]);

  const columns: any[] = [
    {
      name: 'Child Name',
      route: '/kids',
      label: (row: any) => `${row.firstName || '-'} ${row.lastName || '-'}`,
      grow: 2,
      sortable: true,
      sortField: 'firstName',
    },
    {
      name: 'Birthday',
      selector: (row: any) => `${row?.birthday ? toLocaleFormatSlash(row?.birthday) : '-'}`,
      grow: 1,
    },
    {
      name: 'Class',
      selector: (row: any) => `${row?.enrollment?.class?.name || '-'}`,
      grow: 2,
    },
    {
      name: 'Start Date',
      selector: (row: any) =>
        `${row?.enrollment?.enrollmentStartDate ? toLocaleFormatSlash(row?.enrollment?.enrollmentStartDate) : '-'}`,
      grow: 1,
      sortable: true,
      sortField: 'enrollmentStartDate',
    },
    {
      name: 'Class Type',
      selector: (row: any) => {
        const classType = row?.enrollment?.classType?.name || '';
        return `${classType || '-'}`;
      },
      grow: 2,
    },
  ];
  if (showStatus) {
    columns.push({
      name: 'Status',
      selector: (row: any) => {
        const currentStatus = validEnrollmentStatus.find((v: any) => v?.value === row?.enrollment?.status);
        const status = currentStatus?.label || '';
        return `${status || '-'}`;
      },
      grow: 2,
    });
  }

  useEffect(() => {
    setKidsFiltered(kids);
  }, [kids]);

  useEffect(() => {
    if (currentSchoolId) listClasses();
  }, [currentSchoolId]);

  useEffect(() => {
    let newKidsFiltered = JSON.parse(JSON.stringify(kids));
    if (!filterClass) {
      return setKidsFiltered(newKidsFiltered);
    }
    newKidsFiltered = newKidsFiltered.filter((k: any) => k?.enrollment?.classId === filterClass);
    return setKidsFiltered(newKidsFiltered);
  }, [filterClass]);

  useEffect(() => {
    const newKidsFiltered = JSON.parse(JSON.stringify(kids));
    setKidsFiltered(sortData(newKidsFiltered));
  }, [orderBy]);

  const sortData = (kids: any[]): any[] => {
    if (orderBy?.column === 'firstName') {
      return kids?.sort((a, b) =>
        a?.firstName < b?.firstName
          ? orderBy?.direction === 'asc'
            ? -1
            : 1
          : a?.firstName > b?.firstName
          ? orderBy?.direction === 'asc'
            ? 1
            : -1
          : 0,
      );
    }
    if (orderBy?.column === 'enrollmentStartDate') {
      return kids?.sort((a, b) => {
        const firstDate = a?.enrollment?.enrollmentStartDate ? new Date(a?.enrollment?.enrollmentStartDate) : undefined;
        const secondDate = b?.enrollment?.enrollmentStartDate
          ? new Date(b?.enrollment?.enrollmentStartDate)
          : undefined;
        if (!firstDate || !secondDate) return 0;
        if (firstDate.getTime() < secondDate.getTime()) return orderBy?.direction === 'asc' ? -1 : 1;
        if (firstDate.getTime() > secondDate.getTime()) return orderBy?.direction === 'asc' ? 1 : -1;
        return 0;
      });
    }
    return kids;
  };

  const listClasses = async () => {
    if (!currentSchoolId) return;
    const classes = await ClassService(token).listBySchool(currentSchoolId);

    if (classes?.data) {
      setClasses(classes.data);
    }
  };

  return (
    <>
      <ModalContent title={title} modal={toggleModal} toggle={() => handleToggleModal()} size="xl">
        <Row>
          <Col xs="12" className="mb-2">
            <Card>
              <CardBody className="pt-1 pb-1">
                <Row>
                  <Col xs="6">
                    <FormGroup>
                      <label className="form-control-label">Class</label>
                      <Input type="select" onChange={(e) => setFilterClass(e.target.value)} value={filterClass}>
                        <option value="">All Classes</option>
                        {classes?.map((c: any, index: number) => {
                          return (
                            <option value={c.id} key={`class-button-${index}`}>
                              {c.name}
                            </option>
                          );
                        })}
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col xs="12">
            <DataTable
              title="Kids"
              columns={columns}
              data={kidsFiltered}
              totalRows={kidsFiltered.length}
              loading={loading}
              button={false}
              buttonUrl=""
              onChangeSort={(column: string, direction: string) => setOrderBy({ column, direction })}
            />
          </Col>
        </Row>
      </ModalContent>
    </>
  );
};
