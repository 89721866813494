import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Button, Card, CardHeader, CardBody, Col, Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import hasKids from '../../../helpers/hasKids';
import { useContext, useState } from 'react';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import KidsCurrentClassCameras from '../../../components/Kids/KidsCurrentClassCameras';
import { isMobile } from 'react-device-detect';

type ChildDetailedTableProps = {
  id: string;
  pictureUrl: string;
  name: string;
  enrolled: boolean;
  checked: boolean;
  className: string;
  schoolName: string;
};

const ChildDetailedTable = ({
  id,
  pictureUrl,
  name,
  enrolled,
  checked,
  className,
  schoolName,
}: ChildDetailedTableProps) => {
  const { user }: any = useContext(AuthContext);
  const userHasKids = hasKids(user);

  const [modalKidsCamera, setModalKidsCamera] = useState(false);
  const toggleModalKidsCamera = () => setModalKidsCamera(!modalKidsCamera);

  return (
    <>
      <Modal isOpen={modalKidsCamera} toggle={toggleModalKidsCamera} centered size="xl">
        <ModalHeader toggle={toggleModalKidsCamera} className="pb-0"></ModalHeader>
        <ModalBody className="pt-0">
          <KidsCurrentClassCameras kidId={id} stopTimeInterval={modalKidsCamera} />
        </ModalBody>
      </Modal>
      <Col md="12">
        <Card style={{ border: '1px solid rgba(0, 0, 0, 0.1)' }}>
          <CardHeader className="text-center">
            <img
              alt={`${name}`}
              className="rounded-circle"
              src={pictureUrl ? `${pictureUrl}` : require('../../../assets/img/user-default.png').default}
              style={{ width: 135, height: 135 }}
            />
            <h3 className="mt-2 mb-0">{name}</h3>
            <div style={{ position: 'absolute', top: '10px', right: '15px' }}>
              <Button
                color="danger"
                onClick={toggleModalKidsCamera}
                style={{ width: '45px', height: '45px', padding: isMobile ? 0 : undefined }}
              >
                <i className="fas fa-video ml-n1" />
              </Button>
            </div>
          </CardHeader>
          <CardBody>
            <Table bordered responsive>
              <tbody>
                <tr>
                  <th>Enrolled</th>
                  <td>
                    <strong>{enrolled ? 'Enrolled' : 'Not Enrolled'}</strong>
                  </td>
                </tr>
                <tr>
                  <th>Checked</th>
                  <td>
                    <strong>{checked ? 'IN' : 'OUT'}</strong>
                  </td>
                </tr>
                <tr>
                  <th>Class</th>
                  <td>
                    <strong>{className || '-'}</strong>
                  </td>
                </tr>
                <tr>
                  <th>School</th>
                  <td>
                    <strong>{schoolName || '-'}</strong>
                  </td>
                </tr>
              </tbody>
            </Table>

            <div className="d-flex w-100">
              {userHasKids && (
                <Link className="w-50 pr-1" to={`/student-profile/${id}`}>
                  <Button
                    className="mt-4 mr-2 btn-block"
                    color="primary"
                    size="md"
                    style={{ minWidth: 124, minHeight: 28, fontSize: isMobile ? '0.75rem' : '0.875rem' }}
                  >
                    Child Profile
                  </Button>
                </Link>
              )}
              <Link className={`${userHasKids ? 'w-100' : 'w-50 pl-1'}`} to={`/student-profile/${id}?tab=timeline`}>
                <Button
                  className="mt-4 btn-block"
                  color="primary"
                  size="md"
                  style={{ minWidth: 124, minHeight: 28, fontSize: isMobile ? '0.75rem' : '0.875rem' }}
                >
                  Timeline
                </Button>
              </Link>
            </div>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default ChildDetailedTable;
