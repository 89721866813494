/* eslint-disable max-lines */
import { Button, Card, CardHeader, CardBody, FormGroup, Form, Input, Container, Row, Col } from 'reactstrap';
import { toast } from 'react-toastify';
import { useContext, useState, useEffect, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import CommonHeader from '../../../components/Headers/CommonHeader';
import InputMask from 'react-input-mask';
import { toLocaleFormat, toSystemFormat } from '../../../helpers/dateFormat';
import UserService from '../../../services/User';
import PermissionGroupsService from '../../../services/PermissionGroups';
import FranchiseeService from '../../../services/Franchisee';
import history from '../../../history';
import { validStatus } from '../../../interfaces/UserInterface';

const Profile = () => {
  const { id } = useParams<{ id: string }>();
  const { token }: any = useContext(AuthContext);
  const pageTitle = id ? 'Update' : 'Create';
  const imageRef = useRef(null as any);
  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [birthday, setBirthday] = useState('');
  const [groupId, setGroupId] = useState('');
  const [franchiseeId, setFranchiseeId] = useState('');
  const [pictureUrl, setPictureUrl] = useState('');
  const [picture, setPicture] = useState();

  const [groupsList, setGroupsList] = useState([] as any[]);
  const [franchiseesList, setFranchiseesList] = useState([] as any[]);
  const statusList = Object.keys(validStatus);

  const listGroups = async () => {
    try {
      const result = await PermissionGroupsService(token).list();
      setGroupsList(result || []);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No permission groups found');
    }
  };

  const listFranchisees = async () => {
    try {
      const result = await FranchiseeService(token).listSelect();
      setFranchiseesList(result.data || []);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No franchisees found');
    }
  };

  const getById = async () => {
    setIsDisabled(true);
    try {
      const result = await UserService(token).show(id);
      if (result?.data) {
        setFirstName(result?.data?.firstName || '');
        setLastName(result?.data?.lastName || '');
        setEmail(result?.data?.email || '');
        setBirthday(result?.data?.birthday ? toLocaleFormat(result?.data?.birthday) : '');
        setGroupId(result?.data?.groupId || '');
        setFranchiseeId(result?.data?.franchiseeId || '');
        setPictureUrl(result?.data?.picture || '');
      }
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'Invalid user ID');
    } finally {
      setIsDisabled(false);
    }
  };

  useEffect(() => {
    if (id) {
      getById();
    }
    listGroups();
    listFranchisees();
  }, []);

  const showOpenFileDialog = () => {
    if (isDisabled) {
      return;
    }
    if (imageRef?.current?.click) {
      imageRef.current.click();
    }
  };

  const onChangeFile = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];
    setPicture(file);
    setPictureUrl(URL.createObjectURL(file));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);
    setIsDisabled(true);

    const body: any = {
      firstName,
      lastName,
      email,
      birthday: birthday ? toSystemFormat(birthday) : '',
      groupId,
      status: statusList[1],
    };

    if (password) {
      body.password = password;
    }

    if (franchiseeId) {
      body.franchiseeId = franchiseeId;
    }

    try {
      const result = id ? await UserService(token).update(id, body) : await UserService(token).create(body);
      if (picture) {
        const formData = new FormData();
        formData.append('picture', picture as any);
        await UserService(token).picture(result?.data?.id, formData);
      }
      toast.success(`${pageTitle} successfully!`);
      history.push(`/users/${result?.data?.id}`);
    } catch (e: any) {
      const message = String(e?.response?.data?.message || `${pageTitle} error!`);
      toast.error(message);
    } finally {
      setIsDisabled(false);
    }

    setLoading(false);
  };

  return (
    <>
      <CommonHeader />
      <Container className="mt--8" fluid>
        <Row>
          <Col className="order-xl-2" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">{`${pageTitle} User`}</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Link to="/users">
                      <Button color="primary" className="float-right">
                        Back
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmit}>
                  <h6 className="heading-small text-muted mb-4">
                    Picture <small style={{ textTransform: 'none' }}>(Recommended size: 800 x 800px)</small>
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <input
                            id="img-picture"
                            name="picture"
                            type="file"
                            accept=".png,.jpg,.jpeg"
                            style={{ display: 'none' }}
                            ref={imageRef}
                            disabled={isDisabled}
                            onChange={onChangeFile}
                          />
                          <img
                            alt={`${firstName}`}
                            className="rounded-circle"
                            src={pictureUrl ? `${pictureUrl}` : require('../../../assets/img/user-default.png').default}
                            style={{ width: 135, height: 135, cursor: isDisabled ? 'not-allowed' : 'pointer' }}
                            onClick={showOpenFileDialog}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">User information</h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-first-name">
                            First name
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-first-name"
                            placeholder="First name"
                            type="text"
                            disabled={isDisabled}
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-last-name">
                            Last name
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-last-name"
                            placeholder="Last name"
                            type="text"
                            disabled={isDisabled}
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            required
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-email">
                            Email address
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-email"
                            placeholder="Email address"
                            type="email"
                            value={email}
                            disabled={isDisabled}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-pass">
                            Password
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-pass"
                            placeholder="Password"
                            type="password"
                            disabled={isDisabled}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required={id ? false : true}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-birthday">
                            Birthday
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-birthday"
                            placeholder="Birthday"
                            type="text"
                            mask="99/99/9999"
                            tag={InputMask}
                            disabled={isDisabled}
                            value={birthday}
                            onChange={(e) => setBirthday(e.target.value)}
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-group-id">
                            Permission Group
                          </label>
                          <Input
                            id="input-group-id"
                            className="form-control-alternative"
                            type="select"
                            onChange={(e) => setGroupId(e.target.value)}
                            value={groupId}
                            disabled={isDisabled}
                            required
                          >
                            <option value=""></option>
                            {groupsList?.map((row: any, index: number) => {
                              return (
                                <option value={row.id} key={`permission-group-${index}`}>
                                  {row.name}
                                </option>
                              );
                            })}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-franchisee-id">
                            Franchisee
                          </label>
                          <Input
                            id="input-franchisee-id"
                            className="form-control-alternative"
                            type="select"
                            onChange={(e) => setFranchiseeId(e.target.value)}
                            disabled={isDisabled}
                            value={franchiseeId}
                          >
                            <option value=""></option>
                            {franchiseesList?.map((row: any, index: number) => {
                              return (
                                <option value={row.id} key={`franchisee-${index}`}>
                                  {row.name}
                                </option>
                              );
                            })}
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Button className="my-4" color="primary" type="submit" disabled={isDisabled}>
                      Save
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Profile;
