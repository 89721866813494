import type { Identifier, XYCoord } from 'dnd-core';
import type { FC } from 'react';
import { useRef, useContext, useState, useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Container, Button, Row, Col, Card as CardFolder, CardBody, CardTitle, Modal } from 'reactstrap';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import Documents from '../../../services/Documents';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import DocumentsForm from '../Documents/DocumentsForm';
import isAdmin from '../../../helpers/isAdmin';
import isDirector from '../../../helpers/isDirector';
import { Document, Page, pdfjs } from 'react-pdf';
import { isMobile } from 'react-device-detect';

const ItemTypes = {
  CARD: 'card',
};

const style = {
  marginBottom: '.5rem',
  cursor: 'move',
  display: 'inline-grid',
};

export interface CardProps {
  id: any;
  name: string;
  index: number;
  position: number;
  moveCard: (dragIndex: number, hoverIndex: number, idFolder: string) => void;
  picture: string;
  onRefresh: () => void;
  attachment: string;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export const DragDropFilesCard: FC<CardProps> = ({ id, name, index, moveCard, onRefresh, attachment }) => {
  const { token, user } = useContext(AuthContext);
  const hasEditPermission = isAdmin(user) || isDirector(user);

  const urlLocation = useLocation()['pathname'];
  const [documentsFoldersForm, setDocumentsForm] = useState(false);
  const [blobAttachment, setBlobAttachment] = useState('');

  const toggleDocumentsForm = () => {
    setDocumentsForm(!documentsFoldersForm);
  };

  const modalChange = () => {
    setDocumentsForm(!documentsFoldersForm);
  };

  const [title, setTitle] = useState('');

  useEffect(() => {
    if (urlLocation == '/curriculum') {
      setTitle('Curriculum');
    } else if (urlLocation == '/resource') {
      setTitle('Resources');
    }
  }, [urlLocation]);

  useEffect(() => {
    setBlobAttachment(attachment);
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, []);

  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      const idFolder = item.id;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex, idFolder);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const deleteItemAlert = async (id: string) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Container fluid>
            <CardFolder>
              <CardBody>
                <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                  Delete
                </CardTitle>
                <Row className="mt-3">
                  <Col md="12 text-center">
                    <span className="h2 font-weight-bold mb-0">{`Are you sure to delete item: ${id}?`}</span>
                  </Col>
                </Row>
                <Row className="mt-5">
                  <Col md="6 text-center">
                    <Button
                      color="primary"
                      href="#"
                      onClick={() => {
                        deleteItem(id);
                        onClose();
                      }}
                      size="lg"
                      style={{ minWidth: '100px' }}
                    >
                      Yes
                    </Button>
                  </Col>
                  <Col md="6 text-center">
                    <Button
                      color="danger"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        onClose();
                      }}
                      size="lg"
                      style={{ minWidth: '100px' }}
                    >
                      No
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </CardFolder>
          </Container>
        );
      },
    });
  };

  const deleteItem = async (id: string) => {
    try {
      await Documents(token || '').delete(id);
      toast.success(`Deleted successfully!`);
    } catch (e: any) {
      const message = String(e?.response?.data?.message || `Error deleting item: ${id}`);
      return toast.error(message);
    }
  };

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <div
      className={isMobile ? 'col-6' : 'col-2'}
      ref={ref}
      style={{ ...style, opacity }}
      data-handler-id={handlerId}
      id={`folderPosition-${index}`}
      tabIndex={-1}
      //onMouseUp={() => handleEvent(id, isDragging)}
    >
      <CardFolder>
        <div style={{ position: 'relative' }}>
          <div className="text-center">
            <Document file={blobAttachment} className={'pdf-thumb'}>
              <Page pageNumber={1} renderTextLayer={false} />
            </Document>
          </div>
        </div>
        <a
          style={{
            position: 'absolute',
            background: 'rgb(0 0 0 / 45%)',
            width: '100%',
            height: '100%',
            textAlign: 'center',
            color: '#fff',
            fontWeight: 'bolder',
          }}
          onClick={() => {
            document.location.href = `/view-pdf/${id}`;
          }}
        >
          <div style={{ marginTop: '50%' }}>{name}</div>
        </a>
        <div>
          {hasEditPermission && !isMobile && (
            <div style={{ textAlign: 'center', paddingBottom: '10px' }}>
              <div className="btn-group btn-group-sm" role="group" aria-label="Basic example">
                <button
                  type="button"
                  className="btn btn-secondary"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    toggleDocumentsForm();
                  }}
                >
                  <i className="far fa-edit text-blue"></i>
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    deleteItemAlert(id);
                  }}
                >
                  <i className="far fa-trash-alt text-danger"></i>
                </button>
              </div>
            </div>
          )}
        </div>
      </CardFolder>
      <Modal className="modal-dialog-centered" isOpen={documentsFoldersForm} toggle={() => toggleDocumentsForm()}>
        <div className="modal-header">
          <h5 className="modal-title" id="Label">
            Edit Folder
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => toggleDocumentsForm()}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <DocumentsForm title={title} onModalStatus={modalChange} onRefresh={onRefresh} fileId={id} />
        </div>
      </Modal>
    </div>
  );
};
