import http from '../helpers/http';

const Import = (token: string) => {
  const basePath = '/import';
  const headers = {
    'x-access-token': token,
  };

  return {
    fromData: async (type: string, data: any[]) => {
      const result = await http.post(`${basePath}/${type}/fromData`, { data }, { headers });
      return result;
    },
    countTotalsByFranchiseId: async (franchiseId: string) => {
      const result = await http.get(`${basePath}/count-totals-by-franchisee/${franchiseId}`, { headers });
      return result;
    },
    validateFromData: async (type: string, data: any[]) => {
      const result = await http.post(`${basePath}/${type}/validate`, { data }, { headers });
      return result;
    },
  };
};

export default Import;
