export const documentsOptions = [
  { value: '', label: '' },
  { value: 'List A - U.S. Passport or U.S. Passport Card', label: 'List A - U.S. Passport or U.S. Passport Card' },
  {
    value: 'List A - Permanent Resident Card or Alien Registration Receipt Card',
    label: 'List A - Permanent Resident Card or Alien Registration Receipt Card',
  },
  { value: 'List A - Foreign passport (I-551)', label: 'List A - Foreign passport (I-551)' },
  { value: 'List A - Employment Authorization Document', label: 'List A - Employment Authorization Document' },
  { value: 'List A - Foreign passport and Form I-94/I-94A', label: 'List A - Foreign passport and Form I-94/I-94A' },
  {
    value: 'List A - Passport from the FSM/RMI and Form I-94/I-94A',
    label: 'List A - Passport from the FSM/RMI and Form I-94/I-94A',
  },
  {
    value: `List B - Driver's license or ID card issued by a State`,
    label: `List B - Driver's license or ID card issued by a State`,
  },
  {
    value: 'List B - ID card issued by federal, state or local government agencies or entities',
    label: 'List B - ID card issued by federal, state or local government agencies or entities',
  },
  { value: 'List B - School ID card with a photograph', label: 'List B - School ID card with a photograph' },
  { value: `List B - Voter's registration card`, label: `List B - Voter's registration card` },
  { value: 'List B - U.S. Military card or draft record', label: 'List B - U.S. Military card or draft record' },
  { value: `List B - Military dependent's ID card`, label: `List B - Military dependent's ID card` },
  {
    value: 'List B - U.S. Coast Guard Merchant Mariner Card',
    label: 'List B - U.S. Coast Guard Merchant Mariner Card',
  },
  { value: 'List B - Native American tribal document', label: 'List B - Native American tribal document' },
  {
    value: `List B - Driver's license issued by a Canadian government authority`,
    label: `List B - Driver's license issued by a Canadian government authority`,
  },
  { value: 'List B - School record or report card', label: 'List B - School record or report card' },
  { value: 'List B - Clinic, doctor, or hospital record', label: 'List B - Clinic, doctor, or hospital record' },
  { value: 'List B - Day-care or nursery school record', label: 'List B - Day-care or nursery school record' },
  { value: 'List C - A Social Security Account Number card', label: 'List C - A Social Security Account Number card' },
  {
    value: 'List C - Certification of report of birth issued by the Department of State (DS-1350, FS-545, FS-240)',
    label: 'List C - Certification of report of birth issued by the Department of State (DS-1350, FS-545, FS-240)',
  },
  {
    value: 'List C - Original or certified copy of birth certificate',
    label: 'List C - Original or certified copy of birth certificate',
  },
  { value: 'List C - Native American tribal document', label: 'List C - Native American tribal document' },
  { value: 'List C - U.S. Citizen ID Card (Form I-197)', label: 'List C - U.S. Citizen ID Card (Form I-197)' },
  {
    value: 'List C - Identification Card for Use of Resident Citizen in the United States (Form I-179)',
    label: 'List C - Identification Card for Use of Resident Citizen in the United States (Form I-179)',
  },
  {
    value: 'List C - Employment authorization document issued by the Department of Homeland Security',
    label: 'List C - Employment authorization document issued by the Department of Homeland Security',
  },
];
