import { useContext, useEffect, useState } from 'react';
import { Modal, Input, ListGroup, ListGroupItem, Button, Row, Col } from 'reactstrap';
import { AvatarComponent } from '../../../../helpers/generateAvatarRamdomColor';
import { toast } from 'react-toastify';
import Employee from '../../../../services/Employee';
import RoutingSupportTickets from '../../../../services/RoutingSupportTickets';
import { Context as AuthContext } from '../../../../contexts/AuthContext';

interface AddUserOnDepartmentModalProps {
  department: string;
  isOpen: boolean;
  OnRequestedClose: () => void;
  ticketId?: string;
}

type UserPros = {
  id?: string;
  userId?: string;
  firstName: string;
  lastName: string;
  picture?: string;
};

const AddUserOnDepartmentModal = ({
  department,
  isOpen,
  OnRequestedClose,
  ticketId,
}: AddUserOnDepartmentModalProps) => {
  const { token, currentSchool }: any = useContext(AuthContext);
  const [searchValueTo, setSearchValueTo] = useState('');
  const [searchValueFrom, setSearchValueFrom] = useState('');
  const [filterValueAbleToRouting, setFilterValueAbleToRouting] = useState<UserPros[] | null>();
  const [filterValueToRouting, setFilterValueToRouting] = useState<UserPros[] | null>();
  const [selectedToRemove, setSelectedToRemove] = useState<UserPros | null>();
  const [selectedToInsert, setSelectedToInsert] = useState<UserPros | null>();
  const [ableToRouting, setAbleToRouting] = useState<UserPros[]>([]);
  const [routingTo, setRoutingTo] = useState<UserPros[]>([]);

  const handleFindRecipients = async () => {
    const result = await RoutingSupportTickets(token).list(currentSchool.value);

    if (ticketId) {
      const filterResult = result.data.filter(
        (value: any) => value.department === department || value.supportTicketId === ticketId,
      );
      const data: UserPros[] = filterResult.map((value: any) => {
        return {
          id: value?.id,
          userId: value?.userId,
          firstName: value?.user.firstName || '',
          lastName: value?.user.lastName || '',
          picture: value?.user.picture || '',
        };
      });

      setRoutingTo(data);
      return data;
    }

    const filterResult = result.data.filter((value: any) => value.department === department);

    const data: UserPros[] = filterResult.map((value: any) => {
      return {
        id: value?.id,
        userId: value?.userId,
        firstName: value?.user.firstName || '',
        lastName: value?.user.lastName || '',
        picture: value?.user.picture || '',
      };
    });

    setRoutingTo(data);
    return data;
  };

  const handleChangeTicketRecipient = async () => {
    const result = await Employee(token).listCanReciveSupportTickets(currentSchool.value);
    const data: UserPros[] = result.data.map((value: any) => {
      return {
        userId: value?.userId,
        firstName: value?.firstName || '',
        lastName: value?.lastName || '',
        picture: value?.userPicture || '',
      };
    });

    setAbleToRouting(data);
  };

  useEffect(() => {
    handleChangeTicketRecipient();
    handleFindRecipients();
    setSelectedToRemove(null);
    setSelectedToInsert(null);
    setSearchValueFrom('');
    setSearchValueTo('');
  }, [department]);

  useEffect(() => {
    const filteredAbleToRouting = ableToRouting.filter(
      (ableToRouting) => !routingTo.find((routingTo) => ableToRouting.userId === routingTo.userId),
    );

    setAbleToRouting(filteredAbleToRouting);
  }, [routingTo]);

  useEffect(() => {
    const filteredAbleToRouting = ableToRouting.filter((ableToRouting) =>
      `${ableToRouting.firstName} ${ableToRouting.lastName}`.toLowerCase().includes(searchValueFrom.toLowerCase()),
    );

    setFilterValueAbleToRouting(filteredAbleToRouting);
  }, [searchValueFrom]);

  useEffect(() => {
    const filteredRoutingTo = routingTo.filter((routingTo) =>
      `${routingTo.firstName} ${routingTo.lastName}`.toLowerCase().includes(searchValueTo.toLowerCase()),
    );

    setFilterValueToRouting(filteredRoutingTo);
  }, [searchValueTo]);

  const handleItemClickToRemove = (data: UserPros) => {
    if (selectedToRemove?.userId === data.userId) {
      setSelectedToRemove(null);
      return;
    }

    setSelectedToRemove(data);
  };

  const handleItemClickToInsert = (data: UserPros) => {
    if (selectedToInsert?.userId === data.userId) {
      setSelectedToInsert(null);
      return;
    }
    setSelectedToInsert(data);
  };

  const handleRemove = async (data: UserPros) => {
    try {
      await RoutingSupportTickets(token).delete(currentSchool.value, data.id as string);

      const newAbledToRouting = [...ableToRouting];
      newAbledToRouting.push(data);
      setAbleToRouting(newAbledToRouting);

      const newRoutingTo = routingTo.filter((item) => item.id !== data.id);
      setRoutingTo(newRoutingTo);
      setSearchValueFrom('');
      setSearchValueTo('');
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'Invalid user ID');
    }
  };

  const handleInsert = async (data: UserPros) => {
    try {
      let newData = {
        userId: data.userId,
      };
      if (ticketId) {
        newData = { ...newData, ...{ department: 'Ticket', supportTicketId: ticketId } };
      } else {
        newData = { ...newData, ...{ department } };
      }

      await RoutingSupportTickets(token).create(currentSchool.value, newData);

      await handleFindRecipients();
      setSearchValueFrom('');
      setSearchValueTo('');
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'Invalid user ID');
    }
  };

  return (
    <Modal className="modal-dialog-centered" isOpen={isOpen} toggle={() => OnRequestedClose()}>
      <div className="modal-header pb-1">
        <h5 className="modal-title">{department}</h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => OnRequestedClose()}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body pt-0">
        <Row>
          <Col>
            <h4>Able To Routing:</h4>
            <Input
              type="text"
              placeholder="Search"
              value={searchValueFrom}
              onChange={(e) => setSearchValueFrom(e.target.value)}
            />
            <ListGroup style={{ height: '265px', overflow: 'auto' }} className="mt-1">
              {searchValueFrom
                ? filterValueAbleToRouting?.map((user, index) => (
                    <ListGroupItem
                      key={index}
                      className={`custom-hover ${
                        selectedToInsert?.userId === user.userId ? 'active' : ''
                      } d-flex align-items-center cursor-pointer btn-hover`}
                      onClick={() => {
                        handleItemClickToInsert(user);
                      }}
                    >
                      <AvatarComponent
                        name={`${user.firstName} ${user.lastName}`}
                        size="32"
                        userPicture={user.picture}
                      />
                      <p className="m-0 p-0 ml-2">{`${user.firstName} ${user.lastName}`}</p>
                    </ListGroupItem>
                  ))
                : ableToRouting.map((user, index) => (
                    <ListGroupItem
                      key={index}
                      className={`custom-hover ${
                        selectedToInsert?.userId === user.userId ? 'active' : ''
                      } d-flex align-items-center cursor-pointer btn-hover`}
                      onClick={() => {
                        handleItemClickToInsert(user);
                      }}
                    >
                      <AvatarComponent
                        name={`${user.firstName} ${user.lastName}`}
                        size="32"
                        userPicture={user.picture}
                      />
                      <p className="m-0 p-0 ml-2">{`${user.firstName} ${user.lastName}`}</p>
                    </ListGroupItem>
                  ))}
            </ListGroup>
            <Button
              className="btn btn-primary mt-3 ml-auto btn-sm w-100"
              onClick={() => {
                if (selectedToInsert) handleInsert(selectedToInsert);
              }}
            >
              <i className="fas fa-chevron-right"></i>
            </Button>
          </Col>
          <div style={{ borderRight: '1px solid #d3d3d3' }}></div>
          <Col>
            <h4>Routing To:</h4>
            <Input
              type="text"
              placeholder="Search"
              value={searchValueTo}
              onChange={(e) => setSearchValueTo(e.target.value)}
            />
            <ListGroup style={{ height: '265px', overflow: 'auto' }} className="mt-1">
              {searchValueTo
                ? filterValueToRouting?.map((user, index) => (
                    <ListGroupItem
                      key={index}
                      className={`custom-hover ${
                        selectedToRemove?.userId === user.userId ? 'active' : ''
                      } d-flex align-items-center cursor-pointer btn-hover`}
                      onClick={() => {
                        handleItemClickToRemove(user);
                      }}
                    >
                      <AvatarComponent
                        name={`${user.firstName} ${user.lastName}`}
                        size="32"
                        userPicture={user.picture}
                      />
                      <p className="m-0 p-0 ml-2">{`${user.firstName} ${user.lastName}`}</p>
                    </ListGroupItem>
                  ))
                : routingTo.map((user, index) => (
                    <ListGroupItem
                      key={index}
                      className={`custom-hover ${
                        selectedToRemove?.userId === user.userId ? 'active' : ''
                      } d-flex align-items-center cursor-pointer btn-hover`}
                      onClick={() => {
                        handleItemClickToRemove(user);
                      }}
                    >
                      <AvatarComponent
                        name={`${user.firstName} ${user.lastName}`}
                        size="32"
                        userPicture={user.picture}
                      />
                      <p className="m-0 p-0 ml-2">{`${user.firstName} ${user.lastName}`}</p>
                    </ListGroupItem>
                  ))}
            </ListGroup>
            <Button
              className="btn btn-primary mt-3 ml-auto btn-sm w-100"
              onClick={() => {
                if (selectedToRemove) handleRemove(selectedToRemove);
              }}
            >
              <i className="fas fa-chevron-left"></i>
            </Button>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default AddUserOnDepartmentModal;
