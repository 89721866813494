import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import { useCallback, useState, useContext, useEffect } from 'react';
import { Row } from 'reactstrap';
import { DragDropCard } from './DragDropCard';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import { toast } from 'react-toastify';
import DocumentsFolders from '../../../services/DocumentsFolders';
import DocumentsFoldersInterface from '../../../interfaces/DocumentsFoldersInterface';
import { FilteredOptionsInterface } from '../../../entities/FilteredOptions';
import { createOptionsPage } from '../../../entities/OptionsPaged';
import { PagedMetaInterface, createPagedMeta } from '../../../entities/PagedMeta';
import { useLocation } from 'react-router-dom';
import isAdmin from '../../../helpers/isAdmin';
import isDirector from '../../../helpers/isDirector';

export interface Item {
  id: number;
  name: string;
  position: number;
  picture: string;
  groupPermissions: any;
}

type DragDropProps = {
  onRefresh: () => void;
  searchFilter?: string;
};

export const DragDrop = ({ onRefresh, searchFilter }: DragDropProps) => {
  const { token, currentSchool, user } = useContext(AuthContext);
  const hasEditPermission = isAdmin(user) || isDirector(user);

  const [cards, setCards] = useState([] as any[]);

  const [errors, setErrors] = useState('');

  const [pagedMeta, setPagedMeta] = useState(createPagedMeta());
  const [filteredOptions] = useState({
    filters: [],
    options: createOptionsPage(pagedMeta),
  });

  const { hash } = useLocation();
  const hashFolderId = hash.replace('#', '');

  const [titleThisModule, setTitleThisModule] = useState('');

  const urlLocation = useLocation()['pathname'];
  useEffect(() => {
    if (urlLocation == '/curriculum') {
      setTitleThisModule('Curriculum');
      loadList(filteredOptions, 'Curriculum');
    } else if (urlLocation == '/resource') {
      setTitleThisModule('Resources');
      loadList(filteredOptions, 'Resources');
    }
  }, [urlLocation, onRefresh, currentSchool]);

  const loadList = async (filteredOptions: FilteredOptionsInterface, titleModule: string) => {
    try {
      let filterFolderId = null;
      if (!searchFilter) {
        if (hashFolderId) {
          filterFolderId = {
            field: 'documentsFoldersId',
            operation: '=',
            value: hashFolderId,
          };
        } else {
          filterFolderId = {
            field: 'documentsFoldersId',
            operation: '=',
            value: '0',
          };
        }
      } else {
        filterFolderId = {
          field: 'name',
          operation: 'ilike',
          value: `%${searchFilter ? searchFilter : ''}%`,
        };
      }
      const filter = {
        field: 'typeFile',
        operation: '=',
        value: titleModule,
      };

      const filterSchool = {
        field: 'schoolId',
        operation: 'hash',
        value: currentSchool.value,
      };

      filteredOptions.filters = [filter, filterFolderId, filterSchool];

      const result = await DocumentsFolders(token || '').list(filteredOptions);
      if (result.error == 'NOT_IN_SCHOOL') {
        toast.error('It is not possible to access the documents without being on the school network.');
        setErrors('It is not possible to access the documents without being on the school network.');
      }
      const data: DocumentsFoldersInterface[] = result.data;
      setCards(data);
      const meta: PagedMetaInterface = result.meta;
      if (meta) {
        setPagedMeta(meta);
      }
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Error fetching list');
      return toast.error(message);
    }
  };

  const updatePositions = async (
    filteredOptions: FilteredOptionsInterface,
    idFolder: string,
    dragIndex: number,
    hoverIndex: number,
  ) => {
    if (hasEditPermission) {
      let filterFolderIdPosition = null;
      if (hashFolderId) {
        filterFolderIdPosition = {
          field: 'documentsFoldersId',
          operation: '=',
          value: hashFolderId,
        };
      } else {
        filterFolderIdPosition = {
          field: 'documentsFoldersId',
          operation: '=',
          value: '0',
        };
      }
      const filter2 = {
        field: 'typeFile',
        operation: '=',
        value: titleThisModule,
      };

      const filterSchool = {
        field: 'schoolId',
        operation: 'hash',
        value: currentSchool.value,
      };

      filteredOptions.filters = [filter2, filterFolderIdPosition, filterSchool];

      const resultPositions = await DocumentsFolders(token || '').listForPosition(filteredOptions);
      const dataPositions: DocumentsFoldersInterface[] = resultPositions.data;

      const hoverIndexPosition = hoverIndex + 1;
      let countPositions = 1;
      dataPositions.map((row: any) => {
        if (row.id == idFolder) {
          handleSubmit(row.id, hoverIndexPosition);
        } else {
          if (countPositions == hoverIndexPosition) {
            countPositions = countPositions + 1;
          }
          handleSubmit(row.id, countPositions);
        }

        countPositions = countPositions + 1;
      });
    }
  };

  const handleSubmit = async (id: string, position: number) => {
    const body = {
      position: position,
    };

    try {
      if (hasEditPermission) {
        await DocumentsFolders(token || '').updatePositions(id, body);
      }
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Create error!');
      toast.error(message);
    }
  };

  {
    const moveCard = useCallback((dragIndex: number, hoverIndex: number, idFolder: string) => {
      setCards((prevCards: Item[]) =>
        update(prevCards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevCards[dragIndex] as Item],
          ],
        }),
      );
      updatePositions(filteredOptions, idFolder, dragIndex, hoverIndex);
    }, []);

    const renderCard = useCallback(
      (card: { id: number; name: string; position: number; picture: string; groupPermissions: any }, index: number) => {
        return (
          <DragDropCard
            position={card.position}
            key={card.id}
            index={index}
            id={card.id}
            name={card.name}
            moveCard={moveCard}
            picture={card.picture}
            onRefresh={onRefresh}
            groupPermissions={card.groupPermissions}
          />
        );
      },
      [],
    );

    return (
      <>
        <DndProvider backend={HTML5Backend}>
          {errors != '' && !cards.length && (
            <div className="alert alert-danger text-center" role="alert">
              {errors}
            </div>
          )}
          <Row>{cards.map((card, i) => renderCard(card, i))}</Row>
          {/* <CardFolder style={style} className="bg-secondary">
            {cards.map((card, i) => renderCard(card, i))}
          </CardFolder> */}
        </DndProvider>
      </>
    );
  }
};
