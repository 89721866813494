import { confirmAlert as reactConfirmAlert } from 'react-confirm-alert';
import { Container, Row, Col, Card, CardBody, CardTitle } from 'reactstrap';

type ImageModalProps = {
  title: string;
  picture: string;
};

const imageModal = ({ title, picture }: ImageModalProps) => {
  return reactConfirmAlert({
    customUI: ({ onClose }) => {
      return (
        <Container fluid>
          <Card>
            <Row>
              <Col>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => onClose()}
                >
                  <i className="ni ni-fat-remove" />
                </button>
              </Col>
            </Row>
            <CardBody style={{ paddingTop: 0 }}>
              <CardTitle className="text-uppercase text-muted mb-0">{title}</CardTitle>
              <Row className="mt-3">
                <Col md="12 text-center">
                  <img src={picture} />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      );
    },
  });
};

export default imageModal;
