import { Row, Col, Card, CardHeader, FormGroup, Input, CardBody, Button, Table } from 'reactstrap';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import { toLocaleFormatSlash } from '../../../helpers/dateFormat';
import { calculateAge } from '../../../helpers/dateDistance';
import WaitingListService from '../../../services/WaitingList';
import confirmAlert from '../../../helpers/confirmAlert';
import { isMobile } from 'react-device-detect';

type IProps = {
  waitingList: any[];
  orderByWaitingList: string;
  setOrderByWaitingList: (value: string) => void;
};

const WaitList = ({ waitingList, orderByWaitingList, setOrderByWaitingList }: IProps) => {
  const { token } = useContext(AuthContext);
  const [data, setData] = useState([] as any[]);
  const [text, setText] = useState('');

  useEffect(() => {
    const filtered = waitingList.filter((w: any) => w.status === 'ACTIVE');
    setData(filtered);
  }, [waitingList]);

  const handleSendEmail = async (e: any, id: string, name: string) => {
    e.preventDefault();
    return confirmAlert({
      title: 'Confirm',
      messageType: 'EDIT',
      message: `Are you sure you want to allow "${name}" to enroll?`,
      id: '',
      onClickYes: () => sendEmail(id),
    });
  };

  const sendEmail = async (id: string) => {
    try {
      if (id) {
        await WaitingListService(token || '').sendEmail(id);
        toast.success(`Saved successfully!`);
      }
    } catch (e: any) {
      console.error(e?.response?.data?.message || 'Error');
    }
  };

  useEffect(() => {
    setFilterSearch(text);
  }, [text]);

  const setFilterSearch = (text: string) => {
    if (!text) return setData(waitingList);
    const newData = waitingList.filter((w: any) =>
      String(`${w.firstName} ${w.lastName} ${w.parentFirstName} ${w.parentLastName}`).includes(text),
    );
    setData(newData);
  };

  return (
    <Row className="mt-5 home-waitingList">
      <Col xl="12">
        <Card className="shadow">
          <CardHeader className="border-0">
            <Row className="align-items-center">
              <Col xl="10">
                <h3 className="mb-1">Wait List</h3>
                <FormGroup>
                  <Input
                    className="form-control-alternative"
                    placeholder="Search"
                    type="text"
                    onChange={(e) => setText(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="text-right" xl="2">
                {waitingList && (
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-ageSteps">
                      Order By
                    </label>
                    <Input
                      type="select"
                      onChange={(e) => setOrderByWaitingList(e.target.value)}
                      value={orderByWaitingList}
                    >
                      <option value="age">Age</option>
                      <option value="name">Name</option>
                    </Input>
                  </FormGroup>
                )}
              </Col>
            </Row>
          </CardHeader>
          <CardBody className="home-waiting_listCardBody">
            {data?.length === 0 && <span>Data not found</span>}

            {data?.length > 0 && (
              <Table responsive>
                <thead>
                  <tr>
                    <th colSpan={2}>Kids Name</th>
                    {!isMobile && (
                      <>
                        <th>Potential Date</th>
                        <th>Kids Birthday</th>
                        <th colSpan={2}>Parent name</th>
                        <th>Parent Phone</th>
                        <th colSpan={2}>Parent Email</th>
                      </>
                    )}
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((w: any, index: number) => (
                    <tr key={`waiting-list-${w.id}-${index}`}>
                      <td colSpan={2}>
                        <Link to={`/kids/${w.id}`}>
                          {w.firstName} <span className="home-waitinglist-lastname">{w.lastName}</span>
                        </Link>
                      </td>
                      {!isMobile && (
                        <>
                          <td>{w.potentialDate && toLocaleFormatSlash(w.potentialDate)}</td>
                          <td>
                            {toLocaleFormatSlash(w.birthday)} ({calculateAge(toLocaleFormatSlash(w.birthday))} years)
                          </td>
                          <td colSpan={2}>
                            {w.parentFirstName} <span className="home-waitinglist-lastname">{w.parentLastName}</span>
                          </td>
                          <td>{w.phone}</td>
                          <td colSpan={2}>{w.email}</td>
                        </>
                      )}

                      <td>
                        <Button
                          size="sm"
                          onClick={(e) => handleSendEmail(e, w.wlId, `${w.firstName} ${w.lastName}`)}
                          color="primary"
                        >
                          <i className="fa fa-envelope" aria-hidden="true"></i> Send Email
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default WaitList;
