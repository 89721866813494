const Footer = () => {
  return (
    <footer className="footer">
      <div className="copyright text-center text-muted">
        © {new Date().getFullYear()}{' '}
        <a className="font-weight-bold ml-1" href="https://arb.systems" rel="noopener noreferrer" target="_blank">
          Arbitrium Systems
        </a>
      </div>
    </footer>
  );
};

export default Footer;
