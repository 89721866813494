import moment from 'moment';
import { FilteredOptionsInterface, makeQueryParamsForRequest } from '../entities/FilteredOptions';
import http from '../helpers/http';

const Tour = (token: string) => {
  const headers = {
    'x-access-token': token,
  };

  return {
    show: async (id: string) => {
      const result = await http.get(`/schedule/${id}`, {
        headers,
      });
      return result.data;
    },
    list: async (filteredOptions?: FilteredOptionsInterface) => {
      const params = makeQueryParamsForRequest(filteredOptions);
      const result = await http.get('/schedule', {
        headers,
        params,
      });
      return result?.data;
    },
    listByFilter: async (textParam: string, schoolId: string) => {
      const result: any = await http.get(`/schedule/${schoolId}/list-filter`, {
        headers,
        params: {
          text: textParam || '',
        },
      });
      return result?.data;
    },
    listTimeSlot: async (schoolId: string) => {
      const params = makeQueryParamsForRequest();
      const result = await http.get(`/schedule/list/${schoolId}/timeSlot`, {
        headers: {
          'x-access-token': token,
        },
        params,
      });
      return result?.data;
    },
    listCalendar: async (schoolId: string) => {
      const params = makeQueryParamsForRequest();
      const result = await http.get(`/schedule/list/${schoolId}`, {
        headers: {
          'x-access-token': token,
        },
        params,
      });
      return result?.data;
    },
    listCalendarByDates: async (
      schoolId: string,
      startAt: any,
      endAt: any,
      filteredOptions?: FilteredOptionsInterface,
    ) => {
      const params =
        filteredOptions && startAt && endAt
          ? {
              ...makeQueryParamsForRequest(filteredOptions),
              ...{ startAt: moment(startAt).format('YYYY-MM-DD'), endAt: moment(endAt).format('YYYY-MM-DD') },
            }
          : startAt && endAt
          ? {
              ...makeQueryParamsForRequest(),
              ...{ startAt: moment(startAt).format('YYYY-MM-DD'), endAt: moment(endAt).format('YYYY-MM-DD') },
            }
          : makeQueryParamsForRequest();
      const result = await http.get(`/schedule/list-by-period/${schoolId}`, {
        headers: {
          'x-access-token': token,
        },
        params,
      });
      return result?.data;
    },
    listCurrentMonth: async (schoolId: string) => {
      const result = await http.get(`/schedule/${schoolId}/list-current-month`, {
        headers,
      });
      return result?.data;
    },
    listCalendarBlockDays: async (schoolId: string) => {
      const params = makeQueryParamsForRequest();
      const result = await http.get(`/schedule/listBlockTimeDays/${schoolId}`, {
        headers: {
          'x-access-token': token,
        },
        params,
      });
      return result?.data;
    },
    create: async (body: any) => {
      const result: any = await http.post('/schedule', body, { headers });
      return result.data;
    },
    update: async (id: string, body: any) => {
      const result: any = await http.put(`/schedule/${id}`, body, { headers });
      return result.data;
    },
    delete: async (id: string) => {
      const result: any = await http.delete(`/schedule/${id}`, {
        headers,
      });
      return result.data;
    },
  };
};

export default Tour;
