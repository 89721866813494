import http from '../helpers/http';

const EnrollmentFilesType = (token: string) => {
  const headers = {
    'x-access-token': token,
  };

  return {
    list: async () => {
      const result = await http.get(`/enrollment-files-type`, {
        headers,
      });
      return result.data;
    },
    create: async (body: { name: string }) => {
      const result = await http.post(`/enrollment-files-type`, body, { headers });
      return result.data;
    },
    delete: async (id: string) => {
      const result = await http.delete(`/enrollment-files-type/${id}`, {
        headers,
      });
      return result.data;
    },
  };
};

export default EnrollmentFilesType;
