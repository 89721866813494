import { useContext, useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Modal, Row, Spinner } from 'reactstrap';
import TransactionService from '../../../services/Transaction';

import { Context as AuthContext } from '../../../contexts/AuthContext';
import { Elements } from '@stripe/react-stripe-js';

import BillingPaymentDataForm from './BillingPaymentDataForm';
import { useParams } from 'react-router-dom';

type BillingParentSavePaymentProps = {
  isOpen: boolean;
  id: string;
  name: string;
  email: string;
  transactionType: string;
  customerOptions: any;
  stripePromise: any;
  toggleModal: (e?: any) => void;
};

const BillingParentSavePayment = ({
  isOpen,
  id,
  name,
  email,
  transactionType,
  customerOptions,
  stripePromise,
  toggleModal,
}: BillingParentSavePaymentProps) => {
  const { token }: any = useContext(AuthContext);
  const { kidsId } = useParams<{ kidsId: string }>();
  const [options, setOptions] = useState(customerOptions as any);
  const [loading, setLoading] = useState(false);
  const [showButton, setShowButton] = useState(true);

  useEffect(() => {
    if (isOpen) {
      handlePaymentIntent(transactionType);
    }
  }, [isOpen]);

  useEffect(() => {
    if (options?.clientSecret) {
      setShowButton(false);
    }
    setLoading(false);
  }, [options]);

  const handlePaymentIntent = async (transactionType: string) => {
    setLoading(true);
    const intent = await TransactionService(token).createIntent({
      userId: id || '',
      transactionType,
    });
    const newOptions = JSON.parse(JSON.stringify(options));
    newOptions.clientSecret = intent?.data?.clientSecret || '';
    setOptions(newOptions);
    setLoading(false);
  };

  return (
    <>
      <Modal className="modal-dialog-centered" isOpen={isOpen} toggle={(e: any) => toggleModal(e)}>
        <div className="modal-header pb-1">
          <h5 className="modal-title">{name}</h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={(e) => toggleModal(e)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body pt-0">
          <Row>
            <Col xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">Save Payment Method</CardHeader>
                <CardBody>
                  <Row>
                    {!loading && (
                      <>
                        {!showButton && (
                          <Col xl="12">
                            <Elements stripe={stripePromise} options={options}>
                              <BillingPaymentDataForm
                                currentKidsId={kidsId}
                                parentId={id}
                                parentName={name}
                                parentEmail={email}
                              />
                            </Elements>
                          </Col>
                        )}
                      </>
                    )}
                    {loading && (
                      <Col xl="12" className="mt-4 text-center">
                        <span>
                          <Spinner />
                        </span>
                      </Col>
                    )}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default BillingParentSavePayment;
