/* eslint-disable max-lines */
import { useEffect, useState, useContext } from 'react';
import { toast } from 'react-toastify';
import {
  Row,
  Col,
  CardBody,
  Card,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Spinner,
} from 'reactstrap';
import CardWidgetText from '../../../components/Cards/CardWidgetText';
import BillingACHPaymentModal from './BillingACHPaymentModal';
import BillingCardPaymentModal from './BillingCardPaymentModal';
import BillingManualPaymentModal from './BillingManualPaymentModal';
import BillingParentPaymentModal from './BillingParentPaymentModal';
import BillingParentsModal from './BillingParentsModal';
import BillingParentsSummary from './BillingParentsSummary';
import isAdmin from '../../../helpers/isAdmin';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import isEducationDirectorAdminAssistant from '../../../helpers/isEducationDirectorAdminAssistant';
import isUpperManagment from '../../../helpers/isUpperManagment';

import { isMobile } from 'react-device-detect';

const BillingParents = ({
  parents,
  kidsList,
  reload,
  loading,
}: {
  parents: any[];
  kidsList: any[];
  reload: () => void;
  loading: boolean;
}) => {
  const isTablet = window.innerWidth <= 1112 && window.innerWidth > 768 && isMobile;
  const parentsPayer = parents?.filter((p) => p.isPaying);
  const totalAccounts = parentsPayer?.length || 0;
  let totalBillingAmount = 0;
  let totalBalanceAmount = 0;
  for (const k of kidsList) {
    const amount = Number(k?.billingSettings?.amount || 0);
    if (amount) {
      totalBillingAmount = totalBillingAmount + amount;
    }
    const balance = Number(k?.balance?.amount || 0);
    if (balance) {
      totalBalanceAmount = totalBalanceAmount + balance;
    }
  }

  const [modal, setModal] = useState(false);
  const [currentId, setCurrentId] = useState('');
  const [currentName, setCurrentName] = useState('');
  const [currentEmail, setCurrentEmail] = useState('');
  const [manualPaymentModal, setManualPaymentModal] = useState(false);
  const [achPaymentModal, setAchPaymentModal] = useState(false);
  const [cardPaymentModal, setCardPaymentModal] = useState(false);
  const { user } = useContext(AuthContext);
  const userAdmin = isAdmin(user) || isEducationDirectorAdminAssistant(user) || isUpperManagment(user);

  const [parentPaymentModal, setParentPaymentModal] = useState(false);

  useEffect(() => {
    const parentIdParam = new URLSearchParams(window.location.search).get('parentId');
    const parentNameParam = new URLSearchParams(window.location.search).get('parentName');
    const parentEmailParam = new URLSearchParams(window.location.search).get('parentEmail');
    if (parentIdParam && parentNameParam && parentEmailParam) {
      setCurrentId(parentIdParam);
      setCurrentName(parentNameParam);
      setCurrentEmail(parentEmailParam);
      setModal(true);
    }
  }, []);

  const handleClick = (id: string, name: string, email: string) => {
    setCurrentId(id);
    setCurrentName(name);
    setCurrentEmail(email);
    setModal(true);
  };

  const toggleModal = (e: any) => {
    e.preventDefault();
    setModal(!modal);
  };

  const toggleManualPaymentModal = (e: any) => {
    e.preventDefault();
    setManualPaymentModal(!manualPaymentModal);
  };

  const toggleAchPaymentModal = (e: any) => {
    e.preventDefault();
    setAchPaymentModal(!achPaymentModal);
  };

  const toggleCardPaymentModal = (e: any) => {
    e.preventDefault();
    setCardPaymentModal(!cardPaymentModal);
  };

  const toggleParentPaymentModal = (e: any) => {
    e.preventDefault();
    setParentPaymentModal(!parentPaymentModal);
  };

  const manualPaymentModalChange = (e?: any) => {
    if (e) {
      e.preventDefault();
    }
    reload();
    toast.success('Manual payment created successfully');
    setManualPaymentModal(!manualPaymentModal);
  };

  const achPaymentModalChange = (e?: any) => {
    if (e) {
      e.preventDefault();
    }
    reload();
    toast.success('ACH charge created successfully');
    setAchPaymentModal(!achPaymentModal);
  };

  const cardPaymentModalChange = (e?: any) => {
    if (e) {
      e.preventDefault();
    }
    reload();
    toast.success('Credit Card charge created successfully');
    setCardPaymentModal(!cardPaymentModal);
  };

  return (
    <>
      {!userAdmin && isMobile && (
        <UncontrolledDropdown style={{ width: '100%', paddingBottom: 5 }}>
          <DropdownToggle
            href="#"
            role="button"
            size="md"
            color="success"
            onClick={(e) => e.preventDefault()}
            style={{ width: '100%' }}
          >
            Options
          </DropdownToggle>

          <DropdownMenu className="dropdown-menu-arrow" right>
            <DropdownItem
              onClick={(e) => {
                e.preventDefault();
                setParentPaymentModal(true);
              }}
            >
              Payment
            </DropdownItem>
            <DropdownItem
              onClick={(e) => {
                e.preventDefault();
                handleClick(user?.id, user?.firstName ? `${user?.firstName} ${user?.lastName}` : '', user?.email);
              }}
            >
              Update ACH / CC Info
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      )}
      {userAdmin && isMobile && !isTablet && (
        <UncontrolledDropdown style={{ width: '100%', paddingBottom: 5 }}>
          <DropdownToggle
            href="#"
            role="button"
            size="md"
            color="success"
            onClick={(e) => e.preventDefault()}
            style={{ width: '100%' }}
          >
            Payments
          </DropdownToggle>

          <DropdownMenu className="dropdown-menu-arrow" right>
            <DropdownItem
              onClick={(e) => {
                e.preventDefault();
                setManualPaymentModal(true);
              }}
            >
              Manual Payment
            </DropdownItem>
            <DropdownItem
              onClick={(e) => {
                e.preventDefault();
                setAchPaymentModal(true);
              }}
            >
              ACH Charge
            </DropdownItem>
            <DropdownItem
              onClick={(e) => {
                e.preventDefault();
                setCardPaymentModal(true);
              }}
            >
              CC Charge
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      )}
      <Card className="bg-secondary shadow mb-1">
        <CardBody>
          <Row className={isMobile ? 'mb-1' : 'mb-4'}>
            <Col xs="12" className={isMobile ? 'mb-1' : 'mb-2'}>
              <h3 className="mb-0">
                Parents{' '}
                <Button color="link" type="button" title="Reload" size="sm" onClick={() => reload()}>
                  {loading ? (
                    <Spinner style={{ width: '0.7rem', height: '0.7rem' }} color="primary" />
                  ) : (
                    <i className="fas fa-sync"></i>
                  )}
                </Button>
              </h3>

              {!userAdmin && (
                <UncontrolledDropdown style={{ float: 'right' }}>
                  <DropdownToggle href="#" role="button" size="sm" color="success" onClick={(e) => e.preventDefault()}>
                    Options
                  </DropdownToggle>

                  <DropdownMenu className="dropdown-menu-arrow" right>
                    <DropdownItem
                      onClick={(e) => {
                        e.preventDefault();
                        setParentPaymentModal(true);
                      }}
                    >
                      Payment
                    </DropdownItem>
                    <DropdownItem
                      onClick={(e) => {
                        e.preventDefault();
                        handleClick(
                          user?.id,
                          user?.firstName ? `${user?.firstName} ${user?.lastName}` : '',
                          user?.email,
                        );
                      }}
                    >
                      Update ACH / CC Info
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
              {userAdmin && (!isMobile || isTablet) && (
                <UncontrolledDropdown style={{ float: 'right' }} className={isMobile ? 'mt-n4' : ''}>
                  <DropdownToggle href="#" role="button" size="sm" color="success" onClick={(e) => e.preventDefault()}>
                    Payments
                  </DropdownToggle>

                  <DropdownMenu className="dropdown-menu-arrow" right>
                    <DropdownItem
                      onClick={(e) => {
                        e.preventDefault();
                        setManualPaymentModal(true);
                      }}
                    >
                      Manual Payment
                    </DropdownItem>
                    <DropdownItem
                      onClick={(e) => {
                        e.preventDefault();
                        setAchPaymentModal(true);
                      }}
                    >
                      ACH Charge
                    </DropdownItem>
                    <DropdownItem
                      onClick={(e) => {
                        e.preventDefault();
                        setCardPaymentModal(true);
                      }}
                    >
                      CC Charge
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
            </Col>
          </Row>
          <Row className={isMobile ? 'mb-1' : ''}>
            {userAdmin && (
              <>
                {parents?.length ? (
                  parents
                    .filter((p) => p.isPaying)
                    .map((p, index) => {
                      const title = p?.user?.firstName ? `${p?.user?.firstName} ${p?.user?.lastName}` : '';
                      const amount = Number(totalBillingAmount || 0);
                      const percent = Number(p?.payPercent || 0);
                      const amountPercent = !amount || !percent ? 0 : (percent / 100) * amount;
                      return (
                        <Col
                          lg="3"
                          xl="3"
                          xs="6"
                          key={`billing-parent-${index}`}
                          className="billing-parents-info"
                          style={isMobile ? { paddingLeft: 5, paddingRight: 5 } : {}}
                        >
                          <CardWidgetText
                            styles={isMobile ? { maxHeight: 100, minHeight: 100 } : {}}
                            title={title}
                            text={`$${amountPercent.toFixed(2)}`}
                            textSecondary={`${percent.toFixed(2)}%`}
                            onClick={() => handleClick(p?.user?.id, title, p?.user?.email)}
                          />
                        </Col>
                      );
                    })
                ) : (
                  <Col>There are no parents for this child</Col>
                )}
              </>
            )}
            {!userAdmin && (
              <>
                {parents?.length ? (
                  parents
                    .filter((p) => p.isPaying && p.user.id === user.id)
                    .map((p, index) => {
                      const title = p?.user?.firstName ? `${p?.user?.firstName} ${p?.user?.lastName}` : '';
                      const amount = Number(totalBillingAmount || 0);
                      const percent = Number(p?.payPercent || 0);
                      const amountPercent = !amount || !percent ? 0 : (percent / 100) * amount;
                      return (
                        <Col lg="4" xl="4" key={`billing-parent-${index}`}>
                          <CardWidgetText
                            title={title}
                            text={`$${amountPercent.toFixed(2)}`}
                            textSecondary={`${percent.toFixed(2)}%`}
                            onClick={() => handleClick(p?.user?.id, title, p?.user?.email)}
                          />
                        </Col>
                      );
                    })
                ) : (
                  <Col>There are no parents for this child</Col>
                )}
                <BillingParentsSummary
                  totalAccounts={totalAccounts}
                  totalBillingAmount={totalBillingAmount}
                  totalBalanceAmount={totalBalanceAmount}
                  isAdmin={userAdmin}
                />
              </>
            )}
          </Row>
          {userAdmin && (
            <Row className={isMobile ? 'mt-1' : `mt-3`}>
              <BillingParentsSummary
                totalAccounts={totalAccounts}
                totalBillingAmount={totalBillingAmount}
                totalBalanceAmount={totalBalanceAmount}
                isAdmin={userAdmin}
              />
            </Row>
          )}
        </CardBody>
      </Card>
      <BillingParentsModal
        isOpen={modal}
        toggleModal={toggleModal}
        id={currentId}
        name={currentName}
        email={currentEmail}
      />
      <BillingManualPaymentModal
        parents={parents}
        kidsList={kidsList}
        isOpen={manualPaymentModal}
        toggleModal={toggleManualPaymentModal}
        onChange={manualPaymentModalChange}
      />
      <BillingACHPaymentModal
        parents={parents}
        kidsList={kidsList}
        invoicesList={[]}
        isOpen={achPaymentModal}
        toggleModal={toggleAchPaymentModal}
        onChange={achPaymentModalChange}
      />
      <BillingCardPaymentModal
        parents={parents}
        kidsList={kidsList}
        invoicesList={[]}
        isOpen={cardPaymentModal}
        toggleModal={toggleCardPaymentModal}
        onChange={cardPaymentModalChange}
      />
      <BillingParentPaymentModal
        parents={parents}
        kidsList={kidsList}
        isOpen={parentPaymentModal}
        toggleModal={toggleParentPaymentModal}
        onChange={manualPaymentModalChange}
        totalBalanceAmount={totalBalanceAmount}
      />
    </>
  );
};

export default BillingParents;
