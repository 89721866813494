import { Row, Col, Card, CardHeader, CardBody, FormGroup, Input, Button } from 'reactstrap';
import { useContext, useState, useEffect } from 'react';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import TourService from '../../../services/Tour';
import ScheduleCalendarComponent from '../Schedule/ScheduleCalendarComponent';
import isAdmin from '../../../helpers/isAdmin';
import SchedulleSettingsModal from './SchedulleSettingsModal';
import CardStatsText from '../../../components/Cards/CardStatsText';
import CardStatsCols from '../../../components/Cards/CardStatsCols';
import ScheduleBlockdaySettings from '../../../services/ScheduleBlockdaySettings';
import TourList from '../Home/TourList';
import { isMobile } from 'react-device-detect';
import moment, { Moment } from 'moment';

const ScheduleCalendar = () => {
  const isTablet = window.innerWidth <= 1024 && !isMobile;
  const { token, user, currentSchool }: any = useContext(AuthContext);

  const isUserAdmin = isAdmin(user);
  const hasSettingsPermission = isUserAdmin;
  const [settingsModal, setSettingsModal] = useState(false);
  const [enabledTour, setEnabledTour] = useState(false);
  const [showList, setShowList] = useState(false);

  const [data, setData] = useState([]);
  const [updateEventList, setUpdateEventList] = useState(false);
  const [walkInTours, setWalkInTours] = useState(0);
  const [byPhoneTours, setByPhoneTours] = useState(0);
  const [onlineTours, setOnlineTours] = useState(0);
  const [totalTours, setTotalTours] = useState(0);
  const [totalNoShowTours, setTotalNoShowTours] = useState(0);
  const [enrolledTours, setEnrolledTours] = useState(0);
  const [totalConversion, setTotalConversion] = useState(0);
  const [tourConversion, setTourConversion] = useState<{ text: string; description: string }[]>([]);

  const handleScheduleCreated = () => {
    setUpdateEventList(!updateEventList);
    getCalendarList();
  };

  const getCalendarList = async (
    start: Moment = moment().startOf('month').startOf('week'),
    end: Moment = moment().endOf('month').endOf('week'),
  ) => {
    if (!currentSchool?.value || !start || !end) return;
    try {
      const result = await TourService(token || '').listCalendarByDates(currentSchool?.value || '', start, end);
      const dataResult = result?.data || [];
      const blockDaysResult = await ScheduleBlockdaySettings(token || '').list(currentSchool?.value);
      const blockDays = blockDaysResult?.data || [];
      for (const b of blockDays) {
        dataResult.push({
          id: null,
          title: b?.typeBlock === 'entire_day' ? 'All Day Blocked' : 'Blocked',
          start: b?.dateBlock + 'T' + b?.timeStartBlock,
          classNames: 'schedule-calendar-event-red',
          allDay: b?.typeBlock === 'entire_day',
        });
      }
      setData(dataResult);
      const resultCurrentMonth = result;
      const dataResultCurrentMonth = resultCurrentMonth.data || [];
      const walkIn = dataResultCurrentMonth?.filter((d: any) => d?.typeSchedule === 'Walk In');
      setWalkInTours(walkIn?.length || 0);
      const byPhone = dataResultCurrentMonth?.filter((d: any) => d?.typeSchedule === 'By Phone');
      setByPhoneTours(byPhone?.length || 0);
      const toursOnline = dataResultCurrentMonth?.filter((d: any) => d?.typeSchedule === 'Online');
      setOnlineTours(toursOnline?.length || 0);
      const total = (walkIn?.length || 0) + (byPhone?.length || 0) + (toursOnline?.length || 0);
      setTotalTours(total);
      let noShow = 0;
      let enrolled = 0;
      const countByUser: any[] = [];
      for (const t of dataResultCurrentMonth) {
        if (t?.touredBy?.id) {
          const countByUserExists = countByUser?.find((u) => u?.id === t?.touredBy?.id);
          if (!countByUserExists) {
            const firstInitial = `${String(t?.touredBy?.firstName).substring(0, 1)}`;
            const lastInitial = `${String(t?.touredBy?.lastName).substring(0, 1)}`;
            countByUser.push({
              id: t?.touredBy?.id,
              name: String(`${firstInitial}${lastInitial}`).toUpperCase(),
              tours: 0,
              enrolled: 0,
            });
          }
        }
        const countByUserCurrent = countByUser?.find((u) => u?.id === t?.touredBy?.id);
        if (!countByUserCurrent) continue;
        countByUserCurrent.tours = countByUserCurrent?.tours + 1;
        const k = t?.kids?.filter((r: any) => r?.status === 'NO_SHOW_TOUR');
        if (k?.length) {
          noShow = noShow + 1;
        }
        const e = t?.kids?.filter((r: any) => r?.status === 'ENROLLED');
        if (e?.length) {
          enrolled = enrolled + 1;
          countByUserCurrent.enrolled = countByUserCurrent?.enrolled + 1;
        }
      }
      setTourConversion(
        countByUser?.map((c) => {
          const percent = c?.tours > 0 ? (c?.enrolled / c?.tours) * 100 : 0;
          return {
            text: String(c?.name),
            description: `${parseFloat(Number(percent).toFixed(2))}%`,
          };
        }),
      );
      setTotalNoShowTours(noShow);
      setEnrolledTours(enrolled);
      let conversionTotal = 0;
      const conversion = Number((enrolled / (total - noShow)) * 100);
      if (!Number.isNaN(conversion)) {
        conversionTotal = conversion;
      }
      setTotalConversion(conversionTotal);
    } catch (e: any) {
      console.error('Error on get calendar list');
    }
  };

  useEffect(() => {
    getCalendarList();
  }, []);

  useEffect(() => {
    getCalendarList();
  }, [updateEventList]);

  useEffect(() => {
    getCalendarList();
  }, [currentSchool]);

  const alterDatesSetCalendar = async (args: any) => {
    await getCalendarList(moment(args?.start), moment(args?.end));
  };

  return (
    <>
      <Card className="bg-secondary shadow">
        <CardHeader className="bg-white border-0" style={isMobile ? { paddingBottom: 0 } : {}}>
          <Row className="align-items-center">
            <Col sm="7" xs="12">
              <h3 className={isMobile ? 'mb-2' : `mb-0`}>Tour Schedule</h3>

              {hasSettingsPermission && (
                <>
                  <FormGroup
                    style={isMobile ? { display: 'inline-flex', marginBottom: '1rem' } : { display: 'inline-flex' }}
                    className="mr-4"
                  >
                    <Row style={{ marginRight: '0' }}>
                      <Col>
                        <label className="form-control-label" htmlFor="input-enabledTour">
                          Enabled Tour
                        </label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <label className="custom-toggle">
                          <Input
                            type="checkbox"
                            onChange={(e) => setEnabledTour(e?.target?.checked)}
                            checked={enabledTour}
                          />
                          <span className="custom-toggle-slider rounded-circle" />
                        </label>
                      </Col>
                    </Row>
                  </FormGroup>
                  <i
                    className="fas fa-cog fa-lg mr-4"
                    onClick={() => setSettingsModal(true)}
                    style={{ cursor: 'pointer' }}
                  ></i>
                </>
              )}

              <Button color="primary" size="sm" onClick={() => setShowList(!showList)}>
                List
              </Button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md="12" className="mb-4">
              {showList ? (
                <TourList />
              ) : (
                <ScheduleCalendarComponent
                  events={data}
                  updateEventList={handleScheduleCreated}
                  handleDatesSet={alterDatesSetCalendar}
                />
              )}
            </Col>
            <Col md="3" xs="4" style={isMobile || isTablet ? { padding: 1 } : {}}>
              <CardStatsText
                title={isMobile || isTablet ? 'Walk in' : 'Walk in Tours'}
                description={String(walkInTours)}
              />
            </Col>
            <Col md="3" xs="4" style={isMobile || isTablet ? { padding: 1 } : {}}>
              <CardStatsText
                title={isMobile || isTablet ? 'Phone' : 'Phone Scheduled Tours'}
                description={String(byPhoneTours)}
              />
            </Col>
            <Col md="3" xs="4" style={isMobile || isTablet ? { padding: 1 } : {}}>
              <CardStatsText
                title={isMobile || isTablet ? 'Online' : 'Online Scheduled Tours'}
                description={String(onlineTours)}
              />
            </Col>
            <Col md="3" xs="4" style={isMobile || isTablet ? { padding: 1 } : {}}>
              <CardStatsText
                title={isMobile || isTablet ? 'No Show' : 'Total No Show'}
                description={String(totalNoShowTours)}
              />
            </Col>
            <Col md="3" xs="4" style={isMobile || isTablet ? { padding: 1 } : {}}>
              <CardStatsText
                title={isMobile || isTablet ? 'T. Tours' : 'Total Tours'}
                description={String(totalTours)}
              />
            </Col>
            <Col md="3" xs="4" style={isMobile || isTablet ? { padding: 1 } : {}}>
              <CardStatsText
                title={isMobile || isTablet ? 'Enrolled' : 'New Enrolled Children'}
                description={String(enrolledTours)}
              />
            </Col>

            <Col md="3" xs="6" style={isMobile || isTablet ? { padding: 1 } : {}}>
              <CardStatsText
                style={{ minHeight: '90px' }}
                title="Total Conversion"
                description={`${String(parseFloat(Number(totalConversion).toFixed(2)))}%`}
              />
            </Col>
            <Col lg="3" md="3" xs="6" style={isMobile || isTablet ? { padding: 1 } : {}}>
              <CardStatsCols title="Tour Conversion" cols={tourConversion} />
            </Col>
          </Row>
        </CardBody>
      </Card>

      <SchedulleSettingsModal
        isOpen={settingsModal}
        toggleModal={() => {
          if (settingsModal) getCalendarList();
          setSettingsModal(!settingsModal);
        }}
      />
    </>
  );
};

export default ScheduleCalendar;
