import { Container, Row, Col, FormGroup, Input, Button, Card, CardBody, CardTitle, Form } from 'reactstrap';
import { useContext, useState, useEffect } from 'react';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import InputMask from 'react-input-mask';
import ChildForm from './ChildForm';
import { toLocaleFormatSlash } from '../../../helpers/dateFormat';
import WaitingList from '../../../services/WaitingListPublic';
import SuccessPage from './SuccessPage';

const WaitingListForm = () => {
  const { schoolUri } = useParams<{ schoolUri: string }>();
  const { token }: any = useContext(AuthContext);

  const [schoolId, setSchoolId] = useState('');
  const [schoolName, setSchoolName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [aboutUs, setAboutUs] = useState('');
  const [kids, setKids] = useState([{ firstName: '', lastName: '', birthday: '', potentialDate: '' }]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [successFirstName, setSuccessFirstName] = useState('');
  const [successLastName, setSuccessLastName] = useState('');
  const [showUnavailable, setShowUnavailable] = useState(false);

  const checkNameSchool = async () => {
    try {
      const result = await WaitingList(token || '').getNameSchool(schoolUri);
      setSchoolId(result?.data?.id);
      setSchoolName(result?.data?.name);
      if (!result?.data?.waitingList) {
        setShowUnavailable(true);
      }
    } catch (e) {
      toast.error('Error searching for School');
    }
  };

  const setChildFirstName = (value: string, index: number) => {
    const newKids = [...kids];
    newKids.forEach((k, i) => {
      if (i == index) {
        k.firstName = value;
      }
    });
    setKids(newKids);
  };

  const setChildLastName = (value: string, index: number) => {
    const newKids = [...kids];
    newKids.forEach((k, i) => {
      if (i == index) {
        k.lastName = value;
      }
    });
    setKids(newKids);
  };

  const setChildBirthday = (value: string, index: number) => {
    const newKids = [...kids];
    newKids.forEach((k, i) => {
      if (i == index) {
        k.birthday = toLocaleFormatSlash(value);
      }
    });
    setKids(newKids);
  };

  const setPotentialDate = (value: string, index: number) => {
    const newKids = [...kids];
    newKids.forEach((k, i) => {
      if (i == index) {
        k.potentialDate = toLocaleFormatSlash(value);
      }
    });
    setKids(newKids);
  };

  const addKids = () => {
    const newKids = [...kids];
    newKids.push({ firstName: '', lastName: '', birthday: '', potentialDate: '' });
    setKids(newKids);
  };

  const deleteKids = (index: number) => {
    const newKids = kids.filter((_, i) => index !== i);
    setKids(newKids);
  };

  const handleSendSubmit = async (e: any) => {
    e.preventDefault();
    const body = {
      firstName,
      lastName,
      email,
      phone,
      aboutUs,
      schoolId,
      statusDescription: 'Waiting List',
      kids,
    };

    try {
      await WaitingList(token || '').create(body);
      resetFields();
      toast.success(`Saved successfully!`);
      setIsSuccess(true);
      setSuccessFirstName(firstName);
      setSuccessLastName(lastName);
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Create error!');
      toast.error(message);
      setIsSuccess(false);
    }
  };

  const resetFields = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setPhone('');
    setAboutUs('');
    setKids([{ firstName: '', lastName: '', birthday: '', potentialDate: '' }]);
  };

  useEffect(() => {
    checkNameSchool();
  }, [schoolId]);

  return !isSuccess ? (
    <>
      {!showUnavailable ? (
        <Row>
          <Col md="2"></Col>
          <Col md="8">
            <Card className="shadow mt-5">
              <Container fluid>
                <Form onSubmit={handleSendSubmit}>
                  <Card style={{ border: 0 }}>
                    <CardBody>
                      <CardTitle tag="h1" className="text-uppercase text-muted mb-2">
                        Waiting List
                      </CardTitle>
                      <Row>
                        <Col>
                          <h2>Your Information</h2>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p>
                            To add your family's name to the waiting list at <strong>{schoolName}</strong>, please
                            provide the following information:
                          </p>
                        </Col>
                      </Row>
                      <Card>
                        <CardBody>
                          <CardTitle tag="h5" className="text-uppercase text-muted mb-1">
                            Parent information
                          </CardTitle>

                          <Row>
                            <Col lg="6">
                              <FormGroup>
                                <label className="form-control-label">First Name</label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-firstName"
                                  placeholder="First Name"
                                  type="text"
                                  value={firstName}
                                  onChange={(e) => setFirstName(e.target.value)}
                                  required
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label className="form-control-label">Last Name</label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-lastName"
                                  placeholder="Last Name"
                                  type="text"
                                  value={lastName}
                                  onChange={(e) => setLastName(e.target.value)}
                                  required
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <FormGroup>
                                <label className="form-control-label">Email address</label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  placeholder="Email address"
                                  type="email"
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                  required
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label className="form-control-label">Phone</label>
                                <Input
                                  className="form-control-alternative"
                                  placeholder="Phone"
                                  type="text"
                                  mask="(999) 999-9999"
                                  tag={InputMask}
                                  value={phone}
                                  onChange={(e) => setPhone(e.target.value)}
                                  required
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="12">
                              <FormGroup>
                                <label className="form-control-label">How did you hear about us?</label>
                                <select
                                  name="input-aboutUs"
                                  className="form-control"
                                  value={aboutUs}
                                  onChange={(e) => setAboutUs(e.target.value)}
                                  required
                                >
                                  <option value="">Please select</option>
                                  <option value="Co-Worker">Co-Worker</option>
                                  <option value="Drive By">Drive By</option>
                                  <option value="Family Referral">Family Referral</option>
                                  <option value="Friend">Friend</option>
                                  <option value="Online">Online</option>
                                  <option value="Other">Other</option>
                                </select>
                              </FormGroup>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                      <Card>
                        <CardBody>
                          <CardTitle tag="h5" className="text-uppercase text-muted mb-1">
                            Childs information
                          </CardTitle>

                          {kids.map((child, index: number) => (
                            <ChildForm
                              key={`form-${index}`}
                              firstName={child.firstName}
                              lastName={child.lastName}
                              birthday={child.birthday}
                              potentialDate={child.potentialDate}
                              index={index}
                              setFirstName={setChildFirstName}
                              setLastName={setChildLastName}
                              setBirthday={setChildBirthday}
                              setPotentialDate={setPotentialDate}
                              deleteChild={deleteKids}
                            />
                          ))}
                          <Row>
                            <Col>
                              <Button color="info" size="sm" onClick={addKids}>
                                Add Child
                              </Button>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                      <Row>
                        <p>
                          By completing and submitting this web form, I am authorizing {schoolName} staff to contact me
                          via email and/or phone regarding enrollment information.
                        </p>
                      </Row>
                      <Row className="mt-3">
                        <Col md="12 text-center">
                          <Button color="primary" type="submit" size="md">
                            Submit
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Form>
              </Container>
            </Card>
          </Col>
          <Col md="2"></Col>
        </Row>
      ) : (
        <Card className="shadow mt-5 pl-4 pr-4 pb-2 pt-2">
          <Row>
            <Col mod="12" className="text-center">
              Sorry, wait list is currently unavailable for this school.
            </Col>
          </Row>
        </Card>
      )}
    </>
  ) : (
    <SuccessPage firstName={successFirstName} lastName={successLastName} schoolName={schoolName} />
  );
};

export default WaitingListForm;
