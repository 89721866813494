/* eslint-disable max-len */
/* eslint-disable max-lines */
import { Container, Button, Row, Col, Card, CardBody, Modal } from 'reactstrap';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import { useContext, useEffect, useState } from 'react';
import ReportsService from '../../../services/Reports';
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { font as robotoRegular } from '../../../assets/fonts/Roboto-Regular';
import { font as robotoMedium } from '../../../assets/fonts/Roboto-Medium';
import { font as robotoItalic } from '../../../assets/fonts/Roboto-Italic';
import { font as robotoMediumItalic } from '../../../assets/fonts/Roboto-MediumItalic';
import { font as dancingScript } from '../../../assets/fonts/DancingScript';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import Messaging from '../../../services/Messaging';
import { Checkbox, ListItemText, MenuItem, Select, SelectChangeEvent } from '@mui/material';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.vfs['Roboto-Regular.ttf'] = robotoRegular;
pdfMake.vfs['Roboto-Medium.ttf'] = robotoMedium;
pdfMake.vfs['Roboto-Italic.ttf'] = robotoItalic;
pdfMake.vfs['Roboto-MediumItalic.ttf'] = robotoMediumItalic;
pdfMake.vfs['DancingScript.ttf'] = dancingScript;

interface StatusChangeModalProps {
  isOpen: boolean;
  toggleModal: () => void;
}

export function StatusChangeModal({ isOpen, toggleModal }: StatusChangeModalProps) {
  const fileFormat = 'XLSX';
  const [employees, setEmployees] = useState<any>([]);
  const [selectedEmployees, setSelectedEmployees] = useState<string[]>([]);
  const [employeeIds, setEmployeeIds] = useState<string[]>([]);
  const { token, currentSchool }: any = useContext(AuthContext);
  const [isGenerating, setIsGenerating] = useState(false);

  const generateEmployee = async () => {
    const idSchool = currentSchool.value;
    const { data } = await Messaging(token).listSelectStaff(idSchool);

    setEmployees(data);
  };

  useEffect(() => {
    generateEmployee();
  }, []);

  useEffect(() => {
    const ids = selectedEmployees.map((name: string) => {
      const employeesFound = employees.find((employee: any) => name === `${employee.firstName} ${employee.lastName}`);
      return employeesFound.userId;
    });

    setEmployeeIds(ids);
  }, [selectedEmployees]);

  const handleChangeMultiSelect: any = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    if (typeof value === 'string') {
      const newValue = value.split(',').filter((value) => value !== 'All');
      setSelectedEmployees(newValue);
    } else {
      const newValue = value.filter((value) => value !== 'All');
      setSelectedEmployees(newValue);
    }
  };

  const handleExportXlms = async () => {
    try {
      setIsGenerating(true);
      if (fileFormat === 'XLSX') {
        const idSchool = currentSchool.value;
        const query = `employees=${employeeIds.join(',')}`;
        const { data } = await ReportsService(token).userStatus(idSchool, query);
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';

        const ws = XLSX.utils.json_to_sheet(data);
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const response = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(response, 'Status-change' + fileExtension);
        toggleModal();
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <Modal className="modal-dialog-centered" size="lg" isOpen={isOpen} toggle={() => toggleModal()}>
      <Container fluid style={{ minWidth: '500px', paddingTop: 15, paddingBottom: 15 }}>
        <Card>
          <CardBody>
            <div className="pl-lg-0">
              <Row>
                <Col lg="12" className="mb-2">
                  <label className="form-control-label" htmlFor="input-gender">
                    Employees
                  </label>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    placeholder="All"
                    multiple
                    value={selectedEmployees.length > 0 ? selectedEmployees : ['All']}
                    onChange={handleChangeMultiSelect}
                    renderValue={(selected: string[]) => selected.join(', ')}
                    sx={{
                      height: 43,
                      width: '100%',
                      fontSize: 14,
                      color: '#8898aa',
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 280,
                          width: 250,
                        },
                      },
                    }}
                  >
                    {employees?.map((employee: any, index: any) => (
                      <MenuItem value={`${employee.firstName} ${employee.lastName}`} key={`employee-${index}`}>
                        <Checkbox
                          checked={selectedEmployees.indexOf(`${employee.firstName} ${employee.lastName}`) > -1}
                        />
                        <ListItemText primary={`${employee.firstName} ${employee.lastName}`} />
                      </MenuItem>
                    ))}
                  </Select>
                </Col>
              </Row>

              <Row>
                <Col md="12" className="d-flex justify-content-center mt-5">
                  <Button
                    color="primary"
                    type="submit"
                    href="#"
                    onClick={async () => {
                      if (fileFormat === 'XLSX') handleExportXlms();
                    }}
                    size="lg"
                    style={{ minWidth: '200px' }}
                  >
                    {isGenerating ? <FontAwesomeIcon icon={faSpinner} spin={isGenerating} /> : 'Download'}
                  </Button>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
      </Container>
    </Modal>
  );
}
