/* eslint-disable max-lines */
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import { useContext, useState, useEffect } from 'react';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import Pto from '../../../services/Pto';
import { createPagedMeta } from '../../../entities/PagedMeta';
import { createOptionsPage } from '../../../entities/OptionsPaged';
import DataTable from '../../../components/Core/DataTable';
import { toast } from 'react-toastify';
import moment from 'moment';
import EmployeeService from '../../../services/Employee';
import dataTableCollumns from '../../datatableCollumns/UserBalanceDetailFormHistoryCollumns';

const UserBalanceDetailForm = ({ data, editMode = false, onSave }: { data: any; editMode?: boolean; onSave: any }) => {
  const [activeTab, setActiveTab] = useState('overview');
  const [ptoPolices, setPtoPolices] = useState([] as any[]);
  const { token, currentSchool }: any = useContext(AuthContext);
  const [inEditMode, setInEditMode] = useState(editMode);
  const [currentBalance, setCurrentBalance] = useState('');
  const [ptoPolicyId, setPtoPolicyId] = useState('');
  const [accrualRateDaysPerYear, setAccrualRateDaysPerYear] = useState('');
  const [reason, setReason] = useState('');

  const [historyData, setHistoryData] = useState([]);
  const [historyMeta, setHistoryMeta] = useState(createPagedMeta());
  const [filteredOptions, setFilteredOptions] = useState({
    filters: [] as any[],
    options: createOptionsPage(historyMeta),
  });
  const [loading, setLoading] = useState(false);

  const customStyles = {
    navLink: {
      minHeight: '42px',
      paddingTop: '8px',
      cursor: 'pointer',
      border: '0',
    },
    navLinkClickedALter: {
      minHeight: '42px',
      paddingTop: '8px',
      cursor: 'pointer',
      border: '0',
      borderBottom: '2px solid #FF7B00',
    },
    row: {
      paddingBottom: '30px',
      paddingTop: '15px',
      height: '43px',
    },
  };

  const listPtoPolices = async () => {
    try {
      const result = await Pto(token).ptoPolicyList();
      setPtoPolices(result.data || []);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No data Found!');
    }
  };

  const listTimeOffRequestListApprovedForUser = async (userId: string) => {
    setLoading(true);
    try {
      filteredOptions.filters = [{ field: 'userId', operation: 'hash', value: userId }];
      filteredOptions.options.sortBy = ['startDate'];
      filteredOptions.options.sortDesc = [true];
      const result = await Pto(token).timeOffRequestList(currentSchool.value, filteredOptions);

      let balanceAcumulate =
        (result.meta.startBalance + (result.data[0].status !== 'REPPROVE' ? result.data[0].hours : 0)) | 0;

      balanceAcumulate = result.meta.startBalance;
      // balanceAcumulate = 0;
      setHistoryData(
        result.data

          .map((item: any) => {
            if (item.status !== 'REPPROVE') {
              balanceAcumulate += item.hours;
            }
            return { ...item, ...{ balance: balanceAcumulate } };
          })
          .reverse(),
      );
      // setHistoryData(result.data);
      setHistoryMeta(result.meta);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No data Found!');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setInEditMode(false);
    setLoading(true);
    try {
      const body = { currentBalance, ptoPolicyId, accrualRateDaysPerYear, reason };
      await EmployeeService(token).updatePtoSpec(data.userId, body);
      onSave();
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No data Found!');
      setInEditMode(true);
    } finally {
      setLoading(false);
      // onSave();
    }
  };

  const getDiffInFormat = (compareDate: string) => {
    const actualDate = moment().utc();
    const compareWithDate = moment(compareDate).utc();

    const years = actualDate.diff(compareWithDate, 'year');
    compareWithDate.add(years, 'years');

    const months = actualDate.diff(compareWithDate, 'months');
    compareWithDate.add(months, 'months');

    return years + ' years ' + months + ' months';
  };

  const handlePerRowsChange = async (perPage: number) => {
    const newOptions = {
      ...historyMeta,
      perPage,
    };
    const newFilteredOptions = {
      filters: filteredOptions.filters,
      options: createOptionsPage(newOptions),
    };
    setFilteredOptions(newFilteredOptions);
  };

  const handlePageChange = async (page: number) => {
    const newOptions = {
      ...historyMeta,
      currentPage: page,
    };
    const newFilteredOptions = {
      filters: filteredOptions.filters,
      options: createOptionsPage(newOptions),
    };
    setFilteredOptions(newFilteredOptions);
  };

  useEffect(() => {
    listPtoPolices();
    setCurrentBalance(data.ptoPolicy.currentBalance);
    setPtoPolicyId(data.ptoPolicyId);
    setAccrualRateDaysPerYear(data.ptoPolicy.accrualRateDaysPerYear);
    setInEditMode(false);
  }, []);

  useEffect(() => {
    setInEditMode(editMode);
  }, [editMode]);

  useEffect(() => {
    if (activeTab === 'history') {
      setInEditMode(false);
      listTimeOffRequestListApprovedForUser(data.userId);
    }
  }, [activeTab, filteredOptions]);

  return (
    <Form
      onSubmit={(e) => {
        handleSubmit(e);
      }}
    >
      <Row>
        <Col>
          <Nav className="border-0" tabs>
            {['overview', 'history'].map((item, i) => {
              return (
                <>
                  <NavItem className="cursor-pointer" key={`navItem-${i}`}>
                    <NavLink
                      className="cursor-pointer font-weight-bold"
                      style={activeTab === item ? customStyles.navLinkClickedALter : customStyles.navLink}
                      active={activeTab === item}
                      onClick={() => setActiveTab(item)}
                    >
                      {item.charAt(0).toUpperCase() + item.slice(1)}
                    </NavLink>
                  </NavItem>
                </>
              );
            })}
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="overview">
              <Row style={customStyles.row}>
                <Col xs="6" md="6" className="mt-2">
                  <b>Current Balance</b>
                </Col>
                <Col xs="6" md="6" className="mt-2">
                  {!inEditMode && <>{currentBalance || data.ptoPolicy.currentBalance}</>}
                  {inEditMode && (
                    <>
                      <FormGroup className="m-0">
                        <InputGroup>
                          <Input
                            type="number"
                            thousandsGroupStyle="thousand"
                            suffix="%"
                            decimalSeparator="."
                            displayType="input"
                            thousandSeparator={true}
                            allowNegative={false}
                            defaultValue={data.ptoPolicy.currentBalance}
                            readOnly={false}
                            onChange={(e: any) => setCurrentBalance(e.target.value)}
                            required
                          />

                          <InputGroupAddon addonType="append">Hours</InputGroupAddon>
                        </InputGroup>
                      </FormGroup>
                    </>
                  )}
                </Col>
              </Row>
              <Row style={customStyles.row}>
                <Col xs="6" md="6" className="mt-2">
                  <b>Scheduled Hours</b>
                </Col>
                <Col xs="6" md="6" className="mt-2">
                  {data.ptoPolicy.scheduleHours}
                </Col>
              </Row>
              <Row style={customStyles.row}>
                <Col xs="6" md="6" className="mt-2">
                  <b>Available Balance</b>
                </Col>
                <Col xs="6" md="6" className="mt-2">
                  {((currentBalance || data.ptoPolicy.currentBalance) - data.ptoPolicy.scheduleHours).toFixed(2)}
                </Col>
              </Row>
              {inEditMode && (
                <Row style={(customStyles.row, { height: '70px' })}>
                  <Col xs="6" md="6" className="mt-2">
                    <b>Reason</b>
                  </Col>
                  <Col xs="6" md="6" className="mt-2">
                    <Input type="textarea" onChange={(e) => setReason(e.target.value)} required={true} />
                  </Col>
                </Row>
              )}
              <hr />
              <Row style={customStyles.row}>
                <Col xs="6" md="6" className="mt-2">
                  <b>Start Date</b>
                </Col>
                <Col xs="6" md="6" className="mt-2">
                  {moment(data.startDate).utc().format('MMMM D, YYYY')} ({getDiffInFormat(data.startDate)})
                </Col>
              </Row>
              <Row style={customStyles.row}>
                <Col xs="6" md="6" className="mt-2">
                  <b>Policy Name</b>
                </Col>
                <Col xs="6" md="6" className="mt-2">
                  {!inEditMode && data.ptoPolicy.name}
                  {inEditMode && (
                    <Input
                      type="select"
                      defaultValue={data.ptoPolicyId}
                      onChange={(e) => setPtoPolicyId(e.target.value)}
                      required
                    >
                      {ptoPolices.map((value) => (
                        <option key={value.id} value={value.id}>
                          {value.name}
                        </option>
                      ))}
                    </Input>
                  )}
                </Col>
              </Row>
              <Row style={customStyles.row}>
                <Col xs="6" md="6" className="mt-2">
                  <b>Base Accrual Rate</b>
                </Col>
                <Col xs="6" md="6" className="mt-2">
                  {!inEditMode && (
                    <>
                      {accrualRateDaysPerYear || data.ptoPolicy.accrualRateDaysPerYear} {data.ptoPolicy.accrualMethod}
                    </>
                  )}
                  {inEditMode && (
                    <>
                      <FormGroup className="m-0">
                        <InputGroup>
                          <Input
                            type="number"
                            thousandsGroupStyle="thousand"
                            suffix="%"
                            decimalSeparator="."
                            displayType="input"
                            thousandSeparator={true}
                            allowNegative={false}
                            defaultValue={data.ptoPolicy.accrualRateDaysPerYear}
                            onChange={(e: any) => setAccrualRateDaysPerYear(e.target.value)}
                            required
                          />

                          <InputGroupAddon addonType="append">days per year</InputGroupAddon>
                        </InputGroup>
                      </FormGroup>
                    </>
                  )}
                </Col>
              </Row>
              <Row style={customStyles.row}>
                <Col xs="6" md="6" className="mt-2">
                  <b>Accrual Frequency</b>
                </Col>
                <Col xs="6" md="6" className="mt-2">
                  {data.ptoPolicy.accrualFrequency}
                </Col>
              </Row>
              <Row style={customStyles.row}>
                <Col xs="6" md="6" className="mt-2">
                  <b>Accrual Timing</b>
                </Col>
                <Col xs="6" md="6" className="mt-2">
                  {data.ptoPolicy.timingOfAccrual}
                </Col>
              </Row>
              <Row style={customStyles.row}>
                <Col xs="6" md="6" className="mt-2">
                  <b>Accrued Until</b>
                </Col>
                <Col xs="6" md="6" className="mt-2">
                  {moment().endOf('year').format('MMMM 1, YYYY')}
                </Col>
              </Row>
              <hr />
              <Row>
                <Col className="text-right">
                  {!inEditMode && (
                    <>
                      <Button className="btn-warning" onClick={() => setInEditMode(true)}>
                        <i className="fas fa-pen" />
                      </Button>
                    </>
                  )}
                  {inEditMode && (
                    <>
                      <Button onClick={() => setInEditMode(false)}>Cancel</Button>
                      <Button className="btn-warning" type="submit">
                        Save
                      </Button>
                    </>
                  )}
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="history">
              <Row>
                <Col>
                  <DataTable
                    columns={dataTableCollumns()}
                    data={historyData}
                    header={false}
                    totalRows={historyMeta.total}
                    loading={loading}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                  />
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </Form>
  );
};
export default UserBalanceDetailForm;
