import { useState } from 'react';
import { Modal, Row, Col, Button } from 'reactstrap';

type ClassCamerasProps = {
  isOpen: boolean;
  cameras: string[];
  toggleModal: (e?: any) => void;
};

const ClassCameras = ({ isOpen, cameras, toggleModal }: ClassCamerasProps) => {
  const [currentCamera, setCurrentCamera] = useState(cameras[0]);

  return (
    <>
      <Modal className="modal-dialog-centered" isOpen={isOpen} toggle={(e: any) => toggleModal(e)}>
        <div className="modal-header pb-1">
          <h5 className="modal-title">Cameras</h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={(e) => toggleModal(e)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body pt-0">
          <Row>
            <Col>
              {cameras.map((c, i) => (
                <Button key={`camera-${i}`} onClick={() => setCurrentCamera(c)}>
                  Camera {i + 1}
                </Button>
              ))}
            </Col>
            {currentCamera && (
              <Col className="mt-2">
                <img data-src={currentCamera} src={currentCamera} style={{ width: '455px' }} />
              </Col>
            )}
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default ClassCameras;
