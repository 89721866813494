export const validStatus = {
  ACTIVE: { value: 'ACTIVE', color: 'success' },
  BLOCKED: { value: 'BLOCKED', color: 'danger' },
  FINISHED: { value: 'FINISHED', color: 'warning' },
  INACTIVE: { value: 'INACTIVE', color: 'danger' },
  DELETED: { value: 'DELETED', color: 'danger' },
};

export enum statusEnum {
  ACTIVE = 'ACTIVE',
  BLOCKED = 'BLOCKED',
  FINISHED = 'FINISHED',
}

export interface PromoCodesInterface {
  id?: string;
  code?: string;
  regFee?: number;
  tuitionFee?: number;
  qty?: number;
  used?: number;
  status?: statusEnum;
  createdAt?: Date;
  updatedAt?: Date;
}

export default PromoCodesInterface;
