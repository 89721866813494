import { Card, CardBody, Row, Col } from 'reactstrap';
import { useContext, useEffect, useState } from 'react';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import SchoolCreate from '../School/SchoolCreate';
import isFranchiseeManagment from '../../../helpers/isFranchiseeManagment';
import history from '../../../history';
import { createPagedMeta } from '../../../entities/PagedMeta';
import { createOptionsPage } from '../../../entities/OptionsPaged';
import SchoolService from '../../../services/School';
import OpacityLoaderSpinner from '../../../components/Core/OpacityLoaderSpinner';

const StartConfiguration = () => {
  const { token, user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const verifyCanBeAccessed = async () => {
    setLoading(true);
    if (!isFranchiseeManagment(user)) {
      history.push('/home');
    }
    const filteredOptions = {
      filters: [] as any[],
      options: createOptionsPage(createPagedMeta()),
    };
    const filterFranchiseeId = {
      field: 'franchiseeId',
      operation: 'hash',
      value: user.franchiseeId,
    };
    filteredOptions.filters = [filterFranchiseeId];
    const result = await SchoolService(token || '').list(filteredOptions);
    if (result.data.length !== 0) {
      history.push('/home');
    }
    setLoading(false);
  };

  useEffect(() => {
    verifyCanBeAccessed();
  }, []);
  return (
    <>
      {loading ? <OpacityLoaderSpinner /> : <></>}
      {!loading && (
        <Col xs="12">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-4 py-lg-4">
              <Row>
                <Col lg="12">
                  <p>
                    Welcome to {process.env.REACT_APP_FRONTEND_NAME}. Before getting started, we need to configure your
                    first school.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <SchoolCreate withCommonHeader={false} />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      )}
    </>
  );
};

export default StartConfiguration;
