/* eslint-disable max-lines */
import { Modal, Table, Button, Row, Col, FormGroup, Form, Input } from 'reactstrap';
import { useContext, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import AttendancesService from '../../../services/Attendances';
import PayCodesService from '../../../services/PayCodes';
import PayCodesModal from './PayCodesModal';
import isAdmin from '../../../helpers/isAdmin';
import isDirector from '../../../helpers/isDirector';
import TimePicker from '../../../components/Core/TimePicker';
import { isMobile } from 'react-device-detect';

type TimeCardEditorModalProps = {
  userId: string;
  dateSelected: string;
  isOpen: boolean;
  toggleModal: (e?: any) => void;
};

const TimeCardEditorModal = ({ userId, dateSelected, isOpen, toggleModal }: TimeCardEditorModalProps) => {
  const { token, user, currentSchool }: any = useContext(AuthContext);
  const hasPermission = isAdmin(user) || isDirector(user);
  const [loading, setLoading] = useState(false);
  const [payCodes, setPayCodes] = useState([] as any[]);
  const [attendancesList, setAttendancesList] = useState([] as any[]);
  const [dataList, setDataList] = useState([] as any[]);
  const [isTypesOpen, setIsTypesOpen] = useState(false);
  const [payCode, setPayCode] = useState('');
  const [description, setDescription] = useState('');
  const [clockIn, setClockIn] = useState(undefined as undefined | string);
  const [clockOut, setClockOut] = useState(undefined as undefined | string);
  const [startBreak, setStartBreak] = useState(undefined as undefined | string);
  const [endBreak, setEndBreak] = useState(undefined as undefined | string);
  const [isEdit, setIsEdit] = useState(false);
  const [currentClockInId, setCurrentClockInId] = useState('');
  const [currentClockOutId, setCurrentClockOutId] = useState('');
  const [currentStartBreakId, setCurrentStartBreakId] = useState('');
  const [currentEndBreakId, setCurrentEndBreakId] = useState('');

  useEffect(() => {
    if (isOpen) {
      listTypes();
      listData();
    }
  }, [isOpen]);

  const listTypes = async () => {
    try {
      const result = await PayCodesService(token).list();
      setPayCodes(result.data || []);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No Types Found!');
    }
  };

  const listData = async () => {
    try {
      const query = `startDateTime=${dateSelected}T00:00:00.000Z&finalDateTime=${dateSelected}T23:59:59.000Z`;
      const result = await AttendancesService(token).listByUser(userId, query);
      const data = result?.data || [];
      setDataList(data);
      const clockIns = data.filter((d: any) => d.attendanceType === 'CLOCKIN');
      const clockOuts = data.filter((d: any) => d.attendanceType === 'CLOCKOUT');
      const startBreaks = data.filter((d: any) => d.attendanceType === 'STARTBREAK');
      const endBreaks = data.filter((d: any) => d.attendanceType === 'ENDBREAK');
      const newList = [];
      for (let i = 0; i <= clockIns?.length; i++) {
        if (clockIns?.[i]) {
          newList.push({
            clockInId: clockIns?.[i]?.id || '',
            startBreakId: startBreaks?.[i]?.id || '',
            endBreakId: endBreaks?.[i]?.id || '',
            clockOutId: clockOuts?.[i]?.id || '',
            clockIn: clockIns?.[i]?.createdAt ? moment(clockIns[i].createdAt).format('hh:mm A') : '',
            startBreak: startBreaks?.[i]?.createdAt ? moment(startBreaks[i].createdAt).format('hh:mm A') : '-',
            endBreak: endBreaks?.[i]?.createdAt ? moment(endBreaks[i].createdAt).format('hh:mm A') : '-',
            clockOut: clockOuts?.[i]?.createdAt ? moment(clockOuts[i].createdAt).format('hh:mm A') : '',
            payCode: clockIns?.[i]?.payCode || '',
            description: clockIns?.[i]?.description || '',
          });
        }
      }
      setAttendancesList(newList);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No Data Found!');
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;
    const clockInMoment = moment(`${dateSelected} ${moment(clockIn).format('HH:mm:ss')}`);
    const startBreakMoment = moment(`${dateSelected} ${moment(startBreak).format('HH:mm:ss')}`);
    const endBreakMoment = moment(`${dateSelected} ${moment(endBreak).format('HH:mm:ss')}`);
    const clockOutMoment = moment(`${dateSelected} ${moment(clockOut).format('HH:mm:ss')}`);
    if (clockInMoment.isValid() && clockOutMoment.isValid() && clockInMoment > clockOutMoment) {
      toast.warn(`The clock in time cannot be greater than the clock out time.`);
      return;
    }
    if (startBreakMoment.isValid() && clockOutMoment.isValid() && startBreakMoment > clockOutMoment) {
      toast.warn(`The start break time cannot be greater than the clock out time.`);
      return;
    }
    if (endBreakMoment.isValid() && clockOutMoment.isValid() && endBreakMoment > clockOutMoment) {
      toast.warn(`The end break time cannot be greater than the clock out time.`);
      return;
    }
    setLoading(true);
    try {
      const body = {
        roomId: currentSchool.value || '',
        roomType: 'SCHOOL',
        payCode,
        description,
        clockIn: clockInMoment.isValid() ? `${dateSelected} ${clockInMoment.format('HH:mm:ss')}` : '',
        startBreak: startBreakMoment.isValid() ? `${dateSelected} ${startBreakMoment.format('HH:mm:ss')}` : '',
        endBreak: endBreakMoment.isValid() ? `${dateSelected} ${endBreakMoment.format('HH:mm:ss')}` : '',
        clockOut: clockOutMoment.isValid() ? `${dateSelected} ${clockOutMoment.format('HH:mm:ss')}` : '',
      };

      isEdit
        ? await AttendancesService(token).updateByUser(userId, {
            ...body,
            clockInId: currentClockInId,
            startBreakId: currentStartBreakId,
            endBreakId: currentEndBreakId,
            clockOutId: currentClockOutId,
          })
        : await AttendancesService(token).createByUser(userId, body);

      toast.success(`Saved successfully!`);
      listData();
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Error on save!');
      toast.error(message);
    } finally {
      setLoading(false);
      handleCancelEdit();
    }
  };

  const handleDelete = async (
    e: React.FormEvent,
    clockInId: string,
    startBreakId: string,
    endBreakId: string,
    clockOutId: string,
  ) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);

    try {
      if (clockInId) {
        await AttendancesService(token).delete(clockInId);
      }
      if (startBreakId) {
        await AttendancesService(token).delete(startBreakId);
      }
      if (endBreakId) {
        await AttendancesService(token).delete(endBreakId);
      }
      if (clockOutId) {
        await AttendancesService(token).delete(clockOutId);
      }
      toast.success(`Deleted successfully!`);
      listData();
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Delete error!');
      toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (
    e: React.FormEvent,
    clockInId: string,
    startBreakId: string,
    endBreakId: string,
    clockOutId: string,
    payCodeId: string,
    descriptionId: string,
  ) => {
    e.preventDefault();
    setCurrentClockInId(clockInId || '');
    setCurrentStartBreakId(startBreakId || '');
    setCurrentEndBreakId(endBreakId || '');
    setCurrentClockOutId(clockOutId || '');
    const isDataEdit = !!(clockInId || startBreakId || endBreakId || clockOutId);
    setIsEdit(isDataEdit);
    if (isDataEdit) {
      let timeClockIn = dataList.find((d: any) => d.id === clockInId)?.createdAt || undefined;
      if (timeClockIn) {
        timeClockIn = moment.utc(timeClockIn).format('YYYY-MM-DD HH:mm:ss');
      }
      setClockIn(timeClockIn);
      let timeStartBreak = dataList.find((d: any) => d.id === startBreakId)?.createdAt || undefined;
      if (timeStartBreak) {
        timeStartBreak = moment.utc(timeStartBreak).format('YYYY-MM-DD HH:mm:ss');
      }
      setStartBreak(timeStartBreak);
      let timeEndBreak = dataList.find((d: any) => d.id === endBreakId)?.createdAt || undefined;
      if (timeEndBreak) {
        timeEndBreak = moment.utc(timeEndBreak).format('YYYY-MM-DD HH:mm:ss');
      }
      setEndBreak(timeEndBreak);
      let timeClockOut = dataList.find((d: any) => d.id === clockOutId)?.createdAt || undefined;
      if (timeClockOut) {
        timeClockOut = moment.utc(timeClockOut).format('YYYY-MM-DD HH:mm:ss');
      }
      setClockOut(timeClockOut);
      setPayCode(payCodeId || '');
      setDescription(descriptionId || '');
    }
  };

  const handleCancelEdit = () => {
    setCurrentClockInId('');
    setCurrentStartBreakId('');
    setCurrentEndBreakId('');
    setCurrentClockOutId('');
    setIsEdit(false);
    setClockIn(undefined);
    setClockOut(undefined);
    setPayCode('');
    setDescription('');
  };

  const toggleModalTypes = (e: any) => {
    e?.preventDefault();
    setIsTypesOpen(!isTypesOpen);
    listTypes();
  };

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        size="lg"
        isOpen={isOpen}
        style={{ maxWidth: '90%' }}
        toggle={(e: any) => {
          handleCancelEdit();
          toggleModal(e);
        }}
      >
        <div className="modal-header pb-1">
          <h5 className="modal-title">Time Card Editor - {moment(dateSelected).format('MM/DD/yyyy')}</h5>

          <div className="text-right mb-3">
            {hasPermission && (
              <i className="fas fa-cog fa-lg" style={{ cursor: 'pointer' }} onClick={toggleModalTypes}></i>
            )}

            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={(e) => {
                handleCancelEdit();
                toggleModal(e);
              }}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
        </div>
        <div className="modal-body pt-0">
          <Row>
            <Col>{}</Col>
          </Row>
          <Row>
            <Col>
              <Form onSubmit={handleSubmit}>
                <Row>
                  <div className="col-lg col-md">
                    <FormGroup>
                      <label className="form-control-label">Clock In</label>
                      <TimePicker
                        defaultValue={clockIn}
                        value={clockIn}
                        onChangeTime={(clockIn: string) => {
                          setClockIn(clockIn);
                        }}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-lg col-md">
                    <FormGroup>
                      <label className="form-control-label">Start Break</label>
                      <TimePicker
                        defaultValue={startBreak}
                        value={startBreak}
                        onChangeTime={(time: string) => {
                          setStartBreak(time);
                        }}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-lg col-md">
                    <FormGroup>
                      <label className="form-control-label">End Break</label>
                      <TimePicker
                        defaultValue={endBreak}
                        value={endBreak}
                        onChangeTime={(time: string) => {
                          setEndBreak(time);
                        }}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-lg col-md">
                    <FormGroup>
                      <label className="form-control-label">Clock Out</label>
                      <TimePicker
                        defaultValue={clockOut}
                        value={clockOut}
                        onChangeTime={(time: string) => {
                          setClockOut(time);
                        }}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-lg col-md-12">
                    <FormGroup>
                      <label className="form-control-label">Pay Code</label>
                      <Input type="select" value={payCode} onChange={(e) => setPayCode(e.target.value)}>
                        <option value=""></option>
                        {payCodes.map((a: any, index: number) => {
                          return (
                            <option value={a.name} key={`codes-${index}`}>
                              {a.name}
                            </option>
                          );
                        })}
                      </Input>
                    </FormGroup>
                  </div>
                </Row>
                <Row>
                  <Col md="12" className="mb-3 text-right">
                    <Button color="primary" type="submit" size="sm">
                      {isEdit ? 'Edit' : 'Add'}
                    </Button>
                    {isEdit && (
                      <Button color="danger" type="button" size="sm" onClick={handleCancelEdit}>
                        Cancel
                      </Button>
                    )}
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          {isMobile && (
            <Table className="align-items-center table-flush table-timecard-mobile" responsive>
              <tbody>
                {attendancesList.map((s: any, index: number) => (
                  <tr key={`attendances-modal-${index}`}>
                    <td className="pr-2">
                      <p className="m-0">
                        <b>Clock IN</b>{' '}
                        <div className="float-right text-center" style={{ width: '60px' }}>
                          {s.clockIn}
                        </div>
                      </p>
                      <p className="m-0" style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
                        <b>Start Break</b>
                        <div className="float-right text-center" style={{ width: '60px' }}>
                          {s.startBreak}
                        </div>
                      </p>
                      <p className="m-0">
                        <b>End Break</b>
                        <div className="float-right text-center" style={{ width: '60px' }}>
                          {s.endBreak}
                        </div>
                      </p>
                      <p className="m-0" style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
                        <b>Clock OUT</b>
                        <div className="float-right text-center" style={{ width: '60px' }}>
                          {s.clockOut}
                        </div>
                      </p>
                      <p className="m-0">
                        <b>Pay Code</b>
                        <div className="float-right text-center" style={{ width: '60px' }}>
                          {s.payCode}
                        </div>
                      </p>
                    </td>
                    <td
                      align="center"
                      style={{ verticalAlign: 'top', paddingTop: '30px', width: '90px' }}
                      className="pl-2"
                    >
                      {!isEdit && (
                        <>
                          <button
                            className="btn btn-primary btn-md m-0 w-100 d-block mb-2"
                            onClick={(e) =>
                              handleEdit(
                                e,
                                s.clockInId,
                                s.startBreakId,
                                s.endBreakId,
                                s.clockOutId,
                                s.payCode,
                                s.description,
                              )
                            }
                          >
                            <i className="far fa-edit" style={{ cursor: 'pointer' }} title="Edit" />
                          </button>
                          <button
                            className="btn btn-danger btn-md m-0 w-100 d-block"
                            onClick={(e) => handleDelete(e, s.clockInId, s.startBreakId, s.endBreakId, s.clockOutId)}
                          >
                            <i className="far fa-trash-alt" style={{ cursor: 'pointer' }} title="Delete" />
                          </button>
                        </>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
          {!isMobile && (
            <Table className="align-items-center table-flush" responsive>
              <tbody>
                <tr>
                  <th>Clock IN</th>
                  <th>Start Break</th>
                  <th>End Break</th>
                  <th>Clock OUT</th>
                  <th>Pay Code</th>
                  {/* <th>Description</th> */}
                  <th>Action</th>
                </tr>
                {attendancesList?.length === 0 && (
                  <tr>
                    <td colSpan={4} className="text-center">
                      Data not found
                    </td>
                  </tr>
                )}
                {attendancesList.map((s: any, index: number) => (
                  <tr key={`attendances-modal-${index}`}>
                    <td>{s.clockIn}</td>
                    <td>{s.startBreak}</td>
                    <td>{s.endBreak}</td>
                    <td>{s.clockOut}</td>
                    <td>{s.payCode}</td>
                    {/* <td>{s.description}</td> */}
                    <td>
                      {!isEdit && (
                        <>
                          <i
                            className="far fa-edit text-primary mr-2"
                            style={{ cursor: 'pointer' }}
                            title="Edit"
                            onClick={(e) =>
                              handleEdit(
                                e,
                                s.clockInId,
                                s.startBreakId,
                                s.endBreakId,
                                s.clockOutId,
                                s.payCode,
                                s.description,
                              )
                            }
                          ></i>
                          <i
                            className="far fa-trash-alt text-danger"
                            style={{ cursor: 'pointer' }}
                            title="Delete"
                            onClick={(e) => handleDelete(e, s.clockInId, s.startBreakId, s.endBreakId, s.clockOutId)}
                          ></i>
                        </>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </div>
      </Modal>
      <PayCodesModal isOpen={isTypesOpen} toggleModal={(e) => toggleModalTypes(e)} />
    </>
  );
};

export default TimeCardEditorModal;
