import { useState } from 'react';
import { Card, CardBody, CardHeader, Container, Nav, NavItem, TabContent, TabPane, NavLink } from 'reactstrap';
import CommonHeader from '../../../components/Headers/CommonHeader';
import CreditCardTransaction from './CreditCardTransaction';
import ACHTransaction from './ACHTransaction';
import SaveCreditCard from './SaveCreditCard';
import SaveACH from './SaveACH';
import ChargeTransaction from './ChargeTransaction';

const TestTransaction = () => {
  const [activeTab, setActiveTab] = useState('1');

  const customStyles = {
    navLink: {
      minHeight: '42px',
      paddingTop: '8px',
      cursor: 'pointer',
    },
  };

  return (
    <>
      <CommonHeader />
      <Container className="mt--8" fluid>
        <Card className="bg-secondary shadow">
          <CardHeader className="bg-white border-0" style={{ maxHeight: '62px' }}>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={`${activeTab === '1' ? 'active' : ''}`}
                  onClick={() => setActiveTab('1')}
                  style={customStyles.navLink}
                >
                  Direct Credit Card
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={`${activeTab === '2' ? 'active' : ''}`}
                  onClick={() => setActiveTab('2')}
                  style={customStyles.navLink}
                >
                  ACH - Stripe Redirect
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={`${activeTab === '3' ? 'active' : ''}`}
                  onClick={() => setActiveTab('3')}
                  style={customStyles.navLink}
                >
                  Save Credit Card
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={`${activeTab === '4' ? 'active' : ''}`}
                  onClick={() => setActiveTab('4')}
                  style={customStyles.navLink}
                >
                  Save ACH Data
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={`${activeTab === '5' ? 'active' : ''}`}
                  onClick={() => setActiveTab('5')}
                  style={customStyles.navLink}
                >
                  Charge Payment
                </NavLink>
              </NavItem>
            </Nav>
          </CardHeader>
          <CardBody>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <CreditCardTransaction />
              </TabPane>
              <TabPane tabId="2">
                <ACHTransaction />
              </TabPane>
              <TabPane tabId="3">
                <SaveCreditCard />
              </TabPane>
              <TabPane tabId="4">
                <SaveACH />
              </TabPane>
              <TabPane tabId="5">
                <ChargeTransaction />
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </Container>
    </>
  );
};

export default TestTransaction;
