import FilteredOptionsInterface, { makeQueryParamsForRequest } from '../entities/FilteredOptions';
import http from '../helpers/http';

const Invoices = (token: string) => {
  const headers = {
    'x-access-token': token,
  };

  return {
    listByKids: async (kidsId: string, filteredOptions?: FilteredOptionsInterface) => {
      const params = makeQueryParamsForRequest(filteredOptions);
      const result = await http.get(`/invoices/${kidsId}/list`, {
        headers,
        params,
      });
      return result.data;
    },
    getPDF: async (invoiceId: string) => {
      const headersPdf = { ...headers, 'Content-Type': 'multipart/form-data' };
      const result = await http.get(`/invoices/${invoiceId}/pdf`, {
        headers: headersPdf,
        responseType: 'arraybuffer',
      });
      return result.data;
    },
  };
};

export default Invoices;
