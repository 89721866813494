import http from '../helpers/http';

const WriteUp = (token: string) => {
  const headers = {
    'x-access-token': token,
  };

  return {
    show: async (id: string) => {
      const result = await http.get(`/write-up/${id}`, {
        headers,
      });
      return result.data;
    },
    create: async (body: any) => {
      const result = await http.post(`/write-up`, body, { headers });
      return result.data;
    },
    listByUser: async (userId: string, query?: string) => {
      const result = await http.get(`/write-up/by-employee/${userId}${query ? `?${query}` : ''}`, {
        headers,
      });
      return result.data;
    },
    list: async (schoolId: string, query?: string) => {
      const result = await http.get(`/write-up/id-school/${schoolId}${query ? `?${query}` : ''}`, {
        headers,
      });
      return result.data;
    },
  };
};

export default WriteUp;
