import { useContext, useEffect, useState } from 'react';
import { Button, Col, FormGroup, Input, InputGroup, InputGroupText, Modal, Row } from 'reactstrap';
import NumberFormat from 'react-number-format';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import KidsService from '../../../services/Kids';
import { toast } from 'react-toastify';

type BillingBulkPriceModalProps = {
  kidsIds: { id: string; parentId: string }[];
  isOpen: boolean;
  updatePriceSheet: boolean;
  classId: string;
  classTypeId: string;
  toggleModal: (e?: any) => void;
  onChange: (e?: any, amount?: number) => void;
};

const BillingBulkPriceModal = ({
  kidsIds,
  isOpen,
  updatePriceSheet,
  classId,
  classTypeId,
  toggleModal,
  onChange,
}: BillingBulkPriceModalProps) => {
  const { token }: any = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState(0);
  const [updateBy, setUpdateBy] = useState(updatePriceSheet ? 'total' : 'percent');

  const handleRequest = async (e: any) => {
    if (loading) return;
    e.preventDefault();
    setLoading(true);
    try {
      for (const kidsId of kidsIds) {
        await KidsService(token).updateBillingAmount(kidsId?.id, {
          value: amount,
          updateBy,
          updatePriceSheet,
          classId,
          classTypeId,
        });
      }
      onChargeModalChange(e);
      setLoading(false);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'Request error');
      setLoading(false);
    }
  };

  const toggleChargeModal = (e: any) => {
    e.preventDefault();
    toggleModal(e);
    setAmount(0);
  };

  const onChargeModalChange = (e: any) => {
    onChange(e);
    e.preventDefault();
    setAmount(0);
  };

  useEffect(() => {
    setUpdateBy(updatePriceSheet ? 'total' : 'percent');
  }, [updatePriceSheet, isOpen]);

  return (
    <>
      <Modal className="modal-dialog-centered" size="lg" isOpen={isOpen} toggle={(e: any) => toggleChargeModal(e)}>
        <div className="modal-header pb-1">
          <h5 className="modal-title">Price Change</h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={(e) => toggleChargeModal(e)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body pt-0">
          <Row className="mt-2">
            <Col lg="8">
              <FormGroup>
                <label className="form-control-label">Update by</label>
                <Input type="select" onChange={(e) => setUpdateBy(e.target.value)} value={updateBy} required>
                  {!updatePriceSheet ? (
                    <>
                      <option value="percent">Percentage %</option>
                      <option value="amount">Amount $</option>
                    </>
                  ) : (
                    <></>
                  )}
                  <option value="total">Total $</option>
                </Input>
              </FormGroup>
            </Col>
            <Col xl="4">
              <FormGroup>
                <label className="form-control-label">Amount</label>
                <InputGroup className="input-group-alternative">
                  {['amount', 'total'].includes(updateBy) ? <InputGroupText>$</InputGroupText> : <></>}
                  <Input
                    type="text"
                    thousandsGroupStyle="thousand"
                    decimalSeparator="."
                    displayType="input"
                    thousandSeparator={true}
                    allowNegative={false}
                    decimalScale={2}
                    tag={NumberFormat}
                    value={amount}
                    onValueChange={(e: any) => setAmount(e.floatValue)}
                  />
                  {updateBy === 'percent' ? <InputGroupText>%</InputGroupText> : <></>}
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col md="6 text-center">
              <Button color="secondary" size="md" style={{ minWidth: '100px' }} onClick={(e) => toggleChargeModal(e)}>
                Cancel
              </Button>
            </Col>
            <Col md="6 text-center">
              <Button color="success" size="md" style={{ minWidth: '100px' }} onClick={(e) => handleRequest(e)} lo>
                Save
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default BillingBulkPriceModal;
