/* eslint-disable max-lines */
import { Button, Form, Input, Row, Col } from 'reactstrap';
import { toast } from 'react-toastify';
import { useContext, useState, useEffect } from 'react';
import { Context as AuthContext } from '../../../../contexts/AuthContext';

import ClassService from '../../../../services/Class';
import ClassUserService from '../../../../services/ClassUser';

const ClassPermissionsTab = ({ userId }: { userId: string }) => {
  const { token, currentSchool }: any = useContext(AuthContext);

  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [classPermited, setClassPermited] = useState<string[]>([]);
  const [mainClass, setMainClass] = useState('');
  const [classesList, setClassesList] = useState<any[]>([]);
  const [selectedClass, setSelectedClass] = useState('');

  const removeClassToPermited = (id: string) => {
    const classes = JSON.parse(JSON.stringify(classPermited));
    classes.splice(classes.indexOf(id), 1);
    setClassPermited(classes);
  };

  const addClassToPermited = (id: string) => {
    const classes = JSON.parse(JSON.stringify(classPermited));
    if (classes.includes(id) || id === '') {
      return false;
    }
    classes.push(id);
    setClassPermited(classes);
    setSelectedClass('');
  };

  const getAllClasses = async () => {
    if (!currentSchool?.value) return;
    try {
      const classes = await ClassService(token).listBySchool(currentSchool.value || '');
      if (classes?.data) {
        setClassesList(classes.data);
      }
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No schools found');
    }
  };

  useEffect(() => {
    getById();
    getAllClasses();
  }, []);

  useEffect(() => {
    getById();
  }, [userId]);

  const getById = async () => {
    setIsDisabled(true);
    try {
      const result = await ClassUserService(token).show(userId);
      if (result?.data) {
        setClassPermited(result?.data.filter((item: any) => item.isMain === false).map((item: any) => item.classId));
        setMainClass(
          result?.data.filter((item: any) => item.isMain === true).map((item: any) => item.classId)[0] || '',
        );
      }
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'Invalid user ID');
    } finally {
      setIsDisabled(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);
    setIsDisabled(true);

    const body = {
      userId,
      classes: classPermited,
    };

    try {
      await ClassUserService(token).associateWithClasses(body);
      toast.success('Updated successfully!');
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Update error!');
      toast.error(message);
    } finally {
      setIsDisabled(false);
    }

    setLoading(false);
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <div className="pl-lg-4 mt-3">
          <Row className="mb-4">
            <Col md="4">
              <div className="form-group">
                <label>User Role</label>
                <Input type="text" value="Classroom" readOnly={true} />
              </div>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              <h4>Main Class</h4>
              <Input type="text" value={classesList.find((cls) => cls.id == mainClass)?.name} readOnly={true} />
            </Col>
          </Row>
          <Row>
            <Col>
              <h4>Class Permissions</h4>
              <div className="input-group mb-3">
                <Input type="select" value={selectedClass} onChange={(e) => setSelectedClass(e.target.value)}>
                  <option value=""></option>
                  {classesList?.map((filter: any, index: number) => {
                    if (classPermited.indexOf(filter.id) !== -1 || filter.id === mainClass) {
                      return;
                    }
                    return (
                      <option value={filter.id} key={`classes-${index}`}>
                        {filter.name}
                      </option>
                    );
                  })}
                </Input>
                <div className="input-group-append">
                  <button className="btn btn-primary" type="button" onClick={() => addClassToPermited(selectedClass)}>
                    <i className="fas fa-plus"></i>
                  </button>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            {classPermited.map((item, i) => {
              return (
                <Col xs="12" key={i}>
                  <div className="input-group mb-3">
                    <Input type="text" value={classesList.find((cls) => cls.id == item)?.name} readOnly={true} />
                    <div className="input-group-append">
                      <button className="btn btn-danger" type="button" onClick={() => removeClassToPermited(item)}>
                        <i className="fas fa-minus"></i>
                      </button>
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
          <Button className="my-4" color="primary" type="submit" disabled={isDisabled}>
            Save
          </Button>
        </div>
      </Form>
    </>
  );
};

export default ClassPermissionsTab;
