import http from '../helpers/http';

const BillingNotifications = (token: string) => {
  const headers = {
    'x-access-token': token,
  };

  return {
    list: async (status: string) => {
      const result = await http.get(`/billing-notifications/${status}`, {
        headers,
      });
      return result.data;
    },
    read: async (id: string) => {
      const result = await http.patch(
        `/billing-notifications/${id}/read`,
        {},
        {
          headers,
        },
      );
      return result.data;
    },
  };
};

export default BillingNotifications;
