import { Button, Col, Form, Modal, Row } from 'reactstrap';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import EmployeeTerminate from '../../../services/EmployeeTerminate';
import { useContext } from 'react';
import { toast } from 'react-toastify';

interface RevokeTerminationFormProps {
  isOpen: boolean;
  toogle: () => void;
  revokeUser: any;
}

const RevokeTerminationForm = ({ isOpen, toogle, revokeUser }: RevokeTerminationFormProps) => {
  const { token }: any = useContext(AuthContext);
  const handleSubmit = async (e: any) => {
    e?.preventDefault();
    if (!revokeUser?.id) return;
    await EmployeeTerminate(token).revoke(revokeUser?.id);
    toast.success('Saved successfully!');
    toogle();
  };
  return (
    <Modal className="modal-dialog-centered" isOpen={isOpen} toggle={toogle} size={'lg'}>
      <div className="modal-header">
        <h4 className="modal-title">Revoke Termination</h4>
        <div onClick={toogle} className="d-flex p-1 cursor-pointer">
          <i className="fas fa-times" />
        </div>
      </div>
      <div className="modal-body" style={{ borderTop: '1px solid #d9d9d9' }}>
        <div className="px-2">
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col lg="12">
                <span>
                  Revoking this termination will remove the most recent termination from {revokeUser?.user?.firstName}'s
                  profile and they will be Active in the system. Note that this can affect their final paycheck and
                  continued health coverage.
                  <br />
                  <br />
                  You are responsible for, if applicable
                </span>
              </Col>
            </Row>

            <Row className="mt-2 ml-1">
              <Col lg="12" className="d-flex align-items-center">
                <span className="mb-1">Updating any Time Off accruals</span>
              </Col>
            </Row>

            <Row className="mt-1 ml-1">
              <Col lg="12" className="d-flex align-items-center">
                <span className="mb-1">Adding them back to payroll</span>
              </Col>
            </Row>

            <Row className="mt-1 ml-1">
              <Col lg="12" className="d-flex align-items-center">
                <span className="mb-1">Adjusting any earnings for time worked</span>
              </Col>
            </Row>

            <Row className="mt-1 ml-1">
              <Col lg="12" className="d-flex align-items-center">
                <span className="mb-1">Reactivating any other workplace accounts</span>
              </Col>
            </Row>

            <div className="d-flex justify-content-end mt-4">
              <div>
                <Button className="btn-secondary" onClick={toogle}>
                  Cancel
                </Button>
                <Button color="warning" type="submit">
                  Revoke Termination
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default RevokeTerminationForm;
