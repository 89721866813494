import { useContext, useState, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { Card, CardBody, CardHeader, Col, FormGroup, Input, Row, Spinner } from 'reactstrap';
import TransactionService from '../../../services/Transaction';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import { toast } from 'react-toastify';

const ChargeTransaction = () => {
  const { token, user }: any = useContext(AuthContext);
  const [amount, setAmount] = useState(0);
  const [transactionType, setTransactionType] = useState('CREDIT_CARD');
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [lastTransaction, setLastTransaction] = useState({} as any);

  useEffect(() => {
    setLoading(false);
  }, [lastTransaction]);

  const handlePayment = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    try {
      const transaction = await TransactionService(token).create({
        userId: user?.id || '',
        transactionType,
        amount,
        description,
      });
      setLastTransaction(transaction);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'Payment error');
      setLoading(false);
    }
  };

  return (
    <>
      <Row>
        <Col xl="12">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">Transaction with saved payment data</CardHeader>
            <CardBody>
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-school-id">
                      Type
                    </label>
                    <Input type="select" onChange={(e) => setTransactionType(e.target.value)} value={transactionType}>
                      <option value="CREDIT_CARD">Credit Card</option>
                      <option value="ACH">ACH</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col xl="6">
                  <FormGroup>
                    <label className="form-control-label">Amount</label>
                    <Input
                      type="text"
                      thousandsGroupStyle="thousand"
                      prefix="$"
                      decimalSeparator="."
                      displayType="input"
                      thousandSeparator={true}
                      allowNegative={false}
                      decimalScale={2}
                      tag={NumberFormat}
                      value={amount}
                      onValueChange={(e: any) => setAmount(e.floatValue)}
                    />
                  </FormGroup>
                </Col>
                <Col lg="12">
                  <FormGroup>
                    <label className="form-control-label">Description</label>
                    <Input
                      className="form-control-alternative"
                      placeholder="Description"
                      type="text"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col xl="12">
                  <button className="btn btn-primary" onClick={(e) => handlePayment(e)}>
                    Charge Now
                  </button>
                </Col>
                {!loading && lastTransaction ? (
                  <Col xl="12" className="mt-4">
                    <div>
                      <h3>RESULT:</h3>
                      <pre>{JSON.stringify(lastTransaction, null, 2)}</pre>
                    </div>
                  </Col>
                ) : (
                  <></>
                )}
                {loading && (
                  <Col xl="12" className="mt-4 text-center">
                    <span>
                      <Spinner />
                    </span>
                  </Col>
                )}
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ChargeTransaction;
