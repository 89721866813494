import { Button, Card, CardBody, Form, Col, Input, Label } from 'reactstrap';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import Employee from '../../../services/Employee';
import LoaderSpinner from '../../../components/Core/LoaderSpinner';

const NewHireReject = () => {
  const { token } = useParams<{ token: string }>();
  const [isRequested, setIsRequested] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [isValidToken, setIsValidToken] = useState(true);

  useEffect(() => {
    validationToken();
  }, []);

  const validationToken = async () => {
    setIsLoading(true);
    try {
      await Employee('').newHireValidationToken(token);
      setIsValidToken(true);
    } catch {
      setIsValidToken(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      await Employee('').rejectNewHire(token);
      setIsRequested(true);
    } catch (e: any) {
      console.log(e);
      toast.error(e?.response?.data?.message || 'Request error');
    }
  };

  if (isLoading) {
    return (
      <Col lg="10" md="10">
        <Card className="bg-secondary shadow border-0 py-6">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="d-flex align-Items-center justify-content-center">
              <LoaderSpinner />
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  }

  if (!isValidToken) {
    return (
      <Col lg="10" md="10">
        <Card className="bg-secondary shadow border-0 py-6">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="d-flex align-Items-center justify-content-center">
              <i className="fas fa-ban" style={{ fontSize: 72, color: '#df0d0d' }} />
            </div>
            <h2 className="text-center mt-4">Token is invalid or expired!</h2>
          </CardBody>
        </Card>
      </Col>
    );
  }
  return (
    <>
      <Col lg="10" md="10">
        {isRequested ? (
          <Card className="bg-secondary shadow border-0 py-6">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="d-flex align-Items-center justify-content-center">
                <i className="fas fa-ban" style={{ fontSize: 72, color: '#df0d0d' }} />
              </div>
              <h2 className="text-center mt-4"> Offer Letter Rejected</h2>
            </CardBody>
          </Card>
        ) : (
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small>Reject Offer Letter</small>
              </div>
              <Form onSubmit={handleSubmit}>
                <Col lg="12">
                  <Label>Reason (optional)</Label>
                  <Input type="textarea" />
                </Col>
                <Col lg="12" className="text-right">
                  <Button className="my-4" color="danger" type="submit">
                    Reject
                  </Button>
                </Col>
              </Form>
            </CardBody>
          </Card>
        )}
      </Col>
    </>
  );
};

export default NewHireReject;
