import { Button, Card, CardHeader, CardBody, Container, Row, Col } from 'reactstrap';
import { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FilteredOptionsInterface } from '../../../entities/FilteredOptions';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import CommonHeader from '../../../components/Headers/CommonHeader';
import ClassDetailedTable from './ClassDetailedTable';
import ClassDetailedTeachersModal from './ClassDetailedTeachersModal';
import ClassDetailedKidsModal from './ClassDetailedKidsModal';
import ClassDetailedEnrolledModal from './ClassDetailedEnrolledModal';
import ClassService from '../../../services/Class';
import ClassInterface from '../../../interfaces/ClassInterface';
import KidsService from '../../../services/Kids';
import KidsInterface from '../../../interfaces/KidsInterface';
import { PagedMetaInterface, createPagedMeta } from '../../../entities/PagedMeta';
import { createOptionsPage } from '../../../entities/OptionsPaged';
import isAdmin from '../../../helpers/isAdmin';
import isDirector from '../../../helpers/isDirector';
import isEducationDirectorAdminAssistant from '../../../helpers/isEducationDirectorAdminAssistant';
import isUpperManagment from '../../../helpers/isUpperManagment';

const ClassDetailedList = () => {
  const { token, currentSchool, user }: any = useContext(AuthContext);
  const hasEditPermission =
    isAdmin(user) || isDirector(user) || isEducationDirectorAdminAssistant(user) || isUpperManagment(user);

  const [loading, setLoading] = useState(false);
  const [loadingModalData, setLoadingModalData] = useState(false);
  const [data, setData] = useState([] as any[]);
  const [pagedMeta, setPagedMeta] = useState(createPagedMeta());
  const [currentModalId, setCurrentModalId] = useState('');
  const [currentModalClassName, setCurrentModalClassName] = useState('');
  const [currentTeachersIds, setCurrentTeachersIds] = useState([] as string[]);
  const [currentTeachersClock, setCurrentTeachersClock] = useState([] as any[]);
  const [currentKidsNotStarted, setCurrentKidsNotStarted] = useState([] as any[]);
  const [currentKidsEnrolled, setCurrentKidsEnrolled] = useState([] as any[]);
  const [isTeachersModal, setIsTeachersModal] = useState(false);
  const [isNotStartedModal, setIsNotStartedModal] = useState(false);
  const [isEnrolledModal, setIsEnrolledModal] = useState(false);

  useEffect(() => {
    const filteredOptions = {
      filters: [],
      options: createOptionsPage(pagedMeta),
    };
    loadList(filteredOptions);
  }, [currentSchool]);

  useEffect(() => {
    if (!currentModalId) {
      return;
    }
    listClassKids();
  }, [currentModalId]);

  const loadList = async (filteredOptions: FilteredOptionsInterface) => {
    try {
      if (loading) return;

      setLoading(true);

      const filter = {
        field: 'schoolId',
        operation: 'hash',
        value: currentSchool.value,
      };
      filteredOptions.filters = [filter];

      const result = await ClassService(token || '').list(filteredOptions);
      const dataResult: ClassInterface[] = result.data || [];
      setData(dataResult);
      const meta: PagedMetaInterface = result.meta;
      if (meta) {
        setPagedMeta(meta);
      }
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Error fetching list');
      return toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  const listClassKids = async () => {
    try {
      setLoadingModalData(true);
      const result = await KidsService(token || '').listByClass(currentModalId || '');
      const data: KidsInterface[] = result?.data || [];
      const notStarted = data.filter((k) => k.enrollmentStatus === 'ENROLLED_NOT_STARTED');
      setCurrentKidsNotStarted(notStarted);
      const enrolled = data.filter((k) => k.enrollmentStatus === 'ENROLLED');
      setCurrentKidsEnrolled(enrolled);
    } finally {
      setLoadingModalData(false);
    }
  };

  const toggleTeachersModal = (teachersCheckedIn: any[] = [], teachersCheckedOut: any[] = []) => {
    const isTrue = !isTeachersModal;
    if (!isTrue) {
      setCurrentTeachersIds([]);
      setCurrentTeachersClock([]);
      setIsTeachersModal(false);
      return;
    }
    const teachersIds = [];
    const teachersClock = [];
    const idsCheckedIn = teachersCheckedIn
      .map((t) => ({ userId: t.userId, createdAt: t.createdAt }))
      .filter((t) => !!t);
    const idsCheckedOut = teachersCheckedOut
      .map((t) => ({ userId: t.userId, createdAt: t.createdAt }))
      .filter((t) => !!t);
    const isVerified: string[] = [];
    for (const id of idsCheckedIn) {
      if (isVerified.includes(id.userId)) continue;
      isVerified.push(id.userId);
      const countCheckedIn = idsCheckedIn.filter((t) => t.userId === id.userId);
      const countCheckedOut = idsCheckedOut.filter((t) => t.userId === id.userId).length;
      if (countCheckedIn.length > countCheckedOut) {
        teachersIds.push(id.userId);
        teachersClock.push({ id: id.userId, clocked: countCheckedIn[countCheckedIn.length - 1].createdAt });
      }
    }
    setCurrentTeachersIds(teachersIds);
    setCurrentTeachersClock(teachersClock);
    setIsTeachersModal(!isTeachersModal);
  };

  const toggleEnrolledModal = (id: string, className: string = '') => {
    const isTrue = !isEnrolledModal;
    if (!isTrue) {
      return clearModalData();
    }
    setCurrentModalId(id);
    setCurrentModalClassName(className);
    setIsEnrolledModal(!isEnrolledModal);
  };

  const toggleNotStartedModal = (id: string, className: string = '') => {
    const isTrue = !isNotStartedModal;
    if (!isTrue) {
      return clearModalData();
    }
    setCurrentModalId(id);
    setCurrentModalClassName(className);
    setIsNotStartedModal(!isNotStartedModal);
  };

  const clearModalData = () => {
    setCurrentModalId('');
    setCurrentModalClassName('');
    setIsEnrolledModal(false);
    setIsNotStartedModal(false);
    setCurrentKidsNotStarted([]);
    return;
  };

  return (
    <>
      <CommonHeader />
      <Container className="mt--8" fluid>
        <Row>
          <Col className="order-xl-2" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Classroom</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    {hasEditPermission && (
                      <Link to="/class/list">
                        <Button color="primary" className="float-right">
                          Create/Edit
                        </Button>
                      </Link>
                    )}
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  {data?.map((c: ClassInterface) => {
                    return (
                      <ClassDetailedTable
                        key={`class-table-${c.id}`}
                        id={c.id}
                        cameraUri={!!c.cameras?.length}
                        name={c.name}
                        legalRatioTeachers={c.legalRatioTeachers}
                        legalRatioChildrens={c.legalRatioChildrens}
                        teachers={(c.teachersCheckedIn?.length || 0) - (c.teachersCheckedOut?.length || 0)}
                        capacity={c.capacity}
                        enrolled={c.kidsEnrolled || 0}
                        notStarted={c.notStarted || 0}
                        checkedIn={c.checkedIn || 0}
                        toggleTeachersModal={() =>
                          toggleTeachersModal(c.teachersCheckedIn || [], c.teachersCheckedOut || [])
                        }
                        toggleEnrolledModal={toggleEnrolledModal}
                        toggleNotStartedModal={toggleNotStartedModal}
                      />
                    );
                  })}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <ClassDetailedTeachersModal
        isOpen={isTeachersModal}
        toggleModal={toggleTeachersModal}
        teachersIds={currentTeachersIds}
        teachersClock={currentTeachersClock}
      />
      <ClassDetailedKidsModal
        isOpen={isNotStartedModal}
        className={`Not Started - ${currentModalClassName}`}
        kidsList={currentKidsNotStarted}
        loading={loadingModalData}
        toggleModal={toggleNotStartedModal}
      />
      <ClassDetailedEnrolledModal
        isOpen={isEnrolledModal}
        className={currentModalClassName}
        kids={currentKidsEnrolled}
        toggleModal={toggleEnrolledModal}
      />
    </>
  );
};

export default ClassDetailedList;
