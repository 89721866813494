import http from '../helpers/http';

const ClassUserService = (token: string) => {
  const basePath = '/class-user';
  const headers = {
    'x-access-token': token,
  };

  return {
    associateWithClasses: async (body: { userId: string; classes: string[] }) => {
      const result = await http.put(`${basePath}/user/${body.userId}/associate`, body, { headers });
      return result.data;
    },
    show: async (id: string) => {
      const result = await http.get(`${basePath}/user/${id}`, {
        headers,
      });
      return result.data;
    },
  };
};

export default ClassUserService;
