/* eslint-disable quotes */
/* eslint-disable max-lines */
import {
  Table,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Card,
  CardHeader,
  Container,
} from 'reactstrap';
import { useContext, useState, useEffect } from 'react';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import Employee from '../../../services/Employee';
import moment from 'moment';
import ReactDatetime from 'react-datetime';
import CommonHeader from '../../../components/Headers/CommonHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentDots, faMountainCity, faTimes } from '@fortawesome/free-solid-svg-icons';
import Pto from '../../../services/Pto';
import HolidaysInterface from '../../../interfaces/HolidaysInterface';
import Holidays from '../../../services/Holidays';
import { confirmAlert } from 'react-confirm-alert';
import confirmAlertHelper from '../../../helpers/confirmAlert';
import HolidayDeleteConfirm from './HolidayDeleteConfirm';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import PtoTypes from '../../../services/PtoTypes';
import PtoTypesInterface from '../../../interfaces/PtoTypesInterface';

const PtoPolicesForm = () => {
  const { id } = useParams<{ id: string }>();
  const { token, currentSchool }: any = useContext(AuthContext);
  const [step, setStep] = useState(0);
  const [isOpenHoliday, setIsOpenHoliday] = useState(false);
  const [selectedHolidays, setSelectedHolidays] = useState<HolidaysInterface[]>([]);
  const [holidays, setHolidays] = useState<HolidaysInterface[]>([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [showHolidayForm, setShowHolidayForm] = useState(false);
  const [holidayName, setHolidayName] = useState('');
  const [holidayStartDate, setHolidayStartDate] = useState('');
  const [holidayEndDate, setHolidayEndDate] = useState('');
  const [isRecurringHoliday, setIsRecurringHoliday] = useState(false);

  const [isOpenptoTypess, setIsOpenPtoTypess] = useState(false);
  const [ptoTypes, setPtoTypes] = useState<PtoTypesInterface[]>([]);
  const [selectedPtoTypes, setSelectedPtoTypes] = useState<string[]>([]);
  const [showPtoTypesForm, setshowPtoTypesForm] = useState(false);
  const [ptoReason, setPtoReason] = useState('');
  const [isPtoTypeCounted, setIsPtoTypeCounted] = useState(false);
  const [isPtoTypePaid, setIsPtoTypePaid] = useState(false);
  const [selectAllptoTypess, setSelectAllptoTypess] = useState(false);
  const [name, setName] = useState('');
  // const [policyType, setPolicyType] = useState('PTO');
  const [hoursPerWorkday, setHoursPerWorkday] = useState('');
  const [standardWorkweek, setStandardWorkweek] = useState<any[]>([]);
  const [accrualMethod, setAccrualMethod] = useState('Days per year');
  const [accrualFrequency, setAccrualFrequency] = useState('');
  const [accrualRateDaysPerYear, setAccrualRateDaysPerYear] = useState<number>(0);
  const [timingOfAccrual, setTimingOfAccrual] = useState('');
  const [isTenureAccrualRateOpen, setIsTenureAccrualRateOpen] = useState(false);
  const [tenureAccrualRateFirstYear, setTenureAccrualRateFirstYear] = useState(0);
  const [tenureAccrualRateSecondYear, setTenureAccrualRateSecondYear] = useState(0);
  const [tenureAccrualRateThirdYear, setTenureAccrualRateThirdYear] = useState(0);
  const [tenureAccrualRateFourthYear, setTenureAccrualRateFourthYear] = useState(0);
  const [tenureAccrualRateFifthYear, setTenureAccrualRateFifthYear] = useState(0);

  const [annualResetDate, setAnnualResetDate] = useState('');

  const [maximumBalanceHours, setMaximumBalanceHours] = useState<number>();
  const [isDontMaximumBalanceHours, setIsDontMaximumBalanceHours] = useState(false);

  const [carryoverHours, setCarryoverHours] = useState<number>();
  const [isDontLimitCarryover, setIsDontLimitCarryover] = useState(false);

  const [negativeBalanceHours, setNegativeBalanceHours] = useState<number>();

  const [newHireRequestPeriod, setNewHireRequestPeriod] = useState<number>();
  const [accrueTimeDuringPeriod, setAccrueTimeDuringPeriod] = useState(false);
  const [payWorkerOnCompanyHoliday, setPayWorkerOnCompanyHoliday] = useState(false);

  const [searchStaffName, setSearchStaffName] = useState('');
  const [employees, setEmployees] = useState<any[]>();

  const loadStaff = async () => {
    const result = await Employee(token).listEmployeesBySchool(currentSchool.value, searchStaffName);
    setEmployees(result?.data);
  };

  useEffect(() => {
    listPtoTypes();
  }, []);

  useEffect(() => {
    loadStaff();
  }, [searchStaffName]);

  const handleAddTenureAccrualRate = () => {
    setIsTenureAccrualRateOpen(!isTenureAccrualRateOpen);
  };

  const handleClick = (value: string) => {
    if (standardWorkweek.includes(value)) {
      setStandardWorkweek(standardWorkweek.filter((day: string) => day !== value));
    } else {
      setStandardWorkweek([...standardWorkweek, value]);
    }
  };

  useEffect(() => {
    loadHolidays();
  }, [selectedYear]);

  const loadHolidays = async () => {
    const { data } = await Holidays(token).list(selectedYear, currentSchool.value || '');

    setHolidays(data);
  };

  const handleSubmitHolidayForm = async (event: any) => {
    event.preventDefault();

    try {
      const data = {
        schoolId: currentSchool?.value || '',
        name: holidayName,
        startDate: holidayStartDate,
        endDate: holidayEndDate,
        isRecurring: isRecurringHoliday,
      };

      await Holidays(token).create(data);
      setHolidayName('');
      setHolidayStartDate('');
      setHolidayEndDate('');
      setIsRecurringHoliday(false);
      loadHolidays();
      setShowHolidayForm(false);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'Request error');
    }
  };

  const getById = async () => {
    try {
      const result = await Pto(token).ptoPolicyShow(id);
      const data = result?.data || {};
      if (!data) return;
      setName(data?.name || '');
      setHoursPerWorkday(data?.hoursPerWorkday || '');
      setStandardWorkweek(data?.standardWorkweek || 0);
      setAccrualMethod(data?.accrualMethod || '');
      setAccrualFrequency(data?.accrualFrequency || '');
      setAccrualRateDaysPerYear(data?.accrualRateDaysPerYear || 0);
      setTimingOfAccrual(data?.timingOfAccrual || '');
      setIsTenureAccrualRateOpen(
        !!(
          data?.tenureAccrualRateFirstYear ||
          data?.tenureAccrualRateSecondYear ||
          data?.tenureAccrualRateThirdYear ||
          data?.tenureAccrualRateFourthYear ||
          data?.tenureAccrualRateFifthYear
        ),
      );
      setTenureAccrualRateFirstYear(data?.tenureAccrualRateFirstYear || 0);
      setTenureAccrualRateSecondYear(data?.tenureAccrualRateSecondYear || 0);
      setTenureAccrualRateThirdYear(data?.tenureAccrualRateThirdYear || 0);
      setTenureAccrualRateFourthYear(data?.tenureAccrualRateFourthYear || 0);
      setTenureAccrualRateFifthYear(data?.tenureAccrualRateFifthYear || 0);
      setAccrueTimeDuringPeriod(data?.accrueTimeDuringPeriod || false);
      setAnnualResetDate(data?.annualResetDate || '');
      setCarryoverHours(data?.carryoverHours || 0);
      setMaximumBalanceHours(data?.maximumBalanceHours || 0);
      setIsDontMaximumBalanceHours(data?.isDontMaximumBalanceHours || false);
      setIsDontLimitCarryover(data?.isDontLimitCarryover || false);
      setNegativeBalanceHours(data?.negativeBalanceHours || '');
      setNewHireRequestPeriod(data?.newHireRequestPeriod || '');
      setPayWorkerOnCompanyHoliday(data?.payWorkerOnCompanyHoliday || '');

      const ptoPolicyHolidays = data?.ptoPolicyHolidays || [];
      const holidays = ptoPolicyHolidays.map((h: any) => h?.holidays || {}).filter((h: any) => !!h);
      setSelectedHolidays(holidays);
      const ptoPolicyTypes = data?.ptoPolicyTypes || [];
      const ptoTypes = ptoPolicyTypes.map((h: any) => h?.ptoType?.reason || '').filter((h: any) => !!h);
      setSelectedPtoTypes(ptoTypes);
    } catch (e: any) {
      console.error(e?.response?.data?.message || 'Request error');
    }
  };

  useEffect(() => {
    if (id) getById();
  }, [id]);

  const handleDeleteHoliday = async (id: string) => {
    await Holidays(token).delete(id);

    loadHolidays();
  };

  const handleDeletePtoTypes = async (event: any, id: string) => {
    event.preventDefault();
    return confirmAlertHelper({
      title: 'Delete',
      messageType: 'DELETE',
      message: 'Are you sure to delete this PTO Type?',
      onClickYes: () => deletePtoTypes(id),
    });
  };

  const deletePtoTypes = async (id: string) => {
    try {
      await PtoTypes(token).delete(id);
      listPtoTypes();
      toast.success('Deleted Successfully!');
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'Request error');
    }
  };

  const handleSelectHoliday = (holidaySelected: HolidaysInterface) => {
    const holidayFound = selectedHolidays.find((holiday) => holiday.id === holidaySelected.id);

    if (holidayFound) {
      const newSelectHolidays = selectedHolidays.filter((holiday) => holiday.id !== holidaySelected.id);
      setSelectedHolidays(newSelectHolidays);
    } else {
      const newSelectHolidays = [...selectedHolidays];
      newSelectHolidays.push(holidaySelected);
      setSelectedHolidays(newSelectHolidays);
    }
  };

  const handleSelectAllHolidays = () => {
    if (selectedHolidays.length === holidays.length) {
      setSelectedHolidays([]);
    } else {
      setSelectedHolidays([...holidays]);
    }
  };

  const confirmDeleteHoliday = (e: any, id: string) => {
    e.preventDefault();

    confirmAlert({
      customUI: ({ onClose }) => {
        return <HolidayDeleteConfirm id={id} handleDelete={handleDeleteHoliday} onClose={onClose} />;
      },
    });
  };
  const handleIncriseSelectedYear = () => {
    setSelectedYear(selectedYear + 1);
  };

  const handleDecriseSelectedYear = () => {
    setSelectedYear(selectedYear - 1);
  };

  const toggleselectAllptoTypess = () => {
    const all = !selectAllptoTypess;
    setSelectAllptoTypess(all);
    setSelectedPtoTypes(all ? ptoTypes.map((h) => h.reason) : []);
  };

  const toggleptoTypessCheckbox = (index: number) => {
    const type = ptoTypes[index];
    const isExists = type ? !!selectedPtoTypes.find((s) => s === type.reason) : false;
    if (isExists) {
      return setSelectedPtoTypes(selectedPtoTypes.filter((s) => s !== type.reason));
    }
    setSelectedPtoTypes([...selectedPtoTypes, type.reason]);
  };

  const listPtoTypes = async () => {
    try {
      const result = await PtoTypes(token).list(currentSchool.value || '');
      const data = result?.data || {};
      setPtoTypes(data);
      setshowPtoTypesForm(false);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'Request error');
    }
  };

  const handleSubmitPtoTypesForm = async (event: any) => {
    event.preventDefault();

    try {
      const data = {
        schoolId: currentSchool?.value || '',
        reason: ptoReason,
        isCounted: isPtoTypeCounted,
        isPaid: isPtoTypePaid,
      };

      await PtoTypes(token).create(data);

      await listPtoTypes();

      setshowPtoTypesForm(false);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'Request error');
    }
  };

  const handleChangeStep = (event: React.FormEvent) => {
    event.preventDefault();

    setStep(step + 1);
  };

  const handleSubmitPtoPolicy = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      const data: any = {
        name,
        hoursPerWorkday: hoursPerWorkday ? Number(hoursPerWorkday) : 0,
        standardWorkweek: standardWorkweek.toString(),
        accrualMethod,
        accrualFrequency,
        accrualRateDaysPerYear,
        timingOfAccrual,
        tenureAccrualRateFirstYear,
        tenureAccrualRateSecondYear,
        tenureAccrualRateThirdYear,
        tenureAccrualRateFourthYear,
        tenureAccrualRateFifthYear,
        accrueTimeDuringPeriod,
        annualResetDate: annualResetDate || '',
        carryoverHours: carryoverHours || 0,
        isDontMaximumBalanceHours: !!isDontMaximumBalanceHours,
        maximumBalanceHours: maximumBalanceHours || 0,
        isDontLimitCarryover: !!isDontLimitCarryover,
        negativeBalanceHours: negativeBalanceHours || 0,
        newHireRequestPeriod: newHireRequestPeriod || 0,
        payWorkerOnCompanyHoliday: !!payWorkerOnCompanyHoliday,
        holidays: selectedHolidays?.map((h) => h.id),
        ptoTypes: selectedPtoTypes,
      };

      id ? await Pto(token).ptoPolicyUpdate(id, data) : await Pto(token).ptoPolicyCreate(data);

      setStep(3);
      toast.success('Saved Successfully!');
    } catch (err) {
      setStep(2);
      console.log(err);
    }
  };

  return (
    <>
      <CommonHeader />
      <Container className="mt--8" fluid>
        <Row>
          <Col className="order-xl-2" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0" style={{ maxHeight: '62px' }}>
                <h3>PTO Policies Settings</h3>
              </CardHeader>
              {step === 0 && (
                <div className="p-5 pt-2">
                  <Form onSubmit={(event) => handleChangeStep(event)}>
                    <Row>
                      <Col lg="6">
                        <FormGroup className="d-flex align-items-center">
                          <label className="form-control-label mr-2" htmlFor="input-description-1">
                            <strong>Name:</strong>
                          </label>
                          <Input
                            type="text"
                            placeholder="Example: Executive Policy"
                            value={name}
                            onChange={(event) => setName(event.target.value)}
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6" className="d-flex">
                        <Label className="mr-2">
                          <strong>Type:</strong>
                        </Label>
                        <div className="d-flex flex-column flex-wrap">
                          <div className="form-check form-check-inline mr-3">
                            <Input
                              className="form-check-input"
                              type="radio"
                              name="pto"
                              value={'PTO'}
                              checked={true}
                              required
                            />
                            <Label className="form-check-label mr-2" htmlFor="PTO">
                              PTO
                            </Label>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col lg="12">
                        <div className="d-flex align-items-center mt-4">
                          <Button className="mr-2" color="primary" size="sm" onClick={() => setIsOpenHoliday(true)}>
                            <i className="fas fa-plus" />
                          </Button>
                          <h3 className="p-0 m-0">Holiday</h3>
                        </div>
                      </Col>
                      <Col lg="12" className="d-flex mt-2">
                        {selectedHolidays.map((holiday) => {
                          return (
                            <div
                              key={`pto-holiday-${holiday.id}`}
                              className="d-flex align-items-center justify-content-center mt-2 mr-2"
                              style={{ border: '1px solid #dddddd', padding: '20px', width: '170px' }}
                            >
                              <h5>
                                {holiday.name}
                                <br />
                                {`${moment(holiday.startDate).format('MM/DD')} ${
                                  holiday.startDate !== holiday.endDate ? moment(holiday.endDate).format('~ MM/DD') : ''
                                }`}
                              </h5>
                            </div>
                          );
                        })}
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col lg="8">
                        <FormGroup className="d-flex align-items-center mt-4">
                          <label
                            className="form-control-label mr-2"
                            htmlFor="input-description-1"
                            style={{ whiteSpace: 'nowrap' }}
                          >
                            <strong>Hours per Workday:</strong>
                          </label>
                          <Input
                            type="number"
                            value={hoursPerWorkday}
                            min={0}
                            onChange={(e) => setHoursPerWorkday(String(e.target.value))}
                          />
                          <div className="d-flex">
                            <Button
                              className="rounded-circle mr-2 ml-2"
                              size="sm"
                              color="primary"
                              onClick={() => setHoursPerWorkday(String(Number(hoursPerWorkday) + 1))}
                            >
                              <i className="fas fa-plus" />
                            </Button>
                            <Button
                              className="rounded-circle"
                              size="sm"
                              color="primary"
                              onClick={() => {
                                if (Number(hoursPerWorkday) === 0) return;
                                setHoursPerWorkday(String(Number(hoursPerWorkday) - 1));
                              }}
                            >
                              <i className="fas fa-minus" />
                            </Button>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12" className="d-flex align-items-center">
                        <h4 className="p-0  m-0 mr-2" style={{ whiteSpace: 'nowrap' }}>
                          Standard workweek:
                        </h4>
                        <Button
                          style={{
                            border: standardWorkweek.includes('Sunday') ? '1px solid #5ca8ff' : '1px solid #000000',
                            color: standardWorkweek.includes('Sunday') ? '#5ca8ff' : '#000000',
                            fontSize: '12px',
                            width: '95px',
                            height: '45px',
                          }}
                          size="sm"
                          outline={!standardWorkweek.includes('Sunday')}
                          onClick={() => handleClick('Sunday')}
                        >
                          Sunday
                        </Button>
                        <Button
                          style={{
                            border: standardWorkweek.includes('Monday') ? '1px solid #5ca8ff' : '1px solid #000000',
                            color: standardWorkweek.includes('Monday') ? '#5ca8ff' : '#000000',
                            fontSize: '12px',
                            width: '95px',
                            height: '45px',
                          }}
                          size="sm"
                          outline={!standardWorkweek.includes('Monday')}
                          onClick={() => handleClick('Monday')}
                        >
                          Monday
                        </Button>
                        <Button
                          style={{
                            border: standardWorkweek.includes('Tuesday') ? '1px solid #5ca8ff' : '1px solid #000000',
                            color: standardWorkweek.includes('Tuesday') ? '#5ca8ff' : '#000000',
                            fontSize: '12px',
                            width: '95px',
                            height: '45px',
                          }}
                          size="sm"
                          outline={!standardWorkweek.includes('Tuesday')}
                          onClick={() => handleClick('Tuesday')}
                        >
                          Tuesday
                        </Button>
                        <Button
                          style={{
                            border: standardWorkweek.includes('Wednesday') ? '1px solid #5ca8ff' : '1px solid #000000',
                            color: standardWorkweek.includes('Wednesday') ? '#5ca8ff' : '#000000',
                            fontSize: '12px',
                            width: '95px',
                            height: '45px',
                          }}
                          size="sm"
                          outline={!standardWorkweek.includes('Wednesday')}
                          onClick={() => handleClick('Wednesday')}
                        >
                          Wednesday
                        </Button>
                        <Button
                          style={{
                            border: standardWorkweek.includes('Thursday') ? '1px solid #5ca8ff' : '1px solid #000000',
                            color: standardWorkweek.includes('Thursday') ? '#5ca8ff' : '#000000',
                            fontSize: '12px',
                            width: '95px',
                            height: '45px',
                          }}
                          size="sm"
                          outline={!standardWorkweek.includes('Thursday')}
                          onClick={() => handleClick('Thursday')}
                        >
                          Thursday
                        </Button>
                        <Button
                          style={{
                            border: standardWorkweek.includes('Friday') ? '1px solid #5ca8ff' : '1px solid #000000',
                            color: standardWorkweek.includes('Friday') ? '#5ca8ff' : '#000000',
                            fontSize: '12px',
                            width: '95px',
                            height: '45px',
                          }}
                          size="sm"
                          outline={!standardWorkweek.includes('Friday')}
                          onClick={() => handleClick('Friday')}
                        >
                          Friday
                        </Button>
                        <Button
                          style={{
                            border: standardWorkweek.includes('Saturday') ? '1px solid #5ca8ff' : '1px solid #000000',
                            color: standardWorkweek.includes('Saturday') ? '#5ca8ff' : '#000000',
                            fontSize: '12px',
                            width: '95px',
                            height: '45px',
                          }}
                          size="sm"
                          outline={!standardWorkweek.includes('Saturday')}
                          onClick={() => handleClick('Saturday')}
                        >
                          Saturday
                        </Button>
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col lg="12">
                        <div className="d-flex align-items-center mt-4">
                          <Button className="mr-2" color="primary" size="sm" onClick={() => setIsOpenPtoTypess(true)}>
                            <i className="fas fa-plus" />
                          </Button>
                          <h3 className="p-0 m-0">PTO Types</h3>
                        </div>
                      </Col>

                      <Col lg="12" className="d-flex mt-2">
                        {selectedPtoTypes.map((type) => {
                          return (
                            <div
                              key={`pto-type-${type}`}
                              className="d-flex align-items-center justify-content-center mt-2 mr-2"
                              style={{ border: '1px solid #dddddd', padding: '20px', width: '170px' }}
                            >
                              <h5>{type}</h5>
                            </div>
                          );
                        })}
                      </Col>
                    </Row>
                    <Row className="mt-6">
                      <Col lg="6" className="d-flex">
                        <Label className="mr-4">
                          <strong>Accrual Method:</strong>
                        </Label>
                        <div className="d-flex flex-column flex-wrap">
                          <div className="form-check form-check-inline mr-3">
                            <Input
                              className="form-check-input"
                              type="radio"
                              name="location"
                              id="days-per-year"
                              value={'Days per year'}
                              checked={accrualMethod === 'Days per year'}
                              onChange={(e) => setAccrualMethod(e.target.value)}
                              required
                            />
                            <Label className="form-check-label mr-2" htmlFor="days-per-year">
                              Days per year
                            </Label>
                          </div>
                        </div>
                      </Col>
                      <Col lg="6">
                        <FormGroup className="d-flex align-items-center">
                          <label
                            className="form-control-label mr-2"
                            htmlFor="input-description-1"
                            style={{ whiteSpace: 'nowrap' }}
                          >
                            <strong>Accrual Frequency:</strong>
                          </label>
                          <Input
                            type="select"
                            value={accrualFrequency}
                            onChange={(event) => setAccrualFrequency(event.target.value)}
                            required
                          >
                            <option value={''}></option>
                            <option value={'Bi-weekly'}>Bi-weekly</option>
                            <option value={'Monthly'}>Monthly</option>
                            <option value={'Weekly'}>Weekly</option>
                            <option value={'Yearly'}>Yearly</option>
                            <option value={'Quarterly'}>Quarterly</option>
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mt-4 mb-4">
                      <Col lg="6">
                        <FormGroup className="d-flex align-items-center">
                          <label
                            className="form-control-label mr-2"
                            htmlFor="input-description-1"
                            style={{ whiteSpace: 'nowrap' }}
                          >
                            <strong>Accrual Rate:</strong>
                          </label>
                          <Input
                            type="text"
                            placeholder="0"
                            style={{ width: '70px' }}
                            value={accrualRateDaysPerYear}
                            onChange={(event) => {
                              const input = event.target.value;
                              const numbersOnly = input.replace(/\D/g, '');
                              setAccrualRateDaysPerYear(numbersOnly ? parseInt(numbersOnly) : 0);
                            }}
                            required
                          />
                          <h4 className="m-0 ml-2" style={{ whiteSpace: 'nowrap' }}>
                            days per year
                          </h4>
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup className="d-flex align-items-center">
                          <label
                            className="form-control-label mr-2"
                            htmlFor="input-description-1"
                            style={{ whiteSpace: 'nowrap' }}
                          >
                            <strong>Timing of Accrual:</strong>
                          </label>
                          <Input
                            type="select"
                            value={timingOfAccrual}
                            onChange={(event) => setTimingOfAccrual(event.target.value)}
                            required
                          >
                            <option value={''}></option>
                            <option value={'First day of the period'}>First day of the period</option>
                            <option value={'Last day of Period'}>Last day of Period</option>
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>

                    {isTenureAccrualRateOpen && (
                      <>
                        <Row
                          className="d-flex align-items-center"
                          style={{
                            borderTop: '1px solid #307cdf',
                            padding: '20px 5px',
                          }}
                        >
                          <Col lg="3">
                            <label>
                              <strong>Years Worked</strong>
                            </label>
                            <Input type="text" placeholder="Years" value={1} disabled />
                          </Col>

                          <Col lg="4">
                            <label>
                              <strong>Increase Accrual Rate By</strong>
                            </label>
                            <div className="d-flex align-items-center">
                              <Input
                                type="text"
                                placeholder="Years"
                                value={tenureAccrualRateFirstYear}
                                onChange={(event) => {
                                  if (!isNaN(parseInt(event.target.value))) {
                                    setTenureAccrualRateFirstYear(parseInt(event.target.value));
                                  }

                                  if (!event.target.value) {
                                    setTenureAccrualRateFirstYear(0);
                                  }
                                }}
                                required
                              />
                              <span className="ml-3" style={{ whiteSpace: 'nowrap' }}>
                                {accrualMethod}
                              </span>
                            </div>
                          </Col>

                          <Col lg="4">
                            <label>
                              <strong>Total Accrual Rate</strong>
                            </label>
                            <div className="d-flex align-items-center">
                              <span className="ml-3" style={{ whiteSpace: 'nowrap' }}>
                                <strong>{accrualRateDaysPerYear + tenureAccrualRateFirstYear}</strong> {accrualMethod}
                              </span>
                            </div>
                          </Col>
                        </Row>
                        <Row
                          className="d-flex align-items-center"
                          style={{
                            borderTop: '1px solid #307cdf',
                            padding: '20px 5px',
                          }}
                        >
                          <Col lg="3">
                            <label>
                              <strong>Years Worked</strong>
                            </label>
                            <Input type="text" placeholder="Years" value={2} disabled />
                          </Col>

                          <Col lg="4">
                            <label>
                              <strong>Increase Accrual Rate By</strong>
                            </label>
                            <div className="d-flex align-items-center">
                              <Input
                                type="text"
                                placeholder="Years"
                                value={tenureAccrualRateSecondYear}
                                onChange={(event) => {
                                  if (!isNaN(parseInt(event.target.value))) {
                                    setTenureAccrualRateSecondYear(parseInt(event.target.value));
                                  }

                                  if (!event.target.value) {
                                    setTenureAccrualRateSecondYear(0);
                                  }
                                }}
                                required
                              />
                              <span className="ml-3" style={{ whiteSpace: 'nowrap' }}>
                                {accrualMethod}
                              </span>
                            </div>
                          </Col>

                          <Col lg="4">
                            <label>
                              <strong>Total Accrual Rate</strong>
                            </label>
                            <div className="d-flex align-items-center">
                              <span className="ml-3" style={{ whiteSpace: 'nowrap' }}>
                                <strong>
                                  {accrualRateDaysPerYear + tenureAccrualRateFirstYear + tenureAccrualRateSecondYear}
                                </strong>{' '}
                                {accrualMethod}
                              </span>
                            </div>
                          </Col>
                        </Row>
                        <Row
                          className="d-flex align-items-center"
                          style={{
                            borderTop: '1px solid #307cdf',
                            padding: '20px 5px',
                          }}
                        >
                          <Col lg="3">
                            <label>
                              <strong>Years Worked</strong>
                            </label>
                            <Input type="text" placeholder="Years" value={3} disabled />
                          </Col>

                          <Col lg="4">
                            <label>
                              <strong>Increase Accrual Rate By</strong>
                            </label>
                            <div className="d-flex align-items-center">
                              <Input
                                type="text"
                                placeholder="Years"
                                value={tenureAccrualRateThirdYear}
                                onChange={(event) => {
                                  if (!isNaN(parseInt(event.target.value))) {
                                    setTenureAccrualRateThirdYear(parseInt(event.target.value));
                                  }

                                  if (!event.target.value) {
                                    setTenureAccrualRateThirdYear(0);
                                  }
                                }}
                                required
                              />
                              <span className="ml-3" style={{ whiteSpace: 'nowrap' }}>
                                {accrualMethod}
                              </span>
                            </div>
                          </Col>

                          <Col lg="4">
                            <label>
                              <strong>Total Accrual Rate</strong>
                            </label>
                            <div className="d-flex align-items-center">
                              <span className="ml-3" style={{ whiteSpace: 'nowrap' }}>
                                <strong>
                                  {accrualRateDaysPerYear +
                                    tenureAccrualRateFirstYear +
                                    tenureAccrualRateSecondYear +
                                    tenureAccrualRateThirdYear}
                                </strong>{' '}
                                {accrualMethod}
                              </span>
                            </div>
                          </Col>
                        </Row>
                        <Row
                          className="d-flex align-items-center"
                          style={{
                            borderTop: '1px solid #307cdf',
                            padding: '20px 5px',
                          }}
                        >
                          <Col lg="3">
                            <label>
                              <strong>Years Worked</strong>
                            </label>
                            <Input type="text" placeholder="Years" value={4} disabled />
                          </Col>

                          <Col lg="4">
                            <label>
                              <strong>Increase Accrual Rate By</strong>
                            </label>
                            <div className="d-flex align-items-center">
                              <Input
                                type="text"
                                placeholder="Years"
                                value={tenureAccrualRateFourthYear}
                                onChange={(event) => {
                                  if (!isNaN(parseInt(event.target.value))) {
                                    setTenureAccrualRateFourthYear(parseInt(event.target.value));
                                  }

                                  if (!event.target.value) {
                                    setTenureAccrualRateFourthYear(0);
                                  }
                                }}
                                required
                              />
                              <span className="ml-3" style={{ whiteSpace: 'nowrap' }}>
                                {accrualMethod}
                              </span>
                            </div>
                          </Col>

                          <Col lg="4">
                            <label>
                              <strong>Total Accrual Rate</strong>
                            </label>
                            <div className="d-flex align-items-center">
                              <span className="ml-3" style={{ whiteSpace: 'nowrap' }}>
                                <strong>
                                  {accrualRateDaysPerYear +
                                    tenureAccrualRateFirstYear +
                                    tenureAccrualRateSecondYear +
                                    tenureAccrualRateThirdYear +
                                    tenureAccrualRateFourthYear}
                                </strong>{' '}
                                {accrualMethod}
                              </span>
                            </div>
                          </Col>
                        </Row>
                        <Row
                          className="d-flex align-items-center"
                          style={{
                            borderTop: '1px solid #307cdf',
                            padding: '20px 5px',
                          }}
                        >
                          <Col lg="3">
                            <label>
                              <strong>Years Worked</strong>
                            </label>
                            <Input type="text" placeholder="Years" value={5} disabled />
                          </Col>

                          <Col lg="4">
                            <label>
                              <strong>Increase Accrual Rate By</strong>
                            </label>
                            <div className="d-flex align-items-center">
                              <Input
                                type="text"
                                placeholder="Years"
                                value={tenureAccrualRateFifthYear}
                                onChange={(event) => {
                                  if (!isNaN(parseInt(event.target.value))) {
                                    setTenureAccrualRateFifthYear(parseInt(event.target.value));
                                  }

                                  if (!event.target.value) {
                                    setTenureAccrualRateFifthYear(0);
                                  }
                                }}
                                required
                              />
                              <span className="ml-3" style={{ whiteSpace: 'nowrap' }}>
                                {accrualMethod}
                              </span>
                            </div>
                          </Col>

                          <Col lg="4">
                            <label>
                              <strong>Total Accrual Rate</strong>
                            </label>
                            <div className="d-flex align-items-center">
                              <span className="ml-3" style={{ whiteSpace: 'nowrap' }}>
                                <strong>
                                  {accrualRateDaysPerYear +
                                    tenureAccrualRateFirstYear +
                                    tenureAccrualRateSecondYear +
                                    tenureAccrualRateThirdYear +
                                    tenureAccrualRateFourthYear +
                                    tenureAccrualRateFifthYear}
                                </strong>{' '}
                                {accrualMethod}
                              </span>
                            </div>
                          </Col>
                        </Row>
                      </>
                    )}
                    <Row style={isTenureAccrualRateOpen ? { borderTop: '1px solid #307cdf' } : {}}>
                      <Button
                        className="mt-4"
                        style={{
                          border: 'none',
                          boxShadow: 'none',
                          background: 'none',
                          color: '#0095c2',
                        }}
                        onClick={handleAddTenureAccrualRate}
                      >
                        <i className="fas fa-plus" />{' '}
                        <span>
                          {isTenureAccrualRateOpen ? 'Remove a Tenure Accrual Rate' : 'Add a Tenure Accrual Rate'}
                        </span>
                      </Button>
                    </Row>

                    <Row className="d-flex justify-content-end">
                      <Button color="warning">Continue</Button>
                    </Row>
                  </Form>
                </div>
              )}
              {(step === 1 || step === 2) && (
                <div className="p-5 pt-2">
                  <h2>Rules</h2>
                  <Form onSubmit={handleChangeStep}>
                    <Row className="mt-3">
                      <Col lg="12" className="d-flex align-items-center">
                        <label style={{ whiteSpace: 'nowrap', margin: 0, width: '220px' }}>
                          <strong>Maximum Balance</strong>
                        </label>
                        <span className="mr-2" style={{ whiteSpace: 'nowrap' }}>
                          Workers cannot have a balance greater than
                        </span>
                        <Input
                          type="text"
                          className="mr-2"
                          style={{ maxWidth: '70px' }}
                          value={maximumBalanceHours}
                          onChange={(event) => {
                            const input = event.target.value;
                            const numbersOnly = input.replace(/\D/g, '');
                            setMaximumBalanceHours(numbersOnly ? parseInt(numbersOnly) : 0);
                          }}
                          disabled={isDontMaximumBalanceHours}
                        />
                        <span className="mr-2" style={{ whiteSpace: 'nowrap' }}>
                          Vacation hours at any time
                        </span>
                      </Col>
                    </Row>
                    <Row className="mt-1" style={{ marginLeft: '220px' }}>
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="checkbox"
                            style={{ transform: 'scale(1.5)' }}
                            checked={isDontMaximumBalanceHours}
                            onChange={() => {
                              if (!isDontMaximumBalanceHours) {
                                setMaximumBalanceHours(0);
                              }

                              setIsDontMaximumBalanceHours(!isDontMaximumBalanceHours);
                            }}
                          />{' '}
                          Don't limit balances
                        </Label>
                      </FormGroup>
                    </Row>
                    <Row className="mt-6">
                      <Col lg="12" className="d-flex align-items-center">
                        <label style={{ whiteSpace: 'nowrap', margin: 0, width: '220px' }}>
                          <strong>Annual Reset</strong>
                        </label>
                        <span className="mr-2" style={{ whiteSpace: 'nowrap' }}>
                          The time off balance resets to 0 days on
                        </span>
                        <Input
                          type="text"
                          className="mr-2"
                          style={{ maxWidth: '210px' }}
                          value={'a policy year start date of'}
                          disabled
                        />
                        <Input
                          type="select"
                          value={annualResetDate}
                          onChange={(event) => setAnnualResetDate(event.target.value)}
                          style={{ maxWidth: '185px' }}
                        >
                          <option value={''}>{''}</option>
                          <option value={'first day of year'}>first day of year</option>
                          <option value={'last day of year'}>last day of year</option>
                        </Input>
                      </Col>
                    </Row>

                    <Row className="mt-6">
                      <Col lg="12" className="d-flex align-items-center">
                        <label style={{ whiteSpace: 'nowrap', margin: 0, width: '220px' }}>
                          <strong>Carryover Cap</strong>
                        </label>
                        <span className="mr-2" style={{ whiteSpace: 'nowrap' }}>
                          Workers can carry over up to
                        </span>
                        <Input
                          type="text"
                          className="mr-2"
                          style={{ maxWidth: '70px' }}
                          value={carryoverHours}
                          onChange={(event) => {
                            const input = event.target.value;
                            const numbersOnly = input.replace(/\D/g, '');
                            setCarryoverHours(numbersOnly ? parseInt(numbersOnly) : 0);
                          }}
                          disabled={isDontLimitCarryover}
                        />
                        <span className="mr-2" style={{ whiteSpace: 'nowrap' }}>
                          hours from one year to the next on
                        </span>
                        <Input
                          type="text"
                          className="mr-2"
                          style={{ maxWidth: '210px' }}
                          value={'a policy year start date of'}
                          disabled
                        />
                        <InputGroup className="input-group-alternative" style={{ maxWidth: '210px' }}>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-calendar-grid-58" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <ReactDatetime
                            inputProps={{
                              placeholder: '',
                            }}
                            onChange={(_) => {
                              // console.log(`${newValue}`);
                            }}
                            timeFormat={false}
                            dateFormat={'MM/DD/yyyy'}
                            closeOnSelect={true}
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                    <Row className="mt-1" style={{ marginLeft: '220px' }}>
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="checkbox"
                            style={{ transform: 'scale(1.5)' }}
                            checked={isDontLimitCarryover}
                            onChange={() => {
                              if (!isDontLimitCarryover) {
                                setCarryoverHours(0);
                              }

                              setIsDontLimitCarryover(!isDontLimitCarryover);
                            }}
                          />{' '}
                          Don't limit the number of carryover hours
                        </Label>
                      </FormGroup>
                    </Row>

                    <Row className="mt-6">
                      <Col lg="12" className="d-flex align-items-center">
                        <label style={{ whiteSpace: 'nowrap', margin: 0, width: '220px' }}>
                          <strong>Negative Balances</strong>
                        </label>
                        <span className="mr-3" style={{ whiteSpace: 'nowrap' }}>
                          People can request Vacation that makes their balance negative up to
                        </span>
                        <Input
                          type="text"
                          style={{ maxWidth: '70px' }}
                          value={negativeBalanceHours}
                          onChange={(event) => {
                            const input = event.target.value;
                            const numbersOnly = input.replace(/\D/g, '');
                            setNegativeBalanceHours(numbersOnly ? parseInt(numbersOnly) : 0);
                          }}
                        />
                        <span className="ml-3" style={{ whiteSpace: 'nowrap' }}>
                          hours.
                        </span>
                      </Col>
                    </Row>

                    <Row className="mt-6">
                      <Col lg="12" className="d-flex align-items-center">
                        <label style={{ whiteSpace: 'nowrap', margin: 0, width: '220px' }}>
                          <strong>Waiting Periods</strong>
                        </label>
                        <span className="mr-3" style={{ whiteSpace: 'nowrap' }}>
                          New hires can request time off after
                        </span>
                        <Input
                          type="text"
                          style={{ maxWidth: '70px' }}
                          value={newHireRequestPeriod}
                          onChange={(event) => {
                            const input = event.target.value;
                            const numbersOnly = input.replace(/\D/g, '');
                            setNewHireRequestPeriod(numbersOnly ? parseInt(numbersOnly) : 0);
                          }}
                        />
                        <span className="ml-3" style={{ whiteSpace: 'nowrap' }}>
                          days.
                        </span>
                      </Col>
                    </Row>
                    <Row className="mt-1" style={{ marginLeft: '220px' }}>
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="checkbox"
                            style={{ transform: 'scale(1.5)' }}
                            checked={accrueTimeDuringPeriod}
                            onChange={() => setAccrueTimeDuringPeriod(!accrueTimeDuringPeriod)}
                          />
                          Accrue time off during this waiting period.
                        </Label>
                      </FormGroup>
                    </Row>
                    <Row className="mt-1" style={{ marginLeft: '220px' }}>
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="checkbox"
                            style={{ transform: 'scale(1.5)' }}
                            checked={payWorkerOnCompanyHoliday}
                            onChange={() => setPayWorkerOnCompanyHoliday(!payWorkerOnCompanyHoliday)}
                          />{' '}
                          Don't pay workers on company holidays during this waiting period
                        </Label>
                      </FormGroup>
                    </Row>

                    <div className="d-flex align-items-center justify-content-end mt-4">
                      <Button onClick={() => setStep(0)}>Previous</Button>
                      <Button color="warning">Continue</Button>
                    </div>
                  </Form>
                </div>
              )}
              {step === 3 && (
                <div className="p-5 pt-2">
                  <h1 className="text-center">Who is covered by this policy?</h1>
                  <p className="text-center mt-4">
                    Select the individuals who fall under these polices from the list below. People can only be assigned
                    to one set of polices.
                  </p>

                  <div className="mt-4 p-2">
                    <div className="d-flex align-items-center">
                      {/* <Input type="checkbox" style={{ transform: 'scale(1.5)', width: '61px' }} /> */}
                      <div className="d-flex justify-content-start">
                        <Input
                          type="text"
                          placeholder="Search by Name"
                          style={{ width: '250px' }}
                          value={searchStaffName}
                          onChange={(event: any) => setSearchStaffName(event.target.value)}
                        />
                      </div>
                    </div>
                    <Table className="mt-2" responsive>
                      <tbody>
                        <tr>
                          <th></th>
                          <th>Name</th>
                          <th>Currrent Policy</th>
                          <th>Location</th>
                          <th>Department</th>
                          <th>Employment Type</th>
                          <th>Compensation</th>
                        </tr>
                        {employees?.map((employee) => (
                          <tr key={`employee-${employee.id}`}>
                            <td>
                              <Input type="checkbox" style={{ transform: 'scale(1.5)' }} />
                            </td>
                            <td>{`${employee.user?.firstName} ${employee.user?.lastName}`}</td>
                            <td>{employee.ptoPolicy?.name || '-'}</td>
                            <td>{employee.user?.addresses[0]?.city || '-'}</td>
                            <td>{employee.department?.name}</td>
                            <td>
                              {employee.workday === 'FT' && 'Full Time'}
                              {employee.workday === 'PT' && 'Part Time'}
                            </td>
                            <td>{employee.paymentFrequency}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                  <div className="d-flex align-items-center justify-content-end mt-6">
                    {/* <Button onClick={() => setStep(1)}>Previous</Button> */}
                    <Link to={'/employee-settings'}>
                      <Button color="warning">Save & Continue</Button>
                    </Link>
                  </div>
                </div>
              )}
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal className="modal-dialog-centered modal-xl" isOpen={isOpenHoliday} toggle={() => setIsOpenHoliday(false)}>
        <div className="p-2">
          <Table responsive>
            <tbody>
              <tr>
                <th colSpan={3}>Holiday Calendar</th>
                <th>
                  <Button
                    style={{ border: 'none', boxShadow: 'none', width: '20px', padding: 0, background: 'none' }}
                    onClick={handleDecriseSelectedYear}
                  >
                    <i className="fas fa-chevron-left" />
                  </Button>
                  {selectedYear}
                  <Button
                    style={{ border: 'none', boxShadow: 'none', width: '20px', padding: 0, background: 'none' }}
                    onClick={handleIncriseSelectedYear}
                  >
                    <i className="fas fa-chevron-right" />
                  </Button>
                </th>
              </tr>
              <tr>
                <th className="d-flex justify-content-center">
                  <Input
                    type="checkbox"
                    style={{ transform: 'scale(1.5)' }}
                    checked={selectedHolidays.length === holidays.length}
                    onChange={() => {
                      handleSelectAllHolidays();
                    }}
                  />
                </th>
                <th>Holiday Name</th>
                <th>Observed Date</th>
                <th></th>
              </tr>
              {holidays.map((holiday, index) => (
                <tr key={`holiday-${index}`}>
                  <td className="d-flex justify-content-center">
                    <Input
                      type="checkbox"
                      style={{ transform: 'scale(1.5)' }}
                      onChange={() => handleSelectHoliday(holiday)}
                      checked={selectedHolidays.some((holidayItem) => holidayItem.id === holiday.id)}
                    />
                  </td>
                  <td>{holiday.name}</td>
                  <td>
                    {moment(holiday.startDate).format('MMMM D')}{' '}
                    {holiday.endDate !== holiday.startDate ? ` - ${moment(holiday.endDate).format('MMMM D')}` : ''}
                  </td>
                  <td>
                    <Button style={{ border: 'none', boxShadow: 'none', background: 'none', margin: 0 }}>
                      <i className="fas fa-pencil-alt text-mutted" style={{ cursor: 'pointer' }} />
                    </Button>
                    <Button
                      style={{ border: 'none', boxShadow: 'none', background: 'none', margin: 0 }}
                      onClick={(e) => confirmDeleteHoliday(e, holiday.id)}
                    >
                      <i className="far fa-trash-alt text-mutted" style={{ cursor: 'pointer' }}></i>
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {!showHolidayForm && (
            <Button
              style={{ border: 'none', boxShadow: 'none', background: 'none', color: '#0095c2' }}
              onClick={() => setShowHolidayForm(true)}
            >
              <i className="fas fa-plus" /> Add a Holiday
            </Button>
          )}

          {showHolidayForm && (
            <Form className="p-4" onSubmit={handleSubmitHolidayForm}>
              <Row>
                <Col lg="12">
                  <label>Name</label>
                  <Input type="text" value={holidayName} onChange={(e) => setHolidayName(e.target.value)} />
                </Col>
              </Row>
              <Row className="d-flex align-items-center mt-2">
                <Col lg="4">
                  <label>Start Date</label>
                  <InputGroup className="custom-datepicker" style={{ width: '100%' }}>
                    <ReactDatetime
                      inputProps={{
                        placeholder: 'Start Date',
                      }}
                      onChange={(newValue) => {
                        setHolidayStartDate(`${newValue}`);
                        setHolidayEndDate(`${newValue}`);
                      }}
                      timeFormat={false}
                      value={holidayStartDate ? `${moment(holidayStartDate).format('MM/DD/YYYY')}` : ''}
                      dateFormat={'MM/DD/yyyy'}
                      closeOnSelect={true}
                    />
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-calendar-grid-58" />
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </Col>

                <Col lg="4">
                  <label>End Date</label>
                  <InputGroup className="custom-datepicker">
                    <ReactDatetime
                      inputProps={{
                        placeholder: 'End Date',
                      }}
                      onChange={(newValue) => {
                        setHolidayEndDate(`${newValue}`);
                      }}
                      timeFormat={false}
                      value={holidayEndDate ? `${moment(holidayEndDate).format('MM/DD/YYYY')}` : ''}
                      dateFormat={'MM/DD/yyyy'}
                      closeOnSelect={true}
                    />
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-calendar-grid-58" />
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </Col>

                <Col lg="4" className="pt-4">
                  <FormGroup>
                    <Row>
                      <Col>
                        <label className="form-control-label">Recurring Holiday</label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <label className="custom-toggle">
                          <Input
                            type="checkbox"
                            onChange={(e) => setIsRecurringHoliday(e.target.checked)}
                            checked={isRecurringHoliday}
                          >
                            <option className="custom-toggle-slider rounded-circle" />
                          </Input>
                          <span className="custom-toggle-slider rounded-circle" />
                        </label>
                      </Col>
                    </Row>
                  </FormGroup>
                </Col>
              </Row>

              <Row className="mt-2 d-flex justify-content-end">
                <Button
                  onClick={(event) => {
                    event.preventDefault();
                    setShowHolidayForm(false);
                  }}
                >
                  Cancel
                </Button>
                <Button color="warning">Add</Button>
              </Row>
            </Form>
          )}
        </div>
      </Modal>

      <Modal
        className="modal-dialog-centered modal-xl"
        isOpen={isOpenptoTypess}
        toggle={() => setIsOpenPtoTypess(false)}
      >
        <div className="p-2">
          <Table responsive>
            <tbody>
              <tr>
                <th className="d-flex justify-content-center">
                  <Input
                    type="checkbox"
                    style={{ transform: 'scale(1.5)' }}
                    checked={selectAllptoTypess}
                    onChange={toggleselectAllptoTypess}
                  />
                </th>
                <th>Reason</th>
                <th>Count as (PTO Type)</th>
                <th>Is Paid?</th>
                <th></th>
              </tr>
              {ptoTypes.map((type, index) => (
                <tr key={`pto-type-tr-${index}`}>
                  <td className="d-flex justify-content-center">
                    <Input
                      type="checkbox"
                      style={{ transform: 'scale(1.5)' }}
                      checked={!!selectedPtoTypes.find((s) => s === type.reason)}
                      onChange={() => toggleptoTypessCheckbox(index)}
                    />
                  </td>
                  <td>{type.reason}</td>
                  <td>{type.isCounted ? 'Counted' : 'Not Counted'}</td>
                  <td>{type.isPaid ? 'Yes' : 'No'}</td>
                  <td>
                    {/* <i className="fas fa-pencil-alt text-mutted mr-3" style={{ cursor: 'pointer' }} /> */}
                    {!type.id ? (
                      <></>
                    ) : (
                      <i
                        className="far fa-trash-alt text-mutted"
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => handleDeletePtoTypes(e, type?.id || '')}
                      ></i>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {!showPtoTypesForm && (
            <Button
              style={{ border: 'none', boxShadow: 'none', background: 'none', color: '#0095c2' }}
              onClick={() => setshowPtoTypesForm(true)}
            >
              <i className="fas fa-plus" /> Add a Reason
            </Button>
          )}

          {showPtoTypesForm && (
            <Form className="p-4" onSubmit={handleSubmitPtoTypesForm}>
              <Row>
                <Col lg="8">
                  <label>Reason</label>
                  <Input type="text" value={ptoReason} onChange={(e) => setPtoReason(e.target.value)} />
                </Col>

                <Col lg="2">
                  <FormGroup>
                    <Row>
                      <Col>
                        <label className="form-control-label">Is Counted?</label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <label className="custom-toggle">
                          <Input
                            type="checkbox"
                            onChange={(e) => setIsPtoTypeCounted(e.target.checked)}
                            checked={isPtoTypeCounted}
                          >
                            <option className="custom-toggle-slider rounded-circle" />
                          </Input>
                          <span className="custom-toggle-slider rounded-circle" />
                        </label>
                      </Col>
                    </Row>
                  </FormGroup>
                </Col>

                <Col lg="2">
                  <FormGroup>
                    <Row>
                      <Col>
                        <label className="form-control-label">Is Paid?</label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <label className="custom-toggle">
                          <Input
                            type="checkbox"
                            onChange={(e) => setIsPtoTypePaid(e.target.checked)}
                            checked={isPtoTypePaid}
                          >
                            <option className="custom-toggle-slider rounded-circle" />
                          </Input>
                          <span className="custom-toggle-slider rounded-circle" />
                        </label>
                      </Col>
                    </Row>
                  </FormGroup>
                </Col>
              </Row>

              <Row className="mt-2 d-flex justify-content-end">
                <Button
                  onClick={(event) => {
                    event.preventDefault();
                    setshowPtoTypesForm(false);
                  }}
                >
                  Cancel
                </Button>
                <Button color="warning">Add</Button>
              </Row>
            </Form>
          )}
        </div>
      </Modal>

      <Modal className="modal-dialog-centered modal-sm" isOpen={step === 2} toggle={() => setStep(1)}>
        <div className="p-4" style={{ background: '#eeebff' }}>
          <h3 className="text-muted text-center">VACATION</h3>
        </div>

        <div
          className="d-flex flex-column align-items-center justify-content-center p-4"
          style={{ borderBottom: '2px solid #cccccc' }}
        >
          <FontAwesomeIcon icon={faMountainCity} style={{ fontSize: '45px' }} className="mb-4" />
          <h4>Vacation Rules</h4>
        </div>

        <div className="py-2 px-4" style={{ borderBottom: '2px solid #cccccc' }}>
          {!annualResetDate ? (
            <div className="d-flex align-items-center mb-2">
              <FontAwesomeIcon icon={faTimes} />
              <p className="m-0 ml-2 font-weight-bold">Annual Reset </p>
            </div>
          ) : (
            <>
              <div className="d-flex align-items-center">
                <i className="fas fa-check" style={{ color: '#02bb21' }} />
                <p className="m-0 ml-2 font-weight-bold">Annual Reset </p>
              </div>
              <p className="mt-0 ml-2 mb-2 text-sm">
                The time off balance resets to 0 days on a policy year start date of {annualResetDate}
              </p>
            </>
          )}

          {!isDontLimitCarryover ? (
            <div className="d-flex align-items-center mb-2">
              <FontAwesomeIcon icon={faTimes} />
              <p className="m-0 ml-2 font-weight-bold">Carryover Cap</p>
            </div>
          ) : (
            <>
              <div className="d-flex align-items-center mb-2">
                <i className="fas fa-check" style={{ color: '#02bb21' }} />
                <p className="m-0 ml-2 font-weight-bold">Carryover Cap</p>
              </div>
              {isDontLimitCarryover && (
                <>
                  <p className="mt-0 ml-2 mb-2 text-sm">Don't limit the number of carryover hours</p>
                </>
              )}
            </>
          )}

          {!negativeBalanceHours || negativeBalanceHours === 0 ? (
            <div className="d-flex align-items-center mb-2">
              <FontAwesomeIcon icon={faTimes} />
              <p className="m-0 ml-2 font-weight-bold">Negative Balance</p>
            </div>
          ) : (
            <>
              <div className="d-flex align-items-center mb-2">
                <i className="fas fa-check" style={{ color: '#02bb21' }} />
                <p className="m-0 ml-2 font-weight-bold">Negative Balance</p>
              </div>
              {negativeBalanceHours !== 0 && (
                <>
                  <p className="mt-0 ml-2 mb-2 text-sm">
                    People can request Vacation that makes their balance negative up to {negativeBalanceHours} hours.
                  </p>
                </>
              )}
            </>
          )}

          {(!newHireRequestPeriod || newHireRequestPeriod === 0) &&
          !accrueTimeDuringPeriod &&
          !payWorkerOnCompanyHoliday ? (
            <div className="d-flex align-items-center mb-2">
              <FontAwesomeIcon icon={faTimes} />
              <p className="m-0 ml-2 font-weight-bold">Waiting Period</p>
            </div>
          ) : (
            <>
              <div className="d-flex align-items-center mb-2">
                <i className="fas fa-check" style={{ color: '#02bb21' }} />
                <p className="m-0 ml-2 font-weight-bold">Waiting Period</p>
              </div>
              {newHireRequestPeriod ? (
                <>
                  <p className="mt-0 ml-2 mb-2 text-sm">
                    New hires can request time off after {newHireRequestPeriod} days.
                  </p>
                </>
              ) : (
                <></>
              )}

              {accrueTimeDuringPeriod && (
                <>
                  <p className="mt-0 ml-2 mb-2 text-sm">Accrue time off during this waiting period.</p>
                </>
              )}

              {payWorkerOnCompanyHoliday && (
                <>
                  <p className="mt-0 ml-2 mb-2 text-sm">
                    Don't pay workers on company holidays during this waiting period
                  </p>
                </>
              )}
            </>
          )}
        </div>

        <div className="d-flex flex-column justify-content-center p-4" style={{ borderBottom: '2px solid #cccccc' }}>
          <div className="d-flex">
            <FontAwesomeIcon
              icon={faCommentDots}
              style={{ fontSize: '25px', color: '#ddec00' }}
              className="mb-4 mr-4"
            />
            <div>
              <h4 className="font-weight-bold m-0">Reasons</h4>
              <p className="m-0 text-sm">No Reasons</p>
            </div>
          </div>

          <Button onClick={() => setStep(1)} style={{ boxShadow: 'none', background: 'none', color: '#0b3fcf' }}>
            Edit These Rules
          </Button>
        </div>

        <Form onSubmit={handleSubmitPtoPolicy}>
          <div className="d-flex justify-content-center p-4">
            <Button color="warning">Save & Continue</Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default PtoPolicesForm;
