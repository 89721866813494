/* eslint-disable max-lines */
import { Row, Col, FormGroup, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Context as AuthContext } from '../../../../contexts/AuthContext';
import KidsService from '../../../../services/Kids';
import { toLocaleFormatSlash } from '../../../../helpers/dateFormat';
import { isMobile } from 'react-device-detect';

const ProfileTab = () => {
  const { id: kidId } = useParams<{ id: string }>();
  const { token }: any = useContext(AuthContext);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [birthday, setBirthday] = useState('');
  const [gender, setGender] = useState('');

  useEffect(() => {
    getById();
  }, []);

  useEffect(() => {
    getById();
  }, [kidId]);

  const getById = async () => {
    try {
      const result = await KidsService(token).show(kidId);
      if (result?.data) {
        const user = result?.data;
        setFirstName(user?.firstName || '');
        setLastName(user?.lastName || '');
        setBirthday(user?.birthday ? toLocaleFormatSlash(user?.birthday) : '');
        setGender(user?.gender || '');
      }
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'Invalid user ID');
    }
  };

  return (
    <>
      <Row style={isMobile ? { paddingLeft: 5, paddingRight: 5 } : {}}>
        <Col lg="6" xs="12">
          <FormGroup>
            <label className="form-control-label">First name</label>
            <Input
              className="form-control-alternative"
              placeholder="First name"
              type="text"
              disabled={true}
              value={firstName}
            />
          </FormGroup>
        </Col>
        <Col lg="6" xs="12">
          <FormGroup>
            <label className="form-control-label">Last name</label>
            <Input
              className="form-control-alternative"
              placeholder="Last name"
              type="text"
              disabled={true}
              value={lastName}
            />
          </FormGroup>
        </Col>
        <Col lg="6" xs="12">
          <FormGroup>
            <label className="form-control-label">Gender</label>
            <Input
              className="form-control-alternative"
              placeholder="Gender"
              type="text"
              disabled={true}
              value={gender}
            />
          </FormGroup>
        </Col>
        <Col lg="6" xs="12">
          <FormGroup>
            <label className="form-control-label">Birthday</label>
            <Input
              className="form-control-alternative"
              placeholder="Birthday"
              type="text"
              disabled={true}
              value={birthday}
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default ProfileTab;
