import { Table, Row, Col, Button, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { useContext, useState, useEffect } from 'react';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import KidsRemindersService from '../../../services/KidsReminders';
import { toast } from 'react-toastify';
import moment, { Moment } from 'moment';
import ReactDatetime from 'react-datetime';
import { toLocaleFormatSlash } from '../../../helpers/dateFormat';

type KidsRemindersListProps = {
  kidsId: string;
};

const KidsRemindersList = ({ kidsId }: KidsRemindersListProps) => {
  const { token, currentSchool }: any = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [date, setDate] = useState('');
  const [notes, setNotes] = useState('');
  const [kidsRemindersList, setKidsRemindersList] = useState([] as any[]);

  useEffect(() => {
    listData();
  }, []);

  const listData = async () => {
    try {
      const result = await KidsRemindersService(token).listByKids(kidsId);
      setKidsRemindersList(result.data || []);
    } catch (e: any) {
      console.error(e?.response?.data?.message || 'No reminders found!');
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);

    try {
      await KidsRemindersService(token).create({
        kidsId,
        schoolId: currentSchool.value,
        date,
        notes,
      });
      toast.success(`Saved successfully!`);
      listData();
      setShowForm(false);
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Create error!');
      toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (e: React.FormEvent, id: string) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);

    try {
      await KidsRemindersService(token).delete(id);
      toast.success(`Deleted successfully!`);
      listData();
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Delete error!');
      toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  const validDate = (current: Moment) => {
    const yesterday = moment().subtract(1, 'day');
    return current.isAfter(yesterday);
  };

  return (
    <>
      <div className="pt-0">
        <Table className="align-items-center table-flush" responsive>
          <tbody>
            <tr>
              <th>Date</th>
              <th>Notes</th>
              <th>Action</th>
            </tr>
            {kidsRemindersList?.length === 0 && (
              <tr>
                <td colSpan={3} className="text-center">
                  Data not found
                </td>
              </tr>
            )}
            {kidsRemindersList.map((s: any) => (
              <tr key={`kidsRemindersList-modal-${s.id}`}>
                <td>{toLocaleFormatSlash(s.date)}</td>
                <td>{s.notes}</td>
                <td>
                  <i
                    className="far fa-trash-alt text-danger"
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => handleDelete(e, s.id)}
                  ></i>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Row>
          {!showForm && (
            <Col>
              <Button
                size="sm"
                className="float-right"
                onClick={() => {
                  setShowForm(true);
                }}
              >
                + Reminder
              </Button>
            </Col>
          )}
          {showForm && (
            <Col>
              <Row>
                <Col lg="4">
                  <FormGroup>
                    <label className="form-control-label">Date</label>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-calendar-grid-58" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <ReactDatetime
                        inputProps={{
                          placeholder: 'Date',
                        }}
                        onChange={(newValue) => {
                          setDate(`${newValue}`);
                        }}
                        timeFormat={false}
                        value={`${date}`}
                        dateFormat={'MM/DD/yyyy'}
                        isValidDate={validDate}
                        closeOnSelect={true}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col lg="8">
                  <FormGroup>
                    <label className="form-control-label">Notes</label>
                    <Input onChange={(e) => setNotes(e.target.value)} />
                  </FormGroup>
                </Col>
                <Col lg="2">
                  <Button color="primary" size="sm" onClick={handleSubmit} style={{ minWidth: '100px' }}>
                    Save
                  </Button>
                  <Button
                    color="secondary"
                    size="sm"
                    style={{ minWidth: '100px' }}
                    onClick={(e) => {
                      e.preventDefault();
                      setShowForm(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </div>
    </>
  );
};

export default KidsRemindersList;
