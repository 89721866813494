import http from '../helpers/http';
import { FilteredOptionsInterface, makeQueryParamsForRequest } from '../entities/FilteredOptions';
import moment, { Moment } from 'moment';

const Pto = (token: string) => {
  const headers = {
    'x-access-token': token,
  };

  return {
    ptoPolicyShow: async (id: string) => {
      const result = await http.get(`/pto-policies/${id}`, {
        headers,
      });
      return result.data;
    },
    ptoPolicyCreate: async (body: any) => {
      const result = await http.post(`/pto-policies`, body, { headers });
      return result.data;
    },
    ptoPolicyUpdate: async (id: string, body: any) => {
      const result = await http.put(`/pto-policies/${id}`, body, { headers });
      return result.data;
    },
    ptoPolicyList: async () => {
      const result = await http.get(`/pto-policies`, {
        headers,
      });
      return result.data;
    },
    timeOffRequestShow: async (timeOffRequestId: string) => {
      const result = await http.get(`/time-off-request/${timeOffRequestId}`, {
        headers,
      });
      return result.data;
    },
    timeOffRequestCreate: async (body: any) => {
      const result = await http.post(`/time-off-request`, body, { headers });
      return result.data;
    },
    timeOffRequestUpdate: async (id: string, body: any) => {
      const result = await http.put(`/time-off-request/${id}`, body, { headers });
      return result.data;
    },
    timeOffRequestList: async (schoolId: string, filteredOptions?: FilteredOptionsInterface) => {
      try {
        const params = makeQueryParamsForRequest(filteredOptions);
        const result = await http.get(`/time-off-request/${schoolId}`, {
          headers,
          params,
        });
        return result.data;
      } catch (e) {
        console.error(e);
        return [];
      }
    },
    timeOffRequestListByDates: async (
      schoolId: string,
      startAt: Moment,
      endAt: Moment,
      filteredOptions?: FilteredOptionsInterface,
    ) => {
      try {
        const params = {
          ...makeQueryParamsForRequest(filteredOptions),
          ...{ startAt: moment(startAt).format('YYYY-MM-DD'), endAt: moment(endAt).format('YYYY-MM-DD') },
        };
        const result = await http.get(`/time-off-request-by-period/${schoolId}`, {
          headers,
          params,
        });
        return result.data;
      } catch (e) {
        console.error(e);
        return [];
      }
    },
    timeOffRequestListApprovedThisMonth: async (schoolId: string) => {
      try {
        const result = await http.get(`/time-off-request/${schoolId}/approved-this-month`, {
          headers,
        });
        return result.data;
      } catch (e) {
        console.error(e);
        return [];
      }
    },
    timeOffRequestApprove: async (id: string) => {
      const result = await http.patch(
        `/time-off-request/${id}/approve`,
        {},
        {
          headers,
        },
      );
      return result.data;
    },
    timeOffRequestRepprove: async (id: string) => {
      const result = await http.patch(
        `/time-off-request/${id}/repprove`,
        {},
        {
          headers,
        },
      );
      return result.data;
    },
    delete: async (id: string) => {
      const result = await http.delete(`/pto-policies/${id}`, {
        headers,
      });
      return result.data;
    },
  };
};

export default Pto;
