import moment from 'moment';
import { isMobile } from 'react-device-detect';
import { Modal, Button, Col, Row } from 'reactstrap';

interface PtoRequestDetailProps {
  isOpen: boolean;
  toggle: () => void;
  data: any;
  canEdit: boolean;
  clickOnEdit: (data: any) => void;
  clickOnApprove: (data: any) => void;
  clickOnReprove: (data: any) => void;
}

const PtoRequestDetail = ({
  isOpen,
  toggle,
  data,
  canEdit,
  clickOnEdit,
  clickOnApprove,
  clickOnReprove,
}: PtoRequestDetailProps) => {
  return (
    <Modal className="modal-dialog-centered" isOpen={isOpen} toggle={toggle} size={'lg'}>
      <div className="modal-header pt-4 pb-0 px-5">
        <div className="d-flex align-items-center">
          <div style={{ width: '35px', height: '35px', borderRadius: '50%', overflow: 'hidden' }}>
            <img
              src={data?.user?.picture}
              alt="User Profile"
              className="img-fluid"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </div>
          <span className="ml-2">{`${data?.user?.firstName || '-'} ${data?.user?.lastName || '-'}`}</span>
        </div>
        <div className="d-flex p-1">
          <a onClick={toggle} className="cursor-pointer" style={{ color: '#525f7f' }}>
            <i className="fas fa-times" />
          </a>
        </div>
      </div>
      <div className="modal-body px-5 pt-0">
        <hr />
        <Row>
          <Col xs="6" md="6">
            <h4 className="p-0 m-0">Status</h4>
          </Col>
          <Col xs="6" md="6">
            <div>
              {data?.status === 'PENDDING' && (
                <div style={{ border: '1px solid #d4d110', padding: '4px 8px', borderRadius: '3px', maxWidth: 108 }}>
                  <span style={{ color: '#d4d110', fontWeight: '700' }}>REQUESTED</span>
                </div>
              )}

              {data?.status === 'REPPROVE' && (
                <div style={{ border: '1px solid #d43710', padding: '4px 8px', borderRadius: '3px', maxWidth: 78 }}>
                  <span style={{ color: '#d43710', fontWeight: '700' }}>DENIED</span>
                </div>
              )}

              {data?.status === 'APPROVE' && (
                <div style={{ border: '1px solid #08b408', padding: '4px 8px', borderRadius: '3px', maxWidth: 104 }}>
                  <span style={{ color: '#08b408', fontWeight: '700' }}>APPROVED</span>
                </div>
              )}

              <div className="w-100" style={{ display: 'block' }}>
                <p className="p-0 m-0" style={isMobile ? { fontSize: 11 } : {}}>
                  Requested: {moment(data?.createdAt).utc().format('MMMM D, YYYY')}
                </p>
              </div>
            </div>
          </Col>

          <Col xs="6" md="6" className="mt-2">
            <h4 className="p-0 m-0">Hours</h4>
          </Col>
          <Col xs="6" md="6" className="mt-2">
            <p className="p-0 m-0">{data?.hours || '-'}</p>
          </Col>
          <Col xs="6" md="6" className="mt-2">
            <h4 className="p-0 m-0">Start Date</h4>
          </Col>
          <Col xs="6" md="6" className="mt-2">
            <p className="p-0 m-0">{moment(data?.startDate).utc().format('MMMM D, YYYY') || '-'}</p>
          </Col>
          <Col xs="6" md="6" className="mt-2">
            <h4 className="p-0 m-0">End Date</h4>
          </Col>
          <Col xs="6" md="6" className="mt-2">
            <p className="p-0 m-0">{moment(data?.endDate).utc().format('MMMM D, YYYY') || '-'}</p>
          </Col>
          <Col xs="6" md="6" className="mt-2">
            <h4 className="p-0 m-0">Notes</h4>
          </Col>
          <Col xs="6" md="6" className="mt-2">
            <p className="p-0 m-0">{data?.describe || '-'}</p>
          </Col>
          <Col xs="6" md="6" className="mt-2">
            <h4 className="p-0 m-0">Other Workers Off</h4>
          </Col>
          <Col xs="6" md="6" className="mt-2">
            <p className="p-0 m-0">No one else is off during this time</p>
          </Col>
        </Row>
      </div>
      <div className="modal-footer px-5">
        {canEdit && (
          <>
            <Button
              style={{ background: '#ff7b00', color: '#FFFFFF' }}
              onClick={() => {
                clickOnEdit(data);
              }}
            >
              <i className="fas fa-pen" />
            </Button>
            {data?.status !== 'APPROVE' && (
              <>
                <Button
                  className="btn-success"
                  onClick={() => {
                    clickOnApprove(data);
                  }}
                >
                  <i className="fas fa-check" />
                </Button>
                <Button
                  className="btn-danger"
                  onClick={() => {
                    clickOnReprove(data);
                  }}
                >
                  <i className="fas fa-ban" />
                </Button>
              </>
            )}
          </>
        )}
      </div>
    </Modal>
  );
};

export default PtoRequestDetail;
