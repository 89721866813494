import { formatInTimeZone, format } from 'date-fns-tz';

export const dateFormat = (date: string | Date) => {
  let d = date;
  if (!(d instanceof Date)) {
    d = new Date(date);
  }

  return formatInTimeZone(d, 'America/New_York', 'MM/dd/yyyy hh:mm aaa zzz');
};

export const timeFormatInNY = (date: string | Date) => {
  let d = date;
  if (!(d instanceof Date)) {
    d = new Date(date);
  }

  return formatInTimeZone(d, 'America/New_York', 'hh:mm aaa');
};

export const timeFormat = (date: string | Date) => {
  let d = date;
  if (!(d instanceof Date)) {
    d = new Date(date);
  }

  return format(d, 'hh:mm aaa');
};

export const dateTimeFormat = (date: string | Date) => {
  let d = date;
  if (!(d instanceof Date)) {
    d = new Date(date);
  }

  return format(d, 'MM/dd/yyyy hh:mm aaa');
};

export const toLocaleFormat = (date: string | Date) => {
  let d = date;
  if (!(d instanceof Date)) {
    d = new Date(date);
  }
  const day = String(d.getUTCDate()).padStart(2, '0');
  const month = String(d.getUTCMonth() + 1).padStart(2, '0');
  const year = d.getUTCFullYear();
  return `${month}-${day}-${year}`;
};

export const toDateTimeLocaleFormat = (date: string | Date) => {
  let d = date;
  if (!(d instanceof Date)) {
    d = new Date(date);
  }
  const day = String(d.getUTCDate()).padStart(2, '0');
  const month = String(d.getUTCMonth() + 1).padStart(2, '0');
  const year = d.getUTCFullYear();
  const hour = d.getUTCHours();
  const minutes = d.getUTCMinutes();
  const seconds = d.getUTCSeconds();
  return `${month}-${day}-${year} ${hour}:${minutes}:${seconds}`;
};

export const toDateTimePrintFormat = (date: string | Date) => {
  let d = date;
  if (!(d instanceof Date)) {
    d = new Date(date);
  }
  d = convertUTCDateToLocalDate(d);
  const day = String(d.getDate()).padStart(2, '0');
  const month = String(d.getMonth() + 1).padStart(2, '0');
  const year = d.getFullYear();
  const hour = String(d.getHours()).padStart(2, '0');
  const minutes = String(d.getMinutes()).padStart(2, '0');
  return `${month}/${day}/${year} ${hour}:${minutes}`;
};

export const convertUTCDateToLocalDate = (date: Date) => {
  return new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
    ),
  );
};

export const toDateFormat = (date: string | Date) => {
  let d = date;
  if (!(d instanceof Date)) {
    d = new Date(date);
  }
  const day = String(d.getUTCDate()).padStart(2, '0');
  const month = String(d.getUTCMonth() + 1).padStart(2, '0');
  const year = d.getUTCFullYear();
  return `${year}-${month}-${day}`;
};

export const toSystemFormat = (date: string) => {
  const dateSplited = date.split('/');
  if (!dateSplited?.[0] || !dateSplited?.[1] || !dateSplited?.[2]) {
    return '';
  }
  return `${dateSplited?.[2]}-${dateSplited?.[0]}-${dateSplited?.[1]}`;
};

export const toLocaleFormatSlash = (date: string | Date) => {
  let d = date;
  if (!(d instanceof Date)) {
    d = new Date(date);
  }
  const day = String(d.getUTCDate()).padStart(2, '0');
  const month = String(d.getUTCMonth() + 1).padStart(2, '0');
  const year = d.getUTCFullYear();
  return `${month}/${day}/${year}`;
};

export default dateFormat;
