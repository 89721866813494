import { useEffect, useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { Spinner } from 'reactstrap';
import { Context as AuthContext } from '../../../../contexts/AuthContext';
import Timeline from '../../../../services/Timeline';
import DataTable from '../../../../components/Core/DataTableWithoutHeaderAndPagination';
import moment from 'moment';
import ModalParentKids, { simpleKidEnroll } from '../../../../components/Navbars/ModalParentKids';
import { Row, Col, FormGroup, InputGroup } from 'reactstrap';
import ReactDatetime from 'react-datetime';

type StudentDailyQuestionariesListProps = {
  studentId: string;
};

type StudentDailyQuestionariesListDataProps = {
  data: [];
  meta: any;
};

const StudentDailyQuestionariesList = ({ studentId }: StudentDailyQuestionariesListProps) => {
  const [lastUpdate, setLastUpdate] = useState(new Date().getTime());
  const [loading, setLoading] = useState(true);
  const { token }: any = useContext(AuthContext);
  const [data, setData] = useState<StudentDailyQuestionariesListDataProps>({ data: [], meta: {} });
  const [startDate, setStartDate] = useState<string | moment.Moment>(moment().format('MM/01/YYYY'));
  const [endDate, setEndDate] = useState<string | moment.Moment>(
    moment().format('MM/') + moment().daysInMonth() + moment().format('/YYYY'),
  );

  const unselectAll = () => {
    const _data = data.data.map((item: any) => {
      return { ...item, selected: false };
    });
    setData(JSON.parse(JSON.stringify({ data: _data, meta: data.meta })));
  };

  const handleClickList = (time: string, editing: boolean = false) => {
    const _data = data.data.map((item: any) => {
      return { ...item, ...{ selected: false } };
    });
    const index = _data.findIndex((item: any) => item.time === time);
    _data[index].selected = !_data[index].selected;
    _data[index].editing = editing;
    setData(JSON.parse(JSON.stringify({ data: _data, meta: data.meta })));
  };

  const columns: any[] = [
    {
      name: 'Day',
      grow: 1,
      cell: (row: any) => moment(row.time).format('MM/DD/YYYY'),
    },
    {
      name: 'Action',
      grow: 1,
      cell: (row: any) => (
        <>
          <button
            className="btn btn-sm rounded-circle"
            style={{ width: '40px', height: '40px' }}
            onClick={() => handleClickList(row.time)}
          >
            <i className="fas fa-eye" />
          </button>
          <button
            className="btn btn-sm rounded-circle"
            style={{ width: '40px', height: '40px' }}
            onClick={() => handleClickList(row.time, true)}
          >
            <i className="fas fa-pen" />
          </button>
        </>
      ),
    },
  ];

  const selected = (): simpleKidEnroll | undefined => {
    const _selected: any = data.data.find((item: any) => item.selected);
    if (!_selected) return undefined;
    const _return = {
      id: _selected.data[0].kidsId,
      name: `${_selected.data[0].kid.firstName} ${_selected.data[0].kid.lastName}`,
      classId: _selected.data[0].options.classId,
      className: _selected.data[0].options.className,
      showModal: true,
      modalType: _selected.data[0].options.type,
      canCloseModal: true,
      data: _selected.data.map((item: any, i: number) => {
        return { ...{ id: _selected.data[i].id }, ...item.options };
      }),
      canEdit: true,
      editing: _selected.editing,
    };

    return _return;
  };

  useEffect(() => {
    if (!studentId) return;
    const load = async () => {
      setLoading(true);
      try {
        const response = await Timeline(token).showPreCheckins(studentId, {
          filters: [
            { field: 'time', operation: '>=', value: moment(startDate).format('YYYY-MM-DD 00:00:00') },
            { field: 'time', operation: '<=', value: moment(endDate).format('YYYY-MM-DD 23:59:59') },
          ],
        });
        setData(response);
      } catch (e: any) {
        toast.error(e?.response?.data?.message);
      } finally {
        setLoading(false);
      }
    };
    load();
  }, [studentId, startDate, endDate, lastUpdate]);

  return (
    <>
      <ModalParentKids
        student={selected()}
        onClose={() => {
          unselectAll();
          setLastUpdate(new Date().getTime());
        }}
      />
      <div className="row">
        <div className="col-12">
          <Row>
            <Col lg="4" xs="6">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-validAt">
                  Initial Date
                </label>
                <InputGroup className="input-group-alternative">
                  <ReactDatetime
                    inputProps={{
                      placeholder: 'Initial Date',
                    }}
                    onChange={(newValue) => {
                      setStartDate(newValue);
                    }}
                    timeFormat={false}
                    dateFormat={'MM/DD/yyyy'}
                    closeOnSelect={true}
                    value={startDate}
                  />
                </InputGroup>
              </FormGroup>
            </Col>

            <Col lg="4" xs="6">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-validAt">
                  End Date
                </label>
                <InputGroup className="input-group-alternative">
                  <ReactDatetime
                    inputProps={{
                      placeholder: 'End Date',
                    }}
                    onChange={(newValue) => {
                      setEndDate(newValue);
                    }}
                    value={endDate}
                    timeFormat={false}
                    dateFormat={'MM/DD/yyyy'}
                    closeOnSelect={true}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
        </div>
        {loading && (
          <div className="col-12 text-center">
            <Spinner />
          </div>
        )}
        {!loading && (
          <>
            <div className="col-12">
              <DataTable
                columns={columns}
                data={data.data}
                loading={loading}
                title=""
                onChangeRowsPerPage={async () => {}}
                onChangePage={async () => {}}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default StudentDailyQuestionariesList;
