import http from '../helpers/http';

interface TimecardProps {
  weekDay: string;
  selectedUsers: string;
  idSchool: string;
}

const Reports = (token: string) => {
  const headers = {
    'x-access-token': token,
  };

  return {
    weeklyTuition: async (idSchool: string) => {
      const result = await http.get(`/report-export-data/${idSchool}/weekly-tuition`, {
        headers,
      });
      return result.data;
    },
    timecard: async ({ weekDay, selectedUsers, idSchool }: TimecardProps) => {
      const result = await http.get(
        `/report-export-data/${idSchool}/timecard?weekDay=${weekDay}&selectedUsers=${selectedUsers}`,
        {
          headers,
        },
      );
      return result.data;
    },
    employeeInformation: async (idSchool: string) => {
      const result = await http.get(`/report-export-data/${idSchool}/employee-information`, {
        headers,
      });
      return result.data;
    },
    staffExpirationDocuments: async (idSchool: string) => {
      const result = await http.get(`/report-export-data/${idSchool}/staff-expiration-documents`, {
        headers,
      });
      return result.data;
    },
    parentChildInformation: async (idSchool: string) => {
      const result = await http.get(`/report-export-data/${idSchool}/parent-child-information`, {
        headers,
      });
      return result.data;
    },
    childrenEnrollDisenrollRate: async (idSchool: string) => {
      const result = await http.get(`/report-export-data/${idSchool}/children-enroll-disenroll-rate`, {
        headers,
      });
      return result.data;
    },
    childExpirationDocuments: async (idSchool: string) => {
      const result = await http.get(`/report-export-data/${idSchool}/child-expiration-documents`, {
        headers,
      });
      return result.data;
    },
    enrolledChildren: async (idSchool: string) => {
      const result = await http.get(`/report-export-data/${idSchool}/enrolled-children`, {
        headers,
      });
      return result.data;
    },
    classRevenues: async (idSchool: string) => {
      const result = await http.get(`/report-export-data/${idSchool}/class-revenues`, {
        headers,
      });
      return result.data;
    },
    classBreakdown: async (idSchool: string) => {
      const result = await http.get(`/report-export-data/${idSchool}/class-breakdown`, {
        headers,
      });
      return result.data;
    },
    TimerOverage: async (initialDate: string, endDate: string, idSchool: string) => {
      const result = await http.get(
        `/report-export-data/${idSchool}/timer-overage?initialDate=${initialDate}&endDate=${endDate}`,
        {
          headers,
        },
      );
      return result.data;
    },
    ChildNotUpdated: async (initialDate: string, endDate: string, idSchool: string) => {
      const result = await http.get(
        `/report-export-data/${idSchool}/child-not-updated?initialDate=${initialDate}&endDate=${endDate}`,
        {
          headers,
        },
      );
      return result.data;
    },
    employeeList: async (idSchool: string) => {
      const result = await http.get(`/report-export-data/${idSchool}/employee-list`, {
        headers,
      });
      return result?.data || {};
    },
    incidentReports: async (idSchool: string) => {
      const result = await http.get(`/report-export-data/${idSchool}/incident-report`, {
        headers,
      });
      return result.data;
    },
    writeUp: async (idSchool: string, query?: string) => {
      const result = await http.get(`/report-export-data/${idSchool}/write-up${query ? `?${query}` : ''}`, {
        headers,
      });
      return result.data;
    },
    userStatus: async (idSchool: string, query?: string) => {
      const result = await http.get(`/report-export-data/${idSchool}/user-status${query ? `?${query}` : ''}`, {
        headers,
      });
      return result.data;
    },
    classroomOutOfRatio: async (initialDate: string, endDate: string, idSchool: string) => {
      const result = await http.get(
        `/report-export-data/${idSchool}/classroom-out-of-ratio?initialDate=${initialDate}&endDate=${endDate}`,
        {
          headers,
        },
      );
      return result.data;
    },
    teacherTracking: async (initialDate: string, endDate: string, idSchool: string) => {
      const result = await http.get(
        `/report-export-data/${idSchool}/teacher-tracking?initialDate=${initialDate}&endDate=${endDate}`,
        {
          headers,
        },
      );
      return result.data;
    },
    employeeInformationPdf: async (idSchool: string) => {
      const headersPdf = { ...headers, 'Content-Type': 'multipart/form-data' };
      const result = await http.get(`/report-export-data/pdf/${idSchool}/employee-information`, {
        headers: headersPdf,
        responseType: 'arraybuffer',
      });
      return result.data;
    },
    staffExpirationDocumentsPdf: async (idSchool: string) => {
      const headersPdf = { ...headers, 'Content-Type': 'multipart/form-data' };
      const result = await http.get(`/report-export-data/pdf/${idSchool}/staff-expiration-documents`, {
        headers: headersPdf,
        responseType: 'arraybuffer',
      });
      return result.data;
    },
    reportPdf: async (idSchool: string, report: string, params?: {}) => {
      const headersPdf = { ...headers, 'Content-Type': 'multipart/form-data' };
      const result = await http.get(`/report-export-data/pdf/${idSchool}/${report}`, {
        headers: headersPdf,
        responseType: 'arraybuffer',
        params: params || {},
      });
      return result.data;
    },
  };
};

export default Reports;
