import { Button, Col, Form, Input, Label, Modal, Row } from 'reactstrap';
import { toLocaleFormatSlash } from '../../../helpers/dateFormat';

interface RevokeOfferFormProps {
  isRevokeHireOpen: boolean;
  toogleRevokeHire: () => void;
  revokeHireUser: any;
  revokeNameToConfirm: string;
  setRevokeNameToConfirm: (v: any) => void;
  handleSubmit: (e: any) => void;
}

const RevokeOfferForm = ({
  isRevokeHireOpen,
  toogleRevokeHire,
  revokeHireUser,
  revokeNameToConfirm,
  setRevokeNameToConfirm,
  handleSubmit,
}: RevokeOfferFormProps) => {
  return (
    <Modal className="modal-dialog-centered" isOpen={isRevokeHireOpen} toggle={toogleRevokeHire} size={'lg'}>
      <div className="modal-header">
        <h4 className="modal-title">Revoke Offer</h4>
        <div onClick={toogleRevokeHire} className="d-flex p-1 cursor-pointer">
          <i className="fas fa-times" />
        </div>
      </div>
      <div className="modal-body" style={{ borderTop: '1px solid #d9d9d9' }}>
        <div className="px-2">
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col lg="12">
                <h3>
                  {revokeHireUser?.user?.firstName}'s First day of work is scheduled for{' '}
                  {toLocaleFormatSlash(revokeHireUser?.startDate)}. Revoking their offer will:
                </h3>
              </Col>
            </Row>

            <Row className="mt-2 ml-1">
              <Col lg="12" className="d-flex align-items-center">
                <i className="fas fa-check-circle mr-2" style={{ fontSize: 18, color: '#02ce0d' }} />
                <h4 className="mb-1">Delete their offer letter</h4>
              </Col>
            </Row>

            <Row className="mt-1 ml-1">
              <Col lg="12" className="d-flex align-items-center">
                <i className="fas fa-check-circle mr-2" style={{ fontSize: 18, color: '#02ce0d' }} />
                <h4 className="mb-1">Remove them from your payroll</h4>
              </Col>
            </Row>

            <Row className="mt-1 ml-1">
              <Col lg="12" className="d-flex align-items-center">
                <i className="fas fa-check-circle mr-2" style={{ fontSize: 18, color: '#02ce0d' }} />
                <h4 className="mb-1">Delete their employee profile</h4>
              </Col>
            </Row>

            <Row className="d-flex mb-2 align-items-center justify-content-between mt-4">
              <Col lg="5">
                <Label>Type "{revokeHireUser?.user?.firstName}" to confirm</Label>
              </Col>
              <Col lg="7">
                <Input
                  type="text"
                  value={revokeNameToConfirm}
                  onChange={(event: any) => setRevokeNameToConfirm(event.target.value)}
                />
              </Col>
            </Row>
            <div className="d-flex justify-content-end mt-4">
              <div>
                <Button className="btn-secondary" onClick={toogleRevokeHire}>
                  Cancel
                </Button>
                <Button
                  color="warning"
                  type="submit"
                  disabled={!(revokeNameToConfirm === revokeHireUser?.user.firstName)}
                >
                  Confirm
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default RevokeOfferForm;
