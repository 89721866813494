export const i9SpanishFields = [
  { type: 'title', label: 'Verificación de Elegibilidad de Empleo - Sección 1' },
  { i: 0, type: 'row', label: 'Apellido (Nombre de Familia)' },
  { i: 1, type: 'row', label: 'Primer Nombre (Nombre de Pila)' },
  { i: 2, type: 'row', label: 'Inicial de Segundo Nombre (si alguno)' },
  { i: 3, type: 'row', label: 'Otros Apellidos Utilizados (si alguno)' },
  { i: 4, type: 'row', label: 'Dirección (Número y Nombre de la Calle)' },
  { i: 5, type: 'row', label: 'Número de Apartamento (si corresponde)' },
  { i: 6, type: 'row', label: 'Ciudad o Pueblo' },
  { i: 7, type: 'row', label: 'Estado' },
  { i: 8, type: 'row', label: 'Código Postal' },
  { i: 9, type: 'row', label: 'Fecha de Nacimiento (mm/dd/aaaa)' },
  { i: 10, type: 'row', label: 'Número de Seguro Social de EE. UU.' },
  { i: 11, type: 'row', label: `Dirección de Correo Electrónico del Empleado` },
  { i: 12, type: 'row', label: `Número de Teléfono del Empleado` },
  { i: 119, type: 'row', label: `1. Ciudadano de Estados Unidos`, size: '12' },
  { i: 120, type: 'row', label: `2. Nacional no ciudadano de Estados Unidos (Vea las instrucciones)`, size: '12' },
  {
    i: 121,
    type: 'row',
    label: `3. Residente permanente legal (Ingrese el Número de Registro de Extranjero, Número A. o Número de USCIS`,
  },
  { i: 113, type: 'row', label: `` },
  {
    i: 122,
    type: 'row',
    label:
      '4. No ciudadano (distinto de los ítems número 2 y 3 anteriores) autorizado para ' +
      'trabajar hasta (fecha de expiración, si alguna, mm/dd/aaaa)',
  },
  { i: 13, type: 'row', label: `` },
  { i: 14, type: 'row', label: `USCIS/Número A` },
  { i: 111, type: 'row', label: `Formulario I-94 Número de Admisión` },
  { i: 114, type: 'row', label: `Número de pasaporte extranjero y país de emisión` },
  { i: 25, type: 'row', label: `Fecha de Hoy (mm/dd/aaaa)` },

  { type: 'title', label: 'Verificación de Elegibilidad de Empleo - Sección 2' },

  { i: 98, type: 'row', label: `Título del Documento 1 - Lista A`, size: '4' },
  { i: 103, type: 'row', label: `Título del Documento 1 - Lista B`, size: '4' },
  { i: 16, type: 'row', label: `Título del Documento 1 - Lista C`, size: '4' },
  { i: 99, type: 'row', label: `Autoridad Emisora - Lista A`, size: '4' },
  { i: 104, type: 'row', label: `Autoridad Emisora - Lista B`, size: '4' },
  { i: 105, type: 'row', label: `Autoridad Emisora - Lista C`, size: '4' },
  { i: 100, type: 'row', label: `Número de Documento (si corresponde) - Lista A`, size: '4' },
  { i: 17, type: 'row', label: `Número de Documento (si corresponde) - Lista B`, size: '4' },
  { i: 106, type: 'row', label: `Número de Documento (si corresponde) - Lista C`, size: '4' },
  { i: 107, type: 'row', label: `Fecha de Expiración (si alguna) - Lista A`, size: '4' },
  { i: 101, type: 'row', label: `Fecha de Expiración (si alguna) - Lista B`, size: '4' },
  { i: 102, type: 'row', label: `Fecha de Expiración (si alguna) - Lista C`, size: '4' },
  { i: 18, type: 'row', label: `Título del Documento 2`, size: '12' },
  { i: 19, type: 'row', label: `Autoridad Emisora`, size: '12' },
  { i: 48, type: 'row', label: `Número de Documento (si corresponde)`, size: '12' },
  { i: 20, type: 'row', label: `Fecha de Expiración (si alguna)`, size: '12' },
  { i: 21, type: 'row', label: `Título del Documento 3`, size: '12' },
  { i: 22, type: 'row', label: `Autoridad Emisora`, size: '12' },
  { i: 49, type: 'row', label: `Número de Documento (si corresponde)`, size: '12' },
  { i: 23, type: 'row', label: `Fecha de Expiración (si alguna)`, size: '12' },
  { i: 115, type: 'row', label: `Información Adicional`, size: '12' },

  { type: 'title', label: 'Certificación' },

  { i: 112, type: 'row', label: `Primer día de trabajo del empleado (mm/dd/aaaa)` },
  { i: 24, type: 'row', label: `Apellido, Nombre y Cargo del Empleador o Representante Autorizado ` },
  { i: 117, type: 'row', label: `Firma del Empleador o Represente Autorizado` },
  { i: 25, type: 'row', label: `Fecha de Hoy (mm/dd/aaaa)` },
  { i: 116, type: 'row', label: `Nombre de la Empresa u Organización del Empleador` },
  {
    i: 118,
    type: 'row',
    label:
      `Dirección de la Empresa u Organización del Empleador (Número y Nombre de la Calle) ` +
      `Ciudad o Pueblo, Estado y Código Postal`,
  },

  { type: 'title', label: 'Suplemento A' },

  { i: 34, type: 'row', label: `Apellido (Nombre de Familia) como en la Sección 1` },
  { i: 35, type: 'row', label: `Nombre (Nombre de Pila) como en la Sección 1` },
  { i: 36, type: 'row', label: `Inicial del Segundo Nombre (si alguno) como en la Sección 1` },

  {
    i: 1,
    type: 'collapse',
    label: 'Preparador o Traductor',
    rows: [
      { i: 67, type: 'row', label: `Firma del Preparador o Traductor` },
      { i: 63, type: 'row', label: `Fecha de Hoy (mm/dd/aaaa)` },
      { i: 80, type: 'row', label: `Apellido (Nombre de Familia)` },
      { i: 81, type: 'row', label: `Nombre (Nombre de Pila)` },
      { i: 8, type: 'row', label: `Inicial del Segundo Nombre (si alguno)` },
      { i: 83, type: 'row', label: `Dirección (Número de Calle y Nombre)` },
      { i: 84, type: 'row', label: `Cuidad o Pueblo` },
      { i: 26, type: 'row', label: `Estado` },
      { i: 85, type: 'row', label: `Código Postal` },
    ],
  },

  {
    i: 2,
    type: 'collapse',
    label: 'Preparador o Traductor',
    rows: [
      { i: 27, type: 'row', label: `Firma del Preparador o Traductor` },
      { i: 38, type: 'row', label: `Fecha de Hoy (mm/dd/aaaa)` },
      { i: 86, type: 'row', label: `Apellido (Nombre de Familia)` },
      { i: 87, type: 'row', label: `Nombre (Nombre de Pila)` },
      { i: 8, type: 'row', label: `Inicial del Segundo Nombre (si alguno)` },
      { i: 95, type: 'row', label: `Dirección (Número de Calle y Nombre)` },
      { i: 96, type: 'row', label: `Cuidad o Pueblo` },
      { i: 28, type: 'row', label: `Estado` },
      { i: 97, type: 'row', label: `Código Postal` },
    ],
  },

  {
    i: 3,
    type: 'collapse',
    label: 'Preparador o Traductor',
    rows: [
      { i: 29, type: 'row', label: `Firma del Preparador o Traductor` },
      { i: 39, type: 'row', label: `Fecha de Hoy (mm/dd/aaaa)` },
      { i: 89, type: 'row', label: `Apellido (Nombre de Familia)` },
      { i: 90, type: 'row', label: `Nombre (Nombre de Pila)` },
      { i: 9, type: 'row', label: `Inicial del Segundo Nombre (si alguno)` },
      { i: 92, type: 'row', label: `Dirección (Número de Calle y Nombre)` },
      { i: 93, type: 'row', label: `Cuidad o Pueblo` },
      { i: 30, type: 'row', label: `Estado` },
      { i: 94, type: 'row', label: `Código Postal` },
    ],
  },

  {
    i: 4,
    type: 'collapse',
    label: 'Preparador o Traductor',
    rows: [
      { i: 31, type: 'row', label: `Firma del Preparador o Traductor` },
      { i: 32, type: 'row', label: `Fecha de Hoy (mm/dd/aaaa)` },
      { i: 74, type: 'row', label: `Apellido (Nombre de Familia)` },
      { i: 75, type: 'row', label: `Nombre (Nombre de Pila)` },
      { i: 7, type: 'row', label: `Inicial del Segundo Nombre (si alguno)` },
      { i: 77, type: 'row', label: `Dirección (Número de Calle y Nombre)` },
      { i: 78, type: 'row', label: `Cuidad o Pueblo` },
      { i: 33, type: 'row', label: `Estado` },
      { i: 79, type: 'row', label: `Código Postal` },
    ],
  },

  { type: 'title', label: 'Suplemento B' },

  { i: 91, type: 'row', label: `Apellido (Nombre Familiar) como en la Sección 1` },
  { i: 92, type: 'row', label: `Primer Nombre (Nombre de pila) como en la Sección 1` },
  { i: 15, type: 'row', label: `Inicial del Segundo Nombre (si tiene)` },
  { i: 51, type: 'row', label: `Fecha de Hoy (mm/dd/aaaa)` },
  { i: 57, type: 'row', label: `Nuevo nombre (si aplica)` },
  { i: 60, type: 'row', label: `Primer Nombre (Nombre de pila)` },

  {
    i: 5,
    type: 'collapse',
    label: 'Reverificación',
    rows: [
      { i: 70, type: 'row', label: `Título del Documento` },
      { i: 72, type: 'row', label: `Número de Documento (si alguno)` },
      { i: 75, type: 'row', label: `Fecha de Expiración (si alguna) (mm/dd/aaaa)` },
      { i: 78, type: 'row', label: `Nombre del Empleador o Representante Autorizado` },
      { i: 81, type: 'row', label: `Firma del Empleador o Representante Autorizado ` },
      { i: 54, type: 'row', label: `Fecha de Hoy (mm/dd/aaaa)` },
      { i: 87, type: 'row', label: `Información Adicional (Inicial y fecha en cada anotación) ` },
      {
        i: 84,
        label: `Marque aquí si usó un procedimiento alterno autorizado por DHS para examinar documentos.`,
        size: '12',
      },
    ],
  },

  {
    i: 6,
    type: 'collapse',
    label: 'Reverificación',
    rows: [
      { i: 107, type: 'row', label: `Título del Documento` },
      { i: 73, type: 'row', label: `Número de Documento (si alguno)` },
      { i: 77, type: 'row', label: `Fecha de Expiración (si alguna) (mm/dd/aaaa)` },
      { i: 79, type: 'row', label: `Nombre del Empleador o Representante Autorizado` },
      { i: 82, type: 'row', label: `Firma del Empleador o Representante Autorizado ` },
      { i: 55, type: 'row', label: `Fecha de Hoy (mm/dd/aaaa)` },
      { i: 89, type: 'row', label: `Información Adicional (Inicial y fecha en cada anotación) ` },
      {
        i: 85,
        label: `Marque aquí si usó un procedimiento alterno autorizado por DHS para examinar documentos.`,
        size: '12',
      },
    ],
  },

  {
    i: 7,
    type: 'collapse',
    label: 'Reverificación',
    rows: [
      { i: 71, type: 'row', label: `Título del Documento` },
      { i: 74, type: 'row', label: `Número de Documento (si alguno)` },
      { i: 76, type: 'row', label: `Fecha de Expiración (si alguna) (mm/dd/aaaa)` },
      { i: 80, type: 'row', label: `Nombre del Empleador o Representante Autorizado` },
      { i: 83, type: 'row', label: `Firma del Empleador o Representante Autorizado ` },
      { i: 56, type: 'row', label: `Fecha de Hoy (mm/dd/aaaa)` },
      { i: 88, type: 'row', label: `Información Adicional (Inicial y fecha en cada anotación) ` },
      {
        i: 86,
        label: `Marque aquí si usó un procedimiento alterno autorizado por DHS para examinar documentos.`,
        size: '12',
      },
    ],
  },
];
