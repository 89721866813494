import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowAltCircleRight,
  faArrowAltCircleLeft,
  faPuzzlePiece,
  faUtensils,
  faBed,
  faLaughBeam,
  faToilet,
  faExchangeAlt,
  faBell,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faArrowAltCircleRight,
  faArrowAltCircleLeft,
  faPuzzlePiece,
  faUtensils,
  faBed,
  faLaughBeam,
  faToilet,
  faExchangeAlt,
  faBell,
);

const activityIcons = [
  { key: 'checkin', icon: 'fa-arrow-alt-circle-right', status: 'bg-success' },
  { key: 'checkout', icon: 'fa-arrow-alt-circle-left', statues: 'bg-danger' },
  { key: 'activity', icon: 'fa-puzzle-piece', status: 'bg-warning' },
  { key: 'food', icon: 'fa-utensils', status: 'bg-info' },
  { key: 'sleep', icon: 'fa-bed', status: 'bg-dark' },
  { key: 'mood', icon: 'fa-laugh-beam', status: 'bg-primary' },
  { key: 'bathroom', icon: 'fa-toilet', status: 'bg-dark' },
  { key: 'moving', icon: 'fa-exchange-alt', status: '' },
  { key: 'reminder', icon: 'fa-bell', status: '' },
];

export default activityIcons;
