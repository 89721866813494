import { Modal } from 'reactstrap';
import EmployeeSettingsTabs from './EmployeeSettingsTabs';

type EmployeeSettingsModalProps = {
  isOpen: boolean;
  toggleModal: (e?: any) => void;
};

const EmployeeSettingsModal = ({ isOpen, toggleModal }: EmployeeSettingsModalProps) => {
  return (
    <>
      <Modal className="modal-dialog-centered" isOpen={isOpen} toggle={(e: any) => toggleModal(e)} size="xl">
        <div className="modal-header pb-1">
          <h5 className="modal-title">Edit Employee Settings</h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={(e) => toggleModal(e)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body pt-0">
          <EmployeeSettingsTabs isOpen={isOpen} />
        </div>
      </Modal>
    </>
  );
};

export default EmployeeSettingsModal;
