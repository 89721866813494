import { Modal, Table, Spinner } from 'reactstrap';
import { toLocaleFormatSlash } from '../../../helpers/dateFormat';
import { Link } from 'react-router-dom';

type ClassDetailedKidsModalProps = {
  isOpen: boolean;
  className: string;
  kidsList: any[];
  loading?: boolean;
  toggleModal: (id: string) => void;
};

const ClassDetailedKidsModal = ({ isOpen, className, kidsList, loading, toggleModal }: ClassDetailedKidsModalProps) => {
  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={isOpen}
      toggle={(e: any) => {
        e.preventDefault();
        toggleModal('');
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title">{`${className ? `${className}` : ''}`}</h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={(e) => {
            e.preventDefault();
            toggleModal('');
          }}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body pt-0">
        <Table striped bordered responsive>
          <tbody>
            <tr>
              <th>Name</th>
              <th>Start Date</th>
              <th>Birthday</th>
            </tr>

            {loading && (
              <tr>
                <td colSpan={3} className="text-center">
                  <Spinner />
                </td>
              </tr>
            )}

            {kidsList.map((k: any) => (
              <tr key={`not-started-${k.id}`}>
                <td>
                  <Link to={`/student-profile/${k.id}`}>{`${k.firstName || '-'} ${k.lastName || ''}`}</Link>
                </td>
                <td>{k.enrollmentStartDate ? toLocaleFormatSlash(k.enrollmentStartDate) : '-'}</td>
                <td>{k.birthday ? toLocaleFormatSlash(k.birthday) : '-'}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </Modal>
  );
};

export default ClassDetailedKidsModal;
