import http from '../helpers/http';
import PtoTypesInterface from '../interfaces/PtoTypesInterface';

const PtoTypes = (token: string) => {
  const headers = {
    'x-access-token': token,
  };

  return {
    list: async (schoolId: string) => {
      const result = await http.get(`/pto-types/${schoolId}`, {
        headers,
      });
      return result.data;
    },
    create: async (body: Partial<PtoTypesInterface>) => {
      const result = await http.post(`/pto-types`, body, { headers });
      return result.data;
    },
    update: async (id: string, body: Partial<PtoTypesInterface>) => {
      const result = await http.put(`/pto-types/${id}`, body, { headers });
      return result.data;
    },
    delete: async (id: string) => {
      const result = await http.delete(`/pto-types/${id}`, {
        headers,
      });
      return result.data;
    },
  };
};

export default PtoTypes;
