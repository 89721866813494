import { FormGroup, Form, Input, Row, Col } from 'reactstrap';
import { toast } from 'react-toastify';
import { useContext, useState, useEffect } from 'react';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import SchoolService from '../../../services/School';
import StateService from '../../../services/State';

type SchoolIdProps = {
  schoolId: string;
  onSubmitForm: boolean;
};

const SchoolAddress = ({ schoolId, onSubmitForm }: SchoolIdProps) => {
  //const { id } = useParams<{ id: string }>();
  const [id, setId] = useState('');

  //const pageTitle = id ? 'Update' : 'Create';
  const { token }: any = useContext(AuthContext);
  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [zipCode, setZipCode] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  //const [country, setCountry] = useState('');
  const [stateId, setStateId] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');

  const [selectList, setSelectList] = useState<any[]>([]);

  const listSelectState = async () => {
    try {
      const result = await StateService(token).listSelect();
      setSelectList(result.data || []);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No States found');
    }
  };

  const getById = async () => {
    try {
      const result = await SchoolService(token).showAddress(schoolId);
      if (result?.data) {
        const address = result?.data || {};
        setZipCode(address?.zipCode || '');
        setAddress(address?.address || '');
        setCity(address?.city || '');
        setId(address?.id || '');
        setStateId(address?.stateId || '');
        setLatitude(address?.latitude || '');
        setLongitude(address?.longitude || '');
      }
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'Invalid school ID');
    }
  };

  useEffect(() => {
    listSelectState();
  }, []);

  useEffect(() => {
    if (schoolId) getById();
  }, [schoolId]);

  useEffect(() => {
    if (onSubmitForm == true) {
      {
        handleSubmitAddress();
      }
    }
  }, [onSubmitForm]);

  const handleSubmitAddress = async () => {
    //e.preventDefault();
    if (loading) return;

    setLoading(true);
    setIsDisabled(true);
    const body = {
      zipCode,
      address,
      city,
      country: '1',
      stateId: stateId || selectList?.[0]?.id,
      latitude,
      longitude,
      schoolId: schoolId,
    };

    try {
      const result = id
        ? await SchoolService(token).updateAddress(id, body)
        : await SchoolService(token).createAddress(body);
      if (result) {
        console.log(`${id ? 'Updated' : 'Created'} Address successfully!`);
      }
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Create error!');
      toast.error(message);
    }

    setLoading(false);
    setIsDisabled(false);
  };

  return (
    <>
      <Form onSubmit={handleSubmitAddress}>
        {/* Address */}
        <hr className="my-4" />
        <h6 className="heading-small text-muted mb-4">Address information</h6>
        <div className="pl-lg-4">
          <Row>
            <Col md="6">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-address">
                  Address
                </label>
                <Input
                  className="form-control-alternative"
                  id="input-address"
                  placeholder="Address"
                  type="text"
                  disabled={isDisabled}
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  required
                />
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-city">
                  City
                </label>
                <Input
                  className="form-control-alternative"
                  id="input-city"
                  placeholder="City"
                  type="text"
                  disabled={isDisabled}
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  required
                />
              </FormGroup>
            </Col>
            <Col lg="3">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-state-id">
                  State
                </label>
                <Input type="select" onChange={(e) => setStateId(e.target.value)} value={stateId}>
                  {selectList?.map((filterState: any, indexState: number) => {
                    return (
                      <option value={filterState.id} key={`filter-button-${indexState}`}>
                        {filterState.name} ({filterState.uf})
                      </option>
                    );
                  })}
                </Input>
              </FormGroup>
            </Col>
            <Col lg="3">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-country">
                  Postal code
                </label>
                <Input
                  className="form-control-alternative"
                  id="input-postal-code"
                  placeholder="Postal code"
                  type="number"
                  disabled={isDisabled}
                  value={zipCode}
                  onChange={(e) => setZipCode(e.target.value)}
                  required
                />
              </FormGroup>
            </Col>
            {/* <Col lg="3">
              <FormGroup>
                <label className="form-control-label">Latitude</label>
                <Input
                  className="form-control-alternative"
                  placeholder="Latitude"
                  type="text"
                  disabled={isDisabled}
                  value={latitude}
                  onChange={(e) => setLatitude(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg="3">
              <FormGroup>
                <label className="form-control-label">Longitude</label>
                <Input
                  className="form-control-alternative"
                  placeholder="Longitude"
                  type="text"
                  disabled={isDisabled}
                  value={longitude}
                  onChange={(e) => setLongitude(e.target.value)}
                />
              </FormGroup>
            </Col> */}
          </Row>
        </div>
        {/* <div className="pl-lg-4">
          <Button className="my-4" color="primary" type="submit" disabled={isDisabled}>
            Save
          </Button>
          <input type="button" value="Submit" onClick={handleSubmitAddress} />
        </div> */}
      </Form>
    </>
  );
};

export default SchoolAddress;
