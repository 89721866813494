import { Col, Row, FormGroup, InputGroup, Table, Button } from 'reactstrap';
import ReactDatetime from 'react-datetime';
import { useContext, useEffect, useState } from 'react';
import { Context as AuthContext } from '../../../../contexts/AuthContext';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import IncidentReports from '../../../../services/IncidentReports';
import ViewIncidentReportModal from './ViewIncidentReportModal';
import PDFGenerator from './PDFGenerator';
import isAdmin from '../../../../helpers/isAdmin';
import { isMobile } from 'react-device-detect';

const IncidentReportTab = () => {
  const { token, currentUser }: any = useContext(AuthContext);
  const { id: kidsId } = useParams<{ id: string }>();

  const [initialDate, setInitialDate] = useState(moment().isoWeekday(0).format('MM/DD/yyyy'));
  const [endDate, setEndDate] = useState(moment().isoWeekday(0).add(6, 'days').format('MM/DD/yyyy'));

  const [incidentReportModalData, setIncidentReportModalData] = useState<any>({});
  const [isIncidentReportModalOpen, setIsIncidentReportModalOpen] = useState(false);
  const isAdminUser = isAdmin(currentUser);

  const handleOpenIncidentReportModal = (data: any) => {
    setIncidentReportModalData(data);
    setIsIncidentReportModalOpen(true);
  };

  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    getData();
  }, [initialDate, endDate]);

  const getData = async () => {
    const query = `startDateTime=${initialDate}&finalDateTime=${endDate}`;
    const result = await IncidentReports(token).listByKid(kidsId, query);

    if (result?.data) {
      setData(result.data);
    }
  };

  return (
    <>
      <Row>
        <Col lg="4" xs="6" style={isMobile ? { paddingLeft: 5, paddingRight: 5 } : {}}>
          <FormGroup>
            <label className="form-control-label" htmlFor="input-validAt">
              Initial Date
            </label>
            <InputGroup className="input-group-alternative">
              <ReactDatetime
                inputProps={{
                  placeholder: 'Start Date',
                }}
                onChange={(newValue) => {
                  setInitialDate(moment(newValue).format('MM/DD/yyyy'));
                }}
                value={initialDate}
                timeFormat={false}
                dateFormat={'MM/DD/yyyy'}
                closeOnSelect={true}
              />
            </InputGroup>
          </FormGroup>
        </Col>

        <Col lg="4" xs="6" style={isMobile ? { paddingLeft: 5, paddingRight: 5 } : {}}>
          <FormGroup>
            <label className="form-control-label" htmlFor="input-validAt">
              End Date
            </label>
            <InputGroup className="input-group-alternative">
              <ReactDatetime
                inputProps={{
                  placeholder: 'End Date',
                }}
                onChange={(newValue) => {
                  setEndDate(moment(newValue).format('MM/DD/yyyy'));
                }}
                value={endDate}
                timeFormat={false}
                dateFormat={'MM/DD/yyyy'}
                closeOnSelect={true}
              />
            </InputGroup>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="12" style={isMobile ? { paddingLeft: 5, paddingRight: 5 } : {}}>
          <div className="d-flex justify-content-between align-items-center py-2">
            <h3 className="text-muted text-uppercase">Incident Report</h3>
            {isAdminUser && <PDFGenerator data={data} />}
          </div>
          <Table>
            <thead>
              <tr>
                <th>Type</th>
                <th>Date/Time</th>
                {!isMobile && <th>Actions</th>}
              </tr>
            </thead>
            <tbody>
              {data.map((value) => (
                <tr key={value.id}>
                  <td>
                    <a href="#" onClick={() => handleOpenIncidentReportModal(value)}>
                      {value.type}
                    </a>
                  </td>
                  <td>{`${moment(value.acidentDate).format('MM/DD/YYYY')} ${moment(value.acidentTime).format(
                    'h:mm A',
                  )}`}</td>
                  {!isMobile && (
                    <td>
                      <Button size="sm" color="primary" onClick={() => handleOpenIncidentReportModal(value)}>
                        <i className="fas fa-eye"></i>
                      </Button>
                      <PDFGenerator data={[value]} />
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>

      {isIncidentReportModalOpen && (
        <ViewIncidentReportModal
          data={incidentReportModalData}
          isIncidentReportOpen={isIncidentReportModalOpen}
          setIsIncidentReportOpen={setIsIncidentReportModalOpen}
        />
      )}
    </>
  );
};

export default IncidentReportTab;
