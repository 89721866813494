/* eslint-disable prefer-spread */
/* eslint-disable max-lines */
import { Alert, Button, Col, Form, FormGroup, Input, Label, Modal, Row } from 'reactstrap';
import { useEffect, useState } from 'react';
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';
import SignatureCanvas from 'react-signature-canvas';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import HireDocuments from '../../../services/HireDocuments';
import { toast } from 'react-toastify';

type SignDocumentModalProps = {
  isOpen: boolean;
  token: string;
  currentUser: any;
  toggleModal: (e?: any) => void;
  saveModal: (e?: any) => void;
};

const SignDocumentModal = ({ isOpen, token, currentUser, toggleModal, saveModal }: SignDocumentModalProps) => {
  const [pdf, setPdf] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [ssn, setSsn] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [married, setMarried] = useState('');
  const [amounts, setAmounts] = useState<any[]>([]);
  const [employerName, setEmployerName] = useState('');
  const [date, setDate] = useState('');
  const [ein, setEin] = useState('');
  const [sigPad, setSigPad] = useState<any>();
  const [signature, setSignature] = useState<any>();
  const [signatureDate, setSignatureDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [pdfBlob, setPdfBlob] = useState<any>();
  const [pdfSignedBlob, setPdfSignedBlob] = useState<any>();

  const coordinatesSign = {
    x: 100,
    y: 150,
    height: 40,
    opacity: 1,
  };

  const coordinatesDate = {
    x: 460,
    y: 167,
    size: 12,
    color: rgb(0, 0, 0),
  };

  useEffect(() => {
    setSignatureDate(moment().format('MM/DD/YYYY'));
  }, []);

  useEffect(() => {
    if (isOpen) createPdf();
    setSignatureDate(moment().format('MM/DD/YYYY'));
  }, [isOpen, sigPad]);

  const pdfUrl = 'https://arb-systems-s3-public.s3.us-east-2.amazonaws.com/public/Employment-Eligibility/fw4.pdf';

  const setNewAmounts = async (i: number, v: number) => {
    const newAmounts = JSON.parse(JSON.stringify(amounts));
    const isExists = newAmounts?.find((n: any) => n?.i === i);
    if (isExists) {
      isExists.v = v;
      return setAmounts(newAmounts);
    }
    newAmounts.push({
      i,
      v,
    });
    setAmounts(newAmounts);
  };

  const readOnlyFields = (pdfForm: any) => {
    try {
      const fieldNames = pdfForm.getFields();
      for (const f of fieldNames) {
        const type = f.constructor.name;
        const fieldName = f.getName();
        if (type === 'PDFTextField') {
          pdfForm.getTextField(fieldName).enableReadOnly();
        }
        if (type === 'PDFDropdown') {
          pdfForm.getDropdown(fieldName).enableReadOnly();
        }
        if (type === 'PDFCheckBox') {
          pdfForm.getCheckBox(fieldName).enableReadOnly();
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const createPdf = async () => {
    try {
      const arrayBuffer = await fetch(pdfUrl).then((res) => res.arrayBuffer());
      setPdfBlob(arrayBuffer);
      const pdfDoc = await PDFDocument.load(arrayBuffer);
      const pdfForm = pdfDoc.getForm();
      readOnlyFields(pdfForm);
      const pages = pdfDoc.getPages();
      const firstPage = pages[0];
      if (firstPage && sigPad) {
        const img = sigPad?.getTrimmedCanvas()?.toDataURL('image/png');
        setSignature(img);
        const pngImage = await pdfDoc.embedPng(img);
        firstPage.drawImage(pngImage, coordinatesSign);

        const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);
        firstPage.drawText(signatureDate, {
          ...coordinatesDate,
          font: timesRomanFont,
        });
      }
      const fieldNames = pdfForm.getFields().map((f) => f.getName());

      const firstNameField = pdfForm.getTextField(fieldNames[0]);
      firstNameField.setText(firstName);
      firstNameField.enableReadOnly();

      const lastNameField = pdfForm.getTextField(fieldNames[1]);
      lastNameField.setText(lastName);
      lastNameField.enableReadOnly();

      const addressField = pdfForm.getTextField(fieldNames[2]);
      addressField.setText(address);
      addressField.enableReadOnly();

      const cityField = pdfForm.getTextField(fieldNames[3]);
      cityField.setText(city);
      cityField.enableReadOnly();

      const ssnField = pdfForm.getTextField(fieldNames[4]);
      ssnField.setText(ssn);
      ssnField.enableReadOnly();

      const employerNameField = pdfForm.getTextField(fieldNames[15]);
      employerNameField.setText(employerName);
      employerNameField.enableReadOnly();

      const dateField = pdfForm.getTextField(fieldNames[16]);
      dateField.setText(date);
      dateField.enableReadOnly();

      const einField = pdfForm.getTextField(fieldNames[17]);
      einField.setText(ein);
      einField.enableReadOnly();

      const m1 = pdfForm.getCheckBox(fieldNames[5]);
      m1.enableReadOnly();
      const m2 = pdfForm.getCheckBox(fieldNames[6]);
      m2.enableReadOnly();
      const m3 = pdfForm.getCheckBox(fieldNames[7]);
      m3.enableReadOnly();
      if (married === '1') pdfForm.getCheckBox(fieldNames[5]).check();
      if (married === '2') pdfForm.getCheckBox(fieldNames[6]).check();
      if (married === '3') pdfForm.getCheckBox(fieldNames[7]).check();
      for (const n of amounts) {
        if (n?.i) {
          const f = pdfForm.getTextField(fieldNames[n?.i]);
          f.setText(String(n?.v || 0));
          f.enableReadOnly();
        }
      }

      const pdfBytes = await pdfDoc.save();

      const blob = new Blob([pdfBytes], { type: 'application/pdf' });
      setPdfSignedBlob(blob);
      const docUrl = URL.createObjectURL(blob);
      setPdf(docUrl);
    } catch (e) {
      console.log(e);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);

    const fields = {
      firstName,
      lastName,
      ssn,
      address,
      city,
      married,
      amounts,
      employerName,
      date,
      ein,
    };
    const body = {
      type: 'W4',
      userId: currentUser?.userId || '',
      userName: currentUser?.userName || '',
      userToken: token,
      pdf: pdfBlob,
      pdfUrl: pdfUrl,
      pdfSigned: pdfSignedBlob,
      signature,
      options: {
        type: 'W4',
        docUrl: pdf,
        fields,
        coordinatesSign,
        coordinatesDate,
        signPng: signature,
        signatureDate: moment().format('YYYY-MM-DD'),
      },
    };

    try {
      await HireDocuments('').create(body);
      toast.success(`Saved successfully!`);
      saveModal(e);
    } catch (e: any) {
      console.log(e);
      const message = String(e?.response?.data?.message || 'Create error!');
      toast.error(message);
    }

    setLoading(false);
  };

  return (
    <>
      <Modal className="modal-dialog-centered" size="xl" isOpen={isOpen} toggle={(e: any) => toggleModal(e)}>
        <div className="modal-header pb-1">
          <h5 className="modal-title">Document Signing</h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={(e) => toggleModal(e)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body pt-0">
          <Form onBlur={createPdf}>
            <Row className="mt-2">
              <Col md="6">
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-name">
                        First name and middle initial
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-name"
                        placeholder="First name and middle initial"
                        type="text"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-name">
                        Last name
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-name"
                        placeholder="Last Name"
                        type="text"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-name">
                        Social security number
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-name"
                        placeholder="Social security number"
                        type="text"
                        value={ssn}
                        onChange={(e) => setSsn(e.target.value)}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-name">
                        Address
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-name"
                        placeholder="Address"
                        type="text"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-name">
                        City
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-name"
                        placeholder="City"
                        type="text"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup check>
                      <Label check>
                        <Input
                          name="maried"
                          type="radio"
                          value={'1'}
                          checked={married === '1'}
                          onChange={(e) => setMarried(e.target.value)}
                        />{' '}
                        Single or Married filing separately
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup check>
                      <Label check>
                        <Input
                          name="maried"
                          type="radio"
                          value={'2'}
                          checked={married === '2'}
                          onChange={(e) => setMarried(e.target.value)}
                        />{' '}
                        Married filing jointly or Qualifying surviving spouse
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col md="12" className="mb-2">
                    <FormGroup check>
                      <Label check>
                        <Input
                          name="maried"
                          type="radio"
                          value={'3'}
                          checked={married === '3'}
                          onChange={(e) => setMarried(e.target.value)}
                        />{' '}
                        Head of household
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label">
                        Multiply the number of qualifying children under age 17 by $2,000
                      </label>
                      <Input
                        type="text"
                        thousandsGroupStyle="thousand"
                        placeholder="$0"
                        prefix="$"
                        decimalSeparator="."
                        displayType="input"
                        thousandSeparator={true}
                        allowNegative={false}
                        decimalScale={2}
                        tag={NumberFormat}
                        value={amounts?.find((a) => a.i === 9)?.v}
                        onValueChange={(e: any) => setNewAmounts(9, e.floatValue)}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label">Multiply the number of other dependents by $500</label>
                      <Input
                        type="text"
                        thousandsGroupStyle="thousand"
                        placeholder="$0"
                        prefix="$"
                        decimalSeparator="."
                        displayType="input"
                        thousandSeparator={true}
                        allowNegative={false}
                        decimalScale={2}
                        tag={NumberFormat}
                        value={amounts?.find((a) => a.i === 10)?.v}
                        onValueChange={(e: any) => setNewAmounts(10, e.floatValue)}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label">Step 3 - Nº 3</label>
                      <Input
                        type="text"
                        thousandsGroupStyle="thousand"
                        placeholder="$0"
                        prefix="$"
                        decimalSeparator="."
                        displayType="input"
                        thousandSeparator={true}
                        allowNegative={false}
                        decimalScale={2}
                        tag={NumberFormat}
                        value={amounts?.find((a) => a.i === 11)?.v}
                        onValueChange={(e: any) => setNewAmounts(11, e.floatValue)}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label">Step 4 - 4(a)</label>
                      <Input
                        type="text"
                        thousandsGroupStyle="thousand"
                        placeholder="$0"
                        prefix="$"
                        decimalSeparator="."
                        displayType="input"
                        thousandSeparator={true}
                        allowNegative={false}
                        decimalScale={2}
                        tag={NumberFormat}
                        value={amounts?.find((a) => a.i === 12)?.v}
                        onValueChange={(e: any) => setNewAmounts(12, e.floatValue)}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label">Step 4 - 4(b)</label>
                      <Input
                        type="text"
                        thousandsGroupStyle="thousand"
                        placeholder="$0"
                        prefix="$"
                        decimalSeparator="."
                        displayType="input"
                        thousandSeparator={true}
                        allowNegative={false}
                        decimalScale={2}
                        tag={NumberFormat}
                        value={amounts?.find((a) => a.i === 13)?.v}
                        onValueChange={(e: any) => setNewAmounts(13, e.floatValue)}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label">Step 4 - 4(c)</label>
                      <Input
                        type="text"
                        thousandsGroupStyle="thousand"
                        placeholder="$0"
                        prefix="$"
                        decimalSeparator="."
                        displayType="input"
                        thousandSeparator={true}
                        allowNegative={false}
                        decimalScale={2}
                        tag={NumberFormat}
                        value={amounts?.find((a) => a.i === 14)?.v}
                        onValueChange={(e: any) => setNewAmounts(14, e.floatValue)}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12 mt-2">
                    <label className="form-control-label mr-2" htmlFor="input-name">
                      Signature
                    </label>
                  </Col>
                  <Col lg="6">
                    <div>
                      <SignatureCanvas
                        penColor="blue"
                        canvasProps={{ width: 500, height: 200, className: 'sig-canvas' }}
                        ref={(ref) => setSigPad(ref)}
                        onEnd={createPdf}
                      />
                    </div>
                  </Col>
                  <Col lg="12">
                    <Button onClick={() => sigPad?.clear()} color="warning" size="sm" className="mt-1 mb-2">
                      Clear
                    </Button>
                  </Col>
                  <Col lg="12">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-name">
                        Step 5 - Employer’s name and address
                      </label>
                      <Input
                        className="form-control-alternative"
                        type="textarea"
                        value={employerName}
                        onChange={(e) => setEmployerName(e.target.value)}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-name">
                        First date of employment (MM/DD/YYYY)
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-name"
                        placeholder=""
                        type="text"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-name">
                        EIN
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-name"
                        placeholder=""
                        type="text"
                        value={ein}
                        onChange={(e) => setEin(e.target.value)}
                        required
                      />
                    </FormGroup>
                  </Col>

                  <Col lg="12" className="mt-2 mb-2">
                    <h3>Step 2(b) — Multiple Jobs Worksheet </h3>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label">Two Jobs - 1</label>
                      <Input
                        type="text"
                        thousandsGroupStyle="thousand"
                        placeholder="$0"
                        prefix="$"
                        decimalSeparator="."
                        displayType="input"
                        thousandSeparator={true}
                        allowNegative={false}
                        decimalScale={2}
                        tag={NumberFormat}
                        value={amounts?.find((a) => a.i === 18)?.v}
                        onValueChange={(e: any) => setNewAmounts(18, e.floatValue)}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label">Three Jobs - 2a</label>
                      <Input
                        type="text"
                        thousandsGroupStyle="thousand"
                        placeholder="$0"
                        prefix="$"
                        decimalSeparator="."
                        displayType="input"
                        thousandSeparator={true}
                        allowNegative={false}
                        decimalScale={2}
                        tag={NumberFormat}
                        value={amounts?.find((a) => a.i === 19)?.v}
                        onValueChange={(e: any) => setNewAmounts(19, e.floatValue)}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label">Three Jobs - 2b</label>
                      <Input
                        type="text"
                        thousandsGroupStyle="thousand"
                        placeholder="$0"
                        prefix="$"
                        decimalSeparator="."
                        displayType="input"
                        thousandSeparator={true}
                        allowNegative={false}
                        decimalScale={2}
                        tag={NumberFormat}
                        value={amounts?.find((a) => a.i === 20)?.v}
                        onValueChange={(e: any) => setNewAmounts(20, e.floatValue)}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label">Three Jobs - 2c</label>
                      <Input
                        type="text"
                        thousandsGroupStyle="thousand"
                        placeholder="$0"
                        prefix="$"
                        decimalSeparator="."
                        displayType="input"
                        thousandSeparator={true}
                        allowNegative={false}
                        decimalScale={2}
                        tag={NumberFormat}
                        value={amounts?.find((a) => a.i === 21)?.v}
                        onValueChange={(e: any) => setNewAmounts(21, e.floatValue)}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label">Number of pay periods - 3</label>
                      <Input
                        type="text"
                        thousandsGroupStyle="thousand"
                        placeholder="$0"
                        prefix="$"
                        decimalSeparator="."
                        displayType="input"
                        thousandSeparator={true}
                        allowNegative={false}
                        decimalScale={2}
                        tag={NumberFormat}
                        value={amounts?.find((a) => a.i === 22)?.v}
                        onValueChange={(e: any) => setNewAmounts(22, e.floatValue)}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label">Divide - 4</label>
                      <Input
                        type="text"
                        thousandsGroupStyle="thousand"
                        placeholder="$0"
                        prefix="$"
                        decimalSeparator="."
                        displayType="input"
                        thousandSeparator={true}
                        allowNegative={false}
                        decimalScale={2}
                        tag={NumberFormat}
                        value={amounts?.find((a) => a.i === 23)?.v}
                        onValueChange={(e: any) => setNewAmounts(23, e.floatValue)}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12" className="mt-2 mb-2">
                    <h3>Step 4(b) — Deductions Worksheet </h3>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label">Step 4(b) - 1</label>
                      <Input
                        type="text"
                        thousandsGroupStyle="thousand"
                        placeholder="$0"
                        prefix="$"
                        decimalSeparator="."
                        displayType="input"
                        thousandSeparator={true}
                        allowNegative={false}
                        decimalScale={2}
                        tag={NumberFormat}
                        value={amounts?.find((a) => a.i === 24)?.v}
                        onValueChange={(e: any) => setNewAmounts(24, e.floatValue)}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label">Step 4(b) - 2</label>
                      <Input
                        type="text"
                        thousandsGroupStyle="thousand"
                        placeholder="$0"
                        prefix="$"
                        decimalSeparator="."
                        displayType="input"
                        thousandSeparator={true}
                        allowNegative={false}
                        decimalScale={2}
                        tag={NumberFormat}
                        value={amounts?.find((a) => a.i === 25)?.v}
                        onValueChange={(e: any) => setNewAmounts(25, e.floatValue)}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label">Step 4(b) - 3</label>
                      <Input
                        type="text"
                        thousandsGroupStyle="thousand"
                        placeholder="$0"
                        prefix="$"
                        decimalSeparator="."
                        displayType="input"
                        thousandSeparator={true}
                        allowNegative={false}
                        decimalScale={2}
                        tag={NumberFormat}
                        value={amounts?.find((a) => a.i === 26)?.v}
                        onValueChange={(e: any) => setNewAmounts(26, e.floatValue)}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label">Step 4(b) - 4</label>
                      <Input
                        type="text"
                        thousandsGroupStyle="thousand"
                        placeholder="$0"
                        prefix="$"
                        decimalSeparator="."
                        displayType="input"
                        thousandSeparator={true}
                        allowNegative={false}
                        decimalScale={2}
                        tag={NumberFormat}
                        value={amounts?.find((a) => a.i === 27)?.v}
                        onValueChange={(e: any) => setNewAmounts(27, e.floatValue)}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label">Step 4(b) - 5</label>
                      <Input
                        type="text"
                        thousandsGroupStyle="thousand"
                        placeholder="$0"
                        prefix="$"
                        decimalSeparator="."
                        displayType="input"
                        thousandSeparator={true}
                        allowNegative={false}
                        decimalScale={2}
                        tag={NumberFormat}
                        value={amounts?.find((a) => a.i === 28)?.v}
                        onValueChange={(e: any) => setNewAmounts(28, e.floatValue)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col md="6" className="mt-4">
                <div style={{ position: 'fixed', minWidth: 530 }}>
                  <Row>
                    <Col lg="12">
                      <Alert style={{ maxWidth: 540 }}>
                        If you would like to view this document in all its detail, please download.
                      </Alert>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12">
                      <iframe src={pdf} style={{ minHeight: 500, width: '100%' }} />
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col md="6">
                <Button
                  color="success"
                  size="md"
                  style={{ minWidth: '100px' }}
                  onClick={(e) => {
                    handleSubmit(e);
                    toggleModal(e);
                  }}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default SignDocumentModal;
