import FilteredOptionsInterface, { makeQueryParamsForRequest } from '../entities/FilteredOptions';
import http from '../helpers/http';

const Transaction = (token: string) => {
  const headers = {
    'x-access-token': token,
  };

  return {
    exportAchByKids: async (kidsId: string) => {
      const headersPdf = { ...headers, 'Content-Type': 'multipart/form-data' };
      const result = await http.get(`/transactions/${kidsId}/export-ach`, {
        headers: headersPdf,
        responseType: 'arraybuffer',
      });
      return result.data;
    },
    exportByKids: async (kidsId: string) => {
      const result = await http.get(`/transactions/${kidsId}/export`, {
        headers,
      });
      return result.data;
    },
    listByKids: async (kidsId: string, filteredOptions?: FilteredOptionsInterface) => {
      const params = makeQueryParamsForRequest(filteredOptions);
      const result = await http.get(`/transactions/${kidsId}`, {
        headers,
        params,
      });
      return result.data;
    },
    create: async (body: { userId: string; transactionType: string; amount: number; description: string }) => {
      const result = await http.post(`/transactions`, body, { headers });
      return result.data;
    },
    createDirect: async (body: {
      userId: string;
      transactionType: string;
      amount: number;
      description: string;
      options: any;
    }) => {
      const result = await http.post(`/transactions/direct`, body, { headers });
      return result.data;
    },
    createIntent: async (body: { userId: string; transactionType: string }) => {
      const result = await http.post(`/transactions/intent`, body, { headers });
      return result.data;
    },
    createPayment: async (body: {
      userId?: string;
      kidsId?: string;
      kidsPercents?: any[];
      transactionType: string;
      amount: number;
      description: string;
      createInvoice?: string;
      accountingType?: string;
      invoiceId?: string;
      paymentMethodId?: string;
    }) => {
      const result = await http.post(`/transactions/payment`, body, { headers });
      return result.data;
    },
    createDirectPublic: async (body: {
      userId: string;
      transactionType: string;
      amount: number;
      description: string;
      options: any;
      schoolId?: string;
    }) => {
      const result = await http.post(`/transactions/direct-public`, body);
      return result.data;
    },
  };
};

export default Transaction;
