import MedicalInterface from '../interfaces/MedicalInterface';
import http from '../helpers/http';

const Medical = (token: string) => {
  const headers = {
    'x-access-token': token,
  };

  return {
    update: async (userId: string, body: Partial<MedicalInterface>) => {
      const result = await http.put(`/user-medical/${userId}`, body, { headers });
      return result.data;
    },
    show: async (id: string) => {
      const result = await http.get(`/user-medical/${id}`, {
        headers,
      });
      return result.data;
    },
    create: async (body: Partial<MedicalInterface>) => {
      const result = await http.post(`/user-medical`, body, { headers });
      return result.data;
    },
  };
};

export default Medical;
