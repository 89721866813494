import { Button, Col, Modal, Row } from 'reactstrap';

type ClassCheckedInAlertModalProps = {
  isOpen: boolean;
  nameClass: string;
  pinCode: string;
  toggleModal: () => void;
  toggleSubmit: (pinCode: string) => void;
};

const ClassCheckedInAlertModalModal = ({
  isOpen,
  nameClass,
  pinCode,
  toggleModal,
  toggleSubmit,
}: ClassCheckedInAlertModalProps) => {
  const submit = async () => {
    toggleSubmit(pinCode);
    toggleModal();
  };

  const toggle = async () => {
    toggleModal();
  };

  return (
    <Modal
      className="modal-dialog-centered"
      size="sm"
      isOpen={isOpen}
      toggle={(e: any) => {
        e.preventDefault();
        toggle();
      }}
    >
      <div className="modal-header pb-1">
        <h5 className="modal-title">Alert</h5>
        <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => toggle()}>
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body pt-0">
        <Row className="mt-4">
          <Col md="12 text-center">
            <h2>
              You are checked in the <strong>{`${nameClass}`}</strong> room.
            </h2>
            <p>Please next time remember to check out in the other room.</p>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md="12 text-center">
            <Button color="primary" size="md" style={{ minWidth: '100px' }} onClick={() => submit()}>
              Ok
            </Button>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default ClassCheckedInAlertModalModal;
