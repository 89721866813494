import { Card, CardBody, Row, Col } from 'reactstrap';
import { isMobile } from 'react-device-detect';

const CardStatsText = (props: { title: string; description: string; style?: any }) => {
  const isTablet = window.innerWidth <= 1024 && !isMobile;
  return (
    <Card
      className={`${isMobile ? '' : 'card-stats mt-4'}`}
      style={isMobile || isTablet ? { minHeight: '62px', ...props?.style } : {}}
    >
      <CardBody style={isMobile ? { padding: 0 } : {}}>
        <Row>
          <Col
            md="12"
            className={isMobile ? 'mt-1' : ''}
            style={isMobile ? { minHeight: '30px' } : { minHeight: '55px' }}
          >
            <h5
              className={`text-uppercase text-center text-muted mb-0 card-title ${isMobile ? '' : 'pb-3'}`}
              style={isTablet || isMobile ? { fontSize: 11 } : {}}
            >
              {props?.title || ''}
            </h5>
          </Col>
          <Col md="12">
            <span
              className={`w-100 d-flex justify-content-center big-font text-center ${isMobile ? '' : 'mb-0 mt-1'}`}
              style={isMobile ? { fontSize: 22, paddingBottom: '15px' } : {}}
            >
              {props?.description || ''}
            </span>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default CardStatsText;
