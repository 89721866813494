import http from '../helpers/http';

const Payroll = (token: string) => {
  const headers = {
    'x-access-token': token,
  };

  return {
    deductionCreate: async (body: any) => {
      const result = await http.post(`/deduction`, body, { headers });
      return result.data;
    },
    deductionList: async (schoolId: string, search = '') => {
      const result = await http.get(`/deduction/by-school/${schoolId}?search=${search}`, {
        headers,
      });
      return result.data;
    },
    deductionFile: async (deductionId: string, body: FormData) => {
      const headers = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-access-token': token,
        },
      };
      const result = await http.patch(`/deduction/${deductionId}/file`, body, headers);
      return result.data;
    },
    extraPaymentCreate: async (body: any) => {
      const result = await http.post(`/extra-payment`, body, { headers });
      return result.data;
    },
    extraPaymentList: async (schoolId: string, search = '') => {
      const result = await http.get(`/extra-payment/by-school/${schoolId}?search=${search}`, {
        headers,
      });
      return result.data;
    },
    extraPaymentDocument: async (extraPaymentId: string, body: FormData) => {
      const headers = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-access-token': token,
        },
      };
      const result = await http.patch(`/extra-payment/${extraPaymentId}/document`, body, headers);
      return result.data;
    },
    extraPaymentReceipt: async (extraPaymentId: string, body: FormData) => {
      const headers = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-access-token': token,
        },
      };
      const result = await http.patch(`/extra-payment/${extraPaymentId}/receipt`, body, headers);
      return result.data;
    },
    raiseCreate: async (body: any) => {
      const result = await http.post(`/raise`, body, { headers });
      return result.data;
    },
    raiseList: async (schoolId: string, search = '') => {
      const result = await http.get(`/raise/by-school/${schoolId}?search=${search}`, {
        headers,
      });
      return result.data;
    },
    raiseFile: async (extraPaymentId: string, body: FormData) => {
      const headers = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-access-token': token,
        },
      };
      const result = await http.patch(`/raise/${extraPaymentId}/file`, body, headers);
      return result.data;
    },
    raiseApprove: async (id: string) => {
      const result = await http.patch(
        `/raise/${id}/approve`,
        {},
        {
          headers,
        },
      );
      return result.data;
    },
    raiseRepprove: async (id: string) => {
      const result = await http.patch(
        `/raise/${id}/repprove`,
        {},
        {
          headers,
        },
      );
      return result.data;
    },
    bonusesCreate: async (body: any) => {
      const result = await http.post(`/bonuses`, body, { headers });
      return result.data;
    },
    bonusesList: async (schoolId: string, search = '') => {
      const result = await http.get(`/bonuses/by-school/${schoolId}?search=${search}`, {
        headers,
      });
      return result.data;
    },
    coverSheetList: async (schoolId: string, query?: string) => {
      const result = await http.get(`/cover-sheet/by-school/${schoolId}${query ? `?${query}` : ''}`, {
        headers,
      });
      return result.data;
    },
  };
};

export default Payroll;
