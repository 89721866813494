import moment from 'moment';
import { isMobile } from 'react-device-detect';
import { Badge, Button, Col, Row } from 'reactstrap';
export const validStatus: any = {
  PENDDING: { value: 'REQUESTED', color: 'yellow' },
  REPPROVE: { value: 'DENIED', color: 'danger' },
  APPROVE: { value: 'APPROVED', color: 'success' },
};
const collumns = ({
  setRequestDetailsData,
  setIsRequestDetailsOpen,
  isAdminUser,
  confirmRepprove,
}: {
  setRequestDetailsData: any;
  setIsRequestDetailsOpen: any;
  isAdminUser: boolean;
  confirmRepprove: any;
}) => {
  let data: any[] = [
    {
      name: 'Name',
      grow: 2,
      cell: (row: any) => (
        <Row>
          <Col>
            <div
              className="d-flex align-items-center"
              style={{
                cursor: 'pointer',
                transition: 'filter 0.3s ease',
                filter: 'brightness(1)',
                marginLeft: isMobile ? '-10px' : undefined,
              }}
              onMouseEnter={(e) => (e.currentTarget.style.filter = 'brightness(0.8)')}
              onMouseLeave={(e) => (e.currentTarget.style.filter = 'brightness(1)')}
              onClick={() => {
                setRequestDetailsData(row);
                setIsRequestDetailsOpen(true);
              }}
            >
              <div style={{ width: '35px', height: '35px', borderRadius: '50%', overflow: 'hidden' }}>
                <img
                  src={row.user.picture}
                  alt="User Profile"
                  className="img-fluid"
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />
              </div>
              <span className={`ml-2 ${isMobile ? 'mt-n3' : ''} text-primary font-weight-bold text-sm`}>{`${
                row.user.firstName || '-'
              } ${row.user.lastName || '-'}`}</span>
            </div>
          </Col>
          {isMobile ? (
            <Col>
              <div
                style={{
                  width: '100%',
                  marginLeft: 33,
                  marginTop: -18,
                }}
              >
                {isMobile ? (
                  <span>{`${moment(row.startDate).utc().format('MMM D')} - ${moment(row.endDate)
                    .utc()
                    .format('MMM D, YYYY')}`}</span>
                ) : (
                  <></>
                )}
              </div>
            </Col>
          ) : (
            <></>
          )}
        </Row>
      ),
    },
  ];
  if (isMobile) {
    data = [
      ...data,
      ...[
        {
          name: '',
          selector: (row: any) => row.workday,
          grow: 1,
          cell: (row: any) => (
            <div style={{ textAlign: 'right' }}>
              <span>
                <Badge
                  color=""
                  className="badge-dot badge-lg"
                  style={{ fontSize: '0.8125rem', verticalAlign: 'middle', lineHeight: 'normal', padding: 0 }}
                >
                  <i
                    className={`bg-${validStatus?.[row?.status || '']?.color} mr-2`}
                    style={{ verticalAlign: 'baseline' }}
                  />
                </Badge>
                <br />
                {row.reason}
                <br />
                {row.hours ? `${row.hours} hours` : ''}
              </span>
            </div>
          ),
        },
      ],
    ];
  }
  if (!isMobile) {
    data = [
      ...data,
      ...[
        {
          name: 'Status',
          selector: (row: any) => row.workday,
          grow: 1,
          cell: (row: any) => (
            <>
              {row.status === 'PENDDING' && (
                <div style={{ border: '1px solid #d4d110', padding: '4px 8px', borderRadius: '3px' }}>
                  <span style={{ color: '#d4d110', fontWeight: '700' }}>REQUESTED</span>
                </div>
              )}

              {row.status === 'REPPROVE' && (
                <div style={{ border: '1px solid #d43710', padding: '4px 8px', borderRadius: '3px' }}>
                  <span style={{ color: '#d43710', fontWeight: '700' }}>DENIED</span>
                </div>
              )}

              {row.status === 'APPROVE' && (
                <div style={{ border: '1px solid #08b408', padding: '4px 8px', borderRadius: '3px' }}>
                  <span style={{ color: '#08b408', fontWeight: '700' }}>APPROVED</span>
                </div>
              )}
            </>
          ),
        },
        {
          name: 'Reason',
          selector: (row: any) => row.reason,
          grow: 1,
          cell: (row: any) => row.reason,
        },
        {
          name: 'Actual Dates',
          selector: (row: any) =>
            `${moment(row.startDate).utc().format('MMM D, YYYY')} - ${moment(row.endDate).utc().format('MMM D, YYYY')}`,
          grow: 1,
          cell: (row: any) =>
            `${moment(row.startDate).utc().format('MMM D, YYYY')} - ${moment(row.endDate).utc().format('MMM D, YYYY')}`,
        },
        {
          name: 'Total Hours',
          selector: (row: any) => row.hours,
          grow: 1,
          cell: (row: any) => <span className="ml-2">{row.hours}</span>,
        },
        {
          name: 'Actions',
          selector: (row: any) => row.supervisor.name,
          grow: 1,
          cell: (row: any) => (
            <div className="d-flex" style={{ gap: '5px' }}>
              {isAdminUser && (
                <Button
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    background: '#FFFFFF',
                    padding: 0,
                  }}
                  onClick={(e) => confirmRepprove(e, row.id)}
                >
                  <i className="fas fa-ban" />
                </Button>
              )}
              <Button
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  background: '#FFFFFF',
                  padding: 0,
                }}
                onClick={() => {
                  setRequestDetailsData(row);
                  setIsRequestDetailsOpen(true);
                }}
              >
                <i className="fas fa-eye" />
              </Button>
            </div>
          ),
        },
      ],
    ];
  }
  return data;
};

export default collumns;
