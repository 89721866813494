import http from '../helpers/http';

const EmploymentDocuments = (token: string) => {
  const headers = {
    'x-access-token': token,
  };

  return {
    list: async (employeeId: string) => {
      const result = await http.get(`/employment-documents/${employeeId}`, {
        headers,
      });
      return result.data;
    },
    create: async (body: any) => {
      const result = await http.post(`/employment-documents`, body, { headers });
      return result.data;
    },
    delete: async (id: string) => {
      const result = await http.delete(`/employment-documents/${id}`, {
        headers,
      });
      return result.data;
    },
    uploadFile: async (employeeId: string, body: FormData) => {
      const headers = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-access-token': token,
        },
      };
      const result = await http.patch(`/employment-documents/${employeeId}/document`, body, headers);
      return result.data;
    },
  };
};

export default EmploymentDocuments;
