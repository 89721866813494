import { Container, Row, Col } from 'reactstrap';
import { useContext, useState, useEffect } from 'react';
import { Context as AuthContext } from '../../contexts/AuthContext';
import CommonHeader from '../../components/Headers/CommonHeader';
import QrCodeService from '../../services/QrCode';

const QrCode = () => {
  const { token } = useContext(AuthContext);
  const [qrCode, setQrCode] = useState('');

  useEffect(() => {
    const getQrCode = async () => {
      if (token) {
        const result = await QrCodeService().get(token);
        setQrCode(result.qr);
      }
    };
    getQrCode();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <CommonHeader />
      <Container className="mt--8" fluid>
        <Row>
          <Col className="order-xl-2" xl="12">
            <img src={qrCode} />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default QrCode;
