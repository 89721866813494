import { useContext, useState, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import StripeCheckout from 'react-stripe-checkout';
import { Card, CardBody, CardHeader, Col, FormGroup, Input, Row, Spinner } from 'reactstrap';
import TransactionService from '../../../services/Transaction';
import SchoolService from '../../../services/School';
import { Context as AuthContext } from '../../../contexts/AuthContext';

const CreditCardTransaction = () => {
  const { token, user, currentSchool }: any = useContext(AuthContext);
  const [amount, setAmount] = useState(0);
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [lastTransaction, setLastTransaction] = useState({} as any);
  const [stripePK, setStripePK] = useState('');

  const getSchoolStripeCurrent = async () => {
    if (!currentSchool?.value) return;
    try {
      const r = await SchoolService('').getSchoolStripeCurrent(currentSchool?.value);
      if (r?.data?.stripePublicKey) {
        setStripePK(r?.data?.stripePublicKey || '');
      }
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Stripe current error!');
      console.error(message);
    }
  };

  useEffect(() => {
    getSchoolStripeCurrent();
  }, [currentSchool]);

  useEffect(() => {
    setLoading(false);
  }, [lastTransaction]);

  const handlePayment = async (stripeToken: any) => {
    setLoading(true);
    const transaction = await TransactionService(token).createDirect({
      userId: user?.id || '',
      transactionType: 'CREDIT_CARD',
      amount,
      description,
      options: stripeToken,
    });
    setLastTransaction(transaction);
  };

  return (
    <>
      <Row>
        <Col xl="12">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">Transaction Test</CardHeader>
            <CardBody>
              <Row>
                <Col xl="6">
                  <FormGroup>
                    <label className="form-control-label">Amount</label>
                    <Input
                      type="text"
                      thousandsGroupStyle="thousand"
                      prefix="$"
                      decimalSeparator="."
                      displayType="input"
                      thousandSeparator={true}
                      allowNegative={false}
                      decimalScale={2}
                      tag={NumberFormat}
                      value={amount}
                      onValueChange={(e: any) => setAmount(e.floatValue)}
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <label className="form-control-label">Description</label>
                    <Input
                      className="form-control-alternative"
                      placeholder="Description"
                      type="text"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col xl="12">
                  {stripePK && (
                    <StripeCheckout
                      stripeKey={stripePK || ''}
                      token={handlePayment}
                      name="Stripe Payment"
                      panelLabel={`Pay`}
                      currency="USD"
                      amount={amount * 100}
                    >
                      <button className="btn btn-primary">Pay With Card</button>
                    </StripeCheckout>
                  )}
                </Col>
                {!loading && lastTransaction ? (
                  <Col xl="12" className="mt-4">
                    <div>
                      <h3>RESULT:</h3>
                      <pre>{JSON.stringify(lastTransaction, null, 2)}</pre>
                    </div>
                  </Col>
                ) : (
                  <></>
                )}
                {loading && (
                  <Col xl="12" className="mt-4 text-center">
                    <span>
                      <Spinner />
                    </span>
                  </Col>
                )}
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default CreditCardTransaction;
