import moment from 'moment';
import { Modal } from 'reactstrap';

interface RaiseDetailProps {
  isOpen: boolean;
  toggle: () => void;
  data: any;
}

const RaiseDetail = ({ isOpen, toggle, data }: RaiseDetailProps) => {
  return (
    <Modal className="modal-dialog-centered" isOpen={isOpen} toggle={toggle} size={'lg'}>
      <div className="modal-header pb-0 pt-4">
        <div className="d-flex align-items-center">
          <div style={{ width: '35px', height: '35px', borderRadius: '50%', overflow: 'hidden' }}>
            <img
              src={data?.user.picture}
              alt="User Profile"
              className="img-fluid"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </div>
          <span className="ml-2">{`${data?.user.firstName || '-'} ${data?.user.lastName || '-'}`}</span>
        </div>
        <div onClick={toggle} className="d-flex p-1 cursor-pointer">
          <i className="fas fa-times" />
        </div>
      </div>
      <div className="modal-body px-5 pt-0">
        <hr />
        <div className="d-flex align-items-center" style={{ gap: 80 }}>
          <div className="d-flex flex-column justify-content-start" style={{ gap: 15 }}>
            <h4 className="p-0 m-0">Current Pay Rate</h4>
            <h4 className="p-0 m-0">Amount</h4>
            <h4 className="p-0 m-0">% Increase</h4>
            <h4 className="p-0 m-0">Date Adding To Payroll</h4>
            <h4 className="p-0 m-0">Reason</h4>
          </div>

          <div className="d-flex flex-column justify-content-center" style={{ gap: 15 }}>
            <p className="p-0 m-0">$ {parseFloat(data?.oldAmount).toFixed(2)}</p>
            <p className="p-0 m-0">$ {parseFloat(data?.amount).toFixed(2)}</p>
            <p className="p-0 m-0">{(((data?.amount - data?.oldAmount) / data?.oldAmount) * 100).toFixed(2)} %</p>
            <p className="p-0 m-0">{moment(data?.dateItBecomesEffectiveOn).format('MMMM D, YYYY')}</p>
            <p className="p-0 m-0">{data?.reason}</p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RaiseDetail;
