/* eslint-disable prefer-spread */
import { Card, CardHeader, CardBody, Container, Row, Col, Button } from 'reactstrap';
import CommonHeader from '../../../components/Headers/CommonHeader';
import { useContext, useState, useEffect } from 'react';
import { pdfjs } from 'react-pdf';
import { useParams } from 'react-router-dom';
import { FilteredOptionsInterface } from '../../../entities/FilteredOptions';
import Documents from '../../../services/Documents';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import { createOptionsPage } from '../../../entities/OptionsPaged';
import { createPagedMeta } from '../../../entities/PagedMeta';
import { Link } from 'react-router-dom';
const DocumentsPdfShow = () => {
  const { id } = useParams<{ id: string }>();
  const [pagedMeta] = useState(createPagedMeta());
  const [filteredOptions] = useState({
    filters: [],
    options: createOptionsPage(pagedMeta),
  });
  const { token } = useContext(AuthContext);
  const [title, setTitle] = useState('');
  const [pdfString, setPdfString] = useState('');
  const [urlBack, setUrlBack] = useState('');

  const loadPdf = async (filteredOptions: FilteredOptionsInterface) => {
    const filterId = {
      field: 'id',
      operation: '=',
      value: id,
    };

    filteredOptions.filters = [filterId];

    const document = await Documents(token || '').show(id);
    setTitle(document.data.name);
    changeUrlBack('/resource#' + document.data.documentsFoldersId);
    setPdfString(document.data.attachment);
  };

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    loadPdf(filteredOptions);
  }, [id]);

  const changeUrlBack = (newUrl: string) => {
    const urlParams = new URLSearchParams(window.location.search);
    const backUrl = urlParams.get('back');
    if (backUrl === undefined || backUrl === null) {
      setUrlBack(newUrl);
      return;
    }
    setUrlBack('/' + backUrl);
  };

  return (
    <>
      <CommonHeader />
      <Container className="mt--8" fluid>
        <Row>
          <Col className="order-xl-2" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="12">
                    <Row>
                      <Col xs="8">
                        <h3 className="mb-0">{title}</h3>
                      </Col>
                      <Col className="text-right" xs="4">
                        <Link to={urlBack}>
                          <Button color="primary" className="float-right">
                            Back
                          </Button>
                        </Link>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12">
                        <p> </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <iframe
                  src={`${pdfString}#toolbar=0&scrollbar=0&scrolling=0&embedded=true&view=FitH`}
                  style={{ width: '100%', minHeight: '60vh' }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default DocumentsPdfShow;
