import http from '../helpers/http';

const PayCodes = (token: string) => {
  const headers = {
    'x-access-token': token,
  };

  return {
    list: async () => {
      const result = await http.get(`/pay-codes`, {
        headers,
      });
      return result.data;
    },
    create: async (body: { name: string }) => {
      const result = await http.post(`/pay-codes`, body, { headers });
      return result.data;
    },
    delete: async (id: string) => {
      const result = await http.delete(`/pay-codes/${id}`, {
        headers,
      });
      return result.data;
    },
  };
};

export default PayCodes;
