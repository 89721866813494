import {
  Card,
  Button,
  Container,
  Row,
  Col,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from 'reactstrap';
import { useContext, useState, useEffect, useRef } from 'react';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import { toast } from 'react-toastify';
import ImmunizationKidsService from '../../../services/ImmunizationKids';
import history from '../../../history';
import { toLocaleFormatSlash } from '../../../helpers/dateFormat';
import ReactDatetime from 'react-datetime';

type KidsIdProps = {
  kidsId: string;
  onModalStatus: () => void;
};

const ImmunizationKidsForm = ({ kidsId, onModalStatus }: KidsIdProps) => {
  const { token }: any = useContext(AuthContext);
  const fileRef = useRef(null as any);

  const [selectList, setSelectList] = useState([{}]);
  const [loading, setLoading] = useState(false);

  const [immunizationId, setImmunizationId] = useState('');
  const [validAt, setValidAt] = useState('');
  const [picture, setPicture] = useState(null as any);

  const changeonModalStatus = () => {
    onModalStatus();
  };

  useEffect(() => {
    listSelectImmunization();
  }, [kidsId]);

  const onChangeFile = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];
    setPicture(file);
  };

  const listSelectImmunization = async () => {
    try {
      const result = await ImmunizationKidsService(token).listSelectImmunization();
      if (result?.data?.length) {
        setImmunizationId(result.data[0]?.id);
      }
      setSelectList(result.data || []);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No Immunizations found');
    }
  };

  const showOpenFileDialog = () => {
    if (fileRef?.current?.click) {
      fileRef.current.click();
    }
  };

  const handleSubmitForm = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);

    const body = {
      immunizationId,
      kidsId,
      validAt: validAt ? toLocaleFormatSlash(validAt) : '',
    };

    try {
      const result = await ImmunizationKidsService(token).create(body);

      if (picture) {
        const formData = new FormData();
        formData.append('picture', picture as any);
        await ImmunizationKidsService(token).picture(result?.data?.id, formData);
      }

      toast.success(`Immunization Created successfully!`);
      if (result?.data?.id) {
        onModalStatus();
        history.push(`/kids/${kidsId}`);
      }
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Create error!');
      toast.error(message);
    }

    setLoading(false);
  };

  return (
    <>
      <Container fluid>
        <Form onSubmit={handleSubmitForm}>
          <Card>
            <CardBody>
              <Row>
                <Col lg="12">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-franchisee-id">
                      Immunizations
                    </label>
                    <Input type="select" onChange={(e) => setImmunizationId(e.target.value)}>
                      {selectList?.map((filter: any, index: number) => {
                        return (
                          <option value={filter.id} key={`filter-button-${index}`}>
                            {filter.name}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-validAt">
                      Exp. Date
                    </label>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-calendar-grid-58" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <ReactDatetime
                        inputProps={{
                          placeholder: 'Exp. Date',
                        }}
                        onChange={(newValue) => {
                          setValidAt(`${newValue}`);
                        }}
                        timeFormat={false}
                        dateFormat={'MM/DD/yyyy'}
                        closeOnSelect={true}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col lg="12">
                  <Button
                    className="btn-icon btn-sm"
                    color="secondary"
                    type="button"
                    id="input-attach"
                    onClick={showOpenFileDialog}
                    style={{ marginBottom: '2px' }}
                  >
                    <span className="btn-inner--icon pt-1" style={{ marginRight: '1px' }}>
                      <i className="fas fa-paperclip" style={{ top: '0' }} />
                    </span>
                  </Button>
                  <span>{picture?.name || 'Select a file'}</span>
                  <input
                    id="img-attach"
                    name="picture"
                    type="file"
                    accept=".png,.jpg,.jpeg"
                    style={{ display: 'none' }}
                    ref={fileRef}
                    onChange={onChangeFile}
                  />
                </Col>
              </Row>
              <Row className="mt-5">
                <Col md="6 text-center">
                  <Button color="primary" type="submit" size="lg" style={{ minWidth: '100px' }}>
                    Save
                  </Button>
                </Col>
                <Col md="6 text-center">
                  <Button color="secondary" size="lg" style={{ minWidth: '100px' }} onClick={changeonModalStatus}>
                    Cancel
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Form>
      </Container>
    </>
  );
};

export default ImmunizationKidsForm;
