export const i9EnglishFields = [
  { type: 'title', label: 'Employment Eligibility Verification - Section 1' },
  { i: 98, type: 'row', label: 'Last Name (Family Name)' },
  { i: 5, type: 'row', label: 'First Name (Given Name)' },
  { i: 6, type: 'row', label: 'Middle Initial (if any)' },
  { i: 7, type: 'row', label: 'Other Last Names Used (if any)' },
  { i: 99, type: 'row', label: 'Address (Street Number and Name)' },
  { i: 100, type: 'row', label: 'Apt. Number (if any)' },
  { i: 101, type: 'row', label: 'City or Town' },
  { i: 93, type: 'row', label: 'State' },
  { i: 94, type: 'row', label: 'ZIP Code' },
  { i: 95, type: 'row', label: 'Date of Birth (mm/dd/yyyy)' },
  { i: 96, type: 'row', label: 'U.S. Social Security Number' },
  { i: 102, type: 'row', label: `Employee's Email Address` },
  { i: 97, type: 'row', label: `Employee's Telephone Number` },
  { i: 47, type: 'row', label: `1. A citizen of the United States`, size: '12' },
  { i: 48, type: 'row', label: `2. A noncitizen national of the United States (See Instructions.)`, size: '12' },
  { i: 49, type: 'row', label: `3. A lawful permanent resident (Enter USCIS or A-Number.)` },
  { i: 0, type: 'row', label: `` },
  {
    i: 50,
    type: 'row',
    label: `4. A noncitizen (other than Item Numbers 2. and 3. above) authorized to work until (exp. date, if any)`,
  },
  { i: 103, type: 'row', label: `` },
  { i: 104, type: 'row', label: `USCIS A-Number` },
  { i: 105, type: 'row', label: `Form I-94 Admission Number` },
  { i: 106, type: 'row', label: `Foreign Passport Number and Country of Issuance` },
  { i: 8, type: 'row', label: `Today's Date (mm/dd/yyyy)` },

  { type: 'title', label: 'Employment Eligibility Verification - Section 2' },

  { i: 107, type: 'row', label: `Document Title 1 - List A`, size: '4' },
  { i: 13, type: 'row', label: `Document Title 1 - List B`, size: '4' },
  { i: 117, type: 'row', label: `Document Title 1 - List C`, size: '4' },
  { i: 108, type: 'row', label: `Issuing Authority - List A`, size: '4' },
  { i: 125, type: 'row', label: `Issuing Authority - List B`, size: '4' },
  { i: 116, type: 'row', label: `Issuing Authority - List C`, size: '4' },
  { i: 109, type: 'row', label: `Document Number (if any) - List A`, size: '4' },
  { i: 126, type: 'row', label: `Document Number (if any) - List B`, size: '4' },
  { i: 115, type: 'row', label: `Document Number (if any) - List C`, size: '4' },
  { i: 124, type: 'row', label: `Expiration Date (if any) - List A`, size: '4' },
  { i: 127, type: 'row', label: `Expiration Date (if any) - List B`, size: '4' },
  { i: 114, type: 'row', label: `Expiration Date (if any) - List C`, size: '4' },
  { i: 110, type: 'row', label: `Document Title 2 (if any)`, size: '12' },
  { i: 111, type: 'row', label: `Issuing Authority`, size: '12' },
  { i: 112, type: 'row', label: `Document Number (if any)`, size: '12' },
  { i: 9, type: 'row', label: `Expiration Date (if any)`, size: '12' },
  { i: 10, type: 'row', label: `Document Title 3 (if any)`, size: '12' },
  { i: 11, type: 'row', label: `Issuing Authority`, size: '12' },
  { i: 12, type: 'row', label: `Document Number (if any)`, size: '12' },
  { i: 113, type: 'row', label: `Expiration Date (if any)`, size: '12' },
  { i: 14, type: 'row', label: `Additional Information`, size: '12' },

  { type: 'title', label: 'Certification' },

  { i: 118, type: 'row', label: `First Day of Employment (mm/dd/yyyy)` },
  { i: 120, type: 'row', label: `Last Name, First Name and Title of Employer or Authorized Representative` },
  { i: 121, type: 'row', label: `Signature of Employer or Authorized Representative` },
  { i: 119, type: 'row', label: `Today's Date (mm/dd/yyyy)` },
  { i: 122, type: 'row', label: `Employer's Business or Organization Name` },
  { i: 123, type: 'row', label: `Employer's Business or Organization Address, City or Town, State, ZIP Code` },

  { type: 'title', label: 'Supplement A' },

  { i: 2, type: 'row', label: `Last Name (Family Name) from Section 1` },
  { i: 3, type: 'row', label: `First Name (Given Name) from Section 1` },
  { i: 4, type: 'row', label: `Middle initial (if any) from Section 1.` },

  {
    i: 0,
    type: 'collapse',
    label: 'Preparer or Translator',
    rows: [
      { i: 20, type: 'row', label: `Signature of Preparer or Translator` },
      { i: 17, type: 'row', label: `Date (mm/dd/yyyy)` },
      { i: 24, type: 'row', label: `Last Name (Family Name)` },
      { i: 31, type: 'row', label: `First Name (Given Name)` },
      { i: 6, type: 'row', label: `Middle Initial (if any)` },
      { i: 27, type: 'row', label: `Address (Street Number and Name)` },
      { i: 35, type: 'row', label: `City or Town` },
      { i: 39, type: 'row', label: `State` },
      { i: 43, type: 'row', label: `ZIP Code` },
    ],
  },

  {
    i: 1,
    type: 'collapse',
    label: 'Preparer or Translator',
    rows: [
      { i: 21, type: 'row', label: `Signature of Preparer or Translator` },
      { i: 16, type: 'row', label: `Date (mm/dd/yyyy)` },
      { i: 32, type: 'row', label: `Last Name (Family Name)` },
      { i: 32, type: 'row', label: `First Name (Given Name)` },
      { i: 6, type: 'row', label: `Middle Initial (if any)` },
      { i: 28, type: 'row', label: `Address (Street Number and Name)` },
      { i: 36, type: 'row', label: `City or Town` },
      { i: 40, type: 'row', label: `State` },
      { i: 44, type: 'row', label: `ZIP Code` },
    ],
  },

  {
    i: 2,
    type: 'collapse',
    label: 'Preparer or Translator',
    rows: [
      { i: 21, type: 'row', label: `Signature of Preparer or Translator` },
      { i: 16, type: 'row', label: `Date (mm/dd/yyyy)` },
      { i: 32, type: 'row', label: `Last Name (Family Name)` },
      { i: 32, type: 'row', label: `First Name (Given Name)` },
      { i: 6, type: 'row', label: `Middle Initial (if any)` },
      { i: 28, type: 'row', label: `Address (Street Number and Name)` },
      { i: 36, type: 'row', label: `City or Town` },
      { i: 40, type: 'row', label: `State` },
      { i: 44, type: 'row', label: `ZIP Code` },
    ],
  },

  {
    i: 3,
    type: 'collapse',
    label: 'Preparer or Translator',
    rows: [
      { i: 22, type: 'row', label: `Signature of Preparer or Translator` },
      { i: 18, type: 'row', label: `Date (mm/dd/yyyy)` },
      { i: 25, type: 'row', label: `Last Name (Family Name)` },
      { i: 33, type: 'row', label: `First Name (Given Name)` },
      { i: 6, type: 'row', label: `Middle Initial (if any)` },
      { i: 29, type: 'row', label: `Address (Street Number and Name)` },
      { i: 37, type: 'row', label: `City or Town` },
      { i: 41, type: 'row', label: `State` },
      { i: 45, type: 'row', label: `ZIP Code` },
    ],
  },

  {
    i: 4,
    type: 'collapse',
    label: 'Preparer or Translator',
    rows: [
      { i: 23, type: 'row', label: `Signature of Preparer or Translator` },
      { i: 19, type: 'row', label: `Date (mm/dd/yyyy)` },
      { i: 26, type: 'row', label: `Last Name (Family Name)` },
      { i: 34, type: 'row', label: `First Name (Given Name)` },
      { i: 6, type: 'row', label: `Middle Initial (if any)` },
      { i: 30, type: 'row', label: `Address (Street Number and Name)` },
      { i: 38, type: 'row', label: `City or Town` },
      { i: 41, type: 'row', label: `State` },
      { i: 42, type: 'row', label: `ZIP Code` },
    ],
  },

  { type: 'title', label: 'Supplement B' },

  { i: 91, type: 'row', label: `Last Name (Family Name) from Section 1.` },
  { i: 92, type: 'row', label: `First Name (Given Name) from Section 1.` },
  { i: 15, type: 'row', label: `Middle initial (if any) from Section 1.` },
  { i: 51, type: 'row', label: `Date of Rehire (mm/dd/yyyy)` },
  { i: 57, type: 'row', label: `New Name (if applicable)` },
  { i: 60, type: 'row', label: `First Name (Given Name)` },

  {
    i: 5,
    type: 'collapse',
    label: 'Reverification',
    rows: [
      { i: 70, type: 'row', label: `Document Title` },
      { i: 72, type: 'row', label: `Document Number (if any)` },
      { i: 75, type: 'row', label: `Expiration Date (if any) (mm/dd/yyyy)` },
      { i: 78, type: 'row', label: `Name of Employer or Authorized Representative` },
      { i: 81, type: 'row', label: `Signature of Employer or Authorized Representative` },
      { i: 54, type: 'row', label: `Today's Date (mm/dd/yyyy)` },
      { i: 87, type: 'row', label: `Additional Information (Initial and date each notation.) ` },
      {
        i: 84,
        label: `Check here if you used an alternative procedure authorized by DHS to examine documents.`,
        size: '12',
      },
    ],
  },

  {
    i: 6,
    type: 'collapse',
    label: 'Reverification',
    rows: [
      { i: 107, type: 'row', label: `Document Title` },
      { i: 73, type: 'row', label: `Document Number (if any)` },
      { i: 77, type: 'row', label: `Expiration Date (if any) (mm/dd/yyyy)` },
      { i: 79, type: 'row', label: `Name of Employer or Authorized Representative` },
      { i: 82, type: 'row', label: `Signature of Employer or Authorized Representative` },
      { i: 55, type: 'row', label: `Today's Date (mm/dd/yyyy)` },
      { i: 89, type: 'row', label: `Additional Information (Initial and date each notation.) ` },
      {
        i: 85,
        label: `Check here if you used an alternative procedure authorized by DHS to examine documents.`,
        size: '12',
      },
    ],
  },

  {
    i: 7,
    type: 'collapse',
    label: 'Reverification',
    rows: [
      { i: 71, type: 'row', label: `Document Title` },
      { i: 74, type: 'row', label: `Document Number (if any)` },
      { i: 76, type: 'row', label: `Expiration Date (if any) (mm/dd/yyyy)` },
      { i: 80, type: 'row', label: `Name of Employer or Authorized Representative` },
      { i: 83, type: 'row', label: `Signature of Employer or Authorized Representative` },
      { i: 56, type: 'row', label: `Today's Date (mm/dd/yyyy)` },
      { i: 88, type: 'row', label: `Additional Information (Initial and date each notation.) ` },
      {
        i: 86,
        label: `Check here if you used an alternative procedure authorized by DHS to examine documents.`,
        size: '12',
      },
    ],
  },
];
