/* eslint-disable max-lines */
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardText,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
} from 'reactstrap';
import { toast } from 'react-toastify';
import { useContext, useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import ReactDatetime from 'react-datetime';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import CommonHeader from '../../../components/Headers/CommonHeader';
import KidsService from '../../../services/Kids';
import history from '../../../history';
import SchoolService from '../../../services/School';
import ClassService from '../../../services/Class';
import { toLocaleFormatSlash } from '../../../helpers/dateFormat';
import ImmunizationKidsList from '../Immunization/ImmunizationKidsList';
import KidsParentsList from '../Kids/KidsParentsList';
import PriceSheetService from '../../../services/PriceSheet';
import NumberFormat from 'react-number-format';
import DiscountsModal from './DiscountsModal';
import { validEnrollmentStatus } from '../../../interfaces/KidsInterface';
import DiscountsKidsList from './DiscountsKidsList';
import KidsRemindersList from './KidsRemindersList';
import moment, { Moment } from 'moment';
import EnrollmentInformationsModal from './EnrollmentInformationsModal';
import UserCreateContactModal from '../Users/UserCreateContactModal';
import VacationTimeKidsList from './VacationTimeKidsList';
import PromoCodeKidsList from './PromoCodeKidsList';
import isEducationDirectorAdminAssistant from '../../../helpers/isEducationDirectorAdminAssistant';
import UploadAndCropImage from '../../../components/Core/UploadAndCropImage';

const KidsCreate = () => {
  const { id } = useParams<{ id: string }>();
  const pageTitle = id ? 'Update' : 'Create';
  const { kids, token, currentSchool, user }: any = useContext(AuthContext);
  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [classTypeList, setClassTypeList] = useState([]);
  const [priceSheetList, setPriceSheetList] = useState([]);
  const [currentClassType, setCurrentClassType] = useState('');

  const [kidsObj, setKidsObj] = useState({} as any);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [birthday, setBirthday] = useState('');
  const [gender, setGender] = useState('');
  const [isAlergyMedical, setIsAlergyMedical] = useState(false);
  const [alergyMedical, setAlergyMedical] = useState('');
  const [isPrescriptions, setIsPrescriptions] = useState(false);
  const [prescriptions, setPrescriptions] = useState('');
  const [isDiaperCream, setIsDiaperCream] = useState(false);
  const [diaperCream, setDiaperCream] = useState('');
  const [isSunscreen, setIsSunscreen] = useState(false);
  const [sunscreen, setSunscreen] = useState('');
  const [doctor, setDoctor] = useState('');
  const [dentist, setDentist] = useState('');
  const [health, setHealth] = useState('');
  const [insurance, setInsurance] = useState('');

  const [profileNotes, setProfileNotes] = useState('');
  const [otherNotes, setOtherNotes] = useState('');
  const [systemPhotoPermission, setSystemPhotoPermission] = useState(false);
  const [socialMediaPhotoPermission, setSocialMediaPhotoPermission] = useState(false);
  const [pictureUrl, setPictureUrl] = useState(kids?.picture || '');
  const [picture, setPicture] = useState<any>();
  const [immunizationRequired, setImmunizationRequired] = useState(false);
  const [selectList, setSelectList] = useState([{}]);
  const [classList, setClassList] = useState([{}]);
  const [schoolId, setSchoolId] = useState('');
  const [classId, setClassId] = useState('');
  const [enrollmentStatus, setEnrollmentStatus] = useState('');
  const [startEnrollmentDate, setStartEnrollmentDate] = useState('');
  const [endEnrollmentDate, setEndEnrollmentDate] = useState('');
  const [desenrollmentReason, setDesenrollmentReason] = useState('');
  const [billingAmount, setBillingAmount] = useState(0);
  const [billingStartDate, setStartBillingDate] = useState('');
  const [billingEndDate, setEndBillingDate] = useState('');

  const [isDiscountsOpen, setIsDiscountsOpen] = useState(false);
  const [isInformationsOpen, setIsInformationsOpen] = useState(false);
  const [isContactOpen, setIsContactOpen] = useState(false);
  const [newContactCreated, setNewContactCreated] = useState<any>({});
  const [isFeedingSchedule, setIsFeedingSchedule] = useState(false);
  const [isNotPottyTrained, setIsNotPottyTrained] = useState(false);
  const [feedingInterval, setFeedingInterval] = useState('2');
  const [availableVacationWeeks, setAvailableVacationWeeks] = useState(1);
  const [promoCode, setPromoCode] = useState({ discountReg: null, distountTuition: null });
  const [modalPicture, setModalPicture] = useState(false);

  const getPriceSheet = async () => {
    try {
      if (classId) {
        const result = await PriceSheetService(token).list(classId);
        const data = result?.data || [];
        setPriceSheetList(data || []);
        const classTypes = data.map((p: any) => p.classType).filter((p: any) => p);
        setClassTypeList(classTypes || []);
      }
    } catch (e: any) {
      console.error(e?.response?.data?.message || 'No Price Sheet Found!');
    }
  };

  const listSelectSchool = async () => {
    try {
      const result = await SchoolService(token).listSelect();
      setSelectList(result.data || []);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No schools found');
    }
  };

  const listClass = async () => {
    try {
      if (schoolId) {
        const result = await ClassService(token).listBySchool(schoolId);
        setClassList(result.data || []);
      }
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No classes found');
    }
  };

  const immunizationPending = async () => {
    try {
      const result = await KidsService(token).listRequiredImmunization(id);
      if (result) {
        setImmunizationRequired(true);
      }
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No immunizations found');
    }
  };

  const getById = async () => {
    try {
      const result = await KidsService(token).show(id);
      if (result?.data) {
        setKidsObj(result?.data);
        setFirstName(result?.data?.firstName || '');
        setLastName(result?.data?.lastName || '');
        setBirthday(result?.data?.birthday ? toLocaleFormatSlash(result?.data?.birthday) : '');
        setGender(result?.data?.gender || '');
        setAlergyMedical(result?.data?.alergyMedical || '');
        setPrescriptions(result?.data?.prescriptions || '');
        setDiaperCream(result?.data?.diaperCream || '');
        setSunscreen(result?.data?.sunscreen || '');
        setDoctor(result?.data?.doctor || '');
        setDentist(result?.data?.dentist || '');
        setHealth(result?.data?.health || '');
        setInsurance(result?.data?.insurance || '');
        setProfileNotes(result?.data?.profileNotes || '');
        setOtherNotes(result?.data?.otherNotes || '');
        setSystemPhotoPermission(result?.data?.systemPhotoPermission || false);
        setSocialMediaPhotoPermission(result?.data?.socialMediaPhotoPermission || false);
        setPictureUrl(result?.data?.picture || '');
        setSchoolId(result?.data?.enrollment?.schoolId || currentSchool.value);
        setClassId(result?.data?.enrollment?.classId || '');
        setCurrentClassType(result?.data?.enrollment?.classTypeId || '');
        setEnrollmentStatus(result?.data?.enrollment?.status || '');
        setStartEnrollmentDate(
          result?.data?.enrollment?.enrollmentStartDate
            ? toLocaleFormatSlash(result?.data?.enrollment?.enrollmentStartDate)
            : '',
        );
        setEndEnrollmentDate(
          result?.data?.enrollment?.enrollmentEndDate
            ? toLocaleFormatSlash(result?.data?.enrollment?.enrollmentEndDate)
            : '',
        );
        setBillingAmount(result?.data?.billingSettings?.amount || 0);
        setStartBillingDate(
          result?.data?.billingSettings?.startDate ? toLocaleFormatSlash(result?.data?.billingSettings?.startDate) : '',
        );
        setEndBillingDate(
          result?.data?.billingSettings?.endDate ? toLocaleFormatSlash(result?.data?.billingSettings?.endDate) : '',
        );
        setAvailableVacationWeeks(result.data.availableVacationWeeks);
        setPromoCode({
          discountReg: result.data.promoCode.discountReg || null,
          distountTuition: result.data.promoCode.discountTuition || null,
        });

        if (result?.data.alergyMedical) {
          setIsAlergyMedical(true);
        }

        if (result?.data.prescriptions) {
          setIsPrescriptions(true);
        }

        if (result?.data.diaperCream) {
          setIsDiaperCream(true);
        }

        if (result?.data.sunscreen) {
          setIsSunscreen(true);
        }

        if (result?.data?.notPottyTrained) {
          setIsNotPottyTrained(true);
        }

        if (result?.data?.feedingSchedule) {
          setIsFeedingSchedule(true);
          setFeedingInterval(result?.data?.feedingIntervalHours ? result?.data?.feedingIntervalHours.toString() : '');
        }
      }
    } catch (e: any) {
      console.log(e);
      toast.error(e?.response?.data?.message || 'Invalid Kids ID');
    }
  };

  useEffect(() => {
    listSelectSchool();
    setSchoolId(currentSchool.value || '');
    if (id) {
      getById();
      immunizationPending();
    }
  }, []);

  useEffect(() => {
    setSchoolId(currentSchool.value || '');
  }, [currentSchool]);

  useEffect(() => {
    listClass();
  }, [schoolId]);

  useEffect(() => {
    getPriceSheet();
  }, [classId]);

  useEffect(() => {
    const priceSheet: any = priceSheetList.find((p: any) => p.classTypeId === currentClassType);
    setBillingAmount(Number(priceSheet?.tuition || 0));
  }, [currentClassType]);

  const showOpenFileDialog = () => {
    if (isDisabled) {
      return;
    }
    setModalPicture(!modalPicture);
  };

  const onChangePicture = (blob: Blob, imageUrl: string) => {
    setPicture(blob);
    setPictureUrl(imageUrl);
    setModalPicture(!modalPicture);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);
    setIsDisabled(true);

    const body: any = {
      firstName,
      lastName,
      birthday: birthday ? toLocaleFormatSlash(birthday) : '',
      gender,
      systemPhotoPermission,
      socialMediaPhotoPermission,
      feedingSchedule: isFeedingSchedule,
      notPottyTrained: isNotPottyTrained,
    };
    if (alergyMedical) {
      body.alergyMedical = alergyMedical;
    }
    if (prescriptions) {
      body.prescriptions = prescriptions;
    }
    if (diaperCream) {
      body.diaperCream = diaperCream;
    }
    if (sunscreen) {
      body.sunscreen = sunscreen;
    }
    if (doctor) {
      body.doctor = doctor;
    }
    if (dentist) {
      body.dentist = dentist;
    }
    if (health) {
      body.health = health;
    }
    if (insurance) {
      body.insurance = insurance;
    }
    if (profileNotes) {
      body.profileNotes = profileNotes;
    }
    if (otherNotes) {
      body.otherNotes = otherNotes;
    }
    if (schoolId) {
      body.schoolId = schoolId;
    }
    if (classId) {
      body.classId = classId;
    }
    if (enrollmentStatus) {
      body.enrollmentStatus = enrollmentStatus;
    }
    if (enrollmentStatus === 'DISENROLLED') {
      body.desenrollmentReason = desenrollmentReason;
    }
    if (startEnrollmentDate) {
      body.enrollmentStartDate = toLocaleFormatSlash(startEnrollmentDate);
    }
    body.enrollmentEndDate = '';
    if (endEnrollmentDate) {
      body.enrollmentEndDate = toLocaleFormatSlash(endEnrollmentDate);
    }
    if (currentClassType) {
      body.classTypeId = currentClassType;
    }
    if (billingAmount) {
      body.billingAmount = billingAmount;
    }
    if (billingStartDate) {
      body.billingStartDate = toLocaleFormatSlash(billingStartDate);
    }
    if (billingEndDate) {
      body.billingEndDate = toLocaleFormatSlash(billingEndDate);
    }
    if (isFeedingSchedule) {
      body.feedingIntervalHours = feedingInterval;
    }

    try {
      const result = id ? await KidsService(token).update(id, body) : await KidsService(token).create(body);

      if (picture) {
        const formData = new FormData();
        formData.append('picture', picture as any);
        await KidsService(token).picture(result?.data?.id, formData);
      }

      toast.success(`${id ? 'Updated' : 'Created'} successfully!`);
      if (result?.data?.id) {
        history.push(`/kids/${result?.data?.id}`);
      }
      e.preventDefault();
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Create error!');
      toast.error(message);
    }

    setLoading(false);
    setIsDisabled(false);
  };

  const toggleModalDiscounts = (e: any) => {
    e?.preventDefault();
    setIsDiscountsOpen(!isDiscountsOpen);
  };

  const toggleModalInformations = (e: any) => {
    e?.preventDefault();
    setIsInformationsOpen(!isInformationsOpen);
  };

  const validEnrollmentEndDate = (current: Moment) => {
    if (!startEnrollmentDate) return true;
    const startDateMoment = moment(startEnrollmentDate);
    return current.isAfter(startDateMoment);
  };

  const validBillingEndDate = (current: Moment) => {
    if (!billingStartDate) return true;
    const startDateMoment = moment(billingStartDate);
    return current.isAfter(startDateMoment);
  };

  const clearEndDate = () => {
    // endEnrollmentDate = null;
    setEndEnrollmentDate('');
  };

  return (
    <>
      <Modal className="modal-dialog-centered" isOpen={modalPicture} toggle={() => setModalPicture(!modalPicture)}>
        <div className="modal-header">
          <h5 className="modal-title">Change Avatar Picture</h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setModalPicture(!modalPicture)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body pt-0">
          <UploadAndCropImage image={pictureUrl} onChange={onChangePicture} />
        </div>
      </Modal>
      <CommonHeader />
      <Container className="mt--8" fluid>
        <UncontrolledDropdown nav className="flutter-options">
          <DropdownToggle className="pr-0" nav style={{ paddingLeft: '0' }}>
            <Button>
              <i className="fas fa-cogs"></i>
            </Button>
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-arrow" right>
            {id && (
              <>
                <DropdownItem to={`/billing/${id}`} tag={Link} className="bg-info text-white">
                  <span>Billing</span>
                </DropdownItem>
                <DropdownItem to={`/kids/${id}`} tag={Link} className="bg-danger text-white">
                  <span>Archive</span>
                </DropdownItem>
              </>
            )}

            <DropdownItem onClick={handleSubmit} to="#" tag={Link} className="bg-primary text-white">
              <span>Save</span>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
        <Row>
          <Col className="order-xl-2" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">{`${pageTitle} Kids`}</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Link to="/kids">
                      <Button color="primary" className="float-right">
                        Back
                      </Button>
                    </Link>
                    {!isEducationDirectorAdminAssistant(user) && (
                      <i
                        className="fas fa-cog fa-lg mr-3 mt-3"
                        style={{ cursor: 'pointer' }}
                        onClick={toggleModalDiscounts}
                      ></i>
                    )}
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmit}>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <Row style={{ display: 'grid' }}>
                          <Row>
                            <Col lg="12">
                              <h6 className="heading-small text-muted mb-4">
                                Picture <small style={{ textTransform: 'none' }}>(Recommended size: 800 x 800px)</small>
                              </h6>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <FormGroup>
                                <img
                                  alt="Kids"
                                  className="rounded-circle"
                                  src={
                                    pictureUrl
                                      ? `${pictureUrl}`
                                      : require('../../../assets/img/user-default.png').default
                                  }
                                  style={{ width: 135, height: 135, cursor: isDisabled ? 'not-allowed' : 'pointer' }}
                                  onClick={showOpenFileDialog}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </Row>
                      </Col>
                      <Col lg="6">
                        {id && !immunizationRequired ? (
                          <Card style={{ backgroundColor: '#F6CED8' }}>
                            <CardBody>
                              <CardText>
                                <Row>
                                  <Col>Immunization pending!</Col>
                                </Row>
                              </CardText>
                            </CardBody>
                          </Card>
                        ) : (
                          ''
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-firstName">
                            First Name
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-firstName"
                            placeholder="First Name"
                            type="text"
                            disabled={isDisabled}
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-lastName">
                            Last Name
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-lastName"
                            placeholder="Last Name"
                            type="text"
                            disabled={isDisabled}
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="3">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-birthday">
                            Birthday
                          </label>
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-calendar-grid-58" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <ReactDatetime
                              inputProps={{
                                placeholder: 'Birthday',
                              }}
                              onChange={(newValue) => {
                                setBirthday(`${newValue}`);
                              }}
                              timeFormat={false}
                              value={`${birthday}`}
                              dateFormat={'MM/DD/yyyy'}
                              closeOnSelect={true}
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-gender">
                            Gender
                          </label>
                          <Input type="select" onChange={(e) => setGender(e.target.value)} value={gender}>
                            <option value="BOY" key={`filter-button-boy`}>
                              Boy
                            </option>
                            <option value="GIRL" key={`filter-button-girl`}>
                              Girl
                            </option>
                            <option value="UNKNOWN" key={`filter-button-unknown`}>
                              Unknown
                            </option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <Row>
                            <Col>
                              <label className="form-control-label" htmlFor="input-systemPhotoPermission2">
                                System Photo Permission
                              </label>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <label className="custom-toggle">
                                <Input
                                  type="checkbox"
                                  onChange={(e) => setSystemPhotoPermission(e.target.checked)}
                                  checked={systemPhotoPermission}
                                />
                                <span className="custom-toggle-slider rounded-circle" />
                              </label>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <Row>
                            <Col>
                              <label className="form-control-label" htmlFor="input-socialMediaPhotoPermission">
                                Social Media Photo Permission
                              </label>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <label className="custom-toggle">
                                <Input
                                  type="checkbox"
                                  onChange={(e) => setSocialMediaPhotoPermission(e.target.checked)}
                                  checked={socialMediaPhotoPermission}
                                />
                                <span className="custom-toggle-slider rounded-circle" />
                              </label>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <Row>
                            <Col>
                              <label className="form-control-label" htmlFor="input-systemPhotoPermission2">
                                Not Potty Trained
                              </label>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <label className="custom-toggle">
                                <Input
                                  type="checkbox"
                                  onChange={(e) => setIsNotPottyTrained(e.target.checked)}
                                  checked={isNotPottyTrained}
                                />
                                <span className="custom-toggle-slider rounded-circle" />
                              </label>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Col>
                      <Col lg="1">
                        <FormGroup>
                          <Row>
                            <Col>
                              <label className="form-control-label" htmlFor="input-socialMediaPhotoPermission">
                                Feeding Schedule
                              </label>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <label className="custom-toggle">
                                <Input
                                  type="checkbox"
                                  onChange={(e) => setIsFeedingSchedule(e.target.checked)}
                                  checked={isFeedingSchedule}
                                />
                                <span className="custom-toggle-slider rounded-circle" />
                              </label>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Col>
                      {isFeedingSchedule && (
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label" htmlFor="input-gender">
                              Feeding Interval
                            </label>
                            <Input
                              type="select"
                              onChange={(e) => setFeedingInterval(e.target.value)}
                              value={feedingInterval}
                              defaultValue={2}
                            >
                              <option value="2" key={`filter-button-boy`} selected>
                                2 hours
                              </option>
                              <option value="3" key={`filter-button-girl`}>
                                3 hours
                              </option>
                              <option value="4" key={`filter-button-unknown`}>
                                4 hours
                              </option>
                            </Input>
                          </FormGroup>
                        </Col>
                      )}
                    </Row>
                  </div>

                  <hr className="my-4" />
                  <Row>
                    <Col lg="6">
                      <h6 className="heading-small text-muted mb-4">Enrollment</h6>
                    </Col>
                    <Col lg="6" className="text-right">
                      <Button color="primary" href="#a" onClick={toggleModalInformations} size="sm">
                        Enrollment Details
                      </Button>
                    </Col>
                  </Row>

                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-school-id">
                            School
                          </label>
                          <Input type="select" onChange={(e) => setSchoolId(e.target.value)} value={schoolId}>
                            {selectList?.map((filter: any, index: number) => {
                              return (
                                <option value={filter.id} key={`filter-button-${index}`}>
                                  {filter.name}
                                </option>
                              );
                            })}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label">Class</label>
                          <Input type="select" onChange={(e) => setClassId(e.target.value)} value={classId} required>
                            <option value=""></option>
                            {classList?.map((c: any, index: number) => {
                              return (
                                <option value={c.id} key={`class-${index}`}>
                                  {c.name}
                                </option>
                              );
                            })}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <label className="form-control-label">Class Type</label>
                          <Input
                            type="select"
                            onChange={(e) => setCurrentClassType(e.target.value)}
                            value={currentClassType}
                          >
                            <option value=""></option>
                            {classTypeList.map((a: any, index: number) => {
                              return (
                                <option value={a.id} key={`class-type-${index}`}>
                                  {a.name}
                                </option>
                              );
                            })}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <label className="form-control-label">Status</label>
                          <Input
                            type="select"
                            onChange={(e) => setEnrollmentStatus(e.target.value)}
                            value={enrollmentStatus}
                          >
                            <option value=""></option>
                            {validEnrollmentStatus.map((a: any, index: number) => {
                              return (
                                <option value={a.value} key={`enrollment-status-${index}`}>
                                  {a.label}
                                </option>
                              );
                            })}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <label className="form-control-label">Start Date</label>
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-calendar-grid-58" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <ReactDatetime
                              inputProps={{
                                placeholder: 'Start Date',
                              }}
                              onChange={(newValue) => {
                                setStartEnrollmentDate(`${newValue}`);
                              }}
                              timeFormat={false}
                              value={`${startEnrollmentDate}`}
                              dateFormat={'MM/DD/yyyy'}
                              closeOnSelect={true}
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <label className="form-control-label">End Date</label>
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-calendar-grid-58" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <ReactDatetime
                              inputProps={{
                                placeholder: 'End Date',
                              }}
                              onChange={(newValue) => {
                                setEndEnrollmentDate(`${newValue}`);
                              }}
                              timeFormat={false}
                              value={`${endEnrollmentDate}`}
                              dateFormat={'MM/DD/yyyy'}
                              isValidDate={validEnrollmentEndDate}
                              closeOnSelect={true}
                            />
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText onClick={clearEndDate}>
                                <i className="ni ni-fat-remove" />
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      {enrollmentStatus === 'DISENROLLED' && (
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label" htmlFor="input-firstName">
                              Reason for Disenrollment
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-firstName"
                              placeholder="Reason for Disenrollment"
                              type="text"
                              value={desenrollmentReason}
                              onChange={(e) => setDesenrollmentReason(e.target.value)}
                            />
                          </FormGroup>
                        </Col>
                      )}
                    </Row>
                  </div>

                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4" id="billing-title">
                    Billing
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label">Amount</label>
                          <Input
                            type="text"
                            thousandsGroupStyle="thousand"
                            prefix="$"
                            decimalSeparator="."
                            displayType="input"
                            thousandSeparator={true}
                            allowNegative={false}
                            decimalScale={2}
                            tag={NumberFormat}
                            value={billingAmount}
                            onValueChange={(e: any) => setBillingAmount(e.floatValue)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label">Start Billing</label>
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-calendar-grid-58" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <ReactDatetime
                              inputProps={{
                                placeholder: 'Start Billing',
                              }}
                              onChange={(newValue) => {
                                setStartBillingDate(`${newValue}`);
                              }}
                              timeFormat={false}
                              value={`${billingStartDate}`}
                              dateFormat={'MM/DD/yyyy'}
                              closeOnSelect={true}
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label">End Billing</label>
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-calendar-grid-58" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <ReactDatetime
                              inputProps={{
                                placeholder: 'End Billing',
                              }}
                              onChange={(newValue) => {
                                setEndBillingDate(`${newValue}`);
                              }}
                              timeFormat={false}
                              value={`${billingEndDate}`}
                              dateFormat={'MM/DD/yyyy'}
                              isValidDate={validBillingEndDate}
                              closeOnSelect={true}
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      {!!id && (
                        <Col lg="12">
                          <DiscountsKidsList kidsId={id} billingAmount={billingAmount} isChanged={isDiscountsOpen} />
                        </Col>
                      )}
                      {!!id && (
                        <Col lg="12">
                          <VacationTimeKidsList kidsId={id} availableVacationWeeks={availableVacationWeeks} />
                        </Col>
                      )}
                      {!!id && (
                        <Col lg="12">
                          <PromoCodeKidsList
                            kidsId={id}
                            promoCodeDiscountReg={promoCode.discountReg}
                            promoCodeDistountTuition={promoCode.distountTuition}
                          />
                        </Col>
                      )}
                    </Row>
                  </div>

                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">Records</h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="3">
                        <FormGroup>
                          <Row>
                            <Col>
                              <label className="form-control-label" htmlFor="input-systemPhotoPermission2">
                                Allergy Medical?
                              </label>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <label className="custom-toggle">
                                <Input
                                  type="checkbox"
                                  onChange={(e) => {
                                    setIsAlergyMedical(e.target.checked);
                                    setAlergyMedical('');
                                  }}
                                  checked={isAlergyMedical}
                                />
                                <span className="custom-toggle-slider rounded-circle" />
                              </label>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Col>
                      {isAlergyMedical && (
                        <Col lg="9">
                          <FormGroup>
                            <label className="form-control-label" htmlFor="input-alergyMedical">
                              Allergy Medical
                            </label>
                            <textarea
                              className="form-control"
                              id="input-alergyMedical"
                              placeholder="Alergy Medical"
                              disabled={isDisabled}
                              value={alergyMedical}
                              onChange={(e) => setAlergyMedical(e.target.value)}
                              required
                            />
                          </FormGroup>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      <Col lg="3">
                        <FormGroup>
                          <Row>
                            <Col>
                              <label className="form-control-label">Prescriptions?</label>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <label className="custom-toggle">
                                <Input
                                  type="checkbox"
                                  onChange={(e) => {
                                    setIsPrescriptions(e.target.checked);
                                    setPrescriptions('');
                                  }}
                                  checked={isPrescriptions}
                                />
                                <span className="custom-toggle-slider rounded-circle" />
                              </label>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Col>
                      {isPrescriptions && (
                        <Col lg="9">
                          <FormGroup>
                            <label className="form-control-label" htmlFor="input-perscriptions">
                              Prescriptions
                            </label>
                            <textarea
                              className="form-control"
                              id="input-perscriptions"
                              placeholder="Prescriptions"
                              disabled={isDisabled}
                              value={prescriptions}
                              onChange={(e) => setPrescriptions(e.target.value)}
                              required
                            />
                          </FormGroup>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      <Col lg="3">
                        <FormGroup>
                          <Row>
                            <Col>
                              <label className="form-control-label">Diaper Cream?</label>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <label className="custom-toggle">
                                <Input
                                  type="checkbox"
                                  onChange={(e) => {
                                    setIsDiaperCream(e.target.checked);
                                    setDiaperCream('');
                                  }}
                                  checked={isDiaperCream}
                                />
                                <span className="custom-toggle-slider rounded-circle" />
                              </label>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Col>
                      {isDiaperCream && (
                        <Col lg="9">
                          <FormGroup>
                            <label className="form-control-label" htmlFor="input-diaper-cream">
                              Diaper Cream
                            </label>
                            <textarea
                              className="form-control"
                              id="input-diaper-cream"
                              placeholder="Diaper Cream"
                              disabled={isDisabled}
                              value={diaperCream}
                              onChange={(e) => setDiaperCream(e.target.value)}
                              required
                            />
                          </FormGroup>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      <Col lg="3">
                        <FormGroup>
                          <Row>
                            <Col>
                              <label className="form-control-label">Sunscreen?</label>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <label className="custom-toggle">
                                <Input
                                  type="checkbox"
                                  onChange={(e) => {
                                    setIsSunscreen(e.target.checked);
                                    setSunscreen('');
                                  }}
                                  checked={isSunscreen}
                                />
                                <span className="custom-toggle-slider rounded-circle" />
                              </label>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Col>
                      {isSunscreen && (
                        <Col lg="9">
                          <FormGroup>
                            <label className="form-control-label" htmlFor="input-sunscreen">
                              Sunscreen
                            </label>
                            <textarea
                              className="form-control"
                              id="input-sunscreen"
                              placeholder="Sunscreen"
                              disabled={isDisabled}
                              value={sunscreen}
                              onChange={(e) => setSunscreen(e.target.value)}
                              required
                            />
                          </FormGroup>
                        </Col>
                      )}
                    </Row>
                  </div>

                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="12">{id ? <ImmunizationKidsList kidsId={id} /> : ''}</Col>
                    </Row>
                  </div>

                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">Medical Info</h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-doctor">
                            Doctor
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-doctor"
                            placeholder="Doctor"
                            type="text"
                            disabled={isDisabled}
                            value={doctor}
                            onChange={(e) => setDoctor(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-dentist">
                            Dentist
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-dentist"
                            placeholder="Dentist"
                            type="text"
                            disabled={isDisabled}
                            value={dentist}
                            onChange={(e) => setDentist(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-health">
                            Health
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-health"
                            placeholder="Health"
                            type="text"
                            disabled={isDisabled}
                            value={health}
                            onChange={(e) => setHealth(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-insurance">
                            Insurance
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-insurance"
                            placeholder="Insurance"
                            type="text"
                            disabled={isDisabled}
                            value={insurance}
                            onChange={(e) => setInsurance(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>

                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="12">
                        {id ? (
                          <KidsParentsList
                            kidsId={id}
                            setIsContactOpen={setIsContactOpen}
                            newContactCreated={newContactCreated}
                          />
                        ) : (
                          ''
                        )}
                      </Col>
                    </Row>
                  </div>

                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">Reminders</h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="12">{id ? <KidsRemindersList kidsId={id} /> : ''}</Col>
                    </Row>
                  </div>

                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">Notes</h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-profileNotes">
                            Profile Notes
                          </label>
                          <textarea
                            className="form-control"
                            id="input-profileNotes"
                            placeholder="Profile Notes"
                            disabled={isDisabled}
                            value={profileNotes}
                            onChange={(e) => setProfileNotes(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-otherNotes">
                            Other Notes
                          </label>
                          <textarea
                            className="form-control"
                            id="input-otherNotes"
                            placeholder="Other Notes"
                            disabled={isDisabled}
                            value={otherNotes}
                            onChange={(e) => setOtherNotes(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>

                  <div className="pl-lg-4">
                    <Button className="my-4" color="primary" type="submit" disabled={isDisabled}>
                      Save
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <DiscountsModal isOpen={isDiscountsOpen} toggleModal={(e) => toggleModalDiscounts(e)} />
      <EnrollmentInformationsModal
        isOpen={isInformationsOpen}
        kids={kidsObj}
        toggleModal={(e) => toggleModalInformations(e)}
      />
      <UserCreateContactModal
        isOpen={isContactOpen}
        toggleModal={(e?: any) => {
          setIsContactOpen(!isContactOpen);
          if (e?.user) {
            setNewContactCreated(e?.user);
          }
        }}
      />
    </>
  );
};

export default KidsCreate;
