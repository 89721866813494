import Avatar from 'react-avatar';
import randomColor from 'randomcolor';

interface AvatarComponentProps {
  name: string;
  size: string;
  userPicture?: string;
}

function generateColor(name: string) {
  return randomColor({
    seed: name,
    luminosity: 'dark',
  });
}

export function AvatarComponent({ name, size, userPicture = '' }: AvatarComponentProps) {
  if (userPicture !== '') {
    const imgStyle = {
      borderRadius: '50%',
      verticalAlign: 'middle',
      marginRight: 10,
    };
    return <img alt="" style={imgStyle} width="28" height="28" src={userPicture} />;
  }
  const initials = name
    .split(' ')
    .map((word) => word[0])
    .join('');
  const color = generateColor(name);
  return <Avatar name={name} initials={initials} color={color} size={size} round />;
}
