import { useEffect } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

type PaymentDataStatusProps = {
  clientSecret?: string;
};

const PaymentDataStatus = ({ clientSecret }: PaymentDataStatusProps) => {
  const stripe = useStripe();
  const history = useHistory();

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const secret = clientSecret || '';
    if (!secret) return;

    stripe.retrieveSetupIntent(secret).then(({ setupIntent }: any) => {
      switch (setupIntent.status) {
        case 'succeeded':
          toast.success('Success! Payment method has been saved.');
          break;

        case 'processing':
          toast.success(`Processing payment details. We'll update you when processing is complete.`);
          break;

        case 'requires_payment_method':
          toast.error('Failed to process payment details. Please try another payment method.');
          break;
      }
      history.replace({
        search: '',
      });
    });
  }, [stripe]);

  return <></>;
};

export default PaymentDataStatus;
