import { useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { Context } from './contexts/AuthContext';
import LoaderSpinner from './components/Core/LoaderSpinner';
import AuthLayout from './layouts/Auth';
import DefaultLayout from './layouts/Logged';
import Login from './views/pages/Auth/Login';
import Home from './views/Home';
import FranchiseeList from './views/pages/Franchisee/FranchiseeList';
import FranchiseeCreate from './views/pages/Franchisee/FranchiseeCreate';
import NotFound from './views/pages/NotFound';
import Profile from './views/pages/Users/Profile';
import SupportList from './views/pages/Support/SupportList';
import SupportNew from './views/pages/Support/SupportNew';
import SupportView from './views/pages/Support/SupportView';
import QrCode from './views/pages/QrCode';
import SchoolList from './views/pages/School/SchoolList';
import SchoolCreate from './views/pages/School/SchoolCreate';
import UserList from './views/pages/Users/UserList';
import ClassList from './views/pages/Class/ClassList';
import ClassDetailedList from './views/pages/Class/ClassDetailedList';
import ClassCreate from './views/pages/Class/ClassCreate';
import ClassManage from './views/pages/Class/ClassManage';
import ClassInfo from './views/pages/Class/ClassInfo';
import UserCreate from './views/pages/Users/UserCreate';
import UserProfile from './views/pages/Users/UserProfile';
import KidsList from './views/pages/Kids/KidsList';
import KidsCreate from './views/pages/Kids/KidsCreate';
import ImmunizationKidsCreate from './views/pages/Immunization/ImmunizationKidsCreate';
import TimelineList from './views/pages/Timeline/TimelineKids';
import DocumentsFoldersList from './views/pages/Documents/DocumentsFoldersList';
import DocumentsPdfShow from './views/pages/Documents/DocumentsPdfShow';
import MessagingHome from './views/pages/Messaging/MessagingHome';
import SettingsList from './views/pages/Settings/SettingsList';
import SchoolScheduleEventImportForm from './views/pages/SchoolScheduleEvent/SchoolScheduleEventImportForm';
import SchoolScheduleEventImportList from './views/pages/SchoolScheduleEvent/SchoolScheduleEventImportList';

import TestTransaction from './views/pages/Transactions/TestTransaction';
import BillingList from './views/pages/Billing/BillingList';
import Billing from './views/pages/Billing/Billing';
import Register from './views/pages/Auth/Register';
import ForgotPassword from './views/pages/Auth/ForgotPassword';
import Reports from './views/pages/Reports';
import StudentProfile from './views/pages/Kids/StudentProfile';
import AdminSignature from './views/pages/IncidentReport/AdminSignature';
import DirectoryList from './views/pages/Staff/DirectoryList';
import Pto from './views/pages/Staff/Pto/Pto';
import PtoPolicesForm from './views/pages/Users/PtoPolicesForm';
import Payroll from './views/pages/Staff/Payroll';

import PromoCodesList from './views/pages/PromoCodes/PromoCodesList';
import PromoCodesCreate from './views/pages/PromoCodes/PromoCodesCreate';
import NewHireAccept from './views/pages/NewHire/NewHireAccept';
import NewHireReject from './views/pages/NewHire/NewHireReject';
import ForgotPasswordReset from './views/pages/Auth/ForgotPasswordReset';

import WaitingListForm from './views/pages/WaitingListPublic/WaitingListForm';
import TourScheduleForm from './views/pages/TourPublic/TourScheduleForm';
import EnrollForm from './views/pages/EnrollPublic/EnrollForm';

import ParentsDocumentsList from './views/pages/Documents/ParentsDocumentsList';

import EmployeeSettingsView from './views/pages/Users/EmployeeSettingsView';
import NewHireDocuments from './views/pages/NewHire/NewHireDocuments';

import ImportIndex from './views/pages/Import/Index';
import StartConfiguration from './views/pages/Home/StartConfiguration';

const CustomRoute = ({ children, isPrivate, ...rest }: any) => {
  const { loading, authenticated, user }: any = useContext(Context);

  if (loading) {
    return <LoaderSpinner />;
  }

  if (authenticated && !user?.createdAt) {
    return <LoaderSpinner />;
  }

  if (rest.redirectTo) {
    return <Redirect to={rest.redirectTo} />;
  }

  if (isPrivate && !authenticated) {
    return <Redirect to="/login" />;
  }

  const Layout = isPrivate && rest.path !== '/initial-config' ? DefaultLayout : AuthLayout;
  return <Route {...rest} render={() => <Layout>{children}</Layout>} />;
};

export default function Routes() {
  return (
    <Switch>
      <CustomRoute exact path="/" redirectTo="/home"></CustomRoute>
      <CustomRoute isPrivate exact path="/home">
        <Home />
      </CustomRoute>
      <CustomRoute exact path="/login">
        <Login />
      </CustomRoute>
      <CustomRoute exact path="/register">
        <Register />
      </CustomRoute>
      <CustomRoute exact path="/forgot-password">
        <ForgotPassword />
      </CustomRoute>
      <CustomRoute exact path="/new-hire/accept/:token">
        <NewHireAccept />
      </CustomRoute>
      <CustomRoute exact path="/new-hire/reject/:token">
        <NewHireReject />
      </CustomRoute>
      <CustomRoute exact path="/new-hire/documents/:token">
        <NewHireDocuments />
      </CustomRoute>
      <CustomRoute exact path="/forgot-password/:token">
        <ForgotPasswordReset />
      </CustomRoute>
      <CustomRoute isPrivate exact path="/franchisee">
        <FranchiseeList />
      </CustomRoute>
      <CustomRoute isPrivate exact path="/franchisee/new">
        <FranchiseeCreate />
      </CustomRoute>
      <CustomRoute isPrivate path="/franchisee/:id">
        <FranchiseeCreate />
      </CustomRoute>
      <CustomRoute isPrivate exact path="/profile">
        <Profile />
      </CustomRoute>
      <CustomRoute isPrivate exact path="/support">
        <SupportList />
      </CustomRoute>
      <CustomRoute isPrivate exact path="/support/new">
        <SupportNew />
      </CustomRoute>
      <CustomRoute isPrivate path="/incident-report/:id">
        <AdminSignature />
      </CustomRoute>
      <CustomRoute isPrivate path="/support/:id">
        <SupportView />
      </CustomRoute>
      <CustomRoute isPrivate exact path="/class">
        <ClassDetailedList />
      </CustomRoute>
      <CustomRoute exact path="/class-info/:id">
        <ClassInfo />
      </CustomRoute>
      <CustomRoute isPrivate exact path="/class/list">
        <ClassList />
      </CustomRoute>
      <CustomRoute isPrivate exact path="/class/new">
        <ClassCreate />
      </CustomRoute>
      <CustomRoute isPrivate path="/class/:id/details">
        <ClassManage />
      </CustomRoute>
      <CustomRoute isPrivate path="/class/:id">
        <ClassCreate />
      </CustomRoute>
      <CustomRoute isPrivate exact path="/qrcode">
        <QrCode />
      </CustomRoute>
      <CustomRoute isPrivate exact path="/school">
        <SchoolList />
      </CustomRoute>
      <CustomRoute isPrivate exact path="/school/new">
        <SchoolCreate />
      </CustomRoute>
      <CustomRoute isPrivate path="/school/:id">
        <SchoolCreate />
      </CustomRoute>
      <CustomRoute isPrivate exact path="/users">
        <UserList />
      </CustomRoute>
      <CustomRoute isPrivate exact path="/users/new">
        <UserCreate />
      </CustomRoute>
      <CustomRoute isPrivate path="/users/:id/profile">
        <UserProfile />
      </CustomRoute>
      <CustomRoute isPrivate path="/pto-policies-settings/:id?">
        <PtoPolicesForm />
      </CustomRoute>
      <CustomRoute isPrivate path="/employee-settings">
        <EmployeeSettingsView />
      </CustomRoute>
      <CustomRoute isPrivate exact path="/student-profile/:id">
        <StudentProfile />
      </CustomRoute>
      <CustomRoute isPrivate path="/users/:id">
        <UserCreate />
      </CustomRoute>
      <CustomRoute isPrivate exact path="/directory">
        <DirectoryList />
      </CustomRoute>
      <CustomRoute isPrivate exact path="/pto">
        <Pto />
      </CustomRoute>
      <CustomRoute isPrivate exact path="/payroll">
        <Payroll />
      </CustomRoute>
      <CustomRoute isPrivate exact path="/kids">
        <KidsList />
      </CustomRoute>
      <CustomRoute isPrivate exact path="/kids/new">
        <KidsCreate />
      </CustomRoute>
      <CustomRoute isPrivate path="/kids/:id">
        <KidsCreate />
      </CustomRoute>
      <CustomRoute isPrivate exact path="/immunization/new">
        <ImmunizationKidsCreate />
      </CustomRoute>
      <CustomRoute isPrivate path="/immunization/:id">
        <ImmunizationKidsCreate />
      </CustomRoute>
      {/* <CustomRoute isPrivate exact path="/schedule">
        <ScheduleCalendar />
      </CustomRoute> */}
      <CustomRoute isPrivate exact path="/timeline/:kidsId">
        <TimelineList />
      </CustomRoute>
      <CustomRoute isPrivate exact path="/curriculum">
        <DocumentsFoldersList />
      </CustomRoute>
      <CustomRoute isPrivate path="/curriculum/:id">
        <DocumentsFoldersList />
      </CustomRoute>
      <CustomRoute isPrivate exact path="/resource">
        <DocumentsFoldersList />
      </CustomRoute>
      <CustomRoute isPrivate path="/resource/:id">
        <DocumentsFoldersList />
      </CustomRoute>

      <CustomRoute isPrivate exact path="/messaging">
        <MessagingHome />
      </CustomRoute>

      <CustomRoute isPrivate exact path="/settings">
        <SettingsList />
      </CustomRoute>

      <CustomRoute isPrivate exact path="/reports">
        <Reports />
      </CustomRoute>

      <CustomRoute isPrivate exact path="/test-transaction">
        <TestTransaction />
      </CustomRoute>

      <CustomRoute isPrivate exact path="/billing">
        <BillingList />
      </CustomRoute>

      <CustomRoute isPrivate exact path="/billing/:kidsId">
        <Billing />
      </CustomRoute>

      <CustomRoute isPrivate exact path="/billing-parents/:parentId">
        <Billing />
      </CustomRoute>

      <CustomRoute isPrivate exact path="/monthly-info">
        <SchoolScheduleEventImportList />
      </CustomRoute>

      <CustomRoute isPrivate exact path="/monthly-info/import">
        <SchoolScheduleEventImportForm />
      </CustomRoute>

      <CustomRoute isPrivate exact path="/promo-codes">
        <PromoCodesList />
      </CustomRoute>

      <CustomRoute isPrivate exact path="/promo-codes/new">
        <PromoCodesCreate />
      </CustomRoute>

      <CustomRoute isPrivate path="/promo-codes/:id">
        <PromoCodesCreate />
      </CustomRoute>

      <CustomRoute isPrivate exact path="/imports">
        <ImportIndex />
      </CustomRoute>

      <CustomRoute isPrivate path="/view-pdf/:id">
        <DocumentsPdfShow />
      </CustomRoute>

      <CustomRoute exact path="/waiting-list/:schoolUri">
        <WaitingListForm />
      </CustomRoute>

      <CustomRoute exact path="/tour/:franchiseeId/:schoolUri?">
        <TourScheduleForm />
      </CustomRoute>

      <CustomRoute exact path="/tour/:franchiseeId/:schoolUri/schedule/:scheduleId">
        <TourScheduleForm />
      </CustomRoute>

      <CustomRoute exact path="/enroll/:schoolUri">
        <EnrollForm />
      </CustomRoute>

      <CustomRoute isPrivate exact path="/documents">
        <ParentsDocumentsList />
      </CustomRoute>

      <CustomRoute isPrivate exact path="/initial-config">
        <StartConfiguration />
      </CustomRoute>

      <Route component={NotFound} />
    </Switch>
  );
}
