/* eslint-disable max-lines */
import { Button, Card, CardBody, Row, Col, Table, Form, FormGroup, Input, Label, FormText } from 'reactstrap';
import { useEffect, useState } from 'react';
import Employee from '../../../services/Employee';
import { useParams } from 'react-router-dom';
import ReactSelect from 'react-select';
import { toast } from 'react-toastify';
import LoaderSpinner from '../../../components/Core/LoaderSpinner';
import W4DocumentModal from './W4DocumentModal';
import moment from 'moment';
import I9DocumentModal from './I9DocumentModal';
import I9SpanishDocumentModal from './I9SpanishDocumentModal';
import SignDocumentModal from './SignDocumentModal';
import { typeAndTitle } from '../../../helpers/pdfByType';
import HireDocuments from '../../../services/HireDocuments';
import DocumentModal from '../Users/TabContent/DocumentModal';
import EmploymentDocumentsService from '../../../services/EmploymentDocuments';
import BankingAccountsService from '../../../services/BankingAccounts';
import { documentsOptions } from '../../../helpers/documentsOptions';
import confirmAlert from '../../../helpers/confirmAlert';
import W4DocumentViewModal from '../Users/TabContent/W4DocumentViewModal';
import I9DocumentViewModal from '../Users/TabContent/I9DocumentViewModal';
import I9SpanishDocumentViewModal from '../Users/TabContent/I9SpanishDocumentViewModal';
import BankingAccountModal from './BankingAccountModal';

const NewHireDocuments = () => {
  const { token } = useParams<{ token: string }>();
  const [currentUser, setCurrentUser] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [uploads, setUploads] = useState<{ title: string }[]>([]);
  const [showForm, setShowForm] = useState(false);
  const [documentFile, setDocumentFile] = useState<any>();
  const [documentType, setDocumentType] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showModalI9, setShowModalI9] = useState(false);
  const [showModalI9Spanish, setShowModalI9Spanish] = useState(false);

  const [showBankingForm, setShowBankingForm] = useState(false);
  const [showBankingModal, setShowBankingModal] = useState(false);
  const [currentBankingAccount, setCurrentBankingAccount] = useState<any>({});
  const [banks, setBanks] = useState<
    { bankName: string; routingNumber: string; accountNumber: string; url: string; date: string }[]
  >([]);
  const [bankName, setBankName] = useState('');
  const [bankRoutingNumber, setRoutingNumber] = useState('');
  const [bankAccountNumber, setAccountNumber] = useState('');
  const [bankFile, setBankFile] = useState<any>();

  const [currentDocType, setCurrentDocType] = useState('');
  const [showDocumentsModal, setShowDocumentsModal] = useState(false);

  const [isValidToken, setIsValidToken] = useState(true);

  const [hiringDocs, setHiringDocs] = useState<any[]>([]);
  const [eligibilityDocs, setEligibilityDocs] = useState<any[]>([]);
  const [taxDocs, setTaxDocs] = useState<any[]>([]);
  const [signedDocs, setSignedDocs] = useState<any[]>([]);
  const [signedDates, setSignedDates] = useState<any[]>([]);
  const [documentsData, setDocumentsData] = useState<any[]>();
  const [currentDocument, setCurrentDocument] = useState<any[]>();
  const [currentW4Document, setCurrentW4Document] = useState<any[]>();
  const [showDocument, setShowDocument] = useState<boolean>(false);
  const [showW4Document, setShowW4Document] = useState<boolean>(false);
  const [currentI9Document, setCurrentI9Document] = useState<any[]>();
  const [showI9Document, setShowI9Document] = useState<boolean>(false);
  const [currentI9SpanishDocument, setCurrentI9SpanishDocument] = useState<any[]>();
  const [showI9SpanishDocument, setShowI9SpanishDocument] = useState<boolean>(false);

  useEffect(() => {
    validationToken();
  }, []);

  useEffect(() => {
    if (currentUser?.employeeId) {
      getEmploymentDocuments();
      getBankAccounts();
    }
  }, [currentUser]);

  const handleDocument = (type: string) => {
    const doc = documentsData?.find((d) => d?.type === type);
    setCurrentDocument(doc);
    setShowDocument(true);
  };

  const handleW4Document = () => {
    const doc = documentsData?.find((d) => d?.type === 'W4');
    setCurrentW4Document(doc);
    setShowW4Document(true);
  };

  const handleI9Document = () => {
    const doc = documentsData?.find((d) => d?.type === 'I9');
    setCurrentI9Document(doc);
    setShowI9Document(true);
  };

  const handleI9SpanishDocument = () => {
    const doc = documentsData?.find((d) => d?.type === 'I9-Spanish');
    setCurrentI9SpanishDocument(doc);
    setShowI9SpanishDocument(true);
  };

  const getDocuments = async (userId: string) => {
    try {
      const result = await HireDocuments('').show(userId);
      const documents: any[] = result?.data || [];
      setDocumentsData(documents);
      const newDocs = [];
      const newDates = [];
      for (const d of documents) {
        if (d?.options?.type) {
          newDocs.push(d?.options?.type);
        }
        if (d?.options?.type && d?.options?.signatureDate) {
          newDates.push({
            type: d?.options?.type,
            signatureDate: moment(d?.options?.signatureDate).format('MM/DD/YYYY'),
          });
        }
      }
      setSignedDocs(newDocs);
      setSignedDates(newDates);
    } catch (e: any) {
      console.error(e?.response?.data?.message || 'Invalid user ID');
    }
  };

  const getBankAccounts = async () => {
    try {
      const result = await BankingAccountsService(token).list(currentUser?.employeeId);
      const data: any[] = result?.data || [];
      const newBanks = [];
      for (const u of data) {
        newBanks.push({
          id: u?.id,
          bankName: u?.bankName,
          routingNumber: u?.routingNumber,
          accountNumber: u?.accountNumber,
          url: u?.blankCheck,
          date: moment(u?.createdAt || '').format('YYYY-MM-DD'),
        });
      }
      setBanks(newBanks);
    } catch (e: any) {
      console.error(e?.response?.data?.message || 'Invalid employee ID');
    }
  };

  const getEmploymentDocuments = async () => {
    try {
      const result = await EmploymentDocumentsService(token).list(currentUser?.employeeId);
      const data: any[] = result?.data || [];
      const newUploads = [];
      for (const u of data) {
        newUploads.push({ id: u?.id, title: u?.documentType, url: u?.document });
      }
      setUploads(newUploads);
    } catch (e: any) {
      console.error(e?.response?.data?.message || 'Invalid employee ID');
    }
  };

  const deleteEmploymentDocuments = async (id: string) => {
    try {
      await EmploymentDocumentsService(token).delete(id);
      getEmploymentDocuments();
      setDocumentType('');
      setShowForm(false);
    } catch (e: any) {
      console.error(e?.response?.data?.message || 'Invalid ID');
    }
  };

  const deleteBankingAccount = async (id: string) => {
    try {
      await BankingAccountsService(token).delete(id);
      getBankAccounts();
    } catch (e: any) {
      console.error(e?.response?.data?.message || 'Invalid ID');
    }
  };

  const validationToken = async () => {
    setIsLoading(true);
    try {
      const user = await Employee('').documentsValidationToken(token);
      if (user?.data?.hiringAgreements) {
        const hiringTypes: string[] = user?.data?.hiringAgreements?.map((h: any) => h?.key);
        const docs = typeAndTitle.filter((t) => hiringTypes.includes(t?.type));
        setHiringDocs(docs);
      }

      setEligibilityDocs(user?.data?.employmentEligibilityDocs || []);
      setTaxDocs(user?.data?.taxDocs || []);

      setCurrentUser(user?.data);
      getDocuments(user?.data?.userId);
      setIsValidToken(true);
    } catch {
      setIsValidToken(false);
    } finally {
      setIsLoading(false);
    }
  };

  const onChangeFile = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];
    setDocumentFile(file);
  };

  const onChangeBankFile = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];
    setBankFile(file);
  };

  const onSaveFile = async (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    handleSubmitDocument();
  };

  const handleSubmitDocument = async () => {
    try {
      setIsLoading(true);
      const result = await EmploymentDocumentsService(token).create({
        employeeId: currentUser?.employeeId,
        documentType,
      });

      if (documentFile) {
        const formData = new FormData();
        formData.append('document', documentFile as any);
        await EmploymentDocumentsService(token).uploadFile(result?.data?.id, formData);
      }

      toast.success(`Saved successfully!`);
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Create error!');
      toast.error(message);
    } finally {
      setDocumentType('');
      setShowForm(false);
      if (currentUser?.employeeId) getEmploymentDocuments();
      setIsLoading(false);
    }
  };

  const handleSubmitBankingAccount = async () => {
    try {
      setIsLoading(true);
      const result = await BankingAccountsService(token).create({
        employeeId: currentUser?.employeeId,
        bankName,
        routingNumber: bankRoutingNumber,
        accountNumber: bankAccountNumber,
      });

      if (bankFile) {
        const formData = new FormData();
        formData.append('document', bankFile as any);
        await BankingAccountsService(token).uploadFile(result?.data?.id, formData);
      }

      toast.success(`Saved successfully!`);
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Create error!');
      toast.error(message);
    } finally {
      setBankName('');
      setRoutingNumber('');
      setAccountNumber('');
      setShowBankingForm(false);
      if (currentUser?.employeeId) getBankAccounts();
      setIsLoading(false);
    }
  };

  const onBankSaveFile = async (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    await handleSubmitBankingAccount();
  };

  const onRemoveFile = async (event: any, id: string) => {
    event.stopPropagation();
    event.preventDefault();
    return confirmAlert({
      title: 'Delete',
      messageType: 'DELETE',
      message: 'Are you sure to delete this document?',
      onClickYes: () => deleteEmploymentDocuments(id),
    });
  };

  const onRemoveBankAccount = async (event: any, id: string) => {
    event.stopPropagation();
    event.preventDefault();
    return confirmAlert({
      title: 'Delete',
      messageType: 'DELETE',
      message: 'Are you sure to delete this banking account?',
      onClickYes: () => deleteBankingAccount(id),
    });
  };

  if (isLoading) {
    return (
      <Col lg="10" md="10">
        <Card className="bg-secondary shadow border-0 py-6">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="d-flex align-Items-center justify-content-center">
              <LoaderSpinner />
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  }

  if (!isValidToken) {
    return (
      <Col lg="10" md="10">
        <Card className="bg-secondary shadow border-0 py-6">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="d-flex align-Items-center justify-content-center">
              <i className="fas fa-ban" style={{ fontSize: 72, color: '#df0d0d' }} />
            </div>
            <h2 className="text-center mt-4">Token is invalid or expired!</h2>
          </CardBody>
        </Card>
      </Col>
    );
  }

  return (
    <>
      <Col lg="10" md="10">
        <Card className="shadow border-0 mt-4">
          <CardBody className="pb-4">
            <Row>
              <Col lg="12">
                <div className="d-flex justify-content-between align-items-center py-1">
                  <h3 className="text-uppercase text-primary">Hiring Documents</h3>
                </div>
              </Col>
            </Row>
            {eligibilityDocs?.includes('TWO_DOCS_FOR_PROOF') ? (
              <Row>
                <Table className="mt-4" responsive>
                  <thead>
                    <tr>
                      <th style={{ background: '#f3f3f3', borderTop: '1px solid #dadada' }}>
                        <div className="d-flex align-items-center py-2">Uploads - Employment Eligibility Documents</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody></tbody>
                </Table>
                <Table className="align-items-center table-flush" responsive>
                  <tbody>
                    {uploads?.length ? (
                      <>
                        <tr>
                          <th>Title</th>
                          <th>Action</th>
                        </tr>
                        {uploads.map((s: any) => (
                          <tr key={`uploads-${s.title}`}>
                            <td>
                              <i className="fas fa-file mr-2" />
                              <a
                                href={`${s.url}`}
                                target="_blank"
                                style={{
                                  textDecoration: 'underline',
                                  color: '#5e72e4',
                                  backgroundColor: 'transparent',
                                }}
                              >
                                {s.title}
                              </a>
                            </td>
                            <td>
                              {s.title && (
                                <i
                                  className="far fa-trash-alt text-danger"
                                  style={{ cursor: 'pointer' }}
                                  onClick={(e) => onRemoveFile(e, s.id)}
                                ></i>
                              )}
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <>
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <i className="fas fa-file mr-2" />
                              No Document
                            </div>
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </Table>
                <Col style={{ paddingLeft: '20px', paddingRight: '20px', marginTop: '20px' }}>
                  <Row>
                    {!showForm && (
                      <Col>
                        <Button
                          size="sm"
                          color="primary"
                          onClick={() => {
                            setShowForm(true);
                          }}
                        >
                          + New Document
                        </Button>
                      </Col>
                    )}
                    {showForm && (
                      <Col lg="12">
                        <Form onSubmit={(e: any) => e.preventDefault()}>
                          <Row>
                            <Col lg="6">
                              <FormGroup style={{ fontSize: '0.8125rem', whiteSpace: 'nowrap', marginTop: '20px' }}>
                                <Label>Document Type</Label>
                                <ReactSelect
                                  isSearchable
                                  defaultValue={documentsOptions[0]}
                                  options={documentsOptions}
                                  onChange={(e) => (e?.value ? setDocumentType(e.value) : null)}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup style={{ fontSize: '0.8125rem', whiteSpace: 'nowrap', marginTop: '20px' }}>
                                <Label for="exampleFile">Document</Label>
                                <Input id="exampleFile" name="file" type="file" onChange={onChangeFile} />
                                <FormText>File extensions: PDF, JPG</FormText>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <Button
                                color="primary"
                                type="button"
                                size="sm"
                                style={{ minWidth: '100px' }}
                                onClick={onSaveFile}
                              >
                                Save
                              </Button>

                              <Button
                                color="secondary"
                                size="sm"
                                style={{ minWidth: '100px' }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  setShowForm(false);
                                }}
                              >
                                Cancel
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            ) : (
              <></>
            )}

            <Row>
              <Table className="mt-4" responsive>
                <thead>
                  <tr>
                    <th colSpan={4} style={{ background: '#f3f3f3', borderTop: '1px solid #dadada' }}>
                      <div className="d-flex align-items-center py-1">Banking Account</div>
                    </th>
                  </tr>
                  <tr>
                    <th>Bank Name</th>
                    <th>Registration Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {banks?.length ? (
                    <>
                      {banks.map((s: any, i: number) => (
                        <tr key={`bank-${i}`}>
                          <td>
                            <div className="d-flex align-items-center">
                              <i className="fas fa-file mr-2" />
                              <a
                                href={`${s.url}`}
                                target="_blank"
                                style={{
                                  textDecoration: 'underline',
                                  color: '#5e72e4',
                                  backgroundColor: 'transparent',
                                }}
                              >
                                {s.bankName}
                              </a>
                            </div>
                          </td>
                          <td>{moment(s.date).format('MM/DD/YYYY')}</td>
                          <td>
                            <div className="d-flex align-items-center" style={{ gap: 8 }}>
                              <Button
                                style={{ border: 'none', boxShadow: 'none', padding: 0 }}
                                onClick={() => {
                                  setCurrentBankingAccount(s?.id || '');
                                  setShowBankingModal(true);
                                }}
                              >
                                <i className="fas fa-eye" />
                              </Button>
                              <Button
                                style={{ border: 'none', boxShadow: 'none', padding: 0 }}
                                onClick={(e) => onRemoveBankAccount(e, s.id)}
                              >
                                <i className="far fa-trash-alt text-danger" style={{ cursor: 'pointer' }} />
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </>
                  ) : (
                    <></>
                  )}
                </tbody>
              </Table>
              <Col style={{ paddingLeft: '20px', paddingRight: '20px', marginTop: '20px' }}>
                <Row>
                  {!showBankingForm && !banks?.length && (
                    <Col>
                      <Button
                        size="sm"
                        color="primary"
                        onClick={() => {
                          setShowBankingForm(true);
                        }}
                      >
                        Add Banking Account
                      </Button>
                    </Col>
                  )}
                  {showBankingForm && (
                    <Col lg="12">
                      <Form onSubmit={(e: any) => e.preventDefault()}>
                        <Row>
                          <Col lg="3">
                            <FormGroup style={{ fontSize: '0.8125rem', whiteSpace: 'nowrap', marginTop: '20px' }}>
                              <Label>Bank Name</Label>
                              <Input type="text" value={bankName} onChange={(e) => setBankName(e.target.value)} />
                            </FormGroup>
                          </Col>
                          <Col lg="3">
                            <FormGroup style={{ fontSize: '0.8125rem', whiteSpace: 'nowrap', marginTop: '20px' }}>
                              <Label>Routing Number</Label>
                              <Input
                                type="text"
                                value={bankRoutingNumber}
                                onChange={(e) => setRoutingNumber(e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="3">
                            <FormGroup style={{ fontSize: '0.8125rem', whiteSpace: 'nowrap', marginTop: '20px' }}>
                              <Label>Account Number</Label>
                              <Input
                                type="text"
                                value={bankAccountNumber}
                                onChange={(e) => setAccountNumber(e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="3">
                            <FormGroup style={{ fontSize: '0.8125rem', whiteSpace: 'nowrap', marginTop: '20px' }}>
                              <Label for="exampleFile">Upload Blank Check</Label>
                              <Input id="exampleFile" name="file" type="file" onChange={onChangeBankFile} />
                              <FormText>File extensions: PDF, JPG</FormText>
                            </FormGroup>
                          </Col>
                          <Col md="12">
                            <Button
                              color="primary"
                              type="button"
                              size="sm"
                              style={{ minWidth: '100px' }}
                              onClick={onBankSaveFile}
                            >
                              Save
                            </Button>

                            <Button
                              color="secondary"
                              size="sm"
                              style={{ minWidth: '100px' }}
                              onClick={(e) => {
                                e.preventDefault();
                                setShowBankingForm(false);
                              }}
                            >
                              Cancel
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
            <Row>
              <Table className="mt-4" responsive>
                <thead>
                  <tr>
                    <th colSpan={4} style={{ background: '#f3f3f3', borderTop: '1px solid #dadada' }}>
                      <div className="d-flex align-items-center py-1">Hiring Agreements</div>
                    </th>
                  </tr>
                  <tr>
                    <th>Name</th>
                    <th>Signature Date</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {hiringDocs?.map((value, i) => (
                    <tr key={`doc-${i}`}>
                      <td>
                        <div className="d-flex align-items-center">
                          <i className="fas fa-file mr-2" />
                          <a href="#" style={{ textDecoration: 'underline' }}>
                            {value.title}
                          </a>
                        </div>
                      </td>
                      <td>{signedDates.find((d) => d.type === value.type)?.signatureDate || '-'}</td>
                      <td>
                        {signedDocs.includes(value.type) ? (
                          <>
                            Completed <i className="fas fa-check-circle" style={{ color: '#02ce0d' }} />
                          </>
                        ) : (
                          <>
                            Incomplete <i className="fas fa-ban" style={{ color: 'red' }} />
                          </>
                        )}
                      </td>
                      <td>
                        <div className="d-flex align-items-center" style={{ gap: 8 }}>
                          {!signedDocs.includes(value.type) ? (
                            <Button
                              style={{ border: 'none', boxShadow: 'none', padding: 0 }}
                              onClick={() => {
                                setCurrentDocType(value.type);
                                setShowDocumentsModal(true);
                              }}
                            >
                              <i className="fas fa-file-signature" style={{ fontSize: 19 }} />
                            </Button>
                          ) : (
                            <Button
                              style={{ border: 'none', boxShadow: 'none', padding: 0 }}
                              onClick={(e: any) => {
                                e?.preventDefault();
                                handleDocument(value.type);
                              }}
                            >
                              <i className="fas fa-eye" style={{ fontSize: 19 }} />
                            </Button>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Row>

            <Row>
              <Table className="mt-4" responsive>
                <thead>
                  <tr>
                    <th colSpan={4} style={{ background: '#f3f3f3', borderTop: '1px solid #dadada' }}>
                      <div className="d-flex align-items-center py-1">Company Handbooks</div>
                    </th>
                  </tr>
                  <tr>
                    <th>Name</th>
                    <th>Signature Date</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {taxDocs?.includes('W4') ? (
                    <tr>
                      <td>
                        <div className="d-flex align-items-center">
                          <i className="fas fa-file mr-2" />
                          <a href="#" style={{ textDecoration: 'underline' }}>
                            W4
                          </a>
                        </div>
                      </td>
                      <td>{signedDates.find((d) => d.type === 'W4')?.signatureDate || '-'}</td>
                      <td>
                        {signedDocs.includes('W4') ? (
                          <>
                            Completed <i className="fas fa-check-circle" style={{ color: '#02ce0d' }} />
                          </>
                        ) : (
                          <>
                            Incomplete <i className="fas fa-ban" style={{ color: 'red' }} />
                          </>
                        )}
                      </td>
                      <td>
                        <div className="d-flex align-items-center" style={{ gap: 8 }}>
                          {!signedDocs.includes('W4') ? (
                            <Button
                              style={{ border: 'none', boxShadow: 'none', padding: 0 }}
                              onClick={() => setShowModal(true)}
                            >
                              <i className="fas fa-file-signature" style={{ fontSize: 19 }} />
                            </Button>
                          ) : (
                            <Button
                              style={{ border: 'none', boxShadow: 'none', padding: 0 }}
                              onClick={(e: any) => {
                                e?.preventDefault();
                                handleW4Document();
                              }}
                            >
                              <i className="fas fa-eye" style={{ fontSize: 19 }} />
                            </Button>
                          )}
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <></>
                  )}

                  {eligibilityDocs?.includes('I9_ENGLISH') ? (
                    <tr>
                      <td>
                        <div className="d-flex align-items-center">
                          <i className="fas fa-file mr-2" />
                          <a href="#" style={{ textDecoration: 'underline' }}>
                            I9
                          </a>
                        </div>
                      </td>
                      <td>{signedDates.find((d) => d.type === 'I9')?.signatureDate || '-'}</td>
                      <td>
                        {signedDocs.includes('I9') ? (
                          <>
                            Completed <i className="fas fa-check-circle" style={{ color: '#02ce0d' }} />
                          </>
                        ) : (
                          <>
                            Incomplete <i className="fas fa-ban" style={{ color: 'red' }} />
                          </>
                        )}
                      </td>
                      <td>
                        <div className="d-flex align-items-center" style={{ gap: 8 }}>
                          {!signedDocs.includes('I9') ? (
                            <Button
                              style={{ border: 'none', boxShadow: 'none', padding: 0 }}
                              onClick={() => setShowModalI9(true)}
                            >
                              <i className="fas fa-file-signature" style={{ fontSize: 19 }} />
                            </Button>
                          ) : (
                            <Button
                              style={{ border: 'none', boxShadow: 'none', padding: 0 }}
                              onClick={(e: any) => {
                                e?.preventDefault();
                                handleI9Document();
                              }}
                            >
                              <i className="fas fa-eye" style={{ fontSize: 19 }} />
                            </Button>
                          )}
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <></>
                  )}

                  {eligibilityDocs?.includes('I9_SPANISH') ? (
                    <tr>
                      <td>
                        <div className="d-flex align-items-center">
                          <i className="fas fa-file mr-2" />
                          <a href="#" style={{ textDecoration: 'underline' }}>
                            I9 - Spanish
                          </a>
                        </div>
                      </td>
                      <td>{signedDates.find((d) => d.type === 'I9-Spanish')?.signatureDate || '-'}</td>
                      <td>
                        {signedDocs.includes('I9-Spanish') ? (
                          <>
                            Completed <i className="fas fa-check-circle" style={{ color: '#02ce0d' }} />
                          </>
                        ) : (
                          <>
                            Incomplete <i className="fas fa-ban" style={{ color: 'red' }} />
                          </>
                        )}
                      </td>
                      <td>
                        <div className="d-flex align-items-center" style={{ gap: 8 }}>
                          {!signedDocs.includes('I9-Spanish') ? (
                            <Button
                              style={{ border: 'none', boxShadow: 'none', padding: 0 }}
                              onClick={() => setShowModalI9Spanish(true)}
                            >
                              <i className="fas fa-file-signature" style={{ fontSize: 19 }} />
                            </Button>
                          ) : (
                            <Button
                              style={{ border: 'none', boxShadow: 'none', padding: 0 }}
                              onClick={(e: any) => {
                                e?.preventDefault();
                                handleI9SpanishDocument();
                              }}
                            >
                              <i className="fas fa-eye" style={{ fontSize: 19 }} />
                            </Button>
                          )}
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <></>
                  )}
                </tbody>
              </Table>
            </Row>
          </CardBody>
        </Card>
      </Col>
      <SignDocumentModal
        isOpen={showDocumentsModal}
        type={currentDocType}
        token={token}
        currentUser={currentUser}
        toggleModal={(e) => {
          e.preventDefault();
          setShowDocumentsModal(!showDocumentsModal);
        }}
        saveModal={(type) => {
          if (type) {
            const newSignedDocs = [...signedDocs];
            newSignedDocs.push(type);
            setSignedDocs(newSignedDocs);
          }
          getDocuments(currentUser?.userId);
        }}
      />
      <BankingAccountModal
        showBankingModal={showBankingModal}
        bankAccountId={currentBankingAccount}
        banks={banks}
        setShowBankingModal={setShowBankingModal}
      />
      <W4DocumentModal
        isOpen={showModal}
        token={token}
        currentUser={currentUser}
        toggleModal={(e) => {
          e.preventDefault();
          setShowModal(!showModal);
        }}
        saveModal={(e) => {
          e.preventDefault();
          getDocuments(currentUser?.userId);
        }}
      />
      <I9DocumentModal
        isOpen={showModalI9}
        token={token}
        currentUser={currentUser}
        toggleModal={(e) => {
          e.preventDefault();
          setShowModalI9(!showModalI9);
        }}
        saveModal={(e) => {
          e.preventDefault();
          getDocuments(currentUser?.userId);
        }}
      />
      <I9SpanishDocumentModal
        isOpen={showModalI9Spanish}
        token={token}
        currentUser={currentUser}
        toggleModal={(e) => {
          e.preventDefault();
          setShowModalI9Spanish(!showModalI9Spanish);
        }}
        saveModal={(e) => {
          e.preventDefault();
          getDocuments(currentUser?.userId);
        }}
      />
      <DocumentModal data={currentDocument} isOpen={showDocument} toggleModal={() => setShowDocument(false)} />
      <W4DocumentViewModal
        data={currentW4Document}
        isOpen={showW4Document}
        toggleModal={() => setShowW4Document(false)}
      />
      <I9DocumentViewModal
        data={currentI9Document}
        isOpen={showI9Document}
        toggleModal={() => setShowI9Document(false)}
      />
      <I9SpanishDocumentViewModal
        data={currentI9SpanishDocument}
        isOpen={showI9SpanishDocument}
        toggleModal={() => setShowI9SpanishDocument(false)}
      />
    </>
  );
};

export default NewHireDocuments;
