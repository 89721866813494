import { InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import ReactDatetime from 'react-datetime';

type DateTimeProps = {
  format?: string;
  onChange: (date: any) => void;
  value?: string;
  clearButton?: boolean;
  inputProps?: any;
  disabled?: boolean;
};

const DateTime = ({
  onChange,
  value,
  format = 'MM/DD/yyyy',
  clearButton = false,
  inputProps = { placeholder: '' },
}: DateTimeProps) => {
  return (
    <InputGroup className="input-group-alternative">
      <InputGroupAddon addonType="prepend">
        <InputGroupText style={inputProps?.disabled ? { backgroundColor: '#e9ecef' } : {}}>
          <i className="ni ni-calendar-grid-58" />
        </InputGroupText>
      </InputGroupAddon>
      <ReactDatetime
        inputProps={inputProps}
        onChange={(newValue) => {
          onChange(`${newValue}`);
        }}
        timeFormat={false}
        value={`${value ? value : ''}`}
        dateFormat={format}
        closeOnSelect={true}
        renderInput={(props) => {
          return <input {...props} value={value ? props.value : ''} />;
        }}
      />
      {clearButton && (
        <InputGroupAddon addonType="prepend">
          <InputGroupText
            style={inputProps?.disabled ? { backgroundColor: '#e9ecef' } : { cursor: 'pointer' }}
            onClick={() => {
              onChange('');
            }}
          >
            <i className="ni ni-fat-remove" />
          </InputGroupText>
        </InputGroupAddon>
      )}
    </InputGroup>
  );
};

export default DateTime;
