/* eslint-disable prefer-spread */
/* eslint-disable max-lines */
import { Alert, Button, Col, Collapse, Form, FormGroup, Input, Label, Modal, Row } from 'reactstrap';
import { useEffect, useState } from 'react';
import { PDFCheckBox, PDFDocument, PDFDropdown, PDFTextField } from 'pdf-lib';
import SignatureCanvas from 'react-signature-canvas';
import { i9EnglishFields } from '../../../helpers/i9EnglishFields';
import moment from 'moment';
import { toast } from 'react-toastify';
import HireDocuments from '../../../services/HireDocuments';

type I9DocumentModalProps = {
  isOpen: boolean;
  token: string;
  currentUser: any;
  toggleModal: (e?: any) => void;
  saveModal: (e?: any) => void;
};

const I9DocumentModal = ({ isOpen, token, currentUser, toggleModal, saveModal }: I9DocumentModalProps) => {
  const [pdf, setPdf] = useState('');
  const [isCollapseOpen, setIsCollapseOpen] = useState([]);
  const [sigPad, setSigPad] = useState<any>();
  const [formFields, setFormFields] = useState<any[]>([]);
  const [formFieldsValues, setFormFieldsValues] = useState<any[]>([]);
  const [signature, setSignature] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [pdfBlob, setPdfBlob] = useState<any>();
  const [pdfSignedBlob, setPdfSignedBlob] = useState<any>();

  useEffect(() => {
    if (isOpen) {
      loadPdfFields();
      createPdf();
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      createPdf();
    }
  }, [sigPad]);

  const pdfUrl =
    'https://arb-systems-s3-public.s3.us-east-2.amazonaws.com/public/Employment-Eligibility/i-9-english.pdf';

  const coordinatesSign = {
    x: 60,
    y: 420,
    height: 30,
    opacity: 1,
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);

    const body = {
      type: 'I9',
      userId: currentUser?.userId || '',
      userName: currentUser?.userName || '',
      userToken: token,
      pdf: pdfBlob,
      pdfUrl: pdfUrl,
      pdfSigned: pdfSignedBlob,
      signature,
      options: {
        formFields,
        formFieldsValues,
        type: 'I9',
        docUrl: pdf,
        coordinatesSign,
        signPng: signature,
        signatureDate: moment().format('YYYY-MM-DD'),
      },
    };

    try {
      await HireDocuments('').create(body);
      toast.success(`Saved successfully!`);
      saveModal(e);
    } catch (e: any) {
      console.log(e);
      const message = String(e?.response?.data?.message || 'Create error!');
      toast.error(message);
    }

    setLoading(false);
  };

  const readOnlyFields = (pdfForm: any) => {
    try {
      const fieldNames = pdfForm.getFields();
      for (const f of fieldNames) {
        let type = '';
        if (f instanceof PDFTextField) {
          type = 'PDFTextField';
        }
        if (f instanceof PDFDropdown) {
          type = 'PDFDropdown';
        }
        if (f instanceof PDFCheckBox) {
          type = 'PDFCheckBox';
        }
        const fieldName = f.getName();
        if (type === 'PDFTextField' || f instanceof PDFTextField) {
          pdfForm.getTextField(fieldName).enableReadOnly();
        }
        if (type === 'PDFDropdown' || f instanceof PDFDropdown) {
          pdfForm.getDropdown(fieldName).enableReadOnly();
        }
        if (type === 'PDFCheckBox' || f instanceof PDFCheckBox) {
          pdfForm.getCheckBox(fieldName).enableReadOnly();
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const loadPdfFields = async () => {
    try {
      const arrayBuffer = await fetch(pdfUrl).then((res) => res.arrayBuffer());
      setPdfBlob(arrayBuffer);
      const pdfDoc = await PDFDocument.load(arrayBuffer);
      const pdfForm = pdfDoc.getForm();
      const fieldNames = pdfForm.getFields();
      let count = 0;
      const fields = [];
      const values = [];
      for (const f of fieldNames) {
        try {
          let type = '';
          if (f instanceof PDFTextField) {
            type = 'PDFTextField';
          }
          if (f instanceof PDFDropdown) {
            type = 'PDFDropdown';
          }
          if (f instanceof PDFCheckBox) {
            type = 'PDFCheckBox';
          }

          const fieldName = f.getName();
          const field =
            type === 'PDFTextField' || f instanceof PDFTextField ? pdfForm.getTextField(fieldName) : undefined;
          const drop = type === 'PDFDropdown' || f instanceof PDFDropdown ? pdfForm.getDropdown(fieldName) : undefined;
          fields.push({
            fieldType: type,
            fieldName,
            fieldIndex: count,
            fieldMaxLength: field ? field.getMaxLength() : undefined,
            fieldOptions: type === 'PDFDropdown' || f instanceof PDFDropdown ? drop?.getOptions() : [],
          });
          values.push({
            fieldType: type,
            fieldName,
            fieldValue: '',
            fieldIndex: count,
          });
        } catch (e) {
          console.log('E: ', e);
        }
        count++;
      }
      setFormFields(fields);
      setFormFieldsValues(values);
    } catch (e) {
      console.log(e);
    }
  };

  const createPdf = async () => {
    try {
      const arrayBuffer = await fetch(pdfUrl).then((res) => res.arrayBuffer());
      const pdfDoc = await PDFDocument.load(arrayBuffer);
      const pdfForm = pdfDoc.getForm();
      readOnlyFields(pdfForm);
      const pages = pdfDoc.getPages();
      const firstPage = pages[0];
      if (firstPage && sigPad) {
        const img = sigPad?.getTrimmedCanvas()?.toDataURL('image/png');
        setSignature(img);
        const pngImage = await pdfDoc.embedPng(img);
        firstPage.drawImage(pngImage, coordinatesSign);
      }

      for (const f of formFieldsValues) {
        const fieldForm = formFields[f?.fieldIndex];
        if (f?.fieldType === 'PDFTextField' && f?.fieldValue) {
          const field = pdfForm.getTextField(fieldForm?.fieldName);
          field.setText(`${f?.fieldValue}`);
        }
        if (f?.fieldType === 'PDFDropdown' && f?.fieldValue) {
          const field = pdfForm.getDropdown(fieldForm?.fieldName);
          if (f?.fieldValue) field.select(f?.fieldValue);
        }
        if (f?.fieldType === 'PDFCheckBox' && f?.fieldValue === 'true') {
          const field = pdfForm.getCheckBox(fieldForm?.fieldName);
          field.check();
        }
      }

      const pdfBytes = await pdfDoc.save();
      const blob = new Blob([pdfBytes], { type: 'application/pdf' });
      setPdfSignedBlob(blob);
      const docUrl = URL.createObjectURL(blob);
      setPdf(docUrl);
    } catch (e) {
      console.log(e);
    }
  };

  const formRow = (b: any, ff: any, i?: number | string) => {
    let children: any = undefined;
    if (ff.fieldType === 'PDFDropdown') {
      children = (
        <Col lg={`${b?.size || '6'}`} key={i ? `fc1-${i}` : ''}>
          <FormGroup>
            <label className="form-control-label">{b.label}</label>
            <Input
              type="select"
              onChange={(e) => {
                const newFormFieldsValues = JSON.parse(JSON.stringify(formFieldsValues));
                for (let i = 0; i <= newFormFieldsValues?.length; i++) {
                  if (newFormFieldsValues?.[i]?.fieldName === ff?.fieldName) {
                    newFormFieldsValues[i].fieldValue = e.target.value;
                  }
                }
                setFormFieldsValues(newFormFieldsValues);
              }}
            >
              <option value="">Please select</option>
              {ff?.fieldOptions?.map((p: any, index: number) => {
                return (
                  <option value={p} key={`select-${p}-${index}`}>
                    {`${p || ''}`}
                  </option>
                );
              })}
            </Input>
          </FormGroup>
        </Col>
      );
    }

    if (ff.fieldType === 'PDFTextField') {
      children = (
        // <div key={i ? `fc2-${i}` : ''}>
        <>
          <Col lg={`${b?.size || '6'}`}>
            <FormGroup>
              <label className="form-control-label">{b.label}</label>
              <Input
                className="form-control-alternative"
                type="text"
                maxLength={ff?.fieldMaxLength}
                value={formFieldsValues.find((f: any) => f.fieldName === ff.fieldName)?.fieldValue}
                onChange={(e) => {
                  const newFormFieldsValues = JSON.parse(JSON.stringify(formFieldsValues));
                  for (let i = 0; i <= newFormFieldsValues?.length; i++) {
                    if (newFormFieldsValues?.[i]?.fieldName === ff?.fieldName) {
                      newFormFieldsValues[i].fieldValue = e.target.value;
                    }
                  }
                  setFormFieldsValues(newFormFieldsValues);
                }}
                required
              />
            </FormGroup>
          </Col>
          {b?.i === 8 ? (
            <>
              <Col lg="12 mt-2">
                <label className="form-control-label mr-2" htmlFor="input-name">
                  Signature
                </label>
              </Col>
              <Col lg="6">
                <div>
                  <SignatureCanvas
                    penColor="blue"
                    canvasProps={{ width: 500, height: 200, className: 'sig-canvas' }}
                    ref={(ref) => setSigPad(ref)}
                    onEnd={createPdf}
                  />
                </div>
              </Col>
              <Col lg="12">
                <Button
                  onClick={() => {
                    sigPad?.clear();
                    createPdf();
                  }}
                  color="warning"
                  size="sm"
                  className="mt-1 mb-2"
                >
                  Clear
                </Button>
              </Col>
            </>
          ) : (
            <></>
          )}
        </>
      );
    }

    if (ff.fieldType === 'PDFCheckBox') {
      children = (
        <Col lg={`${b?.size || '6'}`} key={i ? `fc3-${i}` : ''}>
          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                checked={formFieldsValues.find((f: any) => f.fieldName === ff.fieldName)?.fieldValue === 'true'}
                onChange={(_) => {
                  const newFormFieldsValues = JSON.parse(JSON.stringify(formFieldsValues));
                  for (let i = 0; i <= newFormFieldsValues?.length; i++) {
                    if (newFormFieldsValues?.[i]?.fieldName === ff?.fieldName) {
                      if (!newFormFieldsValues[i].fieldValue) {
                        newFormFieldsValues[i].fieldValue = 'true';
                      } else {
                        newFormFieldsValues[i].fieldValue = '';
                      }
                    }
                  }
                  setFormFieldsValues(newFormFieldsValues);
                }}
              />{' '}
              {b.label}
            </Label>
          </FormGroup>
        </Col>
      );
    }
    return children;
  };

  return (
    <>
      <Modal className="modal-dialog-centered" size="xl" isOpen={isOpen} toggle={(e: any) => toggleModal(e)}>
        <div className="modal-header pb-1">
          <h5 className="modal-title">Document Signing</h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={(e) => toggleModal(e)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body pt-0">
          <Form onBlur={createPdf}>
            <Row className="mt-2">
              <Col md="6">
                <Row>
                  {i9EnglishFields.map((b: any, i: number) => {
                    if (b?.type === 'collapse') {
                      return (
                        <Col
                          key={`collapse-${i}`}
                          lg="12"
                          className="mb-4 mt-4"
                          onClick={(_) => {
                            let newIsCollapseOpen = JSON.parse(JSON.stringify(isCollapseOpen));
                            const isExists = newIsCollapseOpen.find((c: any) => c === b?.i);
                            if (isExists) {
                              newIsCollapseOpen = newIsCollapseOpen.filter((c: any) => c !== b?.i);
                              return setIsCollapseOpen(newIsCollapseOpen);
                            } else {
                              newIsCollapseOpen.push(b?.i);
                              return setIsCollapseOpen(newIsCollapseOpen);
                            }
                          }}
                          style={{ cursor: 'pointer' }}
                        >
                          <h4>
                            {b?.label}
                            <i
                              className={`ml-3 fas fa-angle-${
                                isCollapseOpen.find((c: any) => c === b?.i) ? 'down' : 'right'
                              }`}
                            ></i>
                          </h4>
                          <Collapse isOpen={!!isCollapseOpen.find((c: any) => c === b?.i)}>
                            <Row>
                              {b?.rows?.map((b2: any, i: number) => {
                                const ff2 = formFields[b2?.i];
                                if (!ff2) return;
                                const children = formRow(b2, ff2, `collapse-row-${i}`);
                                return children;
                              })}
                            </Row>
                          </Collapse>
                        </Col>
                      );
                    }
                    if (b?.type === 'row') {
                      const ff = formFields[b?.i];
                      if (!ff) return;
                      const children = formRow(b, ff, `row-${i}`);
                      return children;
                    }
                    if (b?.type === 'title') {
                      return (
                        <Col lg="12" className="mb-0 mt-4" key={`title-${i}`}>
                          <h2>{b?.label}</h2>
                          <hr />
                        </Col>
                      );
                    }
                  })}
                </Row>
              </Col>
              <Col md="6" className="mt-4">
                <div style={{ position: 'fixed', minWidth: 530 }}>
                  <Row>
                    <Col lg="12">
                      <Alert style={{ maxWidth: 540 }}>
                        If you would like to view this document in all its detail, please download.
                      </Alert>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12">
                      <iframe src={pdf} style={{ minHeight: 500, width: '100%' }} />
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col md="6">
                <Button
                  color="success"
                  size="md"
                  style={{ minWidth: '100px' }}
                  onClick={(e) => {
                    handleSubmit(e);
                    toggleModal(e);
                  }}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default I9DocumentModal;
