/*eslint-disable*/
import { Button, Card, CardHeader, CardBody, FormGroup, Form, Input, Container, Row, Col } from 'reactstrap';
import { toast } from 'react-toastify';
import { useContext, useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import CommonHeader from '../../../components/Headers/CommonHeader';
import KidsService from '../../../services/Kids';
import history from '../../../history';
import SchoolService from '../../../services/School';
import InputMask from 'react-input-mask';
import { toLocaleFormat, toSystemFormat } from '../../../helpers/dateFormat';

const KidsCreate = () => {
  const { id } = useParams<{ id: string }>();
  const pageTitle = id ? 'Update' : 'Create';
  const { kids, token, currentSchool }: any = useContext(AuthContext);
  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [schoolId, setSchoolId] = useState('');
  const [birthday, setBirthday] = useState('');
  const [gender, setGender] = useState('');
  const [alergyMedical, setAlergyMedical] = useState('');
  const [profileNotes, setProfileNotes] = useState('');
  const [otherNotes, setOtherNotes] = useState('');
  const [systemPhotoPermission, setSystemPhotoPermission] = useState(false);

  const imageRef = useRef(null as any);
  const [pictureUrl, setPictureUrl] = useState(kids?.picture || '');
  const [picture, setPicture] = useState();

  const [toggleButton, setToggleButton] = useState(kids?.systemPhotoPermission ? true : false);

  const handleToggleButton = () => {
    setToggleButton(!toggleButton);

    if (toggleButton == true) {
      setSystemPhotoPermission(true);
    } else {
      setSystemPhotoPermission(false);
    }
  };

  const [selectList, setSelectList] = useState([{}]);

  const listSelectSchool = async () => {
    try {
      const result = await SchoolService(token).listSelect();
      setSelectList(result.data || []);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No Schools found');
    }
  };

  const getById = async () => {
    try {
      const result = await KidsService(token).show(id);
      if (result?.data) {
        setFirstName(result?.data?.firstName || '');
        setLastName(result?.data?.lastName || '');
        setSchoolId(result?.data?.schoolId || '');
        setBirthday(result?.data?.birthday ? toLocaleFormat(result?.data?.birthday) : '');
        setGender(result?.data?.gender || '');
        setAlergyMedical(result?.data?.alergyMedical || '');
        setProfileNotes(result?.data?.profileNotes || '');
        setOtherNotes(result?.data?.otherNotes || '');
        setSystemPhotoPermission(result?.data?.systemPhotoPermission || 'NO');
        setPictureUrl(result?.data?.picture || '');
      }
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'Invalid Kids ID');
    }
  };

  useEffect(() => {
    if (id) {
      getById();
    }
    listSelectSchool();
  }, []);

  useEffect(() => {
    setSchoolId(currentSchool.value || '');
  }, [currentSchool]);

  const showOpenFileDialog = () => {
    if (isDisabled) {
      return;
    }
    if (imageRef?.current?.click) {
      imageRef.current.click();
    }
  };

  const onChangeFile = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];
    setPicture(file);
    setPictureUrl(URL.createObjectURL(file));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);
    setIsDisabled(true);

    const body = {
      firstName,
      lastName,
      schoolId,
      birthday: birthday ? toSystemFormat(birthday) : '',
      gender,
      alergyMedical,
      profileNotes,
      otherNotes,
      systemPhotoPermission,
    };

    try {
      const result = id ? await KidsService(token).update(id, body) : await KidsService(token).create(body);

      if (picture) {
        const formData = new FormData();
        formData.append('picture', picture as any);
        await KidsService(token).picture(result?.data?.id, formData);
      }

      toast.success(`${id ? 'Updated' : 'Created'} successfully!`);
      if (result?.data?.id) {
        history.push(`/kids/${result?.data?.id}`);
      }
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Create error!');
      toast.error(message);
    }

    setLoading(false);
    setIsDisabled(false);
  };

  return (
    <>
      <CommonHeader />
      <Container className="mt--8" fluid>
        <Row>
          <Col className="order-xl-2" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">{`${pageTitle} Kids`}</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Link to="/kids">
                      <Button color="primary" className="float-right">
                        Back
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmit}>
                  <h6 className="heading-small text-muted mb-4">
                    Picture <small style={{ textTransform: 'none' }}>(Recommended size: 800 x 800px)</small>
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <input
                            id="img-picture"
                            name="picture"
                            type="file"
                            accept=".png,.jpg,.jpeg"
                            style={{ display: 'none' }}
                            ref={imageRef}
                            disabled={isDisabled}
                            onChange={onChangeFile}
                          />
                          <img
                            alt="Kids"
                            className="rounded-circle"
                            src={pictureUrl ? `${pictureUrl}` : require('../../../assets/img/user-default.png').default}
                            style={{ width: 135, height: 135, cursor: isDisabled ? 'not-allowed' : 'pointer' }}
                            onClick={showOpenFileDialog}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-school-id">
                            School
                          </label>
                          <Input type="select" onChange={(e) => setSchoolId(e.target.value)} value={schoolId} disabled>
                            {selectList?.map((filter: any, index: number) => {
                              return (
                                <option value={filter.id} key={`filter-button-${index}`}>
                                  {filter.name}
                                </option>
                              );
                            })}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-firstName">
                            First Name
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-firstName"
                            placeholder="First Name"
                            type="text"
                            disabled={isDisabled}
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-lastName">
                            Last Name
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-lastName"
                            placeholder="Last Name"
                            type="text"
                            disabled={isDisabled}
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="3">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-birthday">
                            Birthday
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-birthday"
                            placeholder="Birthday"
                            type="text"
                            mask="99/99/9999"
                            tag={InputMask}
                            disabled={isDisabled}
                            value={birthday}
                            onChange={(e) => setBirthday(e.target.value)}
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="2">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-gender">
                            Gender
                          </label>
                          <Input type="select" onChange={(e) => setGender(e.target.value)} value={gender}>
                            <option value="BOY" key={`filter-button-boy`}>
                              Boy
                            </option>
                            <option value="GIRL" key={`filter-button-girl`}>
                              Girl
                            </option>
                            <option value="UNKNOWN" key={`filter-button-unknown`}>
                              Unknown
                            </option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <Row>
                            <Col>
                              <label className="form-control-label" htmlFor="input-systemPhotoPermission2">
                                Photo Permission
                              </label>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <label className="custom-toggle">
                                <Input type="checkbox" onChange={handleToggleButton} checked={systemPhotoPermission}>
                                  <option className="custom-toggle-slider rounded-circle" />
                                </Input>
                                <span className="custom-toggle-slider rounded-circle" />
                              </label>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>

                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">Records & Notes</h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-alergyMedical">
                            Alergy Medical
                          </label>
                          <textarea
                            className="form-control"
                            id="input-alergyMedical"
                            placeholder="Alergy Medical"
                            disabled={isDisabled}
                            value={alergyMedical}
                            onChange={(e) => setAlergyMedical(e.target.value)}
                            required
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-profileNotes">
                            Profile Notes
                          </label>
                          <textarea
                            className="form-control"
                            id="input-profileNotes"
                            placeholder="Profile Notes"
                            disabled={isDisabled}
                            value={profileNotes}
                            onChange={(e) => setProfileNotes(e.target.value)}
                            required
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-otherNotes">
                            Other Notes
                          </label>
                          <textarea
                            className="form-control"
                            id="input-otherNotes"
                            placeholder="Other Notes"
                            disabled={isDisabled}
                            value={otherNotes}
                            onChange={(e) => setOtherNotes(e.target.value)}
                            required
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <div className="pl-lg-4">
                    <Button className="my-4" color="primary" type="submit" disabled={isDisabled}>
                      Save
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default KidsCreate;
