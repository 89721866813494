import { Col, Row, FormGroup, InputGroup, Table } from 'reactstrap';
import ReactDatetime from 'react-datetime';
import { useContext, useEffect, useState } from 'react';
import { Context as AuthContext } from '../../../../contexts/AuthContext';
import ActivitySettings from '../../../../services/Attendances';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { isMobile } from 'react-device-detect';

const AttendanceTab = () => {
  const { token }: any = useContext(AuthContext);
  const { id: kidsId } = useParams<{ id: string }>();

  const [initialDate, setInitialDate] = useState(moment().isoWeekday(1).format('MM/DD/yyyy'));
  const [endDate, setEndDate] = useState(moment().isoWeekday(1).add(4, 'days').format('MM/DD/yyyy'));

  const [data, setData] = useState<any[]>([]);
  const [attendanceInfo, setAttendanceInfo] = useState<any[]>([]);

  useEffect(() => {
    getData();
    getSelectedDays();
  }, [initialDate, endDate, kidsId]);

  useEffect(() => {
    getSelectedDays();
  }, [data]);

  const getData = async () => {
    const query = `startDateTime=${initialDate}&finalDateTime=${endDate}`;
    const result = await ActivitySettings(token).listByKids(kidsId, query);

    if (result?.data) {
      const atendences = result.data;
      setData(atendences);
    }
  };

  const getSelectedDays = () => {
    if (initialDate > endDate) {
      setEndDate(initialDate);
    }

    const diff = moment(endDate).diff(moment(initialDate), 'days');

    let count = 0;

    const days = [];

    while (count <= diff) {
      const day = moment(initialDate).add(count, 'days').format('MM/DD/yyyy');
      const checkin = data.find(
        (data) => moment(data.createdAt).format('MM/DD/yyyy') === day && data.attendanceType === 'CHECKIN',
      );
      const checkout = data.find(
        (data) => moment(data.createdAt).format('MM/DD/yyyy') === day && data.attendanceType === 'CHECKOUT',
      );

      const daysObj = {
        day,
        in: checkin ? moment(checkin.createdAt).format('HH:mm') : '-',
        out: checkout ? moment(checkout.createdAt).format('HH:mm') : '-',
      };

      days.push(daysObj);

      count++;
    }

    setAttendanceInfo(days);
  };

  return (
    <>
      <Row>
        <Col lg="4" xs="6" style={isMobile ? { paddingLeft: 5 } : {}}>
          <FormGroup>
            <label className="form-control-label" htmlFor="input-validAt">
              Initial Date
            </label>
            <InputGroup className="input-group-alternative">
              <ReactDatetime
                inputProps={{
                  placeholder: 'Start Date',
                }}
                onChange={(newValue) => {
                  setInitialDate(moment(newValue).format('MM/DD/yyyy'));
                }}
                value={initialDate}
                timeFormat={false}
                dateFormat={'MM/DD/yyyy'}
                closeOnSelect={true}
              />
            </InputGroup>
          </FormGroup>
        </Col>

        <Col lg="4" xs="6" style={isMobile ? { paddingRight: 5 } : {}}>
          <FormGroup>
            <label className="form-control-label" htmlFor="input-validAt">
              End Date
            </label>
            <InputGroup className="input-group-alternative">
              <ReactDatetime
                inputProps={{
                  placeholder: 'End Date',
                }}
                onChange={(newValue) => {
                  setEndDate(moment(newValue).format('MM/DD/yyyy'));
                }}
                value={endDate}
                timeFormat={false}
                dateFormat={'MM/DD/yyyy'}
                closeOnSelect={true}
              />
            </InputGroup>
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col lg="12" style={isMobile ? { paddingLeft: 5, paddingRight: 5 } : {}}>
          <h4 className="text-muted text-uppercase">Attendance</h4>
          <Table style={isMobile ? { overflowX: 'hidden' } : {}}>
            <thead>
              <tr style={isMobile ? { paddingLeft: 0 } : {}}>
                <th>Date</th>
                <th>IN</th>
                <th>OUT</th>
              </tr>
            </thead>
            <tbody>
              {attendanceInfo.map((attendanceInfo) => (
                <tr key={attendanceInfo.day}>
                  <th scope="row">
                    {attendanceInfo.day} <br />
                    {moment(attendanceInfo.day).format('dddd')}
                  </th>
                  <td>{attendanceInfo.in}</td>
                  <td>{attendanceInfo.out}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
};

export default AttendanceTab;
