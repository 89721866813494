export const validStatus = {
  ACTIVE: { value: 'ACTIVE', color: 'success' },
  FOR_APPROVAL: { value: 'FOR_APPROVAL', color: 'info' },
  BLOCKED: { value: 'BLOCKED', color: 'warning' },
  DENIED: { value: 'DENIED', color: 'danger' },
};

export enum statusEnum {
  ACTIVE = 'ACTIVE',
  FOR_APPROVAL = 'FOR_APPROVAL',
  BLOCKED = 'BLOCKED',
  DENIED = 'DENIED',
}

export const validEnrollmentStatus = [
  { value: 'ENROLLED', label: 'Enrolled' },
  { value: 'ENROLLED_NOT_STARTED', label: 'Enrolled but not started' },
  { value: 'GRADUATED', label: 'Graduated' },
  { value: 'DISENROLLED', label: 'Disenrolled' },
  { value: 'FROZEN_ACCOUNT', label: 'Frozen Account' },
];

export enum enrollmentStatusEnum {
  ENROLLED = 'ENROLLED',
  ENROLLED_NOT_STARTED = 'ENROLLED_NOT_STARTED',
  GRADUATED = 'GRADUATED',
  DISENROLLED = 'DISENROLLED',
  FROZEN_ACCOUNT = 'FROZEN_ACCOUNT',
}

export interface KidsInterface {
  id: string;
  firstName: string;
  lastName: string;
  schoolId: string;
  status: statusEnum;
  birthday: string;
  gender: string;
  alergyMedical: string;
  profileNotes: string;
  otherNotes: string;
  systemPhotoPermission: boolean;
  socialMediaPhotoPermission: boolean;
  isCheckedIn?: boolean;
  isSleep?: boolean;
  isMoved?: boolean;
  fromAnotherClass?: boolean;
  enrolledCurrentClass?: boolean;
  movedRoomId?: string;
  enrolledClassId?: string;
  enrollmentStatus: enrollmentStatusEnum;
  promoCodeId?: string | null;
  promoCodeDiscountReg?: string | null;
  promoCodeDicsountTuition?: string | null;
  createdAt: Date;
  updatedAt: Date;
}

export default KidsInterface;
