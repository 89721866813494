import { Modal, Row, Col, Button, FormGroup, Input } from 'reactstrap';
import { useContext, useState } from 'react';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import ClassReportService from '../../../services/ClassReport';
import { toast } from 'react-toastify';
import FileDownload from 'js-file-download';
import DatePicker from 'react-datepicker';
import moment from 'moment';

type ClassTrackingReportModalProps = {
  isOpen: boolean;
  classId: string;
  toggleModal: (e?: any) => void;
};

const ClassTrackingReportModal = ({ isOpen, classId, toggleModal }: ClassTrackingReportModalProps) => {
  const { token }: any = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(new Date());

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);

    try {
      // const response = await ClassReportService(token).tracking(classId, moment(date).format('YYYY-MM-DD'));
      // FileDownload(response, 'tracking-report.pdf');
      const response = await ClassReportService(token).transition(classId, moment(date).format('YYYY-MM-DD'));
      FileDownload(response, 'transition-report.pdf');
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Report error!');
      toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  const handleDateChange = (d: Date) => {
    setDate(d);
  };

  return (
    <>
      <Modal className="modal-dialog-centered" isOpen={isOpen} toggle={(e: any) => toggleModal(e)}>
        <div className="modal-header pb-1">
          <h5 className="modal-title">Transition Report</h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={(e) => toggleModal(e)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body pt-0">
          <Row>
            <Col>
              <Row>
                <Col lg="12">
                  <FormGroup>
                    <label className="form-control-label">Date</label>
                    <Input
                      tag={DatePicker}
                      dateFormat="MM/dd/yyyy"
                      onChange={(d: any) => handleDateChange(d)}
                      selected={date}
                    />
                    <Button
                      color="primary"
                      type="button"
                      size="sm"
                      className="mt-1"
                      style={{ minWidth: '100px' }}
                      onClick={(e) => handleSubmit(e)}
                    >
                      OK
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default ClassTrackingReportModal;
