import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Spinner,
} from 'reactstrap';
import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import ReCAPTCHA from 'react-google-recaptcha';
import { Context as AuthContext } from '../../../contexts/AuthContext';

const Login = () => {
  const { handleLogin, getProfile } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [captcha, setCaptcha] = useState('');

  const onChangeRecaptcha = (value: any) => {
    setCaptcha(value);
  };

  const submit = async (e: any) => {
    e?.preventDefault();
    localStorage?.clear();
    if (loading || !captcha) {
      return;
    }

    setLoading(true);

    const result = await handleLogin(email, password, captcha);
    if (result?.error) {
      toast.error(result?.error);
      setLoading(false);
      return;
    }
    getProfile();
    setLoading(false);
  };

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Sign in with credentials</small>
            </div>
            <Form role="form" onSubmit={submit}>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Username/Email" type="text" onChange={(e: any) => setEmail(e?.target?.value)} />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Password" type="password" onChange={(e: any) => setPassword(e?.target?.value)} />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <div style={{ width: '100%', display: 'flex', placeContent: 'center' }}>
                  <ReCAPTCHA sitekey="6LcilxsqAAAAACQ2Cv3r9wjjcYLb7db_oHIl4ln0" onChange={onChangeRecaptcha} />
                </div>
              </FormGroup>
              {/*<div className="custom-control custom-control-alternative custom-checkbox">
                <input className="custom-control-input" id="customCheckLogin" type="checkbox" />
                <label className="custom-control-label" htmlFor="customCheckLogin">
                  <span className="text-muted">Remember me</span>
                </label>
              </div>*/}
              <div className="text-center">
                <Button color="primary" type="submit" disabled={loading}>
                  {loading ? <Spinner size="sm">Loading...</Spinner> : 'Sign in'}
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6">
            <a className="text-light" href="/forgot-password">
              <small>Forgot password?</small>
            </a>
          </Col>
          {/*<Col className="text-right" xs="6">
            <a className="text-light" href="/register">
              <small>Create new account</small>
            </a>
            </Col>*/}
        </Row>
      </Col>
    </>
  );
};

export default Login;
