import { Modal, Table } from 'reactstrap';
import { useContext, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import UserService from '../../../services/User';
import moment from 'moment';

type ClassDetailedTeachersModalProps = {
  isOpen: boolean;
  teachers?: any[];
  teachersIds?: any[];
  teachersClock?: any[];
  toggleModal: () => void;
};

const ClassDetailedTeachersModal = ({
  isOpen,
  teachersIds,
  teachersClock,
  toggleModal,
}: ClassDetailedTeachersModalProps) => {
  const { token }: any = useContext(AuthContext);
  const [data, setData] = useState([] as any[]);

  useEffect(() => {
    loadList();
  }, [teachersIds]);

  const loadList = async () => {
    try {
      const result = await UserService(token || '').listFilter(teachersIds || []);
      const dataResult = result.data || [];
      const data = dataResult.map((d: any) => ({
        ...d,
        clocked: teachersClock?.find((t) => {
          return t.id === d.id;
        })?.clocked,
      }));
      setData(data);
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Error fetching list');
      return toast.error(message);
    }
  };

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={isOpen}
      toggle={(e: any) => {
        e.preventDefault();
        toggleModal();
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title">Teachers Linked To This Class</h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={(e) => {
            e.preventDefault();
            toggleModal();
          }}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body pt-0">
        <Table striped bordered responsive>
          <tbody>
            <tr>
              <th>Name</th>
              <th>Clocked Into Class</th>
            </tr>
            {data.map((d) => (
              <tr>
                <td>{`${d.firstName} ${d.lastName}`}</td>
                <td>{`${moment(d.clocked).format('h:mm a')}`}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </Modal>
  );
};

export default ClassDetailedTeachersModal;
