/* eslint-disable prefer-spread */
/* eslint-disable max-lines */
import { Button, Col, Input, Label, Modal, Row } from 'reactstrap';
import { useEffect, useState } from 'react';
import { pdfjs } from 'react-pdf';
import SignatureCanvas from 'react-signature-canvas';
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';
import moment from 'moment';
import { toast } from 'react-toastify';
import HireDocuments from '../../../services/HireDocuments';
import { pdfByType } from '../../../helpers/pdfByType';

type SignDocumentModalProps = {
  isOpen: boolean;
  type: string;
  token: string;
  currentUser: any;
  toggleModal: (e?: any) => void;
  saveModal: (e?: any) => void;
};

const SignDocumentModal = ({ isOpen, type, token, currentUser, toggleModal, saveModal }: SignDocumentModalProps) => {
  const [loading, setLoading] = useState(false);
  const [isSign, setIsSign] = useState(false);
  const [sigPad, setSigPad] = useState<any>();
  const [pdfBlob, setPdfBlob] = useState<any>();
  const [pdfSignedBlob, setPdfSignedBlob] = useState<any>();
  const [signature, setSignature] = useState<any>();
  const [pdf, setPdf] = useState('');

  useEffect(() => {
    if (isOpen)
      pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) createPdf();
  }, [isOpen, sigPad]);

  const pdfUrl = pdfByType.find((p) => p?.type === type);

  const pdfString = pdfUrl?.url;
  if (!pdfString) {
    return <>Invalid document</>;
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);

    const body = {
      type: pdfUrl?.type,
      userId: currentUser?.userId || '',
      userName: currentUser?.userName || '',
      userToken: token,
      pdf: pdfBlob,
      pdfUrl: pdfUrl?.url,
      pdfSigned: pdfSignedBlob,
      signature,
      options: { ...pdfUrl, signPng: signature, signatureDate: moment().format('YYYY-MM-DD') },
    };

    try {
      await HireDocuments('').create(body);
      toast.success(`Saved successfully!`);
      saveModal(pdfUrl?.type);
    } catch (e: any) {
      console.log(e);
      const message = String(e?.response?.data?.message || 'Create error!');
      toast.error(message);
    }

    setLoading(false);
  };

  const createPdf = async () => {
    try {
      const arrayBuffer = await fetch(pdfString).then((res) => res.arrayBuffer());
      setPdfBlob(arrayBuffer);
      const pdfDoc = await PDFDocument.load(arrayBuffer);
      const pages = pdfDoc.getPages();
      const page = pages[pdfUrl?.signPage || 0];
      if (page && sigPad) {
        const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);
        if (pdfUrl?.coordinatesRectangle) {
          page.drawRectangle({
            ...pdfUrl?.coordinatesRectangle,
          });
        }

        const staffName = currentUser?.userName || '';

        const pageName =
          typeof pdfUrl?.signNamePage !== 'undefined' ? pages[pdfUrl?.signNamePage] : pages[pdfUrl?.signPage || 0];
        if (pdfUrl?.coordinatesName) {
          pageName.drawText(staffName, {
            ...pdfUrl?.coordinatesName,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
          });
        }

        const names = pdfUrl?.coordinatesNames || [];
        for (const n of names) {
          const pageName = pages[n?.page || 0];
          pageName.drawText(staffName, {
            ...n?.coordinates,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
          });
        }

        if (pdfUrl?.coordinatesDate) {
          const pageDate =
            typeof pdfUrl?.signDatePage !== 'undefined' ? pages[pdfUrl?.signDatePage] : pages[pdfUrl?.signPage || 0];
          pageDate.drawText(moment().format('MM/DD/YYYY'), {
            ...pdfUrl?.coordinatesDate,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
          });
        }

        const img = sigPad?.getTrimmedCanvas()?.toDataURL('image/png');
        setSignature(img);
        const pngImage = await pdfDoc.embedPng(img);
        page.drawImage(pngImage, pdfUrl.coordinates);
      }

      const pdfBytes = await pdfDoc.save();

      const blob = new Blob([pdfBytes], { type: 'application/pdf' });
      setPdfSignedBlob(blob);
      const docUrl = URL.createObjectURL(blob);
      setPdf(docUrl);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Modal className="modal-dialog-centered" size="xl" isOpen={isOpen} toggle={(e: any) => toggleModal(e)}>
        <div className="modal-header pb-1">
          <h5 className="modal-title">{pdfUrl?.title || ''}</h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={(e) => toggleModal(e)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body pt-0">
          <Row className="mt-2">
            <Col lg="12" className="mt-4" style={{ maxHeight: '500px', overflow: 'scroll' }}>
              <iframe src={pdf} style={{ minHeight: 500, width: '100%' }} />
            </Col>
            <Col lg="12" className="mt-4">
              <Label>
                <strong>Signature:</strong>
              </Label>
              <div className="d-flex flex-wrap align-items-center">
                <div className="form-check form-check-inline mr-3">
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    id="to-sign"
                    checked={isSign}
                    onChange={(e) => setIsSign(!!e.target.checked)}
                  />
                  <Label className="form-check-label mr-2" htmlFor="to-sign">
                    I hereby acknowledge and agree that my electronic signature on this document is the legal equivalent
                    of my handwritten signature.
                  </Label>
                </div>
                {isSign && (
                  <Col lg="12" className="mt-4">
                    <div>
                      <SignatureCanvas
                        penColor="blue"
                        canvasProps={{ width: 500, height: 200, className: 'sig-canvas' }}
                        ref={(ref) => setSigPad(ref)}
                        onEnd={createPdf}
                      />
                    </div>
                    <Button onClick={() => sigPad?.clear()} color="warning" size="sm" className="mt-1 mb-2">
                      Clear
                    </Button>
                  </Col>
                )}
              </div>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col md="6">
              <Button
                color="success"
                size="md"
                style={{ minWidth: '100px' }}
                onClick={(e) => {
                  handleSubmit(e);
                  toggleModal(e);
                }}
              >
                Save
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default SignDocumentModal;
