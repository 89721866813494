import EmployeeTerminateInterface from '../interfaces/EmployeeTerminateInterface';
import http from '../helpers/http';

const EmployeeTerminate = (token: string) => {
  const headers = {
    'x-access-token': token,
  };

  return {
    create: async (body: Partial<EmployeeTerminateInterface>) => {
      const result = await http.post(`/employee-terminate`, body, { headers });
      return result.data;
    },
    listBySchool: async (schoolId: string) => {
      const result = await http.get(`/employee-terminate/by-school/${schoolId}`, {
        headers,
      });
      return result.data;
    },
    revoke: async (employeeId: string) => {
      const result = await http.patch(
        `/employee-terminate/${employeeId}/revoke`,
        {},
        {
          headers,
        },
      );
      return result.data;
    },
  };
};

export default EmployeeTerminate;
