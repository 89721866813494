import http from '../helpers/http';
import { FilteredOptionsInterface, makeQueryParamsForRequest } from '../entities/FilteredOptions';
import FranchiseeInterface from '../interfaces/FranchiseeInterface';

const Franchisee = (token: string) => {
  const headers = {
    'x-access-token': token,
  };

  return {
    list: async (filteredOptions?: FilteredOptionsInterface) => {
      const params = makeQueryParamsForRequest(filteredOptions);
      const result = await http.get('/franchisees', {
        headers,
        params,
      });
      return result.data;
    },
    show: async (id: string) => {
      const result = await http.get(`/franchisees/${id}`, {
        headers,
      });
      return result.data;
    },
    create: async (body: Partial<FranchiseeInterface>) => {
      const result = await http.post(`/franchisees`, body, { headers });
      return result.data;
    },
    update: async (id: string, body: Partial<FranchiseeInterface>) => {
      const result = await http.put(`/franchisees/${id}`, body, { headers });
      return result.data;
    },
    delete: async (id: string) => {
      const result = await http.delete(`/franchisees/${id}`, {
        headers,
      });
      return result.data;
    },
    listSelect: async () => {
      const result = await http.get(`/franchisees/select/all`, {
        headers,
      });
      return result.data;
    },
    picture: async (franchiseeId: string, body: FormData) => {
      const headers = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-access-token': token,
        },
      };
      const result = await http.patch(`/franchisees/${franchiseeId}/picture`, body, headers);
      return result.data;
    },
  };
};

export default Franchisee;
