import http from '../helpers/http';
import HolidaysInterface from '../interfaces/HolidaysInterface';

const Holidays = (token: string) => {
  const headers = {
    'x-access-token': token,
  };

  return {
    list: async (year: number, schoolId: string) => {
      const result = await http.get(`/holidays/${schoolId}?year=${year}`, {
        headers,
      });
      return result.data;
    },
    create: async (body: Partial<HolidaysInterface>) => {
      const result = await http.post(`/holidays`, body, { headers });
      return result.data;
    },
    update: async (id: string, body: Partial<HolidaysInterface>) => {
      const result = await http.put(`/holidays/${id}`, body, { headers });
      return result.data;
    },
    delete: async (id: string) => {
      const result = await http.delete(`/holidays/${id}`, {
        headers,
      });
      return result.data;
    },
  };
};

export default Holidays;
