import moment from 'moment';

const collumns = () => {
  return [
    {
      name: 'Posted',
      grow: 1,
      cell: (row: any) => (
        <>
          {moment(row.createdAt).utc().format('MMMM')}
          <br /> {moment(row.createdAt).utc().format('d, Y')}
        </>
      ),
    },
    {
      name: 'Status',
      grow: 1,
      cell: (row: any) => (
        <>
          {row.status === 'PENDDING' && (
            <div style={{ border: '1px solid #d4d110', padding: '4px 8px', borderRadius: '3px' }}>
              <span style={{ color: '#d4d110', fontWeight: '700' }}>REQUESTED</span>
            </div>
          )}

          {row.status === 'REPPROVE' && (
            <div style={{ border: '1px solid #d43710', padding: '4px 8px', borderRadius: '3px' }}>
              <span style={{ color: '#d43710', fontWeight: '700' }}>DENIED</span>
            </div>
          )}

          {row.status === 'APPROVE' && (
            <div style={{ border: '1px solid #08b408', padding: '4px 8px', borderRadius: '3px' }}>
              <span style={{ color: '#08b408', fontWeight: '700' }}>APPROVED</span>
            </div>
          )}
        </>
      ),
    },
    {
      name: 'Dates',
      grow: 2,
      cell: (row: any) => (
        <>
          {moment(row.startDate).utc().format('MM/DD/YYYY')}
          {row.startDate !== row.endDate ? <> ~ {moment(row.endDate).utc().format('MM/DD/YYYY')}</> : ''}
        </>
      ),
    },
    {
      name: 'Reason',
      grow: 1,
      cell: (row: any) => row.describe,
    },
    {
      name: 'Type',
      grow: 1,
      cell: (row: any) => row.reason,
    },
    {
      name: 'Change',
      grow: 1,
      cell: (row: any) => row.hours,
    },
    {
      name: 'Balance',
      grow: 1,
      cell: (row: any) => row.balance | 0,
    },
  ];
};

export default collumns;
