/* eslint-disable max-lines */
import { Card, Button, Container, Row, Col, CardBody, FormGroup, Form, Input, CardTitle } from 'reactstrap';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import ScheduleService from '../../../services/SchedulePublic';
import { toLocaleFormatSlash } from '../../../helpers/dateFormat';
import ChildForm from './ChildForm';
import moment from 'moment';
import InputMask from 'react-input-mask';

type scheduleProps = {
  isOpen?: boolean;
  scheduleId?: string;
  currentSchedule?: any;
  franchiseeName?: string;
  schoolId?: string;
  schoolName?: string;
  selectedDate: string;
  selectedTime?: string;
  onModalStatus: () => void;
  onCreateSchedule: () => void;
};

const ScheduleForm = ({
  scheduleId,
  currentSchedule,
  franchiseeName,
  schoolId,
  schoolName,
  selectedDate,
  selectedTime,
  onModalStatus,
  onCreateSchedule,
}: scheduleProps) => {
  const dateTime = moment(`${selectedDate} ${selectedTime}`);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isAccept, setIsAccept] = useState(false);
  const [loading, setLoading] = useState(false);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [aboutUs, setAboutUs] = useState('');
  const [typeTour, setTypeTour] = useState('');

  const [kids, setKids] = useState([{ firstName: '', lastName: '', birthday: '', potentialDate: '' }] as {
    id?: string;
    firstName: string;
    lastName: string;
    birthday: string;
    potentialDate: string | null;
  }[]);

  const setChildFirstName = (value: string, index: number) => {
    const newKids = [...kids];
    newKids.forEach((k, i) => {
      if (i == index) {
        k.firstName = value;
      }
    });
    setKids(newKids);
  };

  const setChildLastName = (value: string, index: number) => {
    const newKids = [...kids];
    newKids.forEach((k, i) => {
      if (i == index) {
        k.lastName = value;
      }
    });
    setKids(newKids);
  };

  const setChildBirthday = (value: string, index: number) => {
    const newKids = [...kids];
    newKids.forEach((k, i) => {
      if (i == index) {
        k.birthday = toLocaleFormatSlash(value);
      }
    });
    setKids(newKids);
  };

  const setPotentialDate = (value: string, index: number) => {
    const newKids = [...kids];
    newKids.forEach((k, i) => {
      if (i == index) {
        k.potentialDate = value ? toLocaleFormatSlash(value) : null;
      }
    });
    setKids(newKids);
  };

  const addKids = () => {
    const newKids = [...kids];
    newKids.push({ firstName: '', lastName: '', birthday: '', potentialDate: '' });
    setKids(newKids);
  };

  const deleteKids = (index: number) => {
    const newKids = kids.filter((_, i) => index !== i);
    setKids(newKids);
  };

  const changeonModalStatus = () => {
    onModalStatus();
  };

  useEffect(() => {
    if (currentSchedule) {
      setFirstName(currentSchedule?.firstName || '');
      setLastName(currentSchedule?.lastName || '');
      setEmail(currentSchedule?.email || '');
      setPhone(currentSchedule?.phone || '');
      setAboutUs(currentSchedule?.aboutUs || '');
      setTypeTour(currentSchedule?.typeTour || '');
      if (currentSchedule?.scheduleKids?.length) {
        setKids(currentSchedule?.scheduleKids);
      }
    }
  }, [currentSchedule]);

  const handleSubmitForm = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!isAccept) {
      return toast.warn('Please accept the terms.');
    }

    if (loading) return;

    setLoading(true);
    setIsDisabled(true);

    const newKids = [];
    for (const k of kids) {
      const obj: any = {
        ...k,
        potentialStartDate: k.potentialDate && k.potentialDate !== 'Invalid date' ? k.potentialDate : null,
      };
      delete obj.potentialDate;
      newKids.push(obj);
    }
    const body = {
      dateTour: selectedDate ? toLocaleFormatSlash(selectedDate) : selectedDate || '',
      timeTour: selectedTime,
      firstName,
      lastName,
      email,
      phone,
      aboutUs,
      typeTour,
      schoolId,
      kids: newKids,
    };

    try {
      const result = scheduleId
        ? await ScheduleService().update(scheduleId, body)
        : await ScheduleService().create(body);

      toast.success(`${scheduleId ? 'Updated' : 'Created'} successfully!`);
      if (result?.data?.id) {
        onModalStatus();
        onCreateSchedule();
      }
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Create error!');
      toast.error(message);
    }
    setLoading(false);
    setIsDisabled(false);
  };

  return (
    <>
      <Container fluid>
        <Form onSubmit={handleSubmitForm}>
          <Card>
            <CardBody>
              <Row>
                <Col md="12">
                  <p>
                    To confirm and schedule your <strong>{schoolName}</strong> tour on{' '}
                    <strong>{`${dateTime.format('MM/DD/YYYY')} at ${dateTime.format('hh:mm A')}`}</strong>, please
                    provide the following information:
                  </p>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-firstName">
                      First Name *
                    </label>
                    <Input
                      className="form-control-alternative"
                      id="input-firstName"
                      placeholder="First Name"
                      type="text"
                      disabled={isDisabled}
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-lastName">
                      Last Name *
                    </label>
                    <Input
                      className="form-control-alternative"
                      id="input-lastName"
                      placeholder="Last Name"
                      type="text"
                      disabled={isDisabled}
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-email">
                      E-mail *
                    </label>
                    <Input
                      className="form-control-alternative"
                      id="input-email"
                      placeholder="E-mail"
                      type="text"
                      disabled={isDisabled}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <label className="form-control-label">Phone Number *</label>
                    <Input
                      className="form-control-alternative"
                      placeholder="Phone Number"
                      type="text"
                      mask="(999) 999-9999"
                      tag={InputMask}
                      disabled={isDisabled}
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col lg="12">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-aboutUs">
                      How did you hear about us? *
                    </label>

                    <select
                      name="input-aboutUs"
                      id="input-aboutUs"
                      className="form-control"
                      disabled={isDisabled}
                      value={aboutUs}
                      onChange={(e) => setAboutUs(e.target.value)}
                      required
                    >
                      <option value="">Please select</option>
                      <option value="Co-Worker">Co-Worker</option>
                      <option value="Drive By">Drive By</option>
                      <option value="Family Referral">Family Referral</option>
                      <option value="Friend">Friend</option>
                      <option value="Online">Online</option>
                      <option value="Other">Other</option>
                    </select>
                  </FormGroup>
                </Col>
                <Col lg="12">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-typeTour">
                      Tour Type *
                    </label>

                    <select
                      name="input-typeTour"
                      id="input-typeTour"
                      className="form-control"
                      disabled={isDisabled}
                      value={typeTour}
                      onChange={(e) => setTypeTour(e.target.value)}
                      required
                    >
                      <option value="">Please select</option>
                      <option value="In Person">In Person</option>
                      <option value="Virtual">Virtual</option>
                    </select>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Card>
                  <CardBody>
                    <CardTitle tag="h5" className="text-uppercase text-muted mb-2">
                      Childs Information
                    </CardTitle>

                    {kids.map((child, index: number) => (
                      <ChildForm
                        firstName={child.firstName}
                        lastName={child.lastName}
                        birthday={child.birthday}
                        potentialDate={child.potentialDate || ''}
                        index={index}
                        setFirstName={setChildFirstName}
                        setLastName={setChildLastName}
                        setBirthday={setChildBirthday}
                        setPotentialDate={setPotentialDate}
                        deleteChild={deleteKids}
                      />
                    ))}
                    <Row>
                      <Col>
                        <Button color="info" size="sm" className="mt-2" onClick={addKids}>
                          + Add Child
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Row>
              <Row className="mt-1">
                <Col>
                  <FormGroup check>
                    <Input type="checkbox" checked={isAccept} onChange={() => setIsAccept(!isAccept)} />{' '}
                    <p>
                      By completing and submitting this web form, I am authorizing {franchiseeName || ''} staff to
                      contact me via email and/or phone regarding enrollment information.
                    </p>
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md="6 text-center">
                  <Button color="primary" type="submit" size="md">
                    Schedule my Tour
                  </Button>
                </Col>
                <Col md="6 text-center">
                  <Button color="secondary" size="md" onClick={changeonModalStatus}>
                    Cancel
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Form>
      </Container>
    </>
  );
};

export default ScheduleForm;
