/* eslint-disable max-lines */
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Card, CardHeader, CardBody, Container, Row, Col } from 'reactstrap';

import { useState, useEffect, useContext } from 'react';
import { Context as AuthContext } from '../../contexts/AuthContext';
import OpacityLoaderSpinner from '../Core/OpacityLoaderSpinner';
import SchoolEventsCalendar from '../Calendar/SchoolEventsCalendar';
import KidsTimeline from '../Kids/KidsTimeline';
import KidsViewCamera from '../Kids/KidsViewCamera';
import UserService from '../../services/User';
import QrCode from '../../components/Core/QrCode';
import { ErrorsList } from '../../views/pages/Home/ErrorsList';

const ParentDashboard = ({ currentUser }: { currentUser: any }) => {
  const [loading, setLoading] = useState(false);

  const [schoolId, setSchoolId] = useState('');
  const { currentSchool, token }: any = useContext(AuthContext);
  const [kidsUser, setKidsUser] = useState<any>([]);
  const [selectedKid, setSelectedKid] = useState('');
  const [modalKidsViewCamera, setModalKidsViewCamera] = useState(false);
  const [modalQr, setModalQr] = useState(false);
  const toggleModalQr = () => setModalQr(!modalQr);
  const [breadcrumbModal, setBreadcrumbModal] = useState('');
  const [modalKidsViewCameraSelectedKid, setModalKidsViewCameraSelectedKid] = useState('');

  const getAuthUserData = async () => {
    setLoading(true);
    try {
      const result = await UserService(token).listKids(currentUser.id);
      if (result?.data) {
        setKidsUser(
          result?.data.map((kidsUser: any) => {
            return {
              id: kidsUser.kids.id,
              firstName: kidsUser.kids.firstName,
              lastName: kidsUser.kids.lastName,
              fullName: `${kidsUser.kids.firstName} ${kidsUser.kids.lastName}`,
              picture: kidsUser.kids.picture,
              isCheckedIn: kidsUser.kids.isCheckedIn,
              class: kidsUser.kids.enrollment.class,
              attendances: kidsUser.kids.attendances.reverse(),
            };
          }),
        );
        setSelectedKid(result.data[0].kids.id);
      }
      setLoading(false);
    } catch (e: any) {
      console.error(e?.response?.data?.message || 'Invalid user ID');
      setLoading(false);
    }
  };

  const toggleModalKidsViewCamera = () => {
    setModalKidsViewCameraSelectedKid('');
    getAuthUserData();
    setModalKidsViewCamera(!modalKidsViewCamera);
  };

  useEffect(() => {
    setSchoolId(currentSchool.value || '');
    getAuthUserData();
  }, []);

  useEffect(() => {
    setSchoolId(currentSchool.value || '');
  }, [currentSchool]);

  return (
    <>
      <Modal isOpen={modalQr} toggle={toggleModalQr} centered>
        <ModalHeader toggle={toggleModalQr} className="pb-0">
          My Code
        </ModalHeader>
        <ModalBody className="pt-0">
          <QrCode />
        </ModalBody>
        <ModalFooter style={{ textAlign: 'center' }}>
          <span className="h2 font-weight-bold mx-auto">{`${currentUser.pin || '-'}`}</span>
        </ModalFooter>
      </Modal>
      <Container className="mt--7 " fluid>
        <Row className="mt-5">
          <Col xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Parent's Dashboard</h3>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <Row className="mb-3">
                  <Col xs="12">
                    <button
                      className="w-100 btn-lg btn-success"
                      style={{ height: '100px', fontSize: '30px', fontWeight: 'bold' }}
                      onClick={() => toggleModalQr()}
                    >
                      <i className="ni ni-badge" />
                      &nbsp;Check in/Check out
                    </button>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md="12">
                    <ErrorsList />
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <SchoolEventsCalendar schoolId={schoolId} />
                  </Col>
                </Row>
                <Row>
                  <Col xs="12">
                    <button
                      className="w-100 btn-lg btn-primary mt-3"
                      style={{ height: '100px', fontSize: '30px', fontWeight: 'bold' }}
                      onClick={() => toggleModalKidsViewCamera()}
                    >
                      <i className="fas fa-camera"></i>&nbsp;Cameras
                    </button>
                  </Col>
                </Row>
                <Row className="mt-3 mb-2">
                  <Col xs="12">
                    {kidsUser.map((kid: any, i: any) => {
                      return (
                        <button
                          key={`kidsUser-${i}`}
                          className={
                            selectedKid === kid.id ? 'btn-sm btn-secondary mr-3 active' : 'btn-sm btn-secondary mr-3'
                          }
                          onClick={() => setSelectedKid(kid.id)}
                        >
                          <div className="align-items-center media">
                            <span className="avatar avatar-sm rounded-circle">
                              <img alt={kid.fullName} src={kid.picture} style={{ height: '100%' }} />
                            </span>
                            <div className="ml-2 d-none d-lg-block media">
                              <span className="mb-0 text-sm font-weight-bold">{kid.fullName}</span>
                            </div>
                          </div>
                        </button>
                      );
                    })}
                  </Col>
                </Row>
                <Row>
                  <KidsTimeline kidsId={selectedKid} currentUser={currentUser} />
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      {loading ? <OpacityLoaderSpinner /> : <></>}
      <Modal
        className="modal-dialog-centered"
        isOpen={modalKidsViewCamera}
        toggle={() => toggleModalKidsViewCamera()}
        size="lg"
      >
        <div className="modal-header">
          <h5 className="modal-title w-100" id="Label">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb parentsDash">
                <li className="breadcrumb-item" style={{ paddingTop: '8px' }}>
                  <a
                    href="#"
                    style={{ color: '#5e72e4' }}
                    onClick={(e) => {
                      e.preventDefault();
                      setModalKidsViewCameraSelectedKid('');
                    }}
                  >
                    Your Kids
                  </a>
                </li>
                {breadcrumbModal !== '' && (
                  <li className="breadcrumb-item active" aria-current="page" style={{ color: '#8898aa' }}>
                    <div className="align-items-center media">
                      <span className="avatar avatar-sm rounded-circle">
                        <img
                          alt={kidsUser.find((item: any) => item.id === selectedKid).fullName}
                          src={kidsUser.find((item: any) => item.id === selectedKid).picture}
                          style={{ height: '100%' }}
                        />
                      </span>
                      <div className="ml-2 d-none d-lg-block media">
                        <span className="mb-0 font-weight-bold">
                          {kidsUser.find((item: any) => item.id === selectedKid).fullName}
                        </span>
                      </div>
                    </div>
                  </li>
                )}
              </ol>
            </nav>
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => toggleModalKidsViewCamera()}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body pt-0" style={{ minHeight: '300px' }}>
          <KidsViewCamera
            kidsList={kidsUser}
            currentUser={currentUser}
            setSelectedKidObject={(kid: any) => {
              setBreadcrumbModal(kid.fullName || '');
            }}
            selectedKid={modalKidsViewCameraSelectedKid}
            setSelectedKid={(e: any) => setModalKidsViewCameraSelectedKid(e)}
          />
        </div>
      </Modal>
    </>
  );
};

export default ParentDashboard;
