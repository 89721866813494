import { Card, Button, Container, Row, Col, CardBody, FormGroup, Form, Input, Label } from 'reactstrap';
import { useContext, useState, useRef, useEffect } from 'react';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import { toast } from 'react-toastify';
import DocumentsFolders from '../../../services/DocumentsFolders';
import PermissionGroups from '../../../services/PermissionGroups';
import { useLocation } from 'react-router-dom';

type FoldersIdProps = {
  onModalStatus: () => void;
  title: string;
  onRefresh: () => void;
  folderId?: string;
};

const DocumentsFoldersForm = ({ onModalStatus, title, onRefresh, folderId }: FoldersIdProps) => {
  const { token, currentSchool }: any = useContext(AuthContext);

  const { hash } = useLocation();
  const hashFolderId = hash.replace('#', '');

  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const schoolId = currentSchool?.value;
  const typeFile = title;
  const position = 100;

  const [name, setName] = useState('');
  const [groupPermissions, setGroupPermissions] = useState([]);
  const imageRef = useRef(null as any);
  const [pictureUrl, setPictureUrl] = useState('');
  const [picture, setPicture] = useState();
  const [isPublic, setIsPublic] = useState(Boolean);

  const [permissionsGroupsList, setPermissionsGroupsList] = useState([] as any[]);

  const changeonModalStatus = () => {
    onModalStatus();
  };

  const showOpenFileDialog = () => {
    if (isDisabled) {
      return;
    }
    if (imageRef?.current?.click) {
      imageRef.current.click();
    }
  };

  const onChangeFile = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];
    setPicture(file);
    setPictureUrl(URL.createObjectURL(file));
  };

  const handleSubmitForm = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);
    setIsDisabled(true);

    let folderIdCreate = '';
    if (hashFolderId) {
      folderIdCreate = hashFolderId;
    }

    const body = {
      schoolId,
      documentsFoldersId: folderIdCreate,
      typeFile,
      name,
      groupPermissions,
      position,
      isPublic,
    };

    try {
      const result = folderId
        ? await DocumentsFolders(token).update(folderId, body)
        : await DocumentsFolders(token).create(body);

      if (picture) {
        const formData = new FormData();
        formData.append('picture', picture as any);
        await DocumentsFolders(token).picture(result?.data?.id, formData);
      }

      toast.success(`${folderId ? 'Updated' : 'Created'} successfully!`);
      if (result?.data?.id) {
        onModalStatus();
        onRefresh();
      }
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Create error!');
      toast.error(message);
    }

    setLoading(false);
    setIsDisabled(false);
  };

  const getById = async () => {
    try {
      const result = await DocumentsFolders(token).show(folderId || '');
      if (result?.data) {
        setName(result?.data?.name || '');
        setPictureUrl(result?.data?.picture || '');
        setGroupPermissions(result?.data?.groupPermissions || []);
        setIsPublic(result?.data?.isPublic);
      }
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'Invalid Folder ID');
    }
  };

  const listPermissionsGroups = async () => {
    try {
      const result = await PermissionGroups(token).list();
      setPermissionsGroupsList(result || []);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No permission groups found!');
    }
  };

  const getGroupPermissionsChecked = (value: string) => {
    const isChecked = groupPermissions.find((v) => v === value);
    return !!isChecked;
  };

  const setGroupPermissionsChecked = (value: string) => {
    const newGroupPermissionsSelected = JSON.parse(JSON.stringify(groupPermissions));
    if (newGroupPermissionsSelected.includes(value)) {
      const removedArray = newGroupPermissionsSelected.filter((v: string) => String(v) !== value);
      setGroupPermissions(removedArray);
      return;
    }
    newGroupPermissionsSelected.push(value);
    setGroupPermissions(newGroupPermissionsSelected);
  };

  const inputGroupPermissions = () => {
    try {
      return (
        <>
          <FormGroup row>
            <Col md="12">
              <label className="form-control-label" htmlFor="activityType">
                Access permission
              </label>
            </Col>
            {permissionsGroupsList.map((s: any) => (
              <Col md="12" key={`settings-input-${s.id}`}>
                <FormGroup check>
                  <Label check>
                    <Input
                      name="activityType"
                      type="checkbox"
                      defaultChecked={getGroupPermissionsChecked(s.name)}
                      onChange={() => setGroupPermissionsChecked(s.name)}
                    />{' '}
                    {s.name}
                  </Label>
                </FormGroup>
              </Col>
            ))}
          </FormGroup>
        </>
      );
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No permission groups found!');
    }
  };

  useEffect(() => {
    if (folderId) {
      getById();
    }
    listPermissionsGroups();
  }, []);

  useEffect(() => {
    listPermissionsGroups();
  }, [groupPermissions]);

  return (
    <>
      <Container fluid>
        <Form onSubmit={handleSubmitForm}>
          <Card>
            <CardBody>
              <Row>
                <Col lg="12">
                  <Row style={{ display: 'grid' }}>
                    <Row>
                      <Col lg="12">
                        <h6 className="heading-small text-muted mb-4">
                          Picture <small style={{ textTransform: 'none' }}>(Recommended size: 800 x 800px)</small>
                        </h6>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12" style={{ textAlign: 'center' }}>
                        <FormGroup>
                          <input
                            id="img-picture"
                            name="picture"
                            type="file"
                            accept=".png,.jpg,.jpeg"
                            style={{ display: 'none' }}
                            ref={imageRef}
                            disabled={isDisabled}
                            onChange={onChangeFile}
                          />
                          <img
                            alt={title}
                            className=""
                            src={pictureUrl ? `${pictureUrl}` : require('../../../assets/img/default.png').default}
                            style={{ width: 135, height: 135, cursor: isDisabled ? 'not-allowed' : 'pointer' }}
                            onClick={showOpenFileDialog}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-name">
                      Folder Name
                    </label>
                    <Input
                      className="form-control-alternative"
                      id="input-name"
                      placeholder="Folder Name"
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="12">{inputGroupPermissions()}</Col>
              </Row>
              <Row>
                <Col lg="12">
                  <FormGroup row>
                    <Col md="12">
                      <label className="form-control-label" htmlFor="isPublic">
                        Can be accessed out of school network?
                      </label>
                    </Col>
                    <Col md="12"></Col>
                    <Col md="12">
                      <label className="custom-toggle">
                        <Input type="checkbox" onChange={(e) => setIsPublic(e.target.checked)} checked={isPublic} />
                        <span className="custom-toggle-slider rounded-circle" />
                      </label>
                    </Col>
                  </FormGroup>
                </Col>
              </Row>

              <Row className="mt-5">
                <Col md="6 text-center" xs="6">
                  <Button color="primary" type="submit" size="lg" style={{ minWidth: '100px' }}>
                    Save
                  </Button>
                </Col>
                <Col md="6 text-center" xs="6">
                  <Button color="secondary" size="lg" style={{ minWidth: '100px' }} onClick={changeonModalStatus}>
                    Cancel
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Form>
      </Container>
    </>
  );
};

export default DocumentsFoldersForm;
