/* eslint-disable max-lines */
import moment from 'moment';
import { Modal, Label, Input, Col, FormGroup, Row } from 'reactstrap';
import { useState, useContext } from 'react';
import { Context as AuthContext } from '../../../../contexts/AuthContext';
import IncidentReports from '../../../../services/IncidentReports';
import { toast } from 'react-toastify';
import isAdmin from '../../../../helpers/isAdmin';
import isParent from '../../../../helpers/isParent';

type ViewIncidentReportModalProps = {
  data?: any;
  isIncidentReportOpen?: boolean;
  setIsIncidentReportOpen: (value: boolean) => void;
  incidentSigned?: (incident: any) => void | Promise<void>;
  showCloseButton?: boolean;
};

const ViewIncidentReportModal = ({
  data,
  isIncidentReportOpen,
  setIsIncidentReportOpen,
  incidentSigned,
  showCloseButton = true,
}: ViewIncidentReportModalProps) => {
  const { token, user }: any = useContext(AuthContext);
  const startDate = moment(data?.kids?.birthday || undefined);
  const finalDate = moment();
  const [isSign, setIsSign] = useState(false);
  const [signature, setSignature] = useState('');

  const handleSubmit = async (e: any) => {
    e?.preventDefault();
    const body: any = {
      parentSignature: null,
      parentSignatureDate: null,

      afectedOrInjured: data?.afectedOrInjured,
      type: data?.type,
      acidentDate: data?.acidentDate,
      acidentTime: data?.acidentTime,
      location: data?.location,
      incidentDescription: data?.incidentDescription,
      injuryNatureDescription: data?.injuryNatureDescription,
      medicalTreatmentDescription: data?.medicalTreatmentDescription,
      ambulanceCalled: data?.ambulanceCalled,
      witness: data?.witness,
      action: data?.action,

      directorSignature: null,
      directorSignatureDate: null,
    };

    if (isAdmin(user)) {
      body.directorSignature = signature;
      body.directorSignatureDate = moment().format('YYYY-MM-DD HH:mm:ss');
    }

    if (isParent(user)) {
      body.parentSignature = signature;
      body.parentSignatureDate = moment().format('YYYY-MM-DD HH:mm:ss');
    }

    try {
      const response = await IncidentReports(token || '').update(data?.id, body);
      toast.success('Incident report successfully signed');
      if (incidentSigned) {
        setIsSign(false);
        setSignature('');
        incidentSigned(response?.data || {});
      }
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'Request error');
    }
  };

  return (
    <Modal
      className="modal-dialog-centered modal-lg"
      size="1000px"
      isOpen={isIncidentReportOpen}
      toggle={() => setIsIncidentReportOpen(false)}
    >
      <div className="modal-header pt-4 pb-0">
        <h6 className="modal-title" id="modal-title-notification">
          Accident / Incident Report - {data?.kids?.firstName} {data?.kids?.lastName}
        </h6>
        {showCloseButton && (
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setIsIncidentReportOpen(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        )}
      </div>
      <div className="modal-body px-5 pt-2 pb-0" style={{ maxHeight: '650px', overflowY: 'scroll' }}>
        <div className="py-3">
          <div className="d-flex">
            <div className="d-flex flex-column flex-start align-items-start">
              <h2 className="text-primary">ACCIDENT / INCIDENT REPORT</h2>
              <h4 className="text-primary" style={{ textDecoration: 'underline' }}>
                Facility
              </h4>
              <h4 className="text-primary">{data?.school?.name}</h4>
              <h4 className="text-primary">{data?.school?.fullAddress}</h4>
            </div>
          </div>

          <div className="d-flex align-items-center mt-4">
            <p className="font-weight-bold fs-18 mr-2">First/Last Name:</p>
            <p>{`${data?.kids?.firstName} ${data?.kids?.lastName}`}</p>
          </div>

          <div className="d-flex align-items-center">
            <p className="font-weight-bold fs-18 mr-2 mb-0">Date of Birth:</p>
            <p className="mb-0">{data?.kids?.birthday ? moment(data?.kids?.birthday).format('MM/DD/YYYY') : ''}</p>

            <p className="font-weight-bold fs-18 mr-2 ml-2 mb-0">Age:</p>
            <p className="mb-0">{finalDate?.diff(startDate, 'years')}</p>

            <p className="font-weight-bold fs-18 mr-2 ml-4 mb-0">Gender:</p>
            <div className="form-check form-check-inline">
              <Label className="form-check-label mr-2">Male</Label>
              <Input
                className="form-check-input"
                type="checkbox"
                name="afected"
                checked={data?.kids?.gender === 'BOY'}
                disabled
              />
            </div>
            <div className="form-check form-check-inline mr-3">
              <Label className="form-check-label mr-2">Female</Label>
              <Input
                className="form-check-input"
                type="checkbox"
                name="afected"
                checked={data?.kids?.gender === 'GIRL'}
                disabled
              />
            </div>
            <div className="form-check form-check-inline mr-3">
              <Label className="form-check-label mr-2" htmlFor="staff">
                Unknown
              </Label>
              <Input
                className="form-check-input"
                type="checkbox"
                name="afected"
                checked={data?.kids?.gender === 'UNKNOWN'}
                disabled
              />
            </div>
          </div>

          <div className="d-flex align-items-center mt-4">
            <h3>WHO WAS AFFECTED OR INJURED</h3>
          </div>

          <div className="d-flex align-items-center">
            <div className="form-check form-check-inline mr-3">
              <Label className="form-check-label mr-2" htmlFor="child">
                Child
              </Label>
              <Input
                className="form-check-input"
                type="checkbox"
                name="afected"
                id="child"
                value={'Child'}
                checked={data?.afectedOrInjured === 'Child'}
                disabled
              />
            </div>
            <div className="form-check form-check-inline mr-3">
              <Label className="form-check-label mr-2" htmlFor="parent">
                Parent
              </Label>
              <Input
                className="form-check-input"
                type="checkbox"
                name="afected"
                id="parent"
                value={'Parent'}
                checked={data?.afectedOrInjured === 'Parent'}
                disabled
              />
            </div>
            <div className="form-check form-check-inline mr-3">
              <Label className="form-check-label mr-2" htmlFor="staff">
                Staff
              </Label>
              <Input
                className="form-check-input"
                type="checkbox"
                name="afected"
                id="staff"
                value={'Staff'}
                checked={data?.afectedOrInjured === 'Staff'}
                disabled
              />
            </div>
            <div className="form-check form-check-inline mr-3">
              <Label className="form-check-label mr-2" htmlFor="visitor">
                Visitor
              </Label>
              <Input
                className="form-check-input"
                type="checkbox"
                name="afected"
                id="visitor"
                value={'Visitor'}
                checked={data?.afectedOrInjured === 'Visitor'}
                disabled
              />
            </div>
          </div>

          <div className="d-flex align-items-center mt-4">
            <h3>INCIDENT DESCRIPTION</h3>
          </div>

          <div className="d-flex align-items-center">
            <p className="font-weight-bold fs-18 mr-2 mb-0">Type of Incident:</p>
            <div className="form-check form-check-inline mr-2">
              <Label className="form-check-label mr-2">Accident/Injury</Label>
              <Input
                className="form-check-input"
                type="checkbox"
                name="type"
                checked={data?.type === 'Accident/Injury'}
                disabled
              />
            </div>
            <div className="form-check form-check-inline mr-3">
              <Label className="form-check-label mr-2">Illness</Label>
              <Input
                className="form-check-input"
                type="checkbox"
                name="type"
                checked={data?.type === 'Illness'}
                disabled
              />
            </div>
            <div className="form-check form-check-inline mr-3">
              <Label className="form-check-label mr-2">Observation/Incident</Label>
              <Input
                className="form-check-input"
                type="checkbox"
                name="type"
                checked={data?.type === 'Observation/Incident'}
                disabled
              />
            </div>
          </div>

          <div className="d-flex align-items-center mt-4">
            <p className="font-weight-bold fs-18 mr-2 mb-0">Date of Accident/Incident:</p>
            <p className="mb-0 mr-2">{data?.acidentDate ? moment(data?.acidentDate).format('MM/DD/YYYY') : ''}</p>
            <p className="font-weight-bold fs-18 mr-2 mb-0">Time of Accident/Incident:</p>
            <p className="mb-0 mr-2">{data?.acidentTime ? moment(data?.acidentTime).format('h:mm') : ''}</p>
            <div className="form-check form-check-inline mr-2">
              <Label className="form-check-label mr-1">AM</Label>
              <Input
                className="form-check-input"
                type="checkbox"
                name="type"
                checked={data?.acidentTime && moment(data?.acidentTime).format('A') === 'AM'}
                disabled
              />
            </div>
            <div className="form-check form-check-inline mr-2">
              <Label className="form-check-label mr-1">PM</Label>
              <Input
                className="form-check-input"
                type="checkbox"
                name="type"
                checked={data?.acidentTime && moment(data?.acidentTime).format('A') === 'PM'}
                disabled
              />
            </div>
          </div>

          <div className="d-flex align-items-center mt-4">
            <p className="font-weight-bold fs-18 mr-2 mb-0">Location of Accident/Incident:</p>
            <div className="form-check form-check-inline mr-3">
              <Label className="form-check-label mr-2">Classroom</Label>
              <Input
                className="form-check-input"
                type="checkbox"
                name="location"
                checked={data?.location === 'Classroom'}
                disabled
              />
            </div>
            <div className="form-check form-check-inline mr-3">
              <Label className="form-check-label mr-2">Playground</Label>
              <Input
                className="form-check-input"
                type="checkbox"
                name="location"
                checked={data?.location === 'Playground'}
                disabled
              />
            </div>
            <div className="form-check form-check-inline mr-3">
              <Label className="form-check-label mr-2">Bathroom</Label>
              <Input
                className="form-check-input"
                type="checkbox"
                name="location"
                checked={data?.location === 'Bathroom'}
                disabled
              />
            </div>
            <div className="form-check form-check-inline mr-3">
              <Label className="form-check-label mr-2">Other</Label>
              <Input
                className="form-check-input"
                type="checkbox"
                name="location"
                checked={data?.location === 'Other'}
                disabled
              />
            </div>
          </div>

          <div className="d-flex align-items-center mt-4">
            <p className="font-weight-bold fs-18 mr-2 mb-0">Describe Accident/Incident:</p>
            <p className="mb-0">{data?.incidentDescription}</p>
          </div>

          <div className="d-flex align-items-center mt-4">
            <p className="font-weight-bold fs-18 mr-2 mb-0">Describe Nature of Injury/Illness:</p>
            <p className="mb-0">{data?.injuryNatureDescription}</p>
          </div>

          <div className="d-flex align-items-center mt-4">
            <p className="font-weight-bold fs-18 mr-2 mb-0">Describe Medical Treatment/First Aid:</p>
            <p className="mb-0">{data?.medicalTreatmentDescription}</p>
          </div>

          <div className="d-flex align-items-center mt-4">
            <p className="font-weight-bold fs-18 mr-2 mb-0">Ambulance Called:</p>
            <div className="form-check form-check-inline mr-3">
              <Label className="form-check-label mr-2">Yes</Label>
              <Input className="form-check-input" type="checkbox" checked={data?.ambulanceCalled === true} disabled />
            </div>
            <div className="form-check form-check-inline mr-3">
              <Label className="form-check-label mr-2">No</Label>
              <Input className="form-check-input" type="checkbox" checked={data?.ambulanceCalled === false} disabled />
            </div>
          </div>

          <div className="d-flex align-items-center mt-4">
            <p className="font-weight-bold fs-18 mr-2 mb-0">Witness(es) to Accident/Incident:</p>
            <p className="mb-0">{data?.witness}</p>
          </div>

          <div className="d-flex align-items-center mt-4">
            <p className="font-weight-bold fs-18 mr-2 mb-0">What Action Was Taken & by Whom?</p>
            <p className="mb-0">{data?.action}</p>
          </div>

          <hr />

          <div className="d-flex align-items-center mt-4">
            <h3>NOTIFICATIONS & SIGNATURES</h3>
          </div>

          <div className="d-flex align-items-center mt-4">
            <p className="font-weight-bold fs-18 mr-2 mb-0">Was Parent/Guardian Contacted?</p>
            <div className="form-check form-check-inline mr-3">
              <Label className="form-check-label mr-2">Yes</Label>
              <Input className="form-check-input" type="checkbox" checked={data?.parentContacted === true} disabled />
            </div>
            <div className="form-check form-check-inline mr-3">
              <Label className="form-check-label mr-2">No</Label>
              <Input className="form-check-input" type="checkbox" checked={data?.parentContacted === false} disabled />
            </div>
            <div className="form-check form-check-inline mr-3">
              <Label className="form-check-label mr-2">N/A</Label>
              <Input className="form-check-input" type="checkbox" checked={data?.parentContacted === null} disabled />
            </div>
            <p className="font-weight-bold fs-18 mr-2 mb-0">Time?</p>
            <p className="mb-0 mr-2">
              {data?.parentContactedTime ? moment(data?.parentContactedTime).format('h:mm') : '-'}
            </p>
            <div className="form-check form-check-inline mr-2">
              <Label className="form-check-label mr-1">AM</Label>
              <Input
                className="form-check-input"
                type="checkbox"
                name="type"
                checked={data?.parentContactedTime && moment(data?.parentContactedTime).format('A') === 'AM'}
                disabled
              />
            </div>
            <div className="form-check form-check-inline mr-2">
              <Label className="form-check-label mr-1">PM</Label>
              <Input
                className="form-check-input"
                type="checkbox"
                name="type"
                checked={data?.parentContactedTime && moment(data?.parentContactedTime).format('A') === 'PM'}
                disabled
              />
            </div>
          </div>

          <div className="d-flex align-items-center mt-4">
            <p className="font-weight-bold fs-18 mr-2 mb-0">How? Via:</p>
            <div className="form-check form-check-inline mr-3">
              <Label className="form-check-label mr-2">Phone</Label>
              <Input className="form-check-input" type="checkbox" checked={data?.contactedVia === 'Phone'} disabled />
            </div>
            <div className="form-check form-check-inline mr-3">
              <Label className="form-check-label mr-2">Email</Label>
              <Input className="form-check-input" type="checkbox" checked={data?.contactedVia === 'Email'} disabled />
            </div>
            <div className="form-check form-check-inline mr-3">
              <Label className="form-check-label mr-2">In person</Label>
              <Input
                className="form-check-input"
                type="checkbox"
                checked={data?.contactedVia === 'In person'}
                disabled
              />
            </div>
          </div>
          {!isParent(user) && (
            <>
              <div className="d-flex align-items-center mt-4">
                <p className="font-weight-bold fs-18 mr-2 mb-0">Other Persons Contacted:</p>
                <p className="mb-0">{data?.otherPersonsContacted || ''}</p>
              </div>

              <div className="d-flex align-items-center mt-4">
                <div className="form-check form-check-inline mr-3">
                  <Input className="form-check-input" type="checkbox" id="to-sign" checked={true} disabled />
                  <Label className="form-check-label mr-2" htmlFor="to-sign">
                    I hereby acknowledge and agree that my electronic signature on this document is the legal equivalent
                    of my handwritten signature.
                  </Label>
                </div>
              </div>

              <div className="d-flex align-items-center justify-content-between mt-1">
                <h4 className="mb-0">Signature of Staff Completing Form</h4>
                <h4 className="mb-0">Date/Time</h4>
              </div>

              <div className="d-flex align-items-center justify-content-between mt-1">
                <p className="mb-0" style={{ fontFamily: 'Dancing Script' }}>
                  {data?.teacherSignature || ''}
                </p>

                <p className="mb-0">
                  {data?.teacherSignatureDate ? moment(data?.teacherSignatureDate).format('MM/DD/YYYY') : ''}
                </p>
              </div>

              <div className="d-flex align-items-center mt-4">
                <div className="form-check form-check-inline mr-3">
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    id="to-sign"
                    checked={data?.parentSignature !== null}
                    disabled
                  />
                  <Label className="form-check-label mr-2" htmlFor="to-sign">
                    I hereby acknowledge and agree that my electronic signature on this document is the legal equivalent
                    of my handwritten signature.
                  </Label>
                </div>
              </div>

              <div className="d-flex align-items-center justify-content-between mt-1">
                <h4 className="mb-0">Signature of Parent/Guardian/Authorized Pickup Person</h4>
                <h4 className="mb-0">Date/Time</h4>
              </div>

              <div className="d-flex align-items-center justify-content-between mt-1">
                <p className="mb-0" style={{ fontFamily: 'Dancing Script' }}>
                  {data?.parentSignature}
                </p>

                <p className="mb-0">
                  {data?.parentSignatureDate ? moment(data?.parentSignatureDate).format('MM/DD/YYYY') : ''}
                </p>
              </div>

              <div className="d-flex align-items-center mt-4">
                <div className="form-check form-check-inline mr-3">
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    id="to-sign"
                    checked={!!data?.directorSignatureDate}
                    disabled
                  />
                  <Label className="form-check-label mr-2" htmlFor="to-sign">
                    I hereby acknowledge and agree that my electronic signature on this document is the legal equivalent
                    of my handwritten signature.
                  </Label>
                </div>
              </div>

              <div className="d-flex align-items-center justify-content-between mt-1">
                <h4 className="mb-0">Signature of Director/Person in Charge</h4>
                <h4 className="mb-0">Date/Time</h4>
              </div>

              <div className="d-flex align-items-center justify-content-between mt-1">
                <p className="mb-0" style={{ fontFamily: 'Dancing Script' }}>
                  {data?.directorSignature}
                </p>

                <p className="mb-0">
                  {data?.directorSignatureDate ? moment(data?.directorSignatureDate).format('MM/DD/YYYY') : ''}
                </p>
              </div>
            </>
          )}

          <div className="d-flex align-items-center justify-content-between mt-4">
            <p className="mb-0 font-weight-normal">
              *In the event of a serious injury, the incident must immediately be reported to the local Education
              Department and 911.
            </p>
          </div>
        </div>
      </div>
      <div className="modal-footer px-5" style={{ marginTop: '0', paddingTop: '0' }}>
        {/* <Button className="text-white ml-auto" color="link" data-dismiss="modal" type="button" onClick={() => {}}>
          Close
        </Button> */}
        <form onSubmit={(e: any) => handleSubmit(e)}>
          <div className="py-3">
            <Col lg="12" className="mt-1">
              <Label>
                <strong></strong>
              </Label>
              <div className="d-flex flex-wrap align-items-center">
                <div className="form-check form-check-inline mr-3">
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    id="date-sign"
                    checked={isSign}
                    onChange={() => {
                      setIsSign(!isSign);
                    }}
                  />
                  <Label className="form-check-label mr-2" htmlFor="date-sign">
                    I hereby acknowledge and agree that my electronic signature on this document is the legal equivalent
                    of my handwritten signature.
                  </Label>
                </div>
              </div>
            </Col>

            {isSign && (
              <>
                <Row>
                  <Col lg="12" className="mt-2">
                    <FormGroup style={{ marginBottom: '0' }}>
                      <Label htmlFor="name-for-signature">
                        <strong>Enter your name for signature:</strong>
                      </Label>
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Recipient's username"
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                          onChange={(e) => setSignature(e?.target?.value || '')}
                          required
                          style={{ fontFamily: 'Dancing Script' }}
                        />
                        <div className="input-group-append">
                          <button className="btn btn-primary" type="submit" disabled={!isSign}>
                            Sign
                          </button>
                        </div>
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              </>
            )}
            <div className="d-flex justify-content-end"></div>
          </div>
        </form>
      </div>
    </Modal>
  );
};
export default ViewIncidentReportModal;
