import { useEffect, useState } from 'react';
import { Modal, Table } from 'reactstrap';
import ClassDetailedKidsModal from './ClassDetailedKidsModal';
import { Link } from 'react-router-dom';

type ClassDetailedEnrolledModalProps = {
  isOpen: boolean;
  className: string;
  kids?: any[];
  toggleModal: (id: string) => void;
};

const ClassDetailedEnrolledModal = ({ isOpen, className, kids, toggleModal }: ClassDetailedEnrolledModalProps) => {
  const [kidsByClassType, setKidsByClassType] = useState([] as any[]);
  const [isDetailedModal, setIsDetailedModal] = useState(false);
  const [currentKids, setCurrentKids] = useState([] as any[]);

  useEffect(() => {
    const kidsGroup: any[] = [];
    for (const k of kids || []) {
      if (!k?.enrollmentClassType) continue;
      const findGroup = kidsGroup.find((g: any) => g.classType === k?.enrollmentClassType?.name);
      if (findGroup) {
        findGroup.count = findGroup.count + 1;
        continue;
      }
      kidsGroup.push({ classType: k?.enrollmentClassType?.name, count: 1 });
    }
    setKidsByClassType(kidsGroup);
  }, [kids]);

  const setCurrentByClassType = (classType: string) => {
    const kidsList = kids?.filter((k: any) => classType === k?.enrollmentClassType?.name);
    setCurrentKids(kidsList?.length ? kidsList : []);
    setIsDetailedModal(true);
  };

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        isOpen={isOpen}
        toggle={(e: any) => {
          e.preventDefault();
          toggleModal('');
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title">Enrolled {`${className ? `- ${className}` : ''}`}</h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={(e) => {
              e.preventDefault();
              toggleModal('');
            }}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body pt-0">
          <Table striped bordered responsive>
            <tbody>
              {kidsByClassType?.length ? (
                <>
                  {kidsByClassType.map((k: any, index: number) => (
                    <tr key={`kids-by-class-type-${index}`}>
                      <th>{k.classType}</th>
                      <td>
                        <Link
                          to="#"
                          onClick={(e) => {
                            e.preventDefault();
                            setCurrentByClassType(k.classType);
                          }}
                        >
                          <strong>{k.count}</strong>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <tr>
                  <td>Data not found</td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </Modal>
      <ClassDetailedKidsModal
        isOpen={isDetailedModal}
        className={`Enrolled - ${className}`}
        kidsList={currentKids}
        loading={false}
        toggleModal={() => setIsDetailedModal(!isDetailedModal)}
      />
    </>
  );
};

export default ClassDetailedEnrolledModal;
