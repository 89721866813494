import { Row, Col, Card } from 'reactstrap';

const ClassCheckOutSuccessPage = ({ className, user }: { className: string; user: any }) => {
  return (
    <>
      <Row>
        <Col md="12">
          <Card style={{ textAlign: 'center' }}>
            <div className="card-success" style={{ textAlign: 'center' }}>
              <div style={{ textAlign: 'center' }} className="mt-3 mb-2">
                <img
                  className="rounded-lg shadow"
                  alt={`${user?.firstName} ${user?.lastName}`}
                  src={user?.picture ? `${user?.picture}` : require('../../../assets/img/user-default.png').default}
                  style={{ maxWidth: '100px' }}
                />
              </div>
              <h2>{`${user?.firstName} ${user?.lastName}`}</h2>
              <p>
                has been <strong>checked out</strong> to <strong>{className}</strong>.
              </p>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ClassCheckOutSuccessPage;
