/* eslint-disable max-lines */
import {
  Card,
  Container,
  Row,
  Col,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import ReactDatetime from 'react-datetime';
import moment, { Moment } from 'moment';

type Props = {
  index: number;
  classTypeList: any[];
  firstName: string;
  lastName: string;
  birthday: string;
  startDate: string;
  classType: string;
  setFirstName: (index: number, value: string) => void;
  setLastName: (index: number, value: string) => void;
  setBirthday: (index: number, value: string) => void;
  setClassType: (index: number, value: string) => void;
  setStartDate: (index: number, value: string) => void;
};

const ChildrenForm = ({
  index,
  classTypeList,
  firstName,
  lastName,
  birthday,
  startDate,
  classType,
  setFirstName,
  setLastName,
  setBirthday,
  setStartDate,
  setClassType,
}: Props) => {
  const validStartDate = (current: Moment) => {
    const today = moment();
    return current.isAfter(today);
  };

  const handleSubmitForm = async (e: React.FormEvent) => {
    e.preventDefault();
  };

  return (
    <>
      <Container fluid>
        <Form onSubmit={handleSubmitForm}>
          <Card>
            <CardBody style={{ padding: '1rem', paddingBottom: 0 }}>
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-firstName">
                      First Name *
                    </label>
                    <Input
                      className="form-control-alternative"
                      id="input-firstName"
                      placeholder="First Name"
                      type="text"
                      value={firstName}
                      onChange={(e) => setFirstName(index, e.target.value)}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-lastName">
                      Last Name *
                    </label>
                    <Input
                      className="form-control-alternative"
                      id="input-lastName"
                      placeholder="Last Name"
                      type="text"
                      value={lastName}
                      onChange={(e) => setLastName(index, e.target.value)}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col lg="12">
                  <FormGroup>
                    <label className="form-control-label">Birthday</label>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-calendar-grid-58" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <ReactDatetime
                        inputProps={{
                          placeholder: 'Birthday',
                          required: true,
                        }}
                        onChange={(newValue) => {
                          setBirthday(index, `${newValue}`);
                        }}
                        timeFormat={false}
                        value={`${birthday}`}
                        dateFormat={'MM/DD/yyyy'}
                        closeOnSelect={true}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col lg="12">
                  <FormGroup>
                    <label className="form-control-label">When would you like to start?</label>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-calendar-grid-58" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <ReactDatetime
                        inputProps={{
                          placeholder: 'Start Date',
                        }}
                        onChange={(newValue) => {
                          setStartDate(index, `${newValue}`);
                        }}
                        timeFormat={false}
                        value={`${startDate}`}
                        dateFormat={'MM/DD/yyyy'}
                        isValidDate={validStartDate}
                        closeOnSelect={true}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col lg="12">
                  <FormGroup className="mb-1">
                    <label className="form-control-label">Class Type</label>
                    <Input
                      type="select"
                      defaultValue={classType || ''}
                      onChange={(e) => setClassType(index, e.target.value)}
                      required
                    >
                      <option value=""></option>
                      {classTypeList.map((a: any, index: number) => {
                        return (
                          <option value={a.id} key={`class-type-${index}`}>
                            {a.name}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Form>
      </Container>
    </>
  );
};

export default ChildrenForm;
