import http from '../helpers/http';

const ExpirationDatesTypes = (token: string) => {
  const headers = {
    'x-access-token': token,
  };

  return {
    list: async () => {
      const result = await http.get(`/expiration-dates-types`, {
        headers,
      });
      return result.data;
    },
    create: async (body: { name: string }) => {
      const result = await http.post(`/expiration-dates-types`, body, { headers });
      return result.data;
    },
    delete: async (id: string) => {
      const result = await http.delete(`/expiration-dates-types/${id}`, {
        headers,
      });
      return result.data;
    },
  };
};

export default ExpirationDatesTypes;
