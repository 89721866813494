import { Card, Button, Container, Row, Col, CardBody, FormGroup, Form, Input, CardHeader, Alert } from 'reactstrap';
import { useContext, useState, useEffect } from 'react';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import { toast } from 'react-toastify';
import TimePicker from '../../../components/Core/TimezonePicker';
import KidsActivity from '../../../services/KidsActivity';
import Upload from '../../../components/Core/Upload';
import isAdmin from '../../../helpers/isAdmin';
import isDirector from '../../../helpers/isDirector';
import ClassKidsActivityList from './ClassKidsActivityList';

type ClassActivityProps = {
  kidsIds: string[];
  tag: string;
  isNotPhotoPermissionKids?: any[];
  onCloseModal: (clear?: boolean) => void;
};

const ClassActivityForm = ({ kidsIds, tag, isNotPhotoPermissionKids, onCloseModal }: ClassActivityProps) => {
  const { token, user }: any = useContext(AuthContext);
  const hasSettingsPermission = isAdmin(user) || isDirector(user);
  const [loading, setLoading] = useState(false);
  const [activities, setActivities] = useState([]);
  const [activity, setActivity] = useState('');
  const [notes, setNotes] = useState('');
  const [time, setTime] = useState('');
  const [pictures, setPictures] = useState([] as any[]);
  const [pictureWarning, setPictureWarning] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  useEffect(() => {
    if (!kidsIds.length) {
      toast.warn('Please select the students.');
      onCloseModal();
      return;
    }
    listSelectActivities();
  }, []);

  useEffect(() => {
    if (pictures?.length && isNotPhotoPermissionKids?.length) {
      const kids = isNotPhotoPermissionKids.map((k) => `${k.firstName} ${k.lastName}`);
      setPictureWarning(`Students who are not allowed to upload photos: ${kids.join(', ')}`);
      setDisabled(true);
      return;
    }
    setPictureWarning('');
    setDisabled(false);
  }, [pictures]);

  const closeModal = () => {
    onCloseModal();
  };

  const listSelectActivities = async () => {
    try {
      const result = await KidsActivity(token).listSelect();
      const data = result.data || [];
      setActivities(data.filter((a: any) => a?.tags?.includes('activity')));
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No activities found');
    }
  };

  const onChangeFile = (files: any[]) => {
    setPictures(files);
  };

  const handleSubmitForm = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);

    try {
      const formData = new FormData();
      if (pictures?.length) {
        for (let i = 0; i < pictures.length; i++) {
          formData.append('pictures', pictures[i]);
        }
      }
      for (const k of kidsIds) {
        const result = await KidsActivity(token).send(k, activity, notes, time, {});
        if (pictures?.length && result?.data?.id) {
          await KidsActivity(token).pictures(result?.data?.id, formData);
        }
      }
      toast.success(`Saved successfully!`);
      onCloseModal(true);
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Create error!');
      toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  const toggleActivitySettings = (e: any) => {
    e.preventDefault();
    listSelectActivities();
    setIsSettingsOpen(!isSettingsOpen);
  };

  return (
    <>
      <Container fluid>
        <div className="text-right mb-3">
          {hasSettingsPermission && (
            <i className="fas fa-cog fa-lg" style={{ cursor: 'pointer' }} onClick={toggleActivitySettings}></i>
          )}
        </div>
        <Form onSubmit={handleSubmitForm}>
          <Card>
            {pictureWarning && (
              <CardHeader>
                <Alert color="warning">{pictureWarning}</Alert>
              </CardHeader>
            )}
            <CardBody>
              <Row>
                <Col lg="12">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-franchisee-id">
                      Select an Activity
                    </label>
                    <Input type="select" onChange={(e) => setActivity(e.target.value)} required>
                      <option value=""></option>
                      {activities
                        ?.filter((a: any) => a?.tags?.includes(tag))
                        .map((a: any, index: number) => {
                          return (
                            <option value={a.id} key={`activity-${index}`}>
                              {a.name}
                            </option>
                          );
                        })}
                    </Input>
                  </FormGroup>
                </Col>
                <Col lg="12">
                  <FormGroup>
                    <label className="form-control-label">Insert a Note</label>
                    <Input type="textarea" onChange={(e) => setNotes(e.target.value)} />
                  </FormGroup>
                </Col>
                <Col lg="12">
                  <FormGroup>
                    <label className="form-control-label">Time</label>
                    <TimePicker
                      onChangeTime={(time: string) => {
                        setTime(time);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col lg="12">
                  <Upload accept=".png,.jpg,.jpeg" multiple={true} onChangeFiles={onChangeFile} />
                </Col>
              </Row>
              <Row className="mt-5">
                <Col md="6 text-center">
                  <Button
                    color="primary"
                    type="submit"
                    size="lg"
                    style={{ minWidth: '100px' }}
                    disabled={disabled || loading}
                  >
                    {loading && (
                      <>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        {'  Save'}
                      </>
                    )}
                    {!loading && <>Save</>}
                  </Button>
                </Col>
                <Col md="6 text-center">
                  <Button
                    color="secondary"
                    size="lg"
                    style={{ minWidth: '100px' }}
                    onClick={closeModal}
                    disabled={loading}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Form>
      </Container>
      <ClassKidsActivityList
        isOpen={isSettingsOpen}
        settingsList={activities}
        activityType={'activity'}
        onListUpdate={listSelectActivities}
        toggleModal={toggleActivitySettings}
      />
    </>
  );
};

export default ClassActivityForm;
