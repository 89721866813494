/* eslint-disable max-lines */
import { useContext, useState, useEffect } from 'react';
import { Context as AuthContext } from '../../contexts/AuthContext';
import { Form, FormGroup, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import SearchBarService from '../../services/SearchBar';
import history from '../../history';
import AsyncSelect from 'react-select/async';

const SearchNavbar = () => {
  const { token, currentSchool }: any = useContext(AuthContext);

  const asyncSelectStyles = {
    menu: (styles: any) => ({
      ...styles,
      ...{
        width: 'max-content',
        minWidth: 400,
        marginLeft: window.innerWidth < 830 ? -90 : 0,
      },
    }),
    container: (styles: any) => ({
      ...styles,
      ...(window.innerWidth < 830 ? { width: '80%' } : {}),
    }),
    control: (styles: any) => ({
      ...styles,
      ...{ border: 0, boxShadow: 'none', backgroundColor: 'transparent', color: '#8898aa' },
    }),
    input: (styles: any) => ({
      ...styles,
      ...{ border: 0, width: '550px', backgroundColor: 'transparent', color: '#8898aa' },
    }),
    placeholder: (styles: any) => ({
      ...styles,
      ...{
        fontSize: '0.810rem',
        fontWeight: 400,
        lineHeight: 1.5,
        color: '#8898aa',
        textOverflow: 'clip',
      },
    }),
    valueContainer: (styles: any) => ({
      ...styles,
      ...{ backgroundColor: 'transparent' },
    }),
    singleValue: (styles: any) => ({
      ...styles,
      ...{
        fontSize: '0.810rem',
        fontWeight: 400,
        lineHeight: 1.5,
        color: '#8898aa',
        textOverflow: 'clip',
      },
    }),
    indicatorsContainer: (styles: any) => ({
      ...styles,
      ...{ color: '#8898aa' },
    }),
  };

  // Async Select
  const [selected, setSelected] = useState<any>(undefined);
  const loadOptions = async (query?: string) => {
    try {
      const options = [
        {
          label: 'Children',
          options: [],
        },
        {
          label: 'Staff',
          options: [],
        },
        {
          label: 'Parent',
          options: [],
        },
      ];
      if (!query) return options;
      try {
        const result = await SearchBarService(token).searchBySchool(currentSchool.value, query);
        if (result?.data?.staff?.length) {
          const staff = result?.data?.staff?.map((s: any) => ({
            name: `${s?.firstName} ${s?.lastName}`,
            value: `/users/${s?.id || ''}/profile`,
            photo: s?.picture || '',
            subtitle: '',
          }));
          for (const o of options) {
            if (o.label === 'Staff') {
              o.options = staff || [];
            }
          }
        }
        if (result?.data?.parent?.length) {
          const parent = result?.data?.parent?.map((s: any) => ({
            name: `${s?.firstName} ${s?.lastName}`,
            value: `/users/${s?.id || ''}/profile`,
            photo: s?.picture || '',
            subtitle: '',
          }));
          for (const o of options) {
            if (o.label === 'Parent') {
              o.options = parent || [];
            }
          }
        }
        if (result?.data?.kids?.length) {
          const kids = result?.data?.kids?.map((s: any) => ({
            name: `${s?.firstName} ${s?.lastName}`,
            value: `/student-profile/${s?.id || ''}`,
            photo: s?.picture || '',
            subtitle: '',
          }));
          for (const o of options) {
            if (o.label === 'Children') {
              o.options = kids || [];
            }
          }
        }
      } catch (e: any) {
        console.error(e?.response?.data?.message || 'Error on search');
      }

      return options;
    } catch (e: any) {
      return [];
    }
  };

  const renderResult = (option: any) => {
    const imgStyle = {
      borderRadius: '50%',
      verticalAlign: 'middle',
      marginRight: 10,
    };
    return (
      <div className="select-option" style={{ width: '100%' }}>
        <button type="button" style={{ border: 0, width: '100%', background: 'transparent' }}>
          <div style={{ width: '50%', textAlign: 'left', display: 'inline-block' }}>
            <span>
              <img alt="" style={imgStyle} width="28" height="28" src={option.photo} />
              <span>{option.name}</span>
            </span>
          </div>
          <div style={{ width: '50%', textAlign: 'right', display: 'inline-block' }}>
            <span className="mb-1">{option.subtitle || ''}</span>
          </div>
        </button>
      </div>
    );
  };

  const onChangeSearchBar = (option: any) => {
    setSelected(option);
  };

  useEffect(() => {
    if (selected?.value) {
      setSelected(null);
      history.push(selected?.value);
    }
  }, [selected]);

  return (
    <>
      <Form
        className="navbar-search navbar-search-dark form-inline d-md-flex ml-lg"
        onSubmit={(e) => e.preventDefault()}
      >
        <FormGroup className="mb-0" style={{ width: '100%' }}>
          <InputGroup className="input-group-alternative" style={{ height: 42 }}>
            <InputGroupAddon addonType="prepend">
              <InputGroupText style={{ paddingTop: 10 }}>
                <i className="fas fa-search" style={{ color: '#8898aa' }} />
              </InputGroupText>
            </InputGroupAddon>
            <AsyncSelect
              // cacheOptions
              defaultOptions
              loadOptions={loadOptions}
              formatOptionLabel={renderResult}
              styles={asyncSelectStyles}
              placeholder="Search child or staff"
              value={selected}
              onChange={onChangeSearchBar}
            />
          </InputGroup>
        </FormGroup>
      </Form>
    </>
  );
};

export default SearchNavbar;
