/* eslint-disable max-lines */
import {
  Card,
  Button,
  Container,
  Row,
  Col,
  CardBody,
  FormGroup,
  Form,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  CardTitle,
} from 'reactstrap';
import { useContext, useState, useEffect } from 'react';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import { toast } from 'react-toastify';
import ScheduleBlockdaySettings from '../../../services/ScheduleBlockdaySettings';
import history from '../../../history';
import { toLocaleFormatSlash } from '../../../helpers/dateFormat';
import ReactDatetime from 'react-datetime';
import { confirmAlert } from 'react-confirm-alert';
import TimePicker from '../../../components/Core/TimePicker';
import moment from 'moment';

type scheduleProps = {
  scheduleId: string;
  onModalStatus: () => void;
  dateClick: string;
  onCreateSchedule: () => void;
};

const ScheduleBlockDaysForm = ({ scheduleId, onModalStatus, dateClick, onCreateSchedule }: scheduleProps) => {
  const { token, currentSchool }: any = useContext(AuthContext);
  const [isDisabled, setIsDisabled] = useState(false);
  const [dateBlockPlugin, setDateTourPlugin] = useState(dateClick);
  const [loading, setLoading] = useState(false);
  const [schoolId, setSchoolId] = useState(currentSchool.value);
  const [dateBlock, setDateTour] = useState('');
  const [timeStartBlock, setTimeTour] = useState<string | undefined>(undefined);
  const [typeBlock, setTypeBlock] = useState('');

  const changeonModalStatus = () => {
    onModalStatus();
  };

  useEffect(() => {
    setSchoolId(currentSchool.value);
  }, [currentSchool]);

  useEffect(() => {
    showEvent();
  }, []);

  const showEvent = async () => {
    try {
      if (scheduleId) {
        // const result = await ScheduleService(token).show(scheduleId);
        const result = await ScheduleBlockdaySettings(token).show(scheduleId);
        if (result?.data) {
          setDateTourPlugin(result?.data?.dateBlock || '');
          setDateTour(result?.data?.dateBlock ? toLocaleFormatSlash(result?.data?.dateBlock) : '');
          setTimeTour(
            result?.data?.timeStartBlock ? `${result?.data?.dateBlock} ${result?.data?.timeStartBlock}` : undefined,
          );
          setTypeBlock(result?.data?.typeBlock || '');
          setSchoolId(result?.data?.schoolId || currentSchool.value);
        }
      }
    } catch (e: any) {
      console.error(e?.response?.data?.message || 'No config found');
    }
  };

  const handleSubmitForm = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);
    setIsDisabled(true);

    const timeStartBlockMoment = moment(timeStartBlock);
    const body: any = {
      schoolId,
      dateBlock: dateBlock ? toLocaleFormatSlash(dateBlock) : dateClick || '',
      timeStartBlock: typeBlock !== 'entire_day' ? timeStartBlockMoment.format('HH:mm:ss') : '00:00:00',
      typeBlock,
    };
    if (scheduleId) body.id = scheduleId;
    try {
      const result = await ScheduleBlockdaySettings(token).createOrUpdate(body);
      toast.success(`Updated successfully!`);
      if (result?.data?.id) {
        onModalStatus();
        onCreateSchedule();
      }
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Create error!');
      toast.error(message);
    }
    setLoading(false);
    setIsDisabled(false);
  };

  const deleteItemAlert = async (id: string, title: string) => {
    changeonModalStatus();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Container fluid>
            <Card>
              <CardBody>
                <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                  Delete
                </CardTitle>
                <Row className="mt-3">
                  <Col md="12 text-center">
                    <span className="h2 font-weight-bold mb-0">{`Are you sure to delete item: ${title}?`}</span>
                  </Col>
                </Row>
                <Row className="mt-5">
                  <Col md="6 text-center">
                    <Button
                      color="primary"
                      href="#"
                      onClick={() => {
                        deleteItem(id);
                        onClose();
                      }}
                      size="lg"
                      style={{ minWidth: '100px' }}
                    >
                      Yes
                    </Button>
                  </Col>
                  <Col md="6 text-center">
                    <Button color="danger" href="#" onClick={onClose} size="lg" style={{ minWidth: '100px' }}>
                      No
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Container>
        );
      },
    });
  };

  const deleteItem = async (id: string) => {
    try {
      await ScheduleBlockdaySettings(token || '').delete(id);
      toast.success(`Deleted successfully!`);
      onCreateSchedule();
      history.push(`/`);
    } catch (e: any) {
      const message = String(e?.response?.data?.message || `Error deleting item: ${id}`);
      return toast.error(message);
    }
  };

  return (
    <>
      <Container fluid>
        <Form onSubmit={handleSubmitForm}>
          <Card>
            <CardBody>
              <Row>
                <Col lg="12">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-dateBlock">
                      Tour Date
                    </label>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-calendar-grid-58" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <ReactDatetime
                        inputProps={{
                          placeholder: 'Tour Date',
                        }}
                        onChange={(newValue) => {
                          setDateTourPlugin(`${newValue}`);
                          setDateTour(`${newValue}`);
                        }}
                        timeFormat={false}
                        dateFormat={'MM/DD/yyyy'}
                        closeOnSelect={true}
                        value={dateBlockPlugin ? toLocaleFormatSlash(dateBlockPlugin) : dateBlock}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col lg="12">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-typeBlock">
                      Type Block
                    </label>

                    <select
                      name="input-typeBlock"
                      id="input-typeBlock"
                      className="form-control"
                      disabled={isDisabled}
                      value={typeBlock}
                      onChange={(e) => setTypeBlock(e.target.value)}
                      required
                    >
                      <option value="">Select</option>
                      <option value="30">30 Minutes</option>
                      <option value="60">60 Minutes</option>
                      <option value="90">90 Minutes</option>
                      <option value="120">120 Minutes</option>
                      <option value="entire_day">Entire Day</option>
                    </select>
                  </FormGroup>
                </Col>
                {typeBlock !== 'entire_day' ? (
                  <Col lg="12">
                    <FormGroup>
                      <label className="form-control-label"> Time Start Block </label>
                      <TimePicker
                        defaultValue={timeStartBlock}
                        onChangeTime={(timeStartBlock: string) => {
                          setTimeTour(timeStartBlock);
                        }}
                      />
                    </FormGroup>
                  </Col>
                ) : (
                  <></>
                )}
              </Row>

              <Row className="mt-5">
                <Col md="4 text-center">
                  <Button color="primary" type="submit" size="lg" style={{ minWidth: '100px' }}>
                    Save
                  </Button>
                </Col>
                <Col md="4 text-center">
                  {scheduleId ? (
                    <Button
                      onClick={() => {
                        deleteItemAlert(scheduleId, scheduleId);
                      }}
                      color="danger"
                      className="float-right"
                      size="lg"
                      style={{ minWidth: '100px' }}
                    >
                      Delete
                    </Button>
                  ) : (
                    ''
                  )}
                </Col>
                <Col md="4 text-center">
                  <Button color="secondary" size="lg" style={{ minWidth: '100px' }} onClick={changeonModalStatus}>
                    Cancel
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Form>
      </Container>
    </>
  );
};

export default ScheduleBlockDaysForm;
