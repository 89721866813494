export const validStatus = {
  NEW: { value: 'NEW', color: 'info' },
  ACTIVE: { value: 'ACTIVE', color: 'success' },
  BLOCKED: { value: 'BLOCKED', color: 'danger' },
  PENDING_ACCEPTANCE: { value: 'PENDING', color: 'danger' },
};

export enum statusEnum {
  NEW = 'NEW',
  ACTIVE = 'ACTIVE',
  BLOCKED = 'BLOCKED',
  PENDING_ACCEPTANCE = 'PENDING_ACCEPTANCE',
}

export interface ClassInterface {
  id: string;
  name: string;
  schoolId: string;
  status: statusEnum;
  capacity: number;
  roomNumber: number;
  enrollOnline: string;
  startAge: number;
  endAge: number;
  ageSteps: string;
  legalRatioTeachers: number;
  legalRatioChildrens: number;
  desiredRatioTeachers: number;
  desiredRatioChildrens: number;
  personOnline: string;
  cameras?: any[];
  teachersCheckedIn?: any[] | null;
  teachersCheckedOut?: any[] | null;
  kidsEnrolled?: number | null;
  notStarted?: number | null;
  checkedIn?: number | null;
  school: any;
  createdAt: Date;
  updatedAt: Date;
}

export default ClassInterface;
