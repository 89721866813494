import http from '../helpers/http';
import { FilteredOptionsInterface, makeQueryParamsForRequest } from '../entities/FilteredOptions';

const PromoCodes = (token: string) => {
  const headers = {
    'x-access-token': token,
  };

  return {
    list: async (filteredOptions?: FilteredOptionsInterface) => {
      const params = makeQueryParamsForRequest(filteredOptions);
      const result = await http.get('/promo-codes', {
        headers,
        params,
      });
      return result.data;
    },
    listActive: async (filteredOptions?: FilteredOptionsInterface) => {
      const params = makeQueryParamsForRequest(filteredOptions);
      const result = await http.get('promo-codes/actives', {
        headers,
        params,
      });
      return result.data;
    },
    listInactive: async (filteredOptions?: FilteredOptionsInterface) => {
      const params = makeQueryParamsForRequest(filteredOptions);
      const result = await http.get('promo-codes/inactives', {
        headers,
        params,
      });
      return result.data;
    },
    show: async (id: string) => {
      const result = await http.get(`/promo-codes/${id}`, {
        headers,
      });
      return result.data;
    },
    create: async (body: any) => {
      const result = await http.post(`/promo-codes`, body, { headers });
      return result.data;
    },
    update: async (id: string, body: any) => {
      const result = await http.put(`/promo-codes/${id}`, body, { headers });
      return result.data;
    },
    delete: async (id: string) => {
      const result = await http.delete(`/promo-codes/${id}`, {
        headers,
      });
      return result.data;
    },
    listSelect: async () => {
      const result = await http.get(`/promo-codes/select/all`, {
        headers,
      });
      return result.data;
    },
  };
};

export default PromoCodes;
