/* eslint-disable max-lines */
import { Col, Table, Row, InputGroup, Input, InputGroupAddon, Button, FormGroup, InputGroupText } from 'reactstrap';
import { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ReactDatetime from 'react-datetime';
import moment from 'moment';
import FileDownload from 'js-file-download';
import { Context as AuthContext } from '../../../../contexts/AuthContext';
import TimeCardEditorModal from '../TimeCardEditorModal';
import AttendancesService from '../../../../services/Attendances';
import ReportService from '../../../../services/ClassReport';
import dateInterval, { validIntervals as filtersList } from '../../../../helpers/dateInterval';
import isAdmin from '../../../../helpers/isAdmin';
import isDirector from '../../../../helpers/isDirector';
import UserService from '../../../../services/User';
import getTimeCardInfo from '../../../../helpers/getTimeCardInfo';

const TimeCardTab = () => {
  const { token, user } = useContext(AuthContext);
  const { id } = useParams<{ id: string }>();
  const [modal, setModal] = useState(false);
  const [filter, setFilter] = useState('THIS_WEEK');
  const [dateSelected, setDateSelected] = useState('');
  const [data, setData] = useState([] as any[]);
  const [total, setTotal] = useState({} as any);
  const [loading, setLoading] = useState(false);
  const initialInterval = dateInterval('THIS_WEEK');
  const [startDate, setStartDate] = useState(moment(initialInterval.startDateTime).format('MM/DD/YYYY'));
  const [finalDate, setFinalDate] = useState(moment(initialInterval.finalDateTime).format('MM/DD/YYYY'));
  const isAdminUser = isAdmin(user);

  useEffect(() => {
    loadList();
  }, [filter]);

  useEffect(() => {
    loadList();
  }, [id]);

  const loadList = async () => {
    try {
      setLoading(true);
      const userResult = await UserService(token || '').show(id);
      const userDataParam = userResult?.data || {};

      let startDateParam: string = '';
      let finalDateParam: string = '';
      let startDateTime: string = '';
      let finalDateTime: string = '';
      if (filter === 'CUSTOM') {
        startDateParam = `${moment(startDate).format('YYYY-MM-DD')}`;
        finalDateParam = `${moment(finalDate).format('YYYY-MM-DD')}`;
        startDateTime = `${moment(startDate).format('YYYY-MM-DD')}T00:00:00.000Z`;
        finalDateTime = `${moment(finalDate).format('YYYY-MM-DD')}T23:59:59.000Z`;
      }
      if (filter !== 'CUSTOM') {
        const interval = dateInterval(filter);
        startDateParam = interval.startDate;
        finalDateParam = interval.finalDate;
        startDateTime = interval.startDateTime;
        finalDateTime = interval.finalDateTime;
      }
      const result = await AttendancesService(token || '').listByUser(
        id,
        `startDateTime=${startDateTime}&finalDateTime=${finalDateTime}`,
      );
      const data: any[] = result.data;
      setHeadersList(startDateParam, finalDateParam, userDataParam, data);
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Error fetching list');
      return toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  const setHeadersList = (startDate: string, finalDate: string, userDataParam: any, dataParam?: any[]) => {
    const { data, sumRealWorkTotal, sumStandardTime, sumOvertime, sumDailyOvertime } = getTimeCardInfo(
      startDate,
      finalDate,
      userDataParam,
      dataParam,
    );
    setTotal({
      title: 'TOTAL',
      standardTime: sumStandardTime ? sumStandardTime.toFixed(2) : 0,
      overtime: sumOvertime ? sumOvertime.toFixed(2) : 0,
      realWork: sumRealWorkTotal ? sumRealWorkTotal.toFixed(2) : 0,
      dailyOvertime: sumDailyOvertime.toFixed(2),
    });
    setData(data);
  };

  const toggleModal = (date?: string) => {
    if (!hasEditPermission()) {
      return;
    }
    const newValue = !modal;
    setModal(newValue);
    if (newValue) {
      setDateSelected(date || '');
      return;
    }
    setDateSelected('');
  };

  const hasEditPermission = () => {
    if (isAdmin(user)) {
      return true;
    }
    if (isDirector(user) && id !== user?.id) {
      return true;
    }
    return false;
  };

  const handleReportSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);

    try {
      let startDateTime: string = '';
      let finalDateTime: string = '';
      if (filter === 'CUSTOM') {
        startDateTime = `${moment(startDate).format('YYYY-MM-DD')}T00:00:00.000Z`;
        finalDateTime = `${moment(finalDate).format('YYYY-MM-DD')}T23:59:59.000Z`;
      }
      if (filter !== 'CUSTOM') {
        const interval = dateInterval(filter);
        startDateTime = interval.startDateTime;
        finalDateTime = interval.finalDateTime;
      }
      const response = await ReportService(token || '').timecard(
        id,
        `startDateTime=${startDateTime}&finalDateTime=${finalDateTime}`,
      );
      FileDownload(response, 'time-card.pdf');
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Report error!');
      toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Row>
        <Col lg="4">
          <InputGroup>
            <Input type="select" onChange={(e) => setFilter(e.target.value)}>
              {filtersList?.map((filter: any, index: number) => {
                return (
                  <option value={filter.value} key={`filter-button-${index}`}>
                    {filter.label}
                  </option>
                );
              })}
            </Input>
            {isAdminUser && (
              <Button color="primary" onClick={(e) => handleReportSubmit(e)}>
                <i className="fas fa-print"></i>
              </Button>
            )}
          </InputGroup>
        </Col>
        <Col lg="12" className="mt-2"></Col>
        {filter === 'CUSTOM' && (
          <>
            <Col lg="4">
              <FormGroup>
                <label className="form-control-label">Start Date</label>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-calendar-grid-58" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <ReactDatetime
                    inputProps={{
                      placeholder: 'Start Date',
                    }}
                    onChange={(newValue) => {
                      setStartDate(`${newValue}`);
                    }}
                    timeFormat={false}
                    value={`${startDate}`}
                    dateFormat={'MM/DD/yyyy'}
                    closeOnSelect={true}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col lg="4">
              <FormGroup>
                <label className="form-control-label">End Date</label>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-calendar-grid-58" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <ReactDatetime
                    inputProps={{
                      placeholder: 'End Date',
                    }}
                    onChange={(newValue) => {
                      setFinalDate(`${newValue}`);
                    }}
                    timeFormat={false}
                    value={`${finalDate}`}
                    dateFormat={'MM/DD/yyyy'}
                    closeOnSelect={true}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col lg="12" className="mt-n3 mb-2">
              <Button color="primary" size="sm" onClick={() => loadList()}>
                OK
              </Button>
            </Col>
          </>
        )}
      </Row>
      <Row>
        <Table className="align-items-center table-flush mb-3" responsive>
          <tbody>
            <tr>
              <th>Date</th>
              <th>In</th>
              <th>Out</th>
              <th>Pay Code</th>
              {isAdminUser && (
                <>
                  <th>Standard Time</th>
                  <th>Overtime</th>
                  <th>Daily Overtime</th>
                </>
              )}
              <th>Real Work</th>
            </tr>
            {data.map((d, index) => (
              <tr onClick={() => toggleModal(d.dateSelected)} style={{ cursor: 'pointer' }} key={`data-key-${index}`}>
                <td>{d.date}</td>
                <td>
                  {d.clockIn.map((ci: string, index: number) =>
                    index === 0 ? (
                      <span key={`data-ci-key-${index}`}>{ci}</span>
                    ) : (
                      <span key={`data-ci-key-${index}`}>
                        <br />
                        {ci}
                      </span>
                    ),
                  )}
                </td>
                <td>
                  {d.clockOut.map((co: string, index: number) =>
                    index === 0 ? (
                      <span key={`data-co-key-${index}`}>{co}</span>
                    ) : (
                      <span key={`data-co-key-${index}`}>
                        <br />
                        {co}
                      </span>
                    ),
                  )}
                </td>
                <td>
                  {d.payCode.map((value: string, index: number) =>
                    index === 0 ? (
                      <span key={`data-co-key-${index}`}>{value ? value : 'Regular Hours'}</span>
                    ) : (
                      <span key={`data-co-key-${index}`}>
                        <br />
                        {value ? value : 'Regular Hours'}
                      </span>
                    ),
                  )}
                </td>
                {isAdminUser && (
                  <>
                    <td>{d.standardTime}</td>
                    <td>{d.overtime}</td>
                    <td>{d.dailyOvertime}</td>
                  </>
                )}
                <td>{d.realWork}</td>
              </tr>
            ))}
            {total && (
              <tr>
                <td>
                  <b>{total.title}</b>
                </td>
                <td></td>
                <td></td>
                {isAdminUser && (
                  <>
                    <td>
                      <b>{total.standardTime}</b>
                    </td>
                    <td>
                      <b>{total.overtime}</b>
                    </td>
                    <td>
                      <b>{total.dailyOvertime}</b>
                    </td>
                  </>
                )}
                <td>
                  <b>{total.realWork}</b>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Row>
      <TimeCardEditorModal
        userId={id}
        isOpen={modal}
        dateSelected={dateSelected}
        toggleModal={() => {
          setModal(!modal);
          loadList();
        }}
      />
    </>
  );
};

export default TimeCardTab;
