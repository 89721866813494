import { Container, Badge } from 'reactstrap';
import { useContext, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import CommonHeader from '../../../components/Headers/CommonHeader';
import UserService from '../../../services/User';
import UserInterface, { validStatus, statusEnum } from '../../../interfaces/UserInterface';
import dateFormat from '../../../helpers/dateFormat';
import { FilterInterface, FilteredOptionsInterface } from '../../../entities/FilteredOptions';
import { PagedMetaInterface, createPagedMeta } from '../../../entities/PagedMeta';
import { OptionsPagedInterface, createOptionsPage } from '../../../entities/OptionsPaged';
import DataTable from '../../../components/Core/DataTable';

const UserList = () => {
  const { token } = useContext(AuthContext);

  const columns = [
    {
      name: 'Name',
      route: (row: any) => `/users/${row.id}/profile`,
      label: (row: any) => `${row.firstName || '-'} ${row.lastName || '-'}`,
    },
    {
      name: 'Group',
      selector: (row: any) => row.group.name,
      cell: (row: any) => row.group.name,
      grow: 1,
    },
    {
      name: 'Status',
      selector: (row: any) => row.status,
      grow: 1,
      cell: (row: any) => (
        <Badge
          color=""
          className="badge-dot badge-lg"
          style={{ fontSize: '0.8125rem', verticalAlign: 'middle', lineHeight: 'normal' }}
        >
          <i
            className={`bg-${validStatus[row.status as statusEnum]?.color} mr-2`}
            style={{ verticalAlign: 'baseline' }}
          />
          {validStatus[row.status as statusEnum]?.value}
        </Badge>
      ),
    },
    {
      name: 'Created at',
      selector: (row: any) => row.createdAt,
      grow: 2,
      cell: (row: any) => dateFormat(row.createdAt),
    },
    {
      name: 'Updated at',
      selector: (row: any) => row.updatedAt,
      grow: 2,
      cell: (row: any) => dateFormat(row.updatedAt),
    },
  ];

  const [data, setData] = useState([] as any[]);
  const [loading, setLoading] = useState(false);
  const [pagedMeta, setPagedMeta] = useState(createPagedMeta());
  const [filteredOptions, setFilteredOptions] = useState({
    filters: [],
    options: createOptionsPage(pagedMeta),
  } as { filters: FilterInterface[]; options: OptionsPagedInterface });

  useEffect(() => {
    loadList(filteredOptions);
  }, [filteredOptions]);

  const loadList = async (filteredOptions: FilteredOptionsInterface) => {
    try {
      setLoading(true);
      const result = await UserService(token || '').list(filteredOptions);
      const data: UserInterface[] = result.data;
      setData(data);
      const meta: PagedMetaInterface = result.meta;
      if (meta) {
        setPagedMeta(meta);
      }
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Error fetching list');
      return toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = async (text: string) => {
    const newFilteredOptions = JSON.parse(JSON.stringify(filteredOptions));
    let filters: any[] = [];
    if (filteredOptions?.filters?.length) {
      filters = filteredOptions?.filters?.filter((f: any) => f?.field !== 'firstName');
    }
    const filterFirstName = {
      field: 'firstName',
      operation: 'ilike',
      value: text || '',
    };
    filters.push(filterFirstName);
    newFilteredOptions.filters = filters;
    setFilteredOptions(newFilteredOptions);
  };

  const handlePerRowsChange = async (perPage: number) => {
    const newOptions = {
      ...pagedMeta,
      perPage,
    };
    const newFilteredOptions = {
      filters: filteredOptions.filters,
      options: createOptionsPage(newOptions),
    };
    setFilteredOptions(newFilteredOptions);
  };

  const handlePageChange = async (page: number) => {
    const newOptions = {
      ...pagedMeta,
      currentPage: page,
    };
    const newFilteredOptions = {
      filters: filteredOptions.filters,
      options: createOptionsPage(newOptions),
    };
    setFilteredOptions(newFilteredOptions);
  };

  const filterUsers = async (status: string) => {
    const newFilteredOptions = JSON.parse(JSON.stringify(filteredOptions));
    let filters: any[] = [];
    if (filteredOptions?.filters?.length) {
      filters = filteredOptions?.filters?.filter((f: any) => f?.field !== 'status');
    }
    if (status !== 'ALL') {
      filters.push({
        field: 'status',
        operation: '=',
        value: status.toUpperCase(),
      });
    }

    newFilteredOptions.filters = filters;
    setFilteredOptions(newFilteredOptions);
  };

  return (
    <>
      <CommonHeader />
      <Container className="mt--8" fluid>
        <DataTable
          title="Users List"
          subTitle="All Schools"
          columns={columns}
          data={data}
          totalRows={pagedMeta.total}
          loading={loading}
          button={true}
          buttonUrl="/users/new"
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          filtersList={[
            { value: 'ALL', label: 'All Status' },
            { value: 'ACTIVE', label: 'ACTIVE' },
            { value: 'BLOCKED', label: 'BLOCKED' },
            { value: 'NEW', label: 'NEW' },
            { value: 'PENDING', label: 'PENDING' },
            { value: 'HIRED', label: 'HIRED' },
            { value: 'TERMINATED', label: 'TERMINATED' },
            { value: 'DECLINED', label: 'DECLINED' },
            { value: 'LOA', label: 'LOA' },
          ]}
          onChangeFilter={(e) => {
            filterUsers(e);
          }}
          search={true}
          onSearch={handleSearch}
        />
      </Container>
    </>
  );
};

export default UserList;
