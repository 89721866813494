import { useState } from 'react';
import { Modal, Col } from 'reactstrap';
import FullCalendar, { EventClickArg, EventContentArg } from '@fullcalendar/react';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import ScheduleBlockDaysForm from './ScheduleBlockDaysForm';
import ReactTooltip from 'react-tooltip';

type scheduleCalendarProps = {
  events: any[];
  updateEventList: () => void;
};

const ScheduleCalendarBlockDays = ({ events, updateEventList }: scheduleCalendarProps) => {
  const [scheduleId, setScheduleId] = useState('');
  const [dateClick, setDateClick] = useState('');

  const [refresh, setRefresh] = useState(false);

  const handleScheduleCreated = () => {
    setRefresh(!refresh);
    updateEventList();
  };

  const handleDateClick = (selectInfo: DateClickArg) => {
    const date = new Date(selectInfo.date).toISOString().replace(/T.*$/, ''); // YYYY-MM-DD
    setScheduleId('');
    setDateClick(date);
    modalChange();
  };

  const [scheduleBlockDaysForm, setScheduleBlockDaysForm] = useState(false);

  const toggleScheduleForm = () => {
    setScheduleBlockDaysForm(!scheduleBlockDaysForm);
  };

  const modalChange = () => {
    setScheduleBlockDaysForm(!scheduleBlockDaysForm);
    if (scheduleBlockDaysForm == true) {
      //getScheduleId();
    }
  };

  const handleEventClick = (clickInfo: EventClickArg) => {
    setScheduleId(clickInfo.event.id);
    modalChange();
  };

  const renderEventContent = (eventContent: EventContentArg) => {
    return (
      <>
        <b>{eventContent.timeText}</b>
        <i data-tip={eventContent.event.extendedProps.observations}>{eventContent.event.title}</i>
        <ReactTooltip />
      </>
    );
  };

  return (
    <>
      <Col md="12">
        <div className="calendar-app">
          <div className="calendar-app-main">
            <FullCalendar
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
              headerToolbar={{
                left: 'prev',
                center: 'title',
                right: 'next',
              }}
              eventTimeFormat={{
                hour: 'numeric',
                minute: '2-digit',
                meridiem: 'short',
              }}
              initialView="dayGridMonth"
              editable={false}
              selectable={false}
              selectMirror={true}
              dayMaxEvents={false}
              weekends={false}
              events={events.map((e) => ({
                id: e.id,
                title: e.typeBlock === 'entire_day' ? 'All Day Blocked' : 'Blocked',
                start: e.dateBlock + 'T' + e.timeStartBlock,
                classNames: 'schedule-calendar-event-red',
                allDay: e.typeBlock === 'entire_day',
              }))}
              eventContent={renderEventContent} // custom render function
              eventClick={handleEventClick}
              dateClick={handleDateClick}
            />
          </div>
        </div>
      </Col>

      <Modal className="modal-dialog-centered" isOpen={scheduleBlockDaysForm} toggle={() => toggleScheduleForm()}>
        <div className="modal-header">
          <h5 className="modal-title" id="Label">
            {`Update Block Days`}
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => toggleScheduleForm()}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <ScheduleBlockDaysForm
            scheduleId={scheduleId}
            onModalStatus={modalChange}
            dateClick={dateClick}
            onCreateSchedule={handleScheduleCreated}
          />
        </div>
      </Modal>
    </>
  );
};

export default ScheduleCalendarBlockDays;
