import { Modal, Table, Row, Col, Button, Form, FormGroup, Input } from 'reactstrap';
import { useContext, useState } from 'react';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import KidsActivityService from '../../../services/KidsActivity';
import { toast } from 'react-toastify';

type ClassKidsActivityListProps = {
  isOpen: boolean;
  settingsList: any[];
  activityType: string;
  onListUpdate: (e?: any) => void;
  toggleModal: (e?: any) => void;
};

const ClassKidsActivityList = ({
  isOpen,
  settingsList,
  activityType,
  onListUpdate,
  toggleModal,
}: ClassKidsActivityListProps) => {
  const { token }: any = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [settingsName, setSettingsName] = useState('');

  const handleSubmitSettings = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);

    try {
      await KidsActivityService(token).createActivity(settingsName, activityType);
      toast.success(`Saved successfully!`);
      onListUpdate();
      setShowForm(false);
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Create error!');
      toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  const deleteSettings = async (e: React.FormEvent, id: string) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);

    try {
      await KidsActivityService(token).deleteActivity(id);
      toast.success(`Deleted successfully!`);
      onListUpdate();
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Delete error!');
      toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal className="modal-dialog-centered" isOpen={isOpen} toggle={(e: any) => toggleModal(e)}>
        <div className="modal-header pb-1">
          <h5 className="modal-title">Edit Items</h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={(e) => toggleModal(e)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body pt-0">
          <Table className="align-items-center table-flush" responsive>
            <tbody>
              <tr>
                <th>Title</th>
                <th>Action</th>
              </tr>
              {settingsList.map((s: any) => (
                <tr key={`settings-modal-${s.id}`}>
                  <td>{s.name}</td>
                  <td>
                    {s.id && (
                      <i
                        className="far fa-trash-alt text-danger"
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => deleteSettings(e, s.id)}
                      ></i>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Row>
            {!showForm && (
              <Col>
                <Button
                  onClick={() => {
                    setShowForm(true);
                  }}
                >
                  New
                </Button>
              </Col>
            )}
            {showForm && (
              <Col>
                <Form onSubmit={handleSubmitSettings}>
                  <Row>
                    <Col lg="12">
                      <FormGroup>
                        <label className="form-control-label">Name</label>
                        <Input onChange={(e) => setSettingsName(e.target.value)} />
                      </FormGroup>
                    </Col>
                    <Col md="6 text-center">
                      <Button color="primary" type="submit" size="lg" style={{ minWidth: '100px' }}>
                        Save
                      </Button>
                    </Col>
                    <Col md="6 text-center">
                      <Button
                        color="secondary"
                        size="lg"
                        style={{ minWidth: '100px' }}
                        onClick={(e) => {
                          e.preventDefault();
                          setShowForm(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            )}
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default ClassKidsActivityList;
