import http from '../helpers/http';

const State = (token: string) => {
  const headers = {
    'x-access-token': token,
  };

  return {
    listSelect: async () => {
      const result = await http.get(`/state/select/all`, {
        headers,
      });
      return result.data;
    },
  };
};

export default State;
