import http from '../helpers/http';
import DocumentsInterface from '../interfaces/DocumentsInterface';
import DocumentsInterfacePositions from '../interfaces/DocumentsInterfacePositions';
import { FilteredOptionsInterface, makeQueryParamsForRequest } from '../entities/FilteredOptions';

const Documents = (token: string) => {
  const headers = {
    'x-access-token': token,
  };

  return {
    list: async (filteredOptions?: FilteredOptionsInterface) => {
      const params = makeQueryParamsForRequest(filteredOptions);
      const result = await http.get(`/documents`, {
        headers,
        params,
      });
      return result.data;
    },
    listForPosition: async (filteredOptions?: FilteredOptionsInterface) => {
      const params = makeQueryParamsForRequest(filteredOptions);
      const result = await http.get(`/documents/positions`, {
        headers,
        params,
      });
      return result.data;
    },

    show: async (id: string) => {
      const result = await http.get(`/documents/${id}`, {
        headers,
      });
      return result.data;
    },
    create: async (body: Partial<DocumentsInterface>) => {
      const result = await http.post(`/documents`, body, { headers });
      return result.data;
    },
    update: async (id: string, body: Partial<DocumentsInterface>) => {
      const result = await http.put(`/documents/${id}`, body, { headers });
      return result.data;
    },
    updatePositions: async (id: string, body: Partial<DocumentsInterfacePositions>) => {
      const result = await http.put(`/documents/${id}/positions`, body, { headers });
      return result.data;
    },
    delete: async (id: string) => {
      const result = await http.delete(`/documents/${id}`, {
        headers,
      });
      return result.data;
    },
    picture: async (id: string, body: FormData) => {
      const headers = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-access-token': token,
        },
      };
      const result = await http.patch(`/documents/${id}/picture`, body, headers);
      return result.data;
    },
    file: async (id: string, body: FormData) => {
      const headers = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-access-token': token,
        },
      };
      const result = await http.patch(`/documents/${id}/file`, body, headers);
      return result.data;
    },
  };
};

export default Documents;
