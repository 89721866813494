import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import { useCallback, useState, useContext, useEffect } from 'react';
import { Card as CardFolder } from 'reactstrap';
import { DragDropFilesCard } from './DragDropFilesCard';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import { toast } from 'react-toastify';
import Documents from '../../../services/Documents';
import DocumentsInterface from '../../../interfaces/DocumentsInterface';
import { FilteredOptionsInterface } from '../../../entities/FilteredOptions';
import { createOptionsPage } from '../../../entities/OptionsPaged';
import { PagedMetaInterface, createPagedMeta } from '../../../entities/PagedMeta';
import { useLocation } from 'react-router-dom';
import isAdmin from '../../../helpers/isAdmin';
import isDirector from '../../../helpers/isDirector';

const style = {
  display: 'list-item',
  listStyleType: 'none',
  border: 'none',
};

export interface Item {
  id: number;
  name: string;
  position: number;
  picture: string;
  groupPermissions: any;
}

type DragDropProps = {
  onRefresh: () => void;
  searchFilter?: string;
};

export const DragDropFiles = ({ onRefresh, searchFilter }: DragDropProps) => {
  const { token, user, currentSchool } = useContext(AuthContext);
  const hasEditPermission = isAdmin(user) || isDirector(user);

  const [cards, setCards] = useState([] as any[]);

  const [pagedMeta, setPagedMeta] = useState(createPagedMeta());
  const [filteredOptions] = useState({
    filters: [],
    options: createOptionsPage(pagedMeta),
  });

  const { hash } = useLocation();
  const hashFolderId = hash.replace('#', '');

  const urlLocation = useLocation()['pathname'];
  useEffect(() => {
    loadList(filteredOptions);
  }, [urlLocation, onRefresh, currentSchool]);

  const loadList = async (filteredOptions: FilteredOptionsInterface) => {
    try {
      let moduleName = '';
      if (urlLocation == '/curriculum') {
        moduleName = 'Curriculum';
      } else if (urlLocation == '/resource') {
        moduleName = 'Resources';
      }

      let filterFolderId = null;
      if (!searchFilter || searchFilter == null || searchFilter == '') {
        filterFolderId = {
          field: 'documentsFoldersId',
          operation: '=',
          value: hashFolderId,
        };
      } else {
        filterFolderId = {
          field: 'documentsFolders.typeFile',
          operation: '=',
          value: `${moduleName}`,
        };
      }

      const filterSchool = {
        field: 'schoolId',
        operation: 'hash',
        value: currentSchool.value,
      };

      const filterSearch = {
        field: 'name',
        operation: 'ilike',
        value: `%${searchFilter ? searchFilter : ''}%`,
      };

      filteredOptions.filters = [filterFolderId, filterSearch, filterSchool];

      const result = await Documents(token || '').list(filteredOptions);
      const data: DocumentsInterface[] = result.data;
      setCards(data);
      const meta: PagedMetaInterface = result.meta;
      if (meta) {
        setPagedMeta(meta);
      }
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Error fetching list');
      return toast.error(message);
    }
  };

  const updatePositions = async (
    filteredOptions: FilteredOptionsInterface,
    idFile: string,
    dragIndex: number,
    hoverIndex: number,
  ) => {
    if (hasEditPermission) {
      const filterFolderIdPosition = {
        field: 'documentsFoldersId',
        operation: '=',
        value: hashFolderId,
      };

      const filterSchool = {
        field: 'schoolId',
        operation: 'hash',
        value: currentSchool.value,
      };

      filteredOptions.filters = [filterFolderIdPosition, filterSchool];

      const resultPositions = await Documents(token || '').listForPosition(filteredOptions);
      const dataPositions: DocumentsInterface[] = resultPositions.data;
      const hoverIndexPosition = hoverIndex + 1;
      let countPositions = 1;
      let index = 1;
      dataPositions.map((row: any) => {
        console.log(row.id, index);
        if (row.id == idFile) {
          handleSubmit(row.id, hoverIndex);
        } else {
          if (countPositions == hoverIndexPosition) {
            countPositions = countPositions + 1;
          }
          handleSubmit(row.id, countPositions);
        }

        countPositions = countPositions + 1;
        index++;
      });
    }
  };

  const handleSubmit = async (id: string, position: number) => {
    const body = {
      position: position,
    };

    try {
      if (hasEditPermission) {
        await Documents(token || '').updatePositions(id, body);
      }
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Create error!');
      toast.error(message);
    }
  };

  {
    const moveCard = useCallback((dragIndex: number, hoverIndex: number, idFile: string) => {
      setCards((prevCards: Item[]) =>
        update(prevCards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevCards[dragIndex] as Item],
          ],
        }),
      );
      updatePositions(filteredOptions, idFile, dragIndex, hoverIndex);
    }, []);

    const renderCard = useCallback(
      (
        card: {
          id: number;
          name: string;
          position: number;
          picture: string;
          attachment: string;
          attachmentBlob: string;
        },
        index: number,
      ) => {
        return (
          <DragDropFilesCard
            position={card.position}
            key={card.id}
            index={index}
            id={card.id}
            name={card.name}
            moveCard={moveCard}
            picture={card.picture}
            onRefresh={onRefresh}
            attachment={card.attachment}
          />
        );
      },
      [],
    );

    return (
      <>
        <DndProvider backend={HTML5Backend}>
          <CardFolder style={style} className="bg-secondary">
            {cards.map((card, i) => renderCard(card, i))}
          </CardFolder>
        </DndProvider>
      </>
    );
  }
};
