import { Row, Col, Card, CardBody } from 'reactstrap';
import { useContext, useState, useEffect } from 'react';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import MessagingList from './MessagingList';
import MessagingBlank from './MessagingBlank';
import MessagingCreate from './MessagingCreate';
import MessagingTalk from './MessagingTalk';

const MessagingListTalk = ({ userId, studentId }: { userId?: string; studentId?: string }) => {
  const { currentSchool }: any = useContext(AuthContext);
  const [typeDisplay, setTypeDisplay] = useState('blank');

  const setNewTypeDisplay = (type: string) => {
    setTypeDisplay(type);
  };

  useEffect(() => {
    setNewTypeDisplay('blank');
  }, [currentSchool]);

  return (
    <>
      <Row className="messaging-list-talk-mainRow">
        <Col xl="3" lg="5" className="messaging-list-talk-col-talk" style={{ minWidth: '350px', overflowX: 'hidden' }}>
          <MessagingList setNewTypeDisplay={setNewTypeDisplay} userId={userId} studentId={studentId} />
        </Col>
        <Col>
          {!userId && !studentId && (
            <>
              {typeDisplay == 'blank' && <MessagingBlank setNewTypeDisplay={setNewTypeDisplay} />}
              {typeDisplay == '' && <MessagingCreate setNewTypeDisplay={setNewTypeDisplay} />}
            </>
          )}
          {(userId || studentId) && typeDisplay === 'blank' && (
            <>
              <Card className="messaging-card-blank">
                <CardBody className="messaging-blank-cardbody">
                  <Row>
                    <Col md="12" className="messaging-card-header-title">
                      <i className="fas fa-paper-plane"></i>
                    </Col>
                    <Col md="12" className="messaging-card-header-text">
                      <p>Select a message.</p>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </>
          )}
          {typeDisplay != '' && typeDisplay != 'blank' && (
            <MessagingTalk
              idTalk={typeDisplay}
              userId={userId}
              studentId={studentId}
              setNewTypeDisplay={setNewTypeDisplay}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default MessagingListTalk;
