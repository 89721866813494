import http from '../helpers/http';

const HireDocuments = (token: string) => {
  const headers = {
    'x-access-token': token,
  };

  return {
    list: async () => {
      const result = await http.get(`/hire-documents`, {
        headers,
      });
      return result.data;
    },
    show: async (id: string) => {
      const result = await http.get(`/hire-documents/${id}`, {
        headers,
      });
      return result.data;
    },
    create: async (body: any) => {
      const result = await http.post(`/hire-documents`, body, { headers });
      return result.data;
    },
    delete: async (id: string) => {
      const result = await http.delete(`/hire-documents/${id}`, {
        headers,
      });
      return result.data;
    },
  };
};

export default HireDocuments;
