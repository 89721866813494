import { Row, Col, FormGroup, InputGroup, Input, Label, Button, InputGroupAddon, InputGroupText } from 'reactstrap';
import ReactDatetime from 'react-datetime';

type ChildFormProps = {
  firstName: string;
  lastName: string;
  birthday: string;
  potentialDate: string;
  tourStatus: string;
  index: number;

  setFirstName: (value: string, index: number) => void;
  setLastName: (value: string, index: number) => void;
  setBirthday: (value: string, index: number) => void;
  setPotentialDate: (value: string, index: number) => void;
  setTourStatus: (value: string, index: number) => void;
  deleteChild: (index: number) => void;
};

const ChildForm = ({
  firstName,
  lastName,
  birthday,
  potentialDate,
  tourStatus,
  index,
  setFirstName,
  setLastName,
  setBirthday,
  setPotentialDate,
  setTourStatus,
  deleteChild,
}: ChildFormProps) => {
  return (
    <>
      <Row>
        <Col lg="12">{index > 0 && <hr />}</Col>
        <Col lg="12">
          <FormGroup>
            <Label className="form-control-label">First Name</Label>
            <Input
              className="form-control-alternative"
              id="input-firstName"
              placeholder="First Name"
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value, index)}
              required
            />
          </FormGroup>
        </Col>

        <Col lg="12">
          <FormGroup>
            <Label className="form-control-label">Last Name</Label>
            <Input
              className="form-control-alternative"
              id="input-lastName"
              placeholder="Last Name"
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value, index)}
              required
            />
          </FormGroup>
        </Col>

        <Col lg="6">
          <FormGroup>
            <label className="form-control-label">Birthday</label>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-calendar-grid-58" />
                </InputGroupText>
              </InputGroupAddon>
              <ReactDatetime
                inputProps={{
                  placeholder: 'Birthday',
                  required: true,
                }}
                onChange={(newValue) => {
                  setBirthday(`${newValue}`, index);
                }}
                timeFormat={false}
                value={`${birthday}`}
                dateFormat={'MM/DD/yyyy'}
                closeOnSelect={true}
              />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <label className="form-control-label">Potential Start Date</label>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-calendar-grid-58" />
                </InputGroupText>
              </InputGroupAddon>
              <ReactDatetime
                inputProps={{
                  placeholder: 'Potential Start Date',
                }}
                onChange={(newValue) => {
                  setPotentialDate(`${newValue}`, index);
                }}
                timeFormat={false}
                value={`${potentialDate}`}
                dateFormat={'MM/DD/yyyy'}
                closeOnSelect={true}
              />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col lg="12">
          <FormGroup>
            <label className="form-control-label" htmlFor="input-typeTour">
              Tour Status
            </label>

            <select
              name="input-status"
              id="input-status"
              className="form-control"
              value={tourStatus}
              onChange={(e) => setTourStatus(e.target.value, index)}
            >
              <option value="">Please select</option>
              <option value="ENROLLED">Enrolled</option>
              <option value="FOLLOW_UP">Follow Up</option>
              <option value="NOT_ENROLLED">Not Enrolled</option>
              <option value="NOT_INTERESTED">Not Interested </option>
              <option value="WAIT_LIST">Wait List</option>
              <option value="TOUR_INFO_NOT_UPDATED">Tour Info Not Updated</option>
              <option value="NO_SHOW_TOUR">No Show Tour</option>
            </select>
          </FormGroup>
        </Col>
        <Col lg="12">
          {index > 0 && (
            <Button
              color="warning"
              className="waiting-list-btn-delete mb-3"
              size="sm"
              onClick={() => deleteChild(index)}
            >
              Delete
            </Button>
          )}
        </Col>
        <Col lg="12">{index > 0 && <hr />}</Col>
      </Row>
    </>
  );
};

export default ChildForm;
