import { useContext, useState, useEffect } from 'react';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import { FilteredOptionsInterface } from '../../../entities/FilteredOptions';
import { PagedMetaInterface, createPagedMeta } from '../../../entities/PagedMeta';
import { createOptionsPage } from '../../../entities/OptionsPaged';
import DataTable from '../../../components/Core/DataTableWithoutHeader';
import InvoicesService from '../../../services/Invoices';
import { toLocaleFormatSlash } from '../../../helpers/dateFormat';
import FileDownload from 'js-file-download';

const BillingInvoicesList = ({ reload, kidsList, kidsId }: { reload: boolean; kidsList: any[]; kidsId: string }) => {
  const { token, currentSchool } = useContext(AuthContext);

  const columns = [
    {
      name: 'Invoice',
      selector: (row: any) => <h3 style={{ fontSize: 15 }}>{`${row?.number || '-'}`}</h3>,
      grow: 2,
    },
    {
      name: 'Account',
      selector: (row: any) => `${row?.user?.firstName || ''} ${row?.user?.lastName || ''}`,
      grow: 2,
    },
    {
      name: 'Current Status',
      selector: (row: any) => row?.status,
      grow: 1,
      cell: (row: any) => `${row?.status || '-'}`,
    },
    {
      name: 'Total Amount',
      selector: (row: any) => row.totalAmount,
      grow: 1,
      cell: (row: any) => `$ ${row?.totalAmount ? Number(row?.totalAmount).toFixed(2) : '0.00'}`,
    },
    {
      name: 'Discount',
      selector: (row: any) => row.discountReal,
      grow: 1,
      cell: (row: any) =>
        `$ ${row?.discountReal ? Number(row?.discountReal).toFixed(2) : '0.00'} (${row?.discountPercent}%)`,
    },
    {
      name: 'Amount Paid',
      selector: (row: any) => row.amount,
      grow: 1,
      cell: (row: any) => `$ ${row?.amount ? Number(row?.amount).toFixed(2) : '0.00'}`,
    },
    {
      name: 'Due Date',
      selector: (row: any) => row.dueDate,
      grow: 1,
      cell: (row: any) => `${row?.dueDate ? toLocaleFormatSlash(row?.dueDate) : '-'}`,
    },
    {
      name: 'PDF',
      selector: (row: any) => row.id,
      grow: 1,
      cell: (row: any) => (
        <i
          className="far fa-file-pdf fa-lg float-right"
          title="Invoice PDF"
          style={{ cursor: 'pointer' }}
          onClick={(e) => getPDF(e, row.id)}
        ></i>
      ),
    },
  ];

  const [data, setData] = useState([] as any[]);
  const [loading, setLoading] = useState(false);
  const [pagedMeta, setPagedMeta] = useState(createPagedMeta());
  const [filteredOptions, setFilteredOptions] = useState({
    filters: [],
    options: createOptionsPage(pagedMeta),
  });

  useEffect(() => {
    loadList(filteredOptions);
  }, [filteredOptions, currentSchool, reload]);

  const getPDF = async (e: React.FormEvent, id: string) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);

    try {
      const response = await InvoicesService(token || '').getPDF(id);
      FileDownload(response, 'invoice.pdf');
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Report error!');
      console.error(message);
    } finally {
      setLoading(false);
    }
  };

  const loadList = async (filteredOptions: FilteredOptionsInterface) => {
    try {
      setLoading(true);
      if (kidsList.length > 1) {
        const filter = {
          field: 'kidsId',
          operation: 'in',
          value: kidsList.map((item) => item.id),
        };
        filteredOptions.filters = [filter];
      }
      const result = await InvoicesService(token || '').listByKids(kidsId, filteredOptions);
      setData(result?.data);
      const meta: PagedMetaInterface = result.meta;
      if (meta) {
        setPagedMeta(meta);
      }
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Error fetching list');
      console.error(message);
    } finally {
      setLoading(false);
    }
  };

  const handlePerRowsChange = async (perPage: number) => {
    const newOptions = {
      ...pagedMeta,
      perPage,
    };
    const newFilteredOptions = {
      filters: filteredOptions.filters,
      options: createOptionsPage(newOptions),
    };
    setFilteredOptions(newFilteredOptions);
  };

  const handlePageChange = async (page: number) => {
    const newOptions = {
      ...pagedMeta,
      currentPage: page,
    };
    const newFilteredOptions = {
      filters: filteredOptions.filters,
      options: createOptionsPage(newOptions),
    };
    setFilteredOptions(newFilteredOptions);
  };

  return (
    <>
      <DataTable
        title=""
        columns={columns}
        data={data}
        totalRows={pagedMeta.total}
        loading={loading}
        button={false}
        buttonUrl=""
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
      />
    </>
  );
};

export default BillingInvoicesList;
