import http from '../helpers/http';

const SearchBar = (token: string) => {
  const headers = {
    'x-access-token': token,
  };

  return {
    searchBySchool: async (schoolId?: string, query?: string) => {
      const result = await http.get(`/search/${schoolId}${query ? `?query=${query}` : ''}`, {
        headers,
      });
      return result.data;
    },
    search: async (query?: string) => {
      const result = await http.get(`/search${query ? `?query=${query}` : ''}`, {
        headers,
      });
      return result.data;
    },
  };
};

export default SearchBar;
