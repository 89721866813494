import { useState } from 'react';
import { CardBody, Card, CardHeader, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import BillingFilesList from './BillingFilesList';
import BillingInvoicesList from './BillingInvoicesList';
import BillingTransactionsList from './BillingTransactionsList';
import { isMobile } from 'react-device-detect';

const BillingTabs = ({ loading, kidsList, kidsId }: { loading: boolean; kidsList: any[]; kidsId: string }) => {
  const customStyles = {
    navLink: {
      minHeight: '42px',
      paddingTop: '8px',
      cursor: 'pointer',
    },
  };

  const [activeTab, setActiveTab] = useState('1');

  return (
    <Card className="bg-secondary shadow mt-4">
      <CardHeader>
        <Nav tabs style={isMobile ? { fontSize: 15 } : {}}>
          <NavItem>
            <NavLink
              className={`${activeTab === '1' ? 'active' : ''}`}
              onClick={() => setActiveTab('1')}
              style={customStyles.navLink}
            >
              <i className="fas fa-dollar-sign"></i> Transactions
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={`${activeTab === '2' ? 'active' : ''}`}
              onClick={() => setActiveTab('2')}
              style={customStyles.navLink}
            >
              <i className="fas fa-file-invoice-dollar"></i> Invoices
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={`${activeTab === '3' ? 'active' : ''}`}
              onClick={() => setActiveTab('3')}
              style={customStyles.navLink}
            >
              <i className="fas fa-file-pdf"></i> Files
            </NavLink>
          </NavItem>
        </Nav>
      </CardHeader>
      <CardBody>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1" className="pt-2">
            <BillingTransactionsList reload={loading} kidsList={kidsList} kidsId={kidsId} />
          </TabPane>
          <TabPane tabId="2">
            <BillingInvoicesList reload={loading} kidsList={kidsList} kidsId={kidsId} />
          </TabPane>
          <TabPane tabId="3">
            <BillingFilesList />
          </TabPane>
        </TabContent>
      </CardBody>
    </Card>
  );
};

export default BillingTabs;
