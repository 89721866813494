/* eslint-disable max-lines */
import { Card, CardHeader, Table, Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { toLocaleFormatSlash } from '../../helpers/dateFormat';

import ScheduleCalendar from '../../views/pages/Schedule/ScheduleCalendar';
import { ErrorsList } from '../../views/pages/Home/ErrorsList';
import { Expirations } from '../../views/pages/Home/Expirations';

import { DashInfo } from '../../views/pages/Home/DashInfo';

import { ChildrenListModal } from '../../views/pages/Home/ChildrenListModal';

import KidsRemindersService from '../../services/KidsReminders';
import KidsService from '../../services/Kids';
import WaitingListService from '../../services/WaitingList';

import confirmAlert from '../../helpers/confirmAlert';

import { createPagedMeta, PagedMetaInterface } from '../../entities/PagedMeta';
import { createOptionsPage } from '../../entities/OptionsPaged';
import FilteredOptionsInterface from '../../entities/FilteredOptions';

import { useContext, useEffect, useState } from 'react';

import { Context as AuthContext } from '../../contexts/AuthContext';
import isAdmin from '../../helpers/isAdmin';
import isDirector from '../../helpers/isDirector';
import WaitList from '../../views/pages/WaitList/WaitList';
import OpacityLoaderSpinner from '../Core/OpacityLoaderSpinner';
import { isMobile } from 'react-device-detect';
import BaseHomeMobile from '../../views/pages/BaseHomeMobile';
import isEducationDirectorAdminAssistant from '../../helpers/isEducationDirectorAdminAssistant';
import isUpperManagment from '../../helpers/isUpperManagment';
import isFranchiseeManagment from '../../helpers/isFranchiseeManagment';
import { WebEnrollList } from '../../views/pages/Home/WebEnrollList';

const AdminDashboard = () => {
  const isTablet = window.innerWidth <= 1112 && window.innerWidth > 768 && isMobile;
  const { token = '', currentSchool = {}, user }: any = useContext(AuthContext);

  const hasSettingsPermission =
    isAdmin(user) ||
    isDirector(user) ||
    isEducationDirectorAdminAssistant(user) ||
    isUpperManagment(user) ||
    isFranchiseeManagment(user);
  const [kidsData, setKidsData] = useState<any[]>([]);
  const [kidsFiltered, setKidsFiltered] = useState<any[]>([]);
  const [kidsRemindersList, setKidsRemindersList] = useState([] as any[]);
  const [waitingList, setWaitingList] = useState([] as any[]);

  const [orderByWaitingList, setOrderByWaitingList] = useState('age');

  const [pagedMeta, setPagedMeta] = useState(createPagedMeta());
  const [filteredOptions, setFilteredOptions] = useState({
    filters: [],
    options: createOptionsPage(pagedMeta),
  });
  const [loading, setLoading] = useState(false);
  const [modalChildrenTitle, setModalChildrenTitle] = useState('');
  const [modalChildrenType, setModalChildrenType] = useState('');
  const [modalChildrenList, setModalChildrenList] = useState(false);
  const [modalShowStatus, setModalShowStatus] = useState(false);

  const handleFetch = async (filteredOptionsParam: FilteredOptionsInterface) => {
    if (!currentSchool?.value) return;
    try {
      setLoading(true);
      const result = await KidsService(token).list(filteredOptionsParam);
      setKidsData(result?.data);
      const meta: PagedMetaInterface = result.meta;
      if (meta) {
        setPagedMeta(meta);
      }
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      console.error(e?.response?.data?.message || 'No kids found!');
    }
  };

  const refreshData = async () => {
    handleFetch(filteredOptions);
  };

  const setFiltersAndFetch = async () => {
    if (!currentSchool?.value) return;
    if (hasSettingsPermission && filteredOptions) {
      const newFilteredOptions = JSON.parse(JSON.stringify(filteredOptions));
      if (!newFilteredOptions?.options) {
        newFilteredOptions.options = createOptionsPage(pagedMeta);
      }
      const filter = {
        field: 'enrollments.schoolId',
        operation: 'hash',
        value: currentSchool.value,
      };
      if (!Array.isArray(newFilteredOptions?.filters)) {
        newFilteredOptions.filters = [];
      }
      newFilteredOptions.filters.push(filter);
      newFilteredOptions.options.itemsPerPage = 10000;
      handleFetch(newFilteredOptions);
    }
  };

  useEffect(() => {
    setFiltersAndFetch();
  }, [filteredOptions]);

  useEffect(() => {
    setFiltersAndFetch();
  }, [currentSchool]);

  const toggleModalChildren = (title: string = '', type: string = 'CURRENT') => {
    if (title) {
      setModalChildrenTitle(title);
    }
    if (type) {
      setModalChildrenType(type);
    }
    if (kidsData?.length) {
      let statusList = type === 'FUTURE' ? ['ENROLLED_NOT_STARTED'] : ['ENROLLED'];
      if (type === 'ALL') {
        statusList = ['ENROLLED_NOT_STARTED', 'ENROLLED'];
        setModalShowStatus(true);
      } else {
        setModalShowStatus(false);
      }
      let newKidsFiltered = JSON.parse(JSON.stringify(kidsData));
      newKidsFiltered = newKidsFiltered?.filter((k: any) => statusList.includes(k?.enrollment?.status));
      setKidsFiltered(newKidsFiltered);
    }
    setModalChildrenList(!modalChildrenList);
  };

  const setFilteredDate = (startDate: string = '') => {
    const statusList = modalChildrenType === 'FUTURE' ? ['ENROLLED_NOT_STARTED'] : ['ENROLLED'];
    let newKidsFiltered = JSON.parse(JSON.stringify(kidsData));

    if (startDate) {
      newKidsFiltered = newKidsFiltered?.filter(
        (k: any) =>
          statusList.includes(k?.enrollment?.status) &&
          new Date(k?.enrollment?.enrollmentStartDate) >= new Date(startDate),
      );
      return setKidsFiltered(newKidsFiltered);
    }
    newKidsFiltered = newKidsFiltered?.filter((k: any) => statusList.includes(k?.enrollment?.status));
    return setKidsFiltered(newKidsFiltered);
  };

  useEffect(() => {
    if (hasSettingsPermission) {
      listKidsReminders();
      listWaitingList();
    }
  }, [token, orderByWaitingList, currentSchool]);

  const listKidsReminders = async () => {
    try {
      const result = await KidsRemindersService(token).list(currentSchool.value);
      setKidsRemindersList(result.data || []);
    } catch (e: any) {
      console.error(e?.response?.data?.message || 'No reminders found!');
    }
  };

  const listWaitingList = async () => {
    if (!currentSchool?.value) return;
    try {
      const result = await WaitingListService(token).list(currentSchool.value, orderByWaitingList);
      setWaitingList(result.data || []);
    } catch (e: any) {
      console.error(e?.response?.data?.message || 'No WaitingList found!');
    }
  };

  const handleRead = async (id: string) => {
    try {
      await KidsRemindersService(token).read(id);
      listKidsReminders();
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Read error!');
      console.error(message);
    }
  };

  const readReminderAlert = async (e: React.FormEvent, id: string) => {
    e.preventDefault();
    return confirmAlert({
      title: 'Confirm',
      messageType: 'EDIT',
      message: 'Are you sure this was completed?',
      id,
      onClickYes: () => handleRead(id),
    });
  };

  return (
    <>
      <Container className="mt--7 " fluid>
        {isMobile && !isTablet && <BaseHomeMobile />}
        {hasSettingsPermission && kidsData && (
          <DashInfo
            currentSchool={currentSchool}
            token={token}
            kids={kidsData}
            handleStudentsModal={toggleModalChildren}
            refreshData={refreshData}
          />
        )}

        <ErrorsList />

        {hasSettingsPermission && kidsRemindersList.filter((k: any) => !k.read)?.length > 0 && (
          <Row className="mt-5">
            <Col xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Reminders</h3>
                    </div>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <tbody>
                    {kidsRemindersList?.filter((k: any) => !k.read)?.length === 0 && (
                      <tr>
                        <td colSpan={4} className="text-center"></td>
                      </tr>
                    )}
                    {kidsRemindersList
                      .filter((k: any) => !k.read)
                      .map((s: any) => (
                        <tr key={`kidsRemindersList-modal-${s.id}`}>
                          <th scope="row">{toLocaleFormatSlash(s.date)}</th>
                          <td>
                            <Link to={`/kids/${s.kidsId}`}>
                              {s.kids ? `${s.kids.firstName} ${s.kids.lastName}` : '-'}
                            </Link>
                          </td>
                          <td>{s.notes}</td>
                          <td>
                            {!s.read && (
                              <i
                                className="fas fa-check-circle fa-lg text-info"
                                title="Mark as read"
                                style={{ cursor: 'pointer' }}
                                onClick={(e) => readReminderAlert(e, s.id)}
                              ></i>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Card>
            </Col>
            <Col xl="3"></Col>
            <Col xl="3"></Col>
            <Col xl="3"></Col>
          </Row>
        )}

        <Row className={`${isTablet ? 'mt-1' : 'mt-5'}`}>
          <Col md="12">
            <Expirations />
          </Col>
        </Row>

        {hasSettingsPermission && (
          <Row>
            <Col xl="12">
              <WebEnrollList />
            </Col>
          </Row>
        )}

        {hasSettingsPermission && (
          <Row className="mt-5 home-waitingList">
            <Col xl="12">
              <ScheduleCalendar />
            </Col>
          </Row>
        )}

        {hasSettingsPermission && (
          <WaitList
            waitingList={waitingList}
            orderByWaitingList={orderByWaitingList}
            setOrderByWaitingList={setOrderByWaitingList}
          />
        )}

        <ChildrenListModal
          currentSchoolId={currentSchool.value}
          loading={loading}
          token={token}
          title={modalChildrenTitle}
          kids={kidsFiltered}
          filteredOptions={filteredOptions}
          setFilteredOptions={(newOptions: any) => setFilteredOptions(newOptions)}
          setFilteredDate={setFilteredDate}
          toggleModal={modalChildrenList}
          handleToggleModal={() => toggleModalChildren('')}
          showStatus={modalShowStatus}
        />
      </Container>
      {loading ? <OpacityLoaderSpinner /> : <></>}
    </>
  );
};

export default AdminDashboard;
