import { Modal, Table, Row, Col, Button, Form, FormGroup, Input } from 'reactstrap';
import { useContext, useState, useEffect } from 'react';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import ClassTypeService from '../../../services/ClassType';
import { toast } from 'react-toastify';
import { validClassTypes as classTypes } from '../../../interfaces/ClassTypeInterface';

type ClassTypeModalProps = {
  isOpen: boolean;
  toggleModal: (e?: any) => void;
};

const ClassTypeModal = ({ isOpen, toggleModal }: ClassTypeModalProps) => {
  const { token }: any = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [classTypeName, setClassTypeName] = useState('');
  const [classTypeList, setClassTypeList] = useState([]);
  const [classTypeType, setClassTypeType] = useState('');

  useEffect(() => {
    if (isOpen) {
      listData();
    }
  }, [isOpen]);

  const listData = async () => {
    try {
      const result = await ClassTypeService(token).list();
      setClassTypeList(result.data || []);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No Class Type Found!');
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);

    const body = {
      name: classTypeName,
      type: classTypeType,
    };

    try {
      await ClassTypeService(token).create(body);
      toast.success(`Saved successfully!`);
      listData();
      setShowForm(false);
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Create error!');
      toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  const deleteSettings = async (e: React.FormEvent, id: string) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);

    try {
      await ClassTypeService(token).delete(id);
      toast.success(`Deleted successfully!`);
      listData();
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Delete error!');
      toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal className="modal-dialog-centered" isOpen={isOpen} toggle={(e: any) => toggleModal(e)}>
        <div className="modal-header pb-1">
          <h5 className="modal-title">Edit Class Types</h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={(e) => toggleModal(e)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body pt-0">
          <Table className="align-items-center table-flush" responsive>
            <tbody>
              <tr>
                <th>Title</th>
                <th>Action</th>
              </tr>
              {classTypeList?.length === 0 && (
                <tr>
                  <td colSpan={2} className="text-center">
                    Data not found
                  </td>
                </tr>
              )}
              {classTypeList.map((s: any) => (
                <tr key={`classType-modal-${s.id}`}>
                  <td>{s.name}</td>
                  <td>
                    <i
                      className="far fa-trash-alt text-danger"
                      style={{ cursor: 'pointer' }}
                      onClick={(e) => deleteSettings(e, s.id)}
                    ></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Row>
            {!showForm && (
              <Col>
                <Button
                  onClick={() => {
                    setShowForm(true);
                  }}
                >
                  New
                </Button>
              </Col>
            )}
            {showForm && (
              <Col>
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col lg="12">
                      <FormGroup>
                        <label className="form-control-label">Name</label>
                        <Input onChange={(e) => setClassTypeName(e.target.value)} />
                      </FormGroup>
                    </Col>
                    <Col lg="12">
                      <FormGroup>
                        <label className="form-control-label">Type</label>
                        <Input
                          type="select"
                          className="form-control-label"
                          onChange={(e) => setClassTypeType(e.target.value)}
                          value={classTypeType}
                        >
                          {classTypes?.map((c: any, index: number) => {
                            return (
                              <option key={index} value={c.value}>
                                {c.value}
                              </option>
                            );
                          })}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="6 text-center">
                      <Button color="primary" type="submit" size="lg" style={{ minWidth: '100px' }}>
                        Save
                      </Button>
                    </Col>
                    <Col md="6 text-center">
                      <Button
                        color="secondary"
                        size="lg"
                        style={{ minWidth: '100px' }}
                        onClick={(e) => {
                          e.preventDefault();
                          setShowForm(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            )}
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default ClassTypeModal;
