import { Row, Col, Button, FormGroup, Input } from 'reactstrap';
import { useContext, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import NumberFormat from 'react-number-format';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import PriceSheetService from '../../../services/PriceSheet';
import ClassTypeService from '../../../services/ClassType';
import confirmAlert from '../../../helpers/confirmAlert';

type ClassPriceSheetListProps = {
  classId: string;
};

const ClassPriceSheetList = ({ classId }: ClassPriceSheetListProps) => {
  const { token }: any = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [showPriceSheet, setShowPriceSheet] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [priceSheetList, setPriceSheetList] = useState([]);
  const [classTypeList, setClassTypeList] = useState([]);
  const [classTypeId, setClassTypeId] = useState('');
  const [paymentFrequency, setPaymentFrequency] = useState('WEEKLY');
  const [tuition, setTuition] = useState(0);
  const [editId, setEditId] = useState('');

  const validPaymentFrequency = [
    { value: 'WEEKLY', label: 'Weekly' },
    { value: 'MONTHLY', label: 'Monthly' },
  ];

  useEffect(() => {
    listClassTypeData();
    listData();
  }, []);

  const listClassTypeData = async () => {
    try {
      const result = await ClassTypeService(token).list();
      setClassTypeList(result.data || []);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No Class Type Found!');
    }
  };

  const listData = async () => {
    try {
      const result = await PriceSheetService(token).list(classId);
      setPriceSheetList(result.data || []);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No Price Sheet Found!');
    }
  };

  const handleSubmit = async () => {
    if (loading) return;

    setLoading(true);

    try {
      isEdit
        ? await PriceSheetService(token).update({ classTypeId, paymentFrequency, tuition }, classId, editId)
        : await PriceSheetService(token).create({ classTypeId, paymentFrequency, tuition }, classId);
      toast.success(`Saved successfully!`);
      listData();
      setShowPriceSheet(false);
      setIsEdit(false);
      setEditId('');
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Create error!');
      toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  const deleteItemAlert = async (id: string) => {
    return confirmAlert({ title: 'Delete', messageType: 'DELETE', id, onClickYes: deletePriceSheet });
  };

  const deletePriceSheet = async (id: string) => {
    if (loading) return;

    setLoading(true);

    try {
      await PriceSheetService(token).delete(classId, id);
      toast.success(`Deleted successfully!`);
      listData();
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Delete error!');
      toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  const editPriceSheet = async (e: React.FormEvent, p: any) => {
    e.preventDefault();
    setIsEdit(true);
    setEditId(p.id);
    setClassTypeId(p.classTypeId);
    setPaymentFrequency(p.paymentFrequency);
    setTuition(p.tuition);
    setShowPriceSheet(true);
  };

  return (
    <>
      <h6 className="heading-small text-muted mb-4 mt-4">Price Sheet</h6>
      <Row>
        <Col>
          {priceSheetList.map((p: any, i) => (
            <Col key={`price-sheet-${i}`}>
              <Row>
                <Col className="mb-2" lg="4">
                  <FormGroup className="mb-1">
                    <label className="form-control-label">Class Type</label>
                    <Input type="select" disabled>
                      <option value=""></option>
                      {classTypeList.map((a: any, index: number) => {
                        return (
                          <option
                            defaultValue={a.id}
                            key={`class-type-edit-${i}-${index}`}
                            selected={a.id === p.classTypeId}
                          >
                            {a.name}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </Col>
                <Col className="mb-2" lg="4">
                  <FormGroup className="mb-1">
                    <label className="form-control-label">Payment Frequency</label>
                    <Input type="select" disabled>
                      {validPaymentFrequency.map((a: any, index: number) => {
                        return (
                          <option
                            defaultValue={a.id}
                            key={`payment-frequency-edit-${i}-${index}`}
                            selected={a.value === p.paymentFrequency}
                          >
                            {a.label}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </Col>
                <Col className="mb-2" lg="2">
                  <FormGroup className="mb-1">
                    <label className="form-control-label">Tuition</label>
                    <Input
                      type="text"
                      thousandsGroupStyle="thousand"
                      prefix="$"
                      decimalSeparator="."
                      displayType="input"
                      thousandSeparator={true}
                      allowNegative={false}
                      decimalScale={2}
                      tag={NumberFormat}
                      value={p.tuition}
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col lg="2">
                  {' '}
                  <i
                    className="fas fa-edit text-default mt-5"
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => editPriceSheet(e, p)}
                  ></i>{' '}
                  <i
                    className="far fa-trash-alt text-danger mt-5"
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => {
                      e.preventDefault();
                      deleteItemAlert(p.id);
                    }}
                  ></i>
                </Col>
              </Row>
            </Col>
          ))}
          <Col>
            <Button
              size="sm"
              onClick={() => {
                setShowPriceSheet(true);
              }}
            >
              + Add Price
            </Button>
          </Col>
          {showPriceSheet && (
            <Col className="mt-2">
              <Row>
                <Col className="mb-2" lg="4">
                  <FormGroup className="mb-1">
                    <label className="form-control-label">Class Type</label>
                    <Input
                      type="select"
                      defaultValue={classTypeId || ''}
                      onChange={(e) => setClassTypeId(e.target.value)}
                      required
                    >
                      <option value=""></option>
                      {classTypeList.map((a: any, index: number) => {
                        return (
                          <option value={a.id} key={`class-type-${index}`}>
                            {a.name}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </Col>
                <Col className="mb-2" lg="4">
                  <FormGroup className="mb-1">
                    <label className="form-control-label">Payment Frequency</label>
                    <Input
                      type="select"
                      defaultValue={paymentFrequency || 'WEEKLY'}
                      onChange={(e) => setPaymentFrequency(e.target.value)}
                    >
                      {validPaymentFrequency.map((a: any, index: number) => {
                        return (
                          <option value={a.value} key={`payment-frequency-option-${index}`}>
                            {a.label}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </Col>
                <Col className="mb-2" lg="2">
                  <FormGroup className="mb-1">
                    <label className="form-control-label">Tuition</label>
                    <Input
                      type="text"
                      thousandsGroupStyle="thousand"
                      prefix="$"
                      decimalSeparator="."
                      displayType="input"
                      thousandSeparator={true}
                      allowNegative={false}
                      decimalScale={2}
                      tag={NumberFormat}
                      value={tuition || 0}
                      onValueChange={({ value }: any) => setTuition(Number(value))}
                    />
                  </FormGroup>
                </Col>
                <Col lg="12">
                  <Button
                    color="primary"
                    size="sm"
                    style={{ minWidth: '100px' }}
                    onClick={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                  >
                    {isEdit ? 'Edit' : 'Add'}
                  </Button>

                  <Button
                    color="secondary"
                    size="sm"
                    style={{ minWidth: '100px' }}
                    onClick={(e) => {
                      e.preventDefault();
                      setShowPriceSheet(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Col>
          )}
        </Col>
      </Row>
    </>
  );
};

export default ClassPriceSheetList;
