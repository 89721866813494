import { Card, Button, Container, Row, Col, CardBody, FormGroup, Form, Input } from 'reactstrap';
import { useContext, useState, useEffect } from 'react';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import { toast } from 'react-toastify';
import TimePicker from '../../../components/Core/TimezonePicker';
import KidsActivity from '../../../services/KidsActivity';
import ClassService from '../../../services/Class';

type ClassActivityProps = {
  schoolId: string;
  classId: string;
  kidsIds: string[];
  onCloseModal: (clear?: boolean) => void;
};

const ClassActivityForm = ({ schoolId, classId, kidsIds, onCloseModal }: ClassActivityProps) => {
  const { token }: any = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [classList, setClassList] = useState([]);
  const [classMoving, setClassMoving] = useState('');
  const [time, setTime] = useState('');

  useEffect(() => {
    if (!kidsIds.length) {
      toast.warn('Please select the students.');
      onCloseModal();
    }
    listClass();
  }, []);

  const closeModal = () => {
    onCloseModal();
  };

  const listClass = async () => {
    try {
      if (schoolId) {
        const result = await ClassService(token).listBySchool(schoolId);
        setClassList(result.data || []);
      }
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No classes found');
    }
  };

  const handleSubmitForm = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);

    try {
      for (const k of kidsIds) {
        await KidsActivity(token).sendMoving(k, classId, classMoving, time);
      }
      toast.success(`Saved successfully!`);
      onCloseModal(true);
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Create error!');
      toast.error(message);
    }

    setLoading(false);
  };

  return (
    <>
      <Container fluid>
        <Form onSubmit={handleSubmitForm}>
          <Card>
            <CardBody>
              <Row>
                <Col lg="12">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-franchisee-id">
                      Select a Class
                    </label>
                    <Input type="select" onChange={(e) => setClassMoving(e.target.value)} required>
                      <option value=""></option>
                      {classList?.map((c: any, index: number) => {
                        if (c.id === classId) {
                          return;
                        }
                        return (
                          <option value={c.id} key={`class-${index}`}>
                            {c.name}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </Col>
                <Col lg="12">
                  <FormGroup>
                    <label className="form-control-label">Time</label>
                    <TimePicker
                      onChangeTime={(time: string) => {
                        setTime(time);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mt-5">
                <Col md="6 text-center">
                  <Button color="primary" type="submit" size="lg" style={{ minWidth: '100px' }}>
                    Save
                  </Button>
                </Col>
                <Col md="6 text-center">
                  <Button color="secondary" size="lg" style={{ minWidth: '100px' }} onClick={closeModal}>
                    Cancel
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Form>
      </Container>
    </>
  );
};

export default ClassActivityForm;
