export const validStatus = {
  OPEN: { value: 'OPEN', color: 'success' },
  PENDING: { value: 'PENDING', color: 'warning' },
  CLOSED: { value: 'CLOSED', color: 'danger' },
};

export enum statusEnum {
  OPEN = 'OPEN',
  PENDING = 'PENDING',
  CLOSED = 'CLOSED',
}

export interface SupportListInterface {
  id: string;
  department: string;
  priority: string;
  description: string;
  type: string;
  status: string;
  user: any;
  attachment?: string;
  attachmentMimetype?: string;
  createdAt: Date;
  updatedAt: Date;
  requests: any[];
}

export default SupportListInterface;
