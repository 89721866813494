import http from '../helpers/http';

const DiscountsKids = (token: string) => {
  const headers = {
    'x-access-token': token,
  };

  return {
    list: async (kidsId: string) => {
      const result = await http.get(`/kids/${kidsId}/discounts-kids`, {
        headers,
      });
      return result.data;
    },
    create: async (body: { discountsId: string; startDate: string; endDate: string }, kidsId: string) => {
      const result = await http.post(`/kids/${kidsId}/discounts-kids`, body, { headers });
      return result.data;
    },
    update: async (body: { discountsId: string; startDate: string; endDate: string }, kidsId: string, id: string) => {
      const result = await http.put(`/kids/${kidsId}/discounts-kids/${id}`, body, {
        headers,
      });
      return result.data;
    },
    delete: async (kidsId: string, id: string) => {
      const result = await http.delete(`/kids/${kidsId}/discounts-kids/${id}`, {
        headers,
      });
      return result.data;
    },
  };
};

export default DiscountsKids;
