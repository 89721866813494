import { Button, Modal, Table } from 'reactstrap';
import { AvatarComponent } from '../../../../helpers/generateAvatarRamdomColor';
import './styles.css';
import { useContext, useEffect, useState } from 'react';
import { Context as AuthContext } from '../../../../contexts/AuthContext';
import AddUserOnDepartmentModal from '../AddUserOnDepartmentModal';
import RoutingSupportTickets from '../../../../services/RoutingSupportTickets';

type DiscountsModalProps = {
  isOpen: boolean;
  toggleModal: () => void;
};

const DepartmentModal = ({ isOpen, toggleModal }: DiscountsModalProps) => {
  const { token, currentSchool }: any = useContext(AuthContext);

  const [appUsers, setAppUsers] = useState<any[]>([]);
  const [itUsers, setItUsers] = useState<any[]>([]);
  const [merchandiseUsers, setMerchandiseUsers] = useState<any[]>([]);
  const [maintenanceUsers, setMaintenanceUsers] = useState<any[]>([]);
  const [newConstructionUsers, setNewConstructionUsers] = useState<any[]>([]);

  const handleFindRecipients = async () => {
    const result = await RoutingSupportTickets(token).list(currentSchool.value);
    const dataApp = result.data.filter((value: any) => value.department === 'App');
    const dataIT = result.data.filter((value: any) => value.department === 'IT');
    const dataMerchandise = result.data.filter((value: any) => value.department === 'Merchandise');
    const dataMaintenance = result.data.filter((value: any) => value.department === 'Maintenance');
    const dataNewConstruction = result.data.filter((value: any) => value.department === 'New Construction');

    setAppUsers(dataApp);
    setItUsers(dataIT);
    setMerchandiseUsers(dataMerchandise);
    setMaintenanceUsers(dataMaintenance);
    setNewConstructionUsers(dataNewConstruction);
  };

  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const [department, setDepartment] = useState('');

  const handleOpenAddUserModal = (department: string) => {
    setDepartment(department);
    setIsAddUserModalOpen(true);
  };

  const handleRequestCloseAddUserModal = () => {
    setIsAddUserModalOpen(false);
  };

  useEffect(() => {
    handleFindRecipients();
  }, [isAddUserModalOpen]);

  return (
    <>
      <Modal className="modal-dialog-centered" isOpen={isOpen} toggle={() => toggleModal()}>
        <div className="modal-header pb-1">
          <h5 className="modal-title">Routing Support Tickets</h5>
          <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => toggleModal()}>
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body pt-0">
          <Table className="align-items-center table-flush" responsive>
            <tbody>
              <tr>
                <th>Department</th>
                <th>Routes To</th>
              </tr>
              <tr>
                <td>App</td>
                <td className="d-flex">
                  {appUsers.map((data: any) => (
                    <div className="ml-2">
                      <AvatarComponent
                        name={`${data.user.firstName} ${data.user.lastName}`}
                        userPicture={data.user.picture}
                        size="32"
                      />
                    </div>
                  ))}

                  <div className="ml-auto">
                    <Button
                      color="secondary"
                      className="rounded-circle d-flex align-items-center justify-content-center btn-custom"
                      onClick={() => handleOpenAddUserModal('App')}
                    >
                      <i className="fas fa-pencil-alt"></i>
                    </Button>
                  </div>
                </td>
              </tr>
              <tr>
                <td>IT</td>
                <td className="d-flex">
                  {itUsers.map((data: any) => (
                    <div className="ml-2">
                      <AvatarComponent
                        name={`${data.user.firstName} ${data.user.lastName}`}
                        userPicture={data.user.picture}
                        size="32"
                      />
                    </div>
                  ))}
                  <div className="ml-auto">
                    <Button
                      color="secondary"
                      className="rounded-circle d-flex align-items-center justify-content-center btn-custom"
                      onClick={() => handleOpenAddUserModal('IT')}
                    >
                      <i className="fas fa-pencil-alt"></i>
                    </Button>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Merchandise</td>
                <td className="d-flex">
                  {merchandiseUsers.map((data: any) => (
                    <div className="ml-2">
                      <AvatarComponent
                        name={`${data.user.firstName} ${data.user.lastName}`}
                        userPicture={data.user.picture}
                        size="32"
                      />
                    </div>
                  ))}
                  <div className="ml-auto">
                    <Button
                      color="secondary"
                      className="rounded-circle d-flex align-items-center justify-content-center btn-custom"
                      onClick={() => handleOpenAddUserModal('Merchandise')}
                    >
                      <i className="fas fa-pencil-alt"></i>
                    </Button>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Maintenance</td>
                <td className="d-flex">
                  {maintenanceUsers.map((data: any) => (
                    <div className="ml-2">
                      <AvatarComponent
                        name={`${data.user.firstName} ${data.user.lastName}`}
                        userPicture={data.user.picture}
                        size="32"
                      />
                    </div>
                  ))}
                  <div className="ml-auto">
                    <Button
                      color="secondary"
                      className="rounded-circle d-flex align-items-center justify-content-center btn-custom"
                      onClick={() => handleOpenAddUserModal('Maintenance')}
                    >
                      <i className="fas fa-pencil-alt"></i>
                    </Button>
                  </div>
                </td>
              </tr>
              <tr>
                <td>New Construction</td>
                <td className="d-flex">
                  {newConstructionUsers.map((data: any) => (
                    <div className="ml-2">
                      <AvatarComponent
                        name={`${data.user.firstName} ${data.user.lastName}`}
                        userPicture={data.user.picture}
                        size="32"
                      />
                    </div>
                  ))}
                  <div className="ml-auto">
                    <Button
                      color="secondary"
                      className="rounded-circle d-flex align-items-center justify-content-center btn-custom"
                      onClick={() => handleOpenAddUserModal('New Construction')}
                    >
                      <i className="fas fa-pencil-alt"></i>
                    </Button>
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Modal>

      <AddUserOnDepartmentModal
        isOpen={isAddUserModalOpen}
        OnRequestedClose={handleRequestCloseAddUserModal}
        department={department}
      />
    </>
  );
};

export default DepartmentModal;
