import { Table, Row, Col, Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { useContext, useState, useEffect } from 'react';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import EmployeeTitlesService from '../../../services/EmployeeTitles';
import EmployeeDepartmentsService from '../../../services/EmployeeDepartments';
import { toast } from 'react-toastify';
import isAdmin from '../../../helpers/isAdmin';

export enum settingsType {
  TITLE = 'TITLE',
  DEPARTMENT = 'DEPARTMENT',
}

type EmployeeSettingsFormProps = {
  isOpen: boolean;
  type: settingsType;
};

const EmployeeSettingsForm = ({ isOpen, type }: EmployeeSettingsFormProps) => {
  const { token, currentFranchisee, user }: any = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [employeeSettingsName, setEmployeeSettingsName] = useState('');
  const [employeeSettingsList, setEmployeeSettingsList] = useState([] as any[]);
  const canDelete = isAdmin(user);

  useEffect(() => {
    if (isOpen) {
      listData();
    }
  }, [isOpen]);

  const listData = async () => {
    try {
      let result;
      if (type === settingsType.TITLE) {
        result = await EmployeeTitlesService(token).list();
      }
      if (type === settingsType.DEPARTMENT) {
        result = await EmployeeDepartmentsService(token).list();
      }
      setEmployeeSettingsList(result.data || []);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No data Found!');
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);

    try {
      if (type === settingsType.TITLE) {
        await EmployeeTitlesService(token).create({
          franchiseeId: currentFranchisee?.value || '',
          name: employeeSettingsName,
        });
      }
      if (type === settingsType.DEPARTMENT) {
        await EmployeeDepartmentsService(token).create({
          franchiseeId: currentFranchisee?.value || '',
          name: employeeSettingsName,
        });
      }

      toast.success(`Saved successfully!`);
      listData();
      setShowForm(false);
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Create error!');
      toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  const deleteSettings = async (e: React.FormEvent, id: string) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);

    try {
      if (type === settingsType.TITLE) {
        await EmployeeTitlesService(token).delete(id);
      }
      if (type === settingsType.DEPARTMENT) {
        await EmployeeDepartmentsService(token).delete(id);
      }

      toast.success(`Deleted successfully!`);
      listData();
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Delete error!');
      toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Table className="align-items-center table-flush" responsive>
        <tbody>
          <tr>
            <th>Title</th>
            <th>Action</th>
          </tr>
          {employeeSettingsList?.length === 0 && (
            <tr>
              <td colSpan={2} className="text-center">
                Data not found
              </td>
            </tr>
          )}
          {employeeSettingsList.map((s: any) => (
            <tr key={`employeeSettings-modal-${s.id}`}>
              <td>{s.name}</td>
              <td>
                {canDelete && (
                  <i
                    className="far fa-trash-alt text-danger"
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => deleteSettings(e, s.id)}
                  ></i>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Row>
        {!showForm && (
          <Col>
            <Button
              onClick={() => {
                setShowForm(true);
              }}
            >
              New
            </Button>
          </Col>
        )}
        {showForm && (
          <Col>
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col lg="12">
                  <FormGroup>
                    <Label className="form-control-label">Franchisee</Label>
                    <Input value={currentFranchisee?.label || ''} disabled />
                  </FormGroup>
                </Col>
                <Col lg="12">
                  <FormGroup>
                    <label className="form-control-label">Name</label>
                    <Input onChange={(e) => setEmployeeSettingsName(e.target.value)} />
                  </FormGroup>
                </Col>
                <Col md="6 text-center">
                  <Button color="primary" type="submit" size="lg" style={{ minWidth: '100px' }}>
                    Save
                  </Button>
                </Col>
                <Col md="6 text-center">
                  <Button
                    color="secondary"
                    size="lg"
                    style={{ minWidth: '100px' }}
                    onClick={(e) => {
                      e.preventDefault();
                      setShowForm(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        )}
      </Row>
    </>
  );
};

export default EmployeeSettingsForm;
