import { Table, Row, Col, Button, Form, FormGroup, Input } from 'reactstrap';
import { useContext, useState, useEffect, useRef, ChangeEvent } from 'react';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import HireAgreementsService from '../../../services/HireAgreements';
import { toast } from 'react-toastify';
type HireAgreementsFormProps = {
  isOpen: boolean;
};

const HireAgreementsForm = ({ isOpen }: HireAgreementsFormProps) => {
  const { token }: any = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [hireAgreementsName, setHireAgreementsName] = useState('');
  const [hireAgreementsList, setHireAgreementsList] = useState([] as any[]);
  const [selectedFile, setSelectedFile] = useState<any>();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleCustomButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectFile = event.target.files?.[0];
    if (selectFile) {
      setSelectedFile(selectFile);
    }
  };

  useEffect(() => {
    if (isOpen) {
      listData();
    }
  }, [isOpen]);

  const listData = async () => {
    try {
      const result = await HireAgreementsService(token).list();

      setHireAgreementsList(result.data || []);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No data Found!');
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;

    if (!selectedFile) {
      toast.error('File is required');
      return;
    }

    setLoading(true);

    const data = { name: hireAgreementsName };

    try {
      const result = await HireAgreementsService(token).create(data);

      if (selectedFile) {
        const formData = new FormData();
        formData.append('file', selectedFile as any);
        await HireAgreementsService(token).file(result?.data?.id, formData);
      }

      toast.success(`Saved successfully!`);
      listData();
      setHireAgreementsName('');
      setSelectedFile(null);
      setShowForm(false);
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Create error!');
      toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  const deleteSettings = async (e: React.FormEvent, id: string) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);

    try {
      await HireAgreementsService(token).delete(id);

      toast.success(`Deleted successfully!`);
      listData();
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Delete error!');
      toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Table className="align-items-center table-flush" responsive>
        <tbody>
          <tr>
            <th>Name</th>
            <th>Action</th>
          </tr>
          {hireAgreementsList?.length === 0 && (
            <tr>
              <td colSpan={2} className="text-center">
                Data not found
              </td>
            </tr>
          )}
          {hireAgreementsList.map((agreement: any) => (
            <tr key={`hireAgreements-modal-${agreement.id}`}>
              <td>{agreement.name}</td>
              <td>
                <Button
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    background: '#FFFFFF',
                    padding: 0,
                    alignItems: 'center',
                    transition: 'filter 0.3s ease',
                    filter: 'brightness(1)',
                    color: '#416adb',
                  }}
                  onMouseEnter={(e) => (e.currentTarget.style.filter = 'brightness(1.2)')}
                  onMouseLeave={(e) => (e.currentTarget.style.filter = 'brightness(1)')}
                >
                  <a href={agreement.file} target="_blank" className="d-flex flex-column align-items-center">
                    <i className="fas fa-download" />
                  </a>
                </Button>
                <i
                  className="far fa-trash-alt text-danger ml-2"
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => deleteSettings(e, agreement.id)}
                ></i>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Row>
        {!showForm && (
          <Col md="12 text-right" className="mr-4 mt-4">
            <Button
              onClick={() => {
                setShowForm(true);
              }}
              color="primary"
            >
              New
            </Button>
          </Col>
        )}
        {showForm && (
          <Col className="mt-4 ml-4">
            <hr />
            <h2>Create a new Hire Agreement</h2>
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <label className="form-control-label">Name</label>
                    <Input
                      value={hireAgreementsName}
                      onChange={(e) => setHireAgreementsName(e.target.value)}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <div>
                    <h3>Attach File Here</h3>
                    <div className="d-flex align-items-center">
                      <Button color="primary" onClick={handleCustomButtonClick} style={{ width: 160 }}>
                        Select File
                      </Button>
                      <input ref={fileInputRef} type="file" style={{ display: 'none' }} onChange={handleFileChange} />
                      {selectedFile && (
                        <>
                          <p className="p-0 m-0">Selected File: {selectedFile.name} </p>
                          <div onClick={() => setSelectedFile(null)} className="d-flex p-1 cursor-pointer ml-2">
                            <i className="fas fa-times" />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Col>
                <Col md="12 text-right">
                  <Button
                    color="secondary"
                    size="lg"
                    style={{ minWidth: '100px' }}
                    onClick={(e) => {
                      e.preventDefault();
                      setShowForm(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button color="primary" type="submit" size="lg" style={{ minWidth: '100px' }}>
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        )}
      </Row>
    </>
  );
};

export default HireAgreementsForm;
