import http from '../helpers/http';
import { makeQueryParamsForRequest } from '../entities/FilteredOptions';
import MessagingInterface from '../interfaces/MessagingInterface';

const Messaging = (token: string) => {
  const headers = {
    'x-access-token': token,
  };

  return {
    list: async (schoolId: string, filteredOptions?: any, starMessages?: boolean) => {
      const params = makeQueryParamsForRequest();
      params.filteredOptions = filteredOptions;
      params.starMessages = starMessages;

      const result = await http.get(`/messaging/${schoolId}/school`, {
        headers,
        params,
      });

      return result.data;
    },
    listByUser: async (schoolId: string, userId: string, filterSearch?: string, starMessages?: boolean) => {
      const params = makeQueryParamsForRequest();
      params.filterSearch = filterSearch;
      params.starMessages = starMessages;

      const result = await http.get(`/messaging/school/${schoolId}/users/${userId}`, {
        headers,
        params,
      });

      return result.data;
    },
    listByStudent: async (schoolId: string, studentId: string, filterSearch?: string, starMessages?: boolean) => {
      const params = makeQueryParamsForRequest();
      params.filterSearch = filterSearch;
      params.starMessages = starMessages;

      const result = await http.get(`/messaging/school/${schoolId}/kids/${studentId}`, {
        headers,
        params,
      });

      return result.data;
    },
    listLast: async (id: string) => {
      const result = await http.get(`/messaging/${id}/last`, {
        headers,
      });
      return result.data;
    },
    listSelect: async () => {
      const result = await http.get(`/messaging/list/select`, {
        headers,
      });
      return result.data;
    },
    listSelectStaff: async (schoolId: string) => {
      const result = await http.get(`/employees/selectBySchoolId/${schoolId}`, {
        headers,
      });
      return result.data;
    },
    listSelectClass: async (schoolId: string) => {
      const result = await http.get(`/class/selectBySchoolId/${schoolId}`, {
        headers,
      });
      return result.data;
    },
    listSelectKids: async (schoolId: string) => {
      const result = await http.get(`/kids/selectBySchoolId/${schoolId}`, {
        headers,
      });
      return result.data;
    },
    listSelectParents: async (kidsId: string) => {
      const result = await http.get(`/users/selectByKidsId/${kidsId}`, {
        headers,
      });
      return result.data;
    },
    show: async (id: string, page: number = 1) => {
      const params = makeQueryParamsForRequest();
      params.page = page;
      const result = await http.get(`/messaging/${id}`, {
        headers,
        params,
      });
      return result.data;
    },
    create: async (body: Partial<MessagingInterface>) => {
      const result = await http.post(`/messaging`, body, { headers });
      return result.data;
    },
    createReply: async (id: string, body: Partial<MessagingInterface>) => {
      const result = await http.post(`/messaging/${id}`, body, { headers });
      return result.data;
    },
    // update: async (id: string, body: Partial<MessagingInterface>) => {
    //   const result = await http.put(`/messaging/${id}`, body, { headers });
    //   return result.data;
    // },
    createRead: async (id: string, body: Partial<any>) => {
      const result = await http.post(`/messaging/${id}/notifications`, body, { headers });
      return result.data;
    },
    showStars: async (messagingId: string) => {
      const result = await http.get(`/messaging/${messagingId}/stars/status`, {
        headers,
      });
      return result.data;
    },
    deleteStars: async (messagingId: string) => {
      const result = await http.delete(`/messaging/${messagingId}/stars`, {
        headers,
      });
      return result.data;
    },
    createStars: async (messagingId: string, body: Partial<any>) => {
      const result = await http.post(`/messaging/${messagingId}/stars`, body, { headers });
      return result.data;
    },
    // delete: async (id: string) => {
    //   const result = await http.delete(`/messaging/${id}`, {
    //     headers,
    //   });
    //   return result.data;
    // },
    attachment: async (id: string, body: FormData) => {
      const headers = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-access-token': token,
        },
      };
      const result = await http.patch(`/messaging/${id}/attachment`, body, headers);
      return result.data;
    },

    getTotalUnreadMessagesForLoggedUser: async (schoolId: string) => {
      const result = await http.get(`/messaging/total-unread-for-logged-user/${schoolId}`, {
        headers,
      });
      return result.data;
    },
    deleteTalk: async (messagingTalkId: string) => {
      const result = await http.delete(`/messaging/talk/${messagingTalkId}`, {
        headers,
      });
      return result.data;
    },
  };
};

export default Messaging;
