import http from '../helpers/http';

const PromoCode = () => {
  return {
    getCode: async (code: string) => {
      const result = await http.get(`/promo-codes/${code}/public`);
      return result.data;
    },
  };
};

export default PromoCode;
