import { Card, CardBody, Row, Collapse, Button, CardHeader, CardFooter, Col } from 'reactstrap';
import { useContext, useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import KidsService from '../../../services/Kids';
import TimelineService from '../../../services/Timeline';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert';
import ClassActivityConfirm from '../Class/ClassActivityConfirm';
import KidsActivity from '../../../services/KidsActivity';
import { toast } from 'react-toastify';
import isAdmin from '../../../helpers/isAdmin';
import isDirector from '../../../helpers/isDirector';
import isEducationDirectorAdminAssistant from '../../../helpers/isEducationDirectorAdminAssistant';
import isUpperManagment from '../../../helpers/isUpperManagment';

const ProfileRightBar = () => {
  const { id: kidId } = useParams<{ id: string }>();
  const { token, user }: any = useContext(AuthContext);
  const [isCheckedIn, setIsCheckedIn] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [className, setClassName] = useState('');
  const [classTypeName, setClassTypeName] = useState('');
  const [classType, setClassType] = useState('');
  const [parents, setParents] = useState<any[]>([]);
  const [pickupAuthorized, setPickupAuthorized] = useState<any[]>([]);
  const [alergies, setAlergies] = useState('');
  const [schoolName, setSchoolName] = useState('');
  const toggle = () => setIsOpen(!isOpen);
  const canEdit =
    isAdmin(user) || isDirector(user) || isEducationDirectorAdminAssistant(user) || isUpperManagment(user);

  useEffect(() => {
    getById();
  }, []);

  useEffect(() => {
    getById();
  }, [kidId]);

  const getById = async () => {
    try {
      const result = await KidsService(token).show(kidId);
      if (result?.data) {
        const user = result?.data;

        setClassName(result?.data?.enrollment?.class?.name || '');
        setClassTypeName(result?.data?.enrollment?.classType?.name || '');
        setClassType(result?.data?.enrollment?.classType?.type || '');
        setSchoolName(result?.data?.enrollment?.school?.name);

        const parents =
          user?.parents?.filter((parent: any) => parent.title === 'Parent' || parent.title === 'Guardian') || [];
        const pickupAuthorized = user?.parents?.filter((parent: any) => parent.title === 'Pickup Authorized');
        setParents(parents);
        setPickupAuthorized(pickupAuthorized);
        setAlergies(user?.alergyMedical);
      }

      const resultTimeLine = await TimelineService(token).show(kidId);
      if (resultTimeLine?.data) {
        const timeline = resultTimeLine?.data;

        const status = timeline?.find((status: any) => {
          const now = moment().startOf('day').format();
          if (
            (status?.kidsActivity?.key === 'CHECKIN' && moment(status?.time).format() > now) ||
            (status?.kidsActivity?.key === 'CHECKOUT' && moment(status?.time).format() > now)
          ) {
            return status;
          }
        });

        if (status && status?.kidsActivity?.key === 'CHECKIN') {
          setIsCheckedIn(true);
        } else {
          setIsCheckedIn(false);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const confirmActivity = async (e: any, type: string) => {
    e.preventDefault();

    confirmAlert({
      customUI: ({ onClose }) => {
        return <ClassActivityConfirm type={type} sendActivity={sendActivity} onClose={onClose} />;
      },
    });
  };

  const sendActivity = async (type: string) => {
    try {
      if (type === 'CHECKIN') {
        await KidsActivity(token).checkin(kidId);
      }
      if (type === 'CHECKOUT') {
        await KidsActivity(token).checkout(kidId);
      }

      getById();
      toast.success('Saved successfully!');
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'Error');
    }
  };

  return (
    <>
      {alergies && (
        <Card
          className="border-danger mb-3"
          style={{ boxShadow: '0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08)' }}
        >
          <CardHeader className="bg-danger">
            <h2 className="p-0 m-0 text-white text-center">Allergies/medical notes</h2>
          </CardHeader>
          <CardBody className="text-center">
            <Row>
              <Col>
                <strong>{alergies}</strong>
              </Col>
            </Row>
          </CardBody>
        </Card>
      )}
      <Card style={{ boxShadow: '0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08)' }}>
        <CardHeader className="bg-secondary">
          <h3 className="p-0 m-0 text-center">Current Status</h3>
        </CardHeader>
        <CardBody style={{ backgroundColor: isCheckedIn ? 'rgb(212, 255, 225)' : 'rgb(246, 206, 216)' }}>
          <h4 className="p-0 m-0 text-center">{isCheckedIn ? 'Checked In' : 'Checked Out'}</h4>
        </CardBody>
        {canEdit && (
          <CardFooter className="p-0">
            <button
              className="btn btn-block btn-secondary font-weight-bold ac-check"
              onClick={(event) => confirmActivity(event, isCheckedIn ? 'CHECKOUT' : 'CHECKIN')}
              style={{ border: 0, width: '100%', borderTopLeftRadius: '0', borderTopRightRadius: '0' }}
            >
              <span className="h3 text-center mx-auto">
                <i
                  className={`far ${
                    !isCheckedIn ? 'fa-arrow-alt-circle-right text-success' : 'fa-arrow-alt-circle-left text-danger'
                  } mr-1 fa-xl align-baseline font-size-22`}
                />{' '}
                <div>Click here to {!isCheckedIn ? 'Check In' : 'Check Out'}</div>
              </span>
            </button>
          </CardFooter>
        )}
      </Card>

      <Card className="mt-3" style={{ boxShadow: '0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08)' }}>
        <CardHeader className="bg-secondary">
          <h3 className="p-0 m-0 text-center">Classroom and type</h3>
        </CardHeader>
        <CardBody>
          <Row>
            <Col>
              <h4>
                School: <span className="font-weight-300">{schoolName || '-'}</span>
              </h4>
              <h4>
                Class: <span className="font-weight-300">{className || '-'}</span>
              </h4>
              <h4>
                Type:{' '}
                <span className="font-weight-300">
                  {classTypeName} {classType ? `(${classType})` : '-'}
                </span>
              </h4>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card className="mt-3" style={{ boxShadow: '0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08)' }}>
        <CardHeader className="bg-secondary">
          <h3 className="p-0 m-0 text-center">Family Info</h3>
        </CardHeader>
        <CardBody>
          <Row>
            <Col>
              <ul style={{ listStyle: 'none' }} className="p-0 m-0">
                {parents.map((parent, i) => (
                  <div key={`parent-${parent.id}-${i}`}>
                    <li style={{ padding: '1px 0' }}>
                      <h4 className="text-primary">
                        {parent?.user?.firstName} {parent?.user?.lastName}
                      </h4>
                      <h4>
                        Type: <span className="font-weight-300">{parent?.title || '-'}</span>
                      </h4>
                      <h4>
                        Phone: <span className="font-weight-300">{parent?.user?.phone || 'Unregistered phone'}</span>
                      </h4>
                      <h4>
                        Email: <span className="font-weight-300">{parent?.user?.email || '-'}</span>
                      </h4>
                    </li>
                    <hr className="p-0" style={{ margin: '15px 0' }} />
                  </div>
                ))}
              </ul>
            </Col>
          </Row>
        </CardBody>
      </Card>

      {canEdit && (
        <Card
          className="mt-3 pl-2"
          style={{ boxShadow: '0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08)' }}
        >
          <Button
            color="secondary"
            className="d-flex align-items-center justify-content-center text-primary m-0"
            onClick={toggle}
          >
            Authorized pickup{' '}
            {isOpen ? <i className="fas fa-chevron-down ml-2"></i> : <i className="fas fa-chevron-right ml-2"></i>}
          </Button>
          <CardBody>
            <Collapse isOpen={isOpen}>
              <Row>
                <Col>
                  {pickupAuthorized.map((pickupAuthorized) => (
                    <>
                      <div key={pickupAuthorized?.id}>
                        <h4 className="text-primary">
                          {pickupAuthorized?.user?.firstName} {pickupAuthorized?.user?.lastName}
                        </h4>
                        <h4>
                          Phone:{' '}
                          <span className="font-weight-300">
                            {pickupAuthorized?.user?.phone || 'Unregistered phone'}
                          </span>
                        </h4>
                        <h4>
                          Email: <span className="font-weight-300">{pickupAuthorized?.user?.email}</span>
                        </h4>
                      </div>
                      <hr className="mb-0 mt-2" />
                    </>
                  ))}
                </Col>
              </Row>
            </Collapse>
          </CardBody>
        </Card>
      )}
      <div className="mt-4 ml-3">
        <Row>
          {canEdit && (
            <>
              <Link to={`/kids/${kidId}`} className="mr-2 mb-2">
                <button className="btn btn-primary">Edit Student</button>
              </Link>
              <Link to={`/billing/${kidId}`} className="mr-2 mb-2">
                <button className="btn btn-success">Billing</button>
              </Link>
            </>
          )}

          {/* <button className="btn btn-info mb-2">Messages</button> */}
        </Row>
      </div>
    </>
  );
};

export default ProfileRightBar;
