/* eslint-disable max-lines */
import { Card, Button, Container, Row, Col, CardBody, Modal, Input, Form, FormGroup, Table } from 'reactstrap';
import { useContext, useState, useRef, useEffect } from 'react';
import { Context as AuthContext } from '../../../../contexts/AuthContext';
import EnrollmentFilesType from '../../../../services/EnrollmentFilesType';
import { toast } from 'react-toastify';
import EnrollmentFiles from '../../../../services/EnrollmentFiles';
import { useParams } from 'react-router-dom';
import isAdmin from '../../../../helpers/isAdmin';
import { isMobile } from 'react-device-detect';

const FileTab = ({ forceKidId = '' }: { forceKidId?: string }) => {
  const { id: kidId } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(false);
  const { token, user }: any = useContext(AuthContext);
  const fileRef = useRef(null as any);

  const [enrollmentFileTypeForm, setEnrollmentFileTypeForm] = useState(false);
  const [enrollmentFileForm, setEnrollmentFileForm] = useState(false);
  const [file, setFile] = useState(null as any);
  const [selectedFileType, setSelectedFileType] = useState('');
  const [showForm, setShowForm] = useState(false);

  const [fileTypes, setFileTypes] = useState<any[]>([]);
  const [files, setFiles] = useState<any[]>([]);
  const [fileTypeName, setFileTypeName] = useState('');
  const isAdminUser = isAdmin(user);

  useEffect(() => {
    getEnrollmentFilesType();
    getEnrollmentFiles();
  }, []);

  useEffect(() => {
    getEnrollmentFilesType();
    getEnrollmentFiles();
  }, [kidId, forceKidId]);

  const getEnrollmentFilesType = async () => {
    const result = await EnrollmentFilesType(token).list();

    if (result?.data) {
      const enrollmentFileTypes = result?.data;
      setFileTypes(enrollmentFileTypes);
    }
  };

  const handleSubmitFileType = async (event: React.FormEvent) => {
    event.preventDefault();
    if (loading) return;

    setLoading(true);

    const data = {
      name: fileTypeName,
    };

    try {
      await EnrollmentFilesType(token).create(data);

      toast.success(`Saved successfully!`);

      getEnrollmentFilesType();
      setShowForm(false);
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Create error!');
      toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteEnrollmentFileType = async (e: React.FormEvent, id: string) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);

    try {
      await EnrollmentFilesType(token).delete(id);
      toast.success(`Deleted successfully!`);
      getEnrollmentFilesType();
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Delete error!');
      toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  const getEnrollmentFiles = async () => {
    const result = await EnrollmentFiles(token).list(kidId || forceKidId);

    if (result?.data) {
      const enrollmentFileTypes = result?.data;
      setFiles(enrollmentFileTypes);
    }
  };

  const handleSubmitFile = async (event: React.FormEvent) => {
    event.preventDefault();
    if (loading) return;

    setLoading(true);

    const data = {
      typeId: selectedFileType,
    };

    try {
      if (!file) {
        toast.error('File is required');
        return;
      }
      const result = await EnrollmentFiles(token).create(data, kidId || forceKidId);

      const formData = new FormData();
      formData.append('file', file as any);
      await EnrollmentFiles(token).file(result?.data?.id, formData);

      toast.success(`Saved successfully!`);

      getEnrollmentFiles();
      toggleEnrollmentFileForm();
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Create error!');
      toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteEnrollmentFile = async (e: React.FormEvent, id: string) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);

    try {
      await EnrollmentFiles(token).delete(id);
      toast.success(`Deleted successfully!`);
      getEnrollmentFiles();
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Delete error!');
      toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  const showOpenFileDialog = () => {
    if (fileRef?.current?.click) {
      fileRef.current.click();
    }
  };

  const onChangeFile = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];
    setFile(file);
  };

  const toggleEnrollmentFileTypeForm = () => {
    setEnrollmentFileTypeForm(!enrollmentFileTypeForm);
  };

  const toggleEnrollmentFileForm = () => {
    setEnrollmentFileForm(!enrollmentFileForm);
  };

  return (
    <>
      <Row>
        <Col>
          <h6 className="heading-small text-muted mb-4">Files</h6>
        </Col>
        <Col className="text-right items-center d-flex justify-content-end" xs="4">
          {isAdminUser && (
            <>
              <i
                className="fas fa-cog fa-lg mr-3 mt-3"
                style={{ cursor: 'pointer' }}
                onClick={toggleEnrollmentFileTypeForm}
              ></i>
              {!isMobile && (
                <Button
                  size="sm"
                  className="float-right px-3"
                  onClick={() => {
                    toggleEnrollmentFileForm();
                  }}
                >
                  + Files
                </Button>
              )}
            </>
          )}
        </Col>
      </Row>

      {files.map((value, i) => (
        <Row className={isMobile ? 'mt-2' : 'mt-4'} key={`files-${i}-${value.id}`}>
          <Col>
            <Card className={`${!isMobile ? 'p-4' : 'pl-2 pt-2'} d-flex`}>
              <h2 className="d-flex justify-content-between">
                {!isMobile && (
                  <>
                    {value.name}
                    <span>
                      <a href={`/view-pdf/${value.idToOpen}?back=documents`} className="mr-3">
                        <i className="fas fa-eye text-primary" />
                      </a>
                      {isAdminUser && (
                        <>
                          <a target="_blank" href={value.file}>
                            <i className="fas fa-download text-yellow mr-3"></i>
                          </a>
                          <i
                            className="far fa-trash-alt text-danger"
                            style={{ cursor: 'pointer' }}
                            onClick={(event) => handleDeleteEnrollmentFile(event, value.id)}
                          ></i>
                        </>
                      )}
                    </span>
                  </>
                )}
                {isMobile && (
                  <a href={`/view-pdf/${value.idToOpen}?back=documents`} className="mr-3">
                    {value.name}
                  </a>
                )}
              </h2>
            </Card>
          </Col>
        </Row>
      ))}

      <Modal className="modal-dialog-centered" isOpen={enrollmentFileForm} toggle={() => toggleEnrollmentFileForm()}>
        <div className="modal-header">
          <h5 className="modal-title" id="Label">
            New File
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => toggleEnrollmentFileForm()}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Container fluid>
            <Form onSubmit={(event: React.FormEvent) => handleSubmitFile(event)}>
              <Card>
                <CardBody>
                  <Row>
                    <Col lg="12">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="input-gender">
                          File type
                        </label>
                        <Input
                          type="select"
                          onChange={(e) => setSelectedFileType(e.target.value)}
                          value={selectedFileType}
                          required
                        >
                          <option value="" disabled></option>
                          {fileTypes.map((value) => (
                            <option value={value.id} key={`option-${value.id}`}>
                              {value.name}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12">
                      <Button
                        className="btn-icon btn-sm"
                        color="secondary"
                        type="button"
                        id="input-attach"
                        onClick={showOpenFileDialog}
                        style={{ marginBottom: '2px' }}
                      >
                        <span className="btn-inner--icon pt-1" style={{ marginRight: '1px' }}>
                          <i className="fas fa-paperclip" style={{ top: '0' }} />
                        </span>
                      </Button>
                      <span>{file?.name || 'Select a file'}</span>
                      <input
                        id="img-attach"
                        name="picture"
                        type="file"
                        accept=".png,.jpg,.jpeg,.pdf"
                        style={{ display: 'none' }}
                        ref={fileRef}
                        onChange={onChangeFile}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-5">
                    <Col className="text-center">
                      <Button
                        color="secondary"
                        size="lg"
                        style={{ minWidth: '100px' }}
                        onClick={toggleEnrollmentFileTypeForm}
                      >
                        Cancel
                      </Button>
                    </Col>
                    <Col className="text-center">
                      <Button color="primary" type="submit" size="lg" style={{ minWidth: '100px' }}>
                        Save
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Form>
          </Container>
        </div>
      </Modal>

      <Modal
        className="modal-dialog-centered"
        isOpen={enrollmentFileTypeForm}
        toggle={() => toggleEnrollmentFileTypeForm()}
      >
        <div className="modal-header pb-1">
          <h5 className="modal-title">Edit File types</h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => toggleEnrollmentFileTypeForm()}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body pt-0">
          <Table className="align-items-center table-flush" responsive>
            <tbody>
              <tr>
                <th>File Type</th>
                <th>Action</th>
              </tr>
              {fileTypes?.length === 0 && (
                <tr>
                  <td colSpan={3} className="text-center">
                    Data not found
                  </td>
                </tr>
              )}
              {fileTypes.map((value, i) => (
                <tr key={`files-${i}-${value.id}`}>
                  <td>{value.name}</td>
                  <td>
                    <i
                      className="far fa-trash-alt text-danger"
                      style={{ cursor: 'pointer' }}
                      onClick={(event) => handleDeleteEnrollmentFileType(event, value.id)}
                    ></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Row>
            {!showForm && (
              <Col>
                <Button
                  onClick={() => {
                    setShowForm(true);
                  }}
                >
                  New
                </Button>
              </Col>
            )}
            {showForm && (
              <Col>
                <Form onSubmit={() => {}}>
                  <Row>
                    <Col lg="12">
                      <FormGroup>
                        <label className="form-control-label">Name</label>
                        <Input value={fileTypeName} onChange={(event) => setFileTypeName(event.target.value)} />
                      </FormGroup>
                    </Col>
                    <Col md="6 text-center">
                      <Button
                        color="primary"
                        type="submit"
                        size="lg"
                        style={{ minWidth: '100px' }}
                        onClick={(event) => handleSubmitFileType(event)}
                      >
                        Save
                      </Button>
                    </Col>
                    <Col md="6 text-center">
                      <Button
                        color="secondary"
                        size="lg"
                        style={{ minWidth: '100px' }}
                        onClick={(e) => {
                          e.preventDefault();
                          setShowForm(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            )}
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default FileTab;
