export const validStatus = {
  ACTIVE: { value: 'ACTIVE', color: 'success' },
  BLOCKED: { value: 'BLOCKED', color: 'danger' },
};

export enum statusEnum {
  ACTIVE = 'ACTIVE',
  BLOCKED = 'BLOCKED',
}

export interface FranchiseeInterface {
  id: string;
  name: string;
  obs: string;
  status: statusEnum;
  value?: string;
  label?: string;
  createdAt: Date;
  updatedAt: Date;
}

export default FranchiseeInterface;
