/* eslint-disable max-lines */
import {
  Card,
  Button,
  Container,
  Row,
  Col,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  CardTitle,
} from 'reactstrap';
import { useContext, useState, useEffect } from 'react';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import { toast } from 'react-toastify';
import TourService from '../../../services/Tour';
import UserService from '../../../services/User';
import { toLocaleFormatSlash } from '../../../helpers/dateFormat';
import ReactDatetime from 'react-datetime';
import { confirmAlert } from 'react-confirm-alert';
import TimePicker from '../../../components/Core/TimePicker';
import ChildForm from './ChildForm';
import moment from 'moment';
import InputMask from 'react-input-mask';
import isAuthorized from '../../../helpers/isAuthorized';

type scheduleProps = {
  isOpen: boolean;
  scheduleId: string;
  onModalStatus: () => void;
  dateClick: string;
  onCreateSchedule: () => void;
};

const ScheduleForm = ({ isOpen, scheduleId, onModalStatus, dateClick, onCreateSchedule }: scheduleProps) => {
  const { token, currentSchool, user }: any = useContext(AuthContext);
  const [isDisabled, setIsDisabled] = useState(false);
  const [dateTourPlugin, setDateTourPlugin] = useState(dateClick);
  const [loading, setLoading] = useState(false);
  const [tourUsers, setTourUsers] = useState([] as any[]);

  const [dateTour, setDateTour] = useState('');
  const [timeTour, setTimeTour] = useState(undefined as undefined | string);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [aboutUs, setAboutUs] = useState('');
  const [typeTour, setTypeTour] = useState('');
  const [typeSchedule, setTypeSchedule] = useState('');
  const [touredIn, setTouredIn] = useState('');
  const [touredBy, setTouredBy] = useState('');
  const [observations, setObservations] = useState('');
  const [schoolId, setSchoolId] = useState(currentSchool.value);

  const [kids, setKids] = useState([
    { firstName: '', lastName: '', birthday: '', potentialDate: '', tourStatus: '' },
  ] as {
    id?: string;
    firstName: string;
    lastName: string;
    birthday: string;
    potentialDate: string | null;
    tourStatus: string;
  }[]);

  useEffect(() => {
    setSchoolId(currentSchool.value);
    if (isOpen) {
      listTourUsers(currentSchool.value);
    }
  }, [isOpen, currentSchool]);

  const listTourUsers = async (schoolId: string) => {
    try {
      const result = await UserService(token).listTourUsers(schoolId);
      setTourUsers(result?.data || []);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No user roles groups found');
    }
  };

  const setChildFirstName = (value: string, index: number) => {
    const newKids = [...kids];
    newKids.forEach((k, i) => {
      if (i == index) {
        k.firstName = value;
      }
    });
    setKids(newKids);
  };

  const setChildLastName = (value: string, index: number) => {
    const newKids = [...kids];
    newKids.forEach((k, i) => {
      if (i == index) {
        k.lastName = value;
      }
    });
    setKids(newKids);
  };

  const setChildBirthday = (value: string, index: number) => {
    const newKids = [...kids];
    newKids.forEach((k, i) => {
      if (i == index) {
        k.birthday = toLocaleFormatSlash(value);
      }
    });
    setKids(newKids);
  };

  const setPotentialDate = (value: string, index: number) => {
    const newKids = [...kids];
    newKids.forEach((k, i) => {
      if (i == index) {
        k.potentialDate = toLocaleFormatSlash(value);
      }
    });
    setKids(newKids);
  };

  const setTourStatus = (value: string, index: number) => {
    const newKids = [...kids];
    newKids.forEach((k, i) => {
      if (i == index) {
        k.tourStatus = value;
      }
    });
    setKids(newKids);
  };

  const addKids = () => {
    const newKids = [...kids];
    newKids.push({ firstName: '', lastName: '', birthday: '', potentialDate: '', tourStatus: '' });
    setKids(newKids);
  };

  const deleteKids = (index: number) => {
    const newKids = kids.filter((_, i) => index !== i);
    setKids(newKids);
  };

  const changeonModalStatus = () => {
    onModalStatus();
  };

  useEffect(() => {
    showEvent();
  }, []);

  const showEvent = async () => {
    try {
      if (scheduleId && token) {
        const result: any = await TourService(token || '').show(scheduleId);
        if (result?.data) {
          setDateTourPlugin(result?.data?.dateTour || '');
          setDateTour(result?.data?.dateTour ? toLocaleFormatSlash(result?.data?.dateTour) : '');
          setTimeTour(result?.data?.timeTour ? `${result?.data?.dateTour} ${result?.data?.timeTour}` : undefined);
          setFirstName(result?.data?.firstName || '');
          setLastName(result?.data?.lastName || '');
          setEmail(result?.data?.email || '');
          setPhone(result?.data?.phone || '');
          setAboutUs(result?.data?.aboutUs || '');
          setTypeTour(result?.data?.typeTour || '');
          setTypeSchedule(result?.data?.typeSchedule || '');
          setTouredIn(result?.data?.touredIn || '');
          setTouredBy(result?.data?.touredById || '');
          setObservations(result?.data?.observations || '');
          if (result?.data?.kids?.length) {
            const scheduleKids = [];
            for (const sk of result?.data?.kids) {
              if (sk?.id)
                scheduleKids.push({
                  id: sk?.id,
                  firstName: sk?.firstName,
                  lastName: sk?.lastName,
                  birthday: moment(sk?.birthday).format('MM/DD/YYYY'),
                  potentialDate: moment(sk?.potentialStartDate).format('MM/DD/YYYY'),
                  tourStatus: sk?.status,
                });
            }
            setKids(scheduleKids);
          }
        }
      }
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No schedule found');
    }
  };

  const handleSubmitForm = async (e: React.FormEvent) => {
    e?.preventDefault();
    if (loading) return;

    setLoading(true);
    setIsDisabled(true);

    const timeTourMoment = moment(timeTour);
    const newKids = [];
    for (const k of kids) {
      const obj: any = {
        ...k,
        status: k?.tourStatus,
        potentialStartDate: k?.potentialDate && k?.potentialDate !== 'Invalid date' ? k?.potentialDate : null,
      };
      delete obj?.tourStatus;
      delete obj?.potentialDate;
      newKids?.push(obj);
    }
    const body = {
      dateTour: dateTour ? toLocaleFormatSlash(dateTour) : dateClick || '',
      timeTour: timeTourMoment?.format('HH:mm:ss'),
      firstName,
      lastName,
      email,
      phone,
      aboutUs,
      typeTour,
      typeSchedule,
      touredIn,
      observations,
      schoolId,
      touredById: touredBy,
      kids: newKids,
    };

    try {
      let result: any;
      if (scheduleId) {
        result = await TourService(token || '').update(scheduleId, body);
      } else {
        result = await TourService(token || '').create(body);
      }
      toast.success(`${scheduleId ? 'Updated' : 'Created'} successfully!`);
      if (result?.data?.id) {
        onModalStatus();
        onCreateSchedule();
      }
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Create error!');
      toast.error(message);
    }
    setLoading(false);
    setIsDisabled(false);
  };

  const deleteItemAlert = async (id: string, title: string) => {
    changeonModalStatus();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Container fluid>
            <Card>
              <CardBody>
                <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                  Delete
                </CardTitle>
                <Row className="mt-3">
                  <Col md="12 text-center">
                    <span className="h2 font-weight-bold mb-0">{`Are you sure to delete item: ${title}?`}</span>
                  </Col>
                </Row>
                <Row className="mt-5">
                  <Col md="6 text-center">
                    <Button
                      color="primary"
                      href="#"
                      onClick={() => {
                        deleteItem(id);
                        onClose();
                      }}
                      size="lg"
                      style={{ minWidth: '100px' }}
                    >
                      Yes
                    </Button>
                  </Col>
                  <Col md="6 text-center">
                    <Button color="danger" href="#" onClick={onClose} size="lg" style={{ minWidth: '100px' }}>
                      No
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Container>
        );
      },
    });
  };

  const deleteItem = async (id: string) => {
    try {
      await TourService(token || '').delete(id);
      toast.success(`Deleted successfully!`);
      onCreateSchedule();
    } catch (e: any) {
      const message = String(e?.response?.data?.message || `Error deleting item: ${id}`);
      return toast.error(message);
    }
  };

  return (
    <>
      <Container fluid>
        <Form onSubmit={handleSubmitForm}>
          <Card>
            <CardBody>
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-dateTour">
                      Tour Date
                    </label>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-calendar-grid-58" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <ReactDatetime
                        inputProps={{
                          placeholder: 'Tour Date',
                        }}
                        onChange={(newValue) => {
                          setDateTourPlugin(`${newValue}`);
                          setDateTour(`${newValue}`);
                        }}
                        timeFormat={false}
                        dateFormat={'MM/DD/yyyy'}
                        closeOnSelect={true}
                        value={dateTourPlugin ? toLocaleFormatSlash(dateTourPlugin) : dateTour}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <label className="form-control-label"> Tour Time </label>
                    <TimePicker
                      defaultValue={timeTour}
                      onChangeTime={(timeTour: string) => {
                        setTimeTour(timeTour);
                      }}
                    />
                  </FormGroup>
                  {/* </FormGroup> */}
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-firstName">
                      First Name
                    </label>
                    <Input
                      className="form-control-alternative"
                      id="input-firstName"
                      placeholder="First Name"
                      type="text"
                      disabled={isDisabled}
                      value={firstName}
                      onChange={(e) => setFirstName(e?.target?.value)}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-lastName">
                      Last Name
                    </label>
                    <Input
                      className="form-control-alternative"
                      id="input-lastName"
                      placeholder="Last Name"
                      type="text"
                      disabled={isDisabled}
                      value={lastName}
                      onChange={(e) => setLastName(e?.target?.value)}
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-email">
                      E-mail
                    </label>
                    <Input
                      className="form-control-alternative"
                      id="input-email"
                      placeholder="E-mail"
                      type="text"
                      disabled={isDisabled}
                      value={email}
                      onChange={(e) => setEmail(e?.target?.value)}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col lg="12">
                  <FormGroup>
                    <label className="form-control-label">Phone Number</label>
                    <Input
                      className="form-control-alternative"
                      placeholder="Phone Number"
                      type="text"
                      mask="(999) 999-9999"
                      tag={InputMask}
                      disabled={isDisabled}
                      value={phone}
                      onChange={(e) => setPhone(e?.target?.value)}
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col lg="6">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-aboutUs">
                      About Us
                    </label>

                    <select
                      name="input-aboutUs"
                      id="input-aboutUs"
                      className="form-control"
                      disabled={isDisabled}
                      value={aboutUs}
                      onChange={(e) => setAboutUs(e?.target?.value)}
                      required
                    >
                      <option value="">Please select</option>
                      <option value="Co-Worker">Co-Worker</option>
                      <option value="Drive By">Drive By</option>
                      <option value="Family Referral">Family Referral</option>
                      <option value="Friend">Friend</option>
                      <option value="Online">Online</option>
                      <option value="Other">Other</option>
                    </select>
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-typeTour">
                      Tour Type
                    </label>

                    <select
                      name="input-typeTour"
                      id="input-typeTour"
                      className="form-control"
                      disabled={isDisabled}
                      value={typeTour}
                      onChange={(e) => setTypeTour(e?.target?.value)}
                      required
                    >
                      <option value="">Please select</option>
                      <option value="In Person">In Person</option>
                      <option value="Virtual">Virtual</option>
                    </select>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Card>
                  <CardBody>
                    <CardTitle tag="h5" className="text-uppercase text-muted mb-2">
                      Childs information
                    </CardTitle>

                    {kids.map((child, index: number) => (
                      <ChildForm
                        key={`child-${index}`}
                        firstName={child?.firstName || ''}
                        lastName={child?.lastName || ''}
                        birthday={child?.birthday || ''}
                        potentialDate={child?.potentialDate || ''}
                        tourStatus={child?.tourStatus || ''}
                        index={index}
                        setFirstName={setChildFirstName}
                        setLastName={setChildLastName}
                        setBirthday={setChildBirthday}
                        setPotentialDate={setPotentialDate}
                        setTourStatus={setTourStatus}
                        deleteChild={deleteKids}
                      />
                    ))}
                    <Row>
                      <Col>
                        <Button color="info" size="sm" className="mt-2" onClick={addKids}>
                          + Add Child
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Row>

              <Row className="mt-2">
                <Col lg="6">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-typeSchedule">
                      Schedule Type
                    </label>

                    <select
                      name="input-typeSchedule"
                      id="input-typeSchedule"
                      className="form-control"
                      disabled={isDisabled}
                      value={typeSchedule}
                      onChange={(e) => setTypeSchedule(e?.target?.value)}
                    >
                      <option value="">Please select</option>
                      <option value="By Phone">By Phone</option>
                      <option value="Online">Online</option>
                      <option value="Walk In">Walk In</option>
                    </select>
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-touredIn">
                      Toured In
                    </label>

                    <select
                      name="input-touredIn"
                      id="input-touredIn"
                      className="form-control"
                      disabled={isDisabled}
                      value={touredIn}
                      onChange={(e) => setTouredIn(e?.target?.value)}
                    >
                      <option value="">Please select</option>
                      <option value="No Show">No Show</option>
                      <option value="15m">15 Minutes</option>
                      <option value="30m">30 Minutes</option>
                      <option value="45m">45 Minutes</option>
                      <option value="60m">60 Minutes</option>
                    </select>
                  </FormGroup>
                </Col>
                <Col lg="12">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-typeTour">
                      Toured By
                    </label>

                    <select
                      name="input-type-toured-by"
                      id="input-type-toured-by"
                      className="form-control"
                      disabled={isDisabled}
                      value={touredBy}
                      onChange={(e) => setTouredBy(e?.target?.value)}
                    >
                      <option value="">Please select</option>
                      {tourUsers?.map((a: any, index: number) => (
                        <option value={a?.id} key={`tour-user-${index}`}>
                          {`${a?.firstName} ${a?.lastName}`}
                        </option>
                      ))}
                    </select>
                  </FormGroup>
                </Col>
                <Col lg="12">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-observations">
                      Notes
                    </label>
                    <Input
                      className="form-control-observations"
                      id="input-observations"
                      placeholder="Notes"
                      type="textarea"
                      disabled={isDisabled}
                      value={observations}
                      onChange={(e) => setObservations(e?.target?.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mt-5">
                <Col md="4 text-center">
                  <Button color="primary" type="submit" size="lg" style={{ minWidth: '100px' }}>
                    Save
                  </Button>
                </Col>
                <Col md="4 text-center">
                  {isAuthorized({ moduleName: 'Schedule', functionName: 'Delete' }, user) && (
                    <>
                      {scheduleId ? (
                        <Button
                          onClick={() => {
                            deleteItemAlert(scheduleId, firstName + ' ' + lastName);
                          }}
                          color="danger"
                          className="float-right"
                          size="lg"
                          style={{ minWidth: '100px' }}
                        >
                          Delete
                        </Button>
                      ) : (
                        ''
                      )}
                    </>
                  )}
                </Col>
                <Col md="4 text-center">
                  <Button color="secondary" size="lg" style={{ minWidth: '100px' }} onClick={changeonModalStatus}>
                    Cancel
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Form>
      </Container>
    </>
  );
};

export default ScheduleForm;
