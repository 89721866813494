import { useState } from 'react';
import ScheduleCalendarComponent from './ScheduleCalendarComponent';

type scheduleCalendarProps = {
  onClickDay: (date: string) => void;
};

const ScheduleCalendar = ({ onClickDay }: scheduleCalendarProps) => {
  const [data] = useState([]);
  const [updateEventList, setUpdateEventList] = useState(false);

  const handleScheduleCreated = () => {
    setUpdateEventList(!updateEventList);
  };

  return (
    <>
      <ScheduleCalendarComponent events={data} updateEventList={handleScheduleCreated} onClickDay={onClickDay} />
    </>
  );
};

export default ScheduleCalendar;
