import { Card, CardHeader, CardText, Container, Row, Col, CardBody } from 'reactstrap';
import { useContext, useState, useEffect } from 'react';
import { Context as AuthContext } from '../../../../contexts/AuthContext';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { toLocaleFormatSlash } from '../../../../helpers/dateFormat';
import ImmunizationKidsService from '../../../../services/ImmunizationKids';
import ImmunizationKidsInterface from '../../../../interfaces/ImmunizationKidsInterface';
import KidsService from '../../../../services/Kids';
import { isMobile } from 'react-device-detect';

const MedicalTab = () => {
  const { token }: any = useContext(AuthContext);
  const [data, setData] = useState([] as any[]);
  const { id } = useParams<{ id: string }>();

  const [alergyMedical, setAlergyMedical] = useState('');
  const [prescriptions, setPrescriptions] = useState('');
  const [diaperCream, setDiaperCream] = useState('');
  const [sunscreen, setSunscreen] = useState('');
  const [doctor, setDoctor] = useState('');
  const [dentist, setDentist] = useState('');
  const [health, setHealth] = useState('');
  const [insurance, setInsurance] = useState('');

  const getImmunizationsByKidsId = async () => {
    try {
      const resultImunization = await ImmunizationKidsService(token || '').list(id);
      const data: ImmunizationKidsInterface[] = resultImunization.data;

      const result = await KidsService(token).show(id);
      if (result?.data) {
        setAlergyMedical(result?.data?.alergyMedical);
        setPrescriptions(result?.data?.prescriptions);
        setDiaperCream(result?.data?.diaperCream);
        setSunscreen(result?.data?.sunscreen);
        setDoctor(result?.data?.doctor);
        setDentist(result?.data?.dentist);
        setHealth(result?.data?.health);
        setInsurance(result?.data?.insurance);
      }

      setData(data);
    } catch (e: any) {
      toast.error('Error: ' + id);
    }
  };

  useEffect(() => {
    getImmunizationsByKidsId();
  }, [id]);

  return (
    <>
      <Row>
        <Col>
          <h6 className="heading-small text-muted ml-2">Immunizations</h6>
        </Col>
      </Row>

      <Container fluid>
        {data.map((r, index) => {
          return (
            <Row className="align-items-center mt-1" key={`item-${index}`}>
              <Col className="order-xl-2" xl="12" style={isMobile ? { paddingLeft: 5, paddingRight: 5 } : {}}>
                <Card>
                  <CardHeader style={{ paddingBottom: '5px' }}>Type: {r?.immunization?.name || ''}</CardHeader>
                  <CardBody>
                    <CardText>
                      <Row>
                        <Col>
                          <Row>
                            <Col>Valid At: {toLocaleFormatSlash(r.validAt)}</Col>
                          </Row>
                          <Row>
                            <Col>Created At: {toLocaleFormatSlash(r.createdAt)}</Col>
                          </Row>
                          <Row>
                            <Col>
                              {r?.picture && r?.pictureMimetype && (
                                <Col xs="12" className="mt-3 mb-2">
                                  <i className="fas fa-paperclip" />{' '}
                                  <a href={r.picture} target="_blank">
                                    View File
                                  </a>
                                </Col>
                              )}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          );
        })}
      </Container>

      <Row>
        <Col>
          <h6 className="heading-small text-muted ml-2 mt-4">Records</h6>
        </Col>
      </Row>

      <Container fluid>
        <Row className="align-items-center mt-3">
          <Col className="order-xl-2" xl="12" style={isMobile ? { paddingLeft: 5, paddingRight: 5 } : {}}>
            {alergyMedical && (
              <Card>
                <CardHeader style={{ paddingBottom: '5px' }}>Allergy Medical</CardHeader>
                <CardBody>
                  <CardText>
                    <Row>
                      <Col>{alergyMedical}</Col>
                    </Row>
                  </CardText>
                </CardBody>
              </Card>
            )}

            {prescriptions && (
              <Card className="mt-1">
                <CardHeader style={{ paddingBottom: '5px' }}>Prescriptions</CardHeader>
                <CardBody>
                  <CardText>
                    <Row>
                      <Col>{prescriptions}</Col>
                    </Row>
                  </CardText>
                </CardBody>
              </Card>
            )}

            {diaperCream && (
              <Card className="mt-1">
                <CardHeader style={{ paddingBottom: '5px' }}>Diaper Cream</CardHeader>
                <CardBody>
                  <CardText>
                    <Row>
                      <Col>{diaperCream}</Col>
                    </Row>
                  </CardText>
                </CardBody>
              </Card>
            )}

            {sunscreen && (
              <Card className="mt-1">
                <CardHeader style={{ paddingBottom: '5px' }}>Sunscreen</CardHeader>
                <CardBody>
                  <CardText>
                    <Row>
                      <Col>{sunscreen}</Col>
                    </Row>
                  </CardText>
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
      </Container>

      <Row>
        <Col>
          <h6 className="heading-small text-muted ml-2 mt-4">MEDICAL INFO</h6>
        </Col>
      </Row>

      <Container fluid>
        <Row className="align-items-center mt-1">
          <Col className="order-xl-2" xl="12" style={isMobile ? { paddingLeft: 5, paddingRight: 5 } : {}}>
            {doctor && (
              <Card className="mt-1">
                <CardHeader style={{ paddingBottom: '5px' }}>Doctor</CardHeader>
                <CardBody>
                  <CardText>
                    <Row>
                      <Col> {doctor}</Col>
                    </Row>
                  </CardText>
                </CardBody>
              </Card>
            )}
            {doctor && (
              <Card className="mt-1">
                <CardHeader style={{ paddingBottom: '5px' }}>Dentist</CardHeader>
                <CardBody>
                  <CardText>
                    <Row>
                      <Col> {dentist}</Col>
                    </Row>
                  </CardText>
                </CardBody>
              </Card>
            )}

            {health && (
              <Card className="mt-1">
                <CardHeader style={{ paddingBottom: '5px' }}>Health</CardHeader>
                <CardBody>
                  <CardText>
                    <Row>
                      <Col> {health}</Col>
                    </Row>
                  </CardText>
                </CardBody>
              </Card>
            )}

            {insurance && (
              <Card className="mt-1">
                <CardHeader style={{ paddingBottom: '5px' }}>Insurance</CardHeader>
                <CardBody>
                  <CardText>
                    <Row>
                      <Col> {insurance}</Col>
                    </Row>
                  </CardText>
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default MedicalTab;
