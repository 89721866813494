import { Card, CardBody, CardTitle, Row, Col } from 'reactstrap';
import { isMobile } from 'react-device-detect';

const CardWidget = (props: {
  title: string;
  text: string;
  icon: string;
  iconColor?: string;
  tag?: string;
  cursor?: string;
  onClick?: () => void;
  style?: {};
}) => {
  const tag: any = `${props?.tag || 'h5'}`;
  return (
    <Card
      className={`${!isMobile ? 'card-stats mb-4' : ''} mb-xl-0`}
      style={{ ...{ cursor: props?.cursor || 'default' }, ...props?.style }}
      onClick={(e) => (props?.onClick ? props?.onClick() : e.preventDefault())}
    >
      <CardBody style={isMobile ? { padding: '0.6rem' } : {}}>
        <Row style={isMobile ? { padding: 1 } : {}}>
          <div className="col">
            <CardTitle tag={tag} className="text-uppercase text-muted mb-0">
              {props.title || '-'}
            </CardTitle>
            <span className="h2 font-weight-bold mb-0">{props.text || '-'}</span>
          </div>
          {!isMobile && (
            <Col className="col-auto">
              <div className={`icon icon-shape bg-${props.iconColor || 'primary'} text-white rounded-circle shadow`}>
                <i className={props.icon}></i>
              </div>
            </Col>
          )}
        </Row>
      </CardBody>
    </Card>
  );
};

export default CardWidget;
