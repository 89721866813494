import { useState, useEffect } from 'react';
import { useStripe } from '@stripe/react-stripe-js';

type SaveDataStatusProps = {
  clientSecret: string;
};

const SaveDataStatus = ({ clientSecret }: SaveDataStatusProps) => {
  const stripe = useStripe();
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (!stripe) {
      return;
    }

    stripe.retrieveSetupIntent(clientSecret).then(({ setupIntent }: any) => {
      switch (setupIntent.status) {
        case 'succeeded':
          setMessage('Success! Your payment method has been saved.');
          break;

        case 'processing':
          setMessage(`Processing payment details. We'll update you when processing is complete.`);
          break;

        case 'requires_payment_method':
          setMessage('Failed to process payment details. Please try another payment method.');
          break;
      }
    });
  }, [stripe]);

  return <span>{message}</span>;
};

export default SaveDataStatus;
