import { useState } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';

type BillingPaymentDataFormProps = {
  currentKidsId?: string;
  parentId?: string;
  parentName?: string;
  parentEmail?: string;
};

const BillingPaymentDataForm = ({ currentKidsId, parentId, parentName, parentEmail }: BillingPaymentDataFormProps) => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const { error }: any = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: `${process.env.REACT_APP_FRONTEND_URL}/billing/${currentKidsId}${
          parentId ? `?parentId=${parentId}&parentName=${parentName || ''}&parentEmail=${parentEmail || ''}` : ''
        }`,
      },
    });

    if (error) {
      setErrorMessage(error.message);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <PaymentElement />
        <button className="btn btn-primary mt-2" disabled={!stripe}>
          Submit
        </button>
        {errorMessage && <div>{errorMessage}</div>}
      </form>
    </>
  );
};

export default BillingPaymentDataForm;
