import { useState, useEffect } from 'react';
import { Modal, Row, Col } from 'reactstrap';
import FullCalendar, { EventClickArg, EventContentArg } from '@fullcalendar/react';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import ScheduleForm from './ScheduleForm';
import { isMobile } from 'react-device-detect';
import ReactTooltip from 'react-tooltip';

type scheduleCalendarProps = {
  events: any[];
  updateEventList: () => void;
  isWeekensVisible?: boolean;
  handleDatesSet?: (e: any) => void;
};

const Schedule = ({
  events,
  updateEventList,
  isWeekensVisible = false,
  handleDatesSet = () => {},
}: scheduleCalendarProps) => {
  const [weekendsVisible, setWeekendsVisible] = useState(isWeekensVisible);
  const [scheduleId, setScheduleId] = useState('');
  const pageTitle = scheduleId ? 'Update' : 'Create';
  const [dateClick, setDateClick] = useState('');

  const [refresh, setRefresh] = useState(false);

  const handleScheduleCreated = () => {
    setRefresh(!refresh);
    updateEventList();
  };

  const changeWeekendsVisible = () => {
    setWeekendsVisible(!weekendsVisible);
  };

  useEffect(() => {}, []);

  const handleDateClick = (selectInfo: DateClickArg) => {
    const date = new Date(selectInfo.date).toISOString().replace(/T.*$/, ''); // YYYY-MM-DD
    setScheduleId('');
    setDateClick(date);
    modalChange();
  };

  const [scheduleForm, setScheduleForm] = useState(false);

  const toggleScheduleForm = () => {
    setScheduleForm(!scheduleForm);
  };

  const modalChange = () => {
    setScheduleForm(!scheduleForm);
    if (scheduleForm == true) {
      //getScheduleId();
    }
  };

  const handleEventClick = (clickInfo: EventClickArg) => {
    if (clickInfo.event.classNames[0] == 'schedule-calendar-event-red') {
      //console.log(clickInfo.event.classNames);
    } else {
      setScheduleId(clickInfo.event.id);
      modalChange();
    }
  };

  const renderEventContent = (eventContent: EventContentArg) => {
    return (
      <>
        <b>{eventContent.timeText}</b>
        <i data-tip={eventContent.event.extendedProps.observations}>{eventContent.event.title}</i>
        <ReactTooltip />
      </>
    );
  };

  return (
    <>
      <Row>
        <Col xs="12" style={{ paddingRight: 1, paddingLeft: 1 }}>
          <div className="calendar-app">
            <div className="calendar-app-sidebar">
              <div className="calendar-app-sidebar-section">
                <label>
                  <input type="checkbox" checked={weekendsVisible} onChange={() => changeWeekendsVisible()}></input>
                  &nbsp;Show weekends?
                </label>
              </div>
            </div>
            <div className="calendar-app-main">
              <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
                headerToolbar={{
                  left: isMobile ? 'prev,next' : 'prev,next today',
                  center: 'title',
                  right: isMobile ? 'today' : 'dayGridMonth,timeGridWeek,timeGridDay',
                }}
                eventTimeFormat={{
                  hour: 'numeric',
                  minute: '2-digit',
                  meridiem: 'short',
                }}
                initialView="dayGridMonth"
                editable={false}
                selectable={false}
                selectMirror={true}
                dayMaxEvents={false}
                weekends={weekendsVisible}
                events={events}
                eventContent={renderEventContent} // custom render function
                eventClick={handleEventClick}
                dateClick={handleDateClick}
                datesSet={handleDatesSet}
              />
            </div>
          </div>
        </Col>
      </Row>

      <Modal className="modal-dialog-centered" isOpen={scheduleForm} toggle={() => toggleScheduleForm()}>
        <div className="modal-header">
          <h5 className="modal-title" id="Label">
            {`${pageTitle} Schedule`}
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => toggleScheduleForm()}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <ScheduleForm
            isOpen={scheduleForm}
            scheduleId={scheduleId}
            onModalStatus={modalChange}
            dateClick={dateClick}
            onCreateSchedule={handleScheduleCreated}
          />
        </div>
      </Modal>
    </>
  );
};

export default Schedule;
