import { InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import RcTimePicker from 'rc-time-picker';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';

type TimePickerProps = {
  showSecond?: boolean;
  format?: string;
  onChangeTime: (time: any) => void;
  defaultValue?: string;
  value?: string;
  minuteStep?: number;
  disabled?: boolean;
  name?: string;
};

const TimePicker = ({
  showSecond,
  format,
  onChangeTime,
  defaultValue,
  minuteStep = 1,
  disabled = false,
  name,
}: TimePickerProps) => {
  const isShowSecond = typeof showSecond === 'undefined' ? false : showSecond;
  const currentFormat = typeof format === 'undefined' ? 'h:mm a' : format;
  const now = moment();
  const defaulTimeValue = typeof defaultValue === 'undefined' ? now : moment(defaultValue);
  const defaultMinuteStep = minuteStep;
  const [showEmpty, setShowEmpty] = useState(false);

  useEffect(() => {
    if (typeof defaultValue === 'undefined') {
      onChangeTime(now.tz('America/New_York').format('YYYY-MM-DD HH:mm:ss'));
    }
  }, []);

  const changeTime = (time: any) => {
    setShowEmpty(false);
    if (time === null) {
      onChangeTime(null);
      setShowEmpty(true);
      return;
    }
    if (onChangeTime) {
      onChangeTime(time ? time.format('YYYY-MM-DD HH:mm:ss') : now.format('YYYY-MM-DD 00:00:00'));
    }
  };

  return (
    <InputGroup
      className={`input-group-alternative ${disabled ? 'disabled' : ''}`}
      style={{ border: '1px solid #cad1d7' }}
    >
      <InputGroupAddon addonType="prepend">
        <InputGroupText>
          <i className="far fa-clock"></i>
        </InputGroupText>
      </InputGroupAddon>
      {showEmpty && (
        <RcTimePicker
          className={`form-control ${disabled ? 'disabled' : ''}`}
          showSecond={isShowSecond}
          onChange={changeTime}
          allowEmpty={true}
          use12Hours
          minuteStep={defaultMinuteStep}
          disabled={disabled}
          name={name}
        />
      )}
      {!showEmpty && (
        <RcTimePicker
          className={`form-control ${disabled ? 'disabled' : ''}`}
          showSecond={isShowSecond}
          onChange={changeTime}
          format={currentFormat}
          defaultValue={defaulTimeValue}
          value={defaulTimeValue}
          allowEmpty={true}
          use12Hours
          minuteStep={defaultMinuteStep}
          disabled={disabled}
          name={name}
        />
      )}
    </InputGroup>
  );
};

export default TimePicker;
