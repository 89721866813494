import http from '../helpers/http';

const ExpirationDates = (token: string) => {
  const headers = {
    'x-access-token': token,
  };

  return {
    list: async (userId: string) => {
      const result = await http.get(`/expiration-dates/${userId}`, {
        headers,
      });
      return result.data;
    },
    create: async (body: { userId: string; expirationTypeId: string; issueDate: string; expDate: string }) => {
      const result = await http.post(`/expiration-dates`, body, { headers });
      return result.data;
    },
    delete: async (id: string) => {
      const result = await http.delete(`/expiration-dates/${id}`, {
        headers,
      });
      return result.data;
    },
  };
};

export default ExpirationDates;
