import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import ModalContent from '../../../components/Modals/Modal';

export const EnrolledDetailsModal: React.FC<{
  kids: Array<any>;
  toggleModal: boolean;
  handleToggleModal: () => void;
}> = ({ kids, toggleModal, handleToggleModal }) => {
  const [kidsFiltered, setKidsFiltered] = useState<any[]>([]);

  useEffect(() => {
    const newKidsFiltered: any[] = [];
    for (const row of kids) {
      const classType = row?.enrollment?.classType?.name || '';
      const searchKids = newKidsFiltered.find((k: any) => k?.classType === classType);
      if (searchKids) {
        searchKids.count = searchKids.count + 1;
        continue;
      }
      newKidsFiltered.push({
        classType,
        count: 1,
      });
    }
    setKidsFiltered(newKidsFiltered);
  }, [kids]);

  return (
    <>
      <ModalContent title={'Enrolled Details'} modal={toggleModal} toggle={() => handleToggleModal()} size="md">
        <Row>
          {kidsFiltered?.length ? (
            kidsFiltered.map((col: { classType: string; count: number }, index: number) => (
              <Col xs="6" className="mb-2" key={`enrolled-details-${index}`}>
                <Card>
                  <CardBody className="pt-1 pb-1">
                    <CardTitle tag="h5" className={`text-uppercase text-muted`}>
                      {col.classType}
                    </CardTitle>
                    <Row>
                      <Col xs="12" className="text-center">
                        {col.count}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            ))
          ) : (
            <></>
          )}
        </Row>
      </ModalContent>
    </>
  );
};
