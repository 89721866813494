/* eslint-disable max-lines */
import { Card, CardHeader, CardBody, Row, Col, Input, FormGroup } from 'reactstrap';
import { useContext, useState, useEffect } from 'react';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import MessagingService from '../../../services/Messaging';
import { dateDistanceTime } from '../../../helpers/dateDistance';
import MessagingInterface from '../../../interfaces/MessagingInterface';
import ReactDatetime from 'react-datetime';
import moment from 'moment';
import LoaderSpinner from '../../../components/Core/LoaderSpinner';

import { createPagedMeta } from '../../../entities/PagedMeta';
import { createOptionsPage } from '../../../entities/OptionsPaged';

type props = {
  setNewTypeDisplay: (type: string) => void;
  userId?: string;
  studentId?: string;
};

const MessagingList = ({ setNewTypeDisplay, userId, studentId }: props) => {
  const { token, currentSchool } = useContext(AuthContext);
  const { updateuserTotalUnreadMessages }: any = useContext(AuthContext);
  const [data, setData] = useState([] as any[]);
  const { id } = useParams<{ id: string }>();
  const [filterSearch, setFilterSearch] = useState('');
  const [oldDivRead, setOldDivRead] = useState('');
  const [starMessages, setStarMessages] = useState(false);
  const [startDate, setStartDate] = useState<moment.Moment | null>(null);
  const [endDate, setEndDate] = useState<moment.Moment | null>(moment());
  const [lastLoad, setLastLoad] = useState(new Date().getTime());
  const [loading, setLoading] = useState(true);

  const [showUnread, setShowUnread] = useState(false);

  const updateSetShowUnread = async () => {
    setShowUnread(!showUnread);
  };

  const changeStarMessages = async () => {
    setStarMessages(!starMessages);
  };

  const changeReadDiv = async (divId: string) => {
    const div = document.getElementById(divId);
    const divOld = document.getElementById(oldDivRead);
    if (div !== null) {
      if (oldDivRead != '' && divOld !== null) {
        divOld.classList.remove('messaging-card-list-read');
      }
      div.classList.add('messaging-card-list-read');
      setOldDivRead(divId);
    }
  };

  const getList = async () => {
    const filteredOptions: any = {
      filters: [],
      options: createOptionsPage(createPagedMeta()),
    };
    if (filterSearch) {
      filteredOptions.filters = [
        {
          field: 'message',
          operation: 'ilike',
          value: `%${filterSearch}%`,
        },
      ];
    }
    filteredOptions.filters.push({
      field: 'createdAt',
      operation: '>=',
      value: startDate?.format('YYYY-MM-DD 00:00:00'),
    });
    filteredOptions.filters.push({
      field: 'createdAt',
      operation: '<=',
      value: endDate?.format('YYYY-MM-DD 23:59:59'),
    });
    if (studentId) {
      return await MessagingService(token || '').listByStudent(
        currentSchool.value,
        studentId,
        filterSearch,
        starMessages,
      );
    }
    if (userId) {
      return await MessagingService(token || '').listByUser(currentSchool.value, userId, filterSearch, starMessages);
    }

    return await MessagingService(token || '').list(currentSchool.value, filteredOptions, starMessages);
  };

  const loadList = async () => {
    setLoading(true);
    try {
      const result = await getList();
      const data: MessagingInterface[] = result.data;
      setData(data);
    } catch (e: any) {
      toast.error('Error: ' + id);
    } finally {
      setLoading(false);
    }
  };

  const setTypeDisplay = (type: string) => {
    setNewTypeDisplay(type);
  };

  const setRead = async (id: string, messageTalkId: string, exec: string, divId: string) => {
    const body = {
      messagingTalkId: messageTalkId,
    };
    try {
      if (exec === '1') {
        await MessagingService(token || '').createRead(id, body);
        await loadList();
        changeReadDiv(divId);
        toast.success(`successfully!`);
        updateuserTotalUnreadMessages();
      }
    } catch (e: any) {
      toast.error('Error: ' + id);
    }
  };

  const setStars = async (messagingId: string, divStars: string) => {
    try {
      const div = document.getElementById(divStars);

      const starsStatus = await MessagingService(token || '').showStars(messagingId);

      if (starsStatus?.data?.stars == false) {
        const body = {
          messagingId: messagingId,
        };
        await MessagingService(token || '').createStars(messagingId, body);
        if (div !== null) {
          div.classList.remove('far');
          div.classList.add('fas');
        }

        toast.success(`Save Stars successfully!`);
      } else {
        await MessagingService(token || '').deleteStars(messagingId);
        if (div !== null) {
          div.classList.remove('fas');
          div.classList.add('far');
        }
        toast.success(`Remove Stars successfully!`);
      }
    } catch (e: any) {
      toast.error('Error: ' + id);
    }
  };

  const stringMessaging = (messaging: string, long: number, sufix: string) => {
    const removeHtmlTags = messaging.replace(/(<([^>]+)>)/gi, '');
    const removeHtml = removeHtmlTags.replace('&nbsp;', ' ').substring(0, long) + sufix;

    return removeHtml;
  };

  useEffect(() => {
    loadList();
  }, [lastLoad, filterSearch, starMessages, currentSchool, setNewTypeDisplay, startDate, endDate]);

  return (
    <>
      <Card>
        <CardHeader>
          <Row>
            <Col md="12" className="messaging-card-header-title">
              <h1>Messaging</h1>
            </Col>
          </Row>

          <Row>
            <Col md="12 mb-3">
              <div className="input-group">
                <ReactDatetime
                  className="form-control p-0 border-0"
                  inputProps={{
                    placeholder: 'Start Date',
                  }}
                  onChange={(newValue) => {
                    setStartDate(moment(newValue));
                  }}
                  timeFormat={false}
                  value={startDate?.format('MM/DD/yyyy')}
                  dateFormat={'MM/DD/yyyy'}
                  closeOnSelect={true}
                />
                <div className="input-group-append input-group-prepend" style={{ borderLeft: '1px solid' }}>
                  <span className="input-group-text">-</span>
                </div>
                <ReactDatetime
                  className="form-control p-0 border-top-0"
                  inputProps={{
                    placeholder: 'End Date',
                  }}
                  onChange={(newValue) => {
                    setEndDate(moment(newValue));
                  }}
                  timeFormat={false}
                  initialValue={endDate?.format('MM/DD/yyyy')}
                  dateFormat={'MM/DD/yyyy'}
                  closeOnSelect={true}
                />
              </div>
            </Col>
            <Col md="12">
              <FormGroup className="messaging-search">
                <Input
                  className="form-control-alternative"
                  id="input-search"
                  placeholder="Search"
                  type="text"
                  onChange={(e) => setFilterSearch(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
          {!userId && !studentId && (
            <>
              <Row className="messaging-filter-icons">
                <Col md="12">
                  <div className="btn-group" role="group" aria-label="Basic example" style={{ display: 'block' }}>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => setLastLoad(new Date().getTime())}
                      title="Reload"
                    >
                      <i className="fas fa-redo"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => changeStarMessages()}
                      title="Show Star Messages"
                    >
                      <i className={`${starMessages === false ? 'far' : 'fas'} fa-star`}></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => updateSetShowUnread()}
                      title="Show Unread Messages"
                    >
                      <i className="ni ni-email-83"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => setTypeDisplay('')}
                      title="Write New Message"
                    >
                      <i className="fas fa-edit"></i>
                    </button>
                  </div>
                </Col>
              </Row>
            </>
          )}
        </CardHeader>
      </Card>
      <div className="messaging-list-loop-main">
        {loading && (
          <Row className="mt-5">
            <Col>
              <LoaderSpinner />
            </Col>
          </Row>
        )}
        {!loading &&
          data.map((r, index) => {
            return (
              <Row
                key={`item-${index}`}
                className={
                  (() => {
                    if (r?.messagingNotification?.read && showUnread === true) return ' messaging-hidden ';
                  })() + ' align-items-center mt-2 '
                }
              >
                <Col className="order-xl-2" xl="12">
                  <Card
                    className={`${r?.messagingNotification?.read === false && 'messaging-unread'}`}
                    onClick={() => (
                      setTypeDisplay(r?.id),
                      setRead(
                        r?.id,
                        r?.talk?.id,
                        `${r?.messagingNotification?.read === false ? '1' : '0'}`,
                        `messaging-card-list-${index}`,
                      ),
                      changeReadDiv(`messaging-card-list-${index}`)
                    )}
                    id={`messaging-card-list-${index}`}
                  >
                    <CardBody style={{ padding: '5px 10px' }}>
                      <Row>
                        <Col md="2">
                          <img
                            alt={`${r?.talk?.user?.firstName}`}
                            title={`${r?.talk?.user?.firstName}`}
                            className="rounded-circle"
                            src={r?.talk?.user?.picture}
                            style={{ width: 40, height: 40, marginRight: 10 }}
                          />
                        </Col>
                        <Col md="8">
                          <Row>
                            <Col>
                              <span className="messaging-group-direct-type">
                                {r.related.length === 0 && r?.groupMessage && r?.sendTo}
                                {r.related.length === 0 && !r?.groupMessage && 'Direct'}
                                {r.related.length > 0 && 'Group'}
                              </span>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <span>
                                {r?.talk?.user?.firstName || ''} {r?.talk?.user?.lastName || ''}
                              </span>
                            </Col>
                          </Row>

                          <Row>
                            <Col>
                              {r?.talk?.message?.length >= 20 && (
                                <div className="is-size-6">{stringMessaging(r?.talk?.message, 20, '...')}</div>
                              )}

                              {r?.talk?.message?.length < 20 && (
                                <div className="is-size-6"> {stringMessaging(r?.talk?.message, 20, '')}</div>
                              )}
                              <span className="messaging-time-list">{dateDistanceTime(r?.talk?.createdAt)}</span>
                            </Col>
                          </Row>
                        </Col>
                        {!userId && (
                          <Col md="1">
                            <i
                              className={`${r?.messagingStars?.stars === false ? 'far' : 'fas'} fa-star`}
                              onClick={() => setStars(r?.id, `messaging-list-star-${index}`)}
                              id={`messaging-list-star-${index}`}
                            ></i>
                          </Col>
                        )}
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            );
          })}
      </div>
    </>
  );
};

export default MessagingList;
