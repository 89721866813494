/* eslint-disable max-lines */
import { Button, Card, CardHeader, CardBody, Row, Col, Badge, Collapse } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import DailyQuestionareShowModal from '../../../components/Core/DailyQuestionareShowModal';
import moment from 'moment';
import { simpleKidEnroll } from '../../../components/Navbars/ModalParentKids';

type ClassKidsListProps = {
  title: string;
  kids: any[];
  kidsSelected: string[];
  paddingTop?: string;
  badgeColor?: string;
  collapse?: boolean;
  selectChild: (e: any, id: string, isSleep: boolean) => void;
  openModal: (title: string, message: string) => void;
  showActionButtons?: boolean;
  showTitle?: boolean;
};

const styles = {
  kidsCol: isMobile
    ? { paddingLeft: 15, marginLeft: 15, marginTop: 12, marginBottom: 4 }
    : { paddingLeft: 0, marginLeft: 15, marginTop: 12, marginBottom: 4 },
  kidsContainer: { minWidth: 208 },
  kidsProfile: { height: 135 },
  kidsProfileImg: { width: 135, height: 135 },
  kidsName: { fontSize: 15, fontWeight: 100 },
  kidsBadge: { fontSize: '0.8125rem', verticalAlign: 'middle', lineHeight: 'normal', marginBottom: '1px' },
  kidsNameStrong: { fontWeight: 600 },
  disabledButton: {
    width: '30px',
    height: '30px',
    borderRadius: '30px',
    borderWidth: 'medium',
    transition: 'none',
    opacity: 1,
  },
  borderColorPrimary: {
    borderColor: '#5e72e4',
  },
  borderColorWarning: {
    borderColor: '#fb6340',
  },
  cursorDisabled: { cursor: 'not-allowed' },
  cursorPointer: { cursor: 'pointer' },
};

const ClassKidsList = ({
  title,
  kids,
  kidsSelected,
  paddingTop,
  badgeColor,
  collapse,
  selectChild,
  openModal,
  showActionButtons = true,
  showTitle = true,
}: ClassKidsListProps) => {
  const [isOpen, setIsOpen] = useState(true);
  const [selectedKid, setSelectedKid] = useState<simpleKidEnroll | undefined>();

  const showPrecheckinModal = (kidId: string) => {
    const selected = kids.find((kid) => kid.id === kidId);
    if (!selected || selected.precheckin.length === 0) return;
    setSelectedKid({
      id: selected.id,
      name: `${selected.firstName} ${selected.lastName}`,
      classId: selected.enrolledClassId,
      className: selected.precheckin[0].options.className,
      showModal: true,
      modalType: selected.precheckin[0].options.type,
      canCloseModal: true,
      data: selected.precheckin.map((item: any) => item.options),
    });
  };

  const ucfirst = (text: string) => {
    if (text === '' || text === undefined) {
      return '';
    }
    return text[0].toUpperCase() + text.substring(1).toLowerCase();
  };

  const precheckinModal = () => {
    if (!selectedKid) return;
    return (
      <DailyQuestionareShowModal
        isModal={true}
        modalTitle={`${selectedKid.name} | ${moment(selectedKid.data[0].createdAt).format('MM/DD/YYYY')}`}
        onClose={() => {
          setSelectedKid(undefined);
        }}
      >
        {selectedKid.data[0].type === 'other' && (
          <div className="row justify-content-center mt-2 pt-2" style={{ borderTop: '2px solid #5E72E4' }}>
            <div className="col-5 pt-3">Had Breakfast?</div>
            <div className="col-5 pt-3">{ucfirst(selectedKid.data[0].have_breakfast)}</div>
            <div className="col-5 pt-3">Sleep Well?</div>
            <div className="col-5 pt-3">{ucfirst(selectedKid.data[0].sleep_well)}</div>
            <div className="col-5 pt-3">Notes:</div>
            <div className="col-5 pt-3">{ucfirst(selectedKid.data[0].more_detail)}</div>
          </div>
        )}
        {selectedKid.data[0].type === 'infant' && (
          <div className="row justify-content-center mt-2 pt-2" style={{ borderTop: '2px solid #5E72E4' }}>
            <div className="col-5 pt-3">Last Feeding:</div>
            <div className="col-5 pt-3">
              {/* <i className="fas fa-clock mr-1" /> */}
              {moment(selectedKid.data.find((item: any) => item.activityType === 'Food').createdAt).format('HH:mm a')}
            </div>
            <div className="col-5 pt-3">Amount:</div>
            <div className="col-5 pt-3">
              {selectedKid.data.find((item: any) => item.activityType === 'Food')?.childAte} oz
            </div>
            <div className="col-5 pt-3">Last Diaper:</div>
            <div className="col-5 pt-3">
              {/* <i className="fas fa-clock mr-1" /> */}
              {moment(selectedKid.data.find((item: any) => item.activityType === 'Diaper').createdAt).format('HH:mm a')}
            </div>
            <div className="col-5 pt-3">Type:</div>
            <div className="col-5 pt-3">
              {ucfirst(selectedKid.data.find((item: any) => item.activityType === 'Diaper').options[0])}
            </div>
            <div className="col-5 pt-3">Notes:</div>
            <div className="col-5 pt-3">
              {ucfirst(selectedKid.data.find((item: any) => item.activityType === 'Food').describe)}
            </div>
          </div>
        )}
      </DailyQuestionareShowModal>
    );
  };

  return (
    <>
      {precheckinModal()}
      <Row className={`mt-1 bg-white ${paddingTop || ''}`}>
        {showTitle && (
          <Col
            xs="12"
            className="mb-1"
            onClick={(e) => (collapse ? setIsOpen(!isOpen) : e.preventDefault())}
            style={collapse ? styles.cursorPointer : {}}
          >
            <h4 className="mb-0">
              <Badge color="" className="badge-dot badge-lg" style={styles.kidsBadge}>
                <i className={`bg-${badgeColor || 'success'} mr-2`} style={{ verticalAlign: 'baseline' }} />
              </Badge>
              {title}
              {collapse && <i className={`ml-3 fas fa-angle-${isOpen ? 'down' : 'right'}`}></i>}
            </h4>
          </Col>
        )}

        <Collapse isOpen={isOpen} className="row no-gutters justify-content-center" style={{ padding: '0 5px' }}>
          {kids.map((k: any, index: number) => {
            return (
              <div className={`mt-6`} key={`kids-list-${index}`}>
                <Col className="p-1">
                  <Card
                    className="card-profile kids"
                    style={{
                      background: kidsSelected.includes(k.id) ? '#d4ffe1' : 'white',
                      ...styles.kidsContainer,
                      ...styles.cursorPointer,
                    }}
                    onClick={(e) =>
                      k.isMoved && !k.fromAnotherClass ? e.preventDefault() : selectChild(e, k.id, k.isSleep)
                    }
                  >
                    <Row className="justify-content-center mb-n5">
                      <Col className="order-lg-2" lg="3">
                        <div className="card-profile-image" style={styles.kidsProfile}>
                          <a href="#select">
                            <img
                              alt={`${k.firstName} ${k.lastName}`}
                              className="rounded-circle"
                              src={k.picture ? `${k.picture}` : require('../../../assets/img/user-default.png').default}
                              style={{
                                ...styles.kidsProfileImg,
                                ...(k.isMoved && !k.fromAnotherClass && k.enrolledCurrentClass
                                  ? styles.cursorDisabled
                                  : {}),
                              }}
                            />
                          </a>
                        </div>
                      </Col>
                    </Row>
                    <CardHeader
                      className="text-center border-0 pb-4"
                      style={{ background: kidsSelected.includes(k.id) ? '#d4ffe1' : 'white' }}
                    >
                      <div className="text-center">
                        <h3 style={styles.kidsName}>
                          {`${k.firstName}`} <strong style={styles.kidsNameStrong}>{`${k.lastName}`}</strong>
                        </h3>
                      </div>
                    </CardHeader>
                    {showActionButtons && (
                      <CardBody className="text-center pt-0" style={{ marginTop: -20 }}>
                        <Row className="pb-2 pt-0">
                          <Col className="text-center">
                            {k.alergyMedical && (
                              <Link className="pl-1 pr-1" to="#" onClick={(e) => e.preventDefault()}>
                                <Button
                                  color="danger"
                                  size="sm"
                                  title="Allergy"
                                  onClick={() => openModal(`${k.firstName} ${k.lastName}`, k.alergyMedical)}
                                >
                                  <i className="fas fa-briefcase-medical"></i>
                                </Button>
                              </Link>
                            )}
                            <Link className="pl-1 pr-1" to={`/student-profile/${k.id}?tab=timeline`}>
                              <Button color="info" size="sm" title="Timeline">
                                <i className="fas fa-clock"></i>
                              </Button>
                            </Link>
                            <Link className="pl-1 pr-1" to={`/student-profile/${k.id}`}>
                              <Button color="success" size="sm" title="Profile">
                                <i className="fas fa-address-card"></i>
                              </Button>
                            </Link>
                            {k.precheckin.length > 0 && (
                              <Button
                                className="ml-1 mr-1"
                                color="primary"
                                size="sm"
                                title="Pré Checkin Infos"
                                onClick={(_) => showPrecheckinModal(k.id)}
                              >
                                &nbsp;
                                <i className="fas fa-receipt" />
                                &nbsp;
                              </Button>
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col className="text-center" style={{ minHeight: '32px' }}>
                            {k.isSleep && (
                              <>
                                <Button
                                  className="ml-1 mr-0 hover-transition-disabled"
                                  color="btn-outline-primary"
                                  size="sm"
                                  title="Sleeping"
                                  disabled
                                  active={false}
                                  style={{ ...styles.disabledButton, ...styles.borderColorPrimary }}
                                >
                                  <img
                                    alt="Sleeping"
                                    src={require('../../../assets/img/icons/sleeping-icon.svg').default}
                                    style={{ width: '16px', marginTop: '-2px', marginLeft: '-3px' }}
                                  />
                                </Button>
                              </>
                            )}
                            {!k.systemPhotoPermission && (
                              <>
                                <span
                                  className="fa-stack fa-2x pl-1 pr-1"
                                  style={{ fontSize: '1em' }}
                                  title="No system photo permission"
                                >
                                  <i className="fas fa-camera fa-stack-1x"></i>
                                  <i className="fas fa-ban fa-stack-2x" style={{ color: 'tomato' }}></i>
                                </span>
                              </>
                            )}
                            {k.fromAnotherClass && !k.enrolledCurrentClass && (
                              <>
                                <Button
                                  className="ml-1 mr-0 hover-transition-disabled"
                                  color="btn-outline-warning"
                                  size="sm"
                                  title="Moved from another class"
                                  disabled
                                  active={false}
                                  style={{ ...styles.disabledButton, ...styles.borderColorWarning }}
                                >
                                  <i
                                    className="fas fa-exchange-alt"
                                    style={{ marginLeft: '-2px', color: '#fb6340' }}
                                  ></i>
                                </Button>
                              </>
                            )}
                          </Col>
                        </Row>
                      </CardBody>
                    )}
                  </Card>
                </Col>
              </div>
            );
          })}
        </Collapse>
      </Row>
    </>
  );
};

export default ClassKidsList;
