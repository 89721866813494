/* eslint-disable max-lines */
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Alert,
  Media,
} from 'reactstrap';
import { useContext, useState, useEffect } from 'react';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import { Link, useParams } from 'react-router-dom';
import UserService from '../../../services/User';
import CommonHeader from '../../../components/Headers/CommonHeader';
import ProfileTab from './TabContent/ProfileTab';
import TimeCardTab from './TabContent/TimeCardTab';
import EmployeeDetailsTab from './TabContent/EmployeeDetailsTab';
import ChildrenTab from './TabContent/ChildrenTab';
import MedicalTab from './TabContent/MedicalTab';
import AsideProfile from './ProfileRightBar';
import isParent from '../../../helpers/isParent';
import NotificationTab from './TabContent/NotificationTab';
import FilesTab from './TabContent/FilesTab';
import Employee from '../../../services/Employee';
import { toast } from 'react-toastify';
import moment from 'moment';
import isClassroom from '../../../helpers/isClassroom';
import ClassPermissionsTab from './TabContent/ClassPermissionsTab';
import OpacityLoaderSpinner from '../../../components/Core/OpacityLoaderSpinner';
import MessagingListTalk from '../Messaging/MessagingListTalk';
import { isMobile } from 'react-device-detect';
import isAdmin from '../../../helpers/isAdmin';

const Profile = () => {
  const { id: userId } = useParams<{ id: string }>();
  const { token, user: loggedUser }: any = useContext(AuthContext);
  const [kidsList, setKidsList] = useState([]);
  const [reload, setReload] = useState(false);
  const [currentUser, setCurrentUser] = useState({} as any);
  const [terminationDateDay, setTerminationDateDay] = useState('');
  const [terminationDateMonth, setTerminationDateMonth] = useState('');
  const [terminationDateYear, setTerminationDateYear] = useState('');
  const [isTerminationFuture, setIsTerminationFuture] = useState(false);
  const [isParentUser, setIsParentUser] = useState(false);
  const [isUserHimself, setIsUserHimself] = useState(false);
  const [isClassroomUser, setIsClassroomUser] = useState<boolean | null>(null);
  const [loadingUser, setLoadingUser] = useState(true);

  useEffect(() => {
    getKidsByUserId();
    getUserById();
  }, []);

  useEffect(() => {
    getKidsByUserId();
    getUserById();
    setIsUserHimself(userId === loggedUser.id);
  }, [userId]);

  useEffect(() => {
    setLoadingUser(true);
    if (Object.keys(currentUser).length === 0 && currentUser.constructor === Object) {
      return;
    }
    setIsParentUser(isParent(currentUser));
    setIsClassroomUser(isClassroom(currentUser));
    setLoadingUser(false);
  }, [currentUser]);

  useEffect(() => {
    if (isClassroomUser === null) {
      return;
    }
    let tab = 'profile';
    if (isClassroomUser) {
      tab = 'classes';
    }
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    setActiveTab(urlParams.get('tab') || tab);
  }, [isClassroomUser]);

  const getKidsByUserId = async () => {
    try {
      const result = await UserService(token).listKids(userId);
      setKidsList(result?.data || []);
    } catch (e: any) {
      console.error(e?.response?.data?.message || 'Invalid user ID');
    }
  };

  const getUserById = async () => {
    try {
      const result = await UserService(token).show(userId);
      setCurrentUser(result?.data || {});
      if (result?.data?.employee?.terminationDate) {
        const terminationDate = moment(result?.data?.employee?.terminationDate);
        setTerminationDateDay(terminationDate?.format('Do'));
        setTerminationDateMonth(terminationDate?.format('MMMM'));
        setTerminationDateYear(terminationDate?.format('YYYY'));
        setIsTerminationFuture(terminationDate >= moment(moment().format('YYYY-MM-DD')));
      }
    } catch (e: any) {
      console.error(e?.response?.data?.message || 'Invalid user ID');
    }
  };

  const customStyles = {
    navLink: {
      minHeight: '42px',
      paddingTop: '8px',
    },
  };
  const [activeTab, setActiveTab] = useState('');

  const visibleChildrenTab = kidsList?.length;

  const handleActivatedEmployee = async (employeeId: string) => {
    try {
      await Employee(token).activated(employeeId);
      getUserById();
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'Is not possible change status!');
    }
  };

  return (
    <>
      <CommonHeader />
      {loadingUser ? <OpacityLoaderSpinner /> : <></>}
      <Container className="mt--8" fluid style={loadingUser ? { display: 'none' } : { display: 'block' }}>
        <Row>
          <Col className="order-xl-2" xl="12" style={isMobile ? { paddingLeft: 5, paddingRight: 5 } : {}}>
            <Card className="bg-secondary shadow">
              {currentUser?.status === 'TERMINATED' ? (
                <Alert color="warning" className="mt-1 ml-1 mr-1">
                  <b>Terminated:</b> {currentUser?.firstName}'s last day of work {isTerminationFuture ? 'is' : 'was'}{' '}
                  {terminationDateMonth || ''} {terminationDateDay || ''}, {terminationDateYear || ''}.{' '}
                  <Link
                    to={`/directory?id=${currentUser?.employee?.id}&type=revoke`}
                    className="text-white"
                    style={{ textDecoration: 'underline white' }}
                  >
                    <b>Revoke Termination</b>
                  </Link>
                  {' | '}
                  <Link
                    to={`/directory?id=${currentUser?.employee?.id}&type=rehire`}
                    className="text-white"
                    style={{ textDecoration: 'underline white' }}
                  >
                    <b>Rehire</b>
                  </Link>
                </Alert>
              ) : (
                <></>
              )}
              <CardHeader className="bg-white border-0 mb-0 pb-0" style={isMobile ? { padding: '1rem 0.5rem' } : {}}>
                <div style={{ marginBottom: '12px' }}>
                  <Media className="align-items-center">
                    <span className="avatar rounded-circle" style={{ width: '60px', height: '60px' }}>
                      <img
                        alt={`${currentUser?.firstName} ${currentUser?.lastName}`}
                        src={
                          currentUser?.picture
                            ? `${currentUser?.picture}`
                            : require('../../../assets/img/user-default.png').default
                        }
                        style={{ height: '100%' }}
                      />
                    </span>
                    <Media className="ml-2">
                      <span className="mb-0 text-sm font-weight-bold">{`${currentUser?.firstName} ${currentUser?.lastName}`}</span>
                    </Media>
                  </Media>
                </div>
                <div className={isMobile ? 'd-flex justify-content-center' : ''}>
                  <Nav className="border-0 nav-profile" tabs>
                    {!isClassroomUser && (
                      <NavItem className="cursor-pointer">
                        <NavLink
                          className="border-0 cursor-pointer font-weight-bold nav-link-profile"
                          style={customStyles.navLink}
                          active={activeTab === 'profile'}
                          onClick={() => setActiveTab('profile')}
                        >
                          <i className={`fas fa fa-user ${!isMobile ? 'mr-2' : ''}`} />
                          <span>Profile</span>
                        </NavLink>
                      </NavItem>
                    )}

                    {!isParentUser && (
                      <>
                        {currentUser?.employee?.id ? (
                          <NavItem className="cursor-pointer">
                            <NavLink
                              className="border-0 cursor-pointer font-weight-bold nav-link-profile"
                              style={customStyles.navLink}
                              active={activeTab === 'time'}
                              onClick={() => setActiveTab('time')}
                            >
                              <i className={`fas fa fa-clock ${!isMobile ? 'mr-2' : ''}`} />
                              <span>Time Card</span>
                            </NavLink>
                          </NavItem>
                        ) : (
                          <></>
                        )}
                      </>
                    )}

                    {!isParentUser && !isClassroomUser && (
                      <NavItem className="cursor-pointer">
                        <NavLink
                          className="border-0 cursor-pointer font-weight-bold nav-link-profile"
                          style={customStyles.navLink}
                          active={activeTab === 'employee'}
                          onClick={() => setActiveTab('employee')}
                        >
                          <i className={`fas fa fa-briefcase ${!isMobile ? 'mr-2' : ''}`} />
                          <span>{`Employee${!isMobile ? ' Details' : ''}`}</span>
                        </NavLink>
                      </NavItem>
                    )}

                    {!!visibleChildrenTab && (
                      <NavItem className="cursor-pointer">
                        <NavLink
                          className="border-0 cursor-pointer font-weight-bold nav-link-profile"
                          style={customStyles.navLink}
                          active={activeTab === 'children'}
                          onClick={() => setActiveTab('children')}
                        >
                          <i className={`fas fa fa-child ${!isMobile ? 'mr-2' : ''}`} />
                          <span>Children</span>
                        </NavLink>
                      </NavItem>
                    )}

                    {!isParentUser && !isClassroomUser && (
                      <NavItem className="cursor-pointer">
                        <NavLink
                          className="border-0 cursor-pointer font-weight-bold nav-link-profile"
                          style={customStyles.navLink}
                          active={activeTab === 'medical'}
                          onClick={() => setActiveTab('medical')}
                        >
                          <i className={`fas fa-notes-medical ${!isMobile ? 'mr-2' : ''}`} />
                          <span>Medical</span>
                        </NavLink>
                      </NavItem>
                    )}

                    {1 != 1 && (
                      <NavItem className="cursor-pointer">
                        <NavLink
                          className="border-0 cursor-pointer font-weight-bold nav-link-profile"
                          style={customStyles.navLink}
                          active={activeTab === 'notifications'}
                          onClick={() => setActiveTab('notifications')}
                        >
                          <i className={`fas fa-bell ${!isMobile ? 'mr-2' : ''}`} />
                          <span>Notifications</span>
                        </NavLink>
                      </NavItem>
                    )}

                    {!isParentUser && !isClassroomUser && (
                      <NavItem className="cursor-pointer">
                        <NavLink
                          className="border-0 cursor-pointer font-weight-bold nav-link-profile"
                          style={customStyles.navLink}
                          active={activeTab === 'files'}
                          onClick={() => setActiveTab('files')}
                        >
                          <i className={`fas fa-file ${!isMobile ? 'mr-2' : ''}`} />
                          <span>Files</span>
                        </NavLink>
                      </NavItem>
                    )}
                    {isClassroomUser && (
                      <NavItem className="cursor-pointer">
                        <NavLink
                          className="border-0 cursor-pointer font-weight-bold nav-link-profile"
                          style={customStyles.navLink}
                          active={activeTab === 'classes'}
                          onClick={() => setActiveTab('classes')}
                        >
                          <i className={`fas fa-lock ${!isMobile ? 'mr-2' : ''}`} />
                          <span>Classes</span>
                        </NavLink>
                      </NavItem>
                    )}
                    {isAdmin(loggedUser) && (
                      <NavItem className="cursor-pointer">
                        <NavLink
                          className="border-0 cursor-pointer font-weight-bold nav-link-profile"
                          style={customStyles.navLink}
                          active={activeTab === 'messages'}
                          onClick={() => setActiveTab('messages')}
                        >
                          <i className={`fas fa-comment ${!isMobile ? 'mr-2' : ''}`} />
                          <span>Messages</span>
                        </NavLink>
                      </NavItem>
                    )}
                  </Nav>
                </div>
              </CardHeader>
              <CardBody className="d-flex flex-wrap w-100">
                <Col xl="8" lg="8" md="12">
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="profile">
                      <ProfileTab refresh={getUserById} />
                    </TabPane>

                    {!isParentUser && (
                      <>
                        {currentUser?.employee?.id ? (
                          <TabPane tabId="time">
                            <TimeCardTab />
                          </TabPane>
                        ) : (
                          <></>
                        )}
                      </>
                    )}

                    {!isParentUser && (
                      <TabPane tabId="employee">
                        <EmployeeDetailsTab />
                      </TabPane>
                    )}

                    {!!visibleChildrenTab && (
                      <TabPane tabId="children">
                        <ChildrenTab />
                      </TabPane>
                    )}

                    {!isParentUser && (
                      <TabPane tabId="medical">
                        <MedicalTab />
                      </TabPane>
                    )}
                    <TabPane tabId="notifications">
                      <NotificationTab />
                    </TabPane>

                    <TabPane tabId="files">
                      <FilesTab reload={reload} />
                    </TabPane>

                    {isClassroomUser && (
                      <TabPane tabId="classes">
                        <ClassPermissionsTab userId={currentUser?.id} />
                      </TabPane>
                    )}

                    <TabPane tabId="messages">
                      <MessagingListTalk userId={userId} />
                    </TabPane>
                  </TabContent>
                </Col>
                <Col xl="4" lg="4" md="12">
                  <AsideProfile
                    reload={reload}
                    handleReload={setReload}
                    currentUser={currentUser}
                    isUserHimself={isUserHimself}
                    handleActivatedEmployee={handleActivatedEmployee}
                  />
                </Col>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Profile;
