import { useState, useEffect, useContext } from 'react';
import { Col, Nav, NavItem, Row, TabContent, TabPane, NavLink } from 'reactstrap';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import CardInfo from '../../../components/Cards/CardInfo';
import ModalContent from '../../../components/Modals/Modal';
import DataTable from '../../../components/Core/DataTable';
import { Link } from 'react-router-dom';
import IncidentReports from '../../../services/IncidentReports';
import moment from 'moment';

export const IncidentReportModal: React.FC<{
  token: string;
  currentSchoolId: string;
}> = ({ token, currentSchoolId }) => {
  const { currentSchool }: any = useContext(AuthContext);
  const [toggleModal, setToggleModal] = useState(false);
  const handleToggleModal = () => setToggleModal(!toggleModal);
  const [activeTab, setActiveTab] = useState('1');
  const [data, setData] = useState([]);
  const [director, setDirector] = useState([]);
  const [parent, setParent] = useState([]);
  const schoolId = currentSchool?.value || '';

  const customStyles = {
    navLink: {
      minHeight: '42px',
      paddingTop: '8px',
      cursor: 'pointer',
    },
  };

  const columnsDirector = [
    {
      name: 'Name',
      selector: (row: any) => (
        <Link to={`/incident-report/${row?.id}`}>
          <span>{`${row?.kids.firstName || '-'} ${row?.kids.lastName || '-'}`}</span>
        </Link>
      ),
      grow: 1,
    },
    {
      name: 'Class',
      selector: (row: any) => row.class.name,
      grow: 1,
    },
    {
      name: 'Date/Time',
      selector: (row: any) =>
        `${moment(row.acidentDate).format('MM/DD/YY')}/${moment(row.acidentTime).format('h:mm A')}`,
      grow: 1,
    },
  ];

  const columnsParent = [
    {
      name: 'Name',
      selector: (row: any) => <span>{`${row?.kids.firstName || '-'} ${row?.kids.lastName || '-'}`}</span>,
      grow: 1,
    },
    {
      name: 'Class',
      selector: (row: any) => row.class.name,
      grow: 1,
    },
    {
      name: 'Date/Time',
      selector: (row: any) =>
        `${moment(row.acidentDate).format('MM/DD/YY')}/${moment(row.acidentTime).format('h:mm A')}`,
      grow: 1,
    },
  ];

  useEffect(() => {
    loadList();
  }, [currentSchoolId, token]);

  useEffect(() => {
    populateTabs();
  }, [data]);

  const loadList = async () => {
    if (!schoolId) return;
    const result = await IncidentReports(token).listBySchool(schoolId);
    setData(
      result.data.filter((value: any) => ['signed_by_teacher', 'signed_by_director'].indexOf(value.status) !== -1),
    );
  };

  const populateTabs = () => {
    const directorData = data.filter((value: any) => value.status === 'signed_by_teacher');
    const parentData = data.filter((value: any) => value.status === 'signed_by_director');

    setDirector(directorData);
    setParent(parentData);
  };

  return (
    <>
      <CardInfo
        count={data.length}
        class="bg-danger card-info-mg-button"
        title="Incident Reports"
        icon="fa-hospital"
        toggle={handleToggleModal}
      />
      <ModalContent title="Incident Reports" class="pt-0" modal={toggleModal} toggle={handleToggleModal} size="lg">
        <Row>
          <Col xs="12">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={`${activeTab === '1' ? 'active' : ''}`}
                  onClick={() => setActiveTab('1')}
                  style={customStyles.navLink}
                >
                  Director
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={`${activeTab === '2' ? 'active' : ''}`}
                  onClick={() => setActiveTab('2')}
                  style={customStyles.navLink}
                >
                  Parent
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
          <Col xs="12">
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                {director && (
                  <DataTable
                    header={false}
                    shadowStyle={false}
                    columns={columnsDirector}
                    data={director}
                    totalRows={director.length}
                  />
                )}
              </TabPane>
            </TabContent>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="2">
                {parent && (
                  <DataTable
                    header={false}
                    shadowStyle={false}
                    columns={columnsParent}
                    data={parent}
                    totalRows={parent.length}
                  />
                )}
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </ModalContent>
    </>
  );
};
