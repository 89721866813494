import ScheduleListInterface from '../interfaces/ScheduleListInterface';
import http from '../helpers/http';

const Schedule = () => {
  return {
    show: async (scheduleId: string) => {
      const result = await http.get(`/schedule-tour/${scheduleId}`);
      return result.data;
    },
    create: async (body: Partial<ScheduleListInterface>) => {
      const result = await http.post(`/schedule-tour`, body);
      return result.data;
    },
    update: async (scheduleId: string, body: Partial<ScheduleListInterface>) => {
      const result = await http.put(`/schedule-tour/${scheduleId}`, body);
      return result.data;
    },
    delete: async (scheduleId: string) => {
      const result = await http.delete(`/schedule-tour/${scheduleId}`);
      return result.data;
    },
    timeSlot: async (schoolId: string) => {
      const result = await http.get(`/timeslot-settings/${schoolId}/public`);
      return result.data;
    },
    blockDays: async (schoolId: string) => {
      const result = await http.get(`/blockday-settings/${schoolId}/public`);
      return result.data;
    },
  };
};

export default Schedule;
