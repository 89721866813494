import { useContext, useState } from 'react';
import { Modal } from 'reactstrap';
import Keypad from '../../../components/Core/Keypad';
import AttendancesService from '../../../services/Attendances';
import { toast } from 'react-toastify';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import ClassCheckInSuccessPage from './ClassCheckInSuccessPage';
import ClassCheckedInAlertModal from './ClassCheckedInAlertModal';
import ClassCheckOutSuccessPage from './ClassCheckOutSuccessPage';

type ClassCheckInModalProps = {
  isOpen: boolean;
  classId: string;
  nameClass: string;
  toggleModal: () => void;
};

const ClassCheckInModal = ({ isOpen, classId, nameClass, toggleModal }: ClassCheckInModalProps) => {
  const { token }: any = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [type, setType] = useState('');
  const [className, setClassName] = useState('');
  const [currentClass, setCurrentClass] = useState('');
  const [userPin, setUserPin] = useState<any>({});
  const [pin, setPin] = useState('');
  const [alertModal, setAlertModal] = useState(false);

  const handleSubmit = async (pinCode: string) => {
    setLoading(true);
    try {
      const userResult = await AttendancesService(token).getByPinCode(pinCode);
      const userData = userResult?.data || {};
      if (!userData?.id) {
        setLoading(false);
        return toast.error('Invalid PIN Code');
      }
      if (userData?.isCheckedIn && userData?.lastCheckInClassId !== classId) {
        setCurrentClass(userData?.lastCheckInClassName);
        return toggleAlert(pinCode);
      }
      submit(pinCode);
    } catch (e: any) {
      toast.error('Invalid PIN Code');
      setSuccess(false);
    } finally {
      setLoading(false);
    }
  };

  const submit = async (pinCode: string) => {
    setLoading(true);
    try {
      const result = await AttendancesService(token).checkIn(pinCode, classId);
      setClassName(result?.data?.room?.name || '');
      setUserPin(result?.data?.user || {});
      setType(result?.data?.attendanceType || '');
      toast.success('Saved successfully!');
      setSuccess(true);
    } catch (e: any) {
      toast.error('Invalid PIN Code');
      setSuccess(false);
    } finally {
      setLoading(false);
    }
  };

  const toggleAlert = async (pinCode: string) => {
    setPin(pinCode);
    setAlertModal(true);
  };

  const toggle = async () => {
    setSuccess(false);
    setLoading(false);
    setClassName('');
    toggleModal();
  };

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        size="sm"
        isOpen={isOpen}
        toggle={(e: any) => {
          e.preventDefault();
          toggle();
        }}
      >
        <div className="modal-header pb-1">
          <h5 className="modal-title">Check In / Check Out - "{nameClass}"</h5>
          <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => toggle()}>
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body pt-0">
          {success ? (
            <>
              {type === 'CHECKIN' ? (
                <ClassCheckInSuccessPage className={className} user={userPin} />
              ) : (
                <ClassCheckOutSuccessPage className={className} user={userPin} />
              )}
            </>
          ) : (
            <Keypad loading={loading} changePinCode={(_: string) => {}} submitKeypadPin={handleSubmit} />
          )}
        </div>
      </Modal>
      <ClassCheckedInAlertModal
        nameClass={currentClass}
        pinCode={pin}
        isOpen={alertModal}
        toggleModal={() => setAlertModal(!alertModal)}
        toggleSubmit={submit}
      />
    </>
  );
};

export default ClassCheckInModal;
