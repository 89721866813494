import http from '../helpers/http';
import { FilteredOptionsInterface, makeQueryParamsForRequest } from '../entities/FilteredOptions';
import KidsInterface from '../interfaces/KidsInterface';

const Kids = (token: string) => {
  const headers = {
    'x-access-token': token,
  };

  return {
    list: async (filteredOptions?: FilteredOptionsInterface, schoolId?: string) => {
      const params = makeQueryParamsForRequest(filteredOptions);
      if (schoolId) {
        params.schoolId = schoolId;
      }
      const result = await http.get('/kids', {
        headers,
        params,
      });
      return result.data;
    },
    listRequiredImmunization: async (id: string) => {
      const result = await http.get(`/immunizations/${id}/requiredImmunization`, {
        headers,
      });
      return result.data;
    },
    show: async (id: string) => {
      const result = await http.get(`/kids/${id}`, {
        headers,
      });
      return result.data;
    },
    create: async (body: Partial<KidsInterface>) => {
      const result = await http.post(`/kids`, body, { headers });
      return result.data;
    },
    update: async (id: string, body: Partial<KidsInterface>) => {
      const result = await http.put(`/kids/${id}`, body, { headers });
      return result.data;
    },
    delete: async (id: string) => {
      const result = await http.delete(`/kids/${id}`, {
        headers,
      });
      return result.data;
    },
    picture: async (kidsId: string, body: FormData) => {
      const headers = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-access-token': token,
        },
      };
      const result = await http.patch(`/kids/${kidsId}/picture`, body, headers);
      return result.data;
    },
    listByClass: async (classId: string) => {
      const result = await http.get(`/class/${classId}/kids`, {
        headers,
      });
      return result.data;
    },
    listBySchool: async (schoolId: string) => {
      const result = await http.get(`/school/${schoolId}/school`, {
        headers,
      });
      return result.data;
    },
    listBirthdayBySchool: async (schoolId: string) => {
      const result = await http.get(`/school/${schoolId}/birthday`, {
        headers,
      });
      return result.data;
    },
    billingTurnedOn: async (kidsId: string, turnedOn: boolean) => {
      const result = await http.patch(`/kids/${kidsId}/billing/${turnedOn}`, {}, { headers });
      return result.data;
    },
    updateBillingAmount: async (
      kidsId: string,
      body: { updateBy: string; value: number; updatePriceSheet: boolean; classId: string; classTypeId: string },
    ) => {
      const result = await http.patch(`/kids/${kidsId}/billing-amount`, body, { headers });
      return result.data;
    },
    autochargeTurnedOn: async (kidsId: string, turnedOn: boolean) => {
      const result = await http.patch(`/kids/${kidsId}/autocharge/${turnedOn}`, {}, { headers });
      return result.data;
    },
    listAnniversaryBySchool: async (schoolId: string) => {
      const result = await http.get(`/school/${schoolId}/anniversary`, {
        headers,
      });
      return result.data;
    },
    enrollDeny: async (kidsId: string) => {
      const result = await http.patch(`/kids/${kidsId}/deny`, {}, { headers });
      return result.data;
    },
    removePromoCode: async (kidsId: string, body: any) => {
      const result = await http.put(`/kids/${kidsId}/update-promo-code`, body, { headers });
      return result.data;
    },
  };
};

export default Kids;
