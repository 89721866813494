/* eslint-disable max-lines */
import { Card, CardHeader, CardBody, Container, Row, Col, Input, Button, Badge } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useContext, useState, useEffect } from 'react';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import CommonHeader from '../../../components/Headers/CommonHeader';
import SupportService from '../../../services/Support';
import SupportListInterface, { validStatus, statusEnum } from '../../../interfaces/SupportListInterface';
import { toDateTimePrintFormat } from '../../../helpers/dateFormat';
import { PagedMetaInterface, createPagedMeta } from '../../../entities/PagedMeta';
import { OptionsPagedInterface, createOptionsPage } from '../../../entities/OptionsPaged';
import DepartmentModal from './DepartmentModal';
import { FilterInterface, FilteredOptionsInterface } from '../../../entities/FilteredOptions';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';
import Pagination from '../../../components/Core/Pagination';
import isAdmin from '../../../helpers/isAdmin';

const SupportList = () => {
  const { token, currentSchool, user }: any = useContext(AuthContext);
  const [status, setStatus] = useState('OPEN');
  const [countStatus, setCountStatus] = useState({
    OPEN: { total: 0 },
    PENDING: { total: 0 },
    CLOSED: { total: 0 },
  });

  const [data, setData] = useState([] as any[]);
  const [loading, setLoading] = useState(false);
  const [pagedMeta, setPagedMeta] = useState(createPagedMeta());
  const [filteredOptions, setFilteredOptions] = useState({
    filters: [],
    options: createOptionsPage(pagedMeta),
  } as { filters: FilterInterface[]; options: OptionsPagedInterface });
  const [isDiscountsOpen, setIsDiscountsOpen] = useState(false);
  const [priority, setPriority] = useState('');
  const [type, setType] = useState('');

  useEffect(() => {
    loadList(filteredOptions);
    loadCountByStatus();
  }, [filteredOptions, status, currentSchool]);

  useEffect(() => {
    loadList(filteredOptions);
  }, [priority, type]);

  const updateCurrentSupportItem = async (id: string, toUpdate: string, selected: any) => {
    if (loading) return;

    setLoading(true);

    const body = {};
    (body as any)[toUpdate] = selected.value;
    try {
      await SupportService(token).update(id, body);
      toast.success(`${ucfirst(toUpdate)} updated with successfully!`);
      await loadList(filteredOptions);
      await loadCountByStatus();
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'error!');
      toast.error(message);
    }

    setLoading(false);
  };

  const asyncSelectStyles = {
    control: (styles: any) => ({
      ...styles,
      ...{ border: 0 },
    }),
    input: (styles: any) => ({ ...styles, ...{ border: 0 } }),
    placeholder: (styles: any) => ({
      ...styles,
      ...{ fontSize: '0.810rem', fontWeight: 400, lineHeight: 1.5, color: '#8898aa', textOverflow: 'clip' },
    }),
    singleValue: (styles: any) => ({
      ...styles,
      ...{ fontSize: '0.810rem', fontWeight: 400, lineHeight: 1.5, color: '#8898aa', textOverflow: 'clip' },
    }),
  };

  const loadCountByStatus = async () => {
    try {
      setLoading(true);
      const result = await SupportService(token || '').countByStatus(currentSchool.value);
      const counts = countStatus;
      counts.OPEN.total = result.data.find((item: any) => item.status === 'OPEN')?.total || 0;
      counts.PENDING.total = result.data.find((item: any) => item.status === 'PENDING')?.total || 0;
      counts.CLOSED.total = result.data.find((item: any) => item.status === 'CLOSED')?.total || 0;
      setCountStatus(counts);
    } finally {
      setLoading(false);
    }
  };
  const loadList = async (filteredOptions: FilteredOptionsInterface) => {
    try {
      setLoading(true);

      let result: any = {};

      const filters = [
        {
          field: 'schoolId',
          operation: 'hash',
          value: currentSchool.value,
        },
      ];
      if (priority !== '') {
        filters.push({
          field: 'priority',
          operation: '=',
          value: priority,
        });
      }
      if (type !== '') {
        filters.push({
          field: 'type',
          operation: '=',
          value: type,
        });
      }
      filteredOptions.filters = filters;
      filteredOptions.options = { ...filteredOptions.options, sortBy: ['createdAt'], sortDesc: [true] };
      // filteredOptions.options.itemsPerPage = 1;
      // const options = { ...filteredOptions.options, sortBy: [column], sortDesc: [direction !== 'asc'] };
      if (!status) {
        result = await SupportService(token || '').list(filteredOptions);
      } else {
        result = await SupportService(token || '').listStatus(status, filteredOptions);
      }
      const data: SupportListInterface[] = result.data;
      setData(data);
      const meta: PagedMetaInterface = result.meta;
      if (meta) {
        setPagedMeta(meta);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleFilterChange = (status: string) => {
    setStatus(status);
  };

  const handlePriorityFilterChange = async (priority: string) => {
    setPriority(priority);
  };

  const ucfirst = (text: string) => {
    if (text === '' || text === undefined) {
      return '';
    }
    return text[0].toUpperCase() + text.substring(1).toLowerCase();
  };

  const toggleModalDiscounts = () => {
    setIsDiscountsOpen(!isDiscountsOpen);
  };

  const handlePerRowsChange = async (perPage: number) => {
    const newOptions = {
      ...pagedMeta,
      perPage,
    };
    const newFilteredOptions = {
      filters: filteredOptions.filters,
      options: createOptionsPage(newOptions),
    };
    setFilteredOptions(newFilteredOptions);
  };

  const handlePageChange = async (page: number) => {
    const newOptions = {
      ...pagedMeta,
      currentPage: page,
    };
    const newFilteredOptions = {
      filters: filteredOptions.filters,
      options: createOptionsPage(newOptions),
    };
    setFilteredOptions(newFilteredOptions);
  };

  const DataGrid = (items: any) => {
    if (items.items.length === 0) {
      return '';
    }
    return items.items.map((item: any, i: number) => {
      return (
        <>
          <div style={{ display: 'flex', backgroundColor: '#fff' }} className="mb-2" key={`data-grid_${i}`}>
            <div style={{ width: '120px' }}>
              <div
                className="avatar avatar-80 rounded-circle"
                style={{ display: 'block', marginLeft: '20px', marginTop: '20px' }}
              >
                <img
                  alt={`${item.user.firstName}`}
                  src={
                    item.user?.picture
                      ? `${item.user.picture}`
                      : require('../../../assets/img/user-default.png').default
                  }
                  style={{ height: '100%' }}
                />
              </div>
              <div
                style={{
                  width: '100%',
                  height: '20px',
                  textAlign: 'center',
                  padding: ' 0 5px',
                }}
              >
                {item.user.firstName}
              </div>
            </div>
            <div style={{ flexGrow: '2', position: 'relative' }}>
              <div style={{ height: '75px', overflow: 'hidden', padding: '0 10px' }}>
                <Link to={`/support/${item.id}`}>
                  <b>
                    {item.department} : {item.subject}
                  </b>
                  <p style={{ color: '#000' }}>{item.description}</p>
                </Link>
              </div>
              <div
                style={{
                  height: '25px',
                  padding: '0 10px',
                  background: '#F5F6FB',
                  position: 'absolute',
                  bottom: 0,
                  width: '100%',
                }}
              >
                <small style={{ color: '#939393' }}>{toDateTimePrintFormat(item.createdAt)}</small>
              </div>
            </div>
            <div style={{ minWidth: '200px', borderLeft: '2px solid #F5F6FB' }}>
              <div style={{ padding: '0 10px' }}>
                <div style={{ textAlign: 'left', display: 'table', width: '100%' }}>
                  <Badge
                    color=""
                    className="badge-dot badge-lg"
                    style={{ fontSize: '0.8125rem', verticalAlign: 'middle', lineHeight: 'normal' }}
                  >
                    {item.type === 'FEATURE_REQUEST' ? 'Feature Request' : ucfirst(item.type)}
                  </Badge>
                </div>
                <div style={{ textAlign: 'left', display: 'table', width: '100%' }}>
                  <span style={{ display: 'table-cell', verticalAlign: 'middle' }}>
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      formatOptionLabel={(option: any) => {
                        return (
                          <div className="select-option">
                            <Badge
                              color=""
                              className="badge-dot badge-lg"
                              style={{ fontSize: '0.8125rem', verticalAlign: 'middle', lineHeight: 'normal' }}
                            >
                              {option.label}
                              <i
                                className={`bg-${validStatus[option.value as statusEnum].color} mr-2 ml-2`}
                                style={{ verticalAlign: 'baseline' }}
                              />
                            </Badge>
                          </div>
                        );
                      }}
                      loadOptions={async () => {
                        return Object.keys(countStatus).map((d) => ({ label: ucfirst(d), value: d }));
                      }}
                      styles={asyncSelectStyles}
                      value={{ label: ucfirst(item.status), value: item.status }}
                      onChange={async (selected: any) => updateCurrentSupportItem(item.id, 'status', selected)}
                    />
                  </span>
                </div>
                <div style={{ textAlign: 'left', display: 'table', width: '100%' }}>
                  {!isAdmin(user) && (
                    <Badge
                      color=""
                      className="badge-dot badge-lg"
                      style={{ fontSize: '0.8125rem', verticalAlign: 'middle', lineHeight: 'normal' }}
                    >
                      {ucfirst(item.priority)}
                    </Badge>
                  )}

                  {isAdmin(user) && (
                    <span style={{ display: 'table-cell', verticalAlign: 'middle', textTransform: 'capitalize' }}>
                      <AsyncSelect
                        cacheOptions
                        defaultOptions
                        loadOptions={async () => {
                          return ['High', 'Medium', 'Low'].map((d) => ({
                            label: ucfirst(d),
                            value: d,
                          }));
                        }}
                        styles={asyncSelectStyles}
                        value={{
                          label: ucfirst(item.priority),
                          value: item.priority,
                        }}
                        onChange={async (selected: any) => updateCurrentSupportItem(item.id, 'priority', selected)}
                      />
                    </span>
                  )}
                </div>
              </div>
              <div style={{ background: '#F5F6FB' }}>&nbsp;</div>
            </div>
          </div>
        </>
      );
    });
  };

  return (
    <>
      <CommonHeader />
      <Container className="mt--8" fluid>
        <Row>
          <Col className="order-xl-2" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="6">
                    <Row>
                      <Col xs="12">
                        <h3 className="mb-0">Support</h3>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12">
                        <p> </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12">
                        <div className="btn-group" role="group">
                          <button
                            type="button"
                            className={`btn btn-${status === 'OPEN' ? 'primary' : 'secondary'}`}
                            onClick={() => handleFilterChange('OPEN')}
                          >
                            Open ({countStatus['OPEN' as statusEnum].total})
                          </button>
                          <button
                            type="button"
                            className={`btn btn-${status === 'PENDING' ? 'primary' : 'secondary'}`}
                            onClick={() => handleFilterChange('PENDING')}
                          >
                            Pending ({countStatus['PENDING' as statusEnum].total})
                          </button>
                          <button
                            type="button"
                            className={`btn btn-${status === 'CLOSED' ? 'primary' : 'secondary'}`}
                            onClick={() => handleFilterChange('CLOSED')}
                          >
                            Closed ({countStatus['CLOSED' as statusEnum].total})
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col className="text-right" xs="6">
                    {isAdmin(user) && (
                      <Button onClick={toggleModalDiscounts}>
                        <i className="fas fa-cog"></i>
                      </Button>
                    )}
                    <Link to={'/support/new'}>
                      <Button color="primary" className="float-right">
                        Create New
                      </Button>
                    </Link>
                  </Col>
                </Row>
                <h3 className="mt-3">Filter</h3>
                <Row>
                  <Col xs="3">
                    <Input type="select" onChange={(e) => handlePriorityFilterChange(e.target.value)} value={priority}>
                      <option value="" key={`filter-button-boy`}>
                        Show all status
                      </option>
                      <option value="LOW" key={`filter-button-girl`}>
                        Show only low
                      </option>
                      <option value="MEDIUM" key={`filter-button-medium`}>
                        Show only medium
                      </option>
                      <option value="HIGH" key={`filter-button-high`}>
                        Show only high
                      </option>
                    </Input>
                  </Col>
                  <Col xs="3">
                    <Input type="select" onChange={(e) => setType(e.target.value)} value={type}>
                      <option value="" key={`filter-button-boy`}>
                        Show all types
                      </option>
                      <option value="FEATURE_REQUEST">Feature Request</option>
                      <option value="INCIDENT">Incident</option>
                      <option value="PROBLEM">Problem</option>
                      <option value="QUESTION">Question</option>
                      <option value="Polo & T-Shirt Order">Polo & T-Shirt Order</option>
                      <option value="Staff Welcome Bags">Staff Welcome Bags</option>
                      <option value="Children Welcome Bags">Children Welcome Bags</option>
                      <option value="Location">Location</option>
                      <option value="Contacted Builder">Contacted Builder</option>
                    </Input>
                  </Col>
                </Row>
                <Row></Row>
              </CardHeader>
              <CardBody>
                <DataGrid items={data} />
                <Pagination
                  data={data}
                  meta={pagedMeta}
                  onChangeRowsPerPage={async (perPage: any) => handlePerRowsChange(perPage)}
                  onChangePage={async (page: number) => handlePageChange(page)}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <DepartmentModal isOpen={isDiscountsOpen} toggleModal={() => toggleModalDiscounts()} />
    </>
  );
};

export default SupportList;
