/* eslint-disable max-lines */
import { Button, Card, CardHeader, CardBody, FormGroup, Form, Input, Container, Row, Col } from 'reactstrap';
import { toast } from 'react-toastify';
import { useContext, useState, useEffect, useRef } from 'react';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import CommonHeader from '../../../components/Headers/CommonHeader';
import SupportService from '../../../services/Support';
import { Link } from 'react-router-dom';
import SchoolService from '../../../services/School';
import history from '../../../history';
import { AnniverssaryTShirtForm } from './AnniverssaryTShirtForm';
import isAdmin from '../../../helpers/isAdmin';
import isParent from '../../../helpers/isParent';

const SupportNew = () => {
  const { token, currentSchool, user }: any = useContext(AuthContext);
  const isAdminUser = isAdmin(user);
  const isParentUser = isParent(user);
  const fileRef = useRef(null as any);

  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [schoolId, setSchoolId] = useState('');
  const [name, setName] = useState(`${user.firstName} ${user.lastname || ''}`);
  const [email, setEmail] = useState(user.email);
  const [department, setDepatment] = useState(isParentUser ? 'App' : '');
  const [type, setType] = useState('');
  const [priority, setPriority] = useState('');
  const [description, setDescription] = useState('');
  const [subject, setSubject] = useState('');

  const [tShirtType, setTShirtType] = useState('');
  const [tShirtTQuantityXS, setTShirtTQuantityXS] = useState('');
  const [tShirtTQuantityS, setTShirtTQuantityS] = useState('');
  const [tShirtTQuantityM, setTShirtTQuantityM] = useState('');
  const [tShirtTQuantityL, setTShirtTQuantityL] = useState('');
  const [tShirtTQuantityXL, setTShirtTQuantityXL] = useState('');
  const [tShirtTQuantity2XL, setTShirtTQuantity2XL] = useState('');
  const [tShirtTQuantity3XL, setTShirtTQuantity3XL] = useState('');

  const [isPoloShirt, setIsPoloShirt] = useState(false);
  const [poloShirtXS, setPoloShirtXS] = useState('');
  const [poloShirtS, setPoloShirtS] = useState('');
  const [poloShirtM, setPoloShirtM] = useState('');
  const [poloShirtL, setPoloShirtL] = useState('');
  const [poloShirtXL, setPoloShirtXL] = useState('');
  const [poloShirt2XL, setPoloShirt2XL] = useState('');
  const [poloShirt3XL, setPoloShirt3XL] = useState('');

  const [anniversarytShirt, setAnniversaryTShirt] = useState([
    {
      type: '',
      model: '',
      quantityXs: '',
      quantityS: '',
      quantityM: '',
      quantityL: '',
      quantityXl: '',
      quantity2xl: '',
      quantity3xl: '',
    },
  ]);

  const [selectList, setSelectList] = useState([{}]);
  const [attachment, setAttachment] = useState(null as any);

  const addMoreFields = () => {
    const newFiledsToAnniverssaryShirts = [...anniversarytShirt];
    newFiledsToAnniverssaryShirts.push({
      type: '',
      model: '',
      quantityXs: '',
      quantityS: '',
      quantityM: '',
      quantityL: '',
      quantityXl: '',
      quantity2xl: '',
      quantity3xl: '',
    });
    setAnniversaryTShirt(newFiledsToAnniverssaryShirts);
  };

  const setModel = (value: string, index: number) => {
    const newShirts = [...anniversarytShirt];
    newShirts.forEach((shirt, i) => {
      if (i == index) {
        shirt.model = value;
      }
    });
    setAnniversaryTShirt(newShirts);
  };
  const setQuantityXs = (value: string, index: number) => {
    const newShirts = [...anniversarytShirt];
    newShirts.forEach((shirt, i) => {
      if (i == index) {
        shirt.quantityXs = value;
      }
    });

    setAnniversaryTShirt(newShirts);
  };
  const setQuantityS = (value: string, index: number) => {
    const newShirts = [...anniversarytShirt];
    newShirts.forEach((shirt, i) => {
      if (i == index) {
        shirt.quantityS = value;
      }
    });
    setAnniversaryTShirt(newShirts);
  };
  const setQuantityM = (value: string, index: number) => {
    const newShirts = [...anniversarytShirt];
    newShirts.forEach((shirt, i) => {
      if (i == index) {
        shirt.quantityM = value;
      }
    });
    setAnniversaryTShirt(newShirts);
  };
  const setQuantityL = (value: string, index: number) => {
    const newShirts = [...anniversarytShirt];
    newShirts.forEach((shirt, i) => {
      if (i == index) {
        shirt.quantityL = value;
      }
    });
    setAnniversaryTShirt(newShirts);
  };
  const setQuantityXl = (value: string, index: number) => {
    const newShirts = [...anniversarytShirt];
    newShirts.forEach((shirt, i) => {
      if (i == index) {
        shirt.quantityXl = value;
      }
    });
    setAnniversaryTShirt(newShirts);
  };
  const setQuantity2xl = (value: string, index: number) => {
    const newShirts = [...anniversarytShirt];
    newShirts.forEach((shirt, i) => {
      if (i == index) {
        shirt.quantity2xl = value;
      }
    });
    setAnniversaryTShirt(newShirts);
  };
  const setQuantity3xl = (value: string, index: number) => {
    const newShirts = [...anniversarytShirt];
    newShirts.forEach((shirt, i) => {
      if (i == index) {
        shirt.quantity3xl = value;
      }
    });
    setAnniversaryTShirt(newShirts);
  };

  const listSelectSchool = async () => {
    try {
      const result = await SchoolService(token).listSelect();
      setSelectList(result.data || []);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No Schools found');
    }
  };

  useEffect(() => {
    setIsDisabled(false);
    listSelectSchool();
  }, []);

  useEffect(() => {
    setSchoolId(currentSchool.value || '');
  }, [currentSchool]);

  useEffect(() => {
    setType('');
  }, [department]);

  const showOpenFileDialog = () => {
    if (isDisabled) {
      return;
    }
    if (fileRef?.current?.click) {
      fileRef.current.click();
    }
  };

  const onChangeFile = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];
    setAttachment(file);
  };

  const handleDeleteShirtSection = (index: number) => {
    const newShirts = [...anniversarytShirt];
    newShirts.splice(index, 1);
    setAnniversaryTShirt(newShirts);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);
    setIsDisabled(false);

    const body: any = {
      schoolId,
      name,
      email,
      department,
      type,
      priority,
      description,
      subject,
    };

    if (type === 'Polo & T-Shirt Order') {
      body.shirts = [];

      if (tShirtType) {
        body.shirts.push({
          type: 'T-Shirts',
          model: tShirtType,
          xsQuantity: parseInt(tShirtTQuantityXS) || 0,
          sQuantity: parseInt(tShirtTQuantityS) || 0,
          mQuantity: parseInt(tShirtTQuantityM) || 0,
          lQuantity: parseInt(tShirtTQuantityL) || 0,
          xlQuantity: parseInt(tShirtTQuantityXL) || 0,
          '2xlQuantity': parseInt(tShirtTQuantity2XL) || 0,
          '3xlQuantity': parseInt(tShirtTQuantity3XL) || 0,
        });
      }

      if (isPoloShirt) {
        body.shirts.push({
          type: 'Polo Shirts',
          xsQuantity: parseInt(poloShirtXS) || 0,
          sQuantity: parseInt(poloShirtS) || 0,
          mQuantity: parseInt(poloShirtM) || 0,
          lQuantity: parseInt(poloShirtL) || 0,
          xlQuantity: parseInt(poloShirtXL) || 0,
          '2xlQuantity': parseInt(poloShirt2XL) || 0,
          '3xlQuantity': parseInt(poloShirt3XL) || 0,
        });
      }

      const newAnniverssaryShirts = [...anniversarytShirt];
      newAnniverssaryShirts.forEach((shirt) => {
        if (shirt.model) {
          body.shirts.push({
            type: 'Anniversary T-shirts',
            model: shirt.model,
            xsQuantity: parseInt(shirt.quantityXs) || 0,
            sQuantity: parseInt(shirt.quantityS) || 0,
            mQuantity: parseInt(shirt.quantityM) || 0,
            lQuantity: parseInt(shirt.quantityL) || 0,
            xlQuantity: parseInt(shirt.quantityXl) || 0,
            '2xlQuantity': parseInt(shirt.quantity2xl) || 0,
            '3xlQuantity': parseInt(shirt.quantity3xl) || 0,
          });
        }
      });
    }

    try {
      const result = await SupportService(token).create(body);
      if (attachment) {
        const formData = new FormData();
        formData.append('attachment', attachment as any);
        await SupportService(token).attachment(result?.data?.id, formData);
      }
      toast.success('Your ticket has been submitted successfully!');
      setLoading(false);
      if (isAdminUser) {
        return history.push('/support');
      } else {
        setDepatment(isParentUser ? 'App' : '');
        setType('');
        setPriority('');
        setSubject('');
        setDescription('');
      }
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Create error!');
      toast.error(message);
    }

    setLoading(false);
  };

  return (
    <>
      <CommonHeader />
      <Container className="mt--8" fluid>
        <Row>
          <Col className="order-xl-2" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Create new Support Ticket</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    {isAdminUser && (
                      <Link to="/support/">
                        <Button color="primary" className="float-right">
                          Back
                        </Button>
                      </Link>
                    )}
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmit}>
                  <div className="pl-lg-4">
                    {isAdminUser && (
                      <>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label className="form-control-label" htmlFor="input-school-id">
                                School
                              </label>
                              <Input
                                type="select"
                                onChange={(e) => setSchoolId(e.target.value)}
                                value={schoolId}
                                disabled
                              >
                                {selectList?.map((filter: any, index: number) => {
                                  return (
                                    <option value={filter.id} key={`filter-button-${index}`}>
                                      {filter.name}
                                    </option>
                                  );
                                })}
                              </Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label className="form-control-label" htmlFor="input-school-id">
                                Name
                              </label>
                              <Input type="text" onChange={(e) => setName(e.target.value)} value={name} disabled />
                            </FormGroup>
                          </Col>

                          <Col lg="6">
                            <FormGroup>
                              <label className="form-control-label" htmlFor="input-school-id">
                                Email
                              </label>
                              <Input type="text" onChange={(e) => setEmail(e.target.value)} value={email} disabled />
                            </FormGroup>
                          </Col>
                        </Row>
                      </>
                    )}
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-subject">
                            Department
                          </label>
                          <select
                            name="input-priority"
                            id="input-priority"
                            className="form-control"
                            disabled={isParentUser || isDisabled}
                            value={department}
                            onChange={(e) => setDepatment(e.target.value)}
                            required
                          >
                            {!isParentUser && (
                              <option value="" className="text-muted" disabled>
                                Select a Department
                              </option>
                            )}
                            <option value="App">App</option>
                            {!isParentUser && (
                              <>
                                <option value="IT">IT</option>
                                <option value="Merchandise">Merchandise</option>
                                <option value="Maintenance">Maintenance</option>
                                <option value="New Construction">New Construction</option>
                              </>
                            )}
                          </select>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>

                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-type">
                            Type
                          </label>

                          <select
                            name="input-type"
                            id="input-type"
                            className="form-control"
                            disabled={isDisabled}
                            value={type}
                            onChange={(e) => setType(e.target.value)}
                            required
                          >
                            <option value="" disabled className="text-muted" selected>
                              Select a Type
                            </option>
                            {(department === 'App' || department === 'IT') && (
                              <>
                                <option value="FEATURE_REQUEST">Feature Request</option>
                                <option value="INCIDENT">Incident</option>
                                <option value="PROBLEM">Problem</option>
                                <option value="QUESTION">Question</option>
                              </>
                            )}
                            {department === 'Merchandise' && (
                              <>
                                <option value="Polo & T-Shirt Order">Polo & T-Shirt Order</option>
                                <option value="Staff Welcome Bags">Staff Welcome Bags</option>
                                <option value="Children Welcome Bags">Children Welcome Bags</option>
                              </>
                            )}
                            {department === 'Maintenance' && (
                              <>
                                <option value="Location">Location (a text box appears)</option>
                              </>
                            )}

                            {department === 'New Construction' && (
                              <>
                                <option value="Location">Location (a text box appears)</option>
                                <option value="Contacted Builder">
                                  Contacted Builder (yes or no check box appears)
                                </option>
                              </>
                            )}
                          </select>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-priority">
                            Priority
                          </label>

                          <select
                            name="input-priority"
                            id="input-priority"
                            className="form-control"
                            disabled={isDisabled}
                            value={priority}
                            onChange={(e) => setPriority(e.target.value)}
                            required
                          >
                            <option value="" disabled className="text-muted" selected>
                              Select a Priority
                            </option>
                            <option value="LOW">Low</option>
                            <option value="MEDIUM">Medium</option>
                            <option value="HIGH">High</option>
                          </select>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>

                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-school-id">
                            Subject
                          </label>
                          <Input type="text" onChange={(e) => setSubject(e.target.value)} value={subject} />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>

                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-description">
                            Description
                          </label>
                          <textarea
                            className="form-control"
                            id="input-description"
                            placeholder="Description"
                            disabled={isDisabled}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <Button
                          className="btn-icon btn-sm"
                          color="secondary"
                          type="button"
                          id="input-attach"
                          onClick={showOpenFileDialog}
                          style={{ marginBottom: '2px' }}
                        >
                          <span className="btn-inner--icon pt-1" style={{ marginRight: '1px' }}>
                            <i className="fas fa-paperclip" style={{ top: '0' }} />
                          </span>
                        </Button>
                        <span>{attachment?.name || 'Select a file'}</span>
                        <input
                          id="img-attach"
                          name="attach"
                          type="file"
                          accept=".png,.jpg,.jpeg,.pdf"
                          style={{ display: 'none' }}
                          ref={fileRef}
                          disabled={isDisabled}
                          onChange={onChangeFile}
                        />
                      </Col>
                    </Row>

                    {type === 'Polo & T-Shirt Order' && (
                      <>
                        <hr></hr>

                        <div>
                          <h2 className="text-primary">T-Shirts</h2>
                          <hr></hr>

                          <Row>
                            <Col lg="6">
                              <FormGroup className="d-flex align-items-center">
                                <select
                                  name="input-priority"
                                  id="input-priority"
                                  className="form-control"
                                  disabled={isDisabled}
                                  value={tShirtType}
                                  onChange={(e) => setTShirtType(e.target.value)}
                                >
                                  <option value="" selected>
                                    Select Month
                                  </option>
                                  <option value="January">January</option>
                                  <option value="February">February</option>
                                  <option value="March">March</option>
                                  <option value="April">April</option>
                                  <option value="May">May</option>
                                  <option value="June">June</option>
                                  <option value="July">July</option>
                                  <option value="August">August</option>
                                  <option value="September">September</option>
                                  <option value="October">October</option>
                                  <option value="November">November</option>
                                  <option value="December">December</option>
                                </select>
                              </FormGroup>
                            </Col>
                          </Row>

                          {tShirtType && (
                            <Row>
                              <Col lg="1">
                                <FormGroup>
                                  <label className="form-control-label text-primary pl-1" htmlFor="input-priority">
                                    XS
                                  </label>

                                  <input
                                    name="input-priority"
                                    type="text"
                                    id="input-priority"
                                    placeholder="e.g"
                                    className="form-control"
                                    disabled={isDisabled}
                                    value={tShirtTQuantityXS}
                                    onChange={(e) => setTShirtTQuantityXS(e.target.value.replace(/[^0-9]/g, ''))}
                                  />
                                </FormGroup>
                              </Col>

                              <Col lg="1">
                                <FormGroup>
                                  <label className="form-control-label text-primary pl-1" htmlFor="input-priority">
                                    S
                                  </label>

                                  <input
                                    name="input-priority"
                                    type="text"
                                    id="input-priority"
                                    placeholder="e.g"
                                    className="form-control"
                                    disabled={isDisabled}
                                    value={tShirtTQuantityS}
                                    onChange={(e) => setTShirtTQuantityS(e.target.value.replace(/[^0-9]/g, ''))}
                                  />
                                </FormGroup>
                              </Col>

                              <Col lg="1">
                                <FormGroup>
                                  <label className="form-control-label text-primary pl-1" htmlFor="input-priority">
                                    M
                                  </label>

                                  <input
                                    name="input-priority"
                                    type="text"
                                    id="input-priority"
                                    placeholder="e.g"
                                    className="form-control"
                                    disabled={isDisabled}
                                    value={tShirtTQuantityM}
                                    onChange={(e) => setTShirtTQuantityM(e.target.value.replace(/[^0-9]/g, ''))}
                                  />
                                </FormGroup>
                              </Col>

                              <Col lg="1">
                                <FormGroup>
                                  <label className="form-control-label text-primary pl-1" htmlFor="input-priority">
                                    L
                                  </label>

                                  <input
                                    name="input-priority"
                                    type="text"
                                    id="input-priority"
                                    placeholder="e.g"
                                    className="form-control"
                                    disabled={isDisabled}
                                    value={tShirtTQuantityL}
                                    onChange={(e) => setTShirtTQuantityL(e.target.value.replace(/[^0-9]/g, ''))}
                                  />
                                </FormGroup>
                              </Col>

                              <Col lg="1">
                                <FormGroup>
                                  <label className="form-control-label text-primary pl-1" htmlFor="input-priority">
                                    XL
                                  </label>

                                  <input
                                    name="input-priority"
                                    type="text"
                                    id="input-priority"
                                    placeholder="e.g"
                                    className="form-control"
                                    disabled={isDisabled}
                                    value={tShirtTQuantityXL}
                                    onChange={(e) => setTShirtTQuantityXL(e.target.value.replace(/[^0-9]/g, ''))}
                                  />
                                </FormGroup>
                              </Col>

                              <Col lg="1">
                                <FormGroup>
                                  <label className="form-control-label text-primary pl-1" htmlFor="input-priority">
                                    2XL
                                  </label>

                                  <input
                                    name="input-priority"
                                    type="text"
                                    id="input-priority"
                                    placeholder="e.g"
                                    className="form-control"
                                    disabled={isDisabled}
                                    value={tShirtTQuantity2XL}
                                    onChange={(e) => setTShirtTQuantity2XL(e.target.value.replace(/[^0-9]/g, ''))}
                                  />
                                </FormGroup>
                              </Col>

                              <Col lg="1">
                                <FormGroup>
                                  <label className="form-control-label text-primary pl-1" htmlFor="input-priority">
                                    3XL
                                  </label>

                                  <input
                                    name="input-priority"
                                    type="text"
                                    id="input-priority"
                                    placeholder="e.g"
                                    className="form-control"
                                    disabled={isDisabled}
                                    value={tShirtTQuantity3XL}
                                    onChange={(e) => setTShirtTQuantity3XL(e.target.value.replace(/[^0-9]/g, ''))}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          )}

                          <h2 className="text-primary mt-4">Polo Shirts</h2>
                          <hr></hr>
                          <Col lg="3">
                            <FormGroup>
                              <Row>
                                <Col>
                                  <label className="form-control-label">Polo Shirts?</label>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <label className="custom-toggle">
                                    <Input
                                      type="checkbox"
                                      onChange={(e) => {
                                        setIsPoloShirt(e.target.checked);
                                      }}
                                      checked={isPoloShirt}
                                    />
                                    <span className="custom-toggle-slider rounded-circle" />
                                  </label>
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>

                          {isPoloShirt && (
                            <Row>
                              <Col lg="1">
                                <FormGroup>
                                  <label className="form-control-label text-primary pl-1" htmlFor="input-priority">
                                    XS
                                  </label>

                                  <input
                                    name="input-priority"
                                    type="text"
                                    id="input-priority"
                                    placeholder="e.g"
                                    className="form-control"
                                    disabled={isDisabled}
                                    value={poloShirtXS}
                                    onChange={(e) =>
                                      setPoloShirtXS(e.target.value.replace(/[^0-9]/g, '').replace(/[^0-9]/g, ''))
                                    }
                                  />
                                </FormGroup>
                              </Col>

                              <Col lg="1">
                                <FormGroup>
                                  <label className="form-control-label text-primary pl-1" htmlFor="input-priority">
                                    S
                                  </label>

                                  <input
                                    name="input-priority"
                                    type="text"
                                    id="input-priority"
                                    placeholder="e.g"
                                    className="form-control"
                                    disabled={isDisabled}
                                    value={poloShirtS}
                                    onChange={(e) => setPoloShirtS(e.target.value.replace(/[^0-9]/g, ''))}
                                  />
                                </FormGroup>
                              </Col>

                              <Col lg="1">
                                <FormGroup>
                                  <label className="form-control-label text-primary pl-1" htmlFor="input-priority">
                                    M
                                  </label>

                                  <input
                                    name="input-priority"
                                    type="text"
                                    id="input-priority"
                                    placeholder="e.g"
                                    className="form-control"
                                    disabled={isDisabled}
                                    value={poloShirtM}
                                    onChange={(e) => setPoloShirtM(e.target.value.replace(/[^0-9]/g, ''))}
                                  />
                                </FormGroup>
                              </Col>

                              <Col lg="1">
                                <FormGroup>
                                  <label className="form-control-label text-primary pl-1" htmlFor="input-priority">
                                    L
                                  </label>

                                  <input
                                    name="input-priority"
                                    type="text"
                                    id="input-priority"
                                    placeholder="e.g"
                                    className="form-control"
                                    disabled={isDisabled}
                                    value={poloShirtL}
                                    onChange={(e) => setPoloShirtL(e.target.value.replace(/[^0-9]/g, ''))}
                                  />
                                </FormGroup>
                              </Col>

                              <Col lg="1">
                                <FormGroup>
                                  <label className="form-control-label text-primary pl-1" htmlFor="input-priority">
                                    XL
                                  </label>

                                  <input
                                    name="input-priority"
                                    type="text"
                                    id="input-priority"
                                    placeholder="e.g"
                                    className="form-control"
                                    disabled={isDisabled}
                                    value={poloShirtXL}
                                    onChange={(e) => setPoloShirtXL(e.target.value.replace(/[^0-9]/g, ''))}
                                  />
                                </FormGroup>
                              </Col>

                              <Col lg="1">
                                <FormGroup>
                                  <label className="form-control-label text-primary pl-1" htmlFor="input-priority">
                                    2XL
                                  </label>

                                  <input
                                    name="input-priority"
                                    type="text"
                                    id="input-priority"
                                    placeholder="e.g"
                                    className="form-control"
                                    disabled={isDisabled}
                                    value={poloShirt2XL}
                                    onChange={(e) => setPoloShirt2XL(e.target.value.replace(/[^0-9]/g, ''))}
                                  />
                                </FormGroup>
                              </Col>

                              <Col lg="1">
                                <FormGroup>
                                  <label className="form-control-label text-primary pl-1" htmlFor="input-priority">
                                    3XL
                                  </label>

                                  <input
                                    name="input-priority"
                                    type="text"
                                    id="input-priority"
                                    placeholder="e.g"
                                    className="form-control"
                                    disabled={isDisabled}
                                    value={poloShirt3XL}
                                    onChange={(e) => setPoloShirt3XL(e.target.value.replace(/[^0-9]/g, ''))}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          )}
                        </div>

                        <div className="mt-4 ">
                          <h2 className="text-primary">Anniversary T-shirts</h2>
                          <hr></hr>
                          {anniversarytShirt.map((shirt, index) => (
                            <AnniverssaryTShirtForm
                              key={`anniverssary-t-shirt-${index}`}
                              index={index}
                              model={shirt.model}
                              quantityXs={shirt.quantityXs}
                              quantityS={shirt.quantityS}
                              quantityM={shirt.quantityM}
                              quantityL={shirt.quantityL}
                              quantityXl={shirt.quantityXl}
                              quantity2xl={shirt.quantity2xl}
                              quantity3xl={shirt.quantity3xl}
                              setModel={setModel}
                              setQuantityXs={setQuantityXs}
                              setQuantityS={setQuantityS}
                              setQuantityM={setQuantityM}
                              setQuantityL={setQuantityL}
                              setQuantityXl={setQuantityXl}
                              setQuantity2xl={setQuantity2xl}
                              setQuantity3xl={setQuantity3xl}
                              deleteShirt={(index) => {
                                handleDeleteShirtSection(index);
                              }}
                            />
                          ))}
                          <Button color="secondary" size="sm" onClick={addMoreFields}>
                            + Add More
                          </Button>
                        </div>
                      </>
                    )}

                    <Button className="my-4" color="primary" type="submit" disabled={isDisabled}>
                      Submit
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SupportNew;
