import { Button } from 'reactstrap';
import { Card, CardBody, Container, Row, Col } from 'reactstrap';

import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import OpacityLoaderSpinner from '../../../components/Core/OpacityLoaderSpinner';
import ClassService from '../../../services/Class';
import KidsService from '../../../services/Kids';
import KidsInterface from '../../../interfaces/KidsInterface';
import ClassCheckInModal from './ClassCheckInModal';
import { toast } from 'react-toastify';

const ClassInfo = () => {
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(false);

  const [teachersCheckedIn, setTeachersCheckedIn] = useState(0);
  const [kidsCheckedIn, setKidsCheckedIn] = useState(0);
  const [classById, setClassById] = useState<any>({});
  const { token }: any = useContext(AuthContext);
  const [isClockInOpen, setIsClockInOpen] = useState(false);

  const getTeachersCheckInInfo = async () => {
    try {
      const result = await ClassService(token).listAttendances(id, 'USER');
      if (result?.data) {
        const teachersCheckedIn = result?.data.filter((t: any) => t.attendanceType === 'CHECKIN');
        const teachersCheckedOut = result?.data.filter((t: any) => t.attendanceType === 'CHECKOUT');
        setTeachersCheckedIn(teachersCheckedIn.length - teachersCheckedOut.length);
      }
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'Invalid class ID');
    }
  };

  const getKidsCheckInInfo = async () => {
    try {
      const result = await KidsService(token || '').listByClass(id);
      if (result?.data) {
        const data: KidsInterface[] = result?.data || [];
        const movedInFilter = data.filter((k) => k.isCheckedIn && k.isMoved && k.movedRoomId === id);
        const movedIn = movedInFilter.map((k) => ({
          ...k,
          fromAnotherClass: true,
          enrolledCurrentClass: k.enrolledClassId === id,
        }));
        const checkedIn = data.filter((k) => k.isCheckedIn && !k.isMoved);
        setKidsCheckedIn(Number((checkedIn.length || 0) + (movedIn.length || 0)));
      }
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'Invalid class ID');
    }
  };

  const getClassInfo = async () => {
    setLoading(true);
    try {
      const result = await ClassService(token || '').show(id);
      if (result?.data) {
        setClassById(result?.data);
      }
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'Invalid class ID');
    } finally {
      setLoading(false);
    }
  };

  const handleOpenCheckIn = () => {
    setIsClockInOpen(true);
  };

  useEffect(() => {
    getClassInfo();
    getTeachersCheckInInfo();
    getKidsCheckInInfo();
    setInterval(async () => {
      getTeachersCheckInInfo();
      getKidsCheckInInfo();
    }, 5000);
  }, []);

  return (
    <>
      {!loading && (
        <>
          <ClassCheckInModal
            classId={id}
            nameClass={classById.name}
            isOpen={isClockInOpen}
            toggleModal={() => {
              getTeachersCheckInInfo();
              setIsClockInOpen(!isClockInOpen);
            }}
          />
          <Container className="mt--7 " fluid>
            <Row className="mt-5">
              <Col style={{ height: '550px' }}>
                <Card className="card-stats mb-4 mb-xl-0 h-100">
                  <CardBody>
                    <Row>
                      <Col xs="12">
                        <h2 style={{ fontSize: '30px', textTransform: 'uppercase', float: 'left' }}>
                          {classById.name}
                        </h2>
                        <div style={{ float: 'right' }}>
                          <Button className="icon icon-shape text-white bg-info" onClick={handleOpenCheckIn}>
                            <i className="fas fa-clock" />
                          </Button>
                        </div>
                      </Col>
                      <Col xs="6" className="text-center" style={{ padding: 0, borderRight: '#CCC 1px solid' }}>
                        <h2 style={{ fontSize: '300px', fontWeight: 900, color: '#525f7f' }}>{teachersCheckedIn}</h2>
                        <p style={{ fontSize: '30px', marginTop: '-60px' }}>
                          <b>TEACHERS CHECKED IN</b>
                        </p>
                      </Col>
                      <Col xs="6" className="text-center" style={{ padding: 0 }}>
                        <h2 style={{ fontSize: '300px', fontWeight: 900, color: '#525f7f' }}>{kidsCheckedIn}</h2>
                        <p style={{ fontSize: '30px', marginTop: '-60px' }}>
                          <b>STUDENTS CHECKED IN</b>
                        </p>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      )}
      {loading && <OpacityLoaderSpinner />}
    </>
  );
};

export default ClassInfo;
