import { useContext, useEffect, useState } from 'react';
import { Button, Col, FormGroup, Input, InputGroup, InputGroupAddon, Modal, Row } from 'reactstrap';
import NumberFormat from 'react-number-format';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import TransactionService from '../../../services/Transaction';
import { validTransactionTypes } from '../../../interfaces/TransactionsInterface';
import UserCustomer from '../../../services/UserCustomer';
import { toast } from 'react-toastify';

type BillingCardPaymentModalProps = {
  parents: any[];
  kidsList: any[];
  invoicesList: any[];
  isOpen: boolean;
  toggleModal: (e?: any) => void;
  onChange: (e?: any, amount?: number) => void;
};

const BillingCardPaymentModal = ({
  parents,
  kidsList,
  invoicesList,
  isOpen,
  toggleModal,
  onChange,
}: BillingCardPaymentModalProps) => {
  const { token, currentSchool }: any = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState('');
  const [invoiceId, setInvoiceId] = useState('');
  const [description, setDescription] = useState('');
  const [amount, setAmount] = useState(0);

  const [kidsPercents, setKidsPercent] = useState([] as { id: string; name: string; percentage: number }[]);
  const [cardMethods, setCardMethods] = useState([] as any[]);
  const [paymentMethodId, setPaymentMethod] = useState('');
  const [cardDetails, setCardDetails] = useState('');

  useEffect(() => {
    const percents = [];
    for (const k of kidsList) {
      percents.push({ id: k.id, name: `${k.firstName || ''} ${k.lastName || ''}`, percentage: 0 });
    }
    setKidsPercent(percents);
  }, [isOpen]);

  useEffect(() => {
    getUserSavedCards();
  }, [userId]);

  useEffect(() => {
    const cardData = cardMethods.find((a) => a?.id === paymentMethodId);
    setCardDetails(
      cardData?.card?.brand
        ? `${String(cardData?.card?.brand).toUpperCase()} - ${cardData?.card?.exp_month}/${cardData?.card?.exp_year}`
        : '',
    );
  }, [paymentMethodId]);

  const getUserSavedCards = async () => {
    if (!userId) return;
    setLoading(true);
    const parent = parents.find((p: any) => p?.userId === userId);
    const result = await UserCustomer(token).create({
      userId: userId || '',
      schoolId: currentSchool?.value || '',
      options: { email: parent?.user?.email || '' },
    });
    const customer = result?.data || {};
    const card = customer?.options?.paymentMethods?.CREDIT_CARD || [];
    setCardMethods(card);
    setLoading(false);
  };

  const handleRequest = async (e: any) => {
    if (loading) return;
    e.preventDefault();

    const percentage = kidsPercents.reduce(
      (previousValue: number, k: any) => Number(previousValue) + Number(k?.percentage || 0),
      0,
    );
    if (percentage !== 100) {
      toast.error('The sum of the percentages must be 100!');
      setLoading(false);
      return;
    }

    setLoading(true);
    try {
      await TransactionService(token).createPayment({
        kidsPercents,
        userId: userId,
        transactionType: validTransactionTypes.CREDIT_CARD,
        amount,
        invoiceId,
        description,
        paymentMethodId,
      });
      onCardModalChange(e);
      setLoading(false);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'Request error');
      setLoading(false);
    }
  };

  const toggleCardModal = (e: any) => {
    e.preventDefault();
    toggleModal(e);
    setDescription('');
    setAmount(0);
  };

  const onCardModalChange = (e: any) => {
    onChange(e);
    e.preventDefault();
    setDescription('');
    setAmount(0);
  };

  const onKidsPercentChange = (id: string, floatValue: number) => {
    const newKidsPercent = JSON.parse(JSON.stringify(kidsPercents));
    for (const k of newKidsPercent) {
      if (k.id === id) {
        k.percentage = floatValue;
      }
    }
    setKidsPercent(newKidsPercent);
  };

  return (
    <>
      <Modal className="modal-dialog-centered" size="xl" isOpen={isOpen} toggle={(e: any) => toggleCardModal(e)}>
        <div className="modal-header pb-1">
          <h5 className="modal-title">Credit Card Charge</h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={(e) => toggleCardModal(e)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body pt-0">
          <Row className="mt-2">
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label">Parent</label>
                <Input type="select" onChange={(e) => setUserId(e.target.value)} required>
                  <option value="">Please select</option>
                  {parents.map((p: any, index: number) => {
                    return (
                      <option value={p.userId} key={`parent-${index}`}>
                        {`${p?.user?.firstName || ''} ${p?.user?.lastName || ''}`}
                      </option>
                    );
                  })}
                </Input>
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label">Invoice</label>
                <Input type="select" onChange={(e) => setInvoiceId(e.target.value)}>
                  <option value="">Extra Charge (don't link with an invoice)</option>
                  {invoicesList.map((a: any, index: number) => {
                    return (
                      <option value={a.value} key={`invoice-${index}`}>
                        {a.label}
                      </option>
                    );
                  })}
                </Input>
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label">Description</label>
                <Input
                  className="form-control-alternative"
                  placeholder="Please, insert a description."
                  type="text"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label">Amount</label>
                <Input
                  type="text"
                  thousandsGroupStyle="thousand"
                  prefix="$"
                  decimalSeparator="."
                  displayType="input"
                  thousandSeparator={true}
                  allowNegative={false}
                  decimalScale={2}
                  tag={NumberFormat}
                  value={amount}
                  onValueChange={(e: any) => setAmount(e.floatValue)}
                />
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label">Card Number</label>
                <Input type="select" onChange={(e) => setPaymentMethod(e.target.value)}>
                  {loading && <option value="">Loading...</option>}
                  {!loading && (
                    <>
                      <option value="">Please select</option>
                      {cardMethods.map((a: any, index: number) => {
                        return (
                          <option value={a.id} key={`account-${index}`}>
                            {a?.card?.last4 ? `********${a?.card?.last4}` : '-'}
                          </option>
                        );
                      })}
                    </>
                  )}
                </Input>
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label">Card Details</label>
                <Input
                  className="form-control-alternative"
                  placeholder="Please, select a card number"
                  type="text"
                  value={cardDetails}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col lg="12">
              <h5>Kids/Percentage</h5>
            </Col>
            {kidsPercents.map((k: any, index: number) => (
              <Col lg="3" key={`child-${index}`}>
                <FormGroup>
                  <label className="form-control-label">{`${k?.name} `}</label>

                  <InputGroup>
                    <Input
                      type="text"
                      thousandsGroupStyle="thousand"
                      suffix="%"
                      decimalSeparator="."
                      displayType="input"
                      thousandSeparator={true}
                      allowNegative={false}
                      decimalScale={2}
                      tag={NumberFormat}
                      value={k?.percentage}
                      onValueChange={(e: any) => onKidsPercentChange(k.id, e.floatValue)}
                    />

                    <InputGroupAddon addonType="append">
                      <Button color="primary" disabled={true}>
                        ${!amount || !k?.percentage ? '0.00' : Number((k?.percentage / 100) * amount).toFixed(2)}
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Col>
            ))}
          </Row>
          <hr />
          <Row>
            <Col md="6 text-center">
              <Button color="secondary" size="md" style={{ minWidth: '100px' }} onClick={(e) => toggleCardModal(e)}>
                Cancel
              </Button>
            </Col>
            <Col md="6 text-center">
              <Button color="success" size="md" style={{ minWidth: '100px' }} onClick={(e) => handleRequest(e)}>
                Save
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default BillingCardPaymentModal;
