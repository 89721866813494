/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import {
  FormGroup,
  Input,
  Row,
  Col,
  CardBody,
  Card,
  Button,
  Badge,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import DataTable from '../../../components/Core/DataTable';
import { confirmAlert } from 'react-confirm-alert';
import CardInfo from '../../../components/Cards/CardInfo';
import ModalContent from '../../../components/Modals/Modal';
import ClassService from '../../../services/Class';
import KidsActivity from '../../../services/KidsActivity';
import ClassInterface from '../../../interfaces/ClassInterface';
import { toast } from 'react-toastify';
import ClassActivityConfirm from '../Class/ClassActivityConfirm';

export const KidsCheckModal: React.FC<{
  currentSchoolId: string;
  token: string;
  count: number;
  kids: Array<any>;
  refreshData?: () => void;
}> = ({ currentSchoolId, count, kids, token, refreshData }) => {
  const [loading, setLoading] = useState(false);
  const [toggleModal, setToggleModal] = useState(false);
  const [classes, setClasses] = useState<ClassInterface[]>([]);
  const [filterClass, setFilterClass] = useState('');
  const [kidsSelected, setKidsSelected] = useState([] as any[]);
  const [activeTab, setActiveTab] = useState('1');
  const [allCheckedIn, setAllCheckedIn] = useState(false);
  const [allCheckedOut, setAllCheckedOut] = useState(false);
  const kidsCheckedIn = kids.filter((k: any) => k.isCheckedIn);
  const [currentKidsCheckedIn, setCurrentKidsCheckedIn] = useState(kidsCheckedIn as any[]);
  const kidsCheckedOut = kids.filter((k: any) => !k.isCheckedIn);
  const [currentKidsCheckedOut, setCurrentKidsCheckedOut] = useState(kidsCheckedOut as any[]);
  const customStyles = {
    navLink: {
      minHeight: '42px',
      paddingTop: '8px',
      cursor: 'pointer',
    },
  };
  const columnsOut = [
    {
      name: (
        <Input
          type="checkbox"
          className="mt-n1 ml-2"
          onChange={(_) => selectKidsByType('CHECKOUT')}
          checked={allCheckedOut}
        />
      ),
      selector: (row: any) => (
        <Input
          type="checkbox"
          className="mt-n1 ml-2"
          checked={!!kidsSelected.find((k) => k.id === row.id)}
          onChange={(_) => selectKidsById(row.id)}
        />
      ),
      grow: 1,
    },
    {
      name: 'Child Name',
      route: '/kids',
      label: (row: any) => `${row.firstName || '-'} ${row.lastName || '-'}`,
      grow: 2,
    },
    {
      name: 'Class',
      selector: (row: any) => `${row?.enrollment?.class?.name || '-'}`,
      grow: 2,
    },
    {
      name: 'Status',
      selector: (row: any) => (
        <Badge className="badge-dot badge-lg p-1 ml-1">
          <i className={row.isCheckedIn ? 'bg-success' : 'bg-danger'} />
        </Badge>
      ),
      grow: 2,
    },
  ];
  const columnsIn = [
    {
      name: (
        <Input
          type="checkbox"
          className="mt-n1 ml-2"
          onChange={(_) => selectKidsByType('CHECKIN')}
          checked={allCheckedIn}
        />
      ),
      selector: (row: any) => (
        <Input
          type="checkbox"
          className="mt-n1 ml-2"
          checked={kidsSelected.find((k) => k.id === row.id)}
          onChange={(_) => selectKidsById(row.id)}
        />
      ),
      grow: 1,
    },
    {
      name: 'Child Name',
      route: '/kids',
      label: (row: any) => `${row.firstName || '-'} ${row.lastName || '-'}`,
      grow: 2,
    },
    {
      name: 'Class',
      selector: (row: any) => `${row?.enrollment?.class?.name || '-'}`,
      grow: 2,
    },
    {
      name: 'Status',
      selector: (row: any) => (
        <Badge className="badge-dot badge-lg p-1 ml-1">
          <i className={row.isCheckedIn ? 'bg-success' : 'bg-danger'} />
        </Badge>
      ),
      grow: 2,
    },
  ];
  const handleToggleModal = () => {
    setToggleModal(!toggleModal);
  };
  const selectKidsByType = (type: string) => {
    if (type === 'CHECKIN') {
      const kidsSelect = kids.filter((k) => k.isCheckedIn);
      if (kidsSelect.length === kidsSelected.length) {
        setAllCheckedIn(false);
        return setKidsSelected([]);
      }
      setAllCheckedIn(true);
      return setKidsSelected(kidsSelect);
    }
    if (type === 'CHECKOUT') {
      const kidsSelect = kids.filter((k) => !k.isCheckedIn);
      if (kidsSelect.length === kidsSelected.length) {
        setAllCheckedOut(false);
        return setKidsSelected([]);
      }
      setAllCheckedOut(true);
      return setKidsSelected(kidsSelect);
    }
  };
  const selectKidsById = (kidsId: string) => {
    const newKidsSelected = JSON.parse(JSON.stringify(kidsSelected));
    if (newKidsSelected.find((k: any) => k?.id === kidsId)) {
      return setKidsSelected(newKidsSelected.filter((k: any) => k.id !== kidsId));
    }
    const child = kids.find((k) => k.id === kidsId);
    if (child) {
      return setKidsSelected([...newKidsSelected, child]);
    }
  };
  const listClasses = async () => {
    if (!currentSchoolId) return;
    try {
      setLoading(true);
      const classes = await ClassService(token).listBySchool(currentSchoolId);

      if (classes?.data) {
        setClasses(classes.data);
      }
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (currentSchoolId) listClasses();
  }, [currentSchoolId]);
  useEffect(() => {
    let kidsCheckedIn = kids.filter((k: any) => k.isCheckedIn);
    let kidsCheckedOut = kids.filter((k: any) => !k.isCheckedIn);
    if (filterClass) {
      kidsCheckedIn = kidsCheckedIn.filter((row: any) => row?.enrollment?.class?.id === filterClass);
      kidsCheckedOut = kidsCheckedOut.filter((row: any) => row?.enrollment?.class?.id === filterClass);
    }
    setCurrentKidsCheckedIn(kidsCheckedIn);
    setCurrentKidsCheckedOut(kidsCheckedOut);
  }, [kids]);
  useEffect(() => {
    if (kidsSelected?.length !== kidsCheckedIn?.length) {
      setAllCheckedIn(false);
    }
    if (kidsSelected?.length !== kidsCheckedOut?.length) {
      setAllCheckedOut(false);
    }
  }, [kidsSelected]);
  useEffect(() => {
    if (!filterClass) {
      setCurrentKidsCheckedIn(kidsCheckedIn);
      setCurrentKidsCheckedOut(kidsCheckedOut);
      return;
    }
    const newKidsCheckedIn = kidsCheckedIn.filter((row: any) => row?.enrollment?.class?.id === filterClass);
    const newKidsCheckedOut = kidsCheckedOut.filter((row: any) => row?.enrollment?.class?.id === filterClass);
    setCurrentKidsCheckedIn(newKidsCheckedIn);
    setCurrentKidsCheckedOut(newKidsCheckedOut);
  }, [filterClass]);
  const confirmActivity = (e: any, type: string) => {
    e.preventDefault();

    if (!kidsSelected.length) {
      return toast.warn('Please select at least one student');
    }

    confirmAlert({
      customUI: ({ onClose }) => {
        return <ClassActivityConfirm type={type} sendActivity={sendActivity} onClose={onClose} />;
      },
    });
  };
  const sendActivity = async (type: string) => {
    try {
      for (const k of kidsSelected) {
        if (type === 'CHECKIN') await KidsActivity(token).checkin(k.id);
        if (type === 'CHECKOUT') await KidsActivity(token).checkout(k.id);
      }
      setKidsSelected([]);
      toast.success('Saved successfully!');
      if (refreshData) refreshData();
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'Error');
    }
  };
  return (
    <>
      <CardInfo
        count={count}
        class="bg-success card-info-mg-button"
        icon="fa-user-check"
        title="Children Checked IN"
        toggle={handleToggleModal}
      />
      <ModalContent title="Children Checked In" modal={toggleModal} toggle={handleToggleModal} size="xl">
        <Row>
          <Col xs="12" className="mb-2">
            <Card>
              <CardBody className="pt-1 pb-1">
                <Row>
                  <Col xs="6">
                    <FormGroup>
                      <label className="form-control-label">Class</label>
                      <Input type="select" onChange={(e) => setFilterClass(e.target.value)} value={filterClass}>
                        <option value="">All Classes</option>
                        {classes?.map((c: any, index: number) => {
                          return (
                            <option value={c.id} key={`class-button-${index}`}>
                              {c.name}
                            </option>
                          );
                        })}
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col xs="12">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={`${activeTab === '1' ? 'active' : ''}`}
                  onClick={() => {
                    setActiveTab('1');
                    setKidsSelected([]);
                    setAllCheckedIn(false);
                  }}
                  style={customStyles.navLink}
                >
                  Checked Out
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={`${activeTab === '2' ? 'active' : ''}`}
                  onClick={() => {
                    setActiveTab('2');
                    setKidsSelected([]);
                    setAllCheckedOut(false);
                  }}
                  style={customStyles.navLink}
                >
                  Checked In
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
          <Col xs="12">
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <DataTable
                  header={false}
                  title=""
                  columns={columnsOut}
                  data={currentKidsCheckedOut}
                  totalRows={currentKidsCheckedOut.length}
                  loading={loading}
                  button={false}
                  buttonUrl=""
                  onChangeRowsPerPage={(() => {}) as any}
                  onChangePage={(() => {}) as any}
                />
                <Button size="sm" className="mt-3" color="success" onClick={(e) => confirmActivity(e, 'CHECKIN')}>
                  Check In
                </Button>
              </TabPane>
              <TabPane tabId="2">
                <DataTable
                  header={false}
                  title=""
                  columns={columnsIn}
                  data={currentKidsCheckedIn}
                  totalRows={currentKidsCheckedIn.length}
                  loading={loading}
                  button={false}
                  buttonUrl=""
                  onChangeRowsPerPage={(() => {}) as any}
                  onChangePage={(() => {}) as any}
                />
                <Button size="sm" className="mt-3" color="danger" onClick={(e) => confirmActivity(e, 'CHECKOUT')}>
                  Check Out
                </Button>
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </ModalContent>
    </>
  );
};
