import { Button, Col, Form, Modal, Row } from 'reactstrap';

interface RehireFormProps {
  isOpen: boolean;
  toogle: () => void;
  user: any;
  handleSubmit: (e: any) => void;
}

const RehireForm = ({ isOpen, toogle, user, handleSubmit }: RehireFormProps) => {
  return (
    <Modal className="modal-dialog-centered" isOpen={isOpen} toggle={toogle} size={'lg'}>
      <div className="modal-header">
        <h4 className="modal-title">Rehire</h4>
        <div onClick={toogle} className="d-flex p-1 cursor-pointer">
          <i className="fas fa-times" />
        </div>
      </div>
      <div className="modal-body" style={{ borderTop: '1px solid #d9d9d9' }}>
        <div className="px-2">
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col lg="12">
                <span>
                  You're about to rehire {user?.user?.firstName}, adding a new start date to their profile. If this
                  person was accidentally terminated, you can revoke their termination from their Profile page.
                </span>
              </Col>
            </Row>

            <div className="d-flex justify-content-end mt-4">
              <div>
                <Button className="btn-secondary" onClick={toogle}>
                  Cancel
                </Button>
                <Button color="warning" type="submit">
                  Rehire
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default RehireForm;
