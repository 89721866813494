/* eslint-disable max-lines */
import { Button, Card, CardHeader, CardBody, FormGroup, Form, Input, Container, Row, Col, Label } from 'reactstrap';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import CommonHeader from '../../../components/Headers/CommonHeader';
import DatePicker from 'react-datepicker';
import TimePicker from '../../../components/Core/TimePicker';
import { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import IncidentReports from '../../../services/IncidentReports';
import { toast } from 'react-toastify';

const AdminSignature = () => {
  const { id } = useParams<{ id: string }>();
  const { token }: any = useContext(AuthContext);
  const history = useHistory();

  const [isSign, setIsSign] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [kidsFirstName, setKidsFirstName] = useState('');
  const [kidsLastName, setKidsLastName] = useState('');
  const [kidsBirthday, setKidsBirthday] = useState('');
  const [kidsGender, setKidsGender] = useState('');
  const [afectedOrInjured, setAfectedOrInjured] = useState('');
  const [type, setType] = useState('');
  const [acidentDate, setAcidentDate] = useState<any>();
  const [acidentTime, setAcidentTime] = useState(moment().format());
  const [location, setLocation] = useState('');
  const [incidentDescription, setIncidentDescription] = useState('');
  const [injuryNatureDescription, setInjuryNatureDescription] = useState('');
  const [medicalTreatmentDescription, setMedicalTreatmentDescription] = useState('');
  const [ambulanceCalled, setAmbulanceCalled] = useState(false);
  const [witness, setWitness] = useState('');
  const [action, setAction] = useState('');
  const [teacherSignature, setTeacherSignature] = useState('');
  const [parentContacted, setParentContacted] = useState<boolean | null>(false);
  const [parentContactedTime, setParentContactedTime] = useState(moment().format());
  const [contactedVia, setContactedVia] = useState('');
  const [otherPersonsContacted, setOtherPersonsContacted] = useState('');
  const [directorSignature, setDirectorSignature] = useState('');
  const [directorSignatureDate, setDirectorSignatureDate] = useState<Date | null>();

  useEffect(() => {
    getById();
  }, []);

  const getById = async () => {
    try {
      const response = await IncidentReports(token).show(id);
      const result = response.data;

      setKidsFirstName(result.kids?.firstName || '');
      setKidsLastName(result.kids?.lastName || '');
      setKidsBirthday(result.kids?.birthday || '');
      setKidsGender(result.kids?.gender || '');
      setAfectedOrInjured(result.afectedOrInjured || '');
      setType(result.type || '');
      setAcidentDate(result.acidentDate ? moment(result.acidentDate).toDate() : null);
      setAcidentTime(result.acidentTime || '');
      setLocation(result.location || '');
      setIncidentDescription(result.incidentDescription || '');
      setInjuryNatureDescription(result.injuryNatureDescription || '');
      setMedicalTreatmentDescription(result.medicalTreatmentDescription || '');
      setAmbulanceCalled(result.ambulanceCalled || false);
      setWitness(result.witness || '');
      setAction(result.action || '');
      setTeacherSignature(result.teacherSignature || '');
      setParentContacted(result.parentContacted || '');
      setParentContactedTime(result.parentContactedTime || moment().format());
      setContactedVia(result.contactedVia || '');
      setOtherPersonsContacted(result.otherPersonsContacted || '');
      setDirectorSignature(result.directorSignature || '');
      setDirectorSignatureDate(result.setDirectorSignatureDate || '');

      if (directorSignature) {
        setIsSign(true);
      }
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'Request error');
    }
  };

  const handleSubimitIncidentReports = async (event: any) => {
    event.preventDefault();
    setIsDisabled(true);

    const body = {
      afectedOrInjured,
      type,
      acidentDate,
      acidentTime,
      location,
      incidentDescription,
      injuryNatureDescription,
      medicalTreatmentDescription,
      ambulanceCalled,
      witness,
      action,
      parentContacted,
      parentContactedTime,
      contactedVia,
      otherPersonsContacted,
      directorSignature,
      directorSignatureDate,
    };

    try {
      await IncidentReports(token).update(id, body);
      toast.success('Incident report successfully signed');
      setIsDisabled(false);
      history.push('/home');
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'Request error');
      setIsDisabled(false);
    }
  };

  return (
    <>
      <CommonHeader />
      <Container className="mt--8" fluid>
        <Row>
          <Col className="order-xl-2" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Update Incident Report</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Link to="/home">
                      <Button color="primary" className="float-right">
                        Back
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubimitIncidentReports}>
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="input-firstName">
                          First Name
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-firstName"
                          placeholder="First Name"
                          type="text"
                          disabled
                          value={kidsFirstName}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="input-lastName">
                          Last Name
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-lastName"
                          placeholder="Last Name"
                          type="text"
                          disabled
                          value={kidsLastName}
                        />
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="input-lastName">
                          Birthday
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-lastName"
                          placeholder="Birthday"
                          type="text"
                          disabled
                          value={kidsBirthday}
                        />
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="input-lastName">
                          Gender
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-lastName"
                          placeholder="Gender"
                          type="text"
                          disabled
                          value={kidsGender}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="12">
                      <Label>
                        <strong>WHO WAS AFFECTED OR INJURED</strong>
                      </Label>
                      <div className="d-flex align-items-center">
                        <div className="form-check form-check-inline mr-3">
                          <Label className="form-check-label mr-2" htmlFor="child">
                            Child
                          </Label>
                          <Input
                            className="form-check-input"
                            type="radio"
                            name="afected"
                            id="child"
                            value={'Child'}
                            checked={afectedOrInjured === 'Child'}
                            onChange={(e) => setAfectedOrInjured(e.target.value)}
                            required
                          />
                        </div>
                        <div className="form-check form-check-inline mr-3">
                          <Label className="form-check-label mr-2" htmlFor="parent">
                            Parent
                          </Label>
                          <Input
                            className="form-check-input"
                            type="radio"
                            name="afected"
                            id="parent"
                            value={'Parent'}
                            checked={afectedOrInjured === 'Parent'}
                            onChange={(e) => setAfectedOrInjured(e.target.value)}
                            required
                          />
                        </div>
                        <div className="form-check form-check-inline mr-3">
                          <Label className="form-check-label mr-2" htmlFor="staff">
                            Staff
                          </Label>
                          <Input
                            className="form-check-input"
                            type="radio"
                            name="afected"
                            id="staff"
                            value={'Staff'}
                            checked={afectedOrInjured === 'Staff'}
                            onChange={(e) => setAfectedOrInjured(e.target.value)}
                            required
                          />
                        </div>
                        <div className="form-check form-check-inline mr-3">
                          <Label className="form-check-label mr-2" htmlFor="visitor">
                            Visitor
                          </Label>
                          <Input
                            className="form-check-input"
                            type="radio"
                            name="afected"
                            id="visitor"
                            value={'Visitor'}
                            checked={afectedOrInjured === 'Visitor'}
                            onChange={(e) => setAfectedOrInjured(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                    </Col>

                    <Col lg="12">
                      <h3 className="mt-4">
                        <strong>INCIDENT DESCRIPTION</strong>
                      </h3>
                    </Col>

                    <Col lg="12" className="mt-2">
                      <Label>
                        <strong>Type of Incident</strong>
                      </Label>
                      <div className="d-flex flex-wrap align-items-center">
                        <div className="form-check form-check-inline mr-3">
                          <Label className="form-check-label mr-2" htmlFor="accident-injury">
                            Accident/Injury
                          </Label>
                          <Input
                            className="form-check-input"
                            type="radio"
                            name="type"
                            id="accident-injury"
                            value={'Accident/Injury'}
                            checked={type === 'Accident/Injury'}
                            onChange={(e) => setType(e.target.value)}
                            required
                          />
                        </div>
                        <div className="form-check form-check-inline mr-3">
                          <Label className="form-check-label mr-2" htmlFor="illness">
                            Illness
                          </Label>
                          <Input
                            className="form-check-input"
                            type="radio"
                            name="type"
                            id="illness"
                            value={'Illness'}
                            checked={type === 'Illness'}
                            onChange={(e) => setType(e.target.value)}
                            required
                          />
                        </div>
                        <div className="form-check form-check-inline mr-3">
                          <Label className="form-check-label mr-2" htmlFor="observation-incident">
                            Observation/Incident
                          </Label>
                          <Input
                            className="form-check-input"
                            type="radio"
                            name="type"
                            id="observation-incident"
                            value={'Observation/Incident'}
                            checked={type === 'Observation/Incident'}
                            onChange={(e) => setType(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                    </Col>

                    <Col lg="6">
                      <div className="d-flex align-items-center mt-4">
                        <Label className="form-control-label text-left mr-4" htmlFor="incident-date">
                          <strong>Date of Accident/Incident:</strong>
                        </Label>
                        <Input
                          tag={DatePicker}
                          dateFormat="MM/dd/yyyy"
                          onChange={(d: any) => {
                            setAcidentDate(d);
                          }}
                          selected={acidentDate}
                          id="incident-date"
                          required
                        />
                      </div>
                    </Col>
                    <Col lg="4">
                      <div className="d-flex align-items-center mt-4">
                        <Label className="form-control-label text-left mr-4">Time</Label>
                        <TimePicker
                          value={acidentTime}
                          onChangeTime={(time: string) => {
                            setAcidentTime(time);
                          }}
                        />
                      </div>
                    </Col>

                    <Col lg="12" className="mt-4">
                      <Label>
                        <strong>Location of Accident/Incident:</strong>
                      </Label>
                      <div className="d-flex flex-wrap align-items-center">
                        <div className="form-check form-check-inline mr-3">
                          <Label className="form-check-label mr-2" htmlFor="classroom">
                            Classroom
                          </Label>
                          <Input
                            className="form-check-input"
                            type="radio"
                            name="location"
                            id="classroom"
                            value={'Classroom'}
                            checked={location === 'Classroom'}
                            onChange={(e) => setLocation(e.target.value)}
                            required
                          />
                        </div>
                        <div className="form-check form-check-inline mr-3">
                          <Label className="form-check-label mr-2" htmlFor="playground">
                            Playground
                          </Label>
                          <Input
                            className="form-check-input"
                            type="radio"
                            name="location"
                            id="playground"
                            value={'Playground'}
                            checked={location === 'Playground'}
                            onChange={(e) => setLocation(e.target.value)}
                            required
                          />
                        </div>
                        <div className="form-check form-check-inline mr-3">
                          <Label className="form-check-label mr-2" htmlFor="bathroom">
                            Bathroom
                          </Label>
                          <Input
                            className="form-check-input"
                            type="radio"
                            name="location"
                            id="bathroom"
                            value={'Bathroom'}
                            checked={location === 'Bathroom'}
                            onChange={(e) => setLocation(e.target.value)}
                            required
                          />
                        </div>
                        <div className="form-check form-check-inline mr-3">
                          <Label className="form-check-label mr-2" htmlFor="other">
                            Other
                          </Label>
                          <Input
                            className="form-check-input"
                            type="radio"
                            name="location"
                            id="other"
                            value={'Other'}
                            checked={location === 'Other'}
                            onChange={(e) => setLocation(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                    </Col>

                    <Col lg="12" className="mt-4">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="input-description-1">
                          <strong>Describe Accident/Incident:</strong>
                        </label>
                        <textarea
                          className="form-control"
                          id="input-description-1"
                          placeholder="Description"
                          value={incidentDescription}
                          onChange={(e) => {
                            setIncidentDescription(e.target.value);
                          }}
                          required
                        />
                      </FormGroup>
                    </Col>

                    <Col lg="12">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="input-description-2">
                          <strong>Describe Nature of Injury/Illness:</strong>
                        </label>
                        <textarea
                          className="form-control"
                          id="input-description-2"
                          placeholder="Description"
                          value={injuryNatureDescription}
                          onChange={(e) => {
                            setInjuryNatureDescription(e.target.value);
                          }}
                          required
                        />
                      </FormGroup>
                    </Col>

                    <Col lg="12" className="mb-0">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="input-description-3">
                          <strong>Describe Medical Treatment/First Aid:</strong>
                        </label>
                        <textarea
                          className="form-control"
                          id="input-description-3"
                          placeholder="Description"
                          value={medicalTreatmentDescription}
                          onChange={(e) => {
                            setMedicalTreatmentDescription(e.target.value);
                          }}
                          required
                        />
                      </FormGroup>
                    </Col>

                    <Col lg="12" className="mt-4">
                      <Label>
                        <strong>Ambulance Called:</strong>
                      </Label>
                      <div className="d-flex flex-wrap align-items-center">
                        <div className="form-check form-check-inline mr-3">
                          <Label className="form-check-label mr-2" htmlFor="ambulance-yes">
                            Yes
                          </Label>
                          <Input
                            className="form-check-input"
                            type="radio"
                            name="radio1"
                            id="ambulance-yes"
                            checked={ambulanceCalled === true}
                            onChange={() => setAmbulanceCalled(true)}
                          />
                        </div>
                        <div className="form-check form-check-inline mr-3">
                          <Label className="form-check-label mr-2" htmlFor="ambulance-no">
                            No
                          </Label>
                          <Input
                            className="form-check-input"
                            type="radio"
                            name="radio1"
                            id="ambulance-no"
                            checked={ambulanceCalled === false}
                            onChange={() => setAmbulanceCalled(false)}
                            required
                          />
                        </div>
                      </div>
                    </Col>

                    <Col lg="12" className="mt-4">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="input-description-4">
                          <strong>Witness(es) to Accident/Incident</strong>
                        </label>
                        <textarea
                          className="form-control"
                          id="input-description-4"
                          placeholder="Answer"
                          value={witness}
                          onChange={(e) => {
                            setWitness(e.target.value);
                          }}
                          required
                        />
                      </FormGroup>
                    </Col>

                    <Col lg="12" className="mt-4">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="input-description-5">
                          <strong>What Action Was Taken & by Whom?</strong>
                        </label>
                        <textarea
                          className="form-control"
                          id="input-description-5"
                          placeholder="Answer"
                          value={action}
                          onChange={(e) => {
                            setAction(e.target.value);
                          }}
                          required
                        />
                      </FormGroup>
                    </Col>

                    <Col lg="12" className="mt-4">
                      <FormGroup>
                        <Label htmlFor="name-for-signature">
                          <strong>Teacher's signature:</strong>
                        </Label>
                        <Input
                          className="form-control"
                          type="text"
                          id="name-for-signature"
                          disabled
                          value={teacherSignature}
                          style={{ fontFamily: 'Dancing Script' }}
                        />
                      </FormGroup>
                    </Col>

                    <Col lg="12">
                      <h3 className="mt-4">
                        <strong>NOTIFICATIONS & SIGNATURES</strong>
                      </h3>
                    </Col>

                    <Row className="ml-1">
                      <Col lg="8" className="mt-2">
                        <Label>
                          <strong>Was Parent/Guardian Contacted?</strong>
                        </Label>
                        <div className="d-flex flex-wrap align-items-center">
                          <div className="form-check form-check-inline mr-3">
                            <Label className="form-check-label mr-2" htmlFor="contacted-yes">
                              Yes
                            </Label>
                            <Input
                              className="form-check-input"
                              type="radio"
                              name="contacted-parent"
                              id="contacted-yes"
                              checked={parentContacted === true}
                              onChange={() => setParentContacted(true)}
                              required
                            />
                          </div>
                          <div className="form-check form-check-inline mr-3">
                            <Label className="form-check-label mr-2" htmlFor="contacted-no">
                              No
                            </Label>
                            <Input
                              className="form-check-input"
                              type="radio"
                              name="contacted-parent"
                              id="contacted-no"
                              checked={parentContacted === false}
                              onChange={() => setParentContacted(false)}
                              required
                            />
                          </div>
                          <div className="form-check form-check-inline mr-3">
                            <Label className="form-check-label mr-2" htmlFor="contacted-na">
                              N/A
                            </Label>
                            <Input
                              className="form-check-input"
                              type="radio"
                              name="contacted-parent"
                              id="contacted-na"
                              checked={parentContacted === null}
                              onChange={() => setParentContacted(null)}
                              required
                            />
                          </div>
                        </div>
                      </Col>

                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label">Time</label>
                          <TimePicker
                            value={parentContactedTime}
                            onChangeTime={(time: string) => {
                              setParentContactedTime(time);
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Col lg="12" className="mt-2">
                      <Label>
                        <strong>How?</strong> Via
                      </Label>
                      <div className="d-flex flex-wrap align-items-center">
                        <div className="form-check form-check-inline mr-3">
                          <Label className="form-check-label mr-2" htmlFor="via-phone">
                            Phone
                          </Label>
                          <Input
                            className="form-check-input"
                            type="radio"
                            name="via"
                            id="via-phone"
                            value={'Phone'}
                            checked={contactedVia === 'Phone'}
                            onChange={(e) => setContactedVia(e.target.value)}
                            required
                          />
                        </div>
                        <div className="form-check form-check-inline mr-3">
                          <Label className="form-check-label mr-2" htmlFor="via-email">
                            Email
                          </Label>
                          <Input
                            className="form-check-input"
                            type="radio"
                            name="via"
                            id="via-email"
                            value={'Email'}
                            checked={contactedVia === 'Email'}
                            onChange={(e) => setContactedVia(e.target.value)}
                            required
                          />
                        </div>
                        <div className="form-check form-check-inline mr-3">
                          <Label className="form-check-label mr-2" htmlFor="via-in-person">
                            In person
                          </Label>
                          <Input
                            className="form-check-input"
                            type="radio"
                            name="via"
                            id="via-in-person"
                            value={'In person'}
                            checked={contactedVia === 'In person'}
                            onChange={(e) => setContactedVia(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                    </Col>

                    <Col lg="12" className="mt-4">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="input-description-4">
                          <strong>Other Persons Contacted:</strong>
                        </label>
                        <textarea
                          className="form-control"
                          id="input-description-4"
                          placeholder="Answer"
                          value={otherPersonsContacted}
                          onChange={(e) => setOtherPersonsContacted(e.target.value)}
                          required
                        />
                      </FormGroup>
                    </Col>

                    <Col lg="12" className="mt-4">
                      <Label>
                        <strong>Signature of Director/Person in Charge</strong>
                      </Label>
                      <div className="d-flex flex-wrap align-items-center">
                        <div className="form-check form-check-inline mr-3">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            id="to-sign"
                            checked={isSign}
                            onChange={() => {
                              setIsSign(!isSign);
                              if (isSign) setDirectorSignatureDate(new Date());
                            }}
                          />
                          <Label className="form-check-label mr-2" htmlFor="to-sign">
                            I hereby acknowledge and agree that my electronic signature on this document is the legal
                            equivalent of my handwritten signature.
                          </Label>
                        </div>
                      </div>
                    </Col>

                    {isSign && (
                      <Col lg="12" className="mt-4">
                        <FormGroup>
                          <Label htmlFor="name-for-signature">
                            <strong>Enter your name for signature:</strong>
                          </Label>
                          <Input
                            className="form-control"
                            type="text"
                            id="name-for-signature"
                            style={{ fontFamily: 'Dancing Script' }}
                            value={directorSignature}
                            onChange={(e) => setDirectorSignature(e.target.value)}
                            required
                          />
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <div className="d-flex justify-content-end">
                    <Button className="my-4" color="primary" type="submit" disabled={isDisabled}>
                      Save
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AdminSignature;
