import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Modal,
  Row,
} from 'reactstrap';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import ReactDatetime from 'react-datetime';
import { useContext, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import BlackoutDate from '../../../services/BlackoutDate';
import moment from 'moment';
import confirmAlert from '../../../helpers/confirmAlert';

interface BlackoutDatesFormProps {
  isOpen: boolean;
  toogle: () => void;
  change: () => void;
  data?: any;
  viewMode?: boolean;
}

const BlackoutDatesForm = ({ isOpen, toogle, change, data, viewMode = false }: BlackoutDatesFormProps) => {
  const { token, currentSchool }: any = useContext(AuthContext);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [reason, setReason] = useState('');
  const handleToggle = () => {
    setStartDate('');
    setEndDate('');
    setReason('');
    toogle();
  };
  const [baseViewMode, setBaseViewMode] = useState(false);
  const handleSubmit = async (e: any) => {
    e?.preventDefault();
    try {
      const body = {
        schoolId: currentSchool?.value || '',
        reason,
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
      };
      if (data?.id) {
        await BlackoutDate(token).update(data.id, body);
      } else {
        await BlackoutDate(token).create(body);
      }
      toast.success('Saved successfully!');
      change();
      handleToggle();
    } catch (e) {
      console.log(e);
      toast.error('Error on save.');
    }
  };

  const deleteBlackoutDate = async (id: string) => {
    try {
      await BlackoutDate(token).delete(id);
      toast.success('Deleted successfully!');
      change();
      handleToggle();
    } catch (e) {
      toast.error('Error on delete.');
    }
  };

  const handleDelete = async (e: React.FormEvent, blackoutDate: any) => {
    return confirmAlert({
      title: 'Confirm',
      messageType: 'DELETE',
      message: 'Are you sure you want to delete the Blackout date?',
      id: blackoutDate.id,
      onClickYes: async () => await deleteBlackoutDate(blackoutDate.id),
    });
  };

  useEffect(() => {
    if (!data) {
      return;
    }
    setStartDate(moment(data.startDate).format('MM/DD/yyyy'));
    setEndDate(moment(data.endDate).format('MM/DD/yyyy'));
    setReason(data.reason);
  }, [data]);

  useEffect(() => {
    setBaseViewMode(viewMode);
  }, [viewMode]);

  return (
    <Modal className="modal-dialog-centered" isOpen={isOpen} toggle={handleToggle} size={'lg'}>
      <div className="modal-header">
        <h4 className="modal-title">Blackout Dates</h4>
        <div onClick={handleToggle} className="d-flex p-1 cursor-pointer">
          <i className="fas fa-times" />
        </div>
      </div>
      <div className="modal-body" style={{ borderTop: '1px solid #d9d9d9' }}>
        <div className="px-2">
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col lg="6" md="6" sm="12">
                <FormGroup>
                  <Label className="form-control-label">Start Date</Label>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend" className={baseViewMode ? 'disabled' : ''}>
                      <InputGroupText>
                        <i className="ni ni-calendar-grid-58" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <ReactDatetime
                      inputProps={{
                        placeholder: 'Start Date',
                        disabled: baseViewMode,
                      }}
                      onChange={(newValue) => {
                        setStartDate(`${newValue}`);
                      }}
                      timeFormat={false}
                      value={`${startDate}`}
                      dateFormat={'MM/DD/yyyy'}
                      closeOnSelect={true}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col className="mb-2" lg="6" md="6" sm="12">
                <FormGroup>
                  <Label className="form-control-label">End Date</Label>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend" className={baseViewMode ? 'disabled' : ''}>
                      <InputGroupText>
                        <i className="ni ni-calendar-grid-58" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <ReactDatetime
                      inputProps={{
                        placeholder: 'End Date',
                        disabled: baseViewMode,
                      }}
                      onChange={(newValue) => {
                        setEndDate(`${newValue}`);
                      }}
                      timeFormat={false}
                      value={`${endDate}`}
                      dateFormat={'MM/DD/yyyy'}
                      closeOnSelect={true}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col lg="12" md="12" sm="12">
                <FormGroup>
                  <Label className="form-control-label">Reason</Label>
                  <Input
                    type="textarea"
                    onChange={(e) => setReason(e.target.value)}
                    value={reason}
                    disabled={baseViewMode}
                  />
                </FormGroup>
              </Col>
            </Row>

            <div className="d-flex justify-content-end mt-4">
              <div>
                {baseViewMode && (
                  <>
                    <Button color="primary" type="button" onClick={() => setBaseViewMode(!baseViewMode)}>
                      <i className="fas fa-pen" />
                    </Button>
                    <Button color="danger" type="button" onClick={(e) => handleDelete(e, data)}>
                      <i className="fas fa-trash" />
                    </Button>
                  </>
                )}
                {!baseViewMode && (
                  <>
                    <Button className="btn-secondary" onClick={toogle}>
                      Cancel
                    </Button>
                    <Button color="warning" type="submit">
                      Save
                    </Button>
                  </>
                )}
              </div>
            </div>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default BlackoutDatesForm;
