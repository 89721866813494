/* eslint-disable max-lines */
import { Button, Col, FormGroup, Input, Label, Modal, Row } from 'reactstrap';

type BankingAccountModalProps = {
  showBankingModal: boolean;
  bankAccountId: string;
  banks: any[];
  setShowBankingModal: (e?: any) => void;
};

const BankingAccountModal = ({
  showBankingModal,
  bankAccountId,
  banks,
  setShowBankingModal,
}: BankingAccountModalProps) => {
  const bank = banks?.find((b) => b?.id === bankAccountId);
  return (
    <Modal
      className="modal-dialog-centered"
      size="xl"
      isOpen={showBankingModal}
      toggle={() => setShowBankingModal(!showBankingModal)}
    >
      <div className="modal-header pb-1">
        <h5 className="modal-title">Banking Account</h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => setShowBankingModal(!showBankingModal)}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body pt-0">
        <Col lg="12">
          <Row>
            <Col lg="3">
              <FormGroup style={{ fontSize: '0.8125rem', whiteSpace: 'nowrap', marginTop: '20px' }}>
                <Label>Bank Name</Label>
                <Input type="text" value={bank?.bankName || ''} disabled />
              </FormGroup>
            </Col>
            <Col lg="3">
              <FormGroup style={{ fontSize: '0.8125rem', whiteSpace: 'nowrap', marginTop: '20px' }}>
                <Label>Routing Number</Label>
                <Input type="text" value={bank?.routingNumber || ''} disabled />
              </FormGroup>
            </Col>
            <Col lg="3">
              <FormGroup style={{ fontSize: '0.8125rem', whiteSpace: 'nowrap', marginTop: '20px' }}>
                <Label>Account Number</Label>
                <Input type="text" value={bank?.accountNumber || ''} disabled />
              </FormGroup>
            </Col>
            <Col lg="3">
              <FormGroup style={{ marginTop: '44px' }}>
                <a href={bank?.url || '#'} target="_blank">
                  <Button>
                    <i className="fas fa-download" style={{ fontSize: 19 }} /> Blank Check
                  </Button>
                </a>
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </div>
    </Modal>
  );
};

export default BankingAccountModal;
