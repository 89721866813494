import { Card, Button, Container, Row, Col, CardBody, FormGroup, Form, Input, Label } from 'reactstrap';
import { useContext, useState, useEffect } from 'react';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import { toast } from 'react-toastify';
import TimePicker from '../../../components/Core/TimezonePicker';
import KidsActivity from '../../../services/KidsActivity';
import ActivitySettingsService from '../../../services/ActivitySettings';
import ClassActivitySettingsList from './ClassActivitySettingsList';
import isAdmin from '../../../helpers/isAdmin';
import isDirector from '../../../helpers/isDirector';

type ClassRemindersProps = {
  kidsIds: string[];
  onCloseModal: (clear?: boolean) => void;
};

const ClassRemindersForm = ({ kidsIds, onCloseModal }: ClassRemindersProps) => {
  const { token, user }: any = useContext(AuthContext);
  const hasSettingsPermission = isAdmin(user) || isDirector(user);

  const [loading, setLoading] = useState(false);
  const [time, setTime] = useState('');
  const [notes, setNotes] = useState('');
  const [options, setOptions] = useState([]);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [settingsList, setSettingsList] = useState([]);

  useEffect(() => {
    if (!kidsIds.length) {
      toast.warn('Please select the students.');
      onCloseModal();
    }
    listActivitySettings();
  }, []);

  const closeModal = () => {
    onCloseModal();
  };

  const getOptionsChecked = (value: string) => {
    const isChecked = options.find((v) => v === value);
    return !!isChecked;
  };

  const setOptionsChecked = (value: string) => {
    const newOptionsSelected = JSON.parse(JSON.stringify(options));
    if (newOptionsSelected.includes(value)) {
      const removedArray = newOptionsSelected.filter((v: string) => String(v) !== value);
      setOptions(removedArray);
      return;
    }
    newOptionsSelected.push(value);
    setOptions(newOptionsSelected);
  };

  const listActivitySettings = async () => {
    try {
      const result = await ActivitySettingsService(token).listByType('reminder');
      setSettingsList(result.data || []);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No Activity Settings Found!');
    }
  };

  const handleSubmitForm = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);

    try {
      for (const k of kidsIds) {
        await KidsActivity(token).sendReminder(k, notes, time, options);
      }
      toast.success(`Saved successfully!`);
      onCloseModal(true);
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Create error!');
      toast.error(message);
    }

    setLoading(false);
  };

  const toggleActivitySettings = (e: any) => {
    e.preventDefault();
    setIsSettingsOpen(!isSettingsOpen);
  };

  return (
    <>
      <Container fluid>
        <div className="text-right mb-3">
          {hasSettingsPermission && (
            <i className="fas fa-cog fa-lg" style={{ cursor: 'pointer' }} onClick={toggleActivitySettings}></i>
          )}
        </div>
        <Form onSubmit={handleSubmitForm}>
          <Card>
            <CardBody>
              <Row>
                <Col lg="12">
                  <FormGroup row>
                    <Col md="12">
                      <label className="form-control-label" htmlFor="activityType">
                        Please provide more
                      </label>
                    </Col>
                    {settingsList.map((s: any) => (
                      <Col md="12" key={`settings-input-${s.id}`}>
                        <FormGroup check>
                          <Label check>
                            <Input
                              name="activityType"
                              type="checkbox"
                              defaultChecked={getOptionsChecked(s.name)}
                              onChange={() => setOptionsChecked(s.name)}
                            />{' '}
                            {s.name}
                          </Label>
                        </FormGroup>
                      </Col>
                    ))}
                  </FormGroup>
                </Col>
                <Col lg="12">
                  <FormGroup>
                    <label className="form-control-label">Time</label>
                    <TimePicker
                      onChangeTime={(time: string) => {
                        setTime(time);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col lg="12">
                  <FormGroup>
                    <label className="form-control-label"> Notes</label>
                    <Input type="textarea" rows="4" onChange={(e) => setNotes(e.target.value)} />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col md="6 text-center">
                  <Button color="primary" type="submit" size="lg" style={{ minWidth: '100px' }}>
                    Save
                  </Button>
                </Col>
                <Col md="6 text-center">
                  <Button color="secondary" size="lg" style={{ minWidth: '100px' }} onClick={closeModal}>
                    Cancel
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Form>
      </Container>
      <ClassActivitySettingsList
        isOpen={isSettingsOpen}
        settingsList={settingsList}
        activityType={'reminder'}
        onListUpdate={listActivitySettings}
        toggleModal={toggleActivitySettings}
      />
    </>
  );
};

export default ClassRemindersForm;
