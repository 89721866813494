import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import { isMobile } from 'react-device-detect';

const CardWidget = (props: {
  title: string;
  text: string;
  textSecondary?: string;
  onClick?: () => void;
  styles?: any;
}) => {
  return (
    <Card
      className={`mb-xl-0 ${isMobile ? 'mb-1' : 'card-stats mb-4'}`}
      style={props?.styles ? { cursor: 'pointer', ...props?.styles } : { cursor: 'pointer' }}
      onClick={(e) => (props?.onClick ? props?.onClick() : e.preventDefault())}
    >
      <CardBody style={isMobile ? { padding: '0.5rem 0.5rem', textAlign: 'center' } : {}}>
        <Row>
          <Col xs="12" md="6" style={isMobile ? { paddingLeft: 5, paddingRight: 5 } : {}}>
            <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
              {props.title || '-'}
            </CardTitle>
            <span className="h2 font-weight-bold mb-0">{props.text || '-'}</span>
          </Col>
          <Col
            xs="12"
            md="6"
            className={isMobile ? '' : 'col-auto'}
            style={isMobile ? { textAlign: 'center', paddingLeft: 5, paddingRight: 5 } : {}}
          >
            <span className="h4 font-weight-bold mb-0">{props.textSecondary || ''}</span>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default CardWidget;
