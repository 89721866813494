import { Button, Card, CardBody, FormGroup, Form, Container, Row, Col } from 'reactstrap';
import { toast } from 'react-toastify';
import { useContext, useState, useEffect } from 'react';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import SupportService from '../../../services/Support';
import { useParams } from 'react-router-dom';

type SupportReplyNewProps = {
  onCreateReply: () => void;
};

const SupportReplyNew = ({ onCreateReply }: SupportReplyNewProps) => {
  const { support, token }: any = useContext(AuthContext);
  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState('');
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    setIsDisabled(false);
    setDescription(support?.description || '');
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);
    setIsDisabled(false);

    const body = {
      description,
    };

    try {
      await SupportService(token).createReply(id, body);
      toast.success('Created successfully!');
      onCreateReply();
      setDescription('');
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Create error!');
      toast.error(message);
    }

    setLoading(false);
  };

  return (
    <>
      <Container fluid>
        <Row className="align-items-center mt-3">
          <Col className="order-xl-2" xl="12">
            <Card className="bg-secondary shadow">
              <CardBody>
                <Form onSubmit={handleSubmit}>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-description">
                            Reply
                          </label>
                          <textarea
                            className="form-control"
                            id="input-description"
                            placeholder="Description"
                            disabled={isDisabled}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Button className="my-4" color="primary" type="submit" disabled={isDisabled}>
                      Reply
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SupportReplyNew;
