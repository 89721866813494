import http from '../helpers/http';
import ScheduleBlockdaySettingsInterface from '../interfaces/ScheduleBlockdaySettingsInterface';

const ScheduleBlockdaySettings = (token: string) => {
  const headers = {
    'x-access-token': token,
  };

  return {
    list: async (schoolId: string) => {
      const result = await http.get(`/blockday-settings/${schoolId}/list`, {
        headers,
      });
      return result.data;
    },
    show: async (id: string) => {
      const result = await http.get(`/blockday-settings/${id}`, {
        headers,
      });
      return result.data;
    },
    createOrUpdate: async (body: Partial<ScheduleBlockdaySettingsInterface>) => {
      const result = await http.post(`/blockday-settings`, body, { headers });
      return result.data;
    },
    delete: async (id: string) => {
      const result = await http.delete(`/blockday-settings/${id}`, {
        headers,
      });
      return result.data;
    },
  };
};

export default ScheduleBlockdaySettings;
