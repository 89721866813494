import http from '../helpers/http';

const ClassReport = (token: string) => {
  const headers = {
    'x-access-token': token,
    'Content-Type': 'multipart/form-data',
  };

  return {
    monthlyAttendance: async (classId: string, year: string, month: string) => {
      const result = await http.get(`/reports/${classId}/monthly-attendance?year=${year}&month=${Number(month)}`, {
        headers,
        responseType: 'arraybuffer',
      });
      return result.data;
    },
    weeklyAttendance: async (classId: string, startDate: string, endDate: string) => {
      const result = await http.get(`/reports/${classId}/weekly-attendance?startDate=${startDate}&endDate=${endDate}`, {
        headers,
        responseType: 'arraybuffer',
      });
      return result.data;
    },
    tracking: async (classId: string, date: string) => {
      const result = await http.get(`/reports/${classId}/tracking?date=${date}`, {
        headers,
        responseType: 'arraybuffer',
      });
      return result.data;
    },
    timecard: async (userId: string, query?: string) => {
      const result = await http.get(`/reports/${userId}/time-card${query ? `?${query}` : ''}`, {
        headers,
        responseType: 'arraybuffer',
      });
      return result.data;
    },
    transition: async (classId: string, date: string) => {
      const result = await http.get(`/reports/${classId}/transition?date=${date}`, {
        headers,
        responseType: 'arraybuffer',
      });
      return result.data;
    },
  };
};

export default ClassReport;
