import { useContext, useState } from 'react';
import { Button, Col, FormGroup, Input, Modal, Row } from 'reactstrap';
import NumberFormat from 'react-number-format';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import TransactionService from '../../../services/Transaction';
import { validTransactionTypes, validChargeAccountingTypes } from '../../../interfaces/TransactionsInterface';
import { toast } from 'react-toastify';

type BillingChargeModalProps = {
  kidsIds: { id: string; parentId: string }[];
  isOpen: boolean;
  toggleModal: (e?: any) => void;
  onChange: (e?: any, amount?: number) => void;
};

const BillingChargeModal = ({ kidsIds, isOpen, toggleModal, onChange }: BillingChargeModalProps) => {
  const { token }: any = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState('');
  const [amount, setAmount] = useState(0);
  const [createInvoice, setCreateInvoice] = useState('YES');
  const [accountingType, setAccountingType] = useState('');

  const handleRequest = async (e: any) => {
    if (loading) return;
    e.preventDefault();
    setLoading(true);
    try {
      for (const kidsId of kidsIds) {
        await TransactionService(token).createPayment({
          kidsId: kidsId.id,
          userId: kidsId.parentId,
          transactionType: validTransactionTypes.CHARGE,
          amount,
          description,
          createInvoice,
          accountingType,
        });
      }
      onChargeModalChange(e);
      setLoading(false);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'Request error');
      setLoading(false);
    }
  };

  const toggleChargeModal = (e: any) => {
    e.preventDefault();
    toggleModal(e);
    setDescription('');
    setAmount(0);
  };

  const onChargeModalChange = (e: any) => {
    onChange(e);
    e.preventDefault();
    setDescription('');
    setAmount(0);
  };

  return (
    <>
      <Modal className="modal-dialog-centered" size="lg" isOpen={isOpen} toggle={(e: any) => toggleChargeModal(e)}>
        <div className="modal-header pb-1">
          <h5 className="modal-title">Post a Charge</h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={(e) => toggleChargeModal(e)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body pt-0">
          <Row className="mt-2">
            <Col lg="8">
              <FormGroup>
                <label className="form-control-label">Description</label>
                <Input
                  className="form-control-alternative"
                  placeholder="Please, insert a description."
                  type="text"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col xl="4">
              <FormGroup>
                <label className="form-control-label">Amount</label>
                <Input
                  type="text"
                  thousandsGroupStyle="thousand"
                  prefix="$"
                  decimalSeparator="."
                  displayType="input"
                  thousandSeparator={true}
                  allowNegative={false}
                  decimalScale={2}
                  tag={NumberFormat}
                  value={amount}
                  onValueChange={(e: any) => setAmount(e.floatValue)}
                />
              </FormGroup>
            </Col>
            <Col lg="8">
              <FormGroup>
                <label className="form-control-label">Create an Invoice?</label>
                <Input type="select" onChange={(e) => setCreateInvoice(e.target.value)} required>
                  <option value="YES">Create an invoice and add a transaction</option>
                  <option value="NO">Don't create an invoice, just add a transaction to be charged later</option>
                </Input>
              </FormGroup>
            </Col>
            <Col lg="4">
              <FormGroup>
                <label className="form-control-label">Accounting Code</label>
                <Input type="select" onChange={(e) => setAccountingType(e.target.value)} required>
                  <option value="">Please select</option>
                  {validChargeAccountingTypes.map((a: any, index: number) => {
                    return (
                      <option value={a.value} key={`accounting-${index}`}>
                        {a.label}
                      </option>
                    );
                  })}
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col md="6 text-center">
              <Button color="secondary" size="md" style={{ minWidth: '100px' }} onClick={(e) => toggleChargeModal(e)}>
                Cancel
              </Button>
            </Col>
            <Col md="6 text-center">
              <Button color="success" size="md" style={{ minWidth: '100px' }} onClick={(e) => handleRequest(e)} lo>
                Save
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default BillingChargeModal;
