import { Button, Card, CardBody, CardTitle, Row, Col } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import CardInfoInterface from '../../interfaces/UtilsInterface';

const CardInfo = (props: CardInfoInterface) => {
  const isTablet = window.innerWidth <= 1024 && !isMobile;
  return (
    <Card className="card-stats mb-4 mb-xl-0 h-100" style={isTablet ? { height: 130 } : {}}>
      <CardBody style={{ height: 120 }}>
        <Row>
          <Col sm={isMobile || isTablet ? 12 : 8} xs="12" style={isTablet ? { paddingLeft: 0 } : {}}>
            <CardTitle
              tag="h5"
              className={`text-uppercase text-muted mb-0 ${!props.children ? 'pb-3' : ''}`}
              style={isMobile || isTablet ? { fontSize: 11 } : { fontSize: 12 }}
            >
              {props.title}
              {(isMobile || isTablet) && props.toggle && (
                <Button
                  onClick={props.toggle}
                  size="sm"
                  style={{ float: 'right', display: 'block' }}
                  className={`mt-n1 mr-n3`}
                >
                  <i className="fas fa-list"></i>
                </Button>
              )}
            </CardTitle>
            {typeof props.count !== 'undefined' ? (
              <>
                <span
                  className={`w-100 d-flex justify-content-center big-font text-center mb-0 ${
                    !props.children ? 'mt-1' : ''
                  }`}
                  style={props?.handleCountClick ? { cursor: 'pointer' } : {}}
                  onClick={(event: any) =>
                    props?.handleCountClick ? props.handleCountClick() : event?.preventDefault()
                  }
                >
                  {props.count || 0}
                </span>
              </>
            ) : (
              <></>
            )}

            {props.children}
          </Col>
          {!(isMobile || isTablet) && (
            <Col sm="4" xs="12">
              <Row>
                {props.toggle && (
                  <Col md="12">
                    <Button
                      href="#"
                      onClick={props.toggle}
                      size="sm"
                      style={{ float: 'right', display: 'block' }}
                      className={`mt-n1`}
                    >
                      <i className="fas fa-list"></i>
                    </Button>
                  </Col>
                )}

                <Col md="12" style={{ textAlign: 'right' }}>
                  <div
                    className={`card-info-icon icon icon-shape ${props.class} text-white rounded-circle shadow mt-4`}
                  >
                    <i className={`fas ${props.icon}`} />
                  </div>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </CardBody>
    </Card>
  );
};

export default CardInfo;
