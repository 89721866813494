import { Button, Card, CardBody, Container, Row, Col, CardTitle } from 'reactstrap';

type ClassActivityConfirmProps = {
  type: string;
  sendActivity: (type: string) => void;
  onClose: () => void;
};

const validActivities: any = {
  CHECKIN: 'check in',
  CHECKOUT: 'check out',
};

const ClassActivityConfirm = ({ type, sendActivity, onClose }: ClassActivityConfirmProps) => {
  return (
    <Container fluid>
      <Card>
        <CardBody>
          <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
            Are you sure you want to {validActivities[type]}?
          </CardTitle>
          <Row className="mt-3">
            <Col md="12">
              <span className="h2 font-weight-bold mb-0">
                Students activity will update at this time.
                <br />
                <strong>Parents will be notified.</strong>
              </span>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col xs="6 text-center">
              <Button
                color="primary"
                href="#"
                onClick={() => {
                  sendActivity(type);
                  onClose();
                }}
                size="lg"
                style={{ minWidth: '100px' }}
              >
                Yes
              </Button>
            </Col>
            <Col xs="6 text-center">
              <Button color="danger" href="#" onClick={onClose} size="lg" style={{ minWidth: '100px' }}>
                No
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
};

export default ClassActivityConfirm;
