import { useState, useEffect } from 'react';
import { Modal, Row, Col } from 'reactstrap';
import FullCalendar, { EventClickArg, EventContentArg } from '@fullcalendar/react';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import ScheduleForm from './ScheduleForm';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import ReactTooltip from 'react-tooltip';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './calendar.css';

type scheduleCalendarProps = {
  events: any[];
  updateEventList: () => void;
  onClickDay: (date: string) => void;
};

const Schedule = ({ events, updateEventList, onClickDay }: scheduleCalendarProps) => {
  const [scheduleId, setScheduleId] = useState('');
  const pageTitle = scheduleId ? 'Update' : 'Create';
  const [dateClick, setDateClick] = useState('');

  const [refresh, setRefresh] = useState(false);

  const handleScheduleCreated = () => {
    setRefresh(!refresh);
    updateEventList();
  };

  useEffect(() => {}, []);

  const handleDateClick = (selectInfo: DateClickArg) => {
    const date = new Date(selectInfo.date).toISOString().replace(/T.*$/, ''); // YYYY-MM-DD
    setScheduleId('');
    setDateClick(date);
    modalChange();
    onClickDay(date);
  };

  const [scheduleForm, setScheduleForm] = useState(false);

  const toggleScheduleForm = () => {
    setScheduleForm(!scheduleForm);
  };

  const modalChange = () => {
    // setScheduleForm(!scheduleForm);
    if (scheduleForm == true) {
      //getScheduleId();
    }
  };

  const handleEventClick = (clickInfo: EventClickArg) => {
    if (clickInfo.event.classNames[0] == 'schedule-calendar-event-red') {
      //console.log(clickInfo.event.classNames);
    } else {
      setScheduleId(clickInfo.event.id);
      modalChange();
    }
  };

  const renderEventContent = (eventContent: EventContentArg) => {
    return (
      <>
        <b>{eventContent.timeText}</b>
        <i data-tip={eventContent.event.extendedProps.observations}>{eventContent.event.title}</i>
        <ReactTooltip />
      </>
    );
  };

  // const blockDays = (selectInfo: any) => {
  //   const blocked = ['2023-08-24'];
  //   if (blocked.includes(selectInfo?.startStr)) return false;
  //   return true;
  // };

  return (
    <>
      <Row>
        <Col>
          <div className="calendar-app mt-3">
            <div className="calendar-app-main">
              <FullCalendar
                plugins={[dayGridPlugin, interactionPlugin, bootstrapPlugin]}
                headerToolbar={{
                  left: 'prev',
                  center: 'title',
                  right: 'next',
                }}
                eventTimeFormat={{
                  hour: 'numeric',
                  minute: '2-digit',
                  meridiem: 'short',
                }}
                initialView="dayGridMonth"
                buttonIcons={{
                  prev: 'bi bi-caret-left-fill',
                  next: 'bi bi-caret-right-fill',
                }}
                themeSystem="bootstrap"
                editable={false}
                selectMirror={true}
                dayMaxEvents={false}
                weekends={false}
                selectable={true}
                unselectAuto={false}
                events={events}
                validRange={{ start: new Date() }}
                // selectAllow={blockDays}
                //select={handleDateSelect}
                eventContent={renderEventContent} // custom render function
                eventClick={handleEventClick}
                dateClick={handleDateClick}
              />
            </div>
          </div>
        </Col>
      </Row>

      <Modal className="modal-dialog-centered" isOpen={scheduleForm} toggle={() => toggleScheduleForm()}>
        <div className="modal-header">
          <h5 className="modal-title" id="Label">
            {`${pageTitle} Schedule`}
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => toggleScheduleForm()}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <ScheduleForm
            isOpen={scheduleForm}
            scheduleId={scheduleId}
            onModalStatus={modalChange}
            selectedDate={dateClick}
            onCreateSchedule={handleScheduleCreated}
          />
        </div>
      </Modal>
    </>
  );
};

export default Schedule;
