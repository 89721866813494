/* eslint-disable max-lines */
import { Link } from 'react-router-dom';
import { useContext, useState, useEffect } from 'react';
import { Context as AuthContext } from '../../contexts/AuthContext';
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Col,
  Badge,
  Button,
} from 'reactstrap';
import QrCode from '../../components/Core/QrCode';
import UserService from '../../services/User';
import SchoolService from '../../services/School';
import moment from 'moment';
import SearchBarService from '../../services/SearchBar';
import history from '../../history';
import AsyncSelect from 'react-select/async';
import NotificationPermissionsList from '../../views/pages/Users/TabContent/NotificationPermissionsList';
import isAdmin from '../../helpers/isAdmin';
import { toast } from 'react-toastify';
import UserCustomer from '../../services/UserCustomer';
import BillingParentSavePayment from '../../views/pages/Billing/BillingParentSavePayment';

import { loadStripe } from '@stripe/stripe-js';
import isParent from '../../helpers/isParent';
import IncidentReports from '../../services/IncidentReports';
import ViewIncidentReportModal from '../../views/pages/Kids/TabContent/ViewIncidentReportModal';
import isStaff from '../../helpers/isStaff';
import isTeacher from '../../helpers/isTeacher';
import { isMobile } from 'react-device-detect';
import ModalParentKids from './ModalParentKids';

const BaseNavbar = (props: any) => {
  const isTablet = window.innerWidth <= 1112 && window.innerWidth > 768 && isMobile;
  const {
    handleLogout,
    user,
    userNotifications,
    token,
    updateUserNotifications,
    userTotalUnreadMessages,
    currentSchool,
  }: any = useContext(AuthContext);

  const logout = async (e: any) => {
    e?.preventDefault();
    handleLogout();
  };

  const tabletStyles = isTablet ? { maxWidth: 280 } : {};
  const asyncSelectStyles = {
    control: (styles: any) => ({
      ...styles,
      ...{ border: 0, boxShadow: 'none', backgroundColor: 'transparent' },
      ...tabletStyles,
    }),
    input: (styles: any) => ({
      ...styles,
      ...{ border: 0, width: '480px', backgroundColor: 'transparent', color: 'rgba(255, 255, 255, 0.7)' },
    }),
    placeholder: (styles: any) => ({
      ...styles,
      ...{
        fontSize: '0.810rem',
        fontWeight: 400,
        lineHeight: 1.5,
        color: 'rgba(255, 255, 255, 0.7)',
        textOverflow: 'clip',
      },
    }),
    valueContainer: (styles: any) => ({
      ...styles,
      ...{ backgroundColor: 'transparent' },
    }),
    singleValue: (styles: any) => ({
      ...styles,
      ...{
        fontSize: '0.810rem',
        fontWeight: 400,
        lineHeight: 1.5,
        color: 'rgba(255, 255, 255, 0.7)',
        textOverflow: 'clip',
      },
    }),
  };
  const [modalConfigNotify, setModalConfigNotify] = useState(false);
  const [modal, setModal] = useState(false);
  const [notificationList, setNotificationList] = useState<any>([]);
  const toggleModal = () => setModal(!modal);
  const toggleModalNotify = () => setModalConfigNotify(!modalConfigNotify);
  const [modalCanotNavigate, setModalCanotNavigate] = useState(false);
  const [transactionType, setTransactionType] = useState('');
  const [modalPayment, setModalPayment] = useState(false);
  const togglePaymentModal = (e: any) => {
    e?.preventDefault();
    setModalPayment(!modalPayment);
  };

  const [options, setOptions] = useState({ customerId: '' } as any);
  const [stripePromise, setStripePromise] = useState<any>();

  const [incidentReportModalData, setIncidentReportModalData] = useState<any>({} as any);
  const [isIncidentReportModalOpen, setIsIncidentReportModalOpen] = useState(false);
  const showNavBarSearch = !isParent(user) && !isStaff(user) && !isTeacher(user);

  const handleOpenIncidentReportModal = (data: any) => {
    setIncidentReportModalData(data);
    setIsIncidentReportModalOpen(true);
  };

  useEffect(() => {
    setNotificationList(userNotifications);
    verifyIfNotAdminUserCanNavigate(user);
    verifyAndShowIfNeedAssignAnyIncident(user);
  }, [userNotifications]);

  const verifyIfNotAdminUserCanNavigate = async (user: any) => {
    if (!isParent(user) || !currentSchool?.value) {
      return false;
    }

    if (user?.havePaymentMethod === true) {
      return false;
    }

    const result = await UserCustomer(token || '').create({
      userId: user?.id || '',
      schoolId: currentSchool?.value || '',
      options: { email: user?.email || '' },
    });

    if (!Object.values(result?.data || [])?.length) {
      setModalCanotNavigate(true);
      return false;
    }

    const customer = result?.data || {};
    setOptions({ customerId: customer?.options?.providerId || '' });
    user.havePaymentMethod = !(
      !result?.data?.options?.paymentMethods?.ACH?.length && !result?.data?.options?.paymentMethods?.CREDIT_CARD?.length
    );

    if (user?.havePaymentMethod === true) {
      return false;
    }
    setModalCanotNavigate(true);
  };

  const verifyAndShowIfNeedAssignAnyIncident = async (user: any) => {
    if (!isParent(user)) {
      return false;
    }
    const result: any = await UserService(token || '').listKids(user?.id);
    const query = `startDateTime=${moment()?.isoWeekday(0)?.format('MM/DD/yyyy')}&finalDateTime=${moment()
      .isoWeekday(0)
      .add(7, 'days')
      .format('MM/DD/yyyy')} 23:59:59`;
    result?.data?.forEach(async (kid: any) => {
      const incidents = await IncidentReports(token || '').listByKid(kid?.kidsId, query);
      if (!incidents?.data?.length) {
        return;
      }
      handleOpenIncidentReportModal(incidents?.data?.[0] || {});
    });
  };

  const notificationListItems = () => {
    // trocaar para object.values
    return Object.keys(notificationList)?.map((i) => {
      return (
        <>
          <DropdownItem
            to="#"
            tag={Link}
            onClick={() => {
              readNotification((notificationList as any)?.[i]?.id);
            }}
            style={{ width: '400px' }}
            key={`baseItem-${i}`}
          >
            <div className="clearfix">
              <div className="float-left" style={{ paddingRight: '10px' }}>
                <img
                  className="rounded-circle"
                  style={{ width: 40, height: 40 }}
                  src={(notificationList as any)?.[i]?.picture}
                  alt={``}
                />
                <p className="text-center small">
                  <b>{(notificationList as any)?.[i]?.data?.name}</b>
                </p>
              </div>
              <div className="float-left" style={{ width: '85%' }}>
                <div>
                  <b>{(notificationList as any)?.[i]?.data?.title}</b>
                </div>
                <div>{(notificationList as any)?.[i]?.data?.description}</div>
                <div className="text-right">
                  {(notificationList as any)?.[i]?.type === 'employeeBirthdays'
                    ? 'Today'
                    : moment((notificationList as any)?.[i]?.data?.occurAt)
                        .utc()
                        .fromNow()}
                </div>
              </div>
            </div>
          </DropdownItem>
          <DropdownItem divider={true}></DropdownItem>
        </>
      );
    });
  };

  const clearAllNotifications = async () => {
    const notificationIds = notificationList?.map((item: any) => {
      return item?.id;
    });

    await UserService(token || '').readNotification({ id: notificationIds });
    updateUserNotifications();
  };

  const readNotification = async (id: string) => {
    await UserService(token || '').readNotification({ id: [id] });
    updateUserNotifications();
  };

  // Async Select
  const [selected, setSelected] = useState<any>(undefined);
  const loadOptions = async (query?: string) => {
    try {
      const options: any[] = [
        {
          label: 'Children',
          options: [],
        },
        {
          label: 'Staff',
          options: [],
        },
        {
          label: 'Parent',
          options: [],
        },
      ];
      if (!query) return options;
      try {
        const result = await SearchBarService(token || '').searchBySchool(currentSchool?.value, query);
        if (result?.data?.staff?.length) {
          const staff = result?.data?.staff?.map((s: any) => ({
            name: `${s?.firstName} ${s?.lastName}`,
            value: `/users/${s?.id || ''}/profile`,
            photo: s?.picture || '',
            subtitle: '',
          }));
          for (const o of options) {
            if (o?.label === 'Staff') {
              o.options = staff || [];
            }
          }
        }
        if (result?.data?.parent?.length) {
          const parent = result?.data?.parent?.map((s: any) => ({
            name: `${s?.firstName} ${s?.lastName}`,
            value: `/users/${s?.id || ''}/profile`,
            photo: s?.picture || '',
            subtitle: '',
          }));
          for (const o of options) {
            if (o?.label === 'Parent') {
              o.options = parent || [];
            }
          }
        }
        if (result?.data?.kids?.length) {
          const kids = result?.data?.kids?.map((s: any) => ({
            name: `${s?.firstName} ${s?.lastName}`,
            value: `/student-profile/${s?.id || ''}`,
            photo: s?.picture || '',
            subtitle: '',
          }));
          for (const o of options) {
            if (o?.label === 'Children') {
              o.options = kids || [];
            }
          }
        }
      } catch (e: any) {
        console.error(e?.response?.data?.message || 'Error on search');
      }

      return options;
    } catch (e: any) {
      return [];
    }
  };

  const renderResult = (option: any) => {
    const imgStyle: any = {
      borderRadius: '50%',
      verticalAlign: 'middle',
      marginRight: 10,
    };
    return (
      <div className="select-option">
        <button type="button" style={{ border: 0, width: '100%', background: 'transparent' }}>
          <div style={{ width: '50%', textAlign: 'left', display: 'inline-block' }}>
            <span>
              <img alt="" style={imgStyle} width="28" height="28" src={option?.photo || ''} />
              <span>{option?.name || ''}</span>
            </span>
          </div>
          <div style={{ width: '50%', textAlign: 'right', display: 'inline-block' }}>
            <span className="mb-1">{option?.subtitle || ''}</span>
          </div>
        </button>
      </div>
    );
  };

  const onChangeSearchBar = (option: any) => {
    setSelected(option);
  };

  const updateUserConfig = async (notificationPermissions: any) => {
    const body = {
      notificationPermissions,
    };
    try {
      await UserService(token || '').updateConfigs(user?.id, body);
      toast.success('Updated successfully!');
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Update error!');
      toast.error(message);
    }
  };

  const getSchoolStripeCurrent = async () => {
    if (!currentSchool?.value) return;
    try {
      const r = await SchoolService('').getSchoolStripeCurrent(currentSchool?.value);
      if (r?.data?.stripePublicKey) {
        setStripePromise(loadStripe(r?.data?.stripePublicKey || '', { locale: 'en' }));
      }
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Stripe current error!');
      console.error(message);
    }
  };

  useEffect(() => {
    getSchoolStripeCurrent();
  }, [currentSchool]);

  useEffect(() => {
    if (selected?.value) {
      setSelected(null);
      history?.push(selected?.value);
    }
  }, [selected]);

  return (
    <>
      {isIncidentReportModalOpen && incidentReportModalData && (
        <ViewIncidentReportModal
          data={incidentReportModalData || {}}
          isIncidentReportOpen={isIncidentReportModalOpen || false}
          setIsIncidentReportOpen={(_: boolean) => {}}
          incidentSigned={(_: any) => {
            setIsIncidentReportModalOpen(false);
            if (user) verifyAndShowIfNeedAssignAnyIncident(user);
          }}
          showCloseButton={false}
        />
      )}
      {modalPayment && stripePromise && (
        <BillingParentSavePayment
          isOpen={modalPayment}
          toggleModal={togglePaymentModal}
          customerOptions={options}
          stripePromise={stripePromise}
          id={user?.id || ''}
          name={user?.name || ''}
          email={user?.email || ''}
          transactionType={transactionType}
        />
      )}
      <ModalParentKids user={user} />
      <Modal isOpen={modalCanotNavigate} toggle={() => {}} centered>
        <ModalHeader className="w-100">Exist error in your Billing Configuration</ModalHeader>
        <ModalBody className="pt-0">
          <Row>
            <Col className="text-right">
              <Button
                color="primary"
                size="md"
                onClick={() => {
                  setTransactionType('ACH');
                  setModalPayment(true);
                }}
              >
                Add Account
              </Button>
            </Col>
            <Col className="text-left">
              <Button
                color="primary"
                size="md"
                onClick={() => {
                  setTransactionType('CREDIT_CARD');
                  setModalPayment(true);
                }}
              >
                Add Card
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <Modal isOpen={modalConfigNotify} toggle={toggleModalNotify} centered>
        <ModalHeader toggle={toggleModalNotify} className="pb-0">
          My Notification
        </ModalHeader>
        <ModalBody className="pt-0">
          <NotificationPermissionsList
            userId={user?.id || ''}
            setNotifications={(notificationPermissions: any) => updateUserConfig(notificationPermissions)}
            showOnlyThisNotificationsOptions={
              isAdmin(user) ? [] : ['newMessage', 'postWithMyChildren', 'whenMyChildrenCheck']
            }
          />
        </ModalBody>
      </Modal>
      <Modal isOpen={modal} toggle={toggleModal} centered>
        <ModalHeader toggle={toggleModal} className="pb-0">
          My Code
        </ModalHeader>
        <ModalBody className="pt-0">
          <QrCode />
        </ModalBody>
        <ModalFooter style={{ textAlign: 'center' }}>
          <span className="h2 font-weight-bold mx-auto">{`${user?.pin || '-'}`}</span>
        </ModalFooter>
      </Modal>
      {(!isMobile || isTablet) && (
        <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
          <Container fluid>
            <Link className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block" to="/">
              {props?.brandText}
            </Link>

            {showNavBarSearch && (
              <Form
                className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg navbar-input-search"
                onSubmit={(e) => e.preventDefault()}
              >
                <FormGroup className="mb-0">
                  <InputGroup className="input-group-alternative" style={isTablet ? { maxWidth: 350 } : {}}>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-search" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <AsyncSelect
                      // cacheOptions
                      defaultOptions
                      loadOptions={loadOptions}
                      formatOptionLabel={renderResult}
                      styles={asyncSelectStyles}
                      placeholder="Search child or staff"
                      value={selected}
                      onChange={onChangeSearchBar}
                    />
                  </InputGroup>
                </FormGroup>
              </Form>
            )}

            <Nav
              className="align-items-center d-none d-md-flex navbar-notifications"
              style={{ paddingRight: '15px' }}
              navbar
            >
              <Row>
                {
                  <Col style={{ padding: '0' }}>
                    <a aria-haspopup="true" className="nav-link" aria-expanded="true" href="/messaging">
                      <i className="fas fa-comment" style={{ fontSize: 'xx-large', paddingTop: '7px' }}></i>
                      <Badge
                        color="primary"
                        className="badge-md badge-circle badge-floating border-white"
                        style={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          margin: '-15px 10px 0px',
                          padding: '0',
                        }}
                      >
                        {userTotalUnreadMessages}
                      </Badge>
                    </a>
                  </Col>
                }
              </Row>
            </Nav>

            <Nav className="align-items-center d-none d-md-flex" navbar>
              <div style={{ paddingRight: '35px' }}>
                <UncontrolledDropdown nav>
                  <DropdownToggle className="pr-0" nav style={{ paddingLeft: '0' }}>
                    <Media className="align-items-center">
                      <i className="fas fa-bell" style={{ fontSize: 'xx-large', paddingTop: '7px' }}></i>
                      <Badge
                        color="primary"
                        className="badge-md badge-circle badge-floating border-white"
                        style={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          margin: '-15px 10px 0px',
                          padding: '0',
                        }}
                      >
                        {notificationList?.length}
                      </Badge>
                    </Media>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-arrow" right style={{ zIndex: '99999999', minWidth: '400px' }}>
                    <DropdownItem className="noti-title" header tag="div">
                      <h6 className="text-overflow m-0">
                        Notifications
                        <div style={{ float: 'right' }}>
                          <a onClick={toggleModalNotify} style={{ cursor: 'pointer' }}>
                            <i className="fas fa-2x fa-cog"></i>
                          </a>
                        </div>
                      </h6>
                    </DropdownItem>
                    {notificationListItems()}

                    <DropdownItem
                      className="text-center"
                      onClick={() => {
                        clearAllNotifications();
                      }}
                    >
                      <span>Clear All</span>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
              <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav style={{ paddingLeft: '0' }}>
                  <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <img
                        alt={`${user?.firstName || '-'}`}
                        src={user?.picture ? `${user.picture}` : require('../../assets/img/user-default.png').default}
                        style={{ height: '100%' }}
                      />
                    </span>
                    {!isTablet && (
                      <Media className="ml-2 d-none d-lg-block">
                        <span className="mb-0 text-sm font-weight-bold">{`${user?.firstName || '-'} ${
                          user?.lastName || ''
                        }`}</span>
                      </Media>
                    )}
                  </Media>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">Welcome!</h6>
                  </DropdownItem>
                  <DropdownItem to={`/users/${user?.id}/profile`} tag={Link}>
                    <i className="ni ni-single-02" />
                    <span>My profile</span>
                  </DropdownItem>
                  {/* <DropdownItem to="/user-profile" tag={Link}>
                    <i className="ni ni-calendar-grid-58" />
                    <span>Activity</span>
                  </DropdownItem> */}

                  <DropdownItem onClick={toggleModal} to="#" tag={Link}>
                    <i className="fas fa-qrcode"></i>
                    <span>My Code</span>
                  </DropdownItem>

                  <DropdownItem to="#" tag={Link}>
                    <i className="ni ni-badge" />
                    <span>
                      <span className="font-weight-light">REF: </span> {`${user?.pin || '-'}`}
                    </span>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem href="/logout" onClick={logout}>
                    <i className="fas fa-sign-out-alt" />
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Container>
        </Navbar>
      )}
    </>
  );
};

export default BaseNavbar;
