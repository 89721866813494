import { Modal, Row, Col, Button, Form, FormGroup, Input } from 'reactstrap';
import { useContext, useState } from 'react';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import UserService from '../../../services/User';
import { toast } from 'react-toastify';

type ChangePasswordModalProps = {
  userId: string;
  isOpen: boolean;
  isAdmin: boolean;
  toggleModal: (e?: any) => void;
  notClose?: boolean;
  submitModal?: (e?: any) => void;
};

const ChangePasswordModal = ({
  userId,
  isOpen,
  isAdmin,
  toggleModal,
  notClose,
  submitModal,
}: ChangePasswordModalProps) => {
  const { token }: any = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);

    try {
      if (password !== repeatPassword) return toast.error('Passwords do not match.');
      await UserService(token).password({ userId, currentPassword, password });
      toast.success(`Saved successfully!`);
      toggleModal();
      if (submitModal) {
        submitModal();
      }
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Create error!');
      toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal className="modal-dialog-centered" size="lg" isOpen={isOpen} toggle={(e: any) => toggleModal(e)}>
        <div className="modal-header pb-1">
          <h5 className="modal-title mb-3">Change Password</h5>

          <div className="text-right">
            {!notClose ? (
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={(e) => toggleModal(e)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="modal-body pt-0">
          <Row>
            <Col>
              <Form onSubmit={handleSubmit}>
                <Row>
                  {!isAdmin ? (
                    <Col lg="12">
                      <FormGroup>
                        <label className="form-control-label">Current Password</label>
                        <Input type="password" onChange={(e) => setCurrentPassword(e.target.value)} required />
                      </FormGroup>
                    </Col>
                  ) : (
                    <></>
                  )}
                  <Col lg="12">
                    <FormGroup>
                      <label className="form-control-label">New Password</label>
                      <Input type="password" onChange={(e) => setPassword(e.target.value)} required />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup>
                      <label className="form-control-label">Repeat New Password</label>
                      <Input type="password" onChange={(e) => setRepeatPassword(e.target.value)} required />
                    </FormGroup>
                  </Col>
                  <Col md="12 mb-3">
                    <Button color="primary" type="submit" size="sm">
                      Save
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default ChangePasswordModal;
