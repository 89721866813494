/* eslint-disable max-lines */
import { Card, CardHeader, Row, Col, Input, FormGroup, Button } from 'reactstrap';
import { useContext, useState, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import { Context as AuthContext } from '../../contexts/AuthContext';
import { createPagedMeta } from '../../entities/PagedMeta';
import { createOptionsPage } from '../../entities/OptionsPaged';
import SchoolService from '../../services/School';
import SchoolInterface from '../../interfaces/SchoolInterface';
import FranchiseeService from '../../services/Franchisee';
import FranchiseeInterface from '../../interfaces/FranchiseeInterface';
import isAdmin from '../../helpers/isAdmin';
import isParent from '../../helpers/isParent';
import isClassroom from '../../helpers/isClassroom';
import isTeacher from '../../helpers/isTeacher';
import QrCodeModal from './Users/QrCodeModal';

const BaseHomeMobile = (_: any) => {
  const {
    user,
    token,
    currentSchool,
    changeSchool,
    lastSchool,
    currentFranchisee,
    changeFranchisee,
    lastFranchisee,
  }: any = useContext(AuthContext);

  const [selectPlaceholder, setSelectPlaceholder] = useState('Select a school');
  const [schoolSelected, setSchoolSelected] = useState(null as any);

  const [selectPlaceholderFranchisee, setSelectPlaceholderFranchisee] = useState('Select a franchisee');
  const [franchiseeSelected, setFranchiseeSelected] = useState(null as any);

  const [modalQr, setModalQr] = useState(false);
  const toggleModalQr = () => setModalQr(!modalQr);

  const [schools, setSchools] = useState([] as any);

  const isAdminUser = isAdmin(user);
  const isParentUser = isParent(user);
  const isClassroomUser = isClassroom(user);
  const isTeacherUser = isTeacher(user);

  const asyncSelectStyles = {
    control: (styles: any) => ({
      ...styles,
      ...{ border: 0, backgroundColor: 'rgba(0, 0, 0, 0.03)' },
    }),
    input: (styles: any) => ({ ...styles, ...{ border: 0 } }),
    placeholder: (styles: any) => ({
      ...styles,
      ...{ fontSize: '0.810rem', fontWeight: 400, lineHeight: 1.5, color: '#8898aa', textOverflow: 'clip' },
    }),
    singleValue: (styles: any) => ({
      ...styles,
      ...{ fontSize: '0.810rem', fontWeight: 400, lineHeight: 1.5, color: '#8898aa', textOverflow: 'clip' },
    }),
    indicatorsContainer: (styles: any) => ({
      ...styles,
      ...{ display: 'none' },
    }),
  };

  const changeCurrentSchool = async (school: any) => {
    if (school) {
      changeSchool(school);
    }
  };

  const changeCurrentFranchisee = async (franchisee: any) => {
    if (franchisee) {
      changeFranchisee(franchisee);
      setFirstSchoolTheFranchisee(franchisee);
    }
  };

  const setFirstSchoolTheFranchisee = async (franchisee: any) => {
    try {
      const filteredOptions = {
        filters: [] as any[],
        options: createOptionsPage(createPagedMeta()),
      };
      const filterFranchiseeId = {
        field: 'franchiseeId',
        operation: 'hash',
        value: franchisee.value,
      };
      filteredOptions.filters = [filterFranchiseeId];

      const result = await SchoolService(token || '').list(filteredOptions);
      const data: SchoolInterface[] = result.data;

      if (data?.[0]?.name && data?.[0]?.id) {
        const firstSchool = {
          label: data?.[0]?.name,
          value: data?.[0]?.id,
        };
        setSchoolSelected(firstSchool);
        changeCurrentSchool(firstSchool);
      }
    } catch (e: any) {
      console.error(e?.response?.data?.message || 'Invalid Franchisee ID');
    }
  };

  const loadFranchisee = async (search?: string) => {
    try {
      setSelectPlaceholderFranchisee('Loading');
      const filteredOptions = {
        filters: [] as any[],
        options: createOptionsPage(createPagedMeta()),
      };
      if (search) {
        const filter = {
          field: 'name',
          operation: 'ilike',
          value: `%${search}%`,
        };
        filteredOptions.filters = [filter];
      }

      const result = await FranchiseeService(token || '').list(filteredOptions);
      const data: FranchiseeInterface[] = result.data;

      const storeKeyCurrentFranchisee = 'x-current-franchisee';
      const localCurrentFranchisee = localStorage.getItem(storeKeyCurrentFranchisee);
      if (!search && !localCurrentFranchisee) {
        const dataSpliced = data.splice(0, 1);
        const franchisee = {
          label: dataSpliced?.length ? dataSpliced[0].name : '',
          value: dataSpliced?.length ? dataSpliced[0].id : '',
        };
        changeCurrentFranchisee(franchisee);
      }

      return data.map((d) => ({ label: d.name, value: d.id }));
    } catch (e: any) {
      return [];
    } finally {
      setSelectPlaceholderFranchisee('Select a franchisee');
    }
  };

  const loadSchools = async (search?: string) => {
    try {
      setSelectPlaceholder('Loading');
      const filteredOptions = {
        filters: [] as any[],
        options: createOptionsPage(createPagedMeta()),
      };
      if (search) {
        const filter = {
          field: 'name',
          operation: 'ilike',
          value: `%${search}%`,
        };
        filteredOptions.filters = [filter];
      }

      const filterFranchiseeId = {
        field: 'franchiseeId',
        operation: 'hash',
        value: currentFranchisee.value,
      };
      filteredOptions.filters = [filterFranchiseeId];

      const result = await SchoolService(token || '').list(filteredOptions);
      const data: SchoolInterface[] = result.data;
      if (isParentUser) {
        const parentData = user.kids.map((item: any) => ({
          label: item.kids.enrollment.school.id,
          value: item.kids.enrollment.school.name,
        }));
        setSchools(parentData);
        return parentData;
      }
      setSchools(data.map((d) => ({ label: d.name, value: d.id })));
      return data.map((d) => ({ label: d.name, value: d.id }));
    } catch (e: any) {
      return [];
    } finally {
      setSelectPlaceholder('Select a school');
    }
  };

  useEffect(() => {
    if (currentSchool) {
      setSchoolSelected(currentSchool);
    }
    loadSchools();
  }, []);

  useEffect(() => {
    if (currentFranchisee) {
      setFranchiseeSelected(currentFranchisee);
    }
    loadFranchisee();
    loadSchools();
  }, []);

  useEffect(() => {
    if (currentSchool) {
      setSchoolSelected(currentSchool);
    }
    loadSchools();
  }, [currentSchool, lastSchool]);

  useEffect(() => {
    if (currentFranchisee) {
      setFranchiseeSelected(currentFranchisee);
    }
    loadFranchisee();
    loadSchools();
  }, [currentFranchisee, lastFranchisee]);

  return (
    <>
      <Row className={'d-flex justify-content-center'}>
        <Col style={{ marginBottom: 4, padding: 2 }}>
          <Card className="card-profile shadow" style={{ minWidth: 208 }}>
            <Row className="justify-content-center mb-n5">
              <Col className="order-lg-2" lg="3">
                <div className="card-profile-image" style={{ height: 135 }}>
                  <a href="#a" onClick={(e) => e.preventDefault()}>
                    <img
                      alt={`${user.firstName}`}
                      className="rounded-circle"
                      src={user?.picture ? `${user.picture}` : require('../../assets/img/user-default.png').default}
                      style={{ width: 135, height: 135 }}
                    />
                  </a>
                </div>
              </Col>
            </Row>
            <CardHeader className="text-center border-0 pb-1">
              <div style={{ marginBottom: '5px' }}>
                <p style={{ marginTop: 0, marginBottom: 5 }}>{`${user?.firstName || ''} ${user?.lastName || ''}`}</p>
                <p style={{ marginTop: 0, marginBottom: 5 }}>
                  REF. <b>{`${user?.pin || '-'}`}</b>
                </p>
                <Button color="primary" size="sm" style={{ width: '100%', height: 38 }} onClick={toggleModalQr}>
                  MY CODE
                </Button>
              </div>
              {!isAdminUser && (
                <>
                  <FormGroup style={{ marginBottom: '15px' }}>
                    <Input
                      type="text"
                      className="form-control text-center"
                      value={franchiseeSelected?.label || ''}
                      disabled={true}
                      style={{ height: '39px' }}
                    />
                  </FormGroup>
                  {schoolSelected && (
                    <FormGroup className="mb-0">
                      <Input
                        type="text"
                        className="form-control text-center"
                        value={schoolSelected?.label || ''}
                        disabled={true}
                        style={{ height: '39px' }}
                      />
                    </FormGroup>
                  )}
                </>
              )}
              {isAdminUser && (
                <>
                  <p style={{ marginBottom: 5 }}>
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      loadOptions={loadFranchisee}
                      styles={asyncSelectStyles}
                      placeholder={selectPlaceholderFranchisee}
                      value={franchiseeSelected}
                      onChange={changeCurrentFranchisee}
                      isDisabled={!isAdminUser}
                    />
                  </p>
                </>
              )}
              {schools.length && (
                <p className="pb-0 mb-0">
                  <AsyncSelect
                    key={currentFranchisee.value}
                    defaultOptions
                    loadOptions={loadSchools}
                    styles={asyncSelectStyles}
                    placeholder={selectPlaceholder}
                    value={schoolSelected}
                    onChange={changeCurrentSchool}
                    isDisabled={isParentUser || isClassroomUser || isTeacherUser}
                  />
                </p>
              )}
            </CardHeader>
          </Card>
        </Col>
      </Row>
      <QrCodeModal currentUser={user} modalQr={modalQr} toggleModalQr={toggleModalQr} />
    </>
  );
};

export default BaseHomeMobile;
