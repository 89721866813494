/* eslint-disable max-lines */
import { toast } from 'react-toastify';
import { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Context as AuthContext } from '../../../../contexts/AuthContext';
import WriteUpService from '../../../../services/WriteUp';
import UserService from '../../../../services/User';
import moment from 'moment';
import { Row, Col, FormGroup, InputGroup, Card, CardBody, Table } from 'reactstrap';
import ReactDatetime from 'react-datetime';
import WriteUpPDF from './WriteUpPDF';
import { SeeWriteUpModal } from './SeeWriteUpModal';
import HireDocuments from '../../../../services/HireDocuments';
import DocumentModal from './DocumentModal';
import { typeAndTitle } from '../../../../helpers/pdfByType';
import confirmAlert from '../../../../helpers/confirmAlert';
import NewHireDocumentsView from './NewHireDocumentsView';

interface FilesTabProps {
  reload: boolean;
}

const FilesTab = ({ reload }: FilesTabProps) => {
  const { id: userId } = useParams<{ id: string }>();
  const { token }: any = useContext(AuthContext);
  const [currentUser, setCurrentUser] = useState<any>({});
  const [writeUpData, setWriteUpData] = useState<any[]>();
  const [showDocument, setShowDocument] = useState<boolean>(false);
  const [documentsData, setDocumentsData] = useState<any[]>();
  const [currentDocument, setCurrentDocument] = useState<any[]>();
  const [initialDate, setInitialDate] = useState(moment().isoWeekday(0).format('MM/DD/yyyy'));
  const [endDate, setEndDate] = useState(moment().isoWeekday(0).add(6, 'days').format('MM/DD/yyyy'));

  useEffect(() => {
    getUserById();
  }, [userId]);

  useEffect(() => {
    getByUserId();
    getDocuments();
  }, [initialDate, endDate, reload]);

  const getUserById = async () => {
    try {
      const result = await UserService(token).show(userId);
      setCurrentUser(result?.data || {});
    } catch (e: any) {
      console.error(e?.response?.data?.message || 'Invalid user ID');
    }
  };

  const getByUserId = async () => {
    try {
      const query = `startDateTime=${initialDate}&finalDateTime=${endDate}`;
      const result = await WriteUpService(token).listByUser(userId, query);
      setWriteUpData(result?.data);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'Invalid user ID');
    }
  };

  const handleDocument = (d: any) => {
    setCurrentDocument(d);
    setShowDocument(true);
  };

  const getDocuments = async () => {
    try {
      const result = await HireDocuments('').show(userId);
      const documents: any[] = result?.data || [];
      setDocumentsData(documents);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'Invalid user ID');
    }
  };

  const removeAlert = async (e: any, id: string) => {
    e.preventDefault();
    return confirmAlert({
      title: 'Delete',
      messageType: 'DELETE',
      message: 'Are you sure to delete this document?',
      onClickYes: () => deleteDocument(id),
    });
  };

  const deleteDocument = async (id: string) => {
    try {
      await HireDocuments('').delete(id);
      toast.success('Document deleted successfully!');
      getDocuments();
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'Invalid ID');
    }
  };

  return (
    <>
      <Card className="shadow border-0">
        <CardBody className="pb-4">
          <Row>
            <Col lg="12">
              <div className="d-flex justify-content-between align-items-center py-2">
                <h3 className="text-uppercase text-primary">Write Up</h3>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="4">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-validAt">
                  Initial Date
                </label>
                <InputGroup className="input-group-alternative">
                  <ReactDatetime
                    inputProps={{
                      placeholder: 'Start Date',
                    }}
                    onChange={(newValue) => {
                      setInitialDate(moment(newValue).format('MM/DD/yyyy'));
                    }}
                    value={initialDate}
                    timeFormat={false}
                    dateFormat={'MM/DD/yyyy'}
                    closeOnSelect={true}
                  />
                </InputGroup>
              </FormGroup>
            </Col>

            <Col lg="4">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-validAt">
                  End Date
                </label>
                <InputGroup className="input-group-alternative">
                  <ReactDatetime
                    inputProps={{
                      placeholder: 'End Date',
                    }}
                    onChange={(newValue) => {
                      setEndDate(moment(newValue).format('MM/DD/yyyy'));
                    }}
                    value={endDate}
                    timeFormat={false}
                    dateFormat={'MM/DD/yyyy'}
                    closeOnSelect={true}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Table responsive>
              <thead>
                <tr>
                  <th>Action Taken</th>
                  <th>Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {writeUpData?.map((value) => (
                  <tr key={value.id}>
                    <td>{value.actionTaken}</td>
                    <td>{`${moment(value.incidentDate).format('MM/DD/YY')}`}</td>
                    <td>
                      <WriteUpPDF data={[value]} />
                      <SeeWriteUpModal data={value} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Row>
        </CardBody>
      </Card>
      <NewHireDocumentsView
        currentUser={currentUser}
        token={token}
        typeAndTitle={typeAndTitle}
        documentsData={documentsData}
        removeAlert={removeAlert}
        handleDocument={handleDocument}
      />
      <DocumentModal data={currentDocument} isOpen={showDocument} toggleModal={() => setShowDocument(false)} />
    </>
  );
};

export default FilesTab;
