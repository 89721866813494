import http from '../helpers/http';

const HireAgreements = (token: string) => {
  const headers = {
    'x-access-token': token,
  };

  return {
    list: async () => {
      const result = await http.get(`/hire-agreements`, {
        headers,
      });
      return result.data;
    },
    create: async (body: { name: string }) => {
      const result = await http.post(`/hire-agreements`, body, { headers });
      return result.data;
    },
    delete: async (id: string) => {
      const result = await http.delete(`/hire-agreements/${id}`, {
        headers,
      });
      return result.data;
    },
    file: async (hireAgreementId: string, body: FormData) => {
      const headers = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-access-token': token,
        },
      };
      const result = await http.patch(`/hire-agreements/${hireAgreementId}/file`, body, headers);
      return result.data;
    },
  };
};

export default HireAgreements;
