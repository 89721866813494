import {
  Container,
  Button,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Modal,
} from 'reactstrap';
import ReactDatetime from 'react-datetime';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import { useContext, useEffect, useState } from 'react';
import { EmplooyeTable, EmployeeProps } from './EmplooyeTable';
import ReportsService from '../../../services/Reports';
import * as XLSX from 'xlsx';
import moment from 'moment';

interface timecardModalProps {
  isOpen: boolean;
  toggleModal: () => void;
}

export function TimecardModal({ isOpen, toggleModal }: timecardModalProps) {
  const [weekDay, setWeekDay] = useState(moment().format('MM/DD/yyyy'));
  const [fileFormat, setFileFormat] = useState('XLSX');
  const [selected, setSelected] = useState([] as any[]);
  const [employees, setEmployees] = useState<EmployeeProps>({ employeeIncative: [], employeeActive: [] });

  const { token, currentSchool }: any = useContext(AuthContext);

  const generateEmployee = async () => {
    const idSchool = currentSchool.value;
    const { data } = await ReportsService(token).employeeList(idSchool);
    setEmployees(data);
  };

  useEffect(() => {
    generateEmployee();
  }, []);

  const handleExportTimecard = async () => {
    if (fileFormat === 'XLSX') {
      const users = selected.map((user) => user.id);
      const idSchool = currentSchool.value;
      const { data } = await ReportsService(token).timecard({
        weekDay: moment(weekDay).format(),
        selectedUsers: users.toString(),
        idSchool,
      });

      const ws = XLSX.utils.json_to_sheet(data.timecardTotal);
      const ws2 = XLSX.utils.json_to_sheet(data.timecardDetailed);

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Timecard Total');
      XLSX.utils.book_append_sheet(wb, ws2, 'Timecard Detailed');

      XLSX.writeFile(wb, 'Timecard.xlsx');
    }
  };

  return (
    <Modal className="modal-dialog-centered" size="xl" isOpen={isOpen} toggle={() => toggleModal()}>
      <Container fluid style={{ minWidth: '500px' }}>
        <Card>
          <CardBody>
            <Form onSubmit={handleExportTimecard}>
              <div className="pl-lg-0">
                <Row>
                  <Col lg="12">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-week">
                        Choose the week
                      </label>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-calendar-grid-58" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <ReactDatetime
                          inputProps={{
                            placeholder: 'week day',
                          }}
                          onChange={(newValue) => {
                            setWeekDay(`${moment(newValue).format('MM/DD/yyyy')}`);
                          }}
                          timeFormat={false}
                          value={`${weekDay}`}
                          dateFormat={'MM/DD/yyyy'}
                          closeOnSelect={true}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>

                <EmplooyeTable employee={employees} setSelected={setSelected} />

                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-gender">
                        File type
                      </label>
                      <Input type="select" onChange={(e) => setFileFormat(e.target.value)} value={fileFormat}>
                        <option value="XLSX" key={`XLSX`} selected>
                          XLSX
                        </option>
                        <option value="CSV" key={`CSV`} disabled>
                          CSV
                        </option>
                        <option value="PDF" key={`PDF`} disabled>
                          PDF
                        </option>
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12" className="d-flex justify-content-center">
                    <Button
                      color="primary"
                      type="submit"
                      href="#"
                      onClick={() => {
                        handleExportTimecard();
                        toggleModal();
                      }}
                      size="lg"
                      style={{ minWidth: '200px' }}
                    >
                      Download
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Container>
    </Modal>
  );
}
