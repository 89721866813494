import { Button, Card, CardBody, FormGroup, Form, Input, Row, Col, Label } from 'reactstrap';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Employee from '../../../services/Employee';
import { useParams } from 'react-router-dom';
import LoaderSpinner from '../../../components/Core/LoaderSpinner';

const NewHireAccept = () => {
  const { token } = useParams<{ token: string }>();
  const [isRequested, setIsRequested] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [isValidToken, setIsValidToken] = useState(true);
  const [isSign, setIsSign] = useState(false);
  const [signature, setSignature] = useState('');

  useEffect(() => {
    validationToken();
  }, []);

  const validationToken = async () => {
    setIsLoading(true);
    try {
      await Employee('').newHireValidationToken(token);
      setIsValidToken(true);
    } catch {
      setIsValidToken(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!isSign || !signature) {
      toast.error('Your signature is required!');
      return;
    }

    try {
      await Employee('').acceptNewHire(token);
      setIsRequested(true);
    } catch (e: any) {
      console.log(e);
      toast.error(e?.response?.data?.message || 'Request error');
    }
  };

  if (isLoading) {
    return (
      <Col lg="10" md="10">
        <Card className="bg-secondary shadow border-0 py-6">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="d-flex align-Items-center justify-content-center">
              <LoaderSpinner />
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  }

  if (!isValidToken) {
    return (
      <Col lg="10" md="10">
        <Card className="bg-secondary shadow border-0 py-6">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="d-flex align-Items-center justify-content-center">
              <i className="fas fa-ban" style={{ fontSize: 72, color: '#df0d0d' }} />
            </div>
            <h2 className="text-center mt-4">Token is invalid or expired!</h2>
          </CardBody>
        </Card>
      </Col>
    );
  }

  return (
    <>
      <Col lg="10" md="10">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            {isRequested ? (
              <>
                <div className="d-flex align-Items-center justify-content-center">
                  <i className="fas fa-check-circle" style={{ fontSize: 72, color: '#02ce0d' }} />
                </div>
                <h2 className="text-center mt-4"> Offer Letter Accept</h2>
              </>
            ) : (
              <>
                <div className="text-center text-muted mb-4">
                  <small>Accept Offer Letter</small>
                </div>
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col lg="12" className="mt-4">
                      <Label>
                        <strong>Signature:</strong>
                      </Label>
                      <div className="d-flex flex-wrap align-items-center">
                        <div className="form-check form-check-inline mr-3">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            id="to-sign"
                            checked={isSign}
                            onChange={() => {
                              if (isSign) {
                                setSignature('');
                              }
                              setIsSign(!isSign);
                            }}
                          />
                          <Label className="form-check-label mr-2" htmlFor="to-sign">
                            I hereby acknowledge and agree that my electronic signature on this document is the legal
                            equivalent of my handwritten signature.
                          </Label>
                        </div>
                      </div>
                    </Col>

                    {isSign && (
                      <Col lg="12" className="mt-4">
                        <FormGroup>
                          <Label htmlFor="name-for-signature">
                            <strong>Enter your name for signature:</strong>
                          </Label>
                          <Input
                            className="form-control"
                            type="text"
                            id="name-for-signature"
                            style={{ fontFamily: 'Dancing Script' }}
                            value={signature}
                            onChange={(e) => {
                              setSignature(e.target.value);
                            }}
                          />
                        </FormGroup>
                      </Col>
                    )}
                  </Row>

                  <div className="text-center">
                    <Button className="my-4" color="primary" type="submit">
                      Accept
                    </Button>
                  </div>
                </Form>
              </>
            )}
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default NewHireAccept;
