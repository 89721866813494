import { useContext, useState, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { Card, CardBody, CardHeader, Col, FormGroup, Input, Row, Spinner } from 'reactstrap';
import TransactionService from '../../../services/Transaction';
import { Context as AuthContext } from '../../../contexts/AuthContext';

const ACHTransaction = () => {
  const { token, user }: any = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [lastTransaction, setLastTransaction] = useState({} as any);
  const [amountAch, setAmountAch] = useState(0);
  const [email, setEmail] = useState('');

  useEffect(() => {
    setLoading(false);
  }, [lastTransaction]);

  const handlePaymentACH = async () => {
    setLoading(true);
    const transaction = await TransactionService(token).createDirect({
      userId: user?.id || '',
      transactionType: 'ACH',
      amount: amountAch,
      description: '',
      options: { email },
    });
    setLastTransaction(transaction);
  };

  return (
    <>
      <Row>
        <Col xl="12">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">Transaction Test</CardHeader>
            <CardBody>
              <Row>
                <Col xl="6">
                  <FormGroup>
                    <label className="form-control-label">Amount</label>
                    <Input
                      type="text"
                      thousandsGroupStyle="thousand"
                      prefix="$"
                      decimalSeparator="."
                      displayType="input"
                      thousandSeparator={true}
                      allowNegative={false}
                      decimalScale={2}
                      tag={NumberFormat}
                      value={amountAch}
                      onValueChange={(e: any) => setAmountAch(e.floatValue)}
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <label className="form-control-label">Email</label>
                    <Input
                      className="form-control-alternative"
                      placeholder="Email"
                      type="text"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col lg="12">
                  <button className="btn btn-primary" onClick={handlePaymentACH}>
                    Pay With ACH
                  </button>
                </Col>
                {!loading && lastTransaction ? (
                  <Col xl="12" className="mt-4">
                    <div>
                      <h3>RESULT:</h3>
                      <pre>{JSON.stringify(lastTransaction, null, 2)}</pre>
                    </div>
                  </Col>
                ) : (
                  <></>
                )}
                {loading && (
                  <Col xl="12" className="mt-4 text-center">
                    <span>
                      <Spinner />
                    </span>
                  </Col>
                )}
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ACHTransaction;
