import moment from 'moment';

const getWorkTime = (clockOuts: any[], clockIns: any[], overtime: boolean) => {
  let hours = 0;
  for (let i = 0; i <= clockOuts?.length; i++) {
    if (overtime && (clockOuts?.[i]?.payCode || clockIns?.[i]?.payCode)) continue;
    const outDate = clockOuts?.[i]?.createdAt ? moment(clockOuts[i].createdAt) : null;
    const inDate = clockIns?.[i]?.createdAt ? moment(clockIns[i].createdAt) : null;
    if (outDate && inDate) {
      const ms = outDate.diff(inDate);
      const d = moment.duration(ms);
      hours = hours + d.asHours();
    }
  }
  const i = parseInt(String(hours * 100));
  const r = isNaN(i) ? 0 : i / 100;
  return r || 0;
};

const getArrayFiltered = (array: any[], date: string) => {
  return array?.filter((a: any) => {
    const createdAt = new Date(a?.createdAt);
    const createdAtMonth = String(createdAt?.getMonth() + 1).padStart(2, '0');
    const createdAtDay = String(createdAt?.getDate()).padStart(2, '0');
    const createdAtString = `${createdAt?.getFullYear()}-${createdAtMonth}-${createdAtDay}`;
    return date === createdAtString;
  });
};

const getTimeCardInfo = (startDate: string, finalDate: string, userDataParam: any, dataParam?: any[]) => {
  const diff = moment(finalDate).diff(moment(startDate), 'days');
  let compareDate = moment(startDate);
  const data: any[] = [];
  const clockIns = dataParam ? dataParam.filter((d: any) => d.attendanceType === 'CLOCKIN') : [];
  const startBreaks = dataParam ? dataParam.filter((d: any) => d.attendanceType === 'STARTBREAK') : [];
  const clockOuts = dataParam ? dataParam.filter((d: any) => d.attendanceType === 'CLOCKOUT') : [];
  const endBreaks = dataParam ? dataParam.filter((d: any) => d.attendanceType === 'ENDBREAK') : [];
  const weekTime = userDataParam?.employee?.hoursPerWeek || 40;
  const standardTime = weekTime / 5;
  const standardDates = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'];
  let sumRealWork = 0;
  let sumRealWorkTotal = 0;
  let sumStandardTime = 0;
  let sumOvertime = 0;
  let sumDailyOvertime = 0;
  for (let i = 0; i <= diff; i++) {
    sumRealWork = compareDate.format('ddd') === 'Mon' ? 0 : sumRealWork;
    const row: any = {};
    const compareDateString = compareDate.format('YYYY-MM-DD');
    const isClockIn = [
      ...getArrayFiltered(clockIns, compareDateString),
      ...getArrayFiltered(endBreaks, compareDateString),
    ];
    const isClockOut = [
      ...getArrayFiltered(startBreaks, compareDateString),
      ...getArrayFiltered(clockOuts, compareDateString),
    ];
    const headerString = compareDate.format('MM/DD/YYYY ddd');
    const dateSelected = compareDate.format('YYYY-MM-DD');
    row.date = headerString;
    row.dateSelected = dateSelected;
    row.clockIn = isClockIn.map((c: any) => (c.createdAt ? moment(c.createdAt).utc().format('hh:mm A') : ''));
    row.clockOut = isClockOut.map((c: any) => (c.createdAt ? moment(c.createdAt).utc().format('hh:mm A') : ''));
    row.payCode = isClockIn.map((c: any) => c.payCode);
    row.standardTime = standardDates.includes(compareDate.format('ddd')) ? standardTime : 0;
    sumStandardTime = sumStandardTime + Number(row.standardTime);
    const realWorkOvertime = getWorkTime(isClockOut, isClockIn, true);
    sumRealWork = sumRealWork + realWorkOvertime;
    row.realWork = getWorkTime(isClockOut, isClockIn, false);
    const dailyOvertime = Number(row.realWork) - Number(row.standardTime);
    row.dailyOvertime = dailyOvertime.toFixed(2);
    sumDailyOvertime = sumDailyOvertime + Number(row.dailyOvertime);
    sumRealWorkTotal = sumRealWorkTotal + Number(row.realWork);
    const timeDiff = sumRealWork - weekTime;
    row.overtime = compareDate.format('ddd') === 'Fri' && timeDiff > 0 ? timeDiff.toFixed(2) : 0;
    sumOvertime = sumOvertime + Number(row.overtime);
    data.push(row);
    compareDate = compareDate.add(1, 'days');
  }

  return {
    data,
    sumRealWork,
    sumRealWorkTotal,
    sumStandardTime,
    sumOvertime,
    sumDailyOvertime,
  };
};

export default getTimeCardInfo;
