import { Container, Badge } from 'reactstrap';
import { useContext, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import CommonHeader from '../../../components/Headers/CommonHeader';
import KidsService from '../../../services/Kids';
import KidsInterface, { validStatus, statusEnum } from '../../../interfaces/KidsInterface';
import { toLocaleFormatSlash } from '../../../helpers/dateFormat';
import { FilterInterface, FilteredOptionsInterface } from '../../../entities/FilteredOptions';
import { PagedMetaInterface, createPagedMeta } from '../../../entities/PagedMeta';
import { OptionsPagedInterface, createOptionsPage } from '../../../entities/OptionsPaged';
import DataTable from '../../../components/Core/DataTable';
import isAdmin from '../../../helpers/isAdmin';
import moment from 'moment';
import confirmAlert from '../../../helpers/confirmAlert';
import isUpperManagment from '../../../helpers/isUpperManagment';

const KidsList = () => {
  const { token, currentSchool, user } = useContext(AuthContext);
  const hasDeleteOrCreatePermission = isAdmin(user) || isUpperManagment(user);

  const columns = [
    {
      name: 'Name',
      route: '/student-profile',
      grow: 2,
      label: (row: any) => (
        <div className="d-flex align-items-center">
          <div style={{ width: '35px', height: '35px', borderRadius: '50%', overflow: 'hidden' }}>
            <img
              src={row.picture}
              alt="User Profile"
              className="img-fluid"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </div>
          <span className="ml-2">{`${row.firstName || '-'} ${row.lastName || '-'}`}</span>
        </div>
      ),
      sortable: true,
      sortField: 'firstName',
    },
    {
      name: 'Status',
      selector: (row: any) => row?.status,
      cell: (row: any) => (
        <Badge
          color=""
          className="badge-dot badge-lg"
          style={{ fontSize: '0.8125rem', verticalAlign: 'middle', lineHeight: 'normal' }}
        >
          <i
            className={`bg-${validStatus[row?.status as statusEnum].color} mr-2`}
            style={{ verticalAlign: 'baseline' }}
          />
          {validStatus[row.status as statusEnum].value}
        </Badge>
      ),
      grow: 1,
      sortable: true,
      sortField: 'status',
    },
    {
      name: 'Classroom',
      selector: (row: any) => row?.enrollment?.class?.name || '',
      cell: (row: any) => row?.enrollment?.class?.name || '',
      grow: 1,
      sortable: true,
      sortField: 'enrollment.class.name',
    },
    {
      name: 'Age',
      cell: (row: any) => {
        const [year, ,] = row?.birthday?.split('-');
        return parseInt(moment().format('Y')) - parseInt(year);
      },
      grow: 1,
      sortable: true,
      sortField: 'birthday',
    },
    {
      name: 'Birthday',
      cell: (row: any) => toLocaleFormatSlash(row?.birthday || ''),
      grow: 1,
      sortable: true,
      sortField: 'birthday',
    },
    {
      name: 'Start Date',
      cell: (row: any) =>
        row?.enrollment?.enrollmentStartDate ? toLocaleFormatSlash(row?.enrollment?.enrollmentStartDate) : '-',
      grow: 1,
      sortable: true,
      sortField: 'row.enrollment.enrollmentStartDate',
    },
    hasDeleteOrCreatePermission && {
      type: 'delete',
      onClick: (row: any) => {
        deleteItemAlert(row?.id, row?.firstName);
      },
    },
  ];

  const [data, setData] = useState([] as any[]);
  const [loading, setLoading] = useState(false);
  const [pagedMeta, setPagedMeta] = useState(createPagedMeta());
  const [filteredOptions, setFilteredOptions] = useState({
    filters: [],
    options: createOptionsPage(pagedMeta),
  } as { filters: FilterInterface[]; options: OptionsPagedInterface });

  useEffect(() => {
    loadList(filteredOptions);
  }, [filteredOptions, currentSchool]);

  const loadList = async (filteredOptions: FilteredOptionsInterface) => {
    try {
      setLoading(true);

      const result = await KidsService(token || '').list(filteredOptions, currentSchool.value);
      const data: KidsInterface[] = result.data;
      setData(data);
      const meta: PagedMetaInterface = result.meta;
      if (meta) {
        setPagedMeta(meta);
      }
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Error fetching list');
      return console.error(message);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = async (text: string) => {
    const filterFirstName = {
      field: 'firstName',
      operation: 'ilike',
      value: text || '',
    };
    const newFilteredOptions = {
      filters: [filterFirstName],
      options: filteredOptions.options,
    };
    newFilteredOptions.options.page = 1;
    setFilteredOptions(newFilteredOptions);
  };

  const handleSort = async (column: string, direction: string) => {
    const options = { ...filteredOptions.options, sortBy: [column], sortDesc: [direction !== 'asc'] };
    const newFilteredOptions = {
      filters: filteredOptions.filters,
      options,
    };
    newFilteredOptions.options.page = 1;
    setFilteredOptions(newFilteredOptions);
  };

  const deleteItemAlert = async (id: string, label: string) => {
    return confirmAlert({
      title: 'Delete',
      messageType: 'DELETE',
      id,
      onClickYes: deleteItem,
      label,
    });
  };

  const deleteItem = async (id: string) => {
    try {
      setLoading(true);
      await KidsService(token || '').delete(id);
      toast.success(`Deleted successfully!`);
      loadList(filteredOptions);
    } catch (e: any) {
      const message = String(e?.response?.data?.message || `Error deleting item: ${id}`);
      return toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  const handlePerRowsChange = async (perPage: number) => {
    const newOptions = {
      ...pagedMeta,
      perPage,
    };
    const newFilteredOptions = {
      filters: filteredOptions.filters,
      options: createOptionsPage(newOptions),
    };
    setFilteredOptions(newFilteredOptions);
  };

  const handlePageChange = async (page: number) => {
    const newOptions = {
      ...pagedMeta,
      currentPage: page,
    };
    const newFilteredOptions = {
      filters: filteredOptions.filters,
      options: createOptionsPage(newOptions),
    };
    setFilteredOptions(newFilteredOptions);
  };

  return (
    <>
      <CommonHeader />
      <Container className="mt--8" fluid>
        <DataTable
          title="Students List"
          columns={columns}
          data={data}
          totalRows={pagedMeta.total}
          loading={loading}
          button={hasDeleteOrCreatePermission}
          buttonUrl="/kids/new"
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          search={true}
          onSearch={handleSearch}
          onChangeSort={(column: string, direction: string) => handleSort(column, direction)}
        />
      </Container>
    </>
  );
};

export default KidsList;
