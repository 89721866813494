import http from '../helpers/http';

const KidsReminders = (token: string) => {
  const headers = {
    'x-access-token': token,
  };

  return {
    list: async (schoolId: string) => {
      const result = await http.get(`/kids-reminders/all/${schoolId}`, {
        headers,
      });
      return result.data;
    },
    listByKids: async (kidsId: string) => {
      const result = await http.get(`/kids-reminders/${kidsId}`, {
        headers,
      });
      return result.data;
    },
    create: async (body: { kidsId: string; schoolId: string; date: string; notes: string }) => {
      const result = await http.post(`/kids-reminders`, body, { headers });
      return result.data;
    },
    delete: async (id: string) => {
      const result = await http.delete(`/kids-reminders/${id}`, {
        headers,
      });
      return result.data;
    },
    read: async (id: string) => {
      const result = await http.patch(
        `/kids-reminders/${id}/read`,
        {},
        {
          headers,
        },
      );
      return result.data;
    },
  };
};

export default KidsReminders;
