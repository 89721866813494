import { useEffect } from 'react';

import ClassKidsList from '../../views/pages/Class/ClassKidsList';

import { Row, Col } from 'reactstrap';
import { toast } from 'react-toastify';
import KidsCurrentClassCameras from './KidsCurrentClassCameras';

const KidsViewCamera = ({
  kidsList,
  setSelectedKidObject,
  selectedKid,
  setSelectedKid,
}: {
  kidsList: [];
  currentUser?: any;
  setSelectedKidObject?: any;
  selectedKid?: string;
  setSelectedKid?: any;
}) => {
  useEffect(() => {
    setSelectedKidObject('');
    if (!selectedKid) {
      return;
    }
    const selectedKidObject: any = kidsList.find((item: any) => item.id === selectedKid);
    if (!selectedKidObject.isCheckedIn) {
      toast.warning('Child not checked in');
      setSelectedKid('');
      return;
    }
    const kidListSelected: any = kidsList.find((item: any) => item.id === selectedKid);
    setSelectedKidObject(kidListSelected);
  }, [selectedKid]);

  return (
    <>
      {selectedKid === '' && (
        <>
          <ClassKidsList
            paddingTop="pt-0"
            title=""
            kids={kidsList}
            kidsSelected={[selectedKid]}
            selectChild={(e: any, id: string) => {
              e.preventDefault();
              setSelectedKid(id);
            }}
            openModal={() => {}}
            showActionButtons={false}
            showTitle={false}
          />
        </>
      )}
      {selectedKid !== '' && (
        <>
          <KidsCurrentClassCameras kidId={selectedKid || ''} />
          <Row className="mt-3">
            <Col>
              <button
                className="btn btn-primary btn-lg w-100"
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedKid('');
                }}
              >
                Back
              </button>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default KidsViewCamera;
