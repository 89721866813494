import { Modal } from 'reactstrap';
import ModalInterface from '../../interfaces/UtilsInterface';

const ModalContent = (props: ModalInterface) => {
  return (
    <Modal className="modal-dialog-centered" isOpen={props.modal} toggle={props.toggle} size={props?.size || 'md'}>
      <div className="modal-header">
        {props.title && (
          <h4 className="modal-title" id={props.name}>
            {props.title}
          </h4>
        )}
        <div onClick={props.toggle} className="d-flex p-1 cursor-pointer">
          <i className="fas fa-times" />
        </div>
      </div>
      <div className={`modal-body ${props.class}`}>{props.children}</div>
    </Modal>
  );
};

export default ModalContent;
