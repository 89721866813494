import http from '../helpers/http';
//import { FilteredOptionsInterface, makeQueryParamsForRequest } from '../entities/FilteredOptions';
import ImmunizationKidsInterface from '../interfaces/ImmunizationKidsInterface';

const ImmunizationKids = (token: string) => {
  const headers = {
    'x-access-token': token,
  };

  return {
    listAllKids: async () => {
      const result = await http
        .get('/immunizations', {
          headers,
        })
        .then((res) => res.data);
      return result;
    },
    list: async (kidsId: string) => {
      //const params = makeQueryParamsForRequest(filteredOptions);
      const result = await http.get(`/immunizations/${kidsId}/kids`, {
        headers,
        //params,
      });
      return result.data;
    },
    listSelectImmunization: async () => {
      const result = await http.get(`/immunizations/select/all`, {
        headers,
      });
      return result.data;
    },
    show: async (id: string) => {
      const result = await http.get(`/immunizations/${id}`, {
        headers,
      });
      return result.data;
    },
    create: async (body: Partial<ImmunizationKidsInterface>) => {
      const result = await http.post(`/immunizations`, body, { headers });
      return result.data;
    },
    update: async (id: string, body: Partial<ImmunizationKidsInterface>) => {
      const result = await http.put(`/immunizations/${id}`, body, { headers });
      return result.data;
    },
    delete: async (id: string) => {
      const result = await http.delete(`/immunizations/${id}`, {
        headers,
      });
      return result.data;
    },
    picture: async (immunizationKidsId: string, body: FormData) => {
      const headers = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-access-token': token,
        },
      };
      const result = await http.patch(`/immunizations/${immunizationKidsId}/picture`, body, headers);
      return result.data;
    },
  };
};

export default ImmunizationKids;
