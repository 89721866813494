import { Button, FormGroup, Form, Input, Row, Col, Modal } from 'reactstrap';
import { toast } from 'react-toastify';
import { useContext, useState, useEffect } from 'react';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import InputMask from 'react-input-mask';
import UserService from '../../../services/User';

type ModalProps = {
  isOpen: boolean;
  id?: string;
  toggleModal: (e?: any) => void;
};

const UserCreateContactModal = ({ isOpen, id, toggleModal }: ModalProps) => {
  const { token }: any = useContext(AuthContext);
  const pageTitle = id ? 'Update' : 'Create';
  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [workPhone, setWorkPhone] = useState('');

  const getById = async () => {
    setIsDisabled(true);
    try {
      const result = await UserService(token).show(id || '');
      if (result?.data) {
        setFirstName(result?.data?.firstName || '');
        setLastName(result?.data?.lastName || '');
        setEmail(result?.data?.email || '');
        setPhone(result?.data?.phone || '');
        setWorkPhone(result?.data?.workPhone || '');
      }
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'Invalid user ID');
    } finally {
      setIsDisabled(false);
    }
  };

  useEffect(() => {
    if (id) {
      getById();
    }
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);
    setIsDisabled(true);

    const body: any = {
      firstName,
      lastName,
      email,
      phone,
      workPhone,
    };

    try {
      const r = id ? await UserService(token).updateContact(id, body) : await UserService(token).createContact(body);
      toast.success(`${pageTitle} successfully!`);
      toggleModal({ user: r?.data || {} });
    } catch (e: any) {
      const message = String(e?.response?.data?.message || `${pageTitle} error!`);
      toast.error(message);
    } finally {
      setIsDisabled(false);
    }

    setLoading(false);
  };

  return (
    <>
      <Modal className="modal-dialog-centered" isOpen={isOpen} toggle={(e: any) => toggleModal(e)}>
        <div className="modal-header pb-1">
          <h5 className="modal-title">User Contact</h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={(e) => toggleModal(e)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body pt-0">
          <Row>
            <Col>
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col lg="12">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-first-name">
                        First name
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-first-name"
                        placeholder="First name"
                        type="text"
                        disabled={isDisabled}
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-last-name">
                        Last name
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-last-name"
                        placeholder="Last name"
                        type="text"
                        disabled={isDisabled}
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-email">
                        Email address
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-email"
                        placeholder="Email address"
                        type="email"
                        value={email}
                        disabled={isDisabled}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label">Phone</label>
                      <Input
                        className="form-control-alternative"
                        placeholder="Phone"
                        type="text"
                        mask="(999) 999-9999"
                        tag={InputMask}
                        disabled={isDisabled}
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label">Work Phone</label>
                      <Input
                        className="form-control-alternative"
                        placeholder="Work Phone"
                        type="text"
                        mask="(999) 999-9999"
                        tag={InputMask}
                        disabled={isDisabled}
                        value={workPhone}
                        onChange={(e) => setWorkPhone(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Button className="my-4" color="primary" type="submit" disabled={isDisabled}>
                  Save
                </Button>
              </Form>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default UserCreateContactModal;
