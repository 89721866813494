import http from '../helpers/http';

const PriceSheet = (token: string) => {
  const headers = {
    'x-access-token': token,
  };

  return {
    list: async (classId: string) => {
      const result = await http.get(`/class/${classId}/price-sheet`, {
        headers,
      });
      return result.data;
    },
    create: async (body: { classTypeId: string; paymentFrequency: string; tuition: number }, classId: string) => {
      body.tuition = Number(body.tuition);
      const result = await http.post(`/class/${classId}/price-sheet`, body, { headers });
      return result.data;
    },
    update: async (
      body: { classTypeId: string; paymentFrequency: string; tuition: number },
      classId: string,
      id: string,
    ) => {
      body.tuition = Number(body.tuition);
      const result = await http.put(`/class/${classId}/price-sheet/${id}`, body, {
        headers,
      });
      return result.data;
    },
    delete: async (classId: string, id: string) => {
      const result = await http.delete(`/class/${classId}/price-sheet/${id}`, {
        headers,
      });
      return result.data;
    },
    listByClassType: async (classTypeId: string) => {
      const result = await http.get(`/class-type/${classTypeId}/price-sheet`, {
        headers,
      });
      return result.data;
    },
  };
};

export default PriceSheet;
