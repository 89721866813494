import http from '../helpers/http';

const WaitingList = (token: string) => {
  const headers = {
    'x-access-token': token,
  };

  return {
    list: async (schoolId: string, orderByWaitingList: string) => {
      const result = await http.get(`/waiting-list/${schoolId}/bySchool/${orderByWaitingList}`, {
        headers,
      });
      return result.data;
    },
    sendEmail: async (id: string) => {
      const result = await http.patch(`/waiting-list/${id}/email`, {}, { headers });
      return result.data;
    },
  };
};

export default WaitingList;
