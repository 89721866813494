/* eslint-disable max-lines */
import { useState, useEffect, useContext } from 'react';
import { Context as AuthContext } from '../../contexts/AuthContext';
import OpacityLoaderSpinner from '../Core/OpacityLoaderSpinner';
import ClassManage from '../../views/pages/Class/ClassManage';
import ClassUserService from '../../services/ClassUser';
import { toast } from 'react-toastify';

const ClassroomDashboard = ({ currentUser }: { currentUser: any }) => {
  const [loading, setLoading] = useState(false);

  const { token }: any = useContext(AuthContext);

  const [mainClass, setMainClass] = useState('');

  const getMainClass = async () => {
    setLoading(true);
    try {
      const result = await ClassUserService(token).show(currentUser.id);
      if (result?.data) {
        setMainClass(
          result?.data.filter((item: any) => item.isMain === true).map((item: any) => item.classId)[0] || '',
        );
      }
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'Invalid user ID');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMainClass();
  }, []);

  return (
    <>
      {!loading && <ClassManage classId={mainClass} withBackButton={false} />}
      {loading && <OpacityLoaderSpinner />}
    </>
  );
};

export default ClassroomDashboard;
