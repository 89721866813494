import { useContext, useState, useEffect } from 'react';
import { Context as AuthContext } from '../../contexts/AuthContext';
import { VerticalTimeline } from 'react-vertical-timeline-component';
import TimeLineItem from '../../components/Timeline/TimelineItem';
import Gallery from '../../components/Gallery/ImageViewer';
import TimelineService from '../../services/Timeline';
import { toast } from 'react-toastify';
import activityIcons from '../../views/pages/Timeline/timelineActivityIcons';

const KidsTimeline = ({ kidsId, currentUser }: { kidsId: string; currentUser: any }) => {
  const { token }: any = useContext(AuthContext);
  const [data, setData] = useState<any[]>([]);

  const loadData = async () => {
    if (kidsId === '') {
      return;
    }
    try {
      const result = await TimelineService(token).show(kidsId);
      if (result?.data) {
        const dataResult: any = result.data;
        setData(dataResult);
      }
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'Invalid Kids ID');
    }
  };

  useEffect(() => {
    loadData();
  }, [kidsId]);

  return (
    <VerticalTimeline layout="1-column-left" lineColor="#007BFF" animate={false}>
      {data.map((a, index) => {
        const findItem = activityIcons.find((item) => a.kidsActivity.tags.includes(item.key));
        const icon = findItem?.icon || 'fa-ellipsis';
        const status = findItem?.status || 'bg-info';
        a.canDownloadAttachs =
          currentUser.configs?.canDownloadPhotos === undefined ? true : currentUser.configs?.canDownloadPhotos;
        return (
          <TimeLineItem
            options={a.options}
            icon={icon}
            status={status}
            name={a.kidsActivity.name}
            description={(a as any).notes}
            key={`item-${index}`}
            canDownloadAttachs={
              currentUser.configs?.canDownloadPhotos === undefined ? true : currentUser.configs?.canDownloadPhotos
            }
            attachments={a.attachments}
            content={
              a.attachments.length > 0 && (
                <div className="d-flex flex-column">
                  <p style={{ fontSize: '10px' }} className="h6 mt-4 text-muted font-weight-600 text-uppercase mb-3">
                    Attachments
                  </p>
                  <Gallery galleries={a.attachments} />
                </div>
              )
            }
          />
        );
      })}
    </VerticalTimeline>
  );
};

export default KidsTimeline;
