/* eslint-disable max-lines */
import { rgb } from 'pdf-lib';

export const pdfByType = [
  {
    type: 'NON_DISCLOSURE',
    title: 'Non Disclosure Agreement',
    url: 'https://arb-systems-s3-public.s3.us-east-2.amazonaws.com/public/Employment-Eligibility/AEA_DEV_LLC_NDA_2021_MS_UPDATED.pdf',
    signPage: 3,
    coordinates: {
      x: 330,
      y: 310,
      height: 40,
      opacity: 1,
    },
    signDatePage: 0,
    coordinatesDate: {
      x: 350,
      y: 648,
      size: 12,
    },
    coordinatesNames: [
      {
        page: 0,
        coordinates: {
          x: 430,
          y: 636,
          size: 12,
        },
      },
      {
        page: 2,
        coordinates: {
          x: 360,
          y: 306,
          size: 12,
        },
      },
      {
        page: 3,
        coordinates: {
          x: 380,
          y: 285,
          size: 12,
        },
      },
    ],
  },
  {
    type: 'WORK_FOR_HIRE',
    title: 'Work for Hire Agreement',
    url: 'https://arb-systems-s3-public.s3.us-east-2.amazonaws.com/public/Employment-Eligibility/AEA_DEV_LLC_Work_for_Hire_Agreement+Updated.pdf',
    signPage: 5,
    coordinates: {
      x: 95,
      y: 450,
      height: 40,
      opacity: 1,
    },
    signNamePage: 0,
    coordinatesName: {
      x: 145,
      y: 628,
      size: 12,
    },
    signDatePage: 0,
    coordinatesDate: {
      x: 115,
      y: 616,
      size: 12,
    },
  },
  {
    type: 'SECURITY_CAMERA',
    title: 'Security Camera Notification',
    url: 'https://arb-systems-s3-public.s3.us-east-2.amazonaws.com/public/Employment-Eligibility/Security+Camera+Notification.pdf',
    signPage: 0,
    coordinates: {
      x: 100,
      y: 250,
      height: 40,
      opacity: 1,
    },
    coordinatesName: {
      x: 90,
      y: 250,
      size: 12,
    },
    coordinatesDate: {
      x: 90,
      y: 223,
      size: 12,
    },
  },
  {
    type: 'UNIFORM_AND_MATERIALS',
    title: 'Employee Uniform and Materials Agreement',
    url: 'https://arb-systems-s3-public.s3.us-east-2.amazonaws.com/public/Employment-Eligibility/Employee+Uniform+%26+Materials+Agreement.pdf',
    signPage: 0,
    coordinates: {
      x: 100,
      y: 100,
      height: 40,
      opacity: 1,
    },
    coordinatesName: {
      x: 140,
      y: 548,
      size: 12,
    },
  },
  {
    type: 'DAILY_HEALTH_CHECK',
    title: 'Daily Health Check Acknowledgement',
    url: 'https://arb-systems-s3-public.s3.us-east-2.amazonaws.com/public/Employment-Eligibility/Daily+Health+Check+Acknowledgement.pdf',
    signPage: 0,
    coordinates: {
      x: 100,
      y: 100,
      height: 40,
      opacity: 1,
    },
    coordinatesRectangle: {
      x: 90,
      y: 145,
      width: 100,
      height: 20,
      color: rgb(1, 1, 1),
    },
    coordinatesName: {
      x: 90,
      y: 147,
      size: 12,
      backgroundColor: rgb(0, 0, 0),
    },
  },
  {
    type: 'SOCIAL_COVENANT',
    title: 'Social Covenant Agreement',
    url: 'https://arb-systems-s3-public.s3.us-east-2.amazonaws.com/public/Employment-Eligibility/Social+Covenant+Agreement.pdf',
    signPage: 1,
    coordinates: {
      x: 165,
      y: 130,
      height: 40,
      opacity: 1,
    },
    coordinatesRectangle: {
      x: 90,
      y: 130,
      width: 100,
      height: 20,
      color: rgb(1, 1, 1),
    },
    coordinatesName: {
      x: 90,
      y: 135,
      size: 12,
      backgroundColor: rgb(0, 0, 0),
    },
  },
  {
    type: 'POSITIVE_GUIDANCE',
    title: 'Positive Guidance Policy',
    url: 'https://arb-systems-s3-public.s3.us-east-2.amazonaws.com/public/Employment-Eligibility/AEA_positive_guidance_policy_1.pdf',
    signPage: 2,
    coordinates: {
      x: 92,
      y: 525,
      height: 40,
      opacity: 1,
    },
    coordinatesName: {
      x: 92,
      y: 638,
      size: 12,
      backgroundColor: rgb(0, 0, 0),
    },
  },
  {
    type: 'CHILDREN_SPECIAL_ACCOMODATIONS',
    title: 'ECP for Children w/ Special Accommodations or Needs',
    url: 'https://arb-systems-s3-public.s3.us-east-2.amazonaws.com/public/Employment-Eligibility/Emergency_Care_Plan_for_Children_with_Special_Accommodations_or_Needs.pdf',
    signPage: 2,
    coordinates: {
      x: 60,
      y: 570,
      height: 40,
      opacity: 1,
    },
    coordinatesName: {
      x: 60,
      y: 667,
      size: 12,
      backgroundColor: rgb(0, 0, 0),
    },
  },
  {
    type: 'SHAKEN_BABY_SYNDROME',
    title: 'Shaken Baby Syndrome Agreement & Policy',
    url: 'https://arb-systems-s3-public.s3.us-east-2.amazonaws.com/public/Employment-Eligibility/Prevention_of_Shaken_Baby_Syndrome_and_Abusive_Head_Trauma_Policy__2_.pdf',
    signPage: 4,
    coordinates: {
      x: 60,
      y: 560,
      height: 40,
      opacity: 1,
    },
    coordinatesName: {
      x: 67,
      y: 678,
      size: 12,
      backgroundColor: rgb(0, 0, 0),
    },
  },
  {
    type: 'BLOODBORNE_PATHOGEN',
    title: 'Bloodborne Pathogen Training',
    url: 'https://arb-systems-s3-public.s3.us-east-2.amazonaws.com/public/Employment-Eligibility/Bloodborne+Pathogen+Training.pdf',
    signPage: 1,
    coordinates: {
      x: 160,
      y: 556,
      height: 40,
      opacity: 1,
    },
    coordinatesRectangle: {
      x: 90,
      y: 556,
      width: 100,
      height: 20,
      color: rgb(1, 1, 1),
    },
    coordinatesName: {
      x: 90,
      y: 556,
      size: 12,
      backgroundColor: rgb(0, 0, 0),
    },
  },
  {
    type: 'BATHROOM_SUPERVISION',
    title: 'AEA Bathroom Supervision Policy',
    url: 'https://arb-systems-s3-public.s3.us-east-2.amazonaws.com/public/Employment-Eligibility/AEA+Bathroom+Supervision+Policy.pdf',
    signPage: 0,
    coordinates: {
      x: 160,
      y: 139,
      height: 40,
      opacity: 1,
    },
    coordinatesRectangle: {
      x: 90,
      y: 139,
      width: 100,
      height: 20,
      color: rgb(1, 1, 1),
    },
    coordinatesName: {
      x: 90,
      y: 139,
      size: 12,
      backgroundColor: rgb(0, 0, 0),
    },
  },
  {
    type: 'BABYSITTING_POLICY',
    title: 'AEA Babysitting Policy Acknowledgement',
    url: 'https://arb-systems-s3-public.s3.us-east-2.amazonaws.com/public/Employment-Eligibility/AEA_Babysitting_Policy.pdf',
    signPage: 0,
    coordinates: {
      x: 160,
      y: 139,
      height: 40,
      opacity: 1,
    },
    coordinatesRectangle: {
      x: 90,
      y: 139,
      width: 100,
      height: 20,
      color: rgb(1, 1, 1),
    },
    coordinatesName: {
      x: 90,
      y: 139,
      size: 12,
      backgroundColor: rgb(0, 0, 0),
    },
  },
  {
    type: 'PLAYGROUND_POLICY',
    title: 'AEA Playground Safety Policy',
    url: 'https://arb-systems-s3-public.s3.us-east-2.amazonaws.com/public/Employment-Eligibility/AEA+Playground+Safety+Policy.pdf',
    signPage: 3,
    coordinates: {
      x: 180,
      y: 500,
      height: 40,
      opacity: 1,
    },
    coordinatesRectangle: {
      x: 90,
      y: 514,
      width: 100,
      height: 20,
      color: rgb(1, 1, 1),
    },
    coordinatesName: {
      x: 90,
      y: 514,
      size: 12,
      backgroundColor: rgb(0, 0, 0),
    },
  },
  {
    type: 'DIRECT_SUPERVISION',
    title: 'AEA Direct Supervision & Security',
    url: 'https://arb-systems-s3-public.s3.us-east-2.amazonaws.com/public/Employment-Eligibility/AEA+Direct+Supervision+and+Security.pdf',
    signPage: 2,
    coordinates: {
      x: 180,
      y: 185,
      height: 40,
      opacity: 1,
    },
    coordinatesRectangle: {
      x: 90,
      y: 210,
      width: 100,
      height: 20,
      color: rgb(1, 1, 1),
    },
    coordinatesName: {
      x: 90,
      y: 210,
      size: 12,
      backgroundColor: rgb(0, 0, 0),
    },
  },
];

export const typeAndTitle = [
  { type: 'NON_DISCLOSURE', title: 'Non Disclosure Agreement' },
  { type: 'WORK_FOR_HIRE', title: 'Work for Hire Agreement' },
  {
    type: 'SECURITY_CAMERA',
    title: 'Security Camera Notification',
  },
  { type: 'UNIFORM_AND_MATERIALS', title: 'Employee Uniform and Materials Agreement' },
  {
    type: 'DAILY_HEALTH_CHECK',
    title: 'Daily Health Check Acknowledgement',
  },
  {
    type: 'SOCIAL_COVENANT',
    title: 'Social Covenant Agreement',
  },
  {
    type: 'POSITIVE_GUIDANCE',
    title: 'Positive Guidance Policy',
  },
  {
    type: 'CHILDREN_SPECIAL_ACCOMODATIONS',
    title: 'ECP for Children w/ Special Accommodations or Needs',
  },
  {
    type: 'SHAKEN_BABY_SYNDROME',
    title: 'Shaken Baby Syndrome Agreement & Policy',
  },
  {
    type: 'BLOODBORNE_PATHOGEN',
    title: 'Bloodborne Pathogen Training',
  },
  {
    type: 'BATHROOM_SUPERVISION',
    title: 'AEA Bathroom Supervision Policy',
  },
  {
    type: 'BABYSITTING_POLICY',
    title: 'AEA Babysitting Policy Acknowledgement',
  },
  {
    type: 'PLAYGROUND_POLICY',
    title: 'AEA Playground Safety Policy',
  },
  {
    type: 'DIRECT_SUPERVISION',
    title: 'AEA Direct Supervision & Security',
  },
];
