import { useContext, useState, useEffect } from 'react';
import { Context as AuthContext } from '../../contexts/AuthContext';
import AsyncSelect from 'react-select/async';
import User from '../../services/User';
import Kids from '../../services/Kids';
import { createOptionsPage } from '../../entities/OptionsPaged';
import { createPagedMeta } from '../../entities/PagedMeta';
import UserInterface from '../../interfaces/UserInterface';

const UserSelect = ({
  userType,
  onChange = (_val: any) => {},
  filterId,
  isMulti = false,
}: {
  userType: string;
  onChange?: any;
  filterId?: string;
  isMulti?: boolean;
}) => {
  const { token }: any = useContext(AuthContext);
  // const [userId, setUserId] = useState('');
  const [userSelected, setUserSelected] = useState<any | undefined>(undefined);
  const [defaultOptions, setDefaultOptions] = useState<any>([]);

  const getResultByType = async (filteredOptions: any) => {
    const _filteredOptions = filteredOptions;
    switch (userType) {
      case 'kids':
        return await Kids(token).list(filteredOptions);
      case 'parents':
        // return User(token);
        _filteredOptions.filters = [
          ...[
            {
              field: 'kidsUsersRelations.kidsId',
              operation: 'hash',
              value: filterId,
            },
          ],
          ..._filteredOptions.filters.filter((i: any) => {
            i.field !== 'kidsUsersRelations.kidsId';
          }),
        ];
        return User(token).list(_filteredOptions);
      default:
        return User(token);
    }
  };

  const listSelectUser = async (search?: string) => {
    try {
      const filteredOptions = {
        filters: [] as any[],
        options: createOptionsPage(createPagedMeta()),
      };
      if (search) {
        const filterFirstName = {
          field: 'firstName',
          operation: 'ilike',
          value: `%${search}%`,
        };
        filteredOptions.filters = [filterFirstName];
      }
      const resultFirst = await getResultByType(filteredOptions);
      const dataFirst: UserInterface[] = resultFirst.data;
      const optionsFirst = dataFirst.map(({ id, firstName, lastName, picture }: UserInterface) => ({
        value: id,
        label: firstName && lastName ? `${firstName} ${lastName}` : '',
        name: firstName && lastName ? `${firstName} ${lastName}` : '',
        photo: picture,
      }));

      if (search) {
        const filterLastName = {
          field: 'lastName',
          operation: 'ilike',
          value: `%${search}%`,
        };
        filteredOptions.filters = [filterLastName];
      }
      const resultLast = await getResultByType(filteredOptions);
      const dataLast: UserInterface[] = resultLast.data;
      const optionsLast = dataLast.map(({ id, firstName, lastName, picture }: UserInterface) => ({
        value: id,
        label: firstName && lastName ? `${firstName} ${lastName}` : '',
        name: firstName && lastName ? `${firstName} ${lastName}` : '',
        photo: picture,
      }));
      const uniqueParents = [...optionsFirst, ...optionsLast].filter((value, index) => {
        const _value = JSON.stringify(value);
        return (
          index ===
          [...optionsFirst, ...optionsLast].findIndex((obj) => {
            return JSON.stringify(obj) === _value;
          })
        );
      });
      setDefaultOptions([...new Set(uniqueParents)]);
      return [...new Set(uniqueParents)];
    } catch (e: any) {
      console.error(e?.response?.data?.message || 'No users found');
      return [];
    }
  };

  const handleUserChange = (user: any) => {
    setUserSelected(user);
    // setUserId(user.value);
    onChange(user);
  };

  const renderResult = (option: any) => {
    const imgStyle = {
      borderRadius: '50%',
      verticalAlign: 'middle',
      marginRight: 10,
    };
    return (
      <div className="select-option">
        <button type="button" style={{ border: 0, width: '100%', background: 'transparent' }}>
          <div style={{ width: '100%', textAlign: 'left', display: 'inline-block' }}>
            <span>
              <img alt="" style={imgStyle} width="28" height="28" src={option.photo} />
              <span>{option.name}</span>
            </span>
          </div>
        </button>
      </div>
    );
  };

  useEffect(() => {
    listSelectUser();
  }, [filterId]);

  return (
    <AsyncSelect
      loadOptions={listSelectUser}
      defaultOptions={defaultOptions || true}
      onChange={handleUserChange}
      value={userSelected}
      formatOptionLabel={renderResult}
      isMulti={isMulti}
    />
  );
};
export default UserSelect;
