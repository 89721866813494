import { Button, Col, FormGroup, Row } from 'reactstrap';

interface AnniverssaryTShirtFormProps {
  model: string;
  quantityXs: string;
  quantityS: string;
  quantityM: string;
  quantityL: string;
  quantityXl: string;
  quantity2xl: string;
  quantity3xl: string;
  index: number;
  setModel: (value: string, index: number) => void;
  setQuantityXs: (value: string, index: number) => void;
  setQuantityS: (value: string, index: number) => void;
  setQuantityM: (value: string, index: number) => void;
  setQuantityL: (value: string, index: number) => void;
  setQuantityXl: (value: string, index: number) => void;
  setQuantity2xl: (value: string, index: number) => void;
  setQuantity3xl: (value: string, index: number) => void;
  deleteShirt: (index: number) => void;
}

export function AnniverssaryTShirtForm({
  model,
  quantityXs,
  quantityS,
  quantityM,
  quantityL,
  quantityXl,
  quantity2xl,
  quantity3xl,
  index,
  setModel,
  setQuantityXs,
  setQuantityS,
  setQuantityM,
  setQuantityL,
  setQuantityXl,
  setQuantity2xl,
  setQuantity3xl,
  deleteShirt,
}: AnniverssaryTShirtFormProps) {
  return (
    <>
      <FormGroup key={`anniverssary-t-shirt-${index}`}>
        <Row>
          <Col lg="6">
            <FormGroup className="d-flex align-items-center">
              <select
                name="input-priority"
                id="input-priority"
                className="form-control"
                value={model}
                onChange={(e) => setModel(e.target.value, index)}
              >
                <option value="" selected>
                  Select year
                </option>
                <option value="1 year">1 year</option>
                <option value="2 year">2 year</option>
                <option value="3 year">3 year</option>
                <option value="4 year">4 year</option>
                <option value="5 year">5 year</option>
                <option value="6 year">6 year</option>
                <option value="7 year">7 year</option>
                <option value="8 year">8 year</option>
                <option value="9 year">9 year</option>
                <option value="10 year">10 year</option>
              </select>
            </FormGroup>
          </Col>
          <Col lg="6">
            <Button className="btn-danger" onClick={() => deleteShirt(index)}>
              <i className="fas fa-trash cursor-poiter"></i>
            </Button>
          </Col>
        </Row>
        {model && (
          <Row>
            <Col lg="1">
              <FormGroup>
                <label className="form-control-label text-primary pl-1" htmlFor="input-priority">
                  XS
                </label>

                <input
                  name="input-priority"
                  type="text"
                  id="input-priority"
                  placeholder="e.g"
                  className="form-control"
                  value={quantityXs}
                  onChange={(e) => setQuantityXs(e.target.value, index)}
                />
              </FormGroup>
            </Col>

            <Col lg="1">
              <FormGroup>
                <label className="form-control-label text-primary pl-1" htmlFor="input-priority">
                  S
                </label>

                <input
                  name="input-priority"
                  type="text"
                  id="input-priority"
                  placeholder="e.g"
                  className="form-control"
                  value={quantityS}
                  onChange={(e) => setQuantityS(e.target.value, index)}
                />
              </FormGroup>
            </Col>

            <Col lg="1">
              <FormGroup>
                <label className="form-control-label text-primary pl-1" htmlFor="input-priority">
                  M
                </label>

                <input
                  name="input-priority"
                  type="text"
                  id="input-priority"
                  placeholder="e.g"
                  className="form-control"
                  value={quantityM}
                  onChange={(e) => setQuantityM(e.target.value, index)}
                />
              </FormGroup>
            </Col>

            <Col lg="1">
              <FormGroup>
                <label className="form-control-label text-primary pl-1" htmlFor="input-priority">
                  L
                </label>

                <input
                  name="input-priority"
                  type="text"
                  id="input-priority"
                  placeholder="e.g"
                  className="form-control"
                  value={quantityL}
                  onChange={(e) => setQuantityL(e.target.value, index)}
                />
              </FormGroup>
            </Col>

            <Col lg="1">
              <FormGroup>
                <label className="form-control-label text-primary pl-1" htmlFor="input-priority">
                  XL
                </label>

                <input
                  name="input-priority"
                  type="text"
                  id="input-priority"
                  placeholder="e.g"
                  className="form-control"
                  value={quantityXl}
                  onChange={(e) => setQuantityXl(e.target.value, index)}
                />
              </FormGroup>
            </Col>

            <Col lg="1">
              <FormGroup>
                <label className="form-control-label text-primary pl-1" htmlFor="input-priority">
                  2XL
                </label>

                <input
                  name="input-priority"
                  type="text"
                  id="input-priority"
                  placeholder="e.g"
                  className="form-control"
                  value={quantity2xl}
                  onChange={(e) => setQuantity2xl(e.target.value, index)}
                />
              </FormGroup>
            </Col>

            <Col lg="1">
              <FormGroup>
                <label className="form-control-label text-primary pl-1" htmlFor="input-priority">
                  3XL
                </label>

                <input
                  name="input-priority"
                  type="text"
                  id="input-priority"
                  placeholder="e.g"
                  className="form-control"
                  value={quantity3xl}
                  onChange={(e) => setQuantity3xl(e.target.value, index)}
                />
              </FormGroup>
            </Col>
          </Row>
        )}
      </FormGroup>
      <hr></hr>
    </>
  );
}
