import http from '../helpers/http';
import DocumentsFoldersInterface from '../interfaces/DocumentsFoldersInterface';
import DocumentsFoldersInterfacePositions from '../interfaces/DocumentsFoldersInterfacePositions';
import { FilteredOptionsInterface, makeQueryParamsForRequest } from '../entities/FilteredOptions';

const DocumentsFolders = (token: string) => {
  const headers = {
    'x-access-token': token,
  };

  const handleGetIP = async () => {
    const res = await http.get('https://geolocation-db.com/json/');
    return res.data.IPv4;
  };

  return {
    list: async (filteredOptions?: FilteredOptionsInterface) => {
      const params = makeQueryParamsForRequest(filteredOptions);
      params.request_ip = await handleGetIP();
      const result = await http.get(`/folders`, {
        headers,
        params,
      });
      return result.data;
    },
    listForPosition: async (filteredOptions?: FilteredOptionsInterface) => {
      const params = makeQueryParamsForRequest(filteredOptions);
      const result = await http.get(`/folders/positions`, {
        headers,
        params,
      });
      return result.data;
    },

    show: async (id: string) => {
      const result = await http.get(`/folders/${id}`, {
        headers,
      });
      return result.data;
    },
    create: async (body: Partial<DocumentsFoldersInterface>) => {
      const result = await http.post(`/folders`, body, { headers });
      return result.data;
    },
    update: async (id: string, body: Partial<DocumentsFoldersInterface>) => {
      const result = await http.put(`/folders/${id}`, body, { headers });
      return result.data;
    },
    updatePositions: async (id: string, body: Partial<DocumentsFoldersInterfacePositions>) => {
      const result = await http.put(`/folders/${id}/positions`, body, { headers });
      return result.data;
    },
    delete: async (id: string) => {
      const result = await http.delete(`/folders/${id}`, {
        headers,
      });
      return result.data;
    },
    picture: async (id: string, body: FormData) => {
      const headers = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-access-token': token,
        },
      };
      const result = await http.patch(`/folders/${id}/picture`, body, headers);
      return result.data;
    },
  };
};

export default DocumentsFolders;
