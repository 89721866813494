import { Button, Card, CardBody, Container, Row, Col } from 'reactstrap';

type EmployeeChangeStatusConfirmProps = {
  id: string;
  handleActivatedEmployee: (id: string) => void;
  onClose: () => void;
};

const EmployeeChangeStatusConfirm = ({ id, handleActivatedEmployee, onClose }: EmployeeChangeStatusConfirmProps) => {
  return (
    <Container fluid>
      <Card>
        <CardBody>
          <Row className="mt-3">
            <Col md="12">
              <span className="h2 font-weight-bold mb-0">Are you sure you want to activate this employee?</span>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col md="6 text-center">
              <Button
                color="primary"
                href="#"
                onClick={() => {
                  handleActivatedEmployee(id);
                  onClose();
                }}
                size="lg"
                style={{ minWidth: '100px' }}
              >
                Yes
              </Button>
            </Col>
            <Col md="6 text-center">
              <Button color="danger" href="#" onClick={onClose} size="lg" style={{ minWidth: '100px' }}>
                No
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
};

export default EmployeeChangeStatusConfirm;
