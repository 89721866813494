import { Card, Button, Container, Row, Col, CardBody, FormGroup, Form, Input, Label } from 'reactstrap';
import { useContext, useState, useEffect } from 'react';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import { toast } from 'react-toastify';
import TimePicker from '../../../components/Core/TimezonePicker';
import KidsActivity from '../../../services/KidsActivity';

type ClassBathroomProps = {
  kidsIds: string[];
  isNotPhotoPermissionKids?: any[];
  onCloseModal: (clear?: boolean) => void;
  onSubmitSetTimer?: (id: string, type: string) => void;
};

const ClassBathroomForm = ({ kidsIds, onCloseModal, onSubmitSetTimer = () => {} }: ClassBathroomProps) => {
  const { token }: any = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [notes, setNotes] = useState('');
  const [time, setTime] = useState('');
  const [disabled] = useState(false);
  const [activityType, setActivityType] = useState('');
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (!kidsIds.length) {
      toast.warn('Please select the students.');
      onCloseModal();
    }
  }, []);

  const closeModal = () => {
    onCloseModal();
  };

  const handleSubmitForm = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);

    try {
      for (const k of kidsIds) {
        await KidsActivity(token).sendBathroom(k, notes, time, { activityType, options });
      }
      toast.success(`Saved successfully!`);

      kidsIds.forEach((id: string) => onSubmitSetTimer(id, 'BATHROOM'));
      onCloseModal(true);
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Create error!');
      toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  const getOptionsChecked = (value: string) => {
    const isChecked = options.find((v) => v === value);
    return !!isChecked;
  };

  const setOptionsChecked = (value: string) => {
    const newOptionsSelected = JSON.parse(JSON.stringify(options));
    if (newOptionsSelected.includes(value)) {
      const removedArray = newOptionsSelected.filter((v: string) => String(v) !== value);
      setOptions(removedArray);
      return;
    }
    newOptionsSelected.push(value);
    setOptions(newOptionsSelected);
  };

  return (
    <>
      <Container fluid>
        <Form onSubmit={handleSubmitForm}>
          <Card>
            <CardBody>
              <Row>
                <Col lg="12">
                  <FormGroup row>
                    <Col md="12">
                      <label className="form-control-label" htmlFor="activityType">
                        Select an Activity
                      </label>
                    </Col>

                    <Col md="12">
                      <FormGroup check>
                        <Label check>
                          <Input
                            name="activityType"
                            type="radio"
                            value={'Diaper'}
                            onChange={(e) => setActivityType(e.target.value)}
                          />{' '}
                          Diaper
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <FormGroup check>
                        <Label check>
                          <Input
                            name="activityType"
                            type="radio"
                            value={'On Potty'}
                            onChange={(e) => setActivityType(e.target.value)}
                          />{' '}
                          On Potty
                        </Label>
                      </FormGroup>
                    </Col>

                    <Col md="12">
                      <FormGroup check>
                        <Label check>
                          <Input
                            name="activityType"
                            type="radio"
                            value={'Accident'}
                            onChange={(e) => setActivityType(e.target.value)}
                          />{' '}
                          Accident
                        </Label>
                      </FormGroup>
                    </Col>
                  </FormGroup>
                </Col>
                <Col lg="12">
                  <FormGroup row>
                    <Col md="12">
                      <FormGroup check>
                        <Label check>
                          <Input
                            name="options"
                            type="checkbox"
                            defaultChecked={getOptionsChecked('BM')}
                            onChange={() => setOptionsChecked('BM')}
                          />{' '}
                          BM
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <FormGroup check>
                        <Label check>
                          <Input
                            name="options"
                            type="checkbox"
                            defaultChecked={getOptionsChecked('Pee')}
                            onChange={() => setOptionsChecked('Pee')}
                          />{' '}
                          Pee
                        </Label>
                      </FormGroup>
                    </Col>
                    {activityType === 'Diaper' && (
                      <Col md="12">
                        <FormGroup check>
                          <Label check>
                            <Input
                              name="options"
                              type="checkbox"
                              defaultChecked={getOptionsChecked('Dry')}
                              onChange={() => setOptionsChecked('Dry')}
                            />{' '}
                            Dry
                          </Label>
                        </FormGroup>
                      </Col>
                    )}
                    <hr></hr>
                    <Col md="12">
                      <FormGroup check>
                        <Label check>
                          <Input
                            name="options"
                            type="checkbox"
                            defaultChecked={getOptionsChecked('Applied Diaper Cream')}
                            onChange={() => setOptionsChecked('Applied Diaper Cream')}
                          />{' '}
                          Applied Diaper Cream
                        </Label>
                      </FormGroup>
                    </Col>
                  </FormGroup>
                </Col>
                <Col lg="12">
                  <FormGroup>
                    <label className="form-control-label">Insert a Note</label>
                    <Input type="textarea" onChange={(e) => setNotes(e.target.value)} />
                  </FormGroup>
                </Col>
                <Col lg="12">
                  <FormGroup>
                    <label className="form-control-label">Time</label>
                    <TimePicker
                      onChangeTime={(time: string) => {
                        setTime(time);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mt-5">
                <Col md="6 text-center">
                  <Button
                    color="primary"
                    type="submit"
                    size="lg"
                    style={{ minWidth: '100px' }}
                    disabled={disabled || loading}
                  >
                    {loading && (
                      <>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        {'  Save'}
                      </>
                    )}
                    {!loading && <>Save</>}
                  </Button>
                </Col>
                <Col md="6 text-center">
                  <Button
                    color="secondary"
                    size="lg"
                    style={{ minWidth: '100px' }}
                    onClick={closeModal}
                    disabled={loading}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Form>
      </Container>
    </>
  );
};

export default ClassBathroomForm;
