import { useState, useEffect } from 'react';
import { Badge, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';

import KidsService from '../../../services/Kids';
import KidsInterface from '../../../interfaces/KidsInterface';
import UserInterface from '../../../interfaces/UserInterface';
import UserService from '../../../services/User';

import CardInfo from '../../../components/Cards/CardInfo';
import ModalContent from '../../../components/Modals/Modal';
import DataTable from '../../../components/Core/DataTable';
import { toLocaleFormatSlash } from '../../../helpers/dateFormat';
import moment from 'moment';

export const BirthdayAndAnniversary: React.FC<{
  token: string;
  currentSchoolId: string;
}> = ({ token, currentSchoolId }) => {
  const [birthdays, setBirthdays] = useState([] as any[]);
  const [anniversaries, setAnniversaries] = useState([] as any[]);
  const [totalNivers, setTotalNivers] = useState(0);
  const [toggleModal, setToggleModal] = useState(false);
  const handleToggleModal = () => setToggleModal(!toggleModal);
  const [activeTab, setActiveTab] = useState('1');
  const customStyles = {
    navLink: {
      minHeight: '42px',
      paddingTop: '8px',
      cursor: 'pointer',
    },
  };

  const type = (value: string) => {
    if (value === 'Staff') {
      return (
        <Badge className="cursor-pointer" color="danger">
          Staff
        </Badge>
      );
    } else {
      return (
        <Badge className="cursor-pointer" color="success">
          Children
        </Badge>
      );
    }
  };

  const columns = [
    {
      name: 'Name',
      selector: (row: any) => <span>{`${row?.firstName || '-'} ${row?.lastName || '-'}`}</span>,
      grow: 1,
    },
    {
      name: 'Type',
      selector: (row: any) => row.type,
      grow: 1,
      sortable: true,
      cell: (row: any) => type(row.type),
    },
    {
      name: 'Birthday',
      selector: (row: any) => row.birthday,
      grow: 1,
      cell: (row: any) => toLocaleFormatSlash(row.birthday),
    },
  ];

  const columnsAnniversaries = [
    {
      name: 'Name',
      selector: (row: any) => <span>{`${row?.firstName || '-'} ${row?.lastName || '-'}`}</span>,
      grow: 1,
    },
    {
      name: 'Type',
      selector: (row: any) => row.type,
      grow: 1,
      sortable: true,
      cell: (row: any) => type(row.type),
    },
    {
      name: 'Date',
      selector: (row: any) => row?.enrollment?.enrollmentStartDate,
      grow: 1,
      cell: (row: any) =>
        row?.enrollment?.enrollmentStartDate ? toLocaleFormatSlash(row?.enrollment?.enrollmentStartDate) : '-',
    },
    {
      name: '# Of Years',
      selector: (row: any) => row.birthday,
      grow: 1,
      cell: (row: any) => Number(moment().diff(moment(row.birthday), 'years', true)).toFixed(0),
    },
  ];

  useEffect(() => {
    if (currentSchoolId) loadList();
  }, [currentSchoolId, token]);

  const loadList = async () => {
    try {
      const result = await KidsService(token).listBirthdayBySchool(currentSchoolId);
      const result2 = await KidsService(token).listAnniversaryBySchool(currentSchoolId);
      const result3 = await UserService(token).listBirthdayUsers();
      const result4 = await UserService(token).listAnniversaryUsers();

      const data: KidsInterface[] = result?.data || [];
      const data2: KidsInterface[] = result2?.data || [];
      const data3: UserInterface[] = result3?.data || [];
      const data4: UserInterface[] = result4?.data || [];

      const kidsBirthday = data?.map((k) => ({ ...k, type: 'Children' }));

      const kidsAnniversary = data2?.map((k) => ({ ...k, type: 'Children' }));

      const staffBirthday = data3?.map((s) => ({ ...s, type: 'Staff' }));

      const staffAnniversary = data4?.map((s) => ({ ...s, type: 'Staff' }));

      const birthdays = [...kidsBirthday, ...staffBirthday];
      const anniversaries = [...kidsAnniversary, ...staffAnniversary];

      const totalBirthdaysAndAnniversaries =
        (kidsBirthday.length || 0) +
        (kidsAnniversary.length || 0) +
        (staffBirthday.length || 0) +
        (staffAnniversary.length || 0);

      setBirthdays(birthdays);
      setAnniversaries(anniversaries);
      setTotalNivers(totalBirthdaysAndAnniversaries);
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Children Birthday and Anniversary error!');
      console.error(message);
    }
  };

  return (
    <>
      <CardInfo
        count={totalNivers}
        class="bg-info card-info-mg-button"
        title="BDAY/Anniversaries"
        icon="fa-birthday-cake"
        toggle={handleToggleModal}
      />
      <ModalContent
        title="Birthdays and Anniversaries"
        class="pt-0"
        modal={toggleModal}
        toggle={handleToggleModal}
        size="lg"
      >
        <Row>
          <Col xs="12">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={`${activeTab === '1' ? 'active' : ''}`}
                  onClick={() => setActiveTab('1')}
                  style={customStyles.navLink}
                >
                  Birthdays
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={`${activeTab === '2' ? 'active' : ''}`}
                  onClick={() => setActiveTab('2')}
                  style={customStyles.navLink}
                >
                  Anniversaries
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
          <Col xs="12">
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                {birthdays && (
                  <DataTable
                    title="Birthdays"
                    header={false}
                    shadowStyle={false}
                    columns={columns}
                    data={birthdays}
                    totalRows={birthdays.length}
                  />
                )}
              </TabPane>
            </TabContent>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="2">
                {anniversaries && (
                  <DataTable
                    title="Anniversaries"
                    header={false}
                    shadowStyle={false}
                    columns={columnsAnniversaries}
                    data={anniversaries}
                    totalRows={anniversaries.length}
                  />
                )}
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </ModalContent>
    </>
  );
};
